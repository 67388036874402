import {
    listNetworksRequest,
    listNetworksSuccess,
    listNetworksFailure,
    listNetworksFulfill,
    updateNetworkRequest,
    updateNetworkSuccess,
    updateNetworkFailure,
    updateNetworkFullfill,
    deleteNetworkRequest,
    deleteNetworkSuccess,
    deleteNetworkFailure,
    deleteNetworkFullfill,
    createNetworkRequest,
    createNetworkSuccess,
    createNetworkFailure,
    createNetworkFullfill,
    clearNetworkItems
} from "./actions";

const initialState = {
    error: null,
    items: [],
    loading: true,
};

const handleRequest = state =>
    Object.assign({}, state, { loading: true, error: null });

const handleSuccess = (state, { payload }) =>
    Object.assign({}, state, { items: payload });

const handleFailure = (state, { payload }) =>
    Object.assign({}, state, { error: payload });

const handleFulfill = state => Object.assign({}, state, { loading: false });

const handleDelete = (state, { payload }) => {
    const newItems = state.items.filter(item => item.id !== payload.id);
    state.items = newItems;
    return Object.assign({}, state);
};

const handleUpdate = (state, { payload }) => {
    return Object.assign({}, state, { state, items: [
        ...state.items.map(item => {
            if(item.id === payload.id){
                return { ...item, ...payload }
            }
            return item;
    })]})
};

const handleAddNewNetwork = (state, { payload }) => {
    if (!state.items) {
        return Object.assign({}, state, { items: [payload] });
    }
    return Object.assign({}, state, { items: [payload, ...state.items] });
}

const handleClearNetworks = state => Object.assign({}, state, { items: [] });

const handlers = {
    [listNetworksRequest.toString()]: handleRequest,
    [listNetworksSuccess.toString()]: handleSuccess,
    [listNetworksFailure.toString()]: handleFailure,
    [listNetworksFulfill.toString()]: handleFulfill,

    [updateNetworkRequest.toString()]: handleRequest,
    [updateNetworkSuccess.toString()]: handleUpdate,
    [updateNetworkFailure.toString()]: handleFailure,
    [updateNetworkFullfill.toString()]: handleFulfill,

    [deleteNetworkRequest.toString()]: handleRequest,
    [deleteNetworkSuccess.toString()]: handleDelete,
    [deleteNetworkFailure.toString()]: handleFailure,
    [deleteNetworkFullfill.toString()]: handleFulfill,

    [createNetworkRequest.toString()]: handleRequest,
    [createNetworkSuccess.toString()]: handleAddNewNetwork,
    [createNetworkFailure.toString()]: handleFailure,
    [createNetworkFullfill.toString()]: handleFulfill,

    [clearNetworkItems.toString()]: handleClearNetworks,
};

const reducer = (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
