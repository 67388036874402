import {
    LIST_SCENES_REQUEST,
    LIST_SCENES_SUCCESS,
    LIST_SCENES_FAILURE,
    LIST_SCENES_FULFILL,
    SCENES_SEARCH,
    SCENES_CHECK,
    SCENES_EDITABLE,
    SCENES_MODE_EDIT,
    SCENES_STAGE_NAMES,
    UPDATE_SCENE_SUCCESS,
    DELETE_SCENE_SUCCESS,
    SCENES_CLEAR_CHECKED,
    SCENES_CLEAR_EDITABLE,
    SCENES_CLEAR_STAGE_NAMES,
    ADD_SCENE_SUCCESS,
    SET_SCENE_MODAL,
    SELECTED_SCENE,
    LAST_ITEMS_UPDATE,
} from "./actions";

const initialState = {
    error: null,
    items: null,
    lastItemsUpdate: null,
    loading: true,
    mode: {
        edit: [],
    },
    modal: {
        status: false,
        id: null
    },
    selected: null,
    meta: {
        search: "",
        editable: [],
        checked: [],
        stageNames: [],
    }
};

const handleRequest = state =>
    Object.assign({}, state, { loading: true, error: null });

const handleSuccess = (state, { payload }) =>
    Object.assign({}, state, { items: payload });

const handleFailure = (state, { payload }) =>
    Object.assign({}, state, { error: payload });
const handleFulfill = state => Object.assign({}, state, { loading: false });

const handleSearch = (state, { payload }) =>
    Object.assign({}, state, { meta: { ...state.meta, search: payload } });

const handleModeEdit = (state, { payload }) => {
    let modeEdit = state.mode.edit;
    if (modeEdit.includes(payload)) {
        modeEdit = modeEdit.filter(id => id !== payload);
    } else {
        modeEdit.push(payload);
    }
    return Object.assign({}, state, { mode: { ...state.mode, edit: modeEdit } });
}

const handleCheck = (state, { payload }) => {
    let newChecked;
    const { checked } = state.meta;
    const checkExits = checked.includes(payload);

    if(checkExits) {
        newChecked = checked.filter(check => check !== payload);
    } else {
        newChecked = [...checked, payload];
    }
    return Object.assign({}, state, {
        meta: { ...state.meta, checked: newChecked }
    });
};

const handleEditable = (state, { payload }) => {
    let newEditable;
    const { editable } = state.meta;
    const editExits = editable.includes(payload);

    if(editExits) {
        newEditable = editable.filter(edit => edit !== payload);
    } else {
        newEditable = [...editable, payload];
    }
    return Object.assign({}, state, {
        meta: { ...state.meta, editable: newEditable }
    });
};

const handleStageNames = (state, { payload: { id, name } }) => {
    const stageNames = state.meta.stageNames;
    const stageNamesIndex = stageNames.findIndex(stageName => stageName.id === id);
    if(stageNamesIndex >= 0) {
        if (name === null ) {
            const newStageNames = state.meta.stageNames.filter(stageName => stageName.id !== id);
            state.meta.stageNames = newStageNames;
        } else {
            state.meta.stageNames[stageNamesIndex].name = name;
        }
    } else {
        state.meta.stageNames.push({ id, name })
    }

    return Object.assign({}, state);
};

const handleUpdate = (state, { payload }) => {
    const sceneIndex = state.items.findIndex(item => item.id === payload.id);
    if(sceneIndex >= 0) {
        state.items[sceneIndex] = { ...state.items[sceneIndex], ...payload };
        if (state.items[sceneIndex].hidden != null) {
            state.items[sceneIndex].hidden = parseInt(state.items[sceneIndex].hidden, 10);
        }
    }
    return Object.assign({}, state);
};

const handleDelete = (state, { payload }) => {
    const newItems = state.items.filter(item => item.id !== payload.id);
    state.items = newItems;
    return Object.assign({}, state);
};

const handleClearChecked = state => {
    return Object.assign({}, state, {
        meta: { ...state.meta, checked: [] }
    });
};
const handleClearEditable = state => {
    return Object.assign({}, state, {
        meta: { ...state.meta, editable: [] }
    });
};

const handleClearStageNames = state => {
    return Object.assign({}, state, {
        meta: { ...state.meta, stageNames: [] }
    });
};

const handleAddScene = (state, { payload }) => {
    if (!state.items) {
        return Object.assign({}, state, { items: [payload] });
    }
    if (state.items.filter(item => payload.id === item.id).length === 0) {
        return Object.assign({}, state, { items: [...state.items, payload] });
    }
    return Object.assign({}, state, { items: [...state.items] });
}

const handleSetModal = (state, { payload }) =>
    Object.assign({}, state, { modal: {status: payload.status, id: payload.id} });

const handleSelected = (state, { payload }) =>
    Object.assign({}, state, { selected: payload });

// if there is a change in the scene list, time is updated so we know related components should re-render
const handleLastItemsUpdate = (state) => {
    const newDate = new Date();
    return Object.assign({}, state, { lastItemsUpdate: newDate.getTime() });
};

const handlers = {
    [LIST_SCENES_REQUEST]: handleRequest,
    [LIST_SCENES_SUCCESS]: handleSuccess,
    [LIST_SCENES_FAILURE]: handleFailure,
    [LIST_SCENES_FULFILL]: handleFulfill,
    [SCENES_SEARCH]: handleSearch,
    [SCENES_CHECK]: handleCheck,
    [SCENES_EDITABLE]: handleEditable,
    [SCENES_MODE_EDIT]: handleModeEdit,
    [SCENES_STAGE_NAMES]: handleStageNames,
    [UPDATE_SCENE_SUCCESS]: handleUpdate,
    [DELETE_SCENE_SUCCESS]: handleDelete,

    [SCENES_CLEAR_CHECKED]: handleClearChecked,
    [SCENES_CLEAR_EDITABLE]: handleClearEditable,
    [SCENES_CLEAR_STAGE_NAMES]: handleClearStageNames,

    [ADD_SCENE_SUCCESS]: handleAddScene,

    [SET_SCENE_MODAL]: handleSetModal,
    [SELECTED_SCENE]: handleSelected,
    [LAST_ITEMS_UPDATE]: handleLastItemsUpdate,
};

const reducer = (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
