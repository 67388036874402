import Immutable from 'seamless-immutable';
import {
    listEvents,
    processEvent,
    retrieveEvent,
    tableEvent,
    paginationEvent,
    clearNewEvents,
} from "./actions";
import App from 'app/app';

export const initialState = Immutable({
    error: null,
    items: null,
    allItems: [],
    newItems: [],
    loading: true,
    loadings: [],
    meta: {
        pagination: {
            limit: 10,
            count: 0,
            before: null,
            after: null,
        }
    },
    table: {
        currentPage: 1,
        totalPages: 3,
        sorting: [{
            columnName: "created_at",
            direction: "desc"
        }],
        grouping: [],
        expandedGroups: [],
        selection: [],
        expandedRowIds: [],
        search: '',
        filters: [{
            columnName: "created_at",
            value: {
                navigation: ['before', null],
                date_range: [null, null],
            },
        }],
        pageSizes: [ 10, 15, 25, 50, 100, 200 ],
        columns: [
            { name: 'id', title: 'ID' },
            { name: 'site_id', title: 'SiteID'},
            { name: 'event_type', title: 'Event Type' },
            { name: 'location_name', title: 'Location' },
            { name: 'scene_name', title: 'Scene Name' },
            { name: 'link_id', title: 'linkID'},
            { name: 'created_at', title: 'Created' },
            { name: 'updated_at', title: 'Updated' },
            { name: 'state', title: 'Status' },
        ],
        columnWidths: [
            { columnName: 'id', width: 300 },
            { columnName: 'site_id', width: 80 },
            { columnName: 'event_type', width: 150 },
            { columnName: 'location_name', width: 260 },
            { columnName: 'scene_name', width: 150 },
            { columnName: 'link_id', width: 80 },
            { columnName: 'created_at', width: 200 },
            { columnName: 'updated_at', width: 200 },
            { columnName: 'state', width: 100 },
        ],
        columnExtensions: [
            { columnName: 'id', sortingEnabled: false, },
            { columnName: 'site_id', sortingEnabled: false, },
            { columnName: 'event_type', sortingEnabled: false, },
            { columnName: 'location_name', sortingEnabled: false, },
            { columnName: 'scene_name', sortingEnabled: false, },
            { columnName: 'link_id', sortingEnabled: false, },
            { columnName: 'created_at', sortingEnabled: false },
            { columnName: 'updated_at', sortingEnabled: false },
            { columnName: 'state', align: 'center', sortingEnabled: false, filteringEnabled: false },
        ],
        rightColumns: ['state'],
        hiddenColumnNames: ['created_at', 'updated_at']
    }
});

const handleRequest = state => state.set('loading', true).set('error', null)
const handleSuccess = (state, { payload }) => {
    const initialPage = ['before', null];
    const filters = Immutable.asMutable(state.getIn(['table','filters']), { deep: true });
    const filterCreatedAt = filters.find(f => f.columnName === 'created_at');
    const isInitialPage = initialPage.every(p => filterCreatedAt.value.navigation.includes(p));

    if (!isInitialPage && payload.data.length <= 0) {
        App.alert('success', 'No new events found');
        return state;
    }
    return state.set('items', payload.data)
        .setIn(['meta', 'pagination'], payload.meta.pagination)
        .update('allItems', (allItems, data) => {
            if (allItems.length === 0) return data;
            return allItems.reduce((items, item, idx) => {
                    const exist = data.findIndex(value => value.id === item.id);
                    return items.set(idx, {...items[exist], ...data});
                }, allItems);
            }, payload.data)
}
const handleFailure = (state, { payload }) => state.set('error', payload);
const handleFulfill = state => state.set('loading', false)
const handleSorting = (state, { payload }) => state.setIn(['table', 'sorting'], payload);
const handleGrouping = (state, { payload }) => state.setIn(['table', 'grouping'], payload);
const handleSelection = (state, { payload }) => state.setIn(['table', 'selection'], payload);
const handleFilters = (state, { payload }) => {
    let newFilters;
    let { key, value } = payload;
    const filters = Immutable.asMutable(state.getIn(['table','filters']), { deep: true });
    const created_at = filters.find(f => f.columnName === 'created_at');

    // date filters aliases
    if (key === 'navigation') {
        key = 'created_at';
        value = { navigation: value, date_range: created_at.value.date_range };
    } else if(key === 'date_range') {
        key = 'created_at';
        value = { navigation: ['before', null], date_range: value };
    }

    const filter = filters.find(filter => filter.columnName === key);

    if (filter) {
        if (value !== null) { // update filter
            filter.value = value;
            newFilters = filters;
        } else { // remove filter
            newFilters = filters.filter(filter => filter.columnName !== key);
        }
    } else if (value !== null) { // Create filter
        newFilters = filters.concat({
            columnName: key,
            value,
        });
    } else {
        newFilters = filters;
    }

    // if any filters is applied, reset navigation to page 1
    if(key !== 'created_at') {
        created_at.value.navigation = ['before', null];
    }

    return state.setIn(['table', 'filters'], Immutable(newFilters));
};
const handleSearch = (state, { payload }) => state.setIn(['table', 'search'], payload);
const handleExpandedGroups = (state, { payload }) => state.setIn(['table', 'expandedGroups'], payload);
const handleExpandedRowIds = (state, { payload }) => state.setIn(['table', 'expandedRowIds'], payload);

const handlePageSize = (state, { payload }) => state.setIn(['meta', 'pagination', 'limit'], payload);

const handleUpdateItem = (state, { payload })  => {
    const items = state.items.map(item => {
        if(item.id === payload.id) {
            return item.merge(payload);
        }
        return item;
    });
    return state.set('items', items);
}

const handleProcess = (state, { payload }) => {
    const items = state.getIn(['items']);
    let newItems = state.getIn(['newItems']);

    if (items !== null && items.length > 0) {
        const firstItemTimeStamp = (new Date(items[0].created_at)).getTime();
        if((new Date(payload.created_at)).getTime() > firstItemTimeStamp) {
            const exists = newItems.filter(item => item.id === payload.id);
            if (exists.length === 1) {
                newItems = newItems.map(item => {
                    if(item.id === payload.id) {
                        return item.merge(payload);
                    }
                    return item;
                });
            } else {
                newItems = [payload, ...newItems];
            }
        }

        const updateItems = state.items.map(item => {
            if(item.id === payload.id) {
                return item.merge(payload);
            }
            return item;
        });
        return state.set('items', updateItems).set('newItems', newItems);
    }

    return state.set('newItems', newItems);
}

const handleClearNewItems = state => state.set('newItems', initialState.getIn(['newItems']));
const handleResetFilters = state => state.setIn(['table', 'filters'], initialState.getIn(['table', 'filters']));

const handleLoadingRequestByItem = (state, { payload }) => {
    const loadings = state.getIn(['loadings']);
    const idExist = loadings.filter(id => id === payload);
    if (idExist.length === 0) {
        return state.set('loadings', loadings.concat(payload));
    }
    return state;
}

const handleLoadingFulfillByItem = (state, { payload }) => {
    const loadings = state.getIn(['loadings']);
    const ids = loadings.filter(id => id !== payload);
    return state.set('loadings', ids);
}

const handlers = {
    [listEvents.request.toString()]: handleRequest,
    [listEvents.success.toString()]: handleSuccess,
    [listEvents.failure.toString()]: handleFailure,
    [listEvents.fulfill.toString()]: handleFulfill,
    [processEvent.toString()]: handleProcess,
    [tableEvent.sorting.toString()]: handleSorting,
    [tableEvent.grouping.toString()]: handleGrouping,
    [tableEvent.selection.toString()]: handleSelection,
    [tableEvent.filters.toString()]: handleFilters,
    [tableEvent.search.toString()]: handleSearch,
    [tableEvent.expandedGroups.toString()]: handleExpandedGroups,
    [tableEvent.expandedRowIds.toString()]: handleExpandedRowIds,
    [paginationEvent.pageSize.toString()]: handlePageSize,
    [retrieveEvent.request.toString()]: handleLoadingRequestByItem,
    [retrieveEvent.success.toString()]: handleUpdateItem,
    [retrieveEvent.fulfill.toString()]: handleLoadingFulfillByItem,
    [clearNewEvents.toString()]: handleClearNewItems,
    [tableEvent.resetFilters.toString()]: handleResetFilters,
};

const reducer = (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
