import { validateMotion } from "./Motion";
import { validateActions, validatePHDs } from "./Actions";
import { validateTriggers } from "./Triggers";
import type { Issue, Location, SceneValidatorType, ValidatorKey } from "../../types";

export const validateLocationScenes = (location: Location, validatorKey: ValidatorKey) => {
    const locationScenes = location.scenes;
    const scenesIssues: Issue[] = [];
    if (Object.keys(locationScenes).length > 0) {
        for (let id in locationScenes) {
            if (location.isParent && locationScenes[id].location_id !== location.id) {
                continue;
            }

            const issues: Issue[] = [];
            const validators: SceneValidatorType = [
                { key: "action", validator: validateActions },
                { key: "trigger", validator: validateTriggers },
                { key: "motion", validator: validateMotion },
                { key: "phd", validator: validatePHDs },
            ]

            validators.forEach(({ key, validator }) => {
                issues.push(...validator(location, locationScenes[id], validatorKey, key));
            });

            scenesIssues.push(...issues)
        }
    }
    return scenesIssues;
};
