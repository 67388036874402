import { createSelector } from "reselect";

import { isLoadingLocations } from "redux/locations";
import { isLoadingDevices } from "redux/devices";
import { isLoadingScenes } from "redux/scenes";
import { isLoadingDeviceGroups } from "redux/device_groups";
import { isLoadingSchedules } from "redux/schedules";

export const getActiveSite = state => state.activeSite;

export const getSiteItem = createSelector(
    getActiveSite,
    site => site.item
);
export const isLoadingActiveSite = createSelector(
  [
    isLoadingLocations,
    isLoadingDevices,
    isLoadingScenes,
    isLoadingDeviceGroups
  ],
  (locations, devices, scenes, deviceGroups) =>
    locations || devices || scenes || deviceGroups
);

export const isLoadingScheduleFinish = createSelector(
    [
      isLoadingLocations,
      isLoadingScenes,
      isLoadingSchedules,
    ],
    (locations, scenes, schedule) => locations || scenes || schedule
);

export const getActiveSiteError = createSelector(
  getActiveSite,
  site => site.error
);

export const getActiveSiteLocationId  = createSelector(
    getActiveSite,
    site => site.locationId
);

export const getDashboard = createSelector(getActiveSite, site => site.dashboard)
export const isOpenDeviceAndScenes = createSelector(getDashboard, dashboard => dashboard.deviceAndScenes)
export const isOpenDeviceAndScenesGlobal = createSelector(getActiveSite, site => site.toggleGlobalDeviceAndScene)
export const getMqttStatus = createSelector(getActiveSite, site => site.mqtt);
export const getSiteId = createSelector(getSiteItem, item => item.siteid);
export const getMetrics = createSelector(getActiveSite, activeSite => activeSite.metrics);
export const getMetricsItems = createSelector(getMetrics, metrics => metrics.items);
export const getMetricsErrors = createSelector(getMetrics, metrics => metrics.error);
