import Immutable from 'seamless-immutable';
import {
    listDatapoints,
    processDatapoint,
    cleanInflux,
    retrieveDatapoint,
    influxDatapoint,
    tableDatapoint,
    paginationDatapoint,
    clearNewDatapoints,
    setFiltersFromQueryParamsOnDatapoint,
} from "./actions";
import App from 'app/app';

export const initialState = Immutable({
    error: null,
    items: null,
    allItems: [],
    newItems: [],
    loading: true,
    loadings: [],
    meta: {
        pagination: {
            limit: 25,
            offset: 0,
            count: 0,
            before: null,
            after: null,
        }
    },
    table: {
        currentPage: 1,
        totalPages: 3,
        sorting: [{
            columnName: "created_at",
            direction: "desc"
        }],
        grouping: [],
        expandedGroups: [],
        selection: [],
        expandedRowIds: [],
        search: '',
        filters: [
            {
                columnName: "created_at",
                value: {
                    navigation: ['before', null],
                    date_range: [null, null],
                },
            },
            {
                columnName: "name",
                value: [
                    'Channel 1',
                    'RSSI',
                    'Occupied',
                    'Occ Mode',
                    'Nh Timeout',
                    'AH Timeout',
                    'St ch01',
                    'St ch02',
                    'St ch03',
                    'St ch04',
                    'St ch05',
                    'St ch06',
                    'St ch07',
                    'St ch08',
                    'St ch09',
                    'St ch10',
                    'St ch11',
                    'St ch12',
                    'St ch13',
                    'St ch14',
                    'St ch15',
                    'St ch16',
                ],
            }
        ],
        pageSizes: [ 10, 15, 25, 50, 100, 200 ],
        columns: [
            { name: 'device_name', title: 'Device Name' },
            { name: 'name', title: 'Name' },
            { name: 'output', title: 'Output' },
            { name: 'last_value', title: 'Value' },
            { name: 'last_updated', title: 'Last Updated' },
            { name: 'units', title: 'Units' },
            { name: 'spark', title: 'History' },
            { name: 'scada_units', title: 'Scada Units' },
            { name: 'IP_Addy', title: 'IP' },
            { name: 'node_id', title: 'Node Id (idx)' },
            { name: 'device_formatted_id', title: 'Device ID' },
            { name: 'location', title: 'Location' },
            { name: 'Order_Of_Mag', title: 'Order Of Mag' },
            { name: 'Flags', title: 'Flags' },
            { name: 'min_change', title: 'Min Change' },
            { name: 'is_assigned', title: 'Is Assigned' },
            { name: 'is_deleted', title: 'Is Deleted' },
            { name: 'type', title: 'Type' },
            { name: 'comments', title: 'Comments' },
            { name: 'value_format', title: 'Value Format' },
            { name: 'low_alarm', title: 'Low Alarm' },
            { name: 'high_alarm', title: 'High Alarm' },
            { name: 'datapoint_id', title: 'ID' },
            { name: 'site_id', title: 'SiteID' },
            { name: 'uuid', title: 'UUID' },
        ],
        columnWidths: [
            { columnName: 'uuid', width: 300 },
            { columnName: 'site_id', width: 100 },
            { columnName: 'datapoint_id', width: 100 },
            { columnName: 'spark', width: 150 },
            { columnName: 'IP_Addy', width: 300 },
            { columnName: 'node_id', width: 100 },
            { columnName: 'device_name', width: 200 },
            { columnName: 'device_formatted_id', width: 200 },
            { columnName: 'location', width: 200 },
            { columnName: 'last_value', width: 100 },
            { columnName: 'last_updated', width: 250 },
            { columnName: 'scada_units', width: 100 },
            { columnName: 'Order_Of_Mag', width: 100 },
            { columnName: 'Flags', width: 100 },
            { columnName: 'min_change', width: 100 },
            { columnName: 'is_assigned', width: 100 },
            { columnName: 'is_deleted', width: 100 },
            { columnName: 'output', width: 100 },
            { columnName: 'type', width: 100 },
            { columnName: 'name', width: 100 },
            { columnName: 'comments', width: 100 },
            { columnName: 'units', width: 100 },
            { columnName: 'value_format', width: 100 },
            { columnName: 'low_alarm', width: 100 },
            { columnName: 'high_alarm', width: 100 },
        ],
        columnExtensions: ['spark'],
        rightColumns: [],
        hiddenColumnNames: ['created_at', 'updated_at']
    },
    influx: [],
});

const handleRequest = state => {
    return state.set('loading', true).set('error', null).set('influx', [])
}
const handleSuccess = (state, { payload }) => {
    const initialPage = ['before', null];
    const filters = Immutable.asMutable(state.getIn(['table','filters']), { deep: true });
    const filterCreatedAt = filters.find(f => f.columnName === 'created_at');
    const isInitialPage = initialPage.every(p => filterCreatedAt.value.navigation.includes(p));

    if (!isInitialPage && payload.data.length <= 0) {
        App.alert('success', 'No new datapoints found');
        return state;
    }
    return state.set('items', payload.data)
        .setIn(['meta', 'pagination'], payload.meta.pagination)
        .setIn(['table', 'totalPages'], Math.ceil(payload.meta.pagination.count / payload.meta.pagination.limit))
        .update('allItems', (allItems, data) => {
            if (allItems.length === 0) return data;
            return allItems.reduce((items, item, idx) => {
                    const exist = data.findIndex(value => value.datapoint_id === item.datapoint_id);
                    return items.set(idx, {...items[exist], ...data});
                }, allItems);
            }, payload.data)
}
const handleFailure = (state, { payload }) => state.set('error', payload);
const handleFulfill = state => {
    return state.set('loading', false)
}
const handleSorting = (state, { payload }) => state.setIn(['table', 'sorting'], payload);
const handleGrouping = (state, { payload }) => state.setIn(['table', 'grouping'], payload);
const handleSelection = (state, { payload }) => state.setIn(['table', 'selection'], payload);
const handleFilters = (state, { payload }) => {
    let newFilters;
    let { key, value } = payload;
    const filters = Immutable.asMutable(state.getIn(['table','filters']), { deep: true });
    const created_at = filters.find(f => f.columnName === 'created_at');

    // date filters aliases
    if (key === 'navigation') {
        key = 'created_at';
        value = { navigation: value, date_range: created_at.value.date_range };
    } else if(key === 'date_range') {
        key = 'created_at';
        value = { navigation: ['before', null], date_range: value };
    }

    const filter = filters.find(filter => filter.columnName === key);

    if (filter) {
        if (value !== null) { // update filter
            filter.value = value;
            newFilters = filters;
        } else { // remove filter
            newFilters = filters.filter(filter => filter.columnName !== key);
        }
    } else if (value !== null) { // Create filter
        newFilters = filters.concat({
            columnName: key,
            value,
        });
    } else {
        newFilters = filters;
    }

    // if any filters is applied, reset navigation to page 1
    if(key !== 'created_at') {
        created_at.value.navigation = ['before', null];
    }

    return state.setIn(['table', 'filters'], Immutable(newFilters));
};
const handleSearch = (state, { payload }) => state.setIn(['table', 'search'], payload);
const handleExpandedGroups = (state, { payload }) => state.setIn(['table', 'expandedGroups'], payload);
const handleExpandedRowIds = (state, { payload }) => state.setIn(['table', 'expandedRowIds'], payload);

const handlePageSize = (state, { payload: { limit, offset } }) => {
    return state
    .setIn(['meta', 'pagination', 'limit'], limit)
    .setIn(['meta', 'pagination', 'offset'], offset);
}

const handleUpdateItem = (state, { payload })  => {
    const items = state.items.map(item => {
        if(item.datapoint_id === payload.datapoint_id) {
            return item.merge(payload);
        }
        return item;
    });
    return state.set('items', items);
}

const handleProcess = (state, { payload }) => {
    const items = state.getIn(['items']);
    let newItems = state.getIn(['newItems']);

    if (items !== null && items.length > 0) {
        const firstItemTimeStamp = (new Date(items[0].created_at)).getTime();
        if((new Date(payload.created_at)).getTime() > firstItemTimeStamp) {
            const exists = newItems.filter(item => item.datapoint_id === payload.datapoint_id);
            if (exists.length === 1) {
                newItems = newItems.map(item => {
                    if(item.datapoint_id === payload.datapoint_id) {
                        return item.merge(payload);
                    }
                    return item;
                });
            } else {
                newItems = [payload, ...newItems];
            }
        }

        const updateItems = state.items.map(item => {
            if(item.datapoint_id === payload.datapoint_id) {
                return item.merge(payload);
            }
            return item;
        });
        return state.set('items', updateItems).set('newItems', newItems);
    }

    return state.set('newItems', newItems);
}

const handleClearNewItems = state => state.set('newItems', initialState.getIn(['newItems']));
const handleResetFilters = state => state.setIn(['table', 'filters'], initialState.getIn(['table', 'filters']));

const handleLoadingRequestByItem = (state, { payload }) => {
    const loadings = state.getIn(['loadings']);
    const idExist = loadings.filter(id => id === payload);
    if (idExist.length === 0) {
        return state.set('loadings', loadings.concat(payload));
    }
    return state;
}

const handleLoadingFulfillByItem = (state, { payload }) => {
    const loadings = state.getIn(['loadings']);
    const ids = loadings.filter(id => id !== payload);
    return state.set('loadings', ids);
}

const handleSetInflux = (state, { payload }) => {
    if (payload.length === 0) {
        App.alert('success', 'No influx data');
    }
    return state.set('influx', payload)
};

const handleCleanInflux = state => state.set('influx', [])

const handleResetSelection = state => state.setIn(['table', 'selection'], initialState.getIn(['table', 'selection']));

const handlers = {
    [listDatapoints.request.toString()]: handleRequest,
    [listDatapoints.success.toString()]: handleSuccess,
    [listDatapoints.failure.toString()]: handleFailure,
    [listDatapoints.fulfill.toString()]: handleFulfill,
    [processDatapoint.toString()]: handleProcess,
    [cleanInflux.toString()]: handleCleanInflux,
    [tableDatapoint.sorting.toString()]: handleSorting,
    [tableDatapoint.grouping.toString()]: handleGrouping,
    [tableDatapoint.selection.toString()]: handleSelection,
    [tableDatapoint.filters.toString()]: handleFilters,
    [setFiltersFromQueryParamsOnDatapoint.toString()]: handleFilters,
    [tableDatapoint.search.toString()]: handleSearch,
    [tableDatapoint.expandedGroups.toString()]: handleExpandedGroups,
    [tableDatapoint.expandedRowIds.toString()]: handleExpandedRowIds,
    [paginationDatapoint.pageSize.toString()]: handlePageSize,
    [retrieveDatapoint.request.toString()]: handleLoadingRequestByItem,
    [retrieveDatapoint.success.toString()]: handleUpdateItem,
    [retrieveDatapoint.fulfill.toString()]: handleLoadingFulfillByItem,
    [clearNewDatapoints.toString()]: handleClearNewItems,
    [tableDatapoint.resetFilters.toString()]: handleResetFilters,
    [tableDatapoint.resetSelection.toString()]: handleResetSelection,
    [influxDatapoint.request.toString()]: handleRequest,
    [influxDatapoint.success.toString()]: handleSetInflux,
    [influxDatapoint.fulfill.toString()]: handleFulfill,
};

const reducer = (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
