import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from './history';
import activeSite from 'redux/activeSite/reducer';
import sites from 'redux/sites/reducer';
import locations from 'redux/locations/reducer';
import devices from 'redux/devices/reducer';
import scenes from 'redux/scenes/reducer';
import device_groups from 'redux/device_groups/reducer';
import datapoints from 'redux/datapoints/reducer';
import schedules from 'redux/schedules/reducer';
import events from 'redux/events';
import users from 'redux/users/reducer';
import user from 'redux/user/reducer';
import ambrs from 'redux/ambrs/reducer';
import tasks from 'redux/tasks/reducer';
import companies from 'redux/companies/reducer';
import networks from 'redux/networks/reducer';
import nav from 'redux/nav/reducer';
import charts from 'redux/charts/reducer';


import { LOGOUT_USER } from 'redux/auth/actions';

const appReducer = combineReducers({
    // external
    router: connectRouter(history),

    // internal
    activeSite,
    sites,
    locations,
    devices,
    datapoints,
    scenes,
    device_groups,
    schedules,
    events,
    users,
    user,
    ambrs,
    tasks,
    companies,
    networks,
    nav,
    charts,
});

const actions = [LOGOUT_USER];

const rootReducer = (state, action) => {
    if (actions.includes(action.type)) {
        const newState = {};
        return appReducer(newState, action);
    }
    return appReducer(state, action);
};

export default rootReducer;
