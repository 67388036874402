import { createBrowserHistory, createHashHistory } from 'history';

let history;

if (window.cordova) {
    history = createHashHistory({
        basename: '/',
    });
} else {
    history = createBrowserHistory({
        basename: '/',
    });
}


// scrolls to top on every route change
history.listen(() => window.scrollTo(0, 0));

export default history;
