import $ from 'jquery';
import App from 'app/app.js';
import {Scenes} from 'api/amatis/site/scenes/services/scenes';
import Devices from 'api/amatis/site/devices/services/devices';
import Device from 'api/amatis/site/devices/Device';
import Ambrs from 'api/amatis/site/ambr/ambr';
import Amatis from 'api/amatis/amatis';
import Terminal from  'api/amatis/utilities/terminal';
import { decToHex, exportToCsv } from 'classes/methods';
import Scanner from 'classes/scanners';
import SDK from 'api/sdk';
import SDKV3 from 'api/sdkV3';
import SDKDevices from 'api/sdkDevices';

const templateHelpAll =
`&nbsp;&nbsp;<span class='help-text-italic'>{{name}}</span>&nbsp;&nbsp;-&nbsp;&nbsp;<span class='help-text-italic'>{{description}}</span><br/>`;

const templateHelp =
`Name:<br/>&nbsp;&nbsp;<span class='help-text-italic'>{{name}}</span><br/><br/>
Options:<br/>{{options}}<br/>
Description:<br/>&nbsp;&nbsp;<span class='help-text-italic'>{{description}}</span><br/><br/>
Examples:<br/>{{example}}`;

let Commands = {
    help: {
        name: 'help',
        description: 'Prints an example of all commands or of the command specified',
        command_function: function (commandObj) {
            if (commandObj !== undefined && commandObj.defaultOptionParam !== '') {
                let command = commandObj.defaultOptionParam;

                if (command !== undefined && Commands[command]) {
                    const currentCommand = Commands[command];
                    if ( currentCommand.hasOwnProperty('name') &&
                        currentCommand.hasOwnProperty('description') &&
                        currentCommand.hasOwnProperty('options')
                    ) {
                        let print = templateHelp.replace('{{name}}', currentCommand.name);
                        print = print.replace('{{description}}', currentCommand.description);
                        if (currentCommand.options) {
                            let options = '';
                            let example = `&nbsp;&nbsp;<span class='help-text'>${command} </span>`;
                            Object.entries(currentCommand.options).forEach(arg => {
                                const [alias, option] = arg;
                                options += `&nbsp;&nbsp;<span class='help-text'>-${alias}</span>  <span class='help-text-param'>${option.expected_param || ''}</span>     <span class='help-text-desc'>${option.description || ''}</span><br/>`;
                                example += `<span class='help-text-param'> -${alias} ${option.expected_param || ''}</span>`;
                            });

                            print = print.replace('{{options}}', options);
                            print = print.replace('{{example}}', example);
                        }

                        Terminal.print(print, Terminal.commandCount);
                    }
                }
            } else {
                let helpStr = 'Type \'help [command]\' for specific information about command [command] and its options<br><br>';
                for (let command in Commands) {
                    if (command !== 'help') {
                        let print = '';
                        if(Commands[command].hasOwnProperty('description')){
                            print = templateHelpAll.replace('{{name}}', Commands[command].name);
                            print = print.replace('{{description}}', Commands[command].description);
                        }
                        helpStr += `${print}`;
                    }
                }
                Terminal.print(helpStr);
            }
        }
    },
    history: {
        name: 'history',
        description: 'Displays all commands used in recent session',
        options: {
            clear: {
                description: 'clears all command history'
            }
        },
        command_function: function (commandObj) {
            if (commandObj.commandOptions.hasOwnProperty('clear')) {
                Terminal.commandHistory = [];
                return;
            }
            let length = Terminal.commandHistory.length;
            Terminal.print('Command History<br><hr>', Terminal.commandCount);
            for (let i = 0; i < length; i++) {
                Terminal.print(Terminal.commandHistory[i], Terminal.commandCount, true, length);
            }
        }
    },
    mqtt:{
        name: 'mqtt',
        description: 'Connect to an mqtt topic',
        default_option: 'send',
        options: {
            subscribe: {
                description: 'Choose the channel to subscribe to',
                expected_param: '[channel]'
            },
            init: {
                description: 'Run this to get started',
                expected_param: ''
            },
            send: {
                description: 'Send a message (this is the default option',
                expected_param: '[message]'
            },
            username: {
                description: 'Username',
                expected_param: '[channel]'
            },
            password: {
                description: 'Password',
                expected_param: '[password]'
            },
            host: {
                description: 'The host address',
                expected_param: '[host]'
            },
            port: {
                description: 'The port',
                expected_param: '[port]'
            }

        },
        example: '+ mqtt -init -username [username] -password [password] -port [port]',
        command_function: function(commandObj){
            const options = commandObj.commandOptions;

            // if(options.hasOwnProperty('host')
            //     && options.hasOwnProperty('username')
            //     && options.hasOwnProperty('password')
            //     && options.hasOwnProperty('port')
            //     && options.hasOwnProperty('init')
            // ){
            //     Amatis.init(options);
            // }else if (options.hasOwnProperty('init')){
            //     Terminal.print("Init requires -host, -port, -username, -password, -init to be passed");
            // }
            if(options.hasOwnProperty('init')){
                Amatis.init(options);
            }else if (options.hasOwnProperty('init')){
                Terminal.print('Init requires -host, -port, -username, -password, -init to be passed');
            }

            if(options.hasOwnProperty('subscribe')){
                Amatis.subscribe(options.subscribe.param);
            }

            if(options.hasOwnProperty('send')){
                Amatis.mqtt_sendMessage(options.send.param);
            }
        }
    },
    ping: {
        name: 'ping',
        description: 'Pings specified device for its software info',
        default_option: 'd',
        special_option: '@',
        options: {
            d: {
                description: 'ping a single device referenced by name or IP address',
                expected_param: '[device_name/ip]'
            },
            l: {
                description: 'ping all devices in a particular location',
                expected_param: '[location_name]'
            },
            s:{
                description: 'include switches',
            },
            t: {
                description: 'ping all devices of a particular type',
                expected_param: '[device_type]'
            },
            all: {
                description: 'ping all devices in current site'
            },
            a: {
                description: 'ping all devices returned by search'
            },
            g: {
                description: 'filters devices by group',
                expected_param: '[group name]'
            },
            talking: {
                description: 'ping only devices that have talked in the last 20 mins',
            },
            fw: {
                description: 'ping only devices with a specific version of fw'
            }
        },
        example: '+ <span class=\'help-text\'>ping -d</span> <span class=\'help-text-param\'> [Name/IP/MAC]</span> <span class=\'help-text-desc\'> (Ping device by name IP or MAC address)</span> <br>+ <span class=\'help-text\'> ping -l production -t AM-DCDIM</span><span class=\'help-text-desc\'> (Pings all DCDIMs in Production location)</span><br>+<span class=\'help-text\'> ping @ </span><span class=\'help-text-param\'> [location] </span> <span class=\'help-text-desc\'> (Pings all devices in specified location)</span> <br> type \'<span class=\'help-text-italic\'>help ping</span>\' for more info on the ping command',
        command_function: async function (devices, commandObj) {
            let paramObj = {
                count:0,
                multiple: (Object.keys(devices).length > 1 ? true : false),
                placement: Terminal.commandCount,
            };
            let startTime = new Date().getTime();
            for (let id in devices) {
                let response = await devices[id].get2('sw_info', devices[id].ip_address);
                paramObj.count++;
                Terminal.write(response, devices[id], paramObj);
            }
            let endTime = new Date().getTime();
            Terminal.print(`Total Time: ${(endTime - startTime) / 1000}s`);
        }
    },
    addswitch: {
        name: 'addswitch',
        description: 'add switch to site with json',
        default_option: 'json',
        options: {
            json: {
                description: 'JSON object of the expected switch',
                expected_param: '{v1:{mac:5410ec9fd774,type:SWITCH-B,hw_ver:1.8,sw_ver:07.00.02,sw_build:Apr 10 19 16:43:30 ch.26,chan:26,pan_id:abcd,mfg_date:8/18/2015}}'
            },

        },
        example: '+ <span class=\'help-text\'>ping -d</span> <span class=\'help-text-param\'> [Name/IP/MAC]</span> <span class=\'help-text-desc\'> (Ping device by name IP or MAC address)</span> <br>+ <span class=\'help-text\'> ping -l production -t AM-DCDIM</span><span class=\'help-text-desc\'> (Pings all DCDIMs in Production location)</span><br>+<span class=\'help-text\'> ping @ </span><span class=\'help-text-param\'> [location] </span> <span class=\'help-text-desc\'> (Pings all devices in specified location)</span> <br> type \'<span class=\'help-text-italic\'>help ping</span>\' for more info on the ping command',
        command_function: async function (commandObj) {

            if(commandObj.commandOptions.hasOwnProperty('json')){
                let json = commandObj.commandOptions['json'].param;
                Scanner.success(json);
            }
        }
    },
    clean: { //clean device internal actions
        name: 'clean',
        description: 'Puts internal linkIDs into the devices action table in the cloud and removes any bad internal actions',
        default_option: 'd',
        special_option: '@',
        options: {
            d: {
                description: 'clean a single device referenced by name or IP address',
                expected_param: '[device_name/ip]'
            },
            l: {
                description: 'clean all devices in a particular location',
                expected_param: '[location_name]'
            },
            t: {
                description: 'clean all devices of a particular type',
                expected_param: '[device_type]'
            },
            all: {
                description: 'clean all devices in current site'
            },
            a: {
                description: 'clean all devices returned by search'
            },
            g: {
                description: 'filters devices by group',
                expected_param: '[group name]'
            },
            save: {
                description: 'applies device changes by saving to cloud'
            },
            s:{
                description: 'include switches',
            },

        },
        example: '+ <span class=\'help-text\'>ping -d</span> <span class=\'help-text-param\'> [Name/IP/MAC]</span> <span class=\'help-text-desc\'> (Ping device by name IP or MAC address)</span> <br>+ <span class=\'help-text\'> ping -l production -t AM-DCDIM</span><span class=\'help-text-desc\'> (Pings all DCDIMs in Production location)</span><br>+<span class=\'help-text\'> ping @ </span><span class=\'help-text-param\'> [location] </span> <span class=\'help-text-desc\'> (Pings all devices in specified location)</span> <br> type \'<span class=\'help-text-italic\'>help ping</span>\' for more info on the ping command',
        command_function: async function (devices, commandObj) {
            await Scenes.getSceneTable({ ip_address: Object.keys(devices).map(key => devices[key].IP) })
            let paramObj = {
                count:0,
                multiple: (Object.keys(devices).length > 1 ? true : false),
                placement: Terminal.commandCount,
            };

            let doSave = commandObj.commandOptions.hasOwnProperty('save');

            //write actions to cloud and then sync device
            let save = async () => {
                if(App.Site.root.externalActionsToSave.length > 0){
                    let response = await App.Site.root.post({'data':'['+App.Site.root.externalActionsToSave+']'}, false,  'site_table_edit', 'v2');
                    Devices.parseActions(response);
                    Terminal.print('Actions saved to the cloud successfully. Sync devices from the sync tab to update their action tables.');
                    App.Site.root.externalActionsToSave = '';
                }
            }

            for (let id in devices) {
                paramObj.count ++;
                Terminal.write('Cleaning device', devices[id], paramObj);
                Devices.internalActionsCleanup(devices[id], doSave);
            }

            if(doSave === true){
                save();
            }
        }
    },

    duplicatecleanup: {
        name: 'duplicatecleanup',
        description: 'Show count of duplicate scene',
        options: {
            save:{
                description: 'passing save will delete the duplicates and upload them to the cloud'
            },
        },
        command_function: async function (commandObj) {
            const hasCommand = commandObj.commandOptions.hasOwnProperty('save');
            const doDelete = (hasCommand);
            const result = Devices.duplicateCleanup(doDelete);

            for (let item in result) {
                Terminal.print(`${result[item]} <br />`);
            }

            if (hasCommand) {
                //write actions to cloud and then sync device
                if(App.Site.root.externalActionsToSave.length > 0){
                    let response = await App.Site.root.post({'data':'['+App.Site.root.externalActionsToSave+']'}, false,  'site_table_edit', 'v2');
                    Devices.parseActions(response);
                    Terminal.print('Actions saved to the cloud successfully. Sync devices from the sync tab to update their action tables.');
                    App.Site.root.externalActionsToSave = '';
                }
            }
        }
    },

    ghosts: { //clean device internal actions
        name: 'ghosts',
        description: 'Display a devices actions if there is no scene associated with it',
        default_option: 'd',
        special_option: '@',
        options: {
            d: {
                description: 'fix a single device referenced by name or IP address',
                expected_param: '[device_name/ip]'
            },
            l: {
                description: 'fix all devices in a particular location',
                expected_param: '[location_name]'
            },
            t: {
                description: 'fix all devices of a particular type',
                expected_param: '[device_type]'
            },
            all: {
                description: 'fix all devices in current site'
            },
            a: {
                description: 'fix all devices returned by search'
            },
            g: {
                description: 'filters devices by group',
                expected_param: '[group name]'
            },
            save: {
                description: 'applies device changes by saving to cloud'
            },
            skip:{
                descript: 'skip internal action check'
            },
            purge: {
                description: 'Purge all found ghost actions from devices (this is a permanent and potentially dangerous action)'
            },
            id: {
                description: 'option to combined with -purge to only remove action by uuid (copy from cleans output)',
                expected_param: '[action uuid]'
            },
            linkID: {
                description: 'option to combined with -purge to only remove action by linkID (copy from cleans output)',
                expected_param: '[linkID]'
            },
            dups: {
                description: 'check for duplicate actions, option combined with -save to remove duplicate actions',
                expected_param: '[linkID]'
            },
        },
        example: '+ <span class=\'help-text\'>ping -d</span> <span class=\'help-text-param\'> [Name/IP/MAC]</span> <span class=\'help-text-desc\'> (Ping device by name IP or MAC address)</span> <br>+ <span class=\'help-text\'> ping -l production -t AM-DCDIM</span><span class=\'help-text-desc\'> (Pings all DCDIMs in Production location)</span><br>+<span class=\'help-text\'> ping @ </span><span class=\'help-text-param\'> [location] </span> <span class=\'help-text-desc\'> (Pings all devices in specified location)</span> <br> type \'<span class=\'help-text-italic\'>help ping</span>\' for more info on the ping command',
        command_function: async function (devices, commandObj) {
            await Scenes.getSceneTable({ ip_address: Object.keys(devices).map(key => devices[key].IP) })
            let paramObj = {
                count:0,
                multiple: (Object.keys(devices).length > 1 ? true : false),
                placement: Terminal.commandCount,
            };
            let cleanGhosts = true;
            let doPurge = false;
            let doID = false;
            let doLinkID = false;

            let doSave = commandObj.commandOptions.hasOwnProperty('save');

            if(commandObj.commandOptions.hasOwnProperty('purge')){
                if(!doSave){
                    Terminal.print('Running command with -purge option requires -save be set for safety purposes.');
                }

                doPurge = true;
            }
            if(commandObj.commandOptions.hasOwnProperty('id')){
                if(!doPurge){
                    Terminal.print('-purge option not set, action by id will not be removed');
                }
                doID = commandObj.commandOptions.id.param;
            }
            if(commandObj.commandOptions.hasOwnProperty('linkID')){
                if(!doPurge){
                    Terminal.print('-purge option not set, action by linkID will not be removed');
                }
                doLinkID = commandObj.commandOptions.linkID.param;
            }
            //write actions to cloud and then sync device
            let save = async () => {
                if(App.Site.root.externalActionsToSave.length > 0){
                    let response = await App.Site.root.post({'data':'['+App.Site.root.externalActionsToSave+']'}, false,  'site_table_edit', 'v2');
                    Devices.parseActions(response);
                    Terminal.print('Actions saved to the cloud successfully. Sync devices from the sync tab to update their action tables.');
                    App.Site.root.externalActionsToSave = '';
                }
            }
            //print out ghost actions for user to see
            let printGhostActions = (actions, device, type="Ghost Actions") => {
                let printout = '';
                for(let linkID in actions){
                    if((doLinkID !== false && doLinkID === linkID) || doLinkID === false){
                        printout += '<br><ul class="ghost-action-group">'+type+' of Scene LinkID '+linkID+': <br>';
                        let count = 1;
                        for(let id in actions[linkID]){
                            if((doID !== false && id === doID) || doID === false){
                                let activeAction = actions[linkID][id];
                                let pretty = Scenes.displayPretty(activeAction.name, activeAction.var_1, device.partType);
                                printout += `<li class='ghost-action-item'>${count}:`;
                                printout += ` <span class='ghost-action-info'> Name: ${activeAction.name}, Var1: ${activeAction.var_1}, linkID: ${activeAction.link_id}, uuid: ${id}</span><br>`;
                                printout += `<span class='ghost-action-pretty'>(${pretty})</span></li>`;
                            }
                            count++;
                        }
                        printout += '</ul>';
                    }
                }
                Terminal.print(printout);
            }
            //set all ghost actions found to be deleted
            let purge = (ghostActions, device) => {
                let found = false;
                for(let linkID in ghostActions){
                    if((doLinkID !== false && doLinkID === linkID) || doLinkID === false){
                        for(let id in ghostActions[linkID]){
                            let action = ghostActions[linkID][id];
                            if((doID !== false && id === doID) || doID === false){
                                found = true;
                                action.name = 'deleted';
                                action.table_row = '65535';
                                App.Site.root.insertActionToSave(action);
                            }
                        }
                    }
                }
                if(found === false){
                    Terminal.writeError("No ghost actions found matching id or linkID provided for this device", device);
                }
            }

            /*if(!commandObj.commandOptions.hasOwnProperty('skip')){
                Devices.checkInternalActions(devices);
                Terminal.print('Internal actions checked and cleaned if missing');
            }*/
            for (let id in devices) {
                paramObj.count ++;
                Terminal.write('Fixing device actions', devices[id], paramObj);

                if(cleanGhosts){
                    Terminal.print('Checking for ghost actions...');
                    let ghostActions = Devices.findGhostActions(devices[id]);
                    if(ghostActions !== false){
                        printGhostActions(ghostActions, devices[id]);
                        if(doPurge){
                            Terminal.print('Purging ghost actions.')
                            purge(ghostActions, devices[id]);
                        }
                    }else{
                        Terminal.write('No ghost actions found!', devices[id], paramObj);
                    }
                }

            }
            if(doSave) {
                save();
            }else{
                Terminal.print('<br><br>Actions were not saved to cloud, run clean with -save flag to do save them.');
            }
        }
    },
    getdata: {
        name: 'getData',
        description: 'Retrieves data dictionary from specified device',
        default_option: 'f',
        special_option: '@',
        options: {
            t: {
                description: 'search for devices by type',
                expected_param: '[type]'
            },
            s:{
                description: 'include switches',
            },
            f: {
                description: 'specify force value',
                expected_param: '[force_value]'
            },
            v: {
                description: 'specify force value',
                expected_param: '[force_value]'
            },
            d: {
                description: 'specific device reference by name, IP address or MAC address',
                expected_param: '[device]'
            },
            l: {
                description: 'search for device within location',
                expected_param: '[location]'
            },
            raw: {

                description: 'request getData to print raw data returned'

            },
            start: {

                description: 'designates start idx for getData function',
                expected_param: '[number]'

            },
            stop: {
                description: 'designates stop idx for getData function',
                expected_param: '[number]'
            },
            all: {
                description: 'getdata from all devices in current site'
            },

            a: {
                description: 'getdata from all devices returned by search'
            },
            g: {
                description: 'filters devices by group',
                expected_param: '[group name]'
            },
            u: {
                description: 'Send device data through the dataworker to be updated'
            }
        },
        example: '+ <span class=\'help-text\'>getData -d</span><span class=\'help-text-param\'> [Name/IP/MAC]</span><span class=\'help-text-desc\'> (getdata for a specifed device) </span> <br>+<span class=\'help-text\'> getData -d</span><span class=\'help-text-param\'> [device]</span><span class=\'help-text\'> -start 1 -stop 3</span><span class=\'help-text-desc\'> (gets data points from idx 1 to 3)</span><br>+<span class=\'help-text\'> getData @ </span><span class=\'help-text-param\'>[location]</span> <span class=\'help-text-desc\'>(get data for all devices in specified location)</span><br> type \'<span class=\'help-text-italic\'>help getData</span>\' for more info on the getData command',
        command_function: async function (devices, commandObj) {

            let commandData = {
                "force": 5,
                "start_idx":false,
                "stop_idx":false,
            }
            let showRaw = false;

            if (commandObj.commandOptions.hasOwnProperty('u')) {
                commandData.update_db = '1';
            }
            if (commandObj.commandOptions.hasOwnProperty('raw')) {
                showRaw = true;
            }
            if (commandObj.commandOptions.hasOwnProperty('start')) {
                commandData.start_idx = commandObj.commandOptions.start.param;
            }
            if (commandObj.commandOptions.hasOwnProperty('stop')) {
                commandData.stop_idx = commandObj.commandOptions.stop.param;
            } else if (commandData.start !== false) {
                commandData.stop_idx = commandData.start_idx;
            }

            if (commandObj.commandOptions.hasOwnProperty('f')) {
                commandData.force = commandObj.commandOptions.f.param.trim();
            } else if (commandObj.commandOptions.hasOwnProperty('v')) {
                commandData.force = commandObj.commandOptions.v.param.trim();
            }

            const endpoint = `data?${Object.keys(commandData).map(key => commandData[key] ? key + '=' + commandData[key] + '&' : '').join('')}`
            let paramObj = {
                count:0,
                multiple: (Object.keys(devices).length > 1 ? true : false),
                placement: Terminal.commandCount,
            };
            let startTime = new Date().getTime();
            for (let id in devices) {
                paramObj.count++;
                try {
                    const response = await devices[id].get2(endpoint, devices[id].ip_address);
                    if(response.hasOwnProperty('errors') && response.errors.length > 0){
                        Terminal.writeError(response.errors[0], devices[id], paramObj);
                    }else if(!showRaw){
                        const parseData = Devices.parseGetData(devices[id], response, commandData.force, true, false);
                        Terminal.write(parseData, devices[id], paramObj);
                    }else{
                        Terminal.write(response, devices[id], paramObj);
                    }
                    Terminal.scrolled = false;
                    Terminal.updateScroll();
                } catch (error) {
                    Terminal.writeError(error, devices[id]);
                }
            }
            let endTime = new Date().getTime();
            Terminal.print(`Total Time: ${(endTime - startTime) / 1000}s`);
        }
    },
    reset: {
        name: 'reset',
        description: 'Factory reset a device',
        default_option: 'd',
        special_option: '@',
        options: {
            d: {
                description: 'specify device',
                expected_param: '[device]'
            },
            t: {
                description: 'search by device type',
                expected_param: '[device type]'
            },
            l: {
                description: 'search within location',
                expected_param: '[location]'
            },
        },

        example: '+<span class=\'help-text\'> reset last4</span><br> type \'<span class=\'help-text-italic\'>help set</span>\' for more info on the reset command',
        command_function: async function (devices, commandObj) {
            let name_or_id = '50';
            let value = '998';
            let site_id = App.activeSiteID;

            if (Object.keys(devices).length  > 1) {
                if (!confirm('This action will reset idx or name: ' + name_or_id + ' to value: ' + value + ' for ' + Object.keys(devices).length + ' device(s), are you sure you want to continue?')) { // eslint-disable-line
                    return false;
                }
            }

            Terminal.print('<span>Setting idx or name: ' + name_or_id + ' to value: ' + value + ' for ' + Object.keys(devices).length + ' device(s) </span><br>');

            const OPTIONS = { requestBody: { name_or_id, value, site_id } };
            let paramObj = {
                count:0,
                multiple: (Object.keys(devices).length > 1 ? true : false),
                placement: Terminal.commandCount,
            };
            const apiDevices = await SDKDevices.init();
            let startTime = new Date().getTime();
            for (let id in devices) {
                const PARAMS = { device_id: devices[id].ip_address };
                try {
                    const { body: {data} } = await apiDevices.devices.writeSetpoint(PARAMS, OPTIONS);
                    paramObj.count++;
                    //Going to handle this like this for now until we port it all to the sdk
                    if(data.length > 0 ) {
                        Terminal.writeJson(data[0], devices[id], paramObj);
                    } else {
                        Terminal.write("Request timed out. Check that device is plugged in.", devices[id], paramObj);
                    }
                } catch (error) {
                    console.log("ERROR SET ", error)
                    paramObj.count++;
                    Terminal.write("call to write setpoint timed out. Check that device is plugged in and communicating then try again.", devices[id], paramObj);
                }
            }
            let endTime = new Date().getTime();
            Terminal.print(`Total Time: ${(endTime - startTime) / 1000}s`);
        },
    },
    set: {
        name: 'set',
        description: 'Sets data points for device(s) specified by command',
        default_option: 'd',
        special_option: '@',
        options: {
            d: {
                description: 'specify device',
                expected_param: '[device]'
            },
            s:{
                description: 'include switches',
            },
            t: {
                description: 'search by device type',
                expected_param: '[device type]'
            },
            l: {
                description: 'search within location',
                expected_param: '[location]'
            },
            a: {
                description: 'force datapoint assignment to all matching devices without confirmation'

            },
            raw: {
                description: 'Causes set to only print raw data returned from server instead of a table containing data points'
            },
            id: {
                description: 'datapoint to set',
                expected_param: '[number]'
            },
            idx: {
                description: 'datapoint to set',
                expected_param: '[number]'
            },
            i: {
                description: 'datapoint to set',
                expected_param: '[number]'
            },
            val: {
                description: 'value to be set to specified id/idx',
                expected_param: '[number]'
            },
            v: {
                description: 'value to be set to specified id/idx',
                expected_param: '[number]'
            },
            all: {
                description: 'set all in current site'
            },
            g: {
                description: 'filters devices by group',
                expected_param: '[group name]'
            },
            name : {
                description: 'name of datapoint to set'
            },
            n: {
                description : 'name of datapoint to set'
            },
            fw: {
                description: 'set dd values only for devices with a specific version of fw'
            },
            m: {
                description: 'use multicast address'
            },
            mcast: {
                description: 'use multicast address'
            },
            multicast: {
                description: 'use multicast address'
            },
            async: {
                description: 'use setpoint_async instead of'
            }
        },

        example: '+<span class=\'help-text\'> set overhead repair -idx 7 -val 100</span><br> type \'<span class=\'help-text-italic\'>help set</span>\' for more info on the set command',
        command_function: async function (devices, commandObj) {
            let name_or_id;
            let value;
            let fromRecurse = false;
            let recurseCount = null;
            let firstRecurse = false;
            let multicastIpAddress = null;
            //let commandStr = commandObj.
            if (commandObj.hasOwnProperty('recurseCount')) {
                if (commandObj.recurseCount !== null) {
                    fromRecurse = true;
                    recurseCount = commandObj.recurseCount;
                    if (recurseCount === commandObj.idxLength) {
                        firstRecurse = true;
                    }
                }
                if (fromRecurse === true && firstRecurse === true) {

                    Terminal.print('setting each idx: ' + commandObj.recurseData.originalIdx + ' to value(s): ' + commandObj.recurseData.originalVal + ' for ' + Object.keys(devices).length  + ' device(s)...<br><hr>', Terminal.commandCount);

                }
            }

            if (commandObj.commandOptions.hasOwnProperty('val')) { //if -l was an option set in the command
                //we have to search a specific location for the device
                value = commandObj.commandOptions.val.param;
            } else if (commandObj.commandOptions.hasOwnProperty('v')) { //if -l was an option set in the command
                //we have to search a specific location for the device
                value = commandObj.commandOptions.v.param;
            } else {
                Terminal.print('<span class=\'red\'>No value was specified, \'set\' requires a value be designated for -val\'.</span><br> Type \'help set\' for more information on the set command.', Terminal.commandCount);
                return;
            }

            let async = false;

            if (commandObj.commandOptions.hasOwnProperty('async')) {
                async = true;
            } 

            if (commandObj.commandOptions.hasOwnProperty('id')) {
                name_or_id = commandObj.commandOptions.id.param;
            } else if (commandObj.commandOptions.hasOwnProperty('idx')) {
                name_or_id = commandObj.commandOptions.idx.param;
            } else if (commandObj.commandOptions.hasOwnProperty('i')) {
                name_or_id = commandObj.commandOptions.i.param;
            } else if (commandObj.commandOptions.hasOwnProperty('name')) {
                name_or_id = commandObj.commandOptions.name.param;
            } else if (commandObj.commandOptions.hasOwnProperty('n')) {
                name_or_id = commandObj.commandOptions.n.param;
            } else {
                Terminal.print('<span class=\'red\'>No idx or name was specified, \'set\' requires a value be designated for either \'-i\' or \'-id\' or \'-idx\' or \'-name\' or \'-n\'\'.</span><br> Type \'help set\' for more information on the set command.', Terminal.commandCount);
                return;
            }

            if (commandObj.commandOptions.hasOwnProperty('m') || commandObj.commandOptions.hasOwnProperty('mcast') || commandObj.commandOptions.hasOwnProperty('multicast')) {
                multicastIpAddress = "ff1e::89:abcd";
            }

            let paramObj = {
                count:0,
                multiple: (Object.keys(devices).length > 1 ? true : false),
                placement: Terminal.commandCount,
            };

            if (Object.keys(devices).length  > 1) {
                if (!confirm('This action will set idx or name: ' + name_or_id + ' to value: ' + value + ' for ' + Object.keys(devices).length + ' device(s), are you sure you want to continue?')) { // eslint-disable-line
                    return false;
                }
            }

            Terminal.print('<span>Setting idx or name: ' + name_or_id + ' to value: ' + value + ' for ' + Object.keys(devices).length + ' device(s) </span><br>');


            const OPTIONS = { requestBody: { name_or_id, value, site_id: App.activeSiteID } };

            const apiDevices = await SDKDevices.init();
            let startTime = new Date().getTime();
            for (let id in devices) {
                const PARAMS = { device_id: multicastIpAddress === null ? devices[id].ip_address : multicastIpAddress };

                try {

                    let data = [];

                    if (async) {
                        const { body: {data: writeSetpointAsyncResponse} } = await apiDevices.devices.writeSetpointAsync(PARAMS, OPTIONS);
                        data = writeSetpointAsyncResponse;
                    } else {
                        const { body: {data: writeSetpointResponse} } = await apiDevices.devices.writeSetpoint(PARAMS, OPTIONS);
                        data = writeSetpointResponse;
                    }

                    paramObj.count++;
                    //Going to handle this like this for now until we port it all to the sdk
                    if(data.length > 0 ) {
                        Terminal.writeJson(data[0], devices[id], paramObj);
                    } else if(data.hasOwnProperty('data')){
                        Terminal.write(data.data, devices[id], paramObj);
                    } else {
                        Terminal.write("Request timed out. Check that device is plugged in.", devices[id], paramObj);
                    }
                } catch (error) {
                    console.log("ERROR SET ", error)
                    paramObj.count++;
                    Terminal.write("call to write setpoint timed out. Check that device is plugged in and communicating then try again.", devices[id], paramObj);
                }
            }
            let endTime = new Date().getTime();
            Terminal.print(`Total Time: ${(endTime - startTime) / 1000}s`);
        }

    },
    keys: {
        name: 'keys',
        description: 'Get all action functions for specified device(s)',
        default_option: 'd',
        special_option: '@',
        options: {
            l: {
                description: 'search for device within location',
                expected_param: '[location]'
            },
            t: {
                description: 'search for [devices] by type',
                expected_param: '[device type]'
            },
            d: {
                description: 'search for [device] ',
                expected_param: '[device]'
            },
            s:{
                description: 'include switches',
            },

            a: {
                description: 'search for all devices returned by search'
            },

            all: {
                description: 'gets keys for all devices in current site'
            },
            g: {
                description: 'filters devices by group',
                expected_param: '[group name]'
            }
        },

        example: '+ <span class=\'help-text\'>keys @ kitchen</span> <br>+ <span class=\'help-text\'>keys -t dimmer -l bathroom</span> <br>+ <span class=\'help-text\'>keys kitchen light</span> <br> type \'<span class=\'help-text-italic\'>help keys</span>\' for more info on the keys command',
        command_function: async function (devices) {

            let paramObj = {
                count:0,
                multiple: (Object.keys(devices).length > 1 ? true : false),
                placement: Terminal.commandCount,
            };
            let startTime = new Date().getTime();
            for (let id in devices) {
                const response = await devices[id].get2("function_list", devices[id].ip_address, 'devices', 'v1', {'update_db': 1});
                paramObj.count ++;
                Terminal.write(response, devices[id], paramObj);
            }
            let endTime = new Date().getTime();
            Terminal.print(`Total Time: ${(endTime - startTime) / 1000}s`);
        }
    },
    upload: {
        name: 'upload',
        description: 'Get the action table from the device, and store it in the cloud. \'keys [device]\' must be done at least once before this)',
        default_option: 'd',
        special_option: '@',
        options: {
            l: {
                description: 'query [device(s)] within location',
                expected_param: '[location]'
            },
            d: {
                description: 'designates device to query',
                expected_param: '[device]'
            },
            t: {
                description: 'query device(s) by type',
                expected_param: '[device type]'
            },

            a: {
                description: 'upload all devices returned by search'
            },
            s:{
                description: 'include switches',
            },

            all: {
                description: 'upload all devices in current site'
            },
            g: {
                description: 'filters devices by group',
                expected_param: '[group name]'
            }

        },

        example: '+ <span class=\'help-text\'>upload</span><span class=\'help-text-param\'> [device]</span> <br>+<span class=\'help-text\'> upload @ </span><span class=\'help-text-param\'>[location]</span><br>+ <span class=\'help-text\'>upload -t</span><span class=\'help-text-param\'> [deviceType]</span><span class=\'help-text\'> -l</span><span class=\'help-text-param\'> [location] </span><br> type \'<span class=\'help-text-italic\'>help upload</span>\' for more info on the upload command',
        command_function: async function (devices) {

            let paramObj = {
                count:0,
                multiple: (Object.keys(devices).length > 1 ? true : false),
                placement: Terminal.commandCount,
            };
            let startTime = new Date().getTime();
            for (let id in devices) {
                const response = await devices[id].get2("table_list", devices[id].ip_address, 'devices', 'v1', {'update_db': 1})
                paramObj.count ++;
                Terminal.write(response, devices[id], paramObj);
            }
            let endTime = new Date().getTime();
            Terminal.print(`Total Time: ${(endTime - startTime) / 1000}s`);
        }
    },
    save: {
        name: 'save',
        description: 'device(s) specified by command will have their action tables synced with the cloud',
        default_option: 'd',
        special_option: '@',
        options: {
            l: {
                description: 'query [device(s)] within location',
                expected_param: '[location]'
            },
            d: {
                description: 'designates device to query',
                expected_param: '[device]'
            },
            t: {
                description: 'query device(s) by type',
                expected_param: '[device type]'
            },
            all: {
                description: 'save all devices in current site'
            },
            s:{
                description: 'include switches',
            },

            a: {
                description: 'save all devices returned by search'
            },
            g: {
                description: 'filters devices by group',
                expected_param: '[group name]'
            },
            talking: {
                description: 'sync only devices that have talked in the last 20 mins',
            },
            f: {
                description: 'added force param to query'
            },
        },

        example: '+ <span class=\'help-text\'>save</span> <span class=\'help-text-param\'>[device]</span><br>+ <span class=\'help-text\'>save @</span> <span class=\'help-text-param\'>[location]</span><br>+ <span class=\'help-text\'>save -t</span> <span class=\'help-text-param\'>[deviceType] -l [location]</span> <br>type \'<span class=\'help-text-italic\'>help save</span>\' for more info on the save command',
        command_function: async function (devices, commandObj) {
            let paramObj = {
                count:0,
                multiple: (Object.keys(devices).length > 1 ? true : false),
                placement: Terminal.commandCount,
            };
            let startTime = new Date().getTime();

            const forceParam = !!commandObj.commandOptions.hasOwnProperty('f');

            if (forceParam) {
                Terminal.print('<span class=\'yellow\'>Skipping table comparison and writing entire action table</span>');
            }

            for (let id in devices) {
                try {
                    const endpoint = forceParam ? 'save_table_list?force=1' : 'save_table_list';
                    const response = await devices[id].get2(endpoint, devices[id].ip_address)
                    paramObj.count ++;
                    Terminal.write(response, devices[id], paramObj);
                } catch (error) {
                    Terminal.write(error.responseJSON, devices[id], paramObj);
                }
            }
            let endTime = new Date().getTime();
            Terminal.print(`Total Time: ${(endTime - startTime) / 1000}s`);
        }
    },

    find: {
        name: 'find',
        description: 'search for device by specified parameter',
        default_option: 'd',
        special_option: '@',
        options: {
            l: {
                description: 'find [device] within location',
                expected_param: '[location]'
            },
            d: {
                description: 'find [device]',
                expected_param: '[device]'
            },
            t: {
                description: 'find [device] by [type]',
                expected_param: '[type]'
            },
            all: {
                description: 'find all [device] in current site',
            },

            a: {
                description: 'find all devices returned by search'
            },
            g: {
                description: 'filters devices by group',
                expected_param: '[group name]'
            }
        },

        example: '+ <span class=\'help-text\'>find @ kitchen</span> <br>+ <span class=\'help-text\'> find overhead repair </span><br>+ <span class=\'help-text\'>find -t AMDCDIM -l bathroom</span> <br> type \'<span class=\'help-text-italic\'>help find</span>\' for more info on the find command',
        command_function: async function (devices, commandObj) {
            let rootLoc, deviceLoc, num;
            let paramObj = {
                count:0,
                multiple: (Object.keys(devices).length > 1 ? true : false),
                placement: Terminal.commandCount,
            };
            let count = 1;
            //loop through all devices, get their locations, print data and location link
            for (let id in devices) {
                paramObj.count = count;
                let device = devices[id];
                rootLoc = App.Site.root;
                deviceLoc = device.location;
                if (deviceLoc === false) {
                    Terminal.print('<span class=\'green\'>' + device.name + '</span>  <span class=\'device-info\'>(' + device.ip_address.substr(-4) + ') @ </span><a href=\'#\' id=\'terminal-loc-' + rootLoc.ID + '\' class=\'terminal-to-location\'>' + rootLoc.name + '</a>',paramObj);
                } else if(deviceLoc.parent.id === rootLoc.id){
                    Terminal.print('<span class=\'green\'>' + device.name + '</span>  <span class=\'device-info\'>(' + device.ip_address.substr(-4) + ') @ </span><a href=\'#\' id=\'terminal-loc-' + deviceLoc.ID + '\' class=\'terminal-to-location\'>' + deviceLoc.name + '</a>', paramObj);
                }else{
                    let locString = '<span class=\'green\'>' + device.name + '</span>  <span class=\'device-info-' + num + '\'>(' + device.ip_address + ') @ </span> <div class=\'loc-list-' + num + '\'>';
                    const pathStr = deviceLoc.pathStr.split(',');
                    let paths = [];
                    pathStr.forEach((id) => {
                        let loc = App.Site.locations[id];
                        if (id){
                            if (loc.name !== '') {
                                paths.push(`/${loc.name}`);
                            }
                        }
                    });
                    locString += `<a href=/site/${device.site_id}/location/${device.location_id} id='terminal-loc-${device.location_id}' class='terminal-to-location'>${paths.toString().replaceAll(',','')}</a>`;
                    locString+='</div>';
                    Terminal.print(locString, paramObj);
                }
                count++;
            }
        }

    },
    stuset: {
        name: 'stuset',
        description: 'Reset all data for the current active site',
        default_option: '',
        options: {
            o: {
                description: 'Allow user to run stuset command without confirmation message',
                expected_param: '1'
            },
            override: {
                description: 'Allow user to run stuset command without confirmation message',
                expected_param: '1'
            },
        },
        example: '+ stuset!',
        command_function: async function (commandObj) {
            let showConfirmMessage = true;
            if (commandObj.commandOptions.hasOwnProperty('o')) {
                showConfirmMessage = false;
            }
            if (commandObj.commandOptions.hasOwnProperty('override')) {
                showConfirmMessage = false;
            }
            if (showConfirmMessage) {
                if (!confirm(`You are about to do something very risky, are you sure you want to reset the whole site: ${App.activeSiteID}?`)) return false;
            }

            const api = await SDKV3.init();
            const { body: { data, meta: { message } } } = await api.sites.stusetSite({ id: App.activeSiteID });
            Terminal.print(`<span class="green">${data}</span><br>`);
            Terminal.print(`Response: <span>${JSON.stringify(message, undefined, 4)}</span><br>`);
            Terminal.print('Reloading page in 5 seconds...');
            setTimeout(() => {
               window.location.reload();
            }, 5000);
        }
    },
    mcast_linkid: {
        name: 'mcast_linkid',
        description: 'Multicast any link ID do the site',
        default_option: '',
        options: {
            linkid: {
                description: 'Link ID that youd like to be mcasted through site',
                expected_param: '[number]'
            }
        },
        example: 'Mcast being sent',
        command_function: function (commandObj) {
            let linkID;
            if (commandObj.defaultOptionParam !== '') { //check if default param is set, if so set device ident
                linkID = parseInt(commandObj.defaultOptionParam);
            } else if (commandObj.commandOptions.hasOwnProperty('linkid')) { //else get -d
                linkID = parseInt(commandObj.commandOptions.linkid.param);
            }
            if(linkID > 0 && linkID <= 65535){
                Terminal.mcast_linkid(linkID.toString());
            }else{
                //print error to terminal
                Terminal.print(`<span class='red'>Not a valid linkid </span>` );
            }
        }
    },
    listxa:{
        name:'listxa',
        default_option:'',
        options: {},
        description:'Print list of Cross AMBR linkIDs on this site',
        command_function: async function(commandObj=false){
            Terminal.print('Getting cross-AMBR scenes (XAs) list...<br><br>');

            let { link_list, locationsWithXAArray, errors } = await Devices.lookForXas();

            Terminal.print(`Found ${link_list.length} XA scenes across ${locationsWithXAArray.length} locations:`);
            
            Terminal.print(`<span style="display:block">${link_list.join('-')}</span>`);
            
            let locationPaths = [];
            for (let locationId of locationsWithXAArray) {
                const activeLocation = App.Site.locations[locationId];
                if (activeLocation){
                    let pathStr = `<a
                    href=/site/${activeLocation.site_id}/location/${activeLocation.id}
                    id='terminal-loc-${activeLocation.id}'
                    class='terminal-to-location'
                    >
                    ${activeLocation.breadCrumbs}
                    </a>`;
                    locationPaths.push(pathStr);
                }
            }
            
            Terminal.print(`<span style="display:block">${locationPaths.join('<br>')}</span>`);

            if (errors?.length) {
                Terminal.print('<span class="red">Something went wrong saving the scenes to AMBR: </span>');
                errors.forEach(item => Terminal.print('<span class="red"><li>' + item.toString() + '</li></span>'))
            }
        }
    },
    validate:{
        name:'validate',
        default_option:'l',
        options: {
            l: 'Run validate by a specific location',
            e: 'Export issues to a CSV file'
        },
        description:'Runs validation test: QC on the site to look for potential issues',
        command_function: async function(commandObj=false){
            let matchLocation = {};
            let allValidated = true;
            if(commandObj.commandOptions.hasOwnProperty('l')){
                matchLocation = commandObj.commandOptions.l.param;
            }else if(commandObj.defaultOptionParam !== ''){
                matchLocation = commandObj.defaultOptionParam;
            } else {
                Terminal.print("Something went wrong, no location was found or specified.");
                return;
            }
            const locationsMatched = Terminal.getLocationByName(matchLocation);
            if(Object.keys(locationsMatched).length === 0){
                Terminal.print(`<span class="red validation-success">The location "${matchLocation}" was not found.</span>`);
                return;
            }
            const locationId = Object.keys(locationsMatched)[0];

            const api = await SDKV3.init();
            const { body: data } = await api.locations.getValidationIssuesByLocation({ id:locationId, site_id: App.activeSiteID });

            if (data && data.errors && data.errors.length > 0) {
                App.alert('error', 'There was a problem getting the validation issues for this location. Please try again');
                return;
            }

            const siteIssues = [];
            for(let location_id in data){
                const issues = data[location_id];
                if(!issues.length){
                    continue;
                }else{
                    allValidated = false;
                }
                siteIssues.push(...issues);
                let print = `<div class='validation-location'><span class='green validation-header'>${issues[0].locationName}: </span><br>`;
                print += '<table border=\'1\'  class=\'terminal-action-table device-action-table-' + issues[0].locationName + '\'>'
                print += `<tr align=\'justify\'><th>Name</th><th>Error message</th><th>Category</th><th>Type</th></tr>`
                issues.forEach((issue) => {
                    print += `<tr>
                        <td>${issue.sceneName ? issue.sceneName : issue.name}</td>
                        <td>${issue.errorMessage}</td>
                        <td>${issue.category}</td>
                        <td>${issue.type}</td>
                    </tr>`;
                });
                print += '</table><br>'
                Terminal.print(print);
            }

            if (Object.keys(data).length === 0 && allValidated) {
                Terminal.print('<span class="red validation-success">No locations was found or specified in the command.</span>');
            } else if (allValidated){
                Terminal.print('<span class="green validation-success">All Locations Passed Validation</span>');
            } else if (commandObj.commandOptions.hasOwnProperty('e') && siteIssues.length) {
                exportToCsv('validatorReport', siteIssues)
            }
        }
    },
    print: {
        name: 'print',
        description: 'Prints a table of all device actions',
        default_option: 'd',
        options: {
            d: {
                description: 'used to specify which devices actions will be printed'
            },
            a: {
                description: 'print all devices returned by search'
            },
            s: {
                description: 'sort by: var1, linkid or row'
            }
        },
        example: '+ <span class=\'help-text\'>print</span> <span class=\'help-text-param\'>[device]</span><br>type \'<span class=\'help-text-italic\'>help print</span>\' for more info on the print command',
        command_function: async function (devices, commandObj) {
            await Scenes.getSceneTable({ ip_address: Object.keys(devices).map(key => devices[key].IP) })
            let sortBy = 'table_row';
            if(commandObj.commandOptions.hasOwnProperty('s')){

                let sort =  commandObj.commandOptions.s.param;

                switch(sort.toLowerCase()){
                    case 'var1':
                        sortBy = 'var_1';
                        break;
                    case 'linkid':
                        sortBy = 'link_id';
                        break;
                    default:
                        sortBy = 'table_row';
                        break;

                }
            }
            for (let device in devices) {
                device = devices[device];

                let deviceActions = [];
                if(Object.keys(device.externalActions).length === 0 && Object.keys(device.internalActions).length === 0){
                    Terminal.writeError(" has no actions in its action table.", device);
                    continue;
                }
                for(let id in device.internalActions){
                    deviceActions.push(device.internalActions[id]);
                }
                for(let id in device.externalActions){
                    deviceActions.push(device.externalActions[id]);
                }
                let htmlStr = '<table border=\'1\'  class=\'terminal-action-table device-action-table-' + device.ID + '\'>'; //creates table to be printed, classed per dev
                let len = deviceActions.length;
                htmlStr += '<caption>ACTION TABLE<br>' + device.name + ' (' + device.ip_address.substr(-4) + ') </caption>';
                htmlStr += '<tr align=\'justify\'><th>Row</th><th>LinkID</th><th>Var1</th><th>Var1 Pretty</th>';
                htmlStr += '<th>ActionTableName</th><th>saved</th></tr>';

                deviceActions.sort(function (a, b) {
                        return a[sortBy] - b[sortBy];
                });

                for (let i = 0; i < len; i++) {
                    if (deviceActions[i] !== undefined) {
                        htmlStr += '<tr >';

                        //If its a new action, we keep that row, else we assume the order in the app is the new order
                        //Added in reference to Issue #404
                        if (deviceActions[i].table_row === '65535') {
                            htmlStr += '<td>';
                            htmlStr += deviceActions[i].table_row;
                            htmlStr += '</td>';
                        } else {
                            htmlStr += '<td>';
                            htmlStr += deviceActions[i].table_row;
                            htmlStr += '</td>';
                        }

                        htmlStr += '<td>';
                        htmlStr += deviceActions[i].link_id;
                        htmlStr += '</td>';

                        htmlStr += '<td>';

                        //PSMs (and other devices in the future) have encoded Var1s we can decode them here
                        if (device.partType === 'AM-PSM' && deviceActions[i].name.indexOf('Mapped Channels') >= 0) {
                            let ChanString = '';
                            let Arry = [];
                            let numberOfChannels = 16;

                            for (let channelIndex = 0; channelIndex < numberOfChannels; channelIndex++) {
                                if ((deviceActions[i].var_1 >> channelIndex) & 0x01)
                                    Arry.push('1');
                                else
                                    Arry.push('ign');
                            }

                            //parse bitmap arry
                            for (let chan in Arry) {

                                if (Arry[chan] === '1' && Arry[parseInt(chan)] !== Arry[(parseInt(chan) + 1)]) {
                                    ChanString += (parseInt(chan) + 1) + ', ';
                                } else if (Arry[chan] === '1' && Arry[parseInt(chan)] === Arry[(parseInt(chan) + 1)] && ChanString.charAt(ChanString.length - 1) !== '-') {
                                    ChanString += (parseInt(chan) + 1) + '-';
                                }
                            }

                            htmlStr += ChanString + ' (' + deviceActions[i].var_1 + ')';
                        } else {

                            htmlStr += deviceActions[i].var_1;
                        }

                        htmlStr += '</td>';

                        htmlStr += '<td>';
                        if (deviceActions[i].name.indexOf('MAC') >= 0) {
                            let hexVar1 = decToHex(deviceActions[i].var_1);
                            let deviceMacAddy = hexVar1.substr(-12).toUpperCase(); //Last 12 Chars is the mac
                            let hexLinkID = hexVar1.slice(0, -12); //the rest is the linkID
                            let linkID = parseInt(hexLinkID);
                            htmlStr += 'Mac: ' + deviceMacAddy + ' LinkID: ' + linkID;
                        } else {
                            htmlStr += Scenes.displayPretty(deviceActions[i].name, deviceActions[i].var_1, device.partType);
                        }

                        htmlStr += '</td>';

                        htmlStr += '<td>';
                        htmlStr += deviceActions[i].name;
                        htmlStr += '</td>';

                        htmlStr += '<td>';
                        htmlStr += deviceActions[i].user && deviceActions[i].user.email ? deviceActions[i].user.email : deviceActions[i].saved;
                        htmlStr += '</td>';

                        htmlStr += '</tr>';
                    }
                }
                Terminal.print(htmlStr + '<br>');
            }

        }
    },
    ota: {
        name: 'ota',
        description: 'Update software for device(s) specified to latest version or to custom binary',
        default_option: 'd',
        special_option: '@',
        options: {
            l: {
                description: 'ota [device(s)] within location',
                expected_param: '[location]'
            },
            d: {
                description: 'designates device to  ota',
                expected_param: '[device]'
            },
            g: {
                description: 'filters devices by group',
                expected_param: '[group name]'
            },
            s:{
                description: 'include switches',
            },
            t: {
                description: 'ota device(s) by type',
                expected_param: '[device type]'
            },
            all: {
                description: 'ota all devices in current site'
            },
            custom: {
                description: 'allows user to specify binary to use for updating devices'
            },
            ch: {
                description: 'allows user to narrow down devices by channel',
                expected_param: '[channel]'
            },
            target: {
                description: 'the target version for an ota',
                expected_param: '[target]'
            },
            tag: {
                description: 'the tag version for an ota',
                expected_param: '[tag]'
            },
            p: {
                description: 'allows user to specify how many devices to ota in parallel, defaults to 1 and passing all will ota all devices in parallel',
                expected_param: '[number]'
            },
            o: {
                description: 'Allows user to force ota, even if the code is already up to date',
                expected_param: '1'
            },
            override: {
                description: 'Allows user to force ota, even if the code is already up to date',
                expected_param: '1'
            },
            a: {
                description: 'ota all devices returned by search'
            },
            mcba: {
                description: 'special mcba ota option for phd'
            },
            talking: {
                description: 'ota only devices that have talked in the last 20 mins',
            },
            fw: {
                description: 'ota only devices with a specific version of fw'
            },
            set: {
                description: 'set a new ota for a device',
                expected_param: '-device [device_ip] followed by any set of -target [target_version] -site [site_id]'
            }
        },
        example: '+ <span class=\'help-text\'>ota -set -d XXXX -target XX.XX.XX</span> + <span class=\'help-text\'>ota -set -d XXXX -tag latest</span> + <span class=\'help-text\'>ota -d </span><span class=\'help-text-param\'>[device]</span><br> + <span class=\'help-text\'>ota @ kitchen -p 3</span> <br> type \'<span class=\'help-text-italic\'>help ota</span>\' for more info on the ota command',
        command_function: function (devices, commandObj) {
            let paramObj;
            let isCustom = false;
            let p = 1;
            let channel = null;
            let doOverride = false;
            let mcba = false;
            let target = null;
            let tag = null;

            if (commandObj.commandOptions.hasOwnProperty('custom')) { //if -l was an option set in the command
                //we have to search a specific location for the device
                isCustom = true;

            }
            if (commandObj.commandOptions.hasOwnProperty('p')) { //if -l was an option set in the command
                //we have to search a specific location for the device
                p = commandObj.commandOptions.p.param;

            }
            if (commandObj.commandOptions.hasOwnProperty('o') || commandObj.commandOptions.hasOwnProperty('override')) {
                doOverride = true;

            }
            if (commandObj.commandOptions.hasOwnProperty('ch')) { //if -l was an option set in the command
                //we have to search a specific location for the device
                channel = commandObj.commandOptions.ch.param;

            }
            if (commandObj.commandOptions.hasOwnProperty('mcba')) { //if -l was an option set in the command
                //we have to search a specific location for the device
                mcba = true;

            }

            if (commandObj.commandOptions.hasOwnProperty('target')) { 
                target = commandObj.commandOptions.target.param;
            }

            if (commandObj.commandOptions.hasOwnProperty('tag')) { 
                tag = commandObj.commandOptions.tag.param;
            }

            paramObj = {
                count:0,
                multiple: (Object.keys(devices).length > 1 ? true : false),
                custom: isCustom,
                p: p,
                channel: channel,
                override: doOverride,
                mcba: mcba,
                target,
                tag,
            };

            Terminal.ota(devices, paramObj);
        }

    },
    devices: {
        name: 'devices',
        description: 'Returns a list of devices ordered by desired option.',
        default_option: 'd',
        special_option: '@',
        options: {
            count: {
                description: 'count only',
            },
            o: {
                description: 'sort by',
                expected_param: '[device_name/ip]'
            },
            l: {
                description: 'list all devices in a particular location',
                expected_param: '[location_name]'
            },
            t: {
                description: 'list all devices of a particular type',
                expected_param: '[device_type]'
            },
            a: {
                description: 'list all devices returned by search'
            },
            all: {
                description: 'list all devices in site'
            },
            g: {
                description: 'filters devices by group',
                expected_param: '[group name]'
            },
            del: {
                description: 'list all devices in site deleted'
            },
        },
        example: '+ <span class=\'help-text\'>ping -d</span> <span class=\'help-text-param\'> [Name/IP/MAC]</span> <span class=\'help-text-desc\'> (Ping device by name IP or MAC address)</span> <br>+ <span class=\'help-text\'> ping -l production -t AM-DCDIM</span><span class=\'help-text-desc\'> (Pings all DCDIMs in Production location)</span><br>+<span class=\'help-text\'> ping @ </span><span class=\'help-text-param\'> [location] </span> <span class=\'help-text-desc\'> (Pings all devices in specified location)</span> <br> type \'<span class=\'help-text-italic\'>help ping</span>\' for more info on the ping command',
        command_function: function (devices, commandObj=false) {

            let sortBy = 'age';
            let countOnly = false;
            if (commandObj !== false && commandObj.commandOptions.hasOwnProperty('o')) {
                sortBy = commandObj.commandOptions.o.param;
            }
            if(commandObj !== false && commandObj.commandOptions.hasOwnProperty('count')){
                countOnly = true;
            }
            let paramObj = {
                count:0,
                multiple: (Object.keys(devices).length > 1 ? true : false),
                placement: Terminal.commandCount,
            };

            let printString = '';
            let sorted = Devices.sortDevicesBy(Object.keys(devices), sortBy);

            if(countOnly === false){
                for (let list in sorted) {
                    for (let device in sorted[list]) {
                        paramObj.count++;
                        device = devices[sorted[list][device]];

                        printString = '<span>' + device.name + '(' + device.IP.substr(35) + ') [' + device.partType + ' ver:' + device.version + '] </span><br>';
                        if (list === 'low') {
                            printString += '<span class=\'green\'>';
                        } else if (list === 'middle') {

                            printString += '<span class=\'yellow\'>';

                        } else {
                            printString += '<span class=\'red\'>';
                        }

                        if (device.partType.toLowerCase() === 'tny' || device.partType.toLowerCase() === 'tiny' || device.isSpoofed === true) {
                            printString += '&nbsp;&nbsp;&nbsp;&nbsp; No historical data.</span>';
                        } else {
                            printString += '&nbsp;&nbsp;&nbsp;&nbsp;(' + App.tm(device.lastPing - App.converter) + ') (' + device.rssi + ')</span>';
                        }

                        Terminal.print(printString, paramObj);
                    }
                }
            }

            printString = '<br><br>All Devices: (' + Date() + ')<br>';
            printString += '<span class=\'green\'>Green: ' + sorted.low.length + '</span><br>';
            printString += '<span class=\'yellow\'>Yellow: ' + sorted.middle.length + '</span><br>';
            printString += '<span class=\'red\'>Red: ' + sorted.high.length + '</span><br>';

            let total = sorted.low.length + sorted.middle.length + sorted.high.length;

            printString += 'Total: ' + total;
            Terminal.print(printString);

            return;

        }
    },
    scenes: {
        name: 'scenes',
        description: 'View associated scenes for device',
        default_option: 'd',
        special_option: '@',
        options: {
            l: {
                description: 'search for device within location',
                expected_param: '[location]'
            },
            t: {
                description: 'search for [devices] by type',
                expected_param: '[device type]'
            },
            d: {
                description: 'search for [device] ',
                expected_param: '[device]'
            },
            s:{
                description: 'include switches',
            },

            a: {
                description: 'search for all devices returned by search'
            },

            all: {
                description: 'gets keys for all devices in current site'
            },
            g: {
                description: 'filters devices by group',
                expected_param: '[group name]'
            }
        },
        example: '+ <span class=\'help-text\'>ping -d</span> <span class=\'help-text-param\'> [Name/IP/MAC]</span> <span class=\'help-text-desc\'> (Ping device by name IP or MAC address)</span> <br>+ <span class=\'help-text\'> ping -l production -t AM-DCDIM</span><span class=\'help-text-desc\'> (Pings all DCDIMs in Production location)</span><br>+<span class=\'help-text\'> ping @ </span><span class=\'help-text-param\'> [location] </span> <span class=\'help-text-desc\'> (Pings all devices in specified location)</span> <br> type \'<span class=\'help-text-italic\'>help ping</span>\' for more info on the ping command',
        command_function: async function (devices, commandObj) {
            await Scenes.getSceneTable({ ip_address: Object.keys(devices).map(key => devices[key].IP) })
            let paramObj = {
                count:0,
                multiple: (Object.keys(devices).length > 1 ? true : false),
                placement: Terminal.commandCount,
            };
            let device;
            for(let id in devices){
                device = devices[id];
                paramObj.count++;
                let scenes = {};
                let sceneString = "";
                let noScenes = "No Scenes";
                //get scenes per switch, we need to loop over all scenes and look at triggers for tiny ip
                if(device.classification === 'switch'){
                    let allScenes = App.Site.scenes;
                    for(let sceneID in allScenes){
                        let activeScene = allScenes[sceneID];
                        if(activeScene.isSwitchControl === true){
                            for(let trig in activeScene.triggers){
                                trig = activeScene.triggers[trig];
                                if(trig.hasOwnProperty('tiny_ip')){
                                    if(trig.tiny_ip.substr(-4) === device.ip_address.substr(-4)  && scenes.hasOwnProperty(activeScene.id) === false){
                                        scenes[activeScene.id] = activeScene;
                                        let location = activeScene.location;
                                        sceneString += `<span class='green'><br> + ${activeScene.name} - ${activeScene.link_id} (location: ${location.name}) </span>`;
                                    }
                                }
                            }
                        }
                    }
                }
                //get scenes per device by looping over device external actions and finding the associated scene
                for(let action in device.externalActions){
                    let activeAct = device.externalActions[action];
                    let linkID;
                    let scene;
                    if(activeAct.name !== 'deleted'){
                        if(activeAct.name.toLowerCase().indexOf('coap') >= 0){
                            linkID = activeAct.var_1;
                        }else{
                            linkID = activeAct.link_id;
                        }
                        if(App.Site.sceneLookup.hasOwnProperty(linkID)){
                            scene = App.Site.scenes[App.Site.sceneLookup[linkID]];
                            if(!scenes.hasOwnProperty(scene.id)){
                                scenes[scene.id] = scene;
                                let location = scene.location;
                                sceneString += `<span class='green'><br> + ${scene.name} - ${scene.link_id} (location: ${location.name}) </span>`;
                            }

                        }
                    }
                }
                if(Object.keys(scenes).length > 0){
                    Terminal.write(sceneString, device, paramObj);
                }else{
                    Terminal.write(noScenes, device, paramObj);
                }
            }
        }
    },
    profile: {
        name: 'profile',
        description: 'Create, configure and view network profiles.',
        default_option: 'v',
        special_option: '@',
        options: {
            n: {
                description: 'create a new profile by name',
                expected_param: '[profile_name] followed by any set of -ch [channel] -pan [panID] -n [name] (if no channel, pan or name are input they will be randomized and unique)'
            },
            v: {
                description: 'view all profiles or view by name',
                expected_param: '[profile_name]'
            },
            e: {
                description: 'edit profile by name',
                expected_param: '[profile_name] followed by any set of -ch [channel] -pan [panID] -n [name]'
            },
            c: {
                description: 'copy profile by name',
                expected_param: '[profile_name] followed by desired name of copy profile (if no profile name is provided for the copy it will be the same as the copied profile with "copy" appended)'
            },
            d: {
                description: 'delete profile by name',
                expected_param: '[profile_name]'
            }
        },
        example: '+ <span class=\'help-text\'>ping -d</span> <span class=\'help-text-param\'> [Name/IP/MAC]</span> <span class=\'help-text-desc\'> (Ping device by name IP or MAC address)</span> <br>+ <span class=\'help-text\'> ping -l production -t AM-DCDIM</span><span class=\'help-text-desc\'> (Pings all DCDIMs in Production location)</span><br>+<span class=\'help-text\'> ping @ </span><span class=\'help-text-param\'> [location] </span> <span class=\'help-text-desc\'> (Pings all devices in specified location)</span> <br> type \'<span class=\'help-text-italic\'>help ping</span>\' for more info on the ping command',
        command_function: function (devices, commandObj) {
            //TODO: implement all profile features
            Terminal.write("<br> This functionality is not yet implemented.<br>");
        }
    },
    ambr: {
        name: 'ambr',
        description: 'Connect and configure your local ambr',
        default_option: 'status',
        special_option: '',
        loginPut: '<input spellcheck="false" autocapitalize="off" autocorrect="off" id="login-put">',
        connection:false,
        userName:"",
        options: {
            status: {
                description: 'Checks the status of the local ambr. Pings for local ambr to see if it is on the network or displays connection information if already connected.',
                expected_param: '[ambrID] (will use currently connected siteID as default. A ambrID can be passed if you wish to connect to a different router.'
            },
            s: {
                description: 'Shortcut for status',
            },
            connect: {
                description: 'Connect to local ambr by ID',
                expected_param: '[ambrID] (will connect to ambr by current site ID if none provided)'
            },
            c: {
                description: 'Shortcut for connect',
            },
            logout:{
                description: 'Connect to local ambr by ID',
            },
            routeslog:{
                description: 'Get routes information from local ambr, requires ambr connection.',
                endpoint:"routes_log",
                type:"get"
            },
            netlog:{
                description: 'Get recent network and routing applications information. (contiki log)',
                endpoint:"contiki_log",
                type:"get"
            },
            panrepair:{
                description: 'Initiate PAN repair from connected router.',
                endpoint:"pan_repair",
                type:"get"
            },
            panreboot:{
                description: 'Multicast reboot signal to all devices on ambrs network',
                endpoint:"reboot_pan",
                type:"get"
            },
            softreboot:{
                description: 'Restart connected ambrs routing applications.',
                endpoint:"soft_reboot",
                type:"get"
            },
            info:{
                description: 'Load up all data from connected router.',
                endpoint:"info",
                type:"post"
            },
        },
        example: '+ <span class=\'help-text\'>ping -d</span> <span class=\'help-text-param\'> [Name/IP/MAC]</span> <span class=\'help-text-desc\'> (Ping device by name IP or MAC address)</span> <br>+ <span class=\'help-text\'> ping -l production -t AM-DCDIM</span><span class=\'help-text-desc\'> (Pings all DCDIMs in Production location)</span><br>+<span class=\'help-text\'> ping @ </span><span class=\'help-text-param\'> [location] </span> <span class=\'help-text-desc\'> (Pings all devices in specified location)</span> <br> type \'<span class=\'help-text-italic\'>help ping</span>\' for more info on the ping command',
        command_function: function (commandObj) {
            if(commandObj.commandOptions.hasOwnProperty('connect') || commandObj.commandOptions.hasOwnProperty('c')){
                this.init(commandObj);
            }else if(commandObj.commandOptions.hasOwnProperty('status') || commandObj.commandOptions.hasOwnProperty('s')){

            }else if(this.connection){
                if(commandObj.commandOptions.hasOwnProperty('logout')){
                    this.logout();
                }else{
                    this.handleAsyncCall(commandObj);
                }
            }else{
                Terminal.print("<br>You must log in to Ambr before you can use any other ambr functions: ambr -c<br>");
            }
        },
        handleAsyncCall: async function(commandObj){
            let response;
            for(let option in commandObj.commandOptions){
                Terminal.write("<br>"+option+":<br>");
                response = await Ambrs[this.options[option].type](this.options[option].endpoint);
                this.handleResponse(response);
            }
        },
        //print contents of response object from individual calls
        handleResponse: function(response){
            if(response.hasOwnProperty('data')){
                if(typeof(response.data) === 'object'){
                    for(let data in response.data){
                        if(typeof(response.data[data]) === Array|| typeof(response.data[data]) === "object"){
                            for(let content in response.data[data]){
                                Terminal.write("<br>"+response.data[data][content].toString()+"<br>");
                            }
                        }
                    }
                }

            }
        },
        //get username input from user
        getUsername(ambrID){
            $('#command-text').hide().after(this.loginPut);
            Terminal.write("", "Username:");
            $('#login-put').focus();
            $('#login-put').on('keydown',  function(e){
                if(e.keyCode === 13){
                    Terminal.write("<br>Username: "+$(this).val());
                    Commands.ambr.userName = $(this).val();
                    Commands.ambr.getPassword(ambrID);
                }
            });
        },
        //get password input from user then attempt to connect
        getPassword(ambrID){
            Terminal.write("", "Password:");
            $('#login-put').attr('type', 'password').val('');
            $('#login-put').focus();
            $('#login-put').off().on('keydown',function(e){
                if(e.keyCode === 13){
                    Commands.ambr.connect(ambrID, true, $(this).val());
                }
            });
        },
        //init ambr connection
        init: function(commandObj){
            let sessionOpen = this.connected;
            let ambrID = commandObj.commandOptions.hasOwnProperty('connect') ? commandObj.commandOptions.connect.param : commandObj.commandOptions.c.param;
            if(localStorage.getItem('activeAmbrID') !== null){
                sessionOpen = true;
                App.activeAmbrID = localStorage.getItem('activeAmbrID');
            }else{
                App.activeAmbrID = "0"+App.activeSiteID;
            }
            ambrID = ((ambrID === undefined || ambrID === "" || ambrID === null) ? App.activeAmbrID : ambrID);
            ambrID = (ambrID.length === 3 ? "0"+ambrID : ambrID);
            //see if we already have a session with this ambr
            //init all API urls to hit this ambr
            Ambrs.initUrls(ambrID);
            Terminal.write("<br>Connecting to Ambr-"+ambrID+":<br>");
            if(sessionOpen && ambrID.indexOf(App.activeAmbrID) >= 0){
                this.userName = "justCheck";
                this.connect(ambrID, true);
            }else{//otherwise we need to log in
                this.connect(ambrID);
            }
        },
        //log in to ambr or check for standing session
        connect: async function(ambrID="0"+App.activeSiteID, loggedIn=false, password){
            if(loggedIn){
                $('#login-put').remove();
                Terminal.write("<br>Logging in...");
                let connected = await Ambrs.login(this.userName, password);
                if(connected){
                    this.connection = true;
                    Terminal.write("success!<br>");
                    this.completeConnection(true);
                //if we checked for standing connection but it failed well have the user log in manually
                }else if(this.userName === "justCheck"){
                    this.getUsername(App.activeAmbrID);
                }else{
                    this.connection = false;
                    Terminal.write("failed, incorrect username or password!<br>");
                    this.completeConnection(false);
                }
            }else{
                this.getUsername(ambrID);
            }
        },
        //show info in the terminal that we are either connected to ambr or the connection is now closed
        completeConnection: function (ambrConnected=false){
            if(ambrConnected){
                Terminal.userInfo = '/site/' + App.activeSiteID + ' (Ambr-'+App.activeAmbrID+' connected): ';
            }else{
                Terminal.userInfo = '/site/' +  App.activeSiteID + ': ';
            }
            $('#terminal-user-info').text(Terminal.userInfo);
            $('#command-text').show();
        },

        logout: async function(){
            Terminal.write("<br>Logging out...");
            let response = await Ambrs.logout();
            if(response === "logout successful"){
                Terminal.write("success!<br>");
                this.connection = false;
                localStorage.removeItem("activeAmbrID");
                this.completeConnection();
            }else{
                Terminal.write("failed!<br>");
            }
        }
    },
    undelete: {
        name: 'undelete',
        description: 'Undelete device from site',
        default_option: 'd',
        options: {
            d: {
                description: 'undelete a single device referenced last 4',
                expected_param: '[device_last_4]'
            },
            list: {
                description: 'print all undelete devices',
            },
        },
        example: '',
        command_function: async ({ commandOptions }) => {
            if (commandOptions.hasOwnProperty('d')) {
                const { d: { param: deviceLast4 } } = commandOptions;
                try {
                    const PARAMS = { SITEID: App.activeSiteID, IP_LAST_FOUR: deviceLast4 };
                    const OPTIONS = { serverVariables: { baseVersion: 'v2' } };
                    const api = await SDK.init();
                    let startTime = new Date().getTime();
                    const { ok, body } = await api.devices.postUndeleteDevice(PARAMS, OPTIONS);
                    if (ok) {
                        if (!App.Site.devices[body.data.id]) {
                            new Device(body.data);
                            App.Site.devices[body.data.id].finishLoad()
                        }
                        Terminal.print(`<span class='green'>Device ${body.data.IP} is active.</span>`);
                    } else {
                        Terminal.print("<span class='red'>No device not found.</span>");
                    }
                    let endTime = new Date().getTime();
                    Terminal.print(`Total Time: ${(endTime - startTime) / 1000}s`);
                } catch (error) {
                    Terminal.print("<span class='red'>No device not found.</span>");
                }
                Terminal.scrolled = false;
                Terminal.updateScroll();
                return false;
            }
            if (commandOptions.hasOwnProperty('list')) {
                try {
                    const api = await SDK.init();
                    let startTime = new Date().getTime();
                    const { ok, body } = await api.devices.getDeletedDevicesBySiteId({ SITEID: App.activeSiteID });
                    if (ok) {
                        body.data.sort((a, b) => {
                            const dateA = new Date(a.updated_at);
                            const dateB = new Date(b.updated_at);
                            return dateB - dateA;
                        })
                        .forEach(device => {
                            Terminal.print(`<span class='green'>- ${device.Name} (${device.IP.slice(-4)})</span><br>`);
                        });
                    } else {
                        Terminal.print("<span class='red'>No devices not found.</span>");
                    }
                    let endTime = new Date().getTime();
                    Terminal.print(`Total Time: ${(endTime - startTime) / 1000}s`);
                } catch (error) {
                    Terminal.print(`<span class="red">${error}</span>`);
                }
                Terminal.scrolled = false;
                Terminal.updateScroll();
                return false;
            }
        }
    },
    clear: {
        name: 'clear',
        description: 'clears all text in window',
        command_function: function () {
            $('#terminal-text-body').html('');

        }
    },
    reboot: {
        name: 'reboot',
        description: 'Reboot device by IP',
        default_option: 'd',
        options: {
            d: {
                description: 'device referenced by IP address',
                expected_param: '[IP]'
            },
            mcba: {
                description: 'filter devices by PHDs type',
                expected_param: '[IP]'
            },
        },
        example: '',
        command_function: async (allDevices, { commandOptions }) => {
            let devices = {};
            if (commandOptions.hasOwnProperty('mcba')) {
                for (let devID in allDevices) {
                    const deviceItem = allDevices[devID];
                    if (deviceItem.partType === 'AM-PHD') {
                        Object.assign(devices, { ...devices, [devID]: deviceItem })
                    }
                }

                if(Object.keys(devices).length > 0) {
                    for (let devID in devices) {
                        const deviceItem = devices[devID];
                        let startTimeMCBA = new Date().getTime();
                        try {
                            deviceItem.rebootMCBA();
                            Terminal.print(`<span class='green'>Device ${deviceItem.ip_address} is rebooting.</span>`);
                            let endTimeMCBA = new Date().getTime();
                            Terminal.print(`Total Time: ${(endTimeMCBA - startTimeMCBA) / 1000}s`);
                        } catch (error) {
                            Terminal.print(`<span class='red'>${error.toString()}</span>`);
                            return false;
                        }
                    }
                    return false;
                } else {
                    Terminal.print("<span class='red'>None of the applicable devices are PHDs.</span>");
                    return false;
                }
            } else {
                devices = allDevices;
                for (let deviceId in devices) {
                    const device = devices[deviceId];
                    try {
                        const OPTIONS = { requestBody: { device_id: device.ip_address, site_id: App.activeSiteID } };

                        const api = await SDKDevices.init();
                        let startTime = new Date().getTime();
                        const { body } = await api.devices.rebootDevice({}, OPTIONS);
                        if (body.data.data === 'Ok' || body.data[0] === 'Ok') {
                            Terminal.print(`<span class='green'>Device ${device.ip_address} is rebooting.</span>`);
                        } else if (body.errors && body.errors.length){
                            Terminal.print(`<span class='red'>${body.errors[0]}</span>`);
                        } else {
                            Terminal.print("<span class='red'>Device not found</span>");
                        }
                        let endTime = new Date().getTime();
                        Terminal.print(`Total Time: ${(endTime - startTime) / 1000}s`);
                    } catch (error) {
                        Terminal.print("<span class='red'>Found a problem rebooting the device.</span>");
                    }
                }
                Terminal.scrolled = false;
                Terminal.updateScroll();
                return false;
            }
        }
    },
};

export default Commands;
