import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import { clearSite } from 'redux/activeSite'

export const LOGOUT_USER = 'LOGOUT_USER';

export const logoutUser = createAction(LOGOUT_USER);

export const logOut = () => dispatch => {
  dispatch(clearSite());
  dispatch(logoutUser());
  localStorage.removeItem('localLocation');
  localStorage.removeItem('localScene');
  localStorage.removeItem('App.localIP');
  localStorage.removeItem('amatis_user');
  localStorage.removeItem('amatis_token');
  if(window.Intercom){
    window.Intercom('update', {user_id: '', email: '', name: '', user_hash: ''});
  }
  return dispatch(push('/'));
};
