import { createAction } from 'redux-actions';
import SDK from 'api/sdk';

export const ADD_TASK_REQUEST = 'ADD_TASK_REQUEST';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_FAILURE = 'ADD_TASK_FAILURE';
export const ADD_TASK_FULLFILL = 'ADD_TASK_FULLFILL';

export const addTaskRequest = createAction(ADD_TASK_REQUEST);
export const addTaskSuccess = createAction(ADD_TASK_SUCCESS);
export const addTaskFailure = createAction(ADD_TASK_FAILURE);
export const addTaskFullfill = createAction(ADD_TASK_FULLFILL);

export const addTask = (taskObj) => dispatch => {
    dispatch(addTaskRequest());
    const SITEID = window.localStorage.getItem('amatis_site_id');
    const requestBody = {
        task: 'devices.tag',
        args: {...taskObj},
        async: false
    };
    const OPTIONS = { requestBody, serverVariables: { baseVersion: "v2" } };
    const params = { SITEID };
    return SDK.init()
    .then(api => api.tasks.saveTask(params, OPTIONS))
    .then(response => {
        const { body: { data } } = response;
        dispatch(addTaskSuccess(data));
        return data;
    }).catch(error => {
        const { errorMessages } = error;
        dispatch(addTaskFailure(errorMessages[0]));
    })
    .finally(() => {
        dispatch(addTaskFullfill());
    });
};
