import { Model, fk, attr } from 'redux-orm';
import App from 'app/app';

const CONVERTER = 62135596800;

// const normalizeDataTimeStamp = ts => {
//     if(ts > (new Date().getTime()/1000)){
//         ts -= CONVERTER;
//     }
//     return ts;
// };

const tm = unix_tm => {
    const dt = new Date(unix_tm * 1000);
    const dtStr = dt.toString();
    const indexOfGMT = dtStr.indexOf('GMT');
    const badDtStr = dtStr.slice(indexOfGMT, (indexOfGMT + 8));
    return (dtStr.replace(badDtStr, ''));
};

class DeviceModel extends Model {
    static modelName = 'Device';

    static fields = {
        id: attr(), // non-relational field for any value; optional but highly recommended
        ip_address: attr(),
        type: attr(),
        version: attr(),
        compile_time: attr(),
        notes: attr(),
        name: attr(),
        is_assigned: attr(),
        pass_fail: attr(),
        role: attr(),
        created_at: attr(),
        updated_at: attr(),
        updated: attr(),

        site_id: attr(),
        networks_id: attr(),

        dname: attr(),
        dataTable: attr({ getDefault: () => [] }),
        dnameToIndex: attr({ getDefault: () => [] }),
        indexToDname: attr({ getDefault: () => [] }),
        internalActions: attr({ getDefault: () => [] }),
        externalActions: attr({ getDefault: () => [] }),

        talking: attr({ getDefault: () => true }),
        // foreign key field
        location_id: fk('Location'),
    }

    static reducer({ type, payload }, Device) {
        switch (type) {
            // case 'LIST_DEVICES_SUCCESS':
            //     payload.forEach(device => Device.upsert(device));
            //     break;
            // case 'DEVICES_ADD_INTERNAL_ACTION':
            //     for (const deviceId in payload) {
            //         const device = Device.withId(deviceId);
            //         device.set('internalActions', device.internalActions.concat(payload[deviceId]));
            //     }
            //     break;
            // case 'DEVICES_ADD_EXTERNAL_ACTION':
            //     for (const deviceId in payload) {
            //         const device = Device.withId(deviceId);
            //         device.set('externalActions', device.externalActions.concat(payload[deviceId]));
            //     }
            //     break;
            // case 'DEVICE_UPDATE':
            //     payload.forEach(item => {
            //         const { deviceId, items, timestamp } = item;
            //         const values = {
            //             dataTable: [],
            //             dnameToIndex: [],
            //             indexToDname: [],
            //         };
            //         const device = Device.withId(deviceId);
            //         if (timestamp) {
            //             device.set('last_ping', normalizeDataTimeStamp(timestamp));
            //         }
            //         items.forEach(item => {
            //             values.dataTable.push(item);
            //             if(item.hasOwnProperty('dname')){
            //                 values.dnameToIndex.push(item.idx);
            //                 values.indexToDname.push(item.dname);
            //                 switch(item.dname) {
            //                     case 'PIR Value':
            //                     case 'PIR Timeout':
            //                     case 'PIR Timeout2':
            //                     case 'NH Timeout':
            //                     case 'AH Timeout':
            //                     case 'Timeout Mode':
            //                     case 'Occupied':
            //                     case 'Occ Enable':
            //                     case 'PIR Enable':
            //                     case 'DLL Enable':
            //                     case 'Lt Enable':
            //                     case 'Occ Mode':
            //                     case 'Occ Mode Enable':
            //                         if(!this.isTalking && this.id in App.Site.offlineSetpointsToWrite && (this.ip_address + item.dname.replace(/\s+/g, '_')) in App.Site.offlineSetpointsToWrite[this.id]){
            //                             item.cval = App.Site.offlineSetpointsToWrite[this.id][this.ip_address + item.dname.replace(/\s+/g, '_')].value;
            //                             item.timestamp = this.last_ping;
            //                         }
            //                     break;
            //                     case 'PWM':
            //                     case 'Channel 1':
            //                     case 'DAC':
            //                         values['level'] = parseFloat(item.cval, 10);
            //                     break;
            //                     case 'RSSI':
            //                         values['talking'] = true;
            //                         values['rssi'] = parseInt(item.cval, 10, 10);
            //                     break;
            //                     default:
            //                     break;
            //                 }
            //             } else if (!item.hasOwnProperty('dname')) {
            //                 if(item.idx in device.indexToDname){
            //                     values['dname'] = device.indexToDname[item.idx];
            //                 }
            //             }
            //         });
            //         device.update(values);
            //     });
            //     break;
            // no default
        }
        // Return value is ignored.
        return undefined;
    }

    get isTaking() {
        if (!App.isLiveDataLoaded) return true;
        const acceptableAge = 14400;
        if (this.type === 'Tny' || this.type === 'Tiny') {
            return true;
        }
        return (this.age < acceptableAge) ? true : false;
    }

    get shortName(){
        if (this.name.length > 10) {
            return `${this.name.substring(0,7)}...`;
        }
        return this.name;
    }

    get lastPing() {
        return (this.last_ping) ? tm(this.last_ping).replace(/\(([^]+)\)/g, '') : '-';
    }

    get statusIcon() {
        if (this.status === 'green') return '💚';
        if (this.status === 'yellow') return '💛';
        if (this.status === 'red') return '💔';
        return '🖤';
    }

    get status() {
        if(this.deviceShouldReportData){
            if (this.age < 1800){
                return 'green';
            } else if (this.age < 86400){
                return 'yellow';
            } else {
                return 'red';
            }
        }
        return false;
    }

    get deviceShouldReportData(){
        if (this.type === 'Tny' || this.type === 'Tiny' || this.type === 'SWITCH-W' || this.type === 'SWITCH-B') {
            return false;
        }
        return true;
    }

    get age(){
        return parseInt((Math.round(new Date() / 1000) - this.last_ping), 10);
    }

    get nameWithLastFour(){
        return `${this.name} (${this.ip_address.substr(-4)})`;
    }

    get output() {
        return this.level ? `${this.level}%` : '-';
    }

    get swVersion(){
        return this.version ? this.version : '';
    }

    get IP(){
        return this.ip_address;
    }

    get deviceID(){
        return this.ip_address.substring(19).replace(/:/g, '').replace(/fffe/g, '').toUpperCase();
    }

    get isMasterOccByRole(){
        return this.role === 'master_motion' || this.role === 'master_motion_dll'
    }

    get isMasterOccByName(){
        return this.name.toLowerCase().indexOf('master') >= 0
    }
    get isMasterDllByRole(){
        return this.role === 'master_motion_dll' || this.role === 'master_dll'
    }
    get isMasterDllByName(){
        return this.name.indexOf('dll') >=0
    }

    get isMultiChannel(){
        return this.type.indexOf('PHD') >=0 || this.type.indexOf('PSM') >= 0 || this.type === 'AM-SW8'
    }

    get isMultiChannelOutputDevice(){
        return (this.type === 'AM-PHD' || this.type === 'XPOE')
    }

    get isSyncable(){
        return this.type !== 'Tny'
    }

    get signalStrengthIcon () {
        if (this.type === 'Tny' || this.type === 'Tiny') {
            return null;
        }
        if (!this.talking) {
            return 'exclamation-triangle';
        }
        return null;
    }

    get locationName() {
        return (this.location_id && this.location_id.name) ? this.location_id.name : '-';
    }

    get locationPath() {
        return (this.location_id && this.location_id.pathStr) ? this.location_id.pathStr : '-';
    }

    get errors(){
        const errors = {
            error: false,
            message: '',
            lastPing: '',
        };
        if (!this.isTalking) {
            Object.assign(errors, { error: true });
            if (this.age > 1209600) {
                Object.assign(errors, { message: `${this.nameWithLastFour} has not communicated in more than 2 weeks.` });
            } else if (this.age > 604800) {
                Object.assign(errors, { message: `${this.nameWithLastFour} has not communicated in the last 7 days.` });
            } else if (this.age > 86400) {
                Object.assign(errors, { message: `${this.nameWithLastFour} has not communicated in the last 24 hours.` });
            } else if (this.age > 10800) {
                Object.assign(errors, { message: `${this.nameWithLastFour} has not communicated in the last 3 hours.` });
            }

            Object.assign(errors, { lastPing: `Last Ping ${tm(this.lastPing - CONVERTER)}` });
            this.talking = false;
        }
        return errors;
    }
}

export default DeviceModel;
