import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { Redirect } from 'react-router-dom';
import User from 'classes/users';

export default WrappedComponent => {
  class PublicAuthHOC extends Component {

    state = {
      isLoggedIn: User.isLocalStorage(),
      siteID: User.siteIDFromStorage(),
    };

    componentDidMount() {
        const isLoggedIn = User.isLocalStorage();
        const siteID = User.siteIDFromStorage();
        this.setState({
            isLoggedIn,
            siteID,
        });
    }

    render() {
      const { isLoggedIn, siteID } = this.state;

      if (isLoggedIn && siteID === '') {
        return <Redirect to="/select-site" />;
      }

      if (isLoggedIn && siteID !== '') {
        return <Redirect to={`/site/${siteID}`} />;
      }

      return <WrappedComponent {...this.state} {...this.props} />;
    }

  }

  hoistNonReactStatics(PublicAuthHOC, WrappedComponent);

  return PublicAuthHOC;
};
