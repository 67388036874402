import { createSelector } from 'reselect';

export const getLocations = state => state.locations;
export const getLocationIdProps = (_,props) => props.location.id;
export const isLoadingLocations = createSelector(getLocations, locations => locations.loading);
export const getLocationsError = createSelector(getLocations, locations => locations.error);
export const getLocationsItems = createSelector(getLocations, locations => locations.items);
export const getInflux = createSelector(getLocations, locations => locations.influx);
export const getInfluxItems = createSelector(getInflux, influx => influx.items);
export const getLocationsModal = createSelector(getLocations, locations => locations.modal);
export const getLocationsMeta = createSelector(getLocations, locations => locations.meta);
export const getLocationsMetaSearch = createSelector(getLocationsMeta, meta => meta.search);
export const getLocationsOpening = createSelector(getLocations, locations => locations.opening);
export const getLocationsTestMode = createSelector(getLocations, locations => locations.testMode);
export const getLocationsIsTestModeActive = createSelector(getLocations, locations => locations.isTestModeActive);
export const getSavingSettingState = createSelector(getLocations, locations => locations.saving_settings);

export const getLocationIsOpen = createSelector(
    [getLocationsOpening, getLocationIdProps],
    (opening, locationID) => opening.includes(locationID)
);

export const getLocationsPositionScroll = createSelector(getLocations, locations => {
    if(locations !== undefined && locations.positionScroll){
        return locations.positionScroll;
    } else {
        return 0;
    }
});

export const getLocationRoot = createSelector(getLocationsItems,
    locations => locations.find(location => location.parent_id === 0)
);
