import 'bootstrap/dist/css/bootstrap.css';
import 'react-rangeslider/lib/index.css'
import './App.scss';
import React from 'react';
import Loadable from 'react-loadable';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { ThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import ErrorHandler from 'components/containers/error-handler';
import { ErrorBoundary } from 'classes/bugsnag';

import store, { persistor } from 'store';
import history from 'store/history';
import Routes from 'routes';
import routes from 'routes/config';
import Intercom from 'react-intercom';
import 'classes/perf-devtools';

import * as serviceWorker from './serviceWorker';

library.add(fal, fas, far);

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiListItemIcon: {
            root: {
                minWidth: 45,
            },
        },
        MuiSwitch: {
            thumb: {
                backgroundColor: '#ffffff',
            },
        }
    },
});
const generateClassName = createGenerateClassName({
    productionPrefix: 'amatis-',
});

const startApp = () => {
    const appId = process.env.REACT_APP_INTERCOM_APP_ID;
    let settingsIntercom = {
        hide_default_launcher: true,
        custom_launcher_selector: '#intercom_amatis',
    }
    if (localStorage.getItem('amatis_user') !== null) {
        let User = JSON.parse(localStorage.getItem('amatis_user'));
        settingsIntercom = {
            email: User.email,
            name: `${User.fname} ${User.lname}`,
            user_id: User.id,
            hide_default_launcher: true,
            custom_launcher_selector: '#intercom_amatis',
        };
    }
    /* if (process.env.NODE_ENV === 'development') {
        const whyDidYouRender = require('@welldone-software/why-did-you-render');
        whyDidYouRender(React, {
            trackAllPureComponents: true,
        });
    } */
    Loadable.preloadReady().then(() => {
        hydrate(
            <ErrorBoundary>
                <ErrorHandler>
                    <StylesProvider generateClassName={generateClassName}>
                        <ThemeProvider theme={theme}>
                            <Provider store={store}>
                                <PersistGate loading={null} persistor={persistor}>
                                    <ConnectedRouter history={history}>
                                        <Routes routes={routes} store={store} />
                                        <Intercom appID={appId} {...settingsIntercom}/>
                                    </ConnectedRouter>
                                </PersistGate>
                            </Provider>
                        </ThemeProvider>
                    </StylesProvider>
                </ErrorHandler>
            </ErrorBoundary>,
            document.getElementById('root')
        );
    });
}

const deviceReady = () => {
    window.document.body.classList.add("isCordova");
    window.open = window.cordova.InAppBrowser.open;
    startApp();
}

if(window.cordova) {
  console.log('are we actually picking up cordova here?');
  document.addEventListener('deviceready', deviceReady, false);
  serviceWorker.register();
} else {
  startApp();
  serviceWorker.unregister();
}
