import { createAction } from 'redux-actions';
import SDKV3 from "api/sdkV3";
import App from 'app/app';

export const LIST_DEVICE_GROUPS_REQUEST = 'LIST_DEVICE_GROUPS_REQUEST';
export const LIST_DEVICE_GROUPS_SUCCESS = 'LIST_DEVICE_GROUPS_SUCCESS';
export const LIST_DEVICE_GROUPS_FAILURE = 'LIST_DEVICE_GROUPS_FAILURE';
export const LIST_DEVICE_GROUPS_FULFILL = 'LIST_DEVICE_GROUPS_FULFILL';

export const INSERT_DEVICE_GROUPS_SUCCESS = 'INSERT_DEVICE_GROUPS_SUCCESS';

export const listDeviceGroupsRequest = createAction(LIST_DEVICE_GROUPS_REQUEST);
export const listDeviceGroupsSuccess = createAction(LIST_DEVICE_GROUPS_SUCCESS);
export const listDeviceGroupsFailure = createAction(LIST_DEVICE_GROUPS_FAILURE);
export const listDeviceGroupsFulfill = createAction(LIST_DEVICE_GROUPS_FULFILL);

export const insertDeviceGroupsSuccess = createAction(INSERT_DEVICE_GROUPS_SUCCESS);

export const actionGetDeviceGroups = () => dispatch => {
  const id = window.localStorage.getItem("amatis_site_id");
  dispatch(listDeviceGroupsRequest());
  return SDKV3.init().then(api => api.deviceGroups.getDeviceGroups({ site_id: id, sort: "created_at", limit: 10000 }))
    .then(({ body: { data }}) => {
      dispatch(listDeviceGroupsSuccess(data));
    })
    .catch(error => {
      dispatch(listDeviceGroupsFailure(error));
    })
    .finally(() => {
      dispatch(listDeviceGroupsFulfill());
    });
};

export const actionCreateDeviceGroups = (payload) => dispatch => {
  const requestBody = { data: payload }
  return SDKV3.init().then(api => api.deviceGroups.assignOrCreateDeviceGroup({}, { requestBody }))
    .then(({ body: { data }}) => {
      if (App.hasOwnProperty('ConfigTables')) {
        App.ConfigTables.deviceGroupsInsert(data)
      }
      dispatch(insertDeviceGroupsSuccess(data.device_groups));
    })
    .catch(error => {
      dispatch(listDeviceGroupsFailure(error));
    })
    .finally(() => {
      dispatch(listDeviceGroupsFulfill());
    });
};

export const actionUnassingDeviceGroups = (payload) => dispatch => {
  const site_id = window.localStorage.getItem("amatis_site_id");
  const requestBody = { ...payload, site_id }
  return SDKV3.init().then(api => api.deviceGroups.unAssignDeviceGroup({ id: payload.ip_address }, { requestBody }))
    .then(({ body: { meta }}) => {
      if (meta.message === "record deleted") {
        if (App.hasOwnProperty('ConfigTables')) {
          App.ConfigTables.deviceGroupsRemove(requestBody)
        }
      }
    })
    .catch(error => {
      dispatch(listDeviceGroupsFailure(error));
    })
    .finally(() => {
      dispatch(listDeviceGroupsFulfill());
    });
};
