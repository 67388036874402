import { Issue, NormalizeIssueType } from "../types"

export const normilzeIssue: NormalizeIssueType = ({
    isIgnored,
    isResolved,
    sceneData,
    id,
    ...rest
}) => {
    const issue: Issue = {
        ...rest,
        id: id || '',
        sceneName: sceneData ? `${sceneData.name} (${sceneData.link_id})` : '',
        scene: sceneData ? sceneData.id : '',
        isIgnored: isIgnored || false,
        isResolved: isResolved || false,
    }
    return issue;
}
