import { createSelector } from 'reselect';
import Immutable from 'seamless-immutable';
import lightFormat from 'date-fns/lightFormat';

export const getEvents = state => state.events;
export const getProps = (_, props) => props;
export const getRowId = (_, { row }) => row.id;

export const isLoadingEvents = createSelector(getEvents, events => events.loading);
export const getEventsError = createSelector(getEvents, events => events.error);
export const getEventsItems = createSelector(getEvents, events => {
    const { items } = events;
    if (items === null) return Immutable([]);
    return items.map(items => {
        return items.update('info', getEventInfo)
                    .update('created_at', formatDate)
                    .update('updated_at', formatDate)
    });
});
export const getEventsNewItems = createSelector(getEvents, events => events.newItems);
export const getEventsLoadings = createSelector(getEvents, events => events.loadings);

// Meta
export const getEventsMeta = createSelector(getEvents, events => events.meta);

// Meta pagination
export const getEventsPagination = createSelector(getEventsMeta, events => events.pagination || {});
export const getEventsTotalCount = createSelector(getEventsPagination, events => events.count || '');
export const getEventsPageSize = createSelector(getEventsPagination, events => events.limit || '');
export const getEventsBefore = createSelector(getEventsPagination, events => events.before || '');
export const getEventsAfter = createSelector(getEventsPagination, events => events.after || '');

// Table Options
export const getEventsTable = createSelector(getEvents, events => events.table);
export const getEventsCurrentPage = createSelector(getEventsTable, events => {
    if (events.currentPage === null) return events.before;
    return events.currentPage;
});

export const getEventsAllItems = createSelector(
    [getEvents,getProps],
    (events, location_id) => {
        const { allItems } = events;
        if (allItems === null) return Immutable([]);
        return allItems
        .filter((item)=>{
            return item.location_id === location_id;
        })
        .map(item => {
            return item.update('info', getEventInfo)
                        .update('created_at', formatDate)
                        .update('updated_at', formatDate)
        })
});

export const getRecentEvents = createSelector(getEventsAllItems, events => {
    const items = Immutable.asMutable(events);
    return items.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
    }).slice(0, 10);
});

export const getEventsTotalPages = createSelector(getEventsTable, events => events.totalPages || '');
export const getEventsSorting = createSelector(getEventsTable, events => events.sorting);
export const getEventsGrouping = createSelector(getEventsTable, events => events.grouping);
export const getEventsExpandedGroups = createSelector(getEventsTable, events => events.expandedGroups);
export const getEventsSelection = createSelector(getEventsTable, events => events.selection);
export const getEventsExpandedRowIds= createSelector(getEventsTable, events => events.expandedRowIds);
export const getEventsPageSizes = createSelector(getEventsTable, events => events.pageSizes);
export const getEventsFilters = createSelector(getEventsTable, events => events.filters);
export const getEventsSearch = createSelector(getEventsTable, events => events.search);
export const getEventsColumns = createSelector(getEventsTable, events => events.columns);
export const getEventsColumnWidths = createSelector(getEventsTable, events => events.columnWidths);
export const getEventsColumnExtensions = createSelector(getEventsTable, events => events.columnExtensions);
export const getEventsRightColumns = createSelector(getEventsTable, events => events.rightColumns);
export const getEventsHiddenColumnNames = createSelector(getEventsTable, events => events.hiddenColumnNames);


export const getEventsNavigation = createSelector(getEventsFilters, filters => {
    const created_at = filters.filter(filter => filter.columnName === 'created_at')[0];
    return created_at.value.navigation;
});

export const getEventsNavigationDirection = createSelector(getEventsNavigation, navigation => {
    return navigation[0];
});

export const getEventsNavigationTimeStamp = createSelector(getEventsNavigation, navigation => {
    return navigation[1];
});

export const isRowDetailLoadingEvents = createSelector(
    [getEventsLoadings, getRowId],
    (loadings, id) => {
        const idExist = loadings.filter(loadingId => loadingId === id);
        return idExist.length === 1;
});

const formatDate = date => {
    if (!date) return null;
    return lightFormat(new Date(date), 'yyyy-MM-dd HH:mm:ss');
}

const getEventInfo = info => {
    try {
        return JSON.parse(info);
    } catch (err) {
        return {};
    }
}
