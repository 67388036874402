import { createAction, createActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { matchPath } from "react-router";
import { Locations } from  'api/amatis/site/locations/services/locations';
import { Scenes } from 'api/amatis/site/scenes/services/scenes';
import { Group, Groups } from 'api/amatis/site/groups/groups';
import { ActiveSite } from 'api/amatis/site/sites';
import Device from 'api/amatis/site/devices/Device';
import Devices from 'api/amatis/site/devices/services/devices';
import Location from 'api/amatis/site/locations/Location';
import Scene from 'api/amatis/site/scenes/Scene';
import Terminal from  'api/amatis/utilities/terminal';
import Communication from 'api/amatis/utilities/communications';
import User from 'classes/users';
import App from 'app/app';
import SDK from 'api/sdk';
import SDKV3 from 'api/sdkV3';

import routes from 'routes/config';
import { getLocationRoot } from 'redux/locations/selectors';
import { bugsnag } from 'classes/bugsnag';

import {
    listDevicesRequest,
    listDevicesSuccess,
    listDevicesFailure,
    listDevicesFulfill,
    clearDevices,
} from 'redux/devices';

import {
    listLocationsRequest,
    listLocationsSuccess,
    listLocationsFailure,
    listLocationsFulfill,

    openingLocationById,
} from 'redux/locations';

import {
    listScenesRequest,
    listScenesSuccess,
    listScenesFailure,
    listScenesFulfill,
} from 'redux/scenes';

import {
    listDeviceGroupsRequest,
    listDeviceGroupsSuccess,
    listDeviceGroupsFailure,
    listDeviceGroupsFulfill,
} from 'redux/device_groups';

export const SITE_LOAD_REQUEST = 'SITE_LOAD_REQUEST';
export const SITE_LOAD_SUCCESS = 'SITE_LOAD_SUCCESS';
export const SITE_LOAD_FAILURE = 'SITE_LOAD_FAILURE';
export const SITE_LOAD_FULFILL = 'SITE_LOAD_FULFILL';

export const CHANGE_SITE = 'CHANGE_SITE';
export const CHANGE_SITE_FAILURE = 'CHANGE_SITE_FAILURE';

export const CREATE_ROOT_LOCATION = 'CREATE_ROOT_LOCATION';
export const SET_LOCATION_ID = 'SET_LOCATION_ID';

export const PATCH_SITE_REQUEST = 'PATCH_SITE_REQUEST';
export const PATCH_SITE_SUCCESS = 'PATCH_SITE_SUCCESS';
export const PATCH_SITE_FAILURE = 'PATCH_SITE_FAILURE';
export const PATCH_SITE_FULFILL = 'PATCH_SITE_FULFILL';

export const PATCH_TIMEZONE_REQUEST = 'PATCH_TIMEZONE_REQUEST';
export const PATCH_TIMEZONE_SUCCESS = 'PATCH_TIMEZONE_SUCCESS';
export const PATCH_TIMEZONE_FAILURE = 'PATCH_TIMEZONE_FAILURE';
export const PATCH_TIMEZONE_FULFILL = 'PATCH_TIMEZONE_FULFILL';
export const SET_GLOBAL_TOGGLE_BOX_DEVICE_AND_SCENE = 'SET_GLOBAL_TOGGLE_BOX_DEVICE_AND_SCENE';
export const TOGGLE_BOX_DEVICE_AND_SCENE = 'TOGGLE_BOX_DEVICE_AND_SCENE';

export const SET_MQTT_STATUS = 'SET_MQTT_STATUS';

export const METRICS_REQUEST = 'METRICS_REQUEST';
export const METRICS_SUCCESS = 'METRICS_SUCCESS';
export const METRICS_FAILURE = 'METRICS_FAILURE';
export const METRICS_FULFILL = 'METRICS_FULFILL';
export const METRICS_CLEAN = 'METRICS_CLEAN';

export const {
    patchTimezoneRequest,
    patchTimezoneSuccess,
    patchTimezoneFailure,
    patchTimezoneFulfill
} = createActions(
    PATCH_TIMEZONE_REQUEST,
    PATCH_TIMEZONE_SUCCESS,
    PATCH_TIMEZONE_FAILURE,
    PATCH_TIMEZONE_FULFILL,
);

export const {
    patchSiteRequest,
    patchSiteSuccess,
    patchSiteFailure,
    patchSiteFulfill
} = createActions(
    PATCH_SITE_REQUEST,
    PATCH_SITE_SUCCESS,
    PATCH_SITE_FAILURE,
    PATCH_SITE_FULFILL,
);

export const CHANGE_TECH_LEVEL = 'CHANGE_TECH_LEVEL';
export const changeLevelRequest = createAction(CHANGE_TECH_LEVEL);

export const siteLoadRequest = createAction(SITE_LOAD_REQUEST);
export const siteLoadSuccess = createAction(SITE_LOAD_SUCCESS);
export const siteLoadFailure = createAction(SITE_LOAD_FAILURE);
export const siteLoadFulfill = createAction(SITE_LOAD_FULFILL);
export const globalToggleBoxDeviceAndScene = createAction(SET_GLOBAL_TOGGLE_BOX_DEVICE_AND_SCENE);
export const createRootLocation = createAction(CREATE_ROOT_LOCATION);
export const setLocationId = createAction(SET_LOCATION_ID);

export const changeSite = createAction(CHANGE_SITE);
export const changeSiteFailure = createAction(CHANGE_SITE);

export const toggleBoxDeviceAndScene = createAction(TOGGLE_BOX_DEVICE_AND_SCENE);

export const setMqttStatus = createAction(SET_MQTT_STATUS);

export const metricsRequest = createAction(METRICS_REQUEST);
export const metricsSuccess = createAction(METRICS_SUCCESS);
export const metricsFailure = createAction(METRICS_FAILURE);
export const metricsFulfill = createAction(METRICS_FULFILL);
export const metricsClean = createAction(METRICS_CLEAN);

export const patchSite = ({ id, ...values }) => (dispatch, getState) => {
    const state = getState();
    const locationRoot = getLocationRoot(state);
    dispatch(patchSiteRequest());
    return SDK.init()
        .then(api => {
            if (values.hasOwnProperty('dashname')) {
                const optionSDKV3 = {
                    requestBody: { name: values.dashname },
                    serverVariables: { baseVersion: 'v3' }
                };
                // TODO: legacy - update location root name
                api.locations.updateLocation({ id: locationRoot.id }, optionSDKV3);
                App.Site.locations[App.Site.root.id].name = values.dashname;
            }
            const params = { SITEID: id };
            const optionSDK = { requestBody: values };

            if (values.hasOwnProperty('timezone')) {
                const requestBodyTimezone = {
                    url: `sites/${id}/ambr/api`,
                    response_path: `sites/${id}/ambr/api/out`,
                    data:  {
                        "url": "ambrs/me/timezone",
                        "method": "POST",
                        "timezone": values.timezone
                    }
                };
                const OPTIONS_TIMEZONE = { requestBody: requestBodyTimezone, serverVariables: { baseVersion: "v2" } };
                dispatch(patchTimezoneRequest());
                api.sites.sendMqttMessageToAmbr(params, OPTIONS_TIMEZONE)
                .then(() => {
                    dispatch(patchTimezoneSuccess());
                })
                .catch(error => {
                    dispatch(patchTimezoneFailure(error));
                }).finally(() => {
                    dispatch(patchTimezoneFulfill());
                });
            }


            api.sites.patchSiteById(params, optionSDK)
            .then(({ body: { data } }) => {
                dispatch(patchSiteSuccess(data));
                // TODO: legacy - update info site
                App.Site.info = data;
            })
            .catch(error => {
                dispatch(patchSiteFailure(error));
            }).finally(() => {
                // TODO: legacy - force re-render dashboar componente
                App.SiteDashboardContainer.superForceUpdate();
                dispatch(patchSiteFulfill());
            });
        });
}


export const siteLoad = ({ id, location }) => async (dispatch) => {
    window.localStorage.setItem('ambr_fail_connect', false);
    dispatch(siteLoadRequest());
    let sites;

    if (!id) {
        dispatch(push('/select-site'));
        dispatch(siteLoadFulfill());
        return false;
    }

    try {
        sites = await User.getSitesList();
    } catch {
        dispatch(push('/select-site'));
        dispatch(siteLoadFulfill());
        return false;
    }

    const site = sites.find(site => site.siteid === id);
    if(!site) {
        dispatch(push('/select-site'));
        dispatch(siteLoadFulfill());
        return false;
    }
    User.mySites = sites;
    Terminal.loaded = false;
    App.locationsLoaded = false;
    App.activeSiteID = id;
    Devices.didBuildDeviceList = false;
    Devices.devicesNotLoadedCount = 0;

    if(site.dashname !== ''){
        window.localStorage.setItem('amatis_site_name', site.dashname);
    }
    window.localStorage.setItem('amatis_site_id', id);

    if (!Communication.getBaseURL) Communication.setBaseURL();

    const sdk = await SDK.init();

    const sdkv3 = await SDKV3.init();

    try {
        const { body: { data: { mqtt_pass = null }}} = await sdkv3.users.getUserMe();
        if (mqtt_pass) {
            User.myUser.mqtt_pass = mqtt_pass;
        }
    } catch(error) {
        console.log(error);
    }

    // Get permissions user by site
    try {
        const { body: { data: dataPermissions } } = await sdk.users.userPermissions(
            { site_id: id},
            {serverVariables: { baseVersion: 'v3' }
        });
        User.myPermissions = dataPermissions;
    } catch(error) {
        console.log(error);
    }

    document.title = `${id} Site Configuration`;
    App.Site = new ActiveSite(id, User.myUser);

    bugsnag.user = {
        id: User.myUser.id,
        name: User.myUser.email,
        email: User.myUser.email
    };

    try {
        const { body: { data }} = await sdkv3.sites.getSite({ id });
        App.Site.info = data;
        dispatch(siteLoadSuccess(data));
    } catch(error) {
        dispatch(siteLoadFailure(error));
    }

    const params = { SITEID: id };
    const OPTIONS_SDK = { serverVariables: { baseVersion: "v2" } };


    try {
         // loading devices groups by site
        try {
            dispatch(listDeviceGroupsRequest());
            const { body: { data } } = await sdkv3.deviceGroups.getDeviceGroups({ site_id: id });
            dispatch(listDeviceGroupsSuccess(data));
            buildDataStructures(data, 'device_groups');
        } catch(error) {
            dispatch(listDeviceGroupsFailure(error));
        } finally {
            dispatch(listDeviceGroupsFulfill());
        }

        // loading locations by sites
        try {
            dispatch(listLocationsRequest())
            const { body: { data } } = await sdk.locations.getLocationsBySite(params);
            dispatch(listLocationsSuccess(data));
            buildDataStructures(data, 'locations');
            dispatch(buildLocationTree(App, location));
        } catch (error) {
            dispatch(listLocationsFailure(error));
        } finally {
            dispatch(listLocationsFulfill());
        }

        // loading networks by site
        try {
            const { body: { data } } = await sdk.sites.getNetworksBySite(params, { serverVariables: { baseVersion: "v1" } });
            let networksById = {};
            for( let network of data){
                networksById[network.id] = network;
            }

            App.Site.networks = networksById;
        } catch (error) {
            console.log(error);
        }

        // loading devices by site
        try {
            dispatch(listDevicesRequest());
            const { body: { data } } = await sdkv3.devices.getDevices({ site_id: id, limit: 10000 });
            dispatch(listDevicesSuccess(data));
            buildDataStructures(data, 'devices');
            dispatch(buildLocationTree(App, location));
        } catch (error) {
            dispatch(listDevicesFailure(error));
        } finally {
            dispatch(listDevicesFulfill());
        }

        // loading scenes by site
        try {
            dispatch(listScenesRequest());
            const { body: { data } } = await sdk.scenes.getScenesBySite(params, OPTIONS_SDK);
            dispatch(listScenesSuccess(data));
            buildDataStructures(data, 'scenes');
            dispatch(buildLocationTree(App, location));
        } catch (error) {
            dispatch(listScenesFailure(error));
        } finally {
            dispatch(listScenesFulfill());
        }
    } catch (error) {
        console.log("ERROR API ", error)
        dispatch(siteLoadFailure(error));
    } finally {
        dispatch(siteLoadFulfill());
    }
};

export const clearSite = (navigation) => dispatch => {
  dispatch(changeSite());
  try {
    //Clear out all of the stored objects
    // commented because it seems to be causing iss#36
    Terminal.loaded = false;
    App.locationsLoaded = false;
    Scenes.highestLinkID = 255;
    // Render.locations.cached = false;
    // Control.nav.close('locations');
    Locations.list = [];
    Devices.list = [];
    Devices.livingNewDeviceList = [];
    Scenes.list = [];
    App.allTasks = [];
    App.taskError = null;
    App.localIP = [];
    App.activeSiteID = '';
    Communication.baseURL = '';
    Groups.list = [];
    Devices.assocGroups = [];
    Devices.assocDevicesGroups = [];
    App.activeLocationIndex = 0;
    Devices.deletedDeviceList = [];
    User.myPermissions = {};

    //We dont want any unwritten datapoints in device table to perist across sites
    localStorage.removeItem('datapointsUnderEdit');
    if(App.Site && App.Site.mqtt_unsubscribeFromTopic){
        App.Site.mqtt_unsubscribeFromTopic('sites/'+App.Site.ID+'/data');
    }
    if (App.Site && App.Site.thisAmbr) {
      App.Site.thisAmbr = false;
    }
    if (App.Site && App.Site.ambrHasState) {
        App.Site.ambrHasState = false;
    }
    if (App.Site && App.Site.refreshTO) {
        clearTimeout(App.Site.refreshTO);
    }
    if (App.Site && App.Site.mqttInitSuccess) {
        App.Site.mqttInitSuccess = false;
    }
    if (App.Site && App.Site.externalActionsToSave) {
        App.Site.root.externalActionsToSave = '';
    }
    if (App.Site && App.Site.externalActionsToSave) {
        App.ActiveLocation.externalActionsToSave = "";
    }
    if (App.Site && App.Site.readyForActions) {
        App.Site.readyForActions = 0;
    }
    if (App.Site && App.Site.hasPHD) {
        App.Site.hasPHD = false;
    }
    if (App.Site && App.Site.hasSw8) {
        App.Site.hasSw8 = false;
    }
    if (App.Site && App.Site.ActiveLocation) {
        App.Site.ActiveLocation = false;
    }

    //this.App.Site.AMBRIP = false;
    localStorage.removeItem('amatis_site_id');
    localStorage.removeItem('amatis_site_name');

    dispatch(setLocationId(null));

    dispatch(clearDevices());

    if(navigation) {
      return dispatch(push(navigation));
    }

    return ;
  } catch (error) {
    dispatch(changeSiteFailure(error));
  }
};

export const buildLocationTree = (app, locationId = null) => (dispatch) => {
    dispatch(createRootLocation());
    let location;
    // let hasRoot = false;
    for(let locationID in app.Site.locations){
        location = app.Site.locations[locationID];
        if(location.hasOwnProperty('_parent_id')){
            if(location._parent_id === 0 || location.parent_id === '0' || location.parent_id === 0){
                //hasRoot = true;
                location.isRoot = true;
                app.Site.root = location;
                if(app.ActiveLocation === false || app.ActiveLocation.ID !== locationID){
                    app.setActiveLocation(location);
                }
            } else if (app.Site.locations.hasOwnProperty(location.parent_id)){
                //This is a normal locoation
                location.parent = app.Site.locations[location.parent_id];
                location.parent.children[locationID] = location;
            } else {
                //This is orphaned and should be flagged
                Locations.orphans[locationID] = location;
            }
        }
    }

    if(locationId) {
        const legacyLocation = app.Site.locations[locationId];
        if(legacyLocation) {
            app.setActiveLocation(legacyLocation);
            dispatch(setLocationId(locationId));
            dispatch(openingLocationById(locationId));
        }
    }

    // NOTE: disable because we are not creating site from control-app
    /* if(!hasRoot){
        const rootLocName = User.siteNameFromStorage();
        Locations.create([ {parent_id: 0, name: rootLocName }]);
    } */

    return app.Site.root;
}

export const navigationLocation = (
    location = null,
) => (dispatch, getState) => {
    const { pathname } = getState().router.location;
    let isMatch;

    dispatch(setLocationId(location));
    isMatch = matchPath(pathname, { path: routes[routes.length-2].path, exact: true, strict: false });
    if (isMatch) {
        if(location) {
            if (isMatch.params.section) {
                dispatch(push(`/site/${isMatch.params.id}/location/${location}/${isMatch.params.section}`));
            } else {
                dispatch(push(`/site/${isMatch.params.id}/location/${location}`));
            }
            Scenes.getSceneTable({ location });
        } else {
            dispatch(push(`/site/${isMatch.params.id}/${isMatch.params.section}`));
        }
    }
}

export const changeTechLevel = (USERID, tech_level) => dispatch => {
    dispatch(changeLevelRequest());
    let params = {USERID, tech_level};
    let optionSDK = { requestBody: { tech_level }};
    return SDK.init()
    .then(api => {
        api.users.patchUser(params, optionSDK)
        .then(response =>{
            const storage = window.localStorage;
            storage.setItem('amatis_user', JSON.stringify(response.body.data));
        })
    });
}

const buildDataStructures = (objectList, objectType) => {

    let referenceItem = {};
    let relevantConstructor = false;

    // referenceItem will be the last object created in the list.
    // We can use any instance of any class to get the respective update UI method
    switch(objectType){
        case 'locations':
            relevantConstructor = Location;
            break;
        case 'devices':{
            relevantConstructor = Device;
            App.Site.readyForActions = App.Site.readyForActions + 1;
        }
            break;
        case 'scenes': {
            relevantConstructor = Scene;
            App.Site.readyForActions = App.Site.readyForActions + 1;
        }
            break;
        case 'device_groups':
            relevantConstructor = Group;
            break;
        default:
            break;
    }

    if (objectList.length === 0) {
        if (objectType === 'locations') {
            // Locations.addRoot();
        } else if (objectType === 'scenes') {
            // Scenes.getSceneTable();
        }
        return;
    }

    for (let item of objectList) {
        if (relevantConstructor !== false && item != null) {
            referenceItem = new relevantConstructor(item);
        }
    }

    //After we build the lists, update the respective UI component states
    if (referenceItem.hasOwnProperty('finishLoad')) {
        referenceItem.finishLoad();
    }

}

export const fetchMetrics = ({ siteId, metricParams }) => dispatch => {
    dispatch(metricsRequest());
    const params = { id: siteId };
    const OPTIONS_SDK = { serverVariables: { baseVersion: "v3" }, requestBody: metricParams };
    return SDK.init().then(api => api.sites.getMetrics(params, OPTIONS_SDK))
        .then(({ body: { data }}) => {
            dispatch(metricsSuccess(data));
        })
        .catch(error => {
            dispatch(metricsFailure(error));
        })
        .finally(() => {
            dispatch(metricsFulfill());
        });
};