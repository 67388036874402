import { createSelector } from "reselect";
import { isLoadingLocations } from "redux/locations";
export const getIdProps = (_, props) => props && props.id;
export const getActiveLocationProps = (_, props) => props.activeLocation;
export const getDevicesProps = (_, props) => props.devices;

export const getDevices = state => state.devices;
export const isLoadingDevices = createSelector(
    getDevices,
    devices => devices.loading
);
export const isCheckedDevices = createSelector(
    getDevices,
    devices => devices.checked
);
export const isAddingDevices = createSelector(
    getDevices,
    devices => devices.adding
);
export const isOpenModalDevices = createSelector(
    getDevices,
    devices => devices.openModal
);
export const isLoadingDevicesAndLocations = createSelector(
    [getDevices, isLoadingLocations],
    (devices, loadingLocations) => devices.loading && loadingLocations
);
export const getDevicesError = createSelector(
    getDevices,
    devices => devices.error
);
export const getDevicesMeta = createSelector(
    getDevices,
    devices => devices.meta
);
export const getDevicesPagination = createSelector(
    getDevicesMeta,
    meta => meta.pagination
);
export const getDevicesSorting = createSelector(
    getDevicesMeta,
    meta => meta.sorting
);
export const getDevicesGrouping = createSelector(
    getDevicesMeta,
    meta => meta.grouping
);
export const getDevicesSelection = createSelector(
    getDevicesMeta,
    meta => meta.selection
);
export const getDevicesFilters = createSelector(
    getDevicesMeta,
    meta => meta.filters
);
export const getDevicesExpandedGroups = createSelector(
    getDevicesMeta,
    meta => meta.expandedGroups
);
export const getDevicesExpandedRowIds = createSelector(
    getDevicesMeta,
    meta => meta.expandedRowIds
);
export const getDevicesReadout = createSelector(
    [getDevicesMeta, getIdProps],
    (meta, id) => {
        if (id) {
            return meta.readout.filter(
                readout => readout.parent === 0 && readout.idDevice === id
            );
        }
        return meta.readout.filter(readout => readout.parent === 1);
    }
);

export const getDevicesItems = createSelector(getDevices, devices => {
    return devices.items.map(item => {
        if (item.type === 'SWITCH-B' || item.type === 'SWITCH-W'){
            return {
                ...item,
                type: 'Tny',
                _type:'Tny',
                partType:'Tny',
                classification: 'switch',
                is_switch: true,
            };
        }
        return item;
    })
});

export const getDevicesItemsToCharts = createSelector(getDevices, devices => {
    return devices.itemsSearch.map(item => (
        {
            label: `${item.name} (${item.ip_address.slice(-4)})`,
            value: item.ip_address,
        }
    ))
});

export const getDevicesMode = createSelector(getDevices, devices => devices.mode);

export const getDeviceModeEdit = createSelector(
    [getDevicesMode, getActiveLocationProps],
    (mode, activeLocation) => mode.edit.includes(activeLocation.ID)
);

export const getDeviceModeAdd = createSelector(
    [getDevicesMode, getActiveLocationProps],
    (mode, activeLocation) => mode.add.includes(activeLocation.ID)
);

export const hasDevicesProps = createSelector(
    [getDevicesProps, getActiveLocationProps],
    (devices, activeLocation) => {
        const deviceSize = Object.keys(devices).length;
        if (deviceSize > 0) {
            return true;
        }
        if (activeLocation) {
            if (activeLocation.hasOwnProperty('name')) {
                const name = activeLocation.name.toLowerCase();
                if (name.includes('scheduling')) {
                    return true;
                }
            }
        }
        return false;
    }
);

export const isAddDeviceModeProps = createSelector(
    [getDeviceModeAdd, hasDevicesProps],
    (modeAdd, hasDevices) => modeAdd || !hasDevices
);
