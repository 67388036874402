import SDK from 'api/sdkV3';
import {
    setUsersCharts,
    saveUserCharts,
    removeUserChart,
} from './actions';

export function listChartsTrigger(site_id, limit = 50,useDebounce = true) {
    const thunk = dispatch => {
        dispatch(setUsersCharts.request());
        return SDK.init()
            .then(api => api.usersCharts.getCharts({ site_id, limit }))
            .then(response => {
                const { body: { meta, data } } = response;
                dispatch(setUsersCharts.success({ meta, data: data }));
            })
            .catch(error => {
                dispatch(setUsersCharts.failure(error));
            })
            .finally(() => {
                dispatch(setUsersCharts.fulfill());
            });
    };

    return thunk;
}

export function saveChartsTrigger(payload, useDebounce = true) {
    const thunk = dispatch => {
        const requestBody = payload;
        dispatch(saveUserCharts.request());
        return SDK.init()
            .then(api => api.usersCharts.createChart({}, { requestBody }))
            .then(response => {
                const { body: { meta, data } } = response;
                dispatch(saveUserCharts.success({ meta, data: data }));
            })
            .catch(error => {
                dispatch(saveUserCharts.failure(error));
            })
            .finally(() => {
                dispatch(saveUserCharts.fulfill());
            });
    };

    return thunk;
}

export function removeChartsTrigger(chart_id, useDebounce = true) {
    const thunk = dispatch => {
        const params = { id: chart_id }
        dispatch(removeUserChart.request());
        return SDK.init()
            .then(api => api.usersCharts.deleteChartById(params))
            .then(response => {
                const { body: { meta, data } } = response;
                dispatch(removeUserChart.success({ meta, data: data }));
            })
            .catch(error => {
                dispatch(removeUserChart.failure(error));
            })
            .finally(() => {
                dispatch(removeUserChart.fulfill());
            });
    };

    return thunk;
}
