import { hexToDec } from 'classes/methods';

const zoneLookup = { 1: 'a', 2: 'b', 3: 'c', 4: 'd', 5: 'e', 6: 'f', 7: 'g', 8: 'h' };

const phd_calculateLevelFade = (level, fade) => {
    return `00${level.toString(16)}`.substr(-2) + `00${fade.toString(16)}`.substr(-2);
}
  
const phd_combineZoneAndChannelMask = (zoneMask, chanMask) => {
    return (('00' + zoneMask.toString(16)).substr(-2)) + (('0000' + chanMask.toString(16)).substr(-4));
}
  
const phd_combineZoneChannelLevelFade = (zoneMask, chanMask, level, fade) => {
    if (!isNaN(level) && !isNaN(fade)) {
        return `0x${phd_combineZoneAndChannelMask(zoneMask, chanMask)}${phd_calculateLevelFade(level, fade)}`
    } else {
        return `0x${phd_combineZoneAndChannelMask(zoneMask, chanMask)}`
    }
}
  
const calculatorVar1 = (zonesIn = [], channelsIn = [], levelIn = null, fadeIn = null, settings) => {
    let zones = new Set(zonesIn);
    let channels = new Set(channelsIn);
    let level = parseInt(levelIn);
    let fade = parseInt(fadeIn);
    let zoneMask = 0;
    let chanMask = 0;
    //shift 1 left index times and OR it with chan17_mask_on to set that bit in chan17_mask_on
    for (let index = 0; index < 17; index++) {
      const outputNumber = index + 1;
      zoneMask = (zones.has(zoneLookup[outputNumber])) ? zoneMask = zoneMask | (1 << index) : zoneMask;
      chanMask = (channels.has(outputNumber)) ? chanMask |= 1 << index : chanMask;
    }
    const hexVar1 = phd_combineZoneChannelLevelFade(zoneMask, chanMask, level, fade);
    const decimalVar1 = parseInt(hexVar1);

    let output = (('00' + zoneMask.toString(16)).slice(-2)) + (('0000' + chanMask.toString(16)).slice(-4));
    let outputMask = hexToDec(output);

    let result;

    if(settings){
        result = { var1: decimalVar1, identifier: outputMask }
    }else{
        result = decimalVar1
    }

    return result;
}

export const reversePhdCalcMasks = (var_1) => {
    let hexNumber = parseInt(var_1).toString(16);

    let hexNumberToZonesAndChannles = '0000'+hexNumber;

    hexNumber = '0'+hexNumber;

    let fadeAndLevel = hexNumber.substr(-4)
    let returnFade = parseInt(fadeAndLevel.substr(-2),16)
    let returnLevel = parseInt(fadeAndLevel.substr(0,2),16)

    //let returnLevel = parseInt(fadeAndLevel.substr(0,2),16)
    //let returnFade = parseInt(,16)

    let hexNumberToZAndC = hexNumberToZonesAndChannles.slice(0,-4);
    let zonesAndChannels = hexNumberToZAndC.slice(-6);
    let zoneReturn = zonesAndChannels.substr(0,2);
    let channelReturn = zonesAndChannels.substr(-4);

    let zoneParse = parseInt(zoneReturn,16)
    let channelParse = parseInt(channelReturn,16)
    const allZones = []
    const allChannels = []

    for (let index = 0; index < 17; index++) {
        if( zoneParse % 2 === 1) allZones.push(zoneLookup[index+1]);
        zoneParse = zoneParse >> 1;
        
        if( channelParse % 2 === 1) allChannels.push(index+1);
        channelParse = channelParse >> 1;
    }

    let returnValues = {
        zones: allZones,
        channels: allChannels,
        level: returnLevel,
        fade: returnFade,
    }

    return returnValues;
}

export const othersDevicesCalc = (level, fade) => {
    return (`000${level}`).substr(-3) + (`000${fade}`).substr(-3);
}

export const reverseOthersDevicesCalc = (var1) => {
    let var_converted = var1.toString();
    let level = ((`0000${var_converted}`).substr((-6))).substr(0,3); 
    let fade = var_converted.substr(-3);
    return {
        level: parseInt((`000${level}`).substr(-3)),
        fade: parseInt((`000${fade}`).substr(-3)),
    }
}

export const reverseDisableMappedChannels = (var1) => {
    let allChannels = []
    let channelParse = parseInt(var1)
    for (let index = 0; index < 17; index++) {
        if( channelParse % 2 === 1) allChannels.push(index+1);
        channelParse = channelParse >> 1;
    }
    return allChannels;
}

export const calculateVar1DelayScene = (mcast, timer, linkID) => {
    let var1 = (mcast << 47)|(timer << 16)|(linkID << 0);
    return var1;
}

export default calculatorVar1;
