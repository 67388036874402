export const LIST_EVENTS = 'LIST_EVENTS';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';

export const PROCESS_EVENT = 'PROCESS_EVENT';
export const ADD_EVENT = 'ADD_EVENT';
export const RETRIEVE_EVENT = 'RETRIEVE_EVENT';
export const TABLE_EVENT = 'TABLE_EVENT';
export const PAGINATION_EVENT = 'PAGINATION_EVENT';

export const CLEAR_NEW_EVENTS = 'CLEAR_NEW_EVENTS';
