import $ from 'jquery';
import Communication from 'api/amatis/utilities/communications.js';
import User from 'classes/users.js';
import App from 'app/app.js';
import Devices from 'api/amatis/site/devices/services/devices.js';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

// import SiteDashboard from '../components/app/SiteDashboard';


var Scanner = {
    //TODO: build this out to make it a bit more flexy for different things we might do after we scan

    parseTinyWithStickerIssues: function (scannedJson) {

        var requestObj = {
            'string': scannedJson
        };
        $.ajax({
            url: Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/devices/resolve_sticker_issues',
            type: 'POST',
            data: requestObj,
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
            success: function (response) {
                response = JSON.parse(response);
                if (response.data.hasOwnProperty('new_string')) {

                    Scanner.success(response.data.newString);
                } else {
                    App.alert('error', response.errors);
                }

            },
            error: function (response) {
                response = JSON.parse(response.responseText);
                App.alert('error', 'Error retrieving correct json format for scanned information: ' + response.errors);
            }
        });
    },

    fixJSON: function (string) {
        var newString = string.substring(0, string.indexOf('sw_build:')) + 'sw_build:"",' + string.substring(string.indexOf('chan:'));
        var jsonObj;
        var swBuild = string.substr(string.indexOf('sw_build:'), string.indexOf('chan:'));
        swBuild = swBuild.substring(swBuild.indexOf(':') + 1, swBuild.indexOf(','));

        newString = newString.replace(/([{,])(\s*)([A-Za-z0-9_-]+?)\s*:/g, '$1"$3":').replace(/([:])([A-Za-z0-9_\-\s/.]+?)([,}])/g, '$1"$2"$3');
        try {
            jsonObj = JSON.parse(newString);
            jsonObj.v1.sw_build = swBuild;
        } catch (e) {
            App.alert('error', 'Invalid JSON: please check string input and try again.');
            return false;
        }

        return jsonObj;

    },
    
    scan: async (isTiny) => {
        let bodyElement = document.querySelector('body');
        let rootElement = document.querySelector('#root');
        let modalDevicesElement = document.querySelector('#devices-modal-diaglog-id');
        let buttonClose = document.createElement('button');

        let boxContainer = document.createElement('div');
        let boxChild = document.createElement('div');

        const checkPermissionScanner = async () => {
            return new Promise(async (resolve, reject) => {
              const status = await BarcodeScanner.checkPermission({ force: true });
              if (status.granted) {
                resolve(true);
              } else if (status.denied) {
                if (window.confirm('Do you want to go to the app permission settings?')) {
                    BarcodeScanner.openAppSettings();
                }
                resolve(false);
              }
            });
        };

        const prepare = async () => {
            try {
                const allowed = await checkPermissionScanner();
                if (allowed) {
                    await BarcodeScanner.prepare();
                    await startScan();
                } else {
                    window.alert('CAMERA NOT ALLOWED!');
                }
            } catch (error) {
                console.log('ERROR PREPARING SCAN ', error);
            }
        };
          
        const startScan = async () => {
            rootElement.style.opacity = '0';
            rootElement.style.background = 'transparent';
            rootElement.style.backgroundColor = 'transparent';
            bodyElement.style.background = 'transparent';
            bodyElement.style.backgroundColor = 'transparent';

            modalDevicesElement.style.opacity = '0';
            modalDevicesElement.style.background = 'transparent';
            modalDevicesElement.style.backgroundColor = 'transparent';
            
            buttonClose.innerHTML = 'Close scanner';
            buttonClose.onclick = function () { stopScan() };
            buttonClose.style.zIndex = '1600';
            buttonClose.style.width = '100vw';
            buttonClose.style.height = '50px';

            boxContainer.style.width = '100vw';
            boxContainer.style.height = '100vh';
            boxContainer.style.display = 'flex';
            boxContainer.style.justifyContent = 'space-around';
            boxContainer.style.alignItems = 'center';
            boxContainer.style.flexDirection = 'column';

            boxChild.style.width = '210px';
            boxChild.style.height = '210px';
            boxChild.style.boxShadow = '0 0 0 99999px rgba(0, 0, 0, .4)';

            bodyElement.appendChild(boxContainer);
            boxContainer.appendChild(boxChild);
            boxContainer.appendChild(buttonClose);
            BarcodeScanner.hideBackground();
            try {
                const result = await BarcodeScanner.startScan();
                if (result.hasContent && result.hasContent) {
                    Scanner.success(result.content, isTiny);
                    cleanUIScanner();
                }
            } catch (error) {
                console.log('ERROR SCANNING ', error);
            }
        };
          
        const stopScan = () => {
            cleanUIScanner();
            BarcodeScanner.showBackground();
            BarcodeScanner.stopScan();
            if (App.hasOwnProperty('ActionOverlay')) {
                App.ActionOverlay.hide();
            }
        };

        const cleanUIScanner = () => {
            buttonClose.remove();
            boxContainer.remove();
            boxChild.remove();
            rootElement.style.opacity = '1';
            rootElement.style.background = '';
            rootElement.style.backgroundColor = '';
            bodyElement.style.backgroundColor = '#202020';
            modalDevicesElement.style.opacity = '1';
            modalDevicesElement.style.background = '';
            modalDevicesElement.style.backgroundColor = '';
        }
          
        const askUser = async () => {
            await prepare();
        };
        askUser();
     },

    success: function (result, isTiny) {
        var deviceFound = false; //Flag for whether or not we find the device
        var scannedText;
        if(result.hasOwnProperty('text')){
            scannedText = result.text;
        }else{
            scannedText = result;
        }
        var scannedObj = false;
        var jsonVersion = 'v1';
        // var tempDeviceInfoObj;

        //ScannedObj = false means: IS a tiny QR code, or any other JSON object instead of device ID
        scannedObj = Scanner.checkandHandleJSON(scannedText);
        if (scannedObj !== false) {
            isTiny = Scanner.checkIfTny(scannedObj);
            if (isTiny) {
                scannedObj[jsonVersion].mac = scannedObj[jsonVersion].mac.trim();
                for (var deviceIndex = 0; deviceIndex < Devices.list.length; deviceIndex++) {
                    let device = Devices.list[deviceIndex];
                    let deviceIP = Devices.convertMacAddressToIPAddress(scannedObj[jsonVersion].mac);
                    if(device.ip_address === deviceIP){
                        //tny is found in site - just add to location
                        Scanner.addDeviceToLocation(device);
                        return true;
                    }
                }
                //tny isn't found in site - lets add it to site and to location
                Devices.addSpoofedDeviceToSite(scannedObj, App.activeLocationIndex);
                return true;
            }
        }
        let deviceID = Devices.convertScannerIPToValidID(scannedText);

        if(App.Site.devices.hasOwnProperty(deviceID)){
            let device = App.Site.devices[deviceID];
            Scanner.addDeviceToLocation(device);
        }else{
            deviceFound = -1;
        }

        //deviceFound
        //-------------------------------------------------------
        // -1 = unable to find device with IP in site (and not tny)
        // false = device already in site and location
        // true = device found in site but not location - success
        if (deviceFound === -1) {
            App.alert('error','Unable to find a device with IP: ' + scannedText);
            return false;
        }
        return true;
    },
    checkIfTny(scannedObj){
        let isTiny = false;

        //TODO: refactor: scannedObj[jsonVersion] should be set to local var
        //Add support for future versions, and loosely validate the data format
        if (scannedObj.hasOwnProperty('v1')) {
            let jsonVersion = 'v1';
            if (scannedObj[jsonVersion].hasOwnProperty('type') && (scannedObj[jsonVersion].type === 'Tiny' 
               || scannedObj[jsonVersion].type === 'tiny' 
                || scannedObj[jsonVersion].type.toLowerCase() === 'switch-b' 
                ||  scannedObj[jsonVersion].type.toLowerCase() === 'switch-w' )) {
                    isTiny = true;
            }
        }
        return isTiny;
    },
    checkandHandleJSON(scannedText){
        var scannedObj = false;

        //Normalize any fancy quotes...
        scannedText = scannedText.replace(/”/g, '"').replace(/“/g, '"');
        if (scannedText.indexOf('{') >= 0) {
            try {
                scannedObj = JSON.parse(scannedText);
            } catch (e) {
                scannedObj = Scanner.fixJSON(scannedText);
                // if (scannedObj === false) {
                //     return;
                // }
            }
        } else {
            scannedObj = false;
        }
        return scannedObj;
    },
    addDeviceToLocation(device){
        if(App.ActiveLocation.isRoot){
            App.alert('error', 'Device already exists on site.');
        }
        if(device.location_id !== App.ActiveLocation.ID){
            if(App.hasOwnProperty('DeviceContainer')){
                App.DeviceContainer.checkForStartingEmptyList();
            }
            device.setProps({location_id : App.ActiveLocation.ID});
            if(App.hasOwnProperty('SiteDashboardContainer')){
                App.SiteDashboardContainer.setLocationStateDevices(App.ActiveLocation);
            }
            App.alert('success', 'Succesfully added device to '+App.ActiveLocation.name);
        }else{
            App.alert('error', 'Device already in '+App.ActiveLocation.name);
        }
    },
    //And likely not this... maybe i should build them out.
    failure: function (error) {
        App.alert('error', error);
    },
};
export default Scanner;
