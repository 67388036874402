import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { batch } from 'react-redux'

class RouteWithSubRoutes extends PureComponent {
    componentDidMount() {
        if (this.props.actionsMount) {
            const { actionsMount, computedMatch, store: { dispatch } } = this.props;
            batch(() => {
                actionsMount.forEach(action => {
                    dispatch(action(computedMatch.params));
                });
            });
        }
    }

    componentWillUnmount() {
        if (this.props.actionsUnmount) {
            const { actionsUnmount, computedMatch, store: { dispatch } } = this.props;
            batch(() => {
                actionsUnmount.forEach(action => {
                    dispatch(action(computedMatch.params));
                });
            });
        }
    }

    render() {
        const { ...route } = this.props;
        if (route.routes) {
            return (
                <Route
                    exact={route.exact}
                    path={route.path}
                    render={props => (
                        <route.component {...props} nav={route.nav}>
                            <Switch>
                            {route.routes.map(r => (
                                <RouteWithSubRoutes key={`sub-routes-${r.path}`} {...r} />
                            ))}
                            </Switch>
                        </route.component>
                    )}
                />
            );
        }
        return <Route {...route} />;
    }
}

const RouteConfig = ({ routes, store }) => (
  <Switch>
    {routes.map(r => (
      <RouteWithSubRoutes key={`route-config-${r.path}`} store={store} {...r} />
    ))}
  </Switch>
);

export default RouteConfig;
