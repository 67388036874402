import $ from 'jquery';
import App from 'app/app';
import Communication from 'api/amatis/utilities/communications';
import { Locations } from  'api/amatis/site/locations/services/locations';
import { Scenes } from 'api/amatis/site/scenes/services/scenes';
import parser from 'cron-parser';
import CronBuilder from './cron-builder';
import getDate from 'date-fns/getDate'
import getMonth from 'date-fns/getMonth';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import SDKDevices from 'api/sdkDevices';

let Schedule = {
    //Task Constructor Object
    Task: function (scheduleID, name, linkID, time, replaced, inactive, description) {
        this.scheduleID = scheduleID;
        this.name = name;
        this.linkID = linkID;
        this.time = time;
        this.date = time;
        this.replaced = false;
        this.active = (inactive) ? 0 : 1;
        this.location = null;
        this.scene = null;
        this.nameWithLinkID = '';
        this.description = description;
    },

    getAllDateForCron: (task, startDate, endDate) => {
        const allSchedule = [];
        try {
            const schedule = parser.parseExpression(task.time, {
                currentDate: startDate,
                endDate: endDate,
                iterator: true,
            });
            // eslint-disable-next-line no-constant-condition
            while (true) {
                try {
                    const event = schedule.next();
                    const date = event.value.toString();
                    const newTask = Object.assign({}, task, { date });
                    allSchedule.push(newTask);
                    if (event.done) return allSchedule;
                } catch (e) {
                    return allSchedule;
                }
            }
        } catch (e) {
            return allSchedule;
        }
    },

    activeOnDate: (time, startDate, endDate) => {
        const schedule = parser.parseExpression(time, {
            currentDate: startDate,
            endDate: endDate,
            iterator: true,
        });
        try {
            schedule.next();
        } catch (error) {
            return false;
        }
        return true;
    },

    getSavedCronAsync: async () => {
        const siteId = window.localStorage.getItem('amatis_site_id');
        const apiDevices = await SDKDevices.init();
        try {
            const { body: {data} } = await apiDevices.sites.getSchedule({siteId});
            if('dataString' in data && (data.dataString.indexOf('No VPN') >=0 || data.dataString.indexOf('connect to host') >=0)){
                App.taskError = 'Could not connect to local AMBR';
                window.localStorage.setItem('ambr_fail_connect', true);
                App.alert('error', App.taskError, 'Please check to make sure AMBR is powered and online. If this issue persists, contact Amatis support.');
                // Disable post to slack when schedules dont load.
                // let message = `:face_vomiting: AMBR or VPN down for ${App.activeSiteID}`;
                // message = {message};
                // App.Site.post(message, 'slack-log','devices', 'v2').catch((err)=>{
                //     console.log('issue sending off to slack', err);
                // });
            }
            //'data' is a JSON object which we can access directly. If it starts by Curl it means there is an Error.
            if (typeof (data.dataString) !== 'undefined' && data.dataString.split(' ')[0] !== 'Curl') {
                App.allTasks = [];
                App.taskError = null;
                window.localStorage.setItem('ambr_fail_connect', false);
                //call back to set up the communication objects
                return Schedule.savedTaskParse(data.dataString);
            } else {
               App.taskError = 'unrecognized string';
               throw new Error('getSavedCronAsync', 200, false,'There was a problem getting your tasks');
            }
        } catch(error) {
            App.taskError = 'unrecognized string';
            throw new Error('getSavedCronAsync', 200, false,'There was a problem getting your tasks');
        }
    },

    savedTaskParse: function (testString) {
        //clear the old tasks
        App.allTasks = [];
        // let allJson = [];
        let newTask;

        testString = testString.slice(testString.indexOf('"'));
        while (testString.indexOf('jsonData=') >= 0) {
            let json = testString.slice(testString.indexOf('jsonData=') + 9, testString.indexOf(' --END'));
            let jsonUpdate = json.replace(/\\/g, '');
            json = JSON.parse(jsonUpdate);
            let humanReadable = this.humanReadable(json.time)
            let description = (humanReadable !== false) ? (humanReadable) : null;

            //We are adding the #to the time just like how it will have it commented in the bit cron reads
            if (json.time.indexOf('#') >= 0) {
                json.time = json.time.substring(1);
                newTask = new Schedule.Task(json.scheduleID, json.name, json.linkID, json.time, json.replaced, 1, description);
            } else
                newTask = new Schedule.Task(json.scheduleID, json.name, json.linkID, json.time, json.replaced, 0, description);

            //Try to find associated scenes, and add this guy as a trigger
            try {
                let sceneIndex = Scenes.getIndex(parseInt(newTask.linkID, 10), 'link_id');
                if (sceneIndex >= 0) {
                    let activeScene = Scenes.list[sceneIndex];
                    let tempObj = {
                        customName: 'Scheduled call',
                        linkID: json.name,
                        partType: 'task'
                    };

                    activeScene.hasScheduledCall = true;

                    //Remove any that already exist, because this gets called every time we change something
                    let len = activeScene.triggers.length;
                    for (let index = 0; index < len; index++) {
                        if (activeScene.triggers[index].partType === tempObj.partType &&
                            activeScene.triggers[index].linkID === tempObj.linkID) {
                            activeScene.triggers.splice(index, 1);
                        }
                    }

                    // TODO: excepted to array in object.
                    // activeScene.triggers.push(tempObj);
                }
            } catch (e) {
                console.log(e);
            }
            newTask = this.findLocationScenes(newTask);
            App.allTasks.push(newTask);
            testString = testString.slice(testString.indexOf(' --END') + 6);
        }
        // Schedule.displayTasks();
        return App.allTasks;
    },
    updateTasks(){
        //loop through App.allTasks
        for (var i in App.allTasks) {
            let task = App.allTasks[i];
            let updatedTask = this.findLocationScenes(task);
            App.allTasks[i] = updatedTask;
        }
    },
    findLocationScenes(newTask){
        for (let index in Locations.list) {
            const assocScenes = Locations.findAssocScenes(index);
            if (assocScenes.length > 0) {
                for (let scene in assocScenes) {
                    if (
                        assocScenes[scene] &&
                        assocScenes[scene].link_id &&
                        assocScenes[scene].link_id === parseInt(newTask.linkID, 10)
                    ){
                        newTask.location = Locations.list[index];
                        newTask.scene = assocScenes[scene];
                        newTask.nameWithLinkID = assocScenes[scene].nameWithLinkID ? assocScenes[scene].nameWithLinkID : '';
                    }
                }
            }
        }
        return newTask;
    },

    createOrUpdate: data => {
        const SITEID = window.localStorage.getItem('amatis_site_id');
        const url = `${Communication.getBaseURL()}/sites/${SITEID}/schedule`;
        const token = window.localStorage.getItem('amatis_token');
        const request = Object.assign({}, { data });

        return $.ajax({
            type: 'POST',
            dataType: 'json',
            data: request,
            url,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(response => {
            if(response.data.success !== true){
                throw new Error(response.errors, 200);
            }
            return response;
        });
    },

    createOrUpdateAsync: async (dataToSave) => {
        const siteId = window.localStorage.getItem('amatis_site_id');
        const apiDevices = await SDKDevices.init();
        try {
            const { body: {data} } = await apiDevices.sites.createSchedule({siteId}, { requestBody: dataToSave });
            return data;
        } catch (error) {
            throw new Error(error, 200);
        }
    },

    humanReadable: cron => {

        //input: cron format
        //output: 'Every ***' format
        // * - Every minute : * * * * *
        // * - Every hour   : ? * * * *
        // * - Every day    : ? ? * * *
        // * - Every week   : ? ? * * ?
        // * - Every month  : ? ? ? * *
        // * - Every year   : ? ? ? ? *

        let cronArray = cron.split(' ');
        let minute = cronArray[0];
        let hour = cronArray[1];
        let day = cronArray[2];
        let month = cronArray[3];
        let week = cronArray[4];
        if(week === '*' && month === '*' && day === '*' && hour === '*' && minute === '*'){
            return 'Every Minute';
        }else if(week === '*' && month === '*' && day === '*' && hour === '*' && minute !== '*'){
            if(minute === '*/2'){
                return 'Every 2 Minutes';
            }else if(minute === '*/3'){
                return 'Every 3 Minutes';
            }else if(minute === '*/5'){
                return 'Every 5 Minutes';
            }else if(minute === '1-59/2'){
                return 'Every Odd Minute';
            }else{
                return 'Every Hour';
            }
        }else if(week === '*' && month === '*' && day === '*' && hour !== '*' && minute !== '*'){
            return 'Every Day';
        }else if(week !== '*' && month === '*' && day === '*' && hour !== '*' && minute !== '*'){
            if(week === '1,2,3,4,5'){
                return 'Every Weekday';
            }else if(week === '6,0'){
                return 'Every Weekend';
            }else{
                return 'Every Week';
            }
        }
        else if(week === '*' && month === '*' && day !== '*' && hour !== '*' && minute !== '*'){
            return 'Every Month';
        }
        else if(week === '*' && month !== '*' && day !== '*' && hour !== '*' && minute !== '*'){
            return 'Every Year';
        }
        else{
            return false;
        }
    },

    createdCronBuild: values => {
        const cronExpression = new CronBuilder();
        switch (values.time) {
            case 'Hour':
                cronExpression.addValue('minute', `${values.minutes}`);
                break;
            case 'Day':
                cronExpression.addValue('hour', `${getHours(values.hour_minute)}`);
                cronExpression.addValue('minute', `${getMinutes(values.hour_minute)}`);
                break;
            case 'Week':
                cronExpression.addValue('dayOfTheWeek', [`${values.week_day}`]);
                cronExpression.addValue('hour', `${getHours(values.hour_minute)}`);
                cronExpression.addValue('minute', `${getMinutes(values.hour_minute)}`);
                break;
            case 'Weekdays':
                cronExpression.addValue('hour', `${getHours(values.hour_minute)}`);
                cronExpression.addValue('minute', `${getMinutes(values.hour_minute)}`);
                cronExpression.addValue('dayOfTheWeek', ['1','2','3','4','5']);
                break;
            case 'Weekends':
                cronExpression.addValue('hour', `${getHours(values.hour_minute)}`);
                cronExpression.addValue('minute', `${getMinutes(values.hour_minute)}`);
                cronExpression.addValue('dayOfTheWeek', ['6', '0']);
                break;
            case 'Month':
                cronExpression.addValue('dayOfTheMonth', `${values.day_for_month}`);
                cronExpression.addValue('hour', `${getHours(values.hour_minute)}`);
                cronExpression.addValue('minute', `${getMinutes(values.hour_minute)}`);
                break;
            case 'Year':
                cronExpression.addValue('dayOfTheMonth', `${getDate(values.year_hour_minute)}`);
                cronExpression.addValue('month', `${getMonth(values.year_hour_minute) + 1}`);
                cronExpression.addValue('hour', `${getHours(values.year_hour_minute)}`);
                cronExpression.addValue('minute', `${getMinutes(values.year_hour_minute)}`);
                break;
            case '*/2 * * * *':
                cronExpression.addValue('minute', '*/2');
                break;
            case '*/3 * * * *':
                cronExpression.addValue('minute', '*/3');
                break;
            case '*/5 * * * *':
                cronExpression.addValue('minute', '*/5');
                break;
            case '1-59/2 * * * *':
                cronExpression.addValue('minute', '1-59/2');
                break;
            default:
                break;
        }
        return cronExpression.build();
    },

    parseCron: cron => {
        let time, minutes, week_day, hour_minute, year_hour_minute, day_for_month;
        const [minute, hour, day, month, week] = cron.split(' ');

        if (week === '*' && month === '*' && day === '*' && hour === '*' && minute === '*') {
            time = 'Minute';
        } else if (week === '*' && month === '*' && day === '*' && hour === '*' && minute !== '*') {
            if (minute === '*/2' || minute === '*/3' || minute === '*/5' || minute === '1-59/2') {
                time = cron;
            } else {
                time = 'Hour';
                minutes = minute;
            }
        } else if (week === '*' && month === '*' && day === '*' && hour !== '*' && minute !== '*') {
            time = 'Day';
            hour_minute = new Date(); // setMinutes(setHours(, hour), minute);
            hour_minute.setHours(hour);
            hour_minute.setMinutes(minute);
            hour_minute.setSeconds(0);
            hour_minute.setMilliseconds(0);
        } else if (week !== '*' && month === '*' && day === '*' && hour !== '*' && minute !== '*') {
            if (week === '1,2,3,4,5') {
                time = 'Weekdays';
            } else if (week === '6,0') {
                time = 'Weekends';
            } else {
                time = 'Week';
                week_day = week;
            }
            hour_minute = new Date();
            hour_minute.setHours(hour);
            hour_minute.setMinutes(minute);
            hour_minute.setSeconds(0);
            hour_minute.setMilliseconds(0);
        } else if (week === '*' && month === '*' && day !== '*' && hour !== '*' && minute !== '*') {
            time = 'Month';
            day_for_month = day;
            hour_minute = new Date();
            hour_minute.setHours(hour);
            hour_minute.setMinutes(minute);
            hour_minute.setSeconds(0);
            hour_minute.setMilliseconds(0);
        } else if (week === '*' && month !== '*' && day !== '*' && hour !== '*' && minute !== '*') {
            time = 'Year';
            year_hour_minute = new Date();
            year_hour_minute.setMonth(month);
            year_hour_minute.setDate(day)
            year_hour_minute.setHours(hour);
            year_hour_minute.setMinutes(minute);
            year_hour_minute.setSeconds(0);
            year_hour_minute.setMilliseconds(0);
        }

        return {
            time,
            minutes,
            week_day,
            hour_minute,
            day_for_month,
            year_hour_minute,
        };
    }

};

export default Schedule;
