import { Model, attr, fk } from 'redux-orm';

class LocationModel extends Model {
    static modelName = 'Location';

    static fields = {
        id: attr(), // non-relational field for any value; optional but highly recommended
        name: attr(),
        description: attr(),
        room_type: attr(),
        display_index: attr(),
        is_category: attr(),
        created_at: attr(),
        updated_at: attr(),

        parent_id: fk({
            to: 'Location',
            as: 'parent',
            relatedName: 'children',
        }),
        site_id: attr(),

    };

    static reducer({ type, payload }, Location) {
        switch (type) {
            case 'LIST_LOCATIONS_SUCCESS':
                payload.forEach(location => {
                    location.lowerCaseName = location.name.toLowerCase();
                    Location.upsert(location);
                });
                break;
            // no default
        }
        // Return value is ignored.
        return undefined;
    }

    get pathStr() {
        if (this.parent_id === 0) return '';
        return this.parent.pathStr ? `${this.parent.pathStr}/${this.name}` : this.name;
    }
}

export default LocationModel;
