import OpenApi from './spec/openapi.json';
import { fetchInterface, swaggerClient } from './utils';

const { REACT_APP_API_URL_BASE_PATH } = process.env;

OpenApi.servers[0].variables.basePath.default = REACT_APP_API_URL_BASE_PATH;

const SDK = {
    _api: null,
    _fetch: fetchInterface,
    init: () => {
        if (SDK._api) return SDK._api;
        SDK._api = swaggerClient(OpenApi, SDK._fetch);
        return SDK._api;
    }
}

export default SDK;
