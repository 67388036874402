import {Locations} from  'api/amatis/site/locations/services/locations.js';
import App from 'app/app.js';
import Devices from 'api/amatis/site/devices/services/devices.js';
import {Groups} from 'api/amatis/site/groups/groups';
import Terminal from  'api/amatis/utilities/terminal.js';
import {Scenes} from './scenes.js'
//import {remove_duplicates} from 'classes/methods.js'


//We call this function once on init and look for common scene mistakes
const validateOnInit = () => {
    //Todo: Save the results of this so that we don't annoy people constantly

    //////////////////////////////////////////////////////
    //Check for the same trigger calling multiple scenes//
    //////////////////////////////////////////////////////
    let len = Devices.list.length;
    let index;
    let sameTriggerString = '';
    let active = {};
    let activeAction = {};
    let actionsWithDuplicateTrigger = [];
    let allDevices = App.Site.devices;
    let devLookup = App.Site.deviceLookup;

    for (index = 0; index < len; index++) {
        active = Devices.list[index];
        //Loop through all of the external actions for this device
        for (let actionIndex in active.externalActions) {
            activeAction = active.externalActions[actionIndex];
            //We only need to look at triggers
            if (activeAction.name === 'coap_send_action(linkID)') {
                //We have to loop through again to look for dupes...
                for (let actionIndex2 in active.externalActions) {
                    activeAction = active.externalActions[actionIndex2];
                    //look for the same trigger twice (make sure we aren't looking at ourselves though)
                    if (activeAction.name !== 'deleted' && actionIndex !== actionIndex2 &&
                        activeAction.link_id === active.externalActions[actionIndex2].link_id &&
                        activeAction.link_id === active.externalActions[actionIndex2].link_id) {
                        //Push the whole suspect action to the array for display later
                        actionsWithDuplicateTrigger.push(activeAction);
                        break;
                    }
                }
            }
        }
    }

    len = actionsWithDuplicateTrigger.length;

    //If we found duplicate triggers
    if (len > 0) {
        console.log(actionsWithDuplicateTrigger);
        sameTriggerString += '<span className="red">Site ' + App.activeSiteID +
            ': The following trigger functions are attempting to call multiple scenes.<br>';
        sameTriggerString +=
            'Each trigger function can only call one scene. Please review the following scenes:<br><span>';

        //Create the debug string
        for (index = 0; index < len; index++) {
            active = actionsWithDuplicateTrigger[index];
            sameTriggerString += '<br>LinkID: ' + active.var_1 +
                '&nbsp;' + allDevices[devLookup[active.ip_address]].nameWithLastFour + ': ' +
                active.link_id + '<br>';
        }
        sameTriggerString += '<br><br>'
        //Print this to the terminal. Maybe later this goes in a popup?
        Terminal.print(sameTriggerString, Terminal.commandCount, false, 0, false);
    }

    //////////////////////////////////////////////////////
    /////////////Look for "extra scenes"//////////////////
    //////////////////////////////////////////////////////
    let extraSceneArray = [];
    len = Scenes.list.length;
    let extraScenesString = '';
    for (index = 0; index < len; index++) {
        active = Scenes.list[index];
        if (active.located === false) {
            extraSceneArray.push(active);
        }
    }

    len = extraSceneArray.length;
    //If we found extra scenes
    if (len > 0) {
        extraScenesString += 'Site ' + App.activeSiteID + ':There are scenes on this site with no location.<br>';
        extraScenesString +=
            'Unless you created these scenes on purpose, they should be reviewed in the scenes list.<br>';

        //Create the debug string
        let activeExtraScene = extraSceneArray[index];
        for (index = 0; index < len; index++) {
            extraScenesString += activeExtraScene.name + ' (' + activeExtraScene.link_id + ')<br>';
            //Actually delete the extra scenes
            Scenes.delete(activeExtraScene.link_id, undefined, true, false);
            extraScenesString += 'deleted<br>';
        }

        //Print this to the terminal. Maybe later this goes in a popup?
        Terminal.print(extraScenesString, Terminal.commandCount, false, 0, false);
    }
    let output = validate();
    Terminal.print(output);
};

const validate =  () => {
    let outputString = 'Site Validation Test<br>************************************************<br>';
    outputString += '<span class="blue-text">******* Test For Missing Motion Triggers *****<br><br></span>';
    let activeTrigger;
    let index, len, active;
    let assocScenes, assocDevices, devicesHere, scenesHere, motionTimeoutFound, motionSenseFound, activeScene;
    let motionSenseFound2, motionTimeoutFound2;
    let activeAction;
    let pirGroupID;
    let scenesIndex;
    len = Scenes.list.length;
    let lookup = App.Site.triggerLinkIDLookup;
    let allDevices = App.Site.devices;
    let devLookup = App.Site.deviceLookup;
    for (index = 0; index < len; index++) {
        active = Scenes.list[index];
        if (active.name.toLowerCase().indexOf('unoccupied nighttime') >= 0) {
            motionTimeoutFound2 = false;
            for (let triggerIndex in active.triggers) {
                activeTrigger = active.triggers[triggerIndex];
                let activeTriggerName = lookup[allDevices[devLookup[activeTrigger.ip_address]].partType][activeTrigger.link_id];
                if (activeTriggerName === 'Motion TimeOut2') {
                    motionTimeoutFound2 = true;
                    break;
                }
            }
            if (motionTimeoutFound2 === false) {
                outputString += '<span class="red"> Scene: ' + active.name + '(' + active.link_id + ') looks like an "Unoccupied Nighttime" scene, but is missing Night Motion Timeout Trigger<br></span>'; //debug
            }
            motionTimeoutFound2 = false;
        } else if (active.name.toLowerCase().indexOf('occupied nighttime') >= 0) {
            motionSenseFound2 = false;
            for (let triggerIndex in active.triggers) {
                activeTrigger = active.triggers[triggerIndex];
                let device = allDevices[devLookup[activeTrigger.ip_address]];
                let activeTriggerName = lookup[device.partType][activeTrigger.link_id];
                if (activeTriggerName === 'Motion2') {
                    motionSenseFound2 = true;
                    break;
                }
            }
            if (motionSenseFound2 === false) {
                outputString += '<span class="red"> Scene: ' + active.name + '(' + active.link_id + ') looks like an "Occupied Nighttime" scene, but is missing Night Motion Sense trigger <br></span>'; //debug
            }
            motionSenseFound2 = false;
        } else if (active.name.toLowerCase().indexOf('unoccupied') >= 0) {
            motionSenseFound = false;
            for (let triggerIndex in active.triggers) {
                activeTrigger = active.triggers[triggerIndex];
                let device = allDevices[devLookup[activeTrigger.ip_address]];
                let activeTriggerName = lookup[device.partType][activeTrigger.link_id];
                //Sometimes we use sky commands( sw8) as triggers for motion scenes
                if (activeTriggerName === 'Motion TimeOut' || device.partType === 'AM-SW8') {
                    motionSenseFound = true;
                    break;
                }
            }
            if (motionSenseFound === false) {
                outputString += '<span class="red"> Scene: ' + active.name + '(' + active.link_id + ') looks like an "Unoccupied" scene, but is missing Motion Timeout trigger <br></span>'; //debug
            }
            motionSenseFound = false;
        } else if (active.name.toLowerCase().indexOf('occupied') >= 0) {
            motionSenseFound = false;
            for (let triggerIndex in active.triggers) {
                activeTrigger = active.triggers[triggerIndex];
                let device = allDevices[devLookup[activeTrigger.ip_address]];
                let activeTriggerName = lookup[device.partType][activeTrigger.link_id];
                //Sometimes we use sky commands( sw8) as triggers for motion scenes
                if (activeTriggerName === 'Motion' || device.partType === 'AM-SW8') {
                    motionSenseFound = true;
                    break;
                }

            }
            if (motionSenseFound === false) {
                outputString += '<span class="red"> Scene: ' + active.name + '(' + active.link_id + ') looks like an "Occupied" scene, but is missing Motion Sense trigger <br></span>'; //debug
            }
            motionSenseFound = false;
        }

    }
    outputString += '<span class="blue-text"><br>-- End of Motion Trigger test. No output is good --<br></span>';

    outputString += '<br>************************************************<br>';
    outputString += '<span class="blue-text">*********** Test For Missing Actions ***********<br><br></span>';
    len = Locations.list.length;
    for (index = 1; index < len; index++) {
        active = Locations.list[index];

        //Skip parent locations, they will make crazy numbers
        if (Object.keys(active.children).length > 0) {
            continue;
        }

        assocScenes = active.scenes; //This is an array of Obj
        assocDevices = active.devices; //This is an Array of indexes
        devicesHere = 0;
        scenesHere = Object.keys(assocScenes).length;
        for (let id in assocDevices) {
            let activePartType = assocDevices[id].partType;
            // 		    	console.log(Devices.list[assocDevices[deviceIndex]].partType); //debug
            if (activePartType !== 'Tny' &&
                activePartType !== 'AM-SW-ML' &&
                activePartType !== 'AM-SW-M' &&
                activePartType !== 'AM-SW8' &&
                activePartType !== 'AM-PSM') {
                devicesHere++;
            }
        }
        for (let id in assocScenes) {
            activeScene = assocScenes[id];
            //remove_duplicates(activeScene.actions);

            //  	    		console.log(activeScene.actions.length + ' : ' + devicesHere ); //debug
            if (Object.keys(activeScene.actions).length < devicesHere && activeScene.name.toLowerCase().indexOf('motion group') < 0) {
                outputString += 'Scene: ' + activeScene.name + ' (' + activeScene.link_id + ') may be missing an action <br>'; //debug
                outputString += '--- It has only ' + activeScene.actions.length + ' actions in it, but there are ' + devicesHere + ' devices in ' + active.name + '<br><br>'; //debug
            }
        }
    }

    outputString += '<span class="blue-text"><br>-- End of missing action test. No output is good --<br></span>';

    outputString += '<br>************************************************<br>';
    outputString += '<span class="blue-text">********** Test For Missing Daytime Occupancy **********<br><br></span>';

    len = Locations.list.length;
    for (index = 1; index < len; index++) {
        active = Locations.list[index];

        //Skip parent locations, they will make crazy numbers
        //Skip any locations that might be a master
        if (Object.keys(active.children).length > 0 || active.name.toLowerCase().indexOf('master') >= 0) {
            continue;
        }
        assocScenes = active.scenes; //This is an array of Obj
        assocDevices = active.devices; //This is an Array of indexes
        devicesHere = 0;
        scenesHere = Object.keys(assocScenes).length;
        motionTimeoutFound = false;
        motionSenseFound = false;
        for (let id in assocScenes) {
            activeScene = assocScenes[id];
            if (activeScene.name.toLowerCase().trim().indexOf('unoccupied') >= 0) {
                motionTimeoutFound = true;
            } else if (activeScene.name.toLowerCase().trim().indexOf('occupied') >= 0) {
                motionSenseFound = true;
            }
        }
        if (motionTimeoutFound === false) {
            outputString += active.name + ' <span class="red">is missing an "Unoccupied" scene.<br></span>'; //debug
        }
        if (motionSenseFound === false) {
            outputString += active.name + ' <span class="red">is missing an "Occupied" scene.<br></span>'; //debug
        }

    }

    outputString += '<span class="blue-text"><br>-- End of missing Daytime Occupancy test. No output is good --<br></span>';
    outputString += '<br>************************************************<br>';
    outputString += '<span class="blue-text">********** Test For Missing Nighttime Occupancy **********<br><br></span>';
    len = Locations.list.length;
    for (index = 1; index < len; index++) {
        active = Locations.list[index];

        //Skip parent locations, they will make crazy numbers
        //Skip any locations that might be a master
        if (Object.keys(active.children).length > 0 || active.name.toLowerCase().indexOf('master') >= 0) {
            continue;
        }

        assocScenes = active.scenes; //This is an array of Obj
        assocDevices = active.devices; //This is an Array of indexes
        devicesHere = 0;
        scenesHere = assocScenes.length;

        motionTimeoutFound2 = false;
        motionSenseFound2 = false;
        for (let id in assocScenes) {
            activeScene = assocScenes[id];
            if (activeScene.name.toLowerCase().trim().indexOf('unoccupied nighttime') >= 0) {
                motionTimeoutFound2 = true;
            } else if (activeScene.name.toLowerCase().trim().indexOf('occupied nighttime') >= 0) {
                motionSenseFound2 = true;
            }
        }

        if (motionTimeoutFound2 === false) {
            outputString += active.name + ' <span class="red">is missing an "Unoccupied Nighttime" scene.<br></span>'; //debug
        }
        if (motionSenseFound2 === false) {
            outputString += active.name + ' <span class="red">is missing an "Occupied Nighttime" scene.<br></span>'; //debug
        }
        if (motionSenseFound === false || motionTimeoutFound === false || motionSenseFound2 === false || motionTimeoutFound2 === false) {
            outputString += '<br>';
        }

    }

    outputString += '<span class="blue-text"><br>-- End of missing NightTime Occupancy test. No output is good --<br></span>';

    //
    //outputString += "<br>************************************************<br>";
    //outputString += "**********  Test For Corrupted Daylighting **********<br><br>";
    //let assocScenes, assocDevices, devicesHere, scenesHere, dllFound, mlFound;
    //let len = Locations.list.length;
    //for(let index = 1; index < len; index++){
    //    active = Locations.list[index];
    //
    //    //Skip parent locations, they will make crazy numbers
    //    //Skip any locations that might be a master
    //    if(active.children.length > 0 || active.name.toLowerCase().indexOf("master") >= 0){
    //        continue;
    //    }
    //
    //    assocScenes = Locations.findAssocScenes(index); //This is an array of Obj
    //    assocDevices = Locations.findAssocDevices(index); //This is an Array of indexes
    //    devicesHere = 0;
    //    scenesHere = assocScenes.length;
    //
    //    dllFound = false;
    //    mlFound = false;
    //
    //    for(let scenesIndex = 0; scenesIndex < scenesHere; scenesIndex++){
    //        activeScene = assocScenes[scenesIndex];
    //
    //        if(activeScene.name.toLowerCase().trim().indexOf('dll') >= 0 || activeScene.name.toLowerCase().trim().indexOf('daylight') >= 0){
    //            dllFound = true;
    //            outputString += '<br> Found Daylighting here <br>';
    //            break;
    //        }
    //    }
    //
    //    let minIndex = 0;
    //    let maxIndex = App.Site.deviceData.length - 1;
    //    let currentIndex;
    //    let currentElement;
    //    let deviceIndex = false;
    //
    //    if(dllFound !== false){
    //        //outputString += active.name + ' is missing an "Unoccupied Nighttime" scene.<br>'; //debug
    //        for(let deviceIndex = 0; deviceIndex < assocDevices.length; deviceIndex++){
    //            activeDevice = Devices.list[assocDevices[deviceIndex]];
    //            activePartType = activeDevice.partType;
    //            //Should check roll as well. But need to check all of the nodes at flint first
    //            //if(activePartType !== 'AM-SW-ML' && activeDevice.role.indexOf('dll') >= 0){
    //            if(activePartType === 'AM-SW-ML'){
    //                mlFound = true;
    //                outputString += ' Found ML ' + active.name + ' <br>';
    //                deviceIP = activeDevice.IP;
    //                //Binary search for the data for the masterML
    //                while (minIndex <= maxIndex) {
    //                    currentIndex = (minIndex + maxIndex) / 2 | 0;
    //                    currentElement = App.Site.deviceData[currentIndex].ip;
    //                    if (currentElement === deviceIP) {
    //                        deviceIndex = currentIndex;
    //                        break;
    //                    }
    //                    else if (currentElement < deviceIP) {
    //                        minIndex = currentIndex + 1;
    //                    }
    //                    else { //by process of elimination
    //                        maxIndex = currentIndex - 1;
    //                    }
    //                }
    //
    //                if(deviceIndex !== false && App.Site.deviceData.hasOwnProperty(deviceIndex)){
    //                    activeData = App.Site.deviceData[deviceIndex].data;
    //                    activeDataLen = activeData.length;
    //                }
    //
    //                //Find the interesting data points in the data set for this device
    //                for(let dataIndex = 0; dataIndex < activeDataLen; dataIndex++){
    //                    activeDataItem = activeData[dataIndex];
    //                    if(activeDataItem.dname === 'Lt Enable'){
    //                        if(activeDataItem.cval !== '0'){
    //                            outputString += '<br>' + active.parent.name + '/' +  active.name + ' needs to have daylight sensing enabled <br>';
    //                        }
    //                    }
    //                }
    //                devicesHere++;
    //            }
    //        }
    //
    //        if(dllFound !== false){
    //            outputString += '<br>' + active.parent.name + '/' +  active.name + ' needs an ML <br>';
    //        }
    //    }
    //
    //
    //
    //
    //}
    //
    //
    //outputString += "<br>-- End of Corrupted Daylighting test. No output is good --<br>"

    outputString += '<br>************************************************<br>';
    outputString += '<span class="blue-text">*********** Test For Missing Switch Triggers ***********<br><br></span>';
    let missingSwitchTrigger = {};
    len = Scenes.list.length;
    for (index = 0; index < len; index++) {
        //This is only applicable at 661, 1177 W hastings
        if (App.activeSiteID !== '661') {
            outputString += 'Tiny Bridge test not applicable for this site <br>';
            break;
        }

        //Make sure this is a valid scene index. Not sure why it wouldnt be though
        if (Scenes.list.hasOwnProperty(index)) {
            active = Scenes.list[index];
        } else {
            continue;
        }
        //Make sure its a valid location object (still not sure why this would ever fail
        if (active.hasOwnProperty('location') && active.location === false && active.location.isRoot === true) {
            console.log('INVALID LOCATION PROPERTY'); //debug
            console.log(active, active.location); //debug
            continue;
        } 

        let locationStr = active.location.name;
        //Skip the open offices. Ok because its site specific righ now
        if (locationStr.toLowerCase().indexOf('open') >= 0) {
            break;
        }

        if (active.name === 'On' || active.name === 'Raise Lights' || active.name === 'Lower Lights' || active.name === 'Off' || active.name === 'Halt Fade') {
            let tbFound = false;
            for (let action in active.triggers) {
                activeAction = active.triggers[action];
                try {
                    //We are looking for the repeat action, and making sure its repeating this linkID
                    // 				    	console.log(activeAction.name); //debug
                    if ('tiny_ip' in activeAction && activeAction.name === 'coap_repeat_action(linkID)' && Devices.switch.getLinkIDFromVar1(activeAction.var_1) === active.link_id) {
                        tbFound = true;
                        break;
                    }
                } catch (e) {
                    console.log(activeAction); //debug
                }
            }
            if (tbFound === false) {
                missingSwitchTrigger[active.id] = active;
            }
            tbFound = false;
        }
    }
    if(Object.keys(missingSwitchTrigger).length > 0){
        outputString += ' <span class="red">Switch control scenes mising switch triggers:<br><br></span>'; //debug
        for(let scene in missingSwitchTrigger){
            let active = missingSwitchTrigger[scene];
            outputString +=  '<br> + '+active.name + '(' + active.link_id + ') at ' + active.location.name +'<br>'
        }
        outputString += ' <span class="red">!--------------Scenes Missing Switch Triggers!--------------!<br><br></span>'; //debug
    }else{
        outputString += '<span class="green"><br>-- All Switch Control scenes have proper switch triggers.--<br></span>';
    }
    outputString += '<span class="blue-text"><br>-- End of Switch Trigger Test.--<br></span>';
    outputString += '<br>************************************************<br>';
    outputString += '<span class="blue-text">*********** Test For ML2 Errors ***********<br><br></span>';
    //Find the PIR group ID
    len = Groups.list.length;
    for (index = 0; index < len; index++) {
        if (Groups.list[index].name === 'PIR') {
            pirGroupID = Groups.list[index].ID;
            break;
        }
    }
    len = Devices.list.length;
    let deviceShouldHaveML = false;
    let badCount = 0;
    let totalCount = 0;

    for (index = 0; index < len; index++) {
        active = Devices.list[index];
        deviceShouldHaveML = false;
        for (let assocGroupIndex = 0; assocGroupIndex < active.assocGroups.length; assocGroupIndex++) {
            if (active.assocGroups[assocGroupIndex] === pirGroupID) {
                deviceShouldHaveML = true;
                totalCount++;
                break;
            }
        }
        if (deviceShouldHaveML === false) {
            continue;
        }

        let activeData = active.dataTable;
        //Find the interesting data points in the data set for this device
        for (let id in activeData) {
            let activeDataItem = activeData[id];
            if (activeDataItem.dname === 'PIR Value') {
                if (isNaN(activeDataItem.cval)) {
                    badCount++;
                    outputString += '<br><span class="red">' + active.nameWithLastFour+ ' has a bad PIR value of: ' + activeDataItem.cval + '<br></span>';
                } else if ((active.now - activeDataItem.timestamp) / 60 > 60) {
                    badCount++;
                    outputString += '<span class="yellow"><br>' + active.nameWithLastFour + ' has a PIR value thats ' + parseInt(((active.now - activeDataItem.timestamp) / 60)) + 'm old <br></span>';
                }
            }
        }
    }
    outputString += '<span class="green"><br> Summary: Of the ' + totalCount + ' MLs here, ' + badCount + ' are no longer reporting motion(' + parseInt((badCount / totalCount) * 100) + '% failure)<br></span>';
    outputString += '<span class="blue-text"><br>-- End of ML2 test. No output is good --<br></span>';

    outputString += '<br>************************************************<br>';
    outputString += '<span class="blue-text">*********** Test For Master NAN Errors ***********<br><br></span>';

    //Find the PIR group ID
    len = Groups.list.length;
    badCount = 0;
    totalCount = 0;
    for (index = 0; index < len; index++) {
        if (Groups.list[index].name === 'PIR') {
            pirGroupID = Groups.list[index].ID;
            break;
        }
    }
    len = Devices.list.length;
    for (index = 0; index < len; index++) {
        active = Devices.list[index];

        if (active.role !== 'master_motion' && active.role !== 'master_motion_dll') {
            continue;
        }
        totalCount++;

        let activeData = active.dataTable;
        //Find the interesting data points in the data set for this device
        for (let id in activeData) {
            let activeDataItem = activeData[id];
            if (activeDataItem.dname === 'PIR Value') {
                if (isNaN(activeDataItem.cval)) {
                    badCount++;
                    outputString += '<br><span class="red">' + active.nameWithLastFour + ' has role "' + active.role + '", but pir value: ' + activeDataItem.cval + '<br></span>';
                } else if ((active.now - activeDataItem.timestamp) / 60 > 60) {
                    badCount++;
                    outputString += '<br><span class="yellow">' + active.nameWithLastFour + ' has role "' + active.role + '", but pir value is ' + parseInt(((active.now - activeDataItem.timestamp) / 60)) + 'm old <br></span>';
                }
            }
        }
    }
    outputString += '<span class="green"><br> Summary: Of the ' + totalCount + ' masters here, ' + badCount + ' are no longer reporting motion(' + parseInt((badCount / totalCount) * 100) + '% failure)<br></span>';
    outputString += '<span class="blue-text"><br>-- End of Master NAN test. No output is good --<br></span>';

    outputString += '<br>************************************************<br>';
    outputString += '<span class="blue-text">************* Test For Sloppy GM/GN  ***********<br><br></span>';
    //Root location here
    active = Locations.list[0];
    devicesHere = Devices.list.length;
    scenesHere = Scenes.list.length;
    let closedHours = -1;
    let openHours = -1;

    for (scenesIndex = 0; scenesIndex < scenesHere; scenesIndex++) {
        activeScene = Scenes.list[scenesIndex];
        closedHours = activeScene.name.toLowerCase().indexOf('closed hours');
        openHours = activeScene.name.toLowerCase().indexOf('open hours');
        if (openHours >= 0 || closedHours >= 0) {
            let actionCount = 0; // eslint-disable-line
            let deviceCount = 0; // eslint-disable-line
            let activeDevice;
            let actionLen = Object.keys(activeScene.actions).length;
            let location = activeScene.location;
            let devices = location.devices;
            outputString += '<br>Scene: ' + activeScene.name + ' (' + activeScene.link_id + ') [actions: ' + actionLen + ']: <br>'; //debug
            //Loop through all devices
            for (let id in devices) {
                activeDevice = devices[id];
                //Skip tinies
                if (activeDevice.partType === 'Tny' || activeDevice.partType === 'Tiny') {
                    continue;
                }
                let deviceFound = false;
                let multiple = false;
                //Increment relevant device count
                deviceCount++;
                for (let id in activeScene.actions) {
                    activeAction = activeScene.actions[id];
                    if (activeAction.ip_address === activeDevice.ip_address) {
                        if(deviceFound === true){
                            multiple = true;
                        }
                        deviceFound = true;
                    }
                }
                //Open Hours Handler
                if (openHours >= 0) {
                    //How many times do we expect a device to show up?
                    //if (deviceFound === 1) {
                    //    outputString += activeDevice.getName(true) + ' only shows up ' + deviceFound + ' times.<br>'; //debug
                    //}else
                    if (deviceFound === false) {
                        outputString += '<span class="red">'+activeDevice.nameWithLastFour+ ' is missing.<br></span>'; //debug
                    } else if (multiple === true) {
                        outputString += '<span class="red">'+activeDevice.nameWithLastFour + ' Apears multiple times.<br></span>'; //debug
                    }
                }
                //Closed Hours Handler
                if (closedHours >= 0 && activeDevice.role !== null && activeDevice.role.indexOf('master_motion') >= 0) {
                    //How many times do we expect a device to show up?
                    //if (deviceFound === 1) {
                    //    outputString += activeDevice.getName(true) + ' only shows up ' + deviceFound + ' times.<br>'; //debug
                    //}else
                    if (deviceFound === true) {
                        outputString += '<span class="red">'+activeDevice.nameWithLastFour + ' is missing.<br></span>'; //debug
                    }
                }
                deviceFound = 0;
            }
        }
    }

    outputString += '<span class="blue-text"><br>-- End of GM/GN test. No output is good --<br></span>';

    outputString += '<br>************************************************<br>';
    outputString += '<span class="green"/><br> Summary: Of the ' + totalCount + ' masters here, ' + badCount + ' are no longer reporting motion(' + parseInt((badCount / totalCount) * 100) + '% failure)<br></span>';
    outputString += '<br>-- End of Master NAN test. No output is good --<br>';
    outputString += '<br>-- End of Scene Validator --<br>';
    return outputString;
};

export {validateOnInit, validate}
