import App from 'app/app.js';
import Devices from 'api/amatis/site/devices/services/devices.js';
import Amatis from 'api/amatis/amatis.js';
import {decToHex} from 'classes/methods.js';
import {Scenes} from 'api/amatis/site/scenes/services/scenes.js';

export default class Scene extends Amatis{
    constructor(infoObject, ghost=false){
        super(infoObject);

        this.amatisType = 'scenes';
        this.actions = {};
        this.triggers = {};
        this.switchActions = {};
        this.linkedSwitches = {};
        this.softDeleted = false;
        this.link_id = infoObject.link_id;
        if(this.link_id > Scenes.highestLinkID && this.link_id < 65400){//magic number for reserved link iDs?
            Scenes.highestLinkID = this.link_id;
        }
        //Methods
        this.updateUIComponents = this.updateUIComponents.bind(this);
        this.finishLoad = this.finishLoad.bind(this);
        this.patchResourceV3 = this.patchResourceV3.bind(this);
        //Push this thing to the list(s) that holds it
        if(!ghost){
            App.Site.scenes[this.ID] = this;
            App.Site.sceneLookup[this.link_id] = this.ID;
            Scenes.list.push(this);
        }
        this.whiteList = new Set([
            'name',
            'room_type',
            'description',
            'display_index',
            'is_deleted',
            'hidden',
        ]);

    }
    finishLoad(){
        if(App.Site.readyForActions === 2){
            Scenes.getSceneTable();
            // Get deleted linktables for the entire site
            Scenes.getSceneTable({ deleted: true });
        }
        this.updateUIComponents();
    }
    updateUIComponents(){
        if(App.updateUIComponentsIsBusy('Scene', this.updateUIComponents)){
            return;
        }

        if(App.hasOwnProperty('SceneContainer')){
            let scenes = (App.ActiveLocation.isRoot ? App.Site.scenes : App.ActiveLocation.scenes);
            App.SceneContainer.setSceneList(scenes, true);
        }
        if(App.hasOwnProperty('PageContent')){
            App.PageContent.setSceneList(App.Site.scenes, true);
        }
        if(App.hasOwnProperty('ScheduleContainer')){
            App.ScheduleContainer.refreshScenes();
        }
    }
    destroy(fromCloud=false, many=false){
        this.softDeleted = true;
        if(fromCloud === false){
            this.prepareAssociatedDeviceActionsForDeletion(); //.delete wants sceneindex as param needs to be made a method
            this.delete();
        }
        if(this.location !== false){
            this.location.calcFeaturesApplied();
        }
        if((many === false || many === 0) && fromCloud === false){
            App.Site.root.post({'data':'['+App.Site.root.externalActionsToSave+']'}, false,  'site_table_edit', 'v2').then((newActions) => {
                Devices.parseActions(newActions);
                App.Site.root.externalActionsToSave = "";
            });
        }
        this.updateUIComponents();
    }
    get location_id(){
        return this._location_id;
    }

    set name(name){
        this._name = name;
    }

    get name(){
        if (this._name === 'NULL' || typeof (this._name) === 'undefined' || this._name === 'undefined') {
            return `Scene  ${(this.link_id >= 0) ? (this.link_id - 255) : `Unknown`}`;
        } else {
            return this._name ;
        }
    }

    get nameWithLinkID(){
        let linkIDString = 'Unknown';
        let sceneNumberString = 'Unknown';

        if (this.link_id >= 0) {
            linkIDString = this.link_id;
            sceneNumberString = this.link_id - 255;
        }

        if (this._name === 'NULL' || typeof (this._name) === 'undefined' || this._name === 'undefined') {
            return `Scene  ${sceneNumberString} (${linkIDString})`;
        } else {
            return `${this._name} (${linkIDString})` ;
        }
    }

    //store highest link id at time of building all scene objects for building new scenes later
    /*set link_id(link_id){

    }*/

    set location_id(location_id){
        // if(App.Site.hasOwnProperty('locations') && App.Site.locations.hasOwnProperty(location_id)){
        //     App.Site.locations[location_id].scenes[this.id] = this;
        //     this._location_id = location_id;
        // }else{
        //     this._location_id = false;
        // }
        this._location_id = location_id;
    }

    get location(){
        if(App.Site.hasOwnProperty('locations') && App.Site.locations.hasOwnProperty(this._location_id)){
            return App.Site.locations[this._location_id];
        }else{
            return false;
        }
    }
    parseActionFunction (actionFunction, deviceIP){
        //Decide if the action function is a 'trigger' or an 'action' in the applicable scene
        switch (actionFunction.name) {
            case 'deleted':
                break;
            //All trigger action functions have this string
            case 'coap_send_action(linkID)':
                this.triggers[actionFunction.id] = actionFunction;
                if(actionFunction.name !== 'deleted'){
                    Scenes.checkAssociatedActionData(this, actionFunction);//see if its something with a var1 that will fill in our featureSettings/location display data
                }
                break;
                //This is the special mac Action case
            case 'MAC Action(0xLinkID, 0xMAC)':
                this.addSwitchAction(actionFunction, deviceIP);
                break;
            //The remaining case is treated as an action
            default:
                this.actions[actionFunction.id] = actionFunction;
                if(actionFunction.name !== 'deleted'){
                    Scenes.checkAssociatedActionData(this, actionFunction);//see if its something with a var1 that will fill in our featureSettings/location display data
                }
        }
    }
    get isSwitchControl() {
        if(this.description === 'on' || this.description === 'off' || this.description === 'raise-lights' || this.description === 'lower-lights' || this.description === 'halt-fade'){
            return true;
        }else{
            return false;
        }
    }
    addSwitchAction (actionFunction, deviceIP){
        let tinyIP, tinyMAC;
        //remove corrupted switch actions if they happen to exist
        let hexVar1 = decToHex(actionFunction.var_1); //Special Mojo for handling 64bit let properly
        tinyMAC = hexVar1.substr(-12).toUpperCase(); //Last 12 Chars is the mac
        //get the switch (tiny) IP to find the device entry
        //NOTE: need to correctly bitflip or something somewhere, tiny ip is not matching up between action and
        //device in the list, one character off at the beginning, applying info to host device for now to maintain functionality
        tinyIP = Devices.convertMacAddressToIPAddress(tinyMAC);
        //Find the actual tiny device using the mac address we just found
        //If we found it
        if (App.Site.deviceLookup.hasOwnProperty(deviceIP)) {
            let hostDevice = App.Site.devices[App.Site.deviceLookup[deviceIP]];
            //Finishing Constructing this 'fake' action object so that it looks like all the rest of the triggers
            actionFunction.host_device_ip = deviceIP;
            actionFunction.tiny_ip = tinyIP;
            hostDevice.externalActions[actionFunction.id] = actionFunction
            this.triggers[actionFunction.id] = actionFunction;
        }
    }
    set hidden(value){
        if(value === "0" || value === 0 || value === false){
            this._hidden = 0;
        }else{
            this._hidden = 1;
        }
    }
    get hidden(){
        return this._hidden;
    }
    prepareAssociatedDeviceActionsForDeletion () {
        //Scenes are a really reference to actions in the devices tables
        //We need this function to find those actions in devices and remove them
        const handleDeviceActionDelete = (action) => {
            action.name = 'deleted';
            action.table_row = '65535';
            App.Site.root.insertActionToSave(action);
        }
        //Loop over all triggers and actions and remove the actions from the devices tables
        for (let id in this.triggers) {
            handleDeviceActionDelete(this.triggers[id]);
            //TODO: MAC action/tiny things might need to be handled differentlly now but...
            //     i think they are all standard at ip_address now
        }
        for (let id in this.actions) {
            handleDeviceActionDelete(this.actions[id]);
        }
    }

    async patchResourceV3 (apiv3, params, payload) {
        return await apiv3.scenes.updateScene(params, payload);
    }
}
