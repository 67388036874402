import $ from 'jquery';
import jQuery from 'jquery';
import {Locations} from  'api/amatis/site/locations/services/locations.js';
import App from 'app/app.js';
import Devices from 'api/amatis/site/devices/services/devices.js';
import User from 'classes/users.js';
import {Groups} from 'api/amatis/site/groups/groups';
// import {Site} from 'api/amatis/site/sites';
import Commands from 'api/amatis/utilities/commands.js';
import { object } from 'prop-types';
import SDK from 'api/sdk';

let Terminal = {

    commandString: '',
    lastCommand: '',
    commandHistory: [],
    commandList: [],
    deviceCache: {},
    locationCache: {},
    commandCount: 0,
    historySeekCount: 0,
    firstUpwardSeek: false,
    userInfo: '',
    syncFailed: false,
    firstOpen: false,
    scrolled:false,
    otaHistory: {
        ID: [],
        count: []
    },
    multiReturn: false,
    exactMatch: false,
    printMultipleCount: [],
    loaded: false,
    otaQueue: [],
    resetCounts: {},
    originalCounts: {},
    history: '',
    Command: function (mainCommand) {
        this.commandStr = '';
        this.mainCommand = mainCommand;
        this.optionsList = [];
        this.recurseCount = null;
        this.recurseData = null;
        this.specialOption = '';
        this.specialOptionParam = '';
        this.commandOptions = {}; //to be sent in as an object where the option is the key and the value is the argument to be sent with that option
        this.defaultOptionParam = ''; //to be set in the case that no option is set and there is a parameter
    },
    singleWordCommands: {
        history: '',
        clear: '',
        validate: '',
        listxa: '',
        help: '',
        stuset: '',
        locations:'',
        duplicatecleanup: '',
    },

    specialCaseCommands: {
        site: '',
        help: '',
        locations: '',
        validate: '',
        history: '',
        duplicatecleanup: '',
        addswitch:'',
        listxa: '',
        ambr:'',
        mcast_linkid: '',
        undelete: '',
        stuset: '',
    },

    updateScroll: function(){
        if(!Terminal.scrolled){
            const element = document.getElementById("terminal-text-body-container");
            if (element) {
                element.scrollTop = element.scrollHeight;
            }
        }
    },
    setScroll:function(){
        $("#terminal-text-body-container").off().on('scroll', function(){
            // This seems to make a mess below, Scroll looks better if we just set this to false
            Terminal.scrolled = false;
            // if($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight - 1) {
            //     Terminal.scrolled=false;
            // }else{
            //     Terminal.scrolled=true;
            // }
        });
    },
    autoRun:function(){
        if(Terminal.firstOpen === true){
            // Terminal.parseCommand('validate ');
            Terminal.parseCommand('devices -all -count');
            Terminal.firstOpen = false;
        }else{
            Terminal.parseCommand('');
        }
    },

    loadInfo: function () {
        let site = App.activeSiteID;
        // let location = App.activeLocationIndex; //not sure if i want this in there yet but just in case
        let infoString = '/site/' + site + ': ';
        Terminal.userInfo = infoString;
        $('#terminal-user-info').text(Terminal.userInfo);
        $('#terminal-text-body').bind('DOMSubtreeModified', function () {
            Terminal.updateScroll();
        });
        Terminal.firstOpen = true;
        if (!(localStorage.getItem('commandHistory') === null)) {
            Terminal.commandHistory = JSON.parse(localStorage.getItem('commandHistory'));
        }
        Terminal.loaded = true;
        Terminal.firstOpen = true;
    },
    handleSpecialKeyPress: function (keyCode) {
        let commandText = $('#command-text').val().trim();
        if (commandText === 'devices') {
            commandText = 'devices -all';
        }
        let length = commandText.length;
        switch (keyCode) {
            case 13: //ENTER
                if (Terminal.singleWordCommands.hasOwnProperty(commandText)) {
                    let newCommand = new Terminal.Command(commandText.substr(0, length));
                    newCommand.commandString = commandText;
                    Terminal.commandList.push(newCommand);
                    Terminal.commandCount++;
                }
                Terminal.firstSpace = false;
                Terminal.firstUpwardSeek = false;
                if (Terminal.parseCommand(commandText)) {
                    Terminal.commandHistory.push($('#command-text').val());
                    localStorage.setItem('commandHistory', JSON.stringify(Terminal.commandHistory));
                }
                Terminal.commandString = '';
                $('#command-text').val('');
                document.getElementById('terminal-text-body').scrollIntoView(false);
                break;
            case 9: //TAB
                break;
            case 38: //UP ARROW
                if (Terminal.firstUpwardSeek === false) {
                    Terminal.firstUpwardSeek = true;
                    Terminal.historySeekCount = Terminal.commandHistory.length - 1;
                } else if (Terminal.historySeekCount > 0) {
                    Terminal.historySeekCount--;
                }
                $('#command-text').val(Terminal.commandHistory[Terminal.historySeekCount]);
                break;
            case 40: //DOWN ARROW
                Terminal.historySeekCount++;
                $('#command-text').val(Terminal.commandHistory[Terminal.historySeekCount]);
                break;
            default:
                break;
        }
    },
    parseCommand: function (commandStringParam) {
        let commandString = commandStringParam.toLowerCase();
        let firstSpace = false;
        let inOption = false; //this will get set to true if an option is typed. Options are designated by a '-' then a letter. inOption will be true if a dash is typed
        //this is necessary information in order to determine if the next space is typed is going to be a new option or a new option parameter. If in option...the next thing typed after a space
        //will either be another option...designated by '-' OR it will be the option parameter.
        let inOptionParam = false; //are we currently parsing out the option parameter?
        let storeLastOption = '';
        let lastOption = '';
        let length = commandString.length;
        let isSpecial = false;
        let letter;
        let outputText = '';
        let paramString = '';
        let paramChar = '';
        let doRecurse = false;
        let storeIndex;
        let recurseData = {
            'originalIdx': null,
            'originalval': null,
            'idx': [],
            'val': [],
            'idxLength': null
        };
        Terminal.lastCommand = $('#command-text').val();
        for (let i = 0; i < length; i++) { //loops through each character in the string
            letter = commandString[i];
            if (inOption === true && letter !== " " && isSpecial === false) { //if were inOption then last char was '-' so this char must be the option denotation if not a space
                if (commandString[i + 1] !== undefined && commandString[i + 1] !== " ") { //if we have an option that is longer than one character
                    lastOption = letter;
                    do { //read out the entire parameter belonging to the option until a space is hit
                        i++;
                        letter = commandString[i];
                        lastOption += letter; //store param
                    } while ((commandString[i + 1] !== '-' && !(/[0-9]/.test(commandString[i+2]))) && commandString[i + 1] !== undefined && commandString[i + 1] !== " "); //found space so leave

                } else {
                    lastOption = letter;
                }
                if (commandString[i + 1] === undefined) { //will store if the last part of the string is an option
                    Terminal.commandList[Terminal.commandCount - 1].optionsList.push(lastOption);
                    Terminal.commandList[Terminal.commandCount - 1].commandOptions[lastOption] = {};
                    Terminal.commandList[Terminal.commandCount - 1].commandOptions[lastOption]["param"] = null;
                    lastOption = "";
                }
            }
            switch (letter) {
                case " ": //if a space has been typed...check if first space, if so store text as main command
                    if (inOption === true) {
                        storeLastOption = lastOption;
                        inOptionParam = true;
                    }
                    inOption = false;
                    if (isSpecial === true && firstSpace === true) { //look for special option param set and store it
                        do { //read out the entire parameter belonging to the option until a space is hit
                            i++;
                            paramChar = commandString[i];
                            paramString += paramChar; //store param
                        } while ((commandString.slice(i + 1, i + 3) !== " -") && commandString[i + 1] !== undefined); //found space so leave
                        Terminal.commandList[Terminal.commandCount - 1].specialOptionParam = paramString.trim();
                        paramString = "";
                        isSpecial = false;
                    }
                    if (firstSpace === false) {
                        firstSpace = true;
                        //recursive solution for creating 'dataX' alias and 'data'
                        if (commandString.substr(0, i).trim().toLowerCase().match(/^data([1-9]+)$/)) {
                            Terminal.parseCommand("getData -f " + commandString[4] + commandString.substr(i, commandString.length) + " -raw");
                            Terminal.commandHistory.push($('#command-text').val());
                            localStorage.setItem("commandHistory", JSON.stringify(Terminal.commandHistory));
                            return;
                        }
                        if (commandString.substr(0, i).trim().toLowerCase() === 'data') {
                            Terminal.commandHistory.push($('#command-text').val());
                            Terminal.parseCommand("getData" + commandString.substr(i, commandString.length));
                            localStorage.setItem("commandHistory", JSON.stringify(Terminal.commandHistory));
                            return;
                        }
                        let newCommand = new Terminal.Command(commandString.substr(0, i)); //make a new command then push in list
                        Terminal.commandList.push(newCommand);
                        Terminal.commandCount++;
                        if (commandString[i + 1] !== '@' && commandString[i + 1] !== '-' && commandString[i + 1] !== " " && commandString[i + 1] !== undefined) { //grab default option param if there
                            do { //read out the entire parameter belonging to the option until a space is hit
                                i++;
                                paramChar = commandString[i];
                                paramString += paramChar; //store param
                            } while ((commandString.slice(i + 1, i + 3) !== " -") && commandString[i + 1] !== undefined); //found space so leave
                            Terminal.commandList[Terminal.commandCount - 1].defaultOptionParam = paramString;
                            paramString = "";
                        }
                        if (commandString[i + 1] === "@") {
                            isSpecial = true;
                            Terminal.commandList[Terminal.commandCount - 1].specialOption = "@";
                        }
                    } else if ((inOptionParam === true || inOption === true) && ((commandString[i + 1] === '-' && !(/[0-9]/.test(commandString[i+2]))) || commandString[i + 1] === undefined)) { //for storing options without parameters
                        inOptionParam = false;
                        Terminal.commandList[Terminal.commandCount - 1].optionsList.push(lastOption);
                        Terminal.commandList[Terminal.commandCount - 1].commandOptions[lastOption] = {};
                        Terminal.commandList[Terminal.commandCount - 1].commandOptions[lastOption]["param"] = null;
                        lastOption = "";
                    } else if (inOptionParam === true && (commandString[i + 1] !== '-' || (commandString[i + 1] === '-' && (/[0-9]/.test(commandString[i+2]))))) { //then the next thing to be typed will be a option param unless next char is '-'
                        inOptionParam = false;
                        //store idx and value data in object for recursive control
                        do { //read out the entire parameter belonging to the option until a space is hit
                            i++;
                            if (storeLastOption.trim().toLowerCase() === 'idx' || storeLastOption.trim().toLowerCase() === 'id' || storeLastOption.trim().toLowerCase() === 'val') {
                                if (commandString[i] === '(') {
                                    doRecurse = true;
                                    storeIndex = i;
                                    do {
                                        i++;
                                        if (commandString[i] !== ',') {
                                            if (storeLastOption === 'idx' || storeLastOption === 'id') {
                                                recurseData.idx.push(commandString[i])
                                            } else if (storeLastOption === 'val') {
                                                recurseData.val.push(commandString[i])
                                            }
                                        }
                                        if (commandString[i + 1] === undefined || commandString[i + 1] === ' ') {
                                            Terminal.print("Bad data input format, in order for 'set' to receive a data group it must be in format -idx (x,y,z) -val (j,f,k) where there is a val for each idx or one val to be set to all idx points specified.", Terminal.commandCount + 1);
                                            return false;
                                        }
                                    } while (commandString[i + 1] !== ')');
                                    i = storeIndex;
                                }
                            }
                            paramChar = commandString[i];
                            paramString += paramChar; //store param
                        } while ((commandString.slice(i + 1, i + 3) !== " -") && commandString[i + 1] !== undefined);
                        Terminal.commandList[Terminal.commandCount - 1].optionsList.push(lastOption);
                        Terminal.commandList[Terminal.commandCount - 1].commandOptions[lastOption] = {};
                        Terminal.commandList[Terminal.commandCount - 1].commandOptions[lastOption]["param"] = paramString;
                        lastOption = "";
                        paramString = "";
                    }
                    break;
                case '-': //if a dash has been typed then the next char will be an option character, so next is set to lastOption at top
                    if (inOption === false) {
                        inOption = true;
                    }
                    break;
                default:
                    break;
            }
        }
        //If someone hits enter on an empty input field, just print some whitespace
        if (commandString === '') {
            Terminal.print('');
            return false;
        }
        if (firstSpace === false && !(Terminal.singleWordCommands.hasOwnProperty(commandString.trim().toLowerCase()))) {
            outputText = "<span class='red'>You have entered an invalid command or the command you entered may not be complete.</span><br>Type 'help' for information about all commands<br>";
            Terminal.print(outputText);
            return false;
        } else {
            if (doRecurse === true) {
                let tempCommandObj;
                let recurseCount = recurseData.idx.length + 1;
                recurseData.idxLength = recurseData.idx.length;
                recurseData.originalIdx = Terminal.commandList[Terminal.commandCount - 1].commandOptions['idx'].param;
                recurseData.originalVal = Terminal.commandList[Terminal.commandCount - 1].commandOptions['val'].param;
                let length = recurseData.idx.length;
                if (recurseData.hasOwnProperty('idx') && recurseData.hasOwnProperty('val')) {
                    if (recurseData.idx.length === recurseData.val.length) {
                        for (let j = 0; j < length; j++) {
                            //set clone command into tempCommandObj
                            tempCommandObj = jQuery.extend(true, {}, Terminal.commandList[Terminal.commandCount - 1]);
                            //set to decremented recurseCount to keep track of which recursive iteration we are at
                            tempCommandObj.recurseCount = recurseCount--;
                            //save recurse data for backup in object
                            tempCommandObj.recurseData = recurseData;
                            //set idx and val for temp from data stored by parse
                            tempCommandObj['commandOptions']['idx']['param'] = recurseData.idx[j];
                            tempCommandObj['commandOptions']['val']['param'] = recurseData.val[j];
                            //call command handler
                            Terminal.commandHandler(tempCommandObj);
                            //reset temp obj to null for safety
                            tempCommandObj = null;
                        }
                    } else if (recurseData.val.length === 1 && recurseData.idx.length > 1) {
                        for (let j = 0; j < length; j++) {
                            tempCommandObj = jQuery.extend(true, {}, Terminal.commandList[Terminal.commandCount - 1]);
                            //set to decremented recurseCount to keep track of which recursive iteration we are at
                            tempCommandObj.recurseCount = recurseCount--;
                            //save recurse data for backup in object
                            tempCommandObj.recurseData = recurseData;
                            //set idx and val for temp from data stored by parse
                            tempCommandObj['commandOptions']['idx']['param'] = recurseData.idx[j];
                            tempCommandObj['commandOptions']['val']['param'] = recurseData.val[0];
                            //call command handler
                            Terminal.commandHandler(tempCommandObj);
                            //reset temp obj to null for safety
                            tempCommandObj = null;
                        }
                    }
                }
                return true;
            }
            Terminal.commandHandler(Terminal.commandList[Terminal.commandCount - 1]);
            return true;
        }
    },

    commandHandler: function (commandObj) {
        let outputText = "";
        let requestCommand = commandObj.mainCommand.trim().toLowerCase();
        if (Commands.hasOwnProperty(requestCommand)) { //white list
            let activeCommand = Commands[requestCommand];
            //Make sure all the options are valid
            for (let option in commandObj.commandOptions) {
                if (!activeCommand.options.hasOwnProperty(option)) {
                    outputText = "<span class='red'>You have entered an invalid option for this command</span><br> type 'help " + commandObj.mainCommand + "' and ENTER for information about this commands options<br>";
                    Terminal.print(outputText);
                    return;
                }
            }
            //if we made it here the command is a-ok so lets call the command function!
            if (Terminal.specialCaseCommands.hasOwnProperty(activeCommand.name)) {
                activeCommand.command_function(commandObj);
            } else if (Terminal.singleWordCommands.hasOwnProperty(activeCommand.name)) {
                activeCommand.command_function();
            } else {
                Terminal.filter(activeCommand, commandObj);
            }
        } else {
            outputText = "<span class='red'>You have entered an invalid command</span><br> type 'help' for a detailed list of all commands<br>";
            Terminal.print(outputText);
            return;
        }
    },

    filter: function (activeCommand, commandObj) {
        let deviceIdentifier = false;
        let location = false; //location to narrow search or
        let locationSearch;
        let dontFilterSpoofed = false;
        //if set we will ping all devices in location
        let devices = App.Site.devices; //to store arrays of devices for calls that will ping more than one device
        let type = false;
        let groupIdentifier = false;
        let targetFwVersion = false;
        Terminal.exactMatch = false;
        let filterNotTalking = false;
        let getAll = false;
        let wideSearchModifier = false; //set this to true when we use -t, -l or @ but will set to false again if a device is specified

        //if all then set deviceArray to entire device list
        if (commandObj.commandOptions.hasOwnProperty('all') || commandObj.commandOptions.hasOwnProperty('a')) {
            getAll = true;
        }
        if (commandObj.commandOptions.hasOwnProperty('s')) {
            dontFilterSpoofed = true;
        }
        if (commandObj.commandOptions.hasOwnProperty('talking')) {
            filterNotTalking = true;
        }
        //if special location action (we may want to check this against the activeCommand object for allowance)
        if (commandObj.specialOption === "@" ) { //if this shows up then we know that the location we must ping is in specialOptionParam
            //look for location in specialOptionParam
            wideSearchModifier = true;
            if (commandObj.specialOptionParam !== "") { //if location was entered
                location = commandObj.specialOptionParam;
            } else { //print error and correction
                let errorString = "<span class='red'>Using the special character '@' requires a location be specified.</span>";
                Terminal.print(errorString, Terminal.commandCount);
                return;
            }
        }
        if(commandObj.commandOptions.hasOwnProperty('l')){
            wideSearchModifier = true;
            location = commandObj.commandOptions['l'].param;
            locationSearch = Terminal.getLocationByName(location);
        }
        if(location !== false){
            locationSearch = Terminal.getLocationByName(location);
            let numMatched = Object.keys(locationSearch).length;
            if (numMatched === 0) {
                Terminal.print("<span class='red'>No location was found matching your search.</span>");
                return;
            } //TODO: need to deal with vague search response better...
            if (numMatched.length > 1 && !getAll) {
                Terminal.vagueSearchResponse(locationSearch, "location");
                return;
            } else {
                devices = {};
                let locDevices;
                let count = 0;
                location = '';
                for(let id in locationSearch){
                    count++;
                    location += locationSearch[id].name;
                    if(numMatched > 1 && count < numMatched){
                        location += ', ';
                    }
                    locDevices =  locationSearch[id].devices;
                    for(let devid in locDevices){
                        devices[devid] = locDevices[devid];
                    }
                }
            }
            if(Object.keys(devices).length === 0){
                Terminal.print("<span class='red'>There are no devices in the location(s) matching this search.</span>", Terminal.commandCount);
            }
        }
        if (commandObj.commandOptions.hasOwnProperty('t')) {
            wideSearchModifier = true;
            type = commandObj.commandOptions['t'].param;
            if (type === null) {
                Terminal.print("<span class='red'>Using the type option requires that a type parameter be set. Example: -t sensor</span>", Terminal.commandCount);
                return;
            }
            //checks for undefined in get all but if there is a location index we will get all devices by type within that loc
            devices = Terminal.filterDevicesByType(type, devices);
            if(Object.keys(devices).length === 0){
                Terminal.print("<span class='red'>There are no devices of this type.</span>", Terminal.commandCount);
            }
        }

        //filter by group
        if (commandObj.commandOptions.hasOwnProperty('g')) { //else get -d
            wideSearchModifier = true;
            groupIdentifier = commandObj.commandOptions['g'].param;

            if (groupIdentifier === null) {
                Terminal.print("<span class='red'>Using the group option requires that a group parameter be set. Example: -g test group 1</span>", Terminal.commandCount);
                return;
            }
            devices = Terminal.filterDevicesByGroup(devices, groupIdentifier);
            if (Object.keys(devices).length === 0) {

                Terminal.print("<span class='red'>There was no group found matching this name.</span>", Terminal.commandCount);
                return;
            }
        }

        //filter by fw version
        if (commandObj.commandOptions.hasOwnProperty('fw')) { //else get -d
            wideSearchModifier = true;
            targetFwVersion = commandObj.commandOptions['fw'].param;

            if (targetFwVersion === null) {
                Terminal.print("<span class='red'>Using the FW Version option requires that a version be passed. Example: -fw 07.42.00 </span>", Terminal.commandCount);
                return;
            }

            devices = Terminal.filterDevicesByFWVersion(devices, targetFwVersion);
            if (Object.keys(devices).length === 0) {

                Terminal.print("<span class='red'>There were no devices found matching that FW version.</span>", Terminal.commandCount);
                return;
            }
        }
        //Filter by deleted
        if (commandObj.commandOptions.hasOwnProperty('del')) {
            Terminal.devices_deleted()
            return;
        }

        //if a specific device name or id is passed (device is always the default param so we check for that too)
        if (commandObj.defaultOptionParam !== "") { //check if default param is set, if so set device ident
            deviceIdentifier = commandObj.defaultOptionParam;
        } else if (commandObj.commandOptions.hasOwnProperty('d')) { //else get -d
            deviceIdentifier = commandObj.commandOptions['d'].param;
        }
        let matched = {};
        if (deviceIdentifier !== false) {
            devices = Terminal.searchForDevice(deviceIdentifier, dontFilterSpoofed, getAll, devices);
        }else if(dontFilterSpoofed === false){
            for(let id in devices){
                if(Terminal.tinyCheck(devices[id])){
                    matched[id] = devices[id];
                }
            }
            devices = matched;
        }
        if(filterNotTalking){
            let talking = {};
            for(let id in devices){
                if(devices[id].isTalking()){
                    talking[id] = devices[id];
                }
            }
            devices = talking;
        }
        let numDevices = Object.keys(devices).length;

        if (numDevices === 0) { //device was not specified
            let errorString = "<span class='red'>No devices found matching search.</span>";
            Terminal.print(errorString, Terminal.commandCount);
            return;
        }
        //build readout text corresponding to options, parameters and command
        let readoutText = activeCommand.name + ": ";

        if (numDevices > 1) {
            readoutText +=  numDevices + " devices";
        } else if (numDevices === 1) {
            for (let id in devices) {
                readoutText += devices[id].name + " (" + devices[id].ip_address + ") ";
            }
        }

        if (type !== false) {
            readoutText += " of type " + type;
        }

        if (location !== false) {
            readoutText += " in " + location;
        }

        if (groupIdentifier !== false) {
            readoutText += " with group " + groupIdentifier;
        }
        readoutText += "<br><br>";

        Terminal.print(readoutText, Terminal.commandCount);
        //if there were multiple devices matching the search but -a or -all was not specified
        if (Terminal.exactMatch !== false && numDevices > 1) {
            if ($('#continue-command').length > 0) {
                $('#continue-command').off().remove();
            }
            let multiDeviceReturnHtml = "";
            multiDeviceReturnHtml += "<span class='multi-device-return-item green'>+ " + Terminal.exactMatch.name + " (" + Terminal.exactMatch.ip_address.substr(-4) + ")</span><br>";
            for (let id in devices) {
                multiDeviceReturnHtml += "<span class='multi-device-return-item red'>+ " + devices[id].name + " (" + devices[id].ip_address.substr(-4) + ")</span><br>";
            }
            Terminal.print(multiDeviceReturnHtml + "<span style='font-size:10px'>This command search found multiple devices matching the search but will use the first exact match found (above in green), use -all to apply the command to all devices returned. Continue to execute the command on the first exact match.</span><br><br><button id='continue-command'>Continue?</div>", Terminal.commandCount);
            $('#continue-command').one('click', function () {
                $('#continue-command').remove();
                devices = {};
                devices[Terminal.exactMatch.id] = Terminal.exactMatch;
                Terminal.exactMatch = false;
                activeCommand.command_function(devices, commandObj);
            });


        } else if (!getAll && numDevices > 1 && wideSearchModifier === false) {
            if ($('#continue-command').length > 0) {
                $('#continue-command').off().remove();
            }
            let multiDeviceReturnHtml = "";
            for (let id in devices) {
                multiDeviceReturnHtml += "<span class='multi-device-return-item green'>+ " + devices[id].name + " (" + devices[id].ip_address.substr(-4) + ")</span><br>";
            }
            Terminal.print(multiDeviceReturnHtml + "<span style='font-size:10px'>This command search found multiple devices matching the search but the -all modifier was not passed, continue to execute command on all devices listed above.</span><br><button id='continue-command'>Continue?</div>", Terminal.commandCount);
            $('#continue-command').one('click', function () {
                $('#continue-command').remove();
                activeCommand.command_function(devices, commandObj);
            });
        } else {
            //run commmand function
            activeCommand.command_function(devices, commandObj);
        }

    },
    getLocationByName: function(name){
        name = name.toLowerCase().trim();
        let matched = {};
        for(let id in App.Site.locations){
            if(App.Site.locations[id].name.toLowerCase().indexOf(name) >= 0){
                matched[id] = App.Site.locations[id];
            }else if(App.Site.locations[id].name === name){
                return {id: App.Site.locations[id]};
            }
        }
        return matched;
    },
    getParentAndChildLocations: function(parentName) {
        let locationsData = {};
        const locationsMatched = this.getLocationByName(parentName);
        
        const getNestedLocations = (location) => {
            let data = {
                [location.id]: location
            }
            if(Object.keys(location.children).length > 0) {
                for (let id in location.children) {
                    const childsLocations = getNestedLocations(location.children[id]);
                    data = {
                        ...data,
                        ...childsLocations,
                    }
                }
            }
            return data;
        };

        for (let id in locationsMatched) {
            const location = locationsMatched[id];
            const nestedLocations = getNestedLocations(location);
            locationsData = {
                ...locationsData,
                ...nestedLocations,
            }
        }

        return { locations: locationsData, rootLocations: locationsMatched };
    },
    filterDevicesByFWVersion: function (devices, targetFwVersion) {
        let matchedDevices = {};

        for (let id in devices){
            const activeDevice = devices[id];

            if(activeDevice.version === targetFwVersion){
                matchedDevices[id] = activeDevice;
            }
        }

        return matchedDevices;
    },
    filterDevicesByGroup: function (devices, groupName) {

        let groupId;
        let deviceArray = [];
        let returnObj = {
            groupName: "",
            deviceArray: [],
        }
        let groupFound = false;
        if (devices === undefined || devices.length === 0) {
            devices = Devices.list;
        }

        let group;
        for (group in Groups.list) {
            if (Groups.list[group].name.toLowerCase().trim().indexOf(groupName.toLowerCase().trim()) >= 0) {
                groupId = Groups.list[group].ID;
                groupFound = true;
                break;
            }
        }

        //search for devices by group, if device in array is in group, push into array for return
        for (let deviceIndex = 0; deviceIndex < devices.length; deviceIndex++) {

            if (devices[deviceIndex].assocGroups.indexOf(groupId) >= 0) {
                deviceArray.push(devices[deviceIndex]);
            }

        }
        if (groupFound) {
            returnObj.deviceArray = deviceArray;
            returnObj.groupName = Groups.list[group].name;
        } else {
            returnObj.groupName = false;
        }

        return returnObj;

    },

    searchForDevice: function (searchParam, dontFilterSpoofed, getAll, searchIn={}) {
        searchParam = searchParam.toLowerCase().trim();
        let matched = {};
        if (getAll === undefined) {
            getAll = false;
        }

        let activeDevice;
        let deviceList;

        if (Object.keys(searchIn).length === 0) {
            deviceList = App.Site.devices
        } else {
            deviceList = searchIn;
        }
        //search for device first by name, if not found, look at the ID (MAC) then by IP
        for (let id in deviceList) {
            activeDevice = deviceList[id];
            const deviceName = activeDevice.name || '';
            const deviceIpAddress = activeDevice.ip_address || '';

            if (dontFilterSpoofed === false && !Terminal.tinyCheck(activeDevice)) {
                continue;
            }
            if (searchParam === deviceName.toLowerCase() || searchParam === deviceIpAddress.substr(-4) || searchParam === deviceIpAddress.toString().toLowerCase()) {

                if (!getAll && Terminal.exactMatch === false) { //we only want to return the exact matched device, so if there were other matches, clear them out but let the user know there were multiple matches
                    Terminal.exactMatch = deviceList[id];
                } else { //otherwise we want all results so just add it to the device array
                    matched[id] = activeDevice;
                }
            } else if (deviceName.toLowerCase().trim().indexOf(searchParam) >= 0) {
                matched[id] = activeDevice;
            } else if (deviceIpAddress.substr(-4).toLowerCase().indexOf(searchParam) >= 0 ) {
                matched[id] = activeDevice;
            } else if (deviceIpAddress.toString().toLowerCase().indexOf(searchParam) >= 0) {
                matched[id] = activeDevice;
            }

        }
        if(Object.keys(matched).length === 0 && Terminal.exactMatch !== false){
            matched[Terminal.exactMatch.id] = Terminal.exactMatch;
        }
        return matched;
    },

    //get all devices by type or by classification returned in an array of indices into Devices.list[]
    //will auto search based on "deviceType." This function also accepts a location as a param and will get all devices by type
    //within a specified location as well.
    filterDevicesByType: function (deviceType, devices) {
        deviceType = deviceType.trim().toLowerCase();
        let isKey1 = false;
        let matched = {};
        let isKey2 = false, activeDevice;
        for (let key1 in Devices.defines) {
            if (key1.toLowerCase().indexOf(deviceType) >= 0) {
                isKey1 = true;
            }
        }
        for (let key2 in Devices.classifications) {
            if (key2.toLowerCase().indexOf(deviceType) >= 0) {
                isKey2 = true;
            }
        }
        for(let id in devices){
            activeDevice = devices[id];
            if(isKey1){
                if (activeDevice.partType.toLowerCase().indexOf(deviceType) >= 0) {
                    matched[id] = activeDevice;
                }
            }else if(isKey2){
                if (activeDevice.hasOwnProperty('is_'+deviceType) && activeDevice['is_'+deviceType] === true) {
                    matched[id] = activeDevice;
                }
            }
        }
        return matched;
    },

    getOrderedDevices: function (searchFilter, location, type) {

        let list = [];
        let canSearch = ["age", "lastPing", "name"];
        let toSort = [];

        if (searchFilter === "last ping" || searchFilter === "lastping") {
            searchFilter = "lastPing";
        }

        //build correct list to get sorted
        if (location && !type) {
            let locIndex = Terminal.getLocationIndex(location).locationIndices[0];
            list = Locations.list[locIndex].assocDevices;
            if (list.length === 0) {
                for (let i = 0; i < Locations.list[locIndex].children.length; i++) {
                    list = list.concat(Locations.list[locIndex].children[i].assocDevices);
                }
            }

        } else if (type && !location) {

            list = Terminal.getAllDevicesByType(type);

        } else if (location && type) {
            list = Terminal.getAllDevicesByType(type, Terminal.getLocationIndex(location));

        } else {
            list = Devices.list;

        }

        //call sort differently depending on what is getting sorted
        if (location || type) {
            if (canSearch.indexOf(searchFilter) >= 0) {
                for (let i = 0; i < list.length; i++) {
                    toSort.push([list[i], Devices.list[list[i]][searchFilter]])
                }
                toSort.sort(function (a, b) {
                    return a[1] - b[1];
                });

                return toSort;
            } else {
                return false
            }

        } else {
            if (canSearch.indexOf(searchFilter) >= 0) {
                for (let i = 0; i < list.length; i++) {
                    toSort.push([i, Devices.list[i][searchFilter]])
                }
                toSort.sort(function (a, b) {
                    return a[1] - b[1];
                });

                return toSort;
            } else {
                return false;
            }
        }

    },
    write: function(data, device, paramObj){
        let text = "";
        let success = true;
        if((typeof(data) === "string")){
            if(data.indexOf('timed out') >= 0|| data.indexOf('error') >= 0){
                success = false;
            }
            text = data;
        }
        if(typeof(data) === "object"){
            if(data.hasOwnProperty('length')){
                for(let i = 0; i < data.length; i++){
                    text += "<br>";
                    if(typeof(data[i]) === "object"){
                        for(let thing in data[i]){
                            text += thing +": "+ data[i][thing] + " ";
                        }
                    }else if(typeof(data) === "string"){
                        text += data[i];
                    }
                }
            }else{
                if('errors' in data){
                    success = false;
                    text = data.errors.length ? data.errors.map((error, i) => `<br> ${i+1}: ${error}`) : data.errors;
                    if(data.errors[0].includes("Request timed out waiting for a response on MQTT for mid")) text = "There is a problem communicating with the device via MQTT.";
                }else{
                    for(let thing in data){
                        text += "<br>";
                        text += thing +": "+ data[thing] + " ";
                    }
                }
            }
        }
        if(success){
            Terminal.writeSuccess(text, device, paramObj);
        }else{
            Terminal.writeError(text, device, paramObj);
        }
    },
    writeJson: function(data, device, paramObj){
        let json = JSON.stringify(data, undefined, 4);
        // eslint-disable-next-line
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        // eslint-disable-next-line
        json = json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            var cls = 'number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'key';
                } else {
                    cls = 'string';
                }
            } else if (/true|false/.test(match)) {
                cls = 'boolean';
            } else if (/null/.test(match)) {
                cls = 'null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
        });
        const code = `<pre class="terminal__json-pretty">${json}</pre>`;
        Terminal.print(`<span class='green'>Response from ${device.name} (${device.ip_address.substr(-4)}): ${code}</span><br>`, paramObj);
        Terminal.updateScroll();
    },
    writeSuccess(text, device, paramObj={}){
        Terminal.print("<span class='green'>Device "+device.name+" ("+device.ip_address.substr(-4)+") "+text+"</span><br>", paramObj);
        Terminal.updateScroll();
    },
    writeError(text, device, paramObj={}){
        Terminal.print("<span class='red'>Device "+device.name+" ("+device.ip_address.substr(-4)+") "+text+"</span><br>", paramObj);
        Terminal.updateScroll();
    },
    //text to be printed, placement (always Terminal.commandCount) creates the div to keep track of so you can print to the same section later,
    //multiple...used for situations where you are going to print some set of things to the same box area
    //to enumerate outputs send the numeric order in the set that the current item is at
    print: function (text, paramObj={}) {
        let placement = (paramObj.hasOwnProperty('placement') ? paramObj.placement : Terminal.commandCount);
        let command = Terminal.lastCommand;
        let multiple = (paramObj.hasOwnProperty('multiple') ? paramObj.multiple :false);

        if (multiple === true) {
            if (paramObj.hasOwnProperty('count')) {
                //append command+placement
                $('.command' + placement).append(paramObj.count + '. ' + text + "<br>");
            }else{
                $('.command' + placement).append( + "<br>");
            }
        } else {
            Terminal.printMultipleCount[placement - 1] = 0;
            if ($('.command' + placement).length > 0) { //to stop errors for cases that multiple isnt set to true but multiple prints happen for one command
                $('.command' + placement).append(text + "<br>");
            } else if (placement !== undefined) { //create the initial div for the command to be printed in
                $('#terminal-text-body').html($('#terminal-text-body').html() + '<div class="command' + placement + '">' + Terminal.userInfo + command + '<br><br>' + text + '</div><br>');
                // document.getElementById('terminal-text-body').scrollIntoView(false);
            } else {
                $('#terminal-text-body').html($('#terminal-text-body').html() + '<div">' + Terminal.userInfo + command + '<br><br>' + text + '</div><br>');
                // document.getElementById('terminal-text-body').scrollIntoView(false);
            }

        }
        Terminal.updateScroll();

    },
    writeInfo:function(text, info=false){
        if(info){
            $('#terminal-user-info').html(info);
        }
        $('#terminal-text-body').append(text);
        document.getElementById('terminal-text-body').scrollIntoView(false);
    },

    vagueSearchResponse: function (matched, type) {
        let command = Terminal.lastCommand;
        let printStr = "";
        if (object.keys(matched).length > 0) {
            printStr += ('<br>' + Terminal.userInfo + command + '<br><br>There were multiple matches to your search, use the items ID to be specific if there are multiple items with the same name or use -all to target all items returned: <br>');
            if (type === "location") {
                printStr += ("<br>Locations:<br>");
                for (let id in matched) {
                    printStr += ("<br>" + App.Site.locations[matched[id]].name + " (" + App.Site.locations[matched[id]].ID + ")");
                }
            } else if (type === "device") {
                printStr += ('<br>Devices:<br>');
                for (let id in matched) {
                    printStr += ("<br>" + App.Site.devices[matched[id]].getName(true));
                }
            }
        }

        printStr += ('<br><br>');
        $('#terminal-text-body').html($('#terminal-text-body').html() + printStr);

    },
    ota: function (devices, paramObj) {
        if (paramObj.target || paramObj.tag) {
            const requestBody = {
                task: 'devices.ota',
                args: {
                    site_id: App.activeSiteID,
                    target_version: paramObj.target,
                    tag: paramObj.tag,
                    device_ip: Object.values(devices)[0].ip_address,
                },
                async: false
            };
            const options = { requestBody, serverVariables: { baseVersion: "v2" } };
            return SDK.init()
            .then(api => api.tasks.saveTask({}, options))
            .then(response => Terminal.print(`<span class='green'>OTA set successfully</span>`))
            .catch(e => {
                Terminal.print(`<span class='red'>Error setting the OTA</span>` );
            });
        }

        let tempDevList =[];
        let devicesToOta = Object.keys(devices);
        let override = false;
        let xhr = [];
        let channel = false;
        let devCount = devicesToOta.length;
        let devToOta = 0;
        let mcba = paramObj.mcba;
        Terminal.otaQueue = [];
        if (devicesToOta.length < 1) {
            Terminal.print("Something went wrong, no device was found or specified to ota.", paramObj);
            return;
        }
        if (paramObj.custom === true) {
            let otaHtml = `<div class='terminal-custom-ota'>Upload File for OTA:<input id='upload-ota-file-terminal${Terminal.commandCount}' type='file'/><br>`;
            otaHtml += ` <div>Skip Part Type Check:<input id='skip-part-check-terminal-${Terminal.commandCount}' class='skip-part-check-terminal' type='checkbox'></div><br><br> Do OTA: <input id='terminal-ota-${Terminal.commandCount}' class='skip-part-check-terminal' type='checkbox'></div>`;
            otaHtml += ``;
            Terminal.print(otaHtml, paramObj);
            $(document).on('click', `#terminal-ota-${Terminal.commandCount}`, function () {
                let fileInputEl = document.getElementById('upload-ota-file-terminal' + Terminal.commandCount);

                if (paramObj.override === true) {
                    override = true;
                }
                //Make sure the user has actually uploaded a file
                //TODO: wont need this forever if we allow other options
                if (fileInputEl.files[0]) {
                    //filter based on channel if set
                    if (paramObj.channel !== null) {
                        for (let id in devices) {
                            if (devices[id].compile_time.indexOf("ch." + paramObj.channel) >= 0) {
                                tempDevList.push(id);
                            }
                        }
                        devicesToOta = tempDevList;
                    }
                    //run in queue, p many at once. Each finishing ota will start the next in queue
                    if (paramObj.p !== 'all' && devicesToOta.length > 1) {
                        let p = paramObj.p;
                        for (let count = p; count < devicesToOta.length; count++) {
                            //add all but the set we will call to the queue, these will be popped off by finished ota's
                            Terminal.otaQueue[count - p] = devicesToOta[count];
                        }
                        for (let otaCount = 0; otaCount < p; otaCount++) {
                            OTA(devicesToOta[otaCount], fileInputEl.files[0]);
                        }
                    } else { //run all or one
                        if (devicesToOta.length > 1) {
                            for (let i = 0; i < devicesToOta.length; i++) {
                                OTA(devicesToOta[i], fileInputEl.files[0]);
                            }
                        } else {
                            OTA(devicesToOta[0], fileInputEl.files[0]);
                        }
                    }
                } else {
                    Terminal.print("A file is required for custom ota, please upload a file or run ota without the '-custom' flag ", paramObj.placement, true, 1, false);
                }
            });
            return;
        }
        //If override is set
        //filter based on channel if set
        if (paramObj.override === true) {
            override = true;
        }
        if (paramObj.channel !== null) {
            channel = paramObj.channel;
        }
        //run in queue, p many at once. Each finishing ota will start the next in queue
        if (paramObj.p !== 'all' && devicesToOta.length > 1) {
            let p = paramObj.p;
            for (let count = p; count < devicesToOta.length; count++) {
                //add all but the set we will call to the queue, these will be popped off by finished ota's
                Terminal.otaQueue[count - p] = devicesToOta[count];
            }
            for (let otaCount = 0; otaCount < p; otaCount++) {
                OTA(devicesToOta[otaCount]);
            }
        } else { //run all or one
            if (devicesToOta.length > 1) {
                for (let i = 0; i < devicesToOta.length; i++) {
                    OTA(devicesToOta[i]);
                }
            } else {
                OTA(devicesToOta[0]);
            }
        }
        function OTA(deviceID, customBinary) {
            paramObj.count++;
            let device = devices[deviceID];
            let myString = '';
            // let params;
            let currentCount = devToOta;
            if (currentCount < devCount) {
                devToOta++;
            }
            let custom = false;
            if (customBinary) {
                custom = true;
            }
            $(function () {
                xhr[currentCount] = new XMLHttpRequest();
                xhr[currentCount].onreadystatechange = function () { //Call a function when the state changes.
                    //when complete
                    if (xhr[currentCount].readyState === 4 && xhr[currentCount].status === 200) {
                        let errorIndex = xhr[currentCount].responseText.toLowerCase().indexOf('error');
                        let successIndex = xhr[currentCount].responseText.toLowerCase().indexOf('Finished OTAPing');
                        if (errorIndex >= 0 && successIndex < 0) {
                            if (xhr[currentCount].responseText.toLowerCase().indexOf('current code') >= 0) {
                                $(".ota-device-" + device.ID + Terminal.commandCount).append('<span class="green"><br>This device already has the latest code in it</span>');
                            } else if (xhr[currentCount].responseText.toLowerCase().indexOf('error: part type miss match. will exit') >= 0) {
                                $(".ota-device-" + device.ID + Terminal.commandCount).append('<span class="red"><br>Current part type and the type of the new files dont match<br>Click the overide for this check in the custom OTA form</span>');
                            } else {
                                $(".ota-device-" + device.ID + Terminal.commandCount).append('<span class="red"><br>There was a problem communicating with this device.</span><br>');
                            }

                        } else {
                            $(".ota-device-" + device.ID + Terminal.commandCount).append('<span class="green"><br>Successfully updated the software for this device to the latest version.  It will take at least 15 seconds for the device to reboot.<br>' +
                                '*********************************************************************<br><br></span>');
                        }
                        $('#Log').remove();
                        //Legacy: this used to be the div to remove, we keep it around for now
                        $('#update').remove();
                        //Each OTA.php call will create one of these silly divs for now. If we cancel, we remove it to prevent a mess when we try again
                        $('#update_' + device.IP.substr(-4).toLowerCase()).removeAttr('id');
                        if (Terminal.otaQueue.length > 0) {
                            if (customBinary) {
                                OTA(Terminal.otaQueue.shift(), customBinary);
                            } else {
                                OTA(Terminal.otaQueue.shift());
                            }
                        }
                    }
                    //stream data
                    else if (xhr[currentCount].readyState === 3) {

                        let active = xhr[currentCount].responseText;

                        let changedText = active.substr(myString.length); //the new stuff

                        changedText = changedText.replace(/X/g, "");
                        if(changedText.indexOf('log')>=0 ){
                            changedText = changedText.substr(changedText.indexOf(')')+1);
                        }
                        if (changedText.indexOf('script') >= 0 && changedText.indexOf('update') >= 0 && $('#update_' + device.IP.substr(-4).toLowerCase()).length > 0) {
                            $('#update_' + device.IP.substr(-4).toLowerCase()).html(changedText);
                        } else {
                            $(".ota-device-" + device.ID + Terminal.commandCount).append(changedText); //we put the script tag it returns in this div
                        }
                        myString = active;
                    }
                };
                //for keeping track of ota for the same device for betting classing
                if (Terminal.otaHistory.ID.length < 1 && Terminal.otaHistory.count.length < 1) {
                    Terminal.otaHistory.ID.push(device.ID);
                    Terminal.otaHistory.count.push(1);
                } else if (device && device.hasOwnProperty('ID') && Terminal.otaHistory.ID.indexOf(device.ID) >= 0) {
                    let i = Terminal.otaHistory.ID.indexOf(device.ID);
                    if (Terminal.otaHistory.count[i] !== undefined) {
                        Terminal.otaHistory.count[i]++;
                    } else {
                        Terminal.otaHistory.count[i] = 1;
                    }
                } else {
                    Terminal.otaHistory.ID.push(device.ID);
                    Terminal.otaHistory.count.push(1);
                }
                //make dom object specific to this devices ota for printing to
                let otaType;
                if (mcba && custom) {
                    otaType = "OTAing MCBA: "
                } else {
                    otaType = "OTAing: "
                }
                Terminal.print("<span class='ota-device-" + device.ID + Terminal.commandCount + "'>" + otaType + device.name + "(" + device.ip_address + ")<button id='cancel-ota-" + device.ID + Terminal.commandCount + "' class='cancel-ota-terminal fa' data-devcount= '" + currentCount + "' data-devid='" + device.ID + "'>Cancel OTA</button><br></span><br>", paramObj);

                let devId;
                $('#cancel-ota-' + device.ID + Terminal.commandCount).on('click', function (e) {
                    devId = $(this).data('devid');
                    let otaIndex = $(this).data('devcount');
                    xhr[otaIndex].abort();
                    //Each OTA.php call will create one of these silly divs for now. If we cancel, we remove it to prevent a mess when we try again
                    $('#update_' + device.ip_address.substr(-4).toLowerCase()).removeAttr('id');
                    $('.ota-device-' + devId + Terminal.commandCount).append('<br><span class="red"> OTA Cancelled! </span>');
                    $('#cancel-ota-' + device.ID + Terminal.commandCount).hide();
                });

                if ((custom !== true && channel) || custom !== true) { //go to the latest version
                    let url = "https://dash.amatiscontrols.com/OTA/OTA-File.php";
                    if (mcba) {
                        $('.ota-device-' + devId + Terminal.commandCount).append('<br><span class="red">Error: automatic OTA of MCBA is not yet supported:</span> MCBA OTA is supported with .out files uploaded using the -custom flag.<br>');
                        return;
                    }
                    //FOR NOW WE HARD CODE GKEYS AND STEP2
                    //params = "CloudBin=1&ip=" + device.IP+"&clean=1&keys=1&Gkeys=1&STEP2=C2P";
                    let fd = new FormData();
                    fd.append('CloudBin', 1);
                    fd.append('keys', 1);
                    fd.append('Gkeys', 1);
                    fd.append('STEP2', "C2P");
                    fd.append('token', User.apiToken);
                    fd.append('ip', device.ip_address);
                    fd.append('clean', 1);
                    if (channel) {
                        fd.append('new_channel', channel);
                    }
                    if (override) {
                        fd.append('override', 1);
                    }
                    xhr[currentCount].open('POST', url, true);
                    //Send the proper header information along with the request
                    //xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                    //xhr.setRequestHeader("Content-length", params.length);
                    //xhr.setRequestHeader("Connection", "close");
                    xhr[currentCount].send(fd);
                } else {
                    if (customBinary) {
                        let fd = new FormData();
                        let url = "https://dash.amatiscontrols.com/OTA/OTA-File.php";
                        if (mcba) {
                            url = "https://dash.amatiscontrols.com/NextPsmOta/OTA_PHD.php";
                        }else{
                            fd.append('token', User.apiToken);
                        }
                        fd.append('ip', device.ip_address);
                        fd.append('ufile', customBinary);
                        fd.append('clean', 1);
                        if (override) {
                            fd.append('override', 1);
                        }
                        if (document.getElementById('skip-part-check-terminal-'+Terminal.commandCount).checked) {
                            fd.append('FastNScary', 1);
                        }
                        xhr[currentCount].open('POST', url, true);
                        xhr[currentCount].send(fd);
                    }
                }
            });
        }
    },
    mcast_linkid: async function(LINKID){
        let params = { SITEID: App.activeSiteID , LINKID };
        let optionSDK = { serverVariables: { baseVersion: 'v1' } };
        let api = await SDK.init();
        const { body } = await api.scenes.callSceneByLinkID(params, optionSDK);
        if(body){
            Terminal.print(`<span class='green'>Success</span>` );
        }else{
            Terminal.print(`<span class='red'>Error</span>` );
        }
    },
    //Get deleted devices
    devices_deleted: async function(LINKID){
        let params = { SITEID : App.activeSiteID , deleted: 1 };
        let optionSDK = { serverVariables: { baseVersion: 'v2' } };
        const api = await SDK.init();
        const { body: {data} } = await api.devices.getDevicesBySite(params, optionSDK);
        Terminal.print(`<span class='green'>Showing the deleted devices on site ${App.activeSiteID}</span><br /><br />` );
        if(data.length > 0){
            data.forEach(device => {
                Terminal.print(`<span class='red'>Type: ${device.type} - IP: ${device.ip_address.substr(-4)}</span>` );
            });
        }else {
            Terminal.print(`<span class='red'>Without devices deleted</span>` );
        }

    },
    //returns true if not a tiny
    tinyCheck: function (device) {
        if ((device.classification !== 'switch' || device.partType === 'AM-SW8') && device.partType.toLowerCase() !== 'tny' && device.partType.toLowerCase() !== 'tiny') {
            return true;
        } else {
            return false;
        }
    },
    stuset: function () {
        Terminal.print('Clearing site: Deleting all scenes, locations and resetting all device associations, names and roles.');
        // eslint-disable-next-line
        for (let id in App.Site.devices) {
            App.Site.devices[id].setProps({'name':App.Site.devices[id].type, 'role': '-'});
        }
        let count = Object.keys(App.Site.locations).length;
        // eslint-disable-next-line
        for (let id in App.Site.locations) {
            if (App.Site.locations[id].isRoot === false) {
                // eslint-disable-next-line
                App.Site.locations[id].destroy().then(() => {
                    count--;
                    if(count === 1){
                        Terminal.print('Site clearing... Reloading page in 10 seconds.');
                        setTimeout(() => {
                           window.location.reload();
                        }, 6000);
                    }
                });
            }
        }
    },


};

export default Terminal;
