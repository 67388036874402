export const LIST_DATAPOINTS = 'LIST_DATAPOINTS';
export const UPDATE_DATAPOINT = 'UPDATE_DATAPOINT';
export const DELETE_DATAPOINT = 'DELETE_DATAPOINT';
export const INFLUX_DATAPOINT = 'INFLUX_DATAPOINT';

export const PROCESS_DATAPOINT = 'PROCESS_DATAPOINT';
export const CLEAN_INFLUX = 'CLEAN_INFLUX';
export const ADD_DATAPOINT = 'ADD_DATAPOINT';
export const RETRIEVE_DATAPOINT = 'RETRIEVE_DATAPOINT';
export const TABLE_DATAPOINT = 'TABLE_DATAPOINT';
export const PAGINATION_DATAPOINT = 'PAGINATION_DATAPOINT';

export const CLEAR_NEW_DATAPOINTS = 'CLEAR_NEW_DATAPOINTS';
export const SET_FILTERS_FROM_QUERY_PARAMS = 'SET_FILTERS_FROM_QUERY_PARAMS';
