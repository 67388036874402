import { createSelector } from 'reselect';
import App from 'app/app';

export const getActiveSiteLocationId = state => state.activeSite.locationId;
export const getScenes = state => state.scenes;
export const getSceneByIdProp = (_, props) => props.id;
export const getActiveLocation = (_, props) => props.activeLocation;
export const getScenesProps = (_, props) => props.scenes;
export const getActiveIsRootProps = (_, props) => props.activeIsRoot;

export const getActiveLocationId = createSelector(getActiveLocation, activeLocation => activeLocation.ID);
export const getActiveLocationScenes = createSelector(getActiveLocation, activeLocation => activeLocation.scenes);

export const isLoadingScenes = createSelector(getScenes, scenes => scenes.loading);
export const getScenesError = createSelector(getScenes, scenes => scenes.error);

export const getScenesMode = createSelector(getScenes, scenes => scenes.mode);
export const getScenesModeEdit = createSelector(
    [getScenesMode, getActiveLocation],
    (mode, activeLocation) => mode.edit.includes(activeLocation.ID)
);
export const isSceneInRootLocation = createSelector([getActiveLocation], (activeLocation) => activeLocation.isRoot);

export const getScenesMeta = createSelector(getScenes, scenes => scenes.meta);
export const getScenesChecked = createSelector(getScenesMeta, meta => meta.checked);
export const getScenesEditable = createSelector(getScenesMeta, meta => meta.editable);
export const getScenesStageNames = createSelector(getScenesMeta, meta => meta.stageNames);
export const getScenesSearch = createSelector(getScenesMeta, meta => meta.search);

export const getScenesModal = createSelector(getScenes, scenes => scenes.modal);
export const getScenesSelected = createSelector(getScenes, scenes => scenes.selected);
export const getLastItemsUpdate = createSelector(getScenes, scenes => scenes.lastItemsUpdate);

const getLocationChildrenThree = location => {
    const parents = [...Object.keys(location.children)];
    if(location.children){
        const childrens = Object.entries(location.children).map(children => {
            if(Object.keys(children[1].children).length > 0){
                return getLocationChildrenThree(children[1]);
            }
            return false;
        });
        return [...parents, ...childrens].flat();
    }
    return [...parents];
}

export const getScenesItems = createSelector(
    [getScenes, getScenesSearch, getActiveLocation],
    (scenes, search, activeLocation) => {
        const { items } = scenes;
        if (items === null) return items;

        let filterItems = items;
        if (activeLocation.ID !== null) {
            const locationTree = getLocationChildrenThree(App.Site.locations[activeLocation.ID]);
            const locationsId = [...locationTree, activeLocation.ID].filter(item => item);
            filterItems = items.filter(item => locationsId.includes(item.location_id));
        }

        const result = filterItems.map(item => ({
            ...item,
            name: getName(item),
            nameWithLinkID: getNameWithLinkID(item),
            location: geLocationByScene(item),
        }))
        .sort((a, b) => {
            const alc = parseInt(a.link_id);
            const blc = parseInt(b.link_id);
            return alc > blc ? 1 : alc < blc ? -1 : 0;
        });

        if (search !== '') {
            const newSearch = search.toLowerCase();
            return result.filter(({ name, link_id, location }) => {
                return name.toLowerCase().includes(newSearch) ||
                        `${link_id}`.toLowerCase().includes(newSearch) ||
                        (location.name && location.name.toLowerCase().includes(newSearch))
            });
        }

        return result;
});

export const isCheckScene = createSelector(
    [getScenesChecked, getSceneByIdProp],
    (checked, id) => checked.includes(id)
);

export const isEditableScene = createSelector(
    [getScenesEditable, getSceneByIdProp],
    (editable, id) => editable.includes(id)
);

export const getStageNameScene = createSelector(
    [getScenesStageNames, getSceneByIdProp],
    (stageNames, id) => {
        const name = stageNames.find(name => name.id === id);
        if (name) {
            return name.name;
        }
        return null
    }
);

export const getVisibleScenes = createSelector(
    [getScenesProps, getActiveLocationId],
    (scenes, locationId) => {
        return Object.keys(scenes).reduce((acc, index) => {
            const current = scenes[index];
            if ((current.hidden === 0 || current.hidden === '0') && current.softDeleted !== true) {
                if (current.location.ID === locationId) {
                    if (current.description !== 'direct_control') {
                        acc[index] = current;
                    }
                }
            }
            return acc;
        }, {})
    }
);

export const getDirectControl = (state, newProps) => {
    const { activeLocation } = newProps;
    const scenes = getScenesProps(state, newProps);
    const index = Object.keys(scenes).find(index => scenes[index].location_id === activeLocation.ID && scenes[index].description === 'direct_control');
    if (index) {
        return scenes[index];
    }
    return false;
  };

const geLocationByScene = scene => {
    if(App.Site.hasOwnProperty('locations') && App.Site.locations.hasOwnProperty(scene.location_id)){
        return App.Site.locations[scene.location_id];
    }
    return false;
}

const getName = scene => {
    if (scene.name === 'NULL' || typeof (scene.name) === 'undefined' || scene.name === 'undefined') {
        return `Scene  ${(scene.link_id >= 0) ? (scene.link_id - 255) : `Unknown`}`;
    }
    return scene.name ;
}

const getNameWithLinkID = scene => {
    let linkIDString = 'Unknown';
    let sceneNumberString = 'Unknown';

    if (scene.link_id >= 0) {
        linkIDString = scene.link_id;
        sceneNumberString = scene.link_id - 255;
    }

    if (scene.name === 'NULL' || typeof (scene.name) === 'undefined' || scene.name === 'undefined') {
        return `Scene  ${sceneNumberString} (${linkIDString})`;
    }
    return `${scene.name} (${linkIDString})` ;
}
