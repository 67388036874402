// @ts-nocheck
import { MyTemplates } from "classes/templates";
import type {
    Location,
    Scene,
    KeyObjectType,
    Issue,
    ValidatorKey,
} from "../types";
import { normilzeIssue } from "../utils/IssuesNormalizer";

const getScenesByDescription = (location: Location) => {
    const scenesByDesc: KeyObjectType<Scene> = {};
    const scenes = location.scenes;
    for (let id in scenes) {
        if (scenes[id].softDeleted === false) {
            const description = scenes[id].description;
            scenesByDesc[description] = scenes[id];
        }
    }
    return scenesByDesc;
};

//calculate if a feauture is applied based on the scenes in the location (looking at matching descriptions)
export const validateAppliedFeatures = (
    location: Location,
    validatoKey: ValidatorKey
) => {
    let missing: Issue[] = [];
    let matched: number;
    const scenesByDesc = getScenesByDescription(location);

    for (let template in MyTemplates) {
        if (location.featureSettings.hasOwnProperty(template)) {
            matched = Object.keys(MyTemplates[template].scenes).length;
            for (let scene in MyTemplates[template].scenes) {
                const active = MyTemplates[template].scenes[scene];
                // Currently requiring all scenes of the template must exist in the location, change this logic here if we want to handle it otherwise
                if (!scenesByDesc.hasOwnProperty(active.description)) {
                    matched--;
                    missing.push(
                        normilzeIssue({
                            name: active.name,
                            identifierId: template,
                            errorMessage: `${active.name} is missing from ${template}`,
                            locationName: location.name,
                            validatorKey: validatoKey,
                            type: "missing",
                            category: "feature",
                        })
                    );
                }
            }
            if (matched === 0) {
                missing = missing.filter(
                    (item) => item.identifierId !== template
                );
            }
        }
    }
    return missing;
};

export const validateGracePeriodFeatures = (location: Location) => {
    const locationFeatureSettings = location.featureSettings;
    const issues: Issue[] = [];

    if (Object.keys(locationFeatureSettings).length > 0 && !location.isParent) {
        if (
            "nh-grace" in locationFeatureSettings &&
            "day-occ" in locationFeatureSettings &&
            locationFeatureSettings["nh-grace"].applied &&
            locationFeatureSettings["day-occ"].applied
        ) {
            const nhGraceSetting =
                locationFeatureSettings["nh-grace"].settings["grace-period"];
            const dayOccTimeoutSetting =
                locationFeatureSettings["day-occ"].settings["timeout"];

            if (nhGraceSetting.value >= dayOccTimeoutSetting.value) {
                issues.push(
                    normilzeIssue({
                        name: nhGraceSetting.dname,
                        identifierId: locationFeatureSettings["nh-grace"].name,
                        errorMessage: `${nhGraceSetting.name} is greater than ${locationFeatureSettings["day-occ"].alias} timeout`,
                        locationName: location.name,
                        validatorKey: "nhGrace",
                        type: "misconfigured",
                        category: "feature",
                    })
                );
            }
        }

        if (
            "ah-grace" in locationFeatureSettings &&
            "night-occ" in locationFeatureSettings &&
            locationFeatureSettings["ah-grace"].applied &&
            locationFeatureSettings["night-occ"].applied
        ) {
            const ahGraceSetting =
                locationFeatureSettings["ah-grace"].settings["grace-period"];
            const nightOccTimeoutSetting =
                locationFeatureSettings["night-occ"].settings["timeout"];

            if (ahGraceSetting.value >= nightOccTimeoutSetting.value) {
                issues.push(
                    normilzeIssue({
                        name: ahGraceSetting.dname,
                        identifierId: locationFeatureSettings["ah-grace"].name,
                        errorMessage: `${ahGraceSetting.name} is greater than ${locationFeatureSettings["night-occ"].alias} timeout`,
                        validatorKey: "ahGrace",
                        locationName: location.name,
                        type: "misconfigured",
                        category: "feature",
                    })
                );
            }
        }
    }
    return issues;
};
