/* eslint complexity: 0 */
//does work on the action function to return a human understandeable name and treatment of var1
//Consider breaking this into its own file
import {decToHex} from 'classes/methods.js'

const displayPretty = (action, var1=false, partType=false) => {
    let index;
    let Arry = [];
    let numberOfChannels;
    let lightLevel = '';
    let hexVar1;
    let ChanString = '';
    let zoneString = '';
    let numberOfZones = 8;
    let zoneDisplayArr = [];
    let zoneMap;
    let chan;
    let str;
    let duty, chanMap;
    let fadeRate;
    if(var1 === null){
        return action;
    }
    var1 = var1.toString();

    let prettyString = action; //default to the raw action name
    //A place where we can allow certain devices to just bypass the whole function
    if (partType === 'AM-SW8') {
        //For sw8, we dont want to convert buttons to 'top/mid/bottom button'
        return prettyString.replace('INT.','').replace('_', ' ');
    }

    switch (action) {
    case 'Disable Mapped Channels (mask) ':
        ChanString = '';
        Arry = [];
        numberOfChannels = 16;

        for (let i = 0; i < numberOfChannels; i++) {
            if ((var1 >> i) & 0x01)
                Arry.push('1');
            else
                Arry.push('ign');
        }

        //parse off arry
        for (let chan in Arry) {

            if (Arry[chan] === '1' && Arry[parseInt(chan)] !== Arry[(parseInt(chan) + 1)]) {
                ChanString += (parseInt(chan) + 1) + ', ';
            } else if (Arry[chan] === '1' && Arry[parseInt(chan)] === Arry[(parseInt(chan) + 1)] && ChanString.charAt(ChanString.length - 1) !== '-') {
                ChanString += (parseInt(chan) + 1) + '-';
            }
        }

        prettyString = 'Chans: ' + ChanString + ' OFF';
        break;

    case 'Enable Mapped Channels (mask) ':
        ChanString = '';
        Arry = [];
        numberOfChannels = 16;

        for (let i = 0; i < numberOfChannels; i++) {
            if ((var1 >> i) & 0x01)
                Arry.push('1');
            else
                Arry.push('ign');
        }

        //parse off arry
        for (chan in Arry) {
            if (Arry[chan] === '1' && Arry[parseInt(chan)] !== Arry[(parseInt(chan) + 1)]) {
                ChanString += (parseInt(chan) + 1) + ', ';
            } else if (Arry[chan] === '1' && chan < 15 && Arry[parseInt(chan)] === Arry[(parseInt(chan) + 1)] && ChanString.charAt(ChanString.length - 1) !== '-') {
                ChanString += (parseInt(chan) + 1) + '-';
            }
        }

        prettyString = 'Chans: ' + ChanString + ' ON';
        break;

    case 'Set Auto Level (mask,%,fade)':
    case 'Set Manual Level (mask,%,fade)':
        //prettyString = decToHex(var1);
        hexVar1 = decToHex(var1).replace('0x', '');
        ChanString = '';
        zoneString = '';
        Arry = [];
        numberOfChannels = 16;
        zoneDisplayArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
        numberOfZones = 8;
        prettyString = '';

        str = hexVar1;
        fadeRate = parseInt(hexVar1.substr(-2), 16);

        str = str.substring(0, str.length - 2);

        if (str.length === 2) {
            duty = str.substr(-1);
            str = str.substring(0, str.length - 1);
        } else {
            duty = str.substr(-2);
            str = str.substring(0, str.length - 2);
        }

        duty = parseInt(duty, 16);

        //console.log(str)

        chanMap = '0x' + str.substr(-4);

        //console.log(chanMap);

        str = str.substring(0, str.length - 4);

        zoneMap = '0x' + str.substr(-2);

        //console.log(zoneMap);

        for (index = 0; index < numberOfChannels; index++) {
            if ((chanMap >> index) & 0x01)
                Arry.push('1');
            else
                Arry.push('ign');
        }
        //console.log(Arry)

        //parse off arry
        for (chan in Arry) {

            if (Arry[chan] === '1' && Arry[parseInt(chan)] !== Arry[(parseInt(chan) + 1)]) {
                ChanString += (parseInt(chan) + 1) + ', ';
            } else if (Arry[chan] === '1' && chan < 15 && Arry[parseInt(chan)] === Arry[(parseInt(chan) + 1)] && ChanString.charAt(ChanString.length - 1) !== '-') {
                ChanString += (parseInt(chan) + 1) + '-';

            }
        }

        Arry = [];
        for (index = 0; index < numberOfZones; index++) {
            if ((zoneMap >> index) & 0x01)
                Arry.push('1');
            else
                Arry.push('ign');
        }

        //console.log(Arry);

        for (chan in Arry) {

            if (Arry[chan] === '1' && Arry[parseInt(chan)] !== Arry[(parseInt(chan) + 1)]) {
                //displays A-H for zones
                zoneString += zoneDisplayArr[chan] + ', ';

            } else if (Arry[chan] === '1' && chan < 7 && Arry[parseInt(chan)] === Arry[(parseInt(chan) + 1)] && zoneString.charAt(zoneString.length - 1) !== '-') {
                //displays A-H for zones
                zoneString += zoneDisplayArr[chan] + '-';

            }
        }

        if (ChanString !== '') {
            prettyString += 'Chans: ' + ChanString + ' ';
        }
        if (zoneString !== '') {
            prettyString += 'Zones: ' + zoneString + ' ';
        }

        prettyString += 'Set level '+ parseInt(duty) + '% ' + parseInt(fadeRate) + 's fade';

        if (action === 'Set Auto Level (mask,%,fade)') {
            prettyString += ' & enable DLH';
        }else{
            prettyString += ' & ignore DLH';
        }
        break;

    case 'Halt Fade (mask)':
    case 'Cloud Dim (mask)':
    case 'Harvest Daylight (mask)':
            var1 = var1.toString();

            hexVar1 = decToHex(var1).replace('0x', '');
            ChanString = '';
            zoneString = '';
            Arry = [];
            numberOfChannels = 16;
            numberOfZones = 8;
            zoneDisplayArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
            prettyString = '';

            str = hexVar1;
            chanMap = '0x' + str.substr(-4);
            str = str.substring(0, str.length - 4);
            zoneMap = '0x' + str.substr(-2);

            for (let i = 0; i < numberOfChannels; i++) {
                if ((chanMap >> i) & 0x01)
                    Arry.push('1');
                else
                    Arry.push('ign');
            }

            //parse off arry
            for (chan in Arry) {
                if (Arry[chan] === '1' && Arry[parseInt(chan)] !== Arry[(parseInt(chan) + 1)]) {
                    ChanString += (parseInt(chan) + 1) + ', ';
                } else if (Arry[chan] === '1' && chan < 15 && Arry[parseInt(chan)] === Arry[(parseInt(chan) + 1)] && ChanString.charAt(ChanString.length - 1) !== '-') {
                    ChanString += (parseInt(chan) + 1) + '-';

                }
            }

            Arry = [];
            for (let i = 0; i < numberOfZones; i++) {
                if ((zoneMap >> i) & 0x01)
                    Arry.push('1');
                else
                    Arry.push('ign');
            }

            //console.log(Arry);

            for (chan in Arry) {

                if (Arry[chan] === '1' && Arry[parseInt(chan)] !== Arry[(parseInt(chan) + 1)]) {
                    //displays A-H for zones
                    zoneString += zoneDisplayArr[chan] + ', ';
                } else if (Arry[chan] === '1' && chan < 7 && Arry[parseInt(chan)] === Arry[(parseInt(chan) + 1)] && zoneString.charAt(zoneString.length - 1) !== '-') {
                    //displays A-H for zones
                    zoneString += zoneDisplayArr[chan] + '-';
                }
            }

            if (ChanString !== '') {
                prettyString += 'Chans: ' + ChanString + ' ';
            }
            if (zoneString !== '') {
                prettyString += 'Zones: ' + zoneString + ' ';
            }
            if(action === 'Cloud Dim (mask)'){
                action = 'Direct Slider Control';
            }

            prettyString = prettyString+' '+action.replace('(mask)', '');
        break;
    case 'Set Load Shed (mask,0-1)':
        var1 = var1.toString();
        // var1 = var1.substring(0, var1.length - 4);

        hexVar1 = decToHex(var1).replace('0x', '');
        ChanString = '';
        zoneString = '';
        Arry = [];
        numberOfChannels = 16;
        numberOfZones = 8;
        zoneDisplayArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
        prettyString = '';
        str = hexVar1.substring(0, hexVar1.length - 4);
        chanMap = '0x' + str.substr(-4);
        str = str.substring(0, str.length - 4);
        zoneMap = '0x' + str.substr(-2);
        for (let i = 0; i < numberOfChannels; i++) {
            if ((chanMap >> i) & 0x01)
                Arry.push('1');
            else
                Arry.push('ign');
        }

        //parse off arry
        for (chan in Arry) {
            if (Arry[chan] === '1' && Arry[parseInt(chan)] !== Arry[(parseInt(chan) + 1)]) {
                ChanString += (parseInt(chan) + 1) + ', ';
            } else if (Arry[chan] === '1' && chan < 15 && Arry[parseInt(chan)] === Arry[(parseInt(chan) + 1)] && ChanString.charAt(ChanString.length - 1) !== '-') {
                ChanString += (parseInt(chan) + 1) + '-';
            }
        }

        Arry = [];
        for (let i = 0; i < numberOfZones; i++) {
            if ((zoneMap >> i) & 0x01)
                Arry.push('1');
            else
                Arry.push('ign');
        }

        //console.log(Arry);

        for (chan in Arry) {

            if (Arry[chan] === '1' && Arry[parseInt(chan)] !== Arry[(parseInt(chan) + 1)]) {
                //displays A-H for zones
                zoneString += zoneDisplayArr[chan] + ', ';
            } else if (Arry[chan] === '1' && chan < 7 && Arry[parseInt(chan)] === Arry[(parseInt(chan) + 1)] && zoneString.charAt(zoneString.length - 1) !== '-') {
                //displays A-H for zones
                zoneString += zoneDisplayArr[chan] + '-';
            }
        }

        if (ChanString !== '') {
            prettyString += 'Chans: ' + ChanString + ' ';
        }
        if (zoneString !== '') {
            prettyString += 'Zones: ' + zoneString + ' ';
        }

        prettyString = prettyString+' '+action.replace('(mask)', '');
    break;

        //Button Presses
    case 'INT.Button1':
        prettyString = 'Top Button Press';
        break;

    case 'INT.Button2':
        if (partType === 'Tny' || partType === 'Tiny') {
            prettyString = 'Bottom Button Press';
        } else {
            prettyString = 'Mid Button Press';
        }
        break;

    case 'INT.Button3':
        prettyString = 'Bottom Button Press';
        break;

    case 'INT.Button1_Release':
        prettyString = 'Top Button Release';
        break;

    case 'INT.Button2_Release':
        prettyString = 'Bottom Button Release';
        break;

    case 'INT.Button3_Release':
        prettyString = 'Bottom Button Release';
        break;

    case 'INT.Button1_Hold':
    case 'INT.Hold1':
        prettyString = 'Top Long Press';
        break;

    case 'INT.Hold2':
        prettyString = 'Bottom Long Press';
        break;

    case 'INT.Button2_Hold':
        prettyString = 'Bottom Long Press';
        break;

    case 'INT.Button3_Hold':
    case 'INT.Hold3':
        prettyString = 'Bottom Long Press';
        break;

    case 'INT.Hold1_Release':
        prettyString = 'Top Long Release';
        break;

    case 'INT.Hold2_Release':
        prettyString = 'Bottom Long Release';
        break;

    case 'INT.Hold3_Release':
        prettyString = 'Bottom Long release';
        break;

        //Actions
    case 'INT.DayLightDim':
        prettyString = 'Daylight Harvest';
        break;

    case 'INT.ButtonJOIN':
        prettyString = 'Onboard Button Press';
        break;

    case 'INT.unBoot':
        prettyString = 'On Boot';
        break;

    case 'Daylight Dim':
        prettyString = 'Daylight Link';
        break;

    case 'INT.Motion Update':
        prettyString = 'Update Master Sensor';
        break;

    case 'Motion Reset':
        prettyString = 'Reset Timeout Clock';
        break;

    case 'INT.Motion':
        prettyString = 'Motion Sense';
        break;

    case 'INT.Motion2':
        prettyString = 'Nighttime Motion Sense';
        break;

    case 'INT.Motion TimeOut':
        prettyString = 'Motion Timeout';
        break;

    case 'INT.Motion TimeOut2':
        prettyString = 'Nighttime Motion Timeout';
        break;

    case 'Cloud Dim':
        prettyString = 'Direct Slider Control';
        break;

    case 'INT.AH_Motion_Grace':
        prettyString = 'AH Motion Grace';
        break;
    case 'INT.NH_Motion_Grace':
        prettyString = 'NH Motion Grace';
        break;

    case 'Set Auto Duty(%,Fade)':

        try {
            lightLevel = parseInt(var1 / 1000);
            lightLevel = (lightLevel > 100) ? 100 : lightLevel;
            fadeRate = parseInt(var1.substr(-3));
            prettyString = 'Set level ' + lightLevel + '%, ' + fadeRate + 's fade & enable DLH';
        } catch (e) {
            prettyString = 'error ';
        }
        break;
    case 'Set Manual Duty(%,Fade)':
        try {
            lightLevel = parseInt(var1 / 1000);
            lightLevel = (lightLevel > 100) ? 100 : lightLevel;
            //var1 = toString(var1);
            fadeRate = parseInt(var1.substr(-3));
            prettyString = 'Set level ' + lightLevel + '%, ' + fadeRate + 's fade & ignore DLH';
        } catch (e) {
            prettyString = 'error ';
        }
        break;

    case 'Enable Motion (0/1)':
        if (var1 === '1')
            prettyString = 'Enable Motion Control';
        else
            prettyString = 'Disable Motion Control';
        break;

    case 'Set Motion Mode':
        if (var1 === '0')
            prettyString = 'Normal Occupancy Mode';
        else if (var1 === '3')
            prettyString = 'Nighttime Occupancy Mode';
        else
            prettyString = action + ': ' + var1;
        break;

    default:
        prettyString = (var1) ? (action + ': ' + var1) : action;
        break;
    }
    return prettyString;
};

export default displayPretty;