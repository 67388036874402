import $ from 'jquery';
import Communication from 'api/amatis/utilities/communications';
import App from 'app/app';
import store from 'store';
import { MyTemplates } from 'classes/templates';
import { logOut } from 'redux/auth';

let User = {
    apiToken: '',
    myUser: {},
    mySites: [],
    myPermissions: {},
    login: function () {
        // $('#action-overlay').fadeIn('fast', function () {
        //     let un = $('#site-email').val().toLowerCase();
        //     let pw = $('#site-pass').val();

        //     let myURL = Communication.getBaseURL() + '/users/me/token';

        //     $.ajax({
        //         type: 'POST',
        //         timeout: 10000,
        //         data: {
        //             'email': un,
        //             'password': pw
        //         },
        //         datatype: 'json',
        //         url: myURL,

        //         success: function (response) {
        //             let storage = window.localStorage;

        //             //Store the token for later
        //             storage.setItem('amatis_token', response.token);

        //             //Create a user with the info that came back
        //             User.myUser = new App.User(response.data.user.email, response.data.user.role, [], response.data.user.id);

        //             //Store the authorization token we got back
        //             User.apiToken = response.data.token;
        //             storage.setItem('amatis_token', User.apiToken);

        //             //TODO: We then need to get the site permissions for this user
        //             User.getSitesList();

        //         },
        //         error: function (e) {
        //             //TODO: Replace with updated logic once it exists
        //             if (e.status === 401 || e.status === 400 || e.status === 403) {
        //                 Error('User.login', e.status, false, '<i class=\'icon-exclamation-sign\'></i>  Invalid Username or Password');
        //                 window.localStorage.removeItem('amatis_token');
        //                 $('#site-pass').val('').fadeIn('fast');
        //             } else {
        //                 Error('User.login', e.status, false, '<i class="icon-exclamation-sign"></i>  Unable to connect to server.');
        //             }
        //             $('#action-overlay').fadeOut('fast');
        //         }
        //     });
        // });
    },
    siteIDFromStorage(){
        const storage = window.localStorage;
        if (storage.length > 0) {
            try {
              const siteID = storage.getItem('amatis_site_id');
              return (siteID !== null) ? siteID : '';
            } catch {
              // safari private mode throws errors
              return '';
            }
        }
        return '';
    },
    siteNameFromStorage(){
        var storage = window.localStorage;
        if (storage.length > 0) {
            let siteName = storage.getItem('amatis_site_name');
            if(siteName !== null){
                return siteName;
            }else{
                return '';
            }
        } else {
            return '';
        }
    },
    //duplicate isLocalStorage function in app.js
    isLocalStorage: function () {
        var storage = window.localStorage;
        if (storage.length > 0) {
            try {
                //Check for token
                if (storage.getItem('amatis_token') !== null) {
                    User.apiToken = storage.getItem('amatis_token');
                } else {
                    return false;
                }

                //Check for user
                if (storage.getItem('amatis_user') !== null) {
                    User.myUser = JSON.parse(storage.getItem('amatis_user'));
                }

                //Check for a siteid, if found load that site directly
                if (storage.getItem('amatis_site_id') !== null) {
                    App.activeSiteID = storage.getItem('amatis_site_id');
                }

                return true;
            } catch (e) {
                console.log(e); //debug
                console.log('FAILED to get site info from local');
                return false;
            }
        } else {
            return false;
        }
    },
    //TODO: build this once we have an endpoint
    getSitesList: function () {
        let myURL = Communication.getBaseURL('v2') + '/users/me/sites';
        // let loginStatusString;
        return new Promise((resolve, reject) =>{
            $.ajax({
                type: 'GET',
                timeout: 10000,
                datatype: 'json',
                url: myURL,
                headers: {
                    Authorization: 'Bearer ' + User.apiToken,
                },
                success: function (response) {
                    resolve(response.data);
                },
                error: function (e) {
                    reject(e);
                    if (e.status === 401 || e.status === 400 || e.status === 403) {
                        if(e.status === 401){
                            // alert("Your session has expired!");
                            // App.alert('success', 'Please Log in again. You\'ve been logged out because your session expired');
                            localStorage.removeItem('localLocation');
                            localStorage.removeItem('localScene');
                            localStorage.removeItem('App.localIP');
                            localStorage.removeItem('amatis_site_id');
                            localStorage.removeItem('amatis_user');
                            localStorage.removeItem('amatis_token');
                            window.location.reload();
                        }
                    } else {
                        Error('User.login', e.status, false, '<i class="icon-exclamation-sign"></i>  Unable to connect to server.');
                    }
                    $('#action-overlay').fadeOut('fast');
                }
            });
        });
    },
    isPermitted(rolesRequired = ['admin', 'company']){
        //takes in roles required do a thing
        //returns if the current user can do it
        rolesRequired = new Set(rolesRequired);
        let forceCase = false
        let { role, tech_level } = this.myUser;
        if(role === 'installer' && tech_level === 1){
            forceCase = true;
        }
        if(role === 'admin' && tech_level === 1){
            forceCase = true;
        }
        if(rolesRequired.has(role)){
            return true;
        }else if(forceCase === true){
            return true;
        }else{
            return false;
        }
    },
    hasPermission(permission) {
        if (!permission) return false;
        if (!User.hasOwnProperty('myPermissions')) return false;

        if (User.myPermissions.hasOwnProperty(permission)) {
            return User.myPermissions[permission] === true;
        }
        return false;
    },
    isSuperAdmin() {
        return [440, 1700, 2723, 774, 2867, 2812, 2799, 2463].includes(User.myUser.id) || User.myUser.email.includes('@meltstudio.co');
    },
    logout: function () {
      store.dispatch(logOut());
    },

    getTemplates: function () {
        User.myUser.templates = {}; //If we dont find any stored ones, this will stay an empty object
        let storage = window.localStorage;

        //If we find any, we add them to the user templates object.
        let existingTemplates = storage.getItem('user_defined_loc_templates');
        if (existingTemplates)
            User.myUser.templates = $.parseJSON(existingTemplates);
        else //Else bail
            return;

        //Add any templates we found to the main template object
        for (let template in User.myUser.templates) {
            MyTemplates[template] = User.myUser.templates[template];
        }
    }
};

export default User;
