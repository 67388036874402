/* eslint-disable react/display-name */
import React from 'react';
import Loadable from 'react-loadable';
import PublicAuthHOC from 'components/hoc/public-authorization';
import PrivateAuthHOC from 'components/hoc/private-authorization';
import ActionOverlay from 'components/app/status/Loading/action-overlay';

// Actions
import { listSites } from 'redux/sites/actions';
import { fetchUsers, clearUsers } from 'redux/users/actions';
import { listMeSites, clearSites } from 'redux/sites/actions';
import { getUser, clearUser } from 'redux/user/actions';

const AsyncHome = Loadable({
    loader: () => import(/* webpackChunkName: 'home' */ 'components/containers/landing-page'),
    loading: () => <ActionOverlay show />,
});

const AsyncLogin = Loadable({
    loader: () => import(/* webpackChunkName: 'login' */ 'components/containers/login'),
    loading: () => <ActionOverlay show />,
});

const AsyncSignUp = Loadable({
    loader: () => import(/* webpackChunkName: 'signUp' */ 'components/containers/create-account'),
    loading: () => <ActionOverlay show />,
});

const AsyncSelectSite = Loadable({
    loader: () => import(/* webpackChunkName: 'select_site' */ 'components/containers/site-list'),
    loading: () => <ActionOverlay show />,
});

const AsyncCreateSite = Loadable({
    loader: () => import(/* webpackChunkName: 'create_site' */ 'components/containers/create-site'),
    loading: () => <ActionOverlay show />,
});

const AsyncDashboard = Loadable({
    loader: () => import(/* webpackChunkName: 'dashboard' */ 'components/containers/site-dashboard'),
    loading: () => <ActionOverlay show />,
});

const AsyncUsers = Loadable({
    loader: () => import(/* webpackChunkName: 'users' */ 'components/containers/users'),
    loading: () => <ActionOverlay show />,
});

const AsyncUser = Loadable({
    loader: () => import(/* webpackChunkName: 'user' */ 'components/containers/user'),
    loading: () => <ActionOverlay show />,
});

const NotFound = Loadable({
    loader: () => import(/* webpackChunkName: 'not-found' */ 'components/containers/not-found'),
    loading: () => <ActionOverlay show />,
})

const AsyncForgotPassword = Loadable({
    loader: () => import(/* webpackChunkName: 'login' */ 'components/containers/forgot-password'),
    loading: () => <ActionOverlay show />,
});

const AsyncResetPassword = Loadable({
    loader: () => import(/* webpackChunkName: 'reset-password' */ 'components/containers/reset-password'),
    loading: () => <ActionOverlay show />,
});

const AsyncOnboardingMfa = Loadable({
    loader: () => import(/* webpackChunkName: 'onboarding-mfa' */ 'components/containers/screen-onboarding-mfa'),
    loading: () => <ActionOverlay show />,
});

const AsyncMfa = Loadable({
    loader: () => import(/* webpackChunkName: 'mfa' */ 'components/containers/screen-mfa'),
    loading: () => <ActionOverlay show />,
});

const routes = [
    {
        exact: true,
        path: '/',
        component: PublicAuthHOC(AsyncHome),
    },{
        exact: true,
        path: '/login',
        component: PublicAuthHOC(AsyncLogin),
    },{
        exact: true,
        path: '/forgot-password',
        component: PublicAuthHOC(AsyncForgotPassword),
    },{
        exact: true,
        path: '/reset-password/:token',
        component: PublicAuthHOC(AsyncResetPassword),
    },{
        exact: true,
        path: '/sign-up',
        component: PublicAuthHOC(AsyncSignUp),
    },{
        exact: true,
        path: '/onboarding-mfa',
        component: PrivateAuthHOC(AsyncOnboardingMfa),
    },{
        exact: true,
        path: '/mfa',
        component: PrivateAuthHOC(AsyncMfa),
    },{
        exact: true,
        path: '/select-site',
        component: PrivateAuthHOC(AsyncSelectSite),
        actionsMount: [listSites],
        actionsUnmount: [clearSites],
    },{
        exact: true,
        path: '/create-site',
        component: PrivateAuthHOC(AsyncCreateSite),
    },{
        exact: true,
        path: '/users',
        component: PrivateAuthHOC(AsyncUsers),
        actionsMount: [fetchUsers, listSites],
        actionsUnmount: [clearUsers],
    },
    {
        exact: true,
        path: '/users/:id',
        component: PrivateAuthHOC(AsyncUser),
        actionsMount: [getUser, listMeSites],
        actionsUnmount: [clearUser, clearSites],
    },
    // TODO: This route should always go before the last to last.
    {
      exact: true,
      path: [
          '/site/:id?/:section(locations|scenes|devices|sync|config|config-redux|charts|ota|events|events?|ambr|ambr-app|mqtt|schedule|settings|user|data-table|validator)?',
          '/site/:id?/location/:location?/:section(locations|scenes|devices|sync|config|ota|config-redux|charts|events|events?|ambr|ambr-app|mqtt|schedule|data-table|validator)?',
      ],
      component: PrivateAuthHOC(AsyncDashboard),
    },
    {
      name: '404',
      path: '*',
      component: NotFound,
    },
];

export default routes;
