import {
    listCompanies,
} from "./actions";

const initialState = {
    error: null,
    items: [],
    loading: true,
};

const handleRequest = state =>
    Object.assign({}, state, { loading: true, error: null });

const handleSuccess = (state, { payload }) =>
    Object.assign({}, state, { items: payload });

const handleFailure = (state, { payload }) =>
    Object.assign({}, state, { error: payload });

const handleFulfill = state => Object.assign({}, state, { loading: false });

const handlers = {
    [listCompanies.request.toString()]: handleRequest,
    [listCompanies.success.toString()]: handleSuccess,
    [listCompanies.failure.toString()]: handleFailure,
    [listCompanies.fulfill.toString()]: handleFulfill,
};

const reducer = (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
