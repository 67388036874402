import { createAction } from 'redux-actions';
import SDK from 'api/sdk';
import App from 'app/app';
import Devices from 'api/amatis/site/devices/services/devices';
import Scene from 'api/amatis/site/scenes/Scene';

export const LIST_SCENES_REQUEST = 'LIST_SCENES_REQUEST';
export const LIST_SCENES_SUCCESS = 'LIST_SCENES_SUCCESS';
export const LIST_SCENES_FAILURE = 'LIST_SCENES_FAILURE';
export const LIST_SCENES_FULFILL = 'LIST_SCENES_FULFILL';

export const SCENES_SEARCH = 'SCENES_SEARCH';
export const SCENES_CHECK = 'SCENES_CHECK';
export const SCENES_EDITABLE = 'SCENES_EDITABLE';
export const SCENES_STAGE_NAMES = 'SCENES_STAGE_NAMES';
export const SCENES_MODE_EDIT = 'SCENES_MODE_EDIT';

export const SCENES_CLEAR_CHECKED = 'SCENES_CLEAR_CHECKED';
export const SCENES_CLEAR_EDITABLE = 'SCENES_CLEAR_EDITABLE';
export const SCENES_CLEAR_STAGE_NAMES = 'SCENES_CLEAR_STAGE_NAMES';

export const UPDATE_SCENE_REQUEST = 'UPDATE_SCENE_REQUEST';
export const UPDATE_SCENE_SUCCESS = 'UPDATE_SCENE_SUCCESS';
export const UPDATE_SCENE_FAILURE = 'UPDATE_SCENE_FAILURE';
export const UPDATE_SCENE_FULLFILL = 'UPDATE_SCENE_FULLFILL';

export const DELETE_SCENE_REQUEST = 'DELETE_SCENE_REQUEST';
export const DELETE_SCENE_SUCCESS = 'DELETE_SCENE_SUCCESS';
export const DELETE_SCENE_FAILURE = 'DELETE_SCENE_FAILURE';
export const DELETE_SCENE_FULLFILL = 'DELETE_SCENE_FAILURE';

export const ADD_SCENE_REQUEST = 'ADD_SCENE_REQUEST';
export const ADD_SCENE_SUCCESS = 'ADD_SCENE_SUCCESS';
export const ADD_SCENE_FAILURE = 'ADD_SCENE_FAILURE';
export const ADD_SCENE_FULLFILL = 'ADD_SCENE_FULLFILL';

export const SET_SCENE_MODAL = 'SET_SCENE_MODAL';
export const SELECTED_SCENE = 'SELECTED_SCENE';
export const LAST_ITEMS_UPDATE = 'LAST_ITEMS_UPDATE';

export const listScenesRequest = createAction(LIST_SCENES_REQUEST);
export const listScenesSuccess = createAction(LIST_SCENES_SUCCESS);
export const listScenesFailure = createAction(LIST_SCENES_FAILURE);
export const listScenesFulfill = createAction(LIST_SCENES_FULFILL);

export const scenesSearch = createAction(SCENES_SEARCH);
export const scenesCheck = createAction(SCENES_CHECK);
export const scenesEditable = createAction(SCENES_EDITABLE);
export const scenesStageNames = createAction(SCENES_STAGE_NAMES);
export const scenesModeEdit = createAction(SCENES_MODE_EDIT);

export const scenesClearChecked = createAction(SCENES_CLEAR_CHECKED);
export const scenesClearEditable = createAction(SCENES_CLEAR_EDITABLE);
export const scenesClearStageNames = createAction(SCENES_CLEAR_STAGE_NAMES);

export const updateSceneRequest = createAction(UPDATE_SCENE_REQUEST);
export const updateSceneSuccess = createAction(UPDATE_SCENE_SUCCESS);
export const updateSceneFailure = createAction(UPDATE_SCENE_FAILURE);
export const updateSceneFullfill = createAction(UPDATE_SCENE_FULLFILL);

export const deleteSceneRequest = createAction(DELETE_SCENE_REQUEST);
export const deleteSceneSuccess = createAction(DELETE_SCENE_SUCCESS);
export const deleteSceneFailure = createAction(DELETE_SCENE_FAILURE);
export const deleteSceneFullfill = createAction(DELETE_SCENE_FULLFILL);

export const addSceneRequest = createAction(ADD_SCENE_REQUEST);
export const addSceneSuccess = createAction(ADD_SCENE_SUCCESS);
export const addSceneFailure = createAction(ADD_SCENE_FAILURE);
export const addSceneFullfill = createAction(ADD_SCENE_FULLFILL);

export const setSceneModal = createAction(SET_SCENE_MODAL);
export const selectedScene = createAction(SELECTED_SCENE);

export const lastItemUpdate = createAction(LAST_ITEMS_UPDATE);

export const updateScene = (id, values) => dispatch => {
    dispatch(updateSceneRequest());
    const SITEID = window.localStorage.getItem('amatis_site_id');
    const OPTIONS = { requestBody: values, serverVariables: { baseVersion: "v2" } };
    const params = { SITEID, SCENEID: id };
    return SDK.init()
    .then(api => api.scenes.pathScene(params, OPTIONS))
    .then(response => {
        const { body: { data } } = response;
        dispatch(updateSceneSuccess(data));
        dispatch(lastItemUpdate());
        // FIXME: remove actions to app data to legacy
        if(App.Site && App.Site.scenes && App.Site.scenes[data.id]) {
            App.Site.scenes[data.id].hidden = Number(data.hidden);
            App.Site.scenes[data.id].name = data.name;
            App.PageContent.setSceneList(App.Site.scenes);
        }
        return data;
    }).catch(error => {
        dispatch(updateSceneFailure(error));
    })
    .finally(() => {
        dispatch(updateSceneFullfill());
    });
};

export const deleteScene = id => dispatch => {
    dispatch(deleteSceneRequest());

    if (App.Site.scenes[id]) {
        App.Site.scenes[id].prepareAssociatedDeviceActionsForDeletion();
        delete App.Site.scenes[id] 
    }

    const SITEID = window.localStorage.getItem('amatis_site_id');
    const OPTIONS = { serverVariables: { baseVersion: "v2" } };
    const params = { SITEID, SCENEID: id };
    return SDK.init()
    .then(api => api.scenes.deleteScene(params, OPTIONS))
    .then(response => {
        const { body: { data } } = response;
        dispatch(deleteSceneSuccess(data));
        dispatch(lastItemUpdate());
        return data;
    }).then(data => {
        // FIXME: remove import externalActionsToSave and Devices in action
        if (!App.Site.root.externalActionsToSave) return;
        const requestBody = {
            data: `[${App.Site.root.externalActionsToSave}]`
        };
        const newOptions = Object.assign({}, OPTIONS, { requestBody });
        return SDK.init()
            .then(api => api.sites.setSceneTable(params, newOptions))
            .then(response => {
                const { body: { data, errors } } = response;
                if (errors && errors.length > 0) {
                    dispatch(deleteSceneFailure(errors[0]));
                } else {
                    Devices.parseActions(data);
                    App.Site.root.externalActionsToSave = "";
                }
            }).catch(error => {
                dispatch(deleteSceneFailure(error));
            });
    }).catch(error => {
        dispatch(deleteSceneFailure(error));
    })
    .finally(() => {
        dispatch(deleteSceneFullfill());
    });
};

export const addScene = (sceneObj) => dispatch => {
    dispatch(addSceneRequest());
    const SITEID = window.localStorage.getItem('amatis_site_id');
    let requestBody = sceneObj;
    const OPTIONS = { requestBody, serverVariables: { baseVersion: "v2" } };
    const params = { SITEID };
    return SDK.init()
    .then(api => api.scenes.saveSceneBySite(params, OPTIONS))
    .then(response => {
        const { body: { data } } = response;
        dispatch(addSceneSuccess(data));
        dispatch(scenesEditable(data.id));
        dispatch(lastItemUpdate());
        new Scene(data)
        return data;
    }).catch(error => {
        dispatch(addSceneFailure(error));
    })
    .finally(() => {
        dispatch(addSceneFullfill());
    });
};
