const defines = {
    'BAF-SERIAL': {
        classification: 'other',
        trigger: false,
        is_sensor: true,
        is_switch: false,
        is_dimmer: true,
        alias: 'ISIS FAN'
    },
    'BAF20': {
        classification: 'other',
        trigger: false,
        is_sensor: false, //Set to false by default, and set later when we look for connected sensors
        is_dimmer: true,
        alias: 'BAF-NODE'
    },
    'AM-SW8': {
        classification: 'switch',
        trigger: true,
        is_sensor: true,
        is_switch: true,
        is_dimmer: false,
        alias: 'COMMAND'
    },
    'AM-SWITCH': {
        classification: 'switch',
        trigger: true,
        is_sensor: false,
        is_switch: true,
        is_dimmer: false,
        alias: 'COMMAND'
    },
    'AM-SW3': {
        classification: 'switch',
        trigger: true,
        is_sensor: false,
        is_switch: true,
        is_dimmer: false,
        alias: 'TRIO'
    },
    'AM-SW-3': {
        classification: 'switch',
        trigger: true,
        is_sensor: false,
        is_switch: true,
        is_dimmer: false,
        alias: 'TRIO'
    },
    'Tny': {
        classification: 'switch',
        trigger: true,
        is_sensor: false,
        is_switch: true,
        is_dimmer: false,
        is_spoofed: true,
        alwaysDimmingEnable: true,
        alias: 'Tiny'
    },
    'Tiny': {
        classification: 'switch',
        trigger: true,
        is_sensor: false,
        is_switch: true,
        is_dimmer: false,
        is_spoofed: true,
        alwaysDimmingEnable: true,
        alias: 'Tiny'
    },
    'AM-SW-ML': {
        classification: 'sensor',
        trigger: true,
        is_sensor: true,
        is_switch: false,
        is_dimmer: false,
        alias: 'SENSE'
    },
    'AM-SW-M': {
        classification: 'sensor',
        trigger: true,
        is_sensor: true,
        is_switch: true,
        is_dimmer: false,
        alias: 'MEDLEY'
    },
    'AM-SW-MLTH': {
        classification: 'sensor',
        trigger: true,
        is_sensor: true,
        is_switch: false,
        is_dimmer: false,
        alias: 'SENSE-TH'
    },
    'AM-DCDIM': {
        classification: 'dimmer',
        trigger: false,
        is_sensor: false,
        is_switch: false,
        is_dimmer: true,
        alias: 'RISE'
    },
    'AM-DCDIM-DAC': {
        classification: 'dimmer',
        trigger: false,
        is_sensor: false,
        is_switch: false,
        is_dimmer: true,
        alias: 'RISE'
    },
    'AM-DCDIM-PWM': {
        classification: 'dimmer',
        trigger: false,
        is_sensor: false,
        is_switch: false,
        is_dimmer: true,
        alias: 'LED'
    },
    'AM-PHD': {
        classification: 'dimmer',
        trigger: false,
        is_sensor: false,
        is_switch: false,
        is_dimmer: true,
        alias: 'PHD',
        alwaysDimmingEnable: true
    },
    'XPOE': {
        classification: 'dimmer',
        trigger: false,
        is_sensor: false,
        is_switch: false,
        is_dimmer: true,
        alias: 'XPOE',
        multi_channel: true,
        multi_channel_version: 2,
        alwaysDimmingEnable: true
    },
    'DCDIM-TINY-TEST': {
        classification: 'dimmer',
        trigger: false,
        is_sensor: false,
        is_switch: false,
        is_dimmer: true,
        alias: 'DCDIM-TEST'
    },
    'USGG-25P': {
        classification: 'dimmer',
        trigger: false,
        is_sensor: true,
        is_switch: false,
        is_dimmer: true,
        alias: 'SMART-DRIVER'
    },
    'ALC': {
        classification: 'dimmer',
        trigger: false,
        is_sensor: true,
        is_switch: false,
        is_dimmer: true,
        alias: 'ALC',
        alwaysDimmingEnable: true //For dimmers with no mode, this is required
    },
    'ALC-LVD': {
        classification: 'dimmer',
        trigger: false,
        is_sensor: true,
        is_switch: false,
        is_dimmer: true,
        alias: 'ALC-LVD',
        alwaysDimmingEnable: true //For dimmers with no mode, this is required
    },
    'MLTH': {
        classification: 'dimmer',
        trigger: false,
        is_sensor: true,
        is_switch: false,
        is_dimmer: true,
        alias: 'ALC',
        alwaysDimmingEnable: true //For dimmers with no mode, this is required
    },
    'DRIVER': {
        classification: 'dimmer',
        trigger: false,
        is_sensor: true,
        is_switch: false,
        is_dimmer: true,
        alias: 'IP DRIVER',
        alwaysDimmingEnable: true //For dimmers with no mode, this is required
    },
    'ML-DALI': {
        classification: 'dimmer',
        trigger: true,
        is_sensor: true,
        is_switch: false,
        is_dimmer: true,
        alwaysDimmingEnable: true, //For dimmers with no mode, this is required
        alias: 'WS-DALI-SENSOR'
    },
    'AM-ACDIM-MLTH': {
        classification: 'dimmer',
        trigger: false,
        is_sensor: true,
        is_switch: false,
        is_dimmer: true,
        alias: 'ACDIM-MLTH'
    },
    'AM-ACDIM': {
        classification: 'dimmer',
        trigger: false,
        is_sensor: true,
        is_switch: false,
        is_dimmer: true,
        alias: 'ACDIM'
    },
    'AM-PSM': {
        classification: 'other',
        trigger: false,
        is_sensor: false,
        is_switch: false,
        is_dimmer: false,
        alias: 'PSM'
    },
    'default': {
        classification: 'other',
        trigger: false,
        is_sensor: false,
        is_switch: false,
        is_dimmer: false,
        alwaysDimmingEnable: false,
        is_spoofed: false,
        alias: 'unknown Part_Type'
    }
};

export default defines;