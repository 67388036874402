//////////////////////////////
///////  CALL SEQUENCE  //////
//////////////////////////////
//	1. Locations.getAll();
//	2. on ajax success -> Locations.parseAll(data);
//	3. Locations.findChildren();
//	4. Scenes.getAll();
//	5. on ajax success -> Scenes.parseAll(data);
import $ from 'jquery';
import Communication from 'api/amatis/utilities/communications';
import { Locations } from  'api/amatis/site/locations/services/locations';
import { Scenes } from 'api/amatis/site/scenes/services/scenes';
import Devices from 'api/amatis/site/devices/services/devices';
import User from 'classes/users';
import { bugsnag } from 'classes/bugsnag';

import store from 'store';
import { siteLoad } from 'redux/activeSite';


let App = {
    version: '3.0',
    activeSite:{},
    ActiveLocation:false,
    isNextekApp: false,
    currentModalType: '',
    debug: false, //a flag for more robust logging
    config: true,
    doneSortingData: false,
    baseURL: '', //Root of api calls URL
    localIP: [], //an array of all localIPs associated with this siteID
    taskName: '', //is this deprecated???
    allTasks: [], //all tasks
    taskError: null,
    myUser: {}, //active user info
    myIP: '',
    isAdmin: false,
    selectedCount: 0,
    activeObject: '',
    clickstart: null,
    clickstop: null,
    touchSet: false,
    swipe: true,
    timer: 0,
    siteAmbr:{},
    isBasic: false,
    activeSiteID: null,
    eventCanceled: false, //true if touchmove. cleared on touch-up. prevent touch-down event on scroll
    allScenesForStorage: '',
    allLocationsForStorage: '', //all the local ip addies of ambr for site X are pushed here.
    allAvailableSitesStr: '',
    activeLocationIndex: 0,
    converter: 62135596800,
    cloudingToPeter: false,
    modalOption: null, //affected by user selected yes or no in confirm modal
    openTab: '',
    selectedGroupID: null,
    locationsLoaded: false,
    runningCloudToPeterCount: 0,
    keyPresses: [],
    busyLoadingSite: false,
    MyUp: 'click',
    MyDown: 'click',
    mouseDown: 'mousedown',
    mouseUp: 'mouseup',
    newDeviceList: [],
    copyNewDeviceList: [],
    touchOverride: false,
    sceneCallTO: null,
    sceneMouseUpTO: null,
    disableNavRight: false,
    dsiableNavLeft: false,
    navIconBlocker: false,
    isLiveDataLoaded: false,
    activeNav: null,
    ua: navigator.userAgent.toLowerCase(),
    isMobile: false,
    mmenuOpening: false,
    mmenuCleanUpTimer: false,
    visitedLocations: new Set(),
    /////////////////////////////
    //DROPDOWN CONTROLLERS
    addDeviceMenuOpen: false,
    locFeaturesMenuOpen: false,
    locSettingsMenuOpen: false,
    sceneSettingsMenuOpen: false,
    advancedSettingsMenuOpen: false,
    lastSite: null,
    lastDevice: null,
    lastScene: null,
    lastUntaggedDev: null,
    lastGroup: null,
    menuOpen: false,
    ////////

    //UPDATE UI COMPONENTS STATE STUFF
    updateUIComponentsState:{},
    defaultInterval: 300,
    updateUIComponentsInterval: 300, //ms
    updateUIComponentsDebug: false,
    updateUIControlDisabled: false,

    isFirstLiveData: true,

    roomType: {
        0: 'icon-group',
        1: 'icon-laptop',
        2: 'icon-lightbulb',
        3: 'icon-retweet',
        4: 'icon-sun',
        5: 'icon-coffee',
        6: 'icon-food',
        7: 'icon-road',
        8: 'icon-wrench',
        9: 'icon-female',
        10: 'icon-home',
        11: 'icon-shopping-cart',
        12: 'icon-time',
        13: 'icon-question-sign'
    },

    updateUIComponentsIsBusy: function(controllerName, requestingFunction, customUpdateUIComponentsInterval){
        if (customUpdateUIComponentsInterval) App.updateUIComponentsInterval = customUpdateUIComponentsInterval;
        else App.updateUIComponentsInterval = App.defaultInterval;
        return App.updateUIComponentsHandler(controllerName, requestingFunction);
    },
    updateUIComponentsHandler: function (controllerName, requestingFunction) {
        App.updateUIComponentsDebugHandler(controllerName, requestingFunction);

        if (App.updateUIControlDisabled){
            return false;
        }

        if(App.controllerStateExistsAlready(controllerName)){
            let controller = App.updateUIComponentsState[controllerName];

            App.updateControllerRecordKeeping(controller);


            if(App.controllerIsBlocking(controller)){
                controller.doCallback = true;
                // console.log('BLOCKED!');
                return true;
            } else {
                App.controllerSetBlocking(controller);
                controller.doCallback = false;
                return false;
            }
        } else {
            App.createNewControllerState(controllerName, requestingFunction);
            return false;
        }
    },
    controllerStateExistsAlready: function(controllerName){
        return App.updateUIComponentsState.hasOwnProperty(controllerName);
    },
    updateControllerRecordKeeping: function(controller){
        //Record keeping, these arent used for any logic anywhere
        controller.callCount += 1;
        controller.lastCalled = new Date();
    },
    controllerIsBlocking: function(controller){
        return controller.blocked;
    },
    controllerSetBlocking: function(controller){
        controller.blocked = true;
        App.controllerSetClearBlockerTO(controller);
    },
    clearUpdateUIComponentsBlocker: function(controllerName){
        if(App.updateUIComponentsState.hasOwnProperty(controllerName)){
            let controller = App.updateUIComponentsState[controllerName];
            controller.blocked = false;
        }
    },
    controllerSetClearBlockerTO: function(controller){
        if(controller.readyForTO === true){
            controller.readyForTO = false;
            controller.TO = setTimeout(
                () => {
                    if(App.updateUIComponentsDebug){
                        console.log('Clearing the blocker for ' + controller.name)
                    }
                    controller.readyForTO = true;
                    App.clearUpdateUIComponentsBlocker(controller.name);
                    if(controller.doCallback){
                        controller.callback();
                    }
                }
                , App.updateUIComponentsInterval
            );
        }
    },
    createNewControllerState: function(controllerName, requestingFunction){
        const stateObject = {
            name: controllerName,
            TO: setTimeout(
                () => {
                    if(App.updateUIComponentsDebug){
                        console.log('Clearing the blocker for ' + controllerName)
                    }
                    stateObject.readyForTO = true;
                    App.clearUpdateUIComponentsBlocker(controllerName);
                    // stateObject.TO = false;
                }
                , App.updateUIComponentsInterval
            ),
            lastCalled: new Date(),
            callCount: 0,
            blocked: true,
            doCallback: false,
            readyForTO: true,
            callback: requestingFunction,
        }
        App.updateUIComponentsState[controllerName] = stateObject;
    },
    updateUIComponentsDebugHandler: function (controllerName, caller) {
        let controller = false;

        if(App.updateUIComponentsDebug === true){
            if(App.updateUIComponentsState.hasOwnProperty(controllerName)){
                controller = App.updateUIComponentsState[controllerName];
            }
            if(controller){
                console.log(`UpdateUI: ${controller.name} Count:[${controller.callCount}] (${new Date() - controller.lastCalled}ms)`);
            } else {
                console.log(`UpdateUI: First call: ${controllerName}`);
            }
        }
    },
    // This funciton checks for IE and fades in a div rendering the
    // site useless.  Trident is what gets returned when someone
    // is using IE 11, so we must check that too.  This function
    // gets called from the static index.html
    msie: function () {
        let ua = window.navigator.userAgent;
        let msie = ua.indexOf('MSIE ');
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
            $('#msie-error').fadeIn('slow');
            return true;
        } else
            return false;
    },
    logGhostScenes: function(){
        console.log(Scenes.ghostScenes);
    },

    //evidently this function was also a good place to implement
    //dynamic maxChar changes to the devices,scenes and locations lists
    resizeHeaders: function () {

        let pageWidth = window.innerWidth;
        let nameString;
        let titleLength = $('.loc-name').text().length;
        let multiplier = 35;
        let titleWidthRatio = Locations.maxChar - titleLength;
        if (titleWidthRatio < 0) {
            titleWidthRatio = 0;
        }

        let originalFontSize = 48;
        let originalIconSize = 48;

        if (pageWidth <= (845 - multiplier * (titleWidthRatio)) && pageWidth >= (725 - multiplier * (titleWidthRatio))) {

            $('#page-title').css('font-size', originalFontSize / 1.25 + 'px');
            $('#active-location-settings-icon').css('font-size', originalIconSize / 1.25 + 'px');

            $('#loc-settings-icon').css('font-size', originalIconSize / 1.25 + 'px');

        } else if (pageWidth >= (845 - multiplier * (titleWidthRatio))) {

            $('#page-title').css('font-size', originalFontSize + 'px');
            $('#active-location-settings-icon').css('font-size', originalIconSize + 'px');
            $('#loc-settings-icon').css('font-size', originalIconSize + 'px');
        } else if (pageWidth <= (725 - multiplier * (titleWidthRatio)) && pageWidth >= (645 - multiplier * (titleWidthRatio))) {

            $('#page-title').css('font-size', originalFontSize / 1.5 + 'px');
            $('#active-location-settings-icon').css('font-size', originalIconSize / 1.5 + 'px');
            $('#loc-settings-icon').css('font-size', originalIconSize / 1.5 + 'px');

        } else if (pageWidth <= (645 - multiplier * (titleWidthRatio)) && pageWidth >= (550 - multiplier * (titleWidthRatio))) {
            $('#page-title').css('font-size', originalFontSize / 2 + 'px');

        } else if (pageWidth <= (550 - multiplier * (titleWidthRatio))) {
            $('#page-title').css('font-size', originalFontSize / 4.2 + 'px');

            if ($('#link-groups').hasClass('mm-opened') && $('.scene-settings-button').text() === 'Edit') {

                $('.scene-list-button').each(function () {

                    nameString = Scenes.list[Scenes.getIndex($(this).data('linkid'), 'linkID')].name;
                    $(this).children().first().text(App.addEllipsis(nameString, Locations.maxChar) + ' (' + $(this).data('linkid') + ')');

                });
            } else if ($('#link-groups').hasClass('mm-opened') && $('.scene-settings-button').text() === 'Done') {

                $('.scene-list-button').each(function () {

                    nameString = Scenes.list[Scenes.getIndex($(this).data('linkid'), 'linkID')].name;
                    $(this).children().first().text(App.addEllipsis(nameString, Locations.maxChar) + ' (' + $(this).data('linkid') + ')');

                });
            } else if ($('#locations').hasClass('mm-opened')) {

                $.each($('.loc-name-list'), function () {
                    $(this).text(App.addEllipsis($(this).text(), Locations.maxChar));
                });

            } else if ($('#devices').hasClass('mm-opened') && $('.edit-device-list-button').text() === 'Edit') {

                $('.device-name-list').each(function () {
                    let deviceIndex = Devices.getIndex($(this).parent().data('device-id'));
                    nameString = Devices.list[deviceIndex].name;
                    $(this).text(App.addEllipsis(nameString, Devices.maxChar) + ' (' + Devices.list[deviceIndex].IP.substr(-4) + ')');
                });
            } else if ($('#devices').hasClass('mm-opened') && $('.done-device-list-button').text() === 'Done') {

                $('.device-name-list').each(function () {
                    let deviceIndex = Devices.getIndex($(this).parent().data('device-id'));
                    nameString = Devices.list[deviceIndex].name;
                    $(this).text(App.addEllipsis(nameString, Devices.maxChar) + ' (' + Devices.list[deviceIndex].IP.substr(-4) + ')');
                });
            }
        }

        if (pageWidth >= (550 - multiplier * (Locations.maxChar - titleLength))) {
            //in case window gets resized full screen or any case
            //set of if statements to change up charCount accordingly for nav
            if ($('#link-groups').hasClass('mm-opened') && $('.scene-settings-button').text() === 'Edit') {
                //Scenes.buildNav(active.locationID, Scenes.oldSearchString);//reload to get new ellipsis based on screen size for the scenes list

                $('.scene-list-button').each(function () {

                    nameString = Scenes.list[Scenes.getIndex($(this).data('linkid'), 'linkID')].name;
                    $(this).children().first().text(App.addEllipsis(nameString, Locations.maxChar) + ' (' + $(this).data('linkid') + ')');

                });

            } else if ($('#link-groups').hasClass('mm-opened') && $('.scene-settings-button').text() === 'Done') {
                //Scenes.buildNav(Scenes.oldSearchString);

                $('.scene-list-button').each(function () {

                    nameString = Scenes.list[Scenes.getIndex($(this).data('linkid'), 'linkID')].name;
                    $(this).children().first().text(App.addEllipsis(nameString, Locations.maxChar) + ' (' + $(this).data('linkid') + ')');

                });
            }
            if ($('#locations').hasClass('mm-opened')) {
                for (let index = 0; index < Locations.list.length; index++) {
                    $('#loc-name-list-' + Locations.list[index].ID).text(App.addEllipsis(Locations.list[index].name, Locations.maxChar)).fadeIn();
                }
            }
            //when devices edit button has been hit
            if ($('#devices').hasClass('mm-opened') && $('.edit-device-list-button').text() === 'Edit') {
                $('.device-name-list').each(function () {
                    let deviceIndex = Devices.getIndex($(this).parent().data('device-id'));
                    nameString = Devices.list[deviceIndex].name;
                    $(this).text(App.addEllipsis(nameString, Devices.maxChar) + ' (' + Devices.list[deviceIndex].IP.substr(-4) + ')');
                });
            } else if ($('#devices').hasClass('mm-opened') && $('.done-device-list-button').text() === 'Done') {

                $('.device-name-list').each(function () {
                    let deviceIndex = Devices.getIndex($(this).parent().data('device-id'));
                    nameString = Devices.list[deviceIndex].name;
                    $(this).text(App.addEllipsis(nameString, Devices.maxChar) + ' (' + Devices.list[deviceIndex].IP.substr(-4) + ')');
                });

            }
        }

    },

    addEllipsis: function (string, maxChar, isHeader) {
        //this function needs to stay till we get rid of it everywhere...
        return string;

    },

    //Resets all dropdown controllers to false
    resetAllDropdownControllers: function () {
        App.addDeviceMenuOpen = false;
        App.locFeaturesMenuOpen = false;
        App.locSettingsMenuOpen = false;
        App.sceneSettingsMenuOpen = false;
    },

    //gets the parent and appends it to the location vi ew (like file path)
    getBreadcrumbs: function (id) {
        let locationIndex = Locations.getIndex(id, 'ID');
        let activeLoc;

        if (locationIndex >= 0)
            activeLoc = Locations.list[Locations.getIndex(id, 'ID')];
        else
            return;

        let html = '';
        let locations = [];
        let parentIDs = [];

        if (locationIndex < 1) {
            return '<li>Site Level View</li>';
        }
        //Stop when we get to the root location
        while (activeLoc.parent.hasOwnProperty('parent') && activeLoc.parent.parent.ID !== 0) {
            locations.push(activeLoc.parent.name);
            parentIDs.push(activeLoc.parent.ID);
            activeLoc = activeLoc.parent;
        }

        // reverse results and build dom
        locations.reverse();
        parentIDs.reverse();
        let len = locations.length;
        for (let i = 0; i < len; i++) {
            if (i < len - 1)
                html += '<li class="loc-page-crumb" data-index=' + Locations.getIndex(parentIDs[i], 'ID') + '>' + locations[i] + '</li>';
            else
                html += '<li class="loc-page-crumb" data-index=' + Locations.getIndex(parentIDs[i], 'ID') + '>' + locations[i] + '</li>';

        }
        //html += '<li><span class="success roundy">Occupied</span></li>';

        return html;
    },

    makeSceneButtons: function (id, lightLevel) {
        let index = Locations.getIndex(id, 'ID');
        let html = '';
        /* 		let len = Locations.list[index].assocScenes.length; */
        /* 		let scenesHere = Locations.list[index].assocScenes; */
        let scenesHere = Locations.findAssocScenes(index);
        let len = scenesHere.length;
        let directControl = false; //true if we want knob control
        let lightLevelValue;

        if (index === 0) {
            return this.makeSceneButtonsAll(id);
        }

        lightLevel = parseInt(lightLevel); //remove the unit

        if (lightLevel < 1)
            lightLevelValue = lightLevel + 1;
        else
            lightLevelValue = lightLevel;

        if (isNaN(lightLevelValue)) {
            lightLevelValue = 1;
            lightLevel = 0;
        }

        for (let scene = 0; scene < len; scene++) {
            //dont display hidden scenes
            if (scenesHere[scene].hidden === 1)
                continue;

            if ($.trim(scenesHere[scene].name.toLowerCase()).indexOf('direct control') >= 0) {
                html += '<div class=\'loc-scene-container\'><h2 oc=\'' + index + '\' id=\'' + scenesHere[scene].linkID + '\' class=\'section-title\'>Light Level<label id=\'knob-readout-' + scenesHere[scene].linkID + '\'>' + lightLevel + '%</label>';
                html += '<p style="padding-top:10px;"><input data-linkID="' + scenesHere[scene].linkID + '" value="' + parseInt(lightLevelValue) + '" class="knob" data-max="100" data-dontdisplayinput="true" data-fgColor="#66CC66" data-linecap="round" data-angleOffset="-125" data-displayInput="true" data-angleArc="250"></p>';
                html += '</h2>';
                html += '<label oc=\'' + index + '\' id=\'data-stream-readout\' class=\'right\'>';
                html += '</label>';
                continue;
            }
            if ($.trim(scenesHere[scene].name.toLowerCase()) === 'fan control') {
                html += '<h2 oc=\'' + index + '\' id=\'' + scenesHere[scene].linkID + '\' class=\'section-title\'>Fan Speed<label id=\'knob-readout-' + scenesHere[scene].linkID + '\'>' + lightLevel + '%</label>';
                html += '<p style="padding-top:10px;"><input data-linkID="' + scenesHere[scene].linkID + '" value="' + parseInt(lightLevelValue) + '" class="knob" data-max="100" data-dontdisplayinput="true" data-fgColor="#66CC66" data-linecap="round" data-angleOffset="-125" data-displayInput="true" data-angleArc="250"></p>';
                html += '</h2>';
                html += '<label oc=\'' + index + '\' id=\'data-stream-readout\' class=\'right\'>';
                html += '</label>';
                continue;
            }
            html += '<div class="master-slider">';

            html += '</div>';

            //Might be a lot of these, so we need to just append them to the container
            if (scenesHere[scene].description === 'individual_direct_control') {
                $('.individual-slider-container').fadeIn('fast');

                html += '<label class="left setting-label"><i class="icon-lightbulb">&nbsp&nbsp</i>Fixture 1: <readout id="ind-slider-readout-256" class="individual-level-indicator">67</readout>%</label>';
                html += '<input id="min" class="individual-slider" data-linkID="' + scenesHere[scene].linkID + '" type="range" min="0" max="100" value="67" step="1">';
                $('.individual-slider-container').append(html);
                return;
            }
            //Just one of these, just throw it into the right place
            if (scenesHere[scene].description === 'master_individual_direct_control') {
                $('.individual-slider-container').fadeIn('fast');

                html += '<label class="left setting-label"><i class="icon-lightbulb">&nbsp&nbsp</i>Master: <readout id="master-slider-readout-255" class="individual-level-indicator">67</readout>%</label>';
                html += '<input id="min" class="master-direct-slider settings-input" data-linkID="' + scenesHere[scene].linkID + '" type="range" min="0" max="100" value="67" step="1">';

                $('#master-slider').html(html).fadeIn();
                return;
            }
            //put your html stuffs here...
            if (scenesHere[scene].active !== 1 && !directControl)
                html += '<h2 loc=\'' + index + '\' id=\'' + scenesHere[scene].linkID + '\' class=\'section-title scene-button\'>' + scenesHere[scene].name + '</h2>';
            else
                html += '<h2 loc=\'' + index + '\' id=\'' + scenesHere[scene].linkID + '\' class=\'active section-title scene-button\'>' + scenesHere[scene].name + '</h2>';
        }
        if (html === '')
            return '<label>All scenes in this location are currently hidden (<i class="icon-eye-close"></i>)</label>';
        else
            return html;
    },

    makeSceneButtonsAll: function (id, lightLevel) {
        let index = Locations.getIndex(id, 'ID');
        let html = '';
        let locLen = Locations.list.length;
        let directControl = false; //true if we want knob control
        let lightLevelValue;
        let scenesHere = [];

        lightLevel = parseInt(lightLevel); //remove the unit

        for (let locIndex = 1; locIndex < locLen; locIndex++) {
            let activeLoc = Locations.list[locIndex];
            scenesHere = Locations.findAssocScenes(locIndex, false);

            if (activeLoc.children.length === 0) {
                html += '<h1 class="main-list-header">' + activeLoc.name + '</h1>';
            } else {
                //TODO: I really want to handle the tree structure here... but need more time
                //html += '<h1 class="">' + activeLoc.name + ':</h1>';
                continue;
            }

            lightLevel = parseInt(activeLoc.lightLevel);

            if (lightLevel < 1)
                lightLevelValue = lightLevel + 1;
            else
                lightLevelValue = lightLevel;

            if (isNaN(lightLevelValue)) {
                lightLevelValue = 1;
                lightLevel = 0;
            }

            let len = scenesHere.length;

            for (let scene = 0; scene < len; scene++) {
                //console.log($.trim(scenesHere[scene].name.toLowerCase()).indexOf('direct control'));
                try {
                    //dont display hidden scenes
                    if (scenesHere[scene].hidden === 1)
                        continue;

                    if ($.trim(scenesHere[scene].name.toLowerCase()).indexOf('direct control') >= 0) {
                        html += '<h2 oc=\'' + index + '\' id=\'' + scenesHere[scene].linkID + '\' class=\'section-title\'>Light Level<label id=\'knob-readout-' + scenesHere[scene].linkID + '\'>' + lightLevel + '%</label>';
                        html += '<p style="padding-top:10px;"><input data-linkID="' + scenesHere[scene].linkID + '" value="' + parseInt(lightLevelValue) + '" class="knob" data-max="100" data-dontdisplayinput="true" data-fgColor="#66CC66" data-linecap="round" data-angleOffset="-125" data-displayInput="true" data-angleArc="250"></p>';
                        html += '</h2>';
                        html += '<label oc=\'' + index + '\' id=\'data-stream-readout\' class=\'right\'>';
                        html += '</label>';
                        continue;
                    }
                    if ($.trim(scenesHere[scene].name.toLowerCase()) === 'fan control') {
                        html += '<h2 oc=\'' + index + '\' id=\'' + scenesHere[scene].linkID + '\' class=\'section-title\'>Fan Speed<label id=\'knob-readout-' + scenesHere[scene].linkID + '\'>' + lightLevel + '%</label>';
                        html += '<p style="padding-top:10px;"><input data-linkID="' + scenesHere[scene].linkID + '" value="' + parseInt(lightLevelValue) + '" class="knob" data-max="100" data-dontdisplayinput="true" data-fgColor="#66CC66" data-linecap="round" data-angleOffset="-125" data-displayInput="true" data-angleArc="250"></p>';
                        html += '</h2>';
                        html += '<label oc=\'' + index + '\' id=\'data-stream-readout\' class=\'right\'>';
                        html += '</label>';
                        continue;
                    }

                    //put your html stuffs here...
                    if (scenesHere[scene].active !== 1 && !directControl)
                        html += '<h2 loc=\'' + index + '\' id=\'' + scenesHere[scene].linkID + '\' class=\'section-title scene-button\'>' + scenesHere[scene].name + '</h2>';
                    else
                        html += '<h2 loc=\'' + index + '\' id=\'' + scenesHere[scene].linkID + '\' class=\'active section-title scene-button\'>' + scenesHere[scene].name + '</h2>';
                } catch (e) {
                    console.log(e);
                }

            }
        }
        return html;
    },
    scrollDisabler: function (type) {
        let el;
        if (type === 'device') {
            el = '.device-list-el';
        } else if (type === 'location') {
            el = '.loc-list-li';
        } else if (type === 'scene') {
            el = '.scene-list-item';
        }
        $(el).addClass('disable');
        if (App.timer !== null) {
            clearTimeout(App.timer);
        }
        App.timer = setTimeout(function () {
            $(el).removeClass('disable');
        }, 500);
    },

    //alert has three basic types: SUCCESS, FAILURE (error), CONFIRMATION (confirm), ALERT, INFO for basic info tab
    alert: function (type, title, subtext,errorInfo) {
      if(App.hasOwnProperty('Alert')){
          App.Alert.renderSnackbar(type, title, subtext);
      }
    },

    confirmModal: function (confirmMessage = '') {
      $('#confirm-modal').modal({
          show: true,
          backdrop: true,
          keyboard: false,
      });
      $('.modal').addClass('modal-show');
      $('.modal-confirm-message').html(confirmMessage);
    },

    hide: function (element) {
        $(element).css('display', 'none');
    },

    show: function (element) {
        $(element).css('display', 'block');
    },
    //global edit func for changing list item names to editable text and saving the new name to the corresponding object
    editName: function (type, that) {
        if ($(that).hasClass('editable-text')) {
            return;
        }
    },

    //User constructor
    User: function (un, role, sites, id) {
        this.username = un;
        this.role = role;
        this.sites = sites;
        this.id = id;
        bugsnag.user = {
            id: id,
            name: un,
            email: un
        };
    },

    siteLogin: function (siteID, sites = []) {
        User.mySites = sites;
        store.dispatch(siteLoad({ id: siteID }));
        if (User.myUser.role === 'admin' || User.myUser.role === 'company' || User.myUser.role === 'installer') {
            $('#terminal-icon').show();
        } else {
            $('#terminal-icon').hide();
        }
    },

    setActiveLocationIndex(locationId){
        const index = Locations.getIndex(locationId);
        if(index >= 0){
            App.activeLocationIndex = index;
            // App.DeviceContainer.setStates({location_id:locationId});
        }
    },
    clearOldActiveState(location){
        location.newDevices = {};
        location.newOutputs = {};
        location.externalActionsToSave = '';
        location.templateActions = [];
        location.buildingScenes = {};
    },
    async setActiveLocation(location){
        if(App.ActiveLocation !== false){
            this.clearOldActiveState(App.ActiveLocation);
        }
        if(App.hasOwnProperty('LocationSettingsMenu') && App.LocationSettingsMenu.state.changesMade === true){
            App.LocationSettingsMenu.setState({setNewActive:location, setAfterApply:true});
            App.LocationSettingsMenu.openDialog();
            return;
        }

        App.ActiveLocation = location;
        App.SiteDashboardContainer.setActiveLocation(location);
        App.ActiveLocation.calcFeaturesApplied();
        //calculate features applied in parent if it exists and children if they exist so parent/child feature configuration
        if(location.isRoot === false){//can work based on state of parent/child locations if those locations have not been activated
            if(location.parent.isRoot === false){
                location.parent.calcFeaturesApplied();
            }
            if('children' in location && Object.keys(location.children).length > 0){
                for(let child in location.children){
                    location.children[child].calcFeaturesApplied();
                }
            }
        }

        App.ActiveLocation.updateUIComponents();

        //Uncomment this if you are feeling lazy and want this for debugging
        // window.site = App.Site;
        // window.app = App;
        // window.activeLocation = App.ActiveLocation;
        // window.scenes = Scenes;
        // window.devices = Devices;
    },

    setLocalIP: function (IP) {
        App.localIP = [];
        App.localIP.push(IP);
        JSON.stringify(App.localIP); //if we are going to store it locally, which we arent right now
        App.Site.init(App.localIP[0]);
    },

    isLocalStorage: function () {
        let storage = window.localStorage;

        if (storage.length > 0) {
            try {
                //Check for token
                if (storage.getItem('amatis_token') !== null) {
                    User.apiToken = storage.getItem('amatis_token');
                } else {
                    return false;
                }

                //Check for user
                if (storage.getItem('amatis_user') !== null) {
                    User.myUser = JSON.parse(storage.getItem('amatis_user'));
                }

                //Check for a siteid, if found load that site directly
                if (storage.getItem('amatis_site_id') !== null) {
                    App.activeSiteID = storage.getItem('amatis_site_id');
                }

                return true;
            } catch (e) {
                console.log(e); //debug
                console.log('FAILED to get site info from local');
                return false;
            }
        } else {
            return false;
        }
    },

    storeLocal: function () {
        window.localStorage.setItem('localLocation', App.allLocationsForStorage);
        // window.localStorage.setItem('localScene', allScenesForStorage);

        //log("saved:  "+window.localStorage.getItem("localLocation"));
        //log("saved:  "+window.localStorage.getItem("localScene"));
    },
    //Prints a red background error message to the login-status on login screen
    displayError: function (error, code, unhideCode) {
        $('#login-status-error').hide().html('<p class="error">' + error + '<div id=\'error-code\' class=\'error\'>ERROR : ' + code + '</div></p>').fadeIn('fast');
        if (unhideCode)
            $('#error-code').show();
        else
            $('#error-code').hide();
    },
    //Prints a green background message to the onscreen-status box (where everyone can see it)
    displayErrorOnScreen: function (error, code, unhideCode) {
        $('#on-screen-status-error>.error').html(error + '<div id=\'error-code\' class=\'error hidden\'>ERROR : ' + code + '</div>').fadeIn('fast');
        if (unhideCode)
            $('#error-code').show();
        else
            $('#error-code').hide();
    },

    refreshToken: function (refresh_token) {
        let myURL = Communication.getBaseURL + 'user/refreshToken?refresh_token=' + refresh_token;
        let myHeader = 'Bearer ' + refresh_token;

        $.ajax({
            type: 'GET',
            dataType: 'json',
            headers: {
                'Authorization': myHeader
            },
            timeout: 10000,
            url: myURL,
            async: false,
            success: function (result) {
                $.cookie('token', decodeURI(result.token), {
                    expires: (result.expires_in) / (60 * 60 * 24)
                });
            },
            error: function (e) {
                App.displayError(e.status + ':' + e.statusText);
            },
            complete: function (jqXHR, status) {
                let i = $.xhrPool.indexOf(jqXHR); //  get index for current connection completed
                if (i > -1) $.xhrPool.splice(i, 1); //  removes from list by index
                $('#action-overlay').fadeOut('fast');

            }
        });
    },
    //SoftReboot Ambr
    softRebootAMBR: function () {
        let myUrl = 'https://' + Communication.HAL_BASE_URL + '/am-config/ambrSoftReboot.php?siteID=' + App.activeSiteID;

        $.ajax({ //get data request for applicable device
            type: 'GET',
            cache: false,
            dataType: 'text',
            url: myUrl,
            success: function (response) {
                App.alert('success', 'AMBR was succesfully rebooted');
            },
            error: function (request, status, error) {
                Error('upload', request.status, 'No network connection. Please verify that you are connected to the internet.');
            }
        });
    },

    tm: function (unix_tm) {
        let dt = new Date(unix_tm * 1000);
        let dtStr = dt.toString();
        let indexOfGMT = dtStr.indexOf('GMT');
        let badDtStr = dtStr.slice(indexOfGMT, (indexOfGMT + 8));
        dtStr = dtStr.replace(badDtStr, '');
        return (dtStr);
    },

    JSClock: function () {
        let time = new Date();
        let hour = time.getHours();
        let minute = time.getMinutes();
        let second = time.getSeconds();
        let temp = '' + ((hour > 12) ? hour - 12 : hour);
        if (hour === 0)
            temp = '12';
        temp += ((minute < 10) ? ':0' : ':') + minute;
        temp += ((second < 10) ? ':0' : ':') + second;
        temp += (hour >= 12) ? ' P.M.' : ' A.M.';
        return temp;
    }
};

export default App;
