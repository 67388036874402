// import $ from 'jquery';
import App from 'app/app.js';
import Devices from 'api/amatis/site/devices/services/devices.js';

import Amatis from 'api/amatis/amatis.js';
// import {remove_duplicates, decToHex, hexToDec} from 'classes/methods.js';
import {MyTemplates, StandardTemplates} from 'classes/templates';
import {Template} from 'app/templates.js';
// import Ambrs from 'api/amatis/site/ambr/services/ambrs.js';
import {Locations} from './services/locations.js';
import User from 'classes/users.js';
import {null_location_id} from 'classes/methods.js';
import Terminal from '../../utilities/terminal.js';
import { Scenes } from '../scenes/services/scenes.js';

import { buildLocationTree } from 'redux/activeSite';
import store from 'store';
import Validator from '../validator/';
import calculatorVar1 from 'components/app/menu/scenes/scene-config-modal/calculator';
import { reverseChansZonesFadeLevel } from 'components/app/menu/scenes/scene-config-modal/calculators/CalculatorFuntions';

class Location extends Amatis{
    constructor(info) {
        super(info);
        this.amatisType = 'locations';
        this._path = new Set();
        this.scenesHere = [];
        this.hasMuteDevice = false;
        this.children = {}; //Populated by findAllChildren()
        this.linksTablesLoaded = false;
        //this.scenes = {};
        this.devicesToSave = new Set();
        this.scenesToDelete = new Set();
        this.masterML = false;
        this.externalActionsToSave = '';
        this.setpointsToWrite = {};
        this.buildingScenes = {};
        this.templateActions = {};
        this.newSw8Inputs = {};
        this.activeTemplateScenes = {};//reference scene name to linkID
        this.templateDevices = {};
        this.hasPHD = false;
        this.newDevices = {};
        this.canUmbrellaConfig = false;
        this.canUmbrellaOcc = false;
        this.canUmbrellaDlh = false;
        this.canUmbrellaSwitch = false;
        this.tempMasterOcc = false;
        this.dualTechSw8 = false;
        this.newSwitches = {};
        this.newOutputs = {};
        this.lightLevelChange = 0;
        this.lightLevel =false;
        this.lightLevelStamp = 0;
        this.phdOutputAdded = false;
        this.sw8InputAdded = false;
        this.featuresAppliedToNewDevices = false;
        this.devicesByType ={
            'switch': new Set(),
            'dimmer': new Set(),
            'sensor': new Set(),
            'multi_channel': new Set(),
            'other': new Set()
        };
        this.multiChannelDevices = new Set();
        this.assocScenes = []; //Associated scene indeces with this location
        this.assocSensors = []; //Associated sensor indeces with this location
        this.assocDimmers = [];
        this.assocSwitches = [];
        this.assocDevices = [];
        this.otherDevices = [];
        this.liveData = [];
        this.url = '/locations/';
        this.parent = false;
        this.softDeleted = false;
        this.open = false;
        //Info about the stuff in this location, and its state of being
        //Beware that this shit might not always be up to date...
        this.doingCustomTemplate = false;
        this.featuresToApply = false;
        this.occTimestampsByDname = {};
        this.reactComp = false;
        this.masterSensor = false;
        this.averageOccupancy = false;
        this.occupancyHistory = false;
        this.lightsOn = false;
        this._lightLevel = false;
        this.occupied = false;
        this.hasOccupancyControl = false;
        this.loadShedOn = false;
        this.locked = true;
        this.errorFlag = false;
        this.isRoot = false;
        this.active = false;
        this.mode = false;
        this._hasMute = false;
        this.brand_new_local = (typeof info.brand_new_local === 'undefined') ? false : info.brand_new_local;
        this.network_id = null;
        this.hasXADevices = false;
        this.featureSettings = {
            'dimming': {},
            'on-off': {},
            'day-occ': {},
            'day-vac':{},
            'night-occ': {},
            'night-vac':{},
            'dll': {},
            'slider-control':{},
            'individual-control': {},
            'responsive-dimming': {},
        };
        this.whiteList = new Set([
            'name',
            'room_type',
            'description',
            'display_index',
            'is_deleted',
        ]);
        this.featureSettings = JSON.parse(JSON.stringify(Locations.featureSettingDefaults));
        //New Stuff
        this.nameIsEditable = (typeof info.nameIsEditable === 'undefined') ? false : info.nameIsEditable;

        //Methods
        this.removeAssocDevice = Locations.RemoveAssocDevice;
        this.removeAssocScene = Locations.RemoveAssocScene;
        this.updateUIComponents = this.updateUIComponents.bind(this);
        this.finishLoad = this.finishLoad.bind(this);
        this.patchResourceV3 = this.patchResourceV3.bind(this);
        //Push this thing to the list(s) that holds it
        App.Site.locations[this.ID] = this;
        Locations.list = [...Locations.list];
        const indexLocation = Locations.list.findIndex(item => item.ID === this.ID);
        if (indexLocation !== -1) Locations.list.splice(indexLocation, 1);
        Locations.list.push(this);
        this.validator = new Validator(this);
    }
    async finishLoad(){
        App.locationsLoaded = true;
        store.dispatch(buildLocationTree(App));
        App.setActiveLocation(App.Site.root);
        this.updateUIComponents();
        Terminal.loadInfo();
        if(App.Site.mqttInitSuccess === false){
            App.Site.init();
            //getting commented out so we don't call deviceData from client everytime frontend loads
            // Ambrs.loadInfo();
            let triggerLinkIDs = await App.Site.get(false, false, 'devices/deviceData', 'v2');
            App.Site.triggerLinkIDLookup = triggerLinkIDs;
        }
    }
    getLocationRefreshUIInterval(){
        let updateUIComponentsInterval = 300;
        if (!App.locationsLoaded) return updateUIComponentsInterval;

        const devicesCount = Object.keys(App.Site.devices).length;
        if(devicesCount > 3000){
            updateUIComponentsInterval = 2000;
        } else if (devicesCount > 1500) {
            updateUIComponentsInterval = 1200;
        } else if (devicesCount > 600) {
            updateUIComponentsInterval = 800;
        }
        return updateUIComponentsInterval;
    }
    updateUIComponents(){
        if(App.updateUIComponentsIsBusy('Location', this.updateUIComponents, this.getLocationRefreshUIInterval())){
            return;
        }

        if (App.hasOwnProperty('DashContainer')) {
            App.DashContainer.forceUpdate();
        }

        if(App.hasOwnProperty('LocationContainer')){
            App.LocationContainer.setLocationList(Locations.list, true);
        }
        if(App.hasOwnProperty('PageContent')){
            App.PageContent.setLocationList(Locations.list, true);
        }
        if(App.hasOwnProperty('LocationSettingsMenu')){
            App.LocationSettingsMenu.updateLocationSettings(App.ActiveLocation.room_type);
        }
    }
    //recursively search through child locations to check for occ or daylight config
    findConfigInChildren(){
        let config = {
            hasOcc:false,
            hasDLH:false,
        }
        let findConfig = function(location){
            for(let id in location.children){
                if(location.children.hasOwnProperty(id)){
                    let child = location.children[id];
                    if(child.featureSettings.dll.applied === true){
                        config.hasDLH = true;
                    }
                    if(child.featureSettings['day-occ'].applied === true || child.featureSettings['night-occ'].applied === true){
                        config.hasOcc = true;
                    }
                    if(Object.keys(child.children).length > 0){
                        findConfig(child);
                    }
                }
            }
        }
        findConfig(this);
        return config;
    }
    //search through parent locations to check for occ or daylight config
    findConfigInParent(){
        let config = {
            hasOcc:false,
            hasDLH:false,
            hasSwitch:false,
            hasSlider: false,
            parent:false,
        }
        let findConfig = function(location){
            if(location.parent !== false && location.isRoot === false && location.parent.id !== App.Site.root.id){
                let parent = location.parent;
                if(parent.featureSettings['dll'].applied === true){
                    config.hasDLH = true;
                    config.parent = parent;
                }
                if(parent.featureSettings['day-occ'].applied === true || parent.featureSettings['night-occ'].applied === true){
                    config.hasOcc = true;
                    config['night-occ'] = parent.featureSettings['night-occ'].applied;
                    config['day-occ'] = parent.featureSettings['day-occ'].applied;
                    config.parent = parent;
                }
                if(parent.featureSettings['responsive-dimming'].applied === true){
                    config.hasSwitch = true;
                    config.parent = parent;
                }
                if(parent.featureSettings['slider-control'].applied === true){
                    config.hasSlider = true;
                    config.parent = parent;
                }
                findConfig(parent);
            }
        }
        findConfig(this);
        return config;
    }
    //check against state variables set based on devices in the parent and children to see whether certain
    checkForParentCapabilities(feature){
        let result = true;
        if(this.isParent){
            if(feature === 'day-occ' || feature === 'night-occ' ){
                if(this.canUmbrellaOcc === true){//can apply occupancy across all children. children have sensors
                    result = true;
                }else{
                    App.alert('warning','Feature not applied:', 'Add sensors to this location or any child of this location to allow application of occupancy control.');
                    result = false;
                }
            }
            if(feature === 'responsive-dimming'){
                if(this.canUmbrellaSwitch === true){//can apply switch control aka "responsive dimming" across all children due to switch being in parent
                    result = true;
                }else{
                    App.alert('warning', 'Feature not applied: ','Add switch(s) to this location directly to allow application of responsive dimming.');
                }
            }
            if(feature === 'dll'){
                if(this.canUmbrellaDlh === true){//can apply dlh across all children due sensor being in this location as a parent
                    result = true;
                }else{
                    App.alert('warning','Feature not applied: ','Add sensors(s) to this location directly to allow application of daylight harvesting.');
                    result = false;
                }
            }
        }
        return result;
    }
    applyCustomTemplate(templateName, applyingTemplate=false){
        console.log("applyingTemplate ", applyingTemplate)
        let featuresToApply=[];
        let template = {};
        if(App.Site.customTemplates.hasOwnProperty(templateName)){

            template = App.Site.customTemplates[templateName];
            //only add features to apply if feature is not applied so we dont toggle it off
            for(let feature in template){
                if(template[feature].applied === true && this.featureSettings[feature].applied === false){
                    featuresToApply.push(feature);
                }else if(this.featureSettings[feature]?.applied === true && applyingTemplate){//if user press button to apply template and the feature is applied compare with the feature applied in the template and mark any other feature in false
                    if(template[feature].applied === true){
                        featuresToApply.push(feature);
                    }
                    this.featureSettings[feature].applied = false;
                }
            }
        }else if(StandardTemplates.hasOwnProperty(templateName)){
           featuresToApply = StandardTemplates[templateName].features;
           template = JSON.parse(JSON.stringify(Locations.featureSettingDefaults));
           //create the object infrastructure we need to use applyCustomTemplate settings: settings if it has them, false otherwise (defined in classes/templates StandardTemplates)
           for(let feature in featuresToApply){
                //remove the feature from features to apply if it is already applied so we dont toggle it off
                if(this.featureSettings[featuresToApply[feature]]?.applied){
                    featuresToApply.splice(feature, 1);
                }
                feature = featuresToApply[feature];
                if(StandardTemplates[templateName].hasOwnProperty('feature_settings') && StandardTemplates[templateName]['feature_settings'].hasOwnProperty(feature) && template[feature].setting !== false){
                    for(let setting in StandardTemplates[templateName]['feature_settings'][feature]){
                        if(setting in template[feature].settings){
                            template[feature].settings[setting].value = StandardTemplates[templateName]['feature_settings'][feature][setting];
                        }
                    }
                }
           }
        }
        this.featuresToApply = featuresToApply;
        this.activeTemplate = template;
        this.doingCustomTemplate = true;
        this.toggleFeatures(featuresToApply, applyingTemplate);
    }
    //once the templates are applied we can go through and edit the settings accordingly
    applyCustomTemplateSettings(featuresToApply){
        Devices.actionsParsing = true;//set this so that we dont render sync things for everything device that gets
        //settings edited during this process (unsaved = true)
        let feature, activeFeature, activeSetting,actions, scenes, lookFor,scene,activeScene=false;
        for(let i in featuresToApply){
            feature = featuresToApply[i];
            activeFeature = this.activeTemplate[feature];
            if(activeFeature != null && activeFeature.settings !== false){
                for(let setting in activeFeature.settings){
                    activeSetting = activeFeature.settings[setting];
                    //if this is a property we must set in device data we will have to write the setpoint
                    if(activeSetting.hasOwnProperty('dname')){//AA:we may want to actually filter devices somehow not just all for the location but isnce this is location settings...its fine, just thinking for future of applying to groups/parent locs etc
                        this.editFeatureSettings(activeSetting);
                    }else{//otherwise we will edit the scene most likely for occ/vacant light levels
                    //get the scene so we can loop over its actions and edit them
                        scenes =  this.buildingScenes;
                        lookFor = activeSetting.sceneName;
                        for(let id in scenes){
                            scene = scenes[id];
                            // eslint-disable-next-line
                            if(scene.description == lookFor){
                                activeScene = scene;
                                break;
                            }
                        }
                        actions = activeScene.externalActions;
                        this.editFeatureSettings(activeSetting, actions);
                    }
                    this.featureSettings[feature].settings[setting] = activeSetting;
                }
            }
        }
        //wait a second to set the state incase things are still applying
        setTimeout(()=>{this.reactComp.setStateAfterCustomTemplateApply();
            this.featuresToApply = false;
            this.activeTemplate = false;
            this.reactComp = false;
            this.doingCustomTemplate = false;
            Devices.actionsParsing = false;
        },300);
    }
    addSetpointsToWrite(device, value, dname){
        let partType = device.partType, setpointObj;
        if(partType === 'PHD Output'){
            let outputStr = device.output.toString();
            let addToDname = (device.type === 'Channel' ? 'ch' +(device.output < 10 ? '0'+outputStr : outputStr ) : 'zn-'+outputStr);
            dname = `${dname} ${addToDname}`;
            device = device.parent;
        }
        //TODO: Add generic mcd handling here. Formatting function should be in class
        setpointObj = {
            'ip':device.ip_address,
            'value':value,
            'dname':dname
        }
        if('dnameToIndex' in device && dname in device.dnameToIndex){
            let idx = device.dnameToIndex[dname];
            setpointObj.idx = idx;
        }
        let reference = device.ip_address+dname.replace(/\s+/g, '_');
        this.setpointsToWrite[reference] = setpointObj;
        if(!device.isTalking()){//if device is not talking we will save the setpoint to write it later when the device is up
            if(!App.Site.offlineSetpointsToWrite.hasOwnProperty(device.id)){
                App.Site.offlineSetpointsToWrite[device.id] = {};
            }
            App.Site.offlineSetpointsToWrite[device.id][reference] = setpointObj;
        }
    }
    handleSettingValueBoundaires(activeSetting){
        let value = activeSetting.value;
        let setTo = value;
        for(let limit in activeSetting.limit){
            limit = activeSetting.limit[limit];
            let limitSettingVal = this.featureSettings[limit.feature].settings[limit.setting].value;
            //check current setting value against limit of dependent setting value
            switch(limit.mustBe){
                case 'greaterThan'://if current value must be greater than limit but is not, set it higher
                    if(value <= limitSettingVal){
                        setTo = limitSettingVal + activeSetting.step;//set one step higher than limit value
                    }
                    break;
                case 'lessThan'://if current value must be less than limit but is not, set it lower
                    if(value >= limitSettingVal){
                        setTo = limitSettingVal - activeSetting.step;//set one step higher than limit value
                    }
                    break;
                default:
                    setTo = value;
                    break;
            }
        }
        if(setTo > activeSetting.max){
            setTo = activeSetting.max;
        }else if(setTo < activeSetting.min){
            setTo = activeSetting.min;
        }
        return setTo;
    }
    calcSettingVar1(activeSetting, device, action, customFade){
        let var1 = action.var_1;
        let fadeRate = false;
        let value = parseInt(activeSetting.value, 10);
        let identifier;

        //fade rate on, we always have light level 100%
        if(activeSetting.name === 'Fade Rate On'){
            fadeRate = value;
            value = 100;
        }else if(activeSetting.name === 'Fade Rate Off'){//fade rate off, light level always 0%
            fadeRate = value;
            value = 0;
        }
        else if(customFade !== false){
            fadeRate = parseInt(activeSetting['fade-rate'].value, 10);
        }

        if(device.isMultiChannelOutputDevice){
            let reverse_var1 = reverseChansZonesFadeLevel(var1);
            let returnObj = calculatorVar1(reverse_var1.zones, reverse_var1.channels, value, reverse_var1.fade, true)//The last value is a flag to return identifier value
            identifier = returnObj.identifier
            var1 = returnObj.var1
        }else{
            identifier =  device.ip_address.substr(-4)+'-'+action.link_id.toString();
            if(fadeRate === false){
                fadeRate = parseInt(var1.toString().substr(-3));//calculate faderate
            }
            if (fadeRate < 10) {
                fadeRate = '00' + fadeRate;
            } else if (fadeRate < 100 && fadeRate >= 10) {
                fadeRate = '0' + fadeRate;
            }
            var1 = parseInt(value + fadeRate, 10);
        }
        return {var1, identifier};
    }

    editFeatureSettings(activeSetting, actions=false, customFade=false, editingParentConfigInChild=false, newDevices=false){
        let featureDevices = this.featureDevices;
        activeSetting.value = this.handleSettingValueBoundaires(activeSetting);
        if(actions === false){//if there are not actions we are writing straight to device data setpoints
            //Some settings dont have a dname. These arent really settings. Protect here for it
            if (activeSetting.hasOwnProperty('dname') === false){
                return;
            }
            for(let id in featureDevices){//each device that meats the classification requirement we will want to set the new value for
                let dname = activeSetting.dname;
                let setTo = activeSetting.value;
                let device = featureDevices[id];
                if(device.isMultiChannelOutput || device.partType === 'PHD Output' || device.partType === 'SW8 input' ){
                    device = device.parent;
                }
                if(device.classification === 'switch' && device.dualTech === false){
                    continue;
                }
                if(device.type === 'DRIVER' && dname === 'Min Out' && setTo < 16){
                    setTo = 16;
                }
                this.addSetpointsToWrite(device, setTo, dname);
            }
        }else{//if there are actions, we are editing action values
            for(let id in actions){
                let activeAct = actions[id];
                let activeDevice = App.Site.devices[App.Site.deviceLookup[activeAct.ip_address]];
                //dont edit settings for triggers or switch functions, also...dont do it for psm because their settings are static on/off
                if(activeAct.name === 'coap_send_action(linkID)' || activeAct.name === 'MAC Action(0xLinkID, 0xMAC)' || activeDevice.partType === 'AM-PSM') continue;
                //if we are editing the feature settings for config from parent in a child, we only want to edit actions for devices in the child
                if(editingParentConfigInChild === true && activeDevice.location.id !== this.id && activeDevice.isMultiChannelOutputDevice === false){
                    continue;
                //same with phd except we check that the location outputs match the var1 instead of checking against location id
                }else if(activeDevice.isMultiChannelOutputDevice === true && editingParentConfigInChild === true && (newDevices || !Devices.phd.compareVar1ToLocOutputs(activeAct.var_1, activeDevice.ip_address, this, activeAct.name))){
                    continue;
                }
                let var1CalcObj = this.calcSettingVar1(activeSetting, activeDevice, activeAct, customFade);
                activeAct.var_1 = var1CalcObj.var1;
                //SEB: This fixes the issue with table_row being overwritten -> but we need to figure out the right way to do this and call the editRow function
                //Mark the row as edited by this user
                activeAct.saved = User.myUser.id;
                this.templateActions[var1CalcObj.identifier] = activeAct;//insert the action to be pushed to the cloud and mark the device as unsaved
                activeDevice.unsaved = true;
            }
        }
    }
    assignAssocActionDataToFeatures(scene, lightLevel, fadeRate, apply=true){
        let location = this;
        let feature, setting;
        let description = scene.description;
        switch(description){
            case 'occupied_nighttime':
                feature = 'night-occ';
                setting = 'occ-light-level';
                break;
            case 'unoccupied_nighttime':
                feature = 'night-occ';
                setting = 'vac-light-level';
                break;
            case 'occupied':
                feature = 'day-occ';
                setting = 'occ-light-level';
                break;
            case 'unoccupied':
                feature = 'day-occ';
                setting = 'vac-light-level';
                break;
            case 'on':
                feature = 'responsive-dimming';
                setting ='fade-on';
                break;
            case 'off':
                feature = 'responsive-dimming';
                setting ='fade-off';
                break;
            case 'nh_grace':
                feature = 'nh-grace';
                setting = 'light-level';
                break;
            case 'ah_grace':
                feature = 'ah-grace';
                setting = 'light-level';
                break;
            default:
                return;
        }
        let locationFeatureSetting = location.featureSettings[feature].settings[setting];
        location.featureSettings[feature].applied = apply;
        if(locationFeatureSetting.hasOwnProperty('fade-rate')){
            locationFeatureSetting.value = lightLevel;
            locationFeatureSetting['fade-rate'].value = fadeRate;
        }else{
            locationFeatureSetting.value = fadeRate;
        }
    }

    insertActionToSave(actionObj){
        let actionString = '';
        if(this.externalActionsToSave.length > 0){
            actionString = actionString + ',';
        }
        actionString = actionString + JSON.stringify(actionObj);
        this.externalActionsToSave = this.externalActionsToSave + actionString;
    }
    //main call to apply or disable a location feature by name, will toggle based on current setting
    async toggleFeatures(features, applyingTemplate, devices = this.devices){
        /* jshint ignore:start */
        if(this.isParent){//remove non parental switches
            for(let id in devices){
                if(devices[id].is_switch && devices[id].location.id !== this.id){
                    delete devices[id];
                }
            }
        }
        let doToggle = async (features, multiChannelDevices=false) => {
            this.templateDevices = await Devices.checkInternalActions(devices);
            if(Object.keys(this.parentPHDs).length > 0){
                Devices.checkInternalActions(this.parentPHDs);
            }
            if(Object.keys(this.parentSW8s).length > 0){
                Devices.checkInternalActions(this.parentSW8s);
            }
            /* for(let featureAll in this.featureSettings){
                if(this.featureSettings[featureAll].applied){
                    this.disablefeature(this.featureSettings[featureAll].name);
                    this.applyFeatureDataPoints(this.featureSettings[featureAll].name, 'disable');
                    this.disableActiveDependentFeatures(this.featureSettings[featureAll].name);
                }
            } */
            for (let feature in features){
                feature = features[feature];
                //loop over feature names and check if feature is custom then set custom, send to applyFeature
                //may want to add this same check specifically for multiChannelDevices here
                if(this.featureSettings.hasOwnProperty(feature)){
                    if(this.featureSettings[feature].applied === true && !applyingTemplate){//if applied disable the feature
                        this.disablefeature(feature);
                        this.applyFeatureDataPoints(feature, 'disable');
                        this.disableActiveDependentFeatures(feature);
                    }else{//otherwise apply it
                        this.applyFeature(feature, this.templateDevices, false, true);
                        this.applyFeatureDataPoints(feature);
                    }
                }
            //now that all the scenes are parsed and internal actions made for each, we save the scenes and the internal actions
               //Template.finalize(this.buildingScenes, this.devicesToSave);
               //AA:commented out because we are now switching over to only applying the template fully when we press the check
               //uncomment to make templates apply immediately
            }
            if(this.doingCustomTemplate === true){
                this.applyCustomTemplateSettings(this.featuresToApply, this.reactComp);
            }
        }
        /* jshint ignore:end */
        //if(!Locations.checkForAndHandleMultiChannelDevices(features, doToggle, this)){
        doToggle(features);
        //}
    }

    //configure newly added devices into already configured features for this location
    async addDevicesToFeaturesApplied(devices=this.newDevices, phdOutputs=this.newOutputs, sw8Inputs=this.newSw8Inputs, parentConfigInChild=false, doInChild=false){
        if (App.hasOwnProperty('ActionOverlay')) {
            App.ActionOverlay.show();
        }
        let scenes = this.parentalScenes;
        let features = [];
        let outputsAdded = (Object.keys(phdOutputs).length > 0);
        this.buildingScenes = {};
        //figure out what features we need to apply these new devices to
        for(let feature in this.featureSettings){
            if(this.featureSettings[feature].applied === true){
                features.push(feature);
            }
        }

        let location = (parentConfigInChild === false ? this : parentConfigInChild);//otherwise well add them all
        let insertIntoParent = (parentConfigInChild === false ? false : this);//otherwise well add them all
        //set up our scenes for the build process so that we configure to existing scenes but only configure with the new devices
        for(let id in scenes){//using building scenes also allows us to configure the feature settings directly to these new devices
            let activeLinkID = scenes[id].link_id;
            this.activeTemplateScenes[scenes[id].description] = activeLinkID;
            //reconfigure all phd actions for parent phd associated with new outputs added if we added new outputs
            if(outputsAdded){
                Devices.insertMcdOutputsIntoLocScene(scenes[id], location, insertIntoParent);
            }
        }

        let existingDevices = this.devices;
        //add in switches so that we can correctly configure MAC action functions
        for(let id in existingDevices){
            let activeDevice = existingDevices[id];
            //if the switch was not one of the devices newly added
            //switches must be directly in the location we are configuring (this one)
            if(activeDevice.classification === "switch" && this.ID === activeDevice.location.ID){
                if(devices.hasOwnProperty(id)){//if the switch is new, put it in the new switch list
                    this.newSwitches[id] = devices[id];
                }else{//otherwise add it to normal device object
                    devices[id] = activeDevice;
                }
            }
        }
        for(let id in sw8Inputs){
            devices[id] = sw8Inputs[id];
        }
        if(this.onlyNewOutputs(phdOutputs) === true){
            this.newOutputs = phdOutputs;
        }
        //adding new mac action functions will use template devices, allowing us to configure them only for these newly added devices
        this.templateDevices = devices;
        for(let i in features){//apply features
            this.applyFeatureDataPoints(features[i]);
            await this.applyFeature(features[i], devices, true);
        }
        let locToEdit = this;
        let configInChild = (parentConfigInChild !== false);
        for(let i in features){//apply currently configured settings to the actions for the devices we just added inot the feature configuration
            if(parentConfigInChild !== false && (features[i] === 'day-occ' || features[i] === 'night-occ')){
                locToEdit = parentConfigInChild;
            }else{
                locToEdit = this;
            }
            for(let setting in locToEdit.featureSettings[features[i]].settings){
                let activeSetting = locToEdit.featureSettings[features[i]].settings[setting];
                if(activeSetting.hasOwnProperty('dname')){
                    this.editFeatureSettings(activeSetting);
                }else{
                    let lookFor = activeSetting.sceneName;
                    let activeScene = Scenes.getSceneByDescription(this.buildingScenes, lookFor);
                    if(activeScene !== false){
                        await locToEdit.editFeatureSettings(activeSetting, activeScene.externalActions, false, configInChild, true);
                    }
                }
            }
        }
        await Template.finalize(this);
        this.featuresAppliedToNewDevices = true;
        this.newDevices = {};
        this.newSwitches = {};
        this.newSw8Inputs = {};
        this.newOutputs = {};
        this.phdOutputAdded = false;
        if(doInChild === true){
            parentConfigInChild.addDevicesToFeaturesApplied();
        }
        else if(parentConfigInChild){
            parentConfigInChild.featuresAppliedToNewDevices = true;
            parentConfigInChild.newDevices = {};
            parentConfigInChild.newOutputs = {};
            this.phdOutputAdded = false;
            if (App.hasOwnProperty('ActionOverlay')) {
                App.ActionOverlay.hide();
            }
        }else{
            if (App.hasOwnProperty('ActionOverlay')) {
                App.ActionOverlay.hide();
            }
        }
    }

    //contains functionality of what used to be Template.make, summed up into a more concise chunk that just iterates over
    //the devices that the template will apply it and parse the scenes out of the template applicable to each
    /* jshint ignore:start */
    async applyFeature(feature, devices, insertingNewDevices=false, insertingNewPHD = false){
        let template, occ=false, dll=false, dualTech = false;
        if(!this.checkForParentCapabilities(feature) || !(feature in MyTemplates)){
            return;
        }
        //make sure the devices are all talking and have their keys, if they are talking but dont have keys we'll get them
        if(this.featureSettings.hasOwnProperty(feature) && this.featureSettings[feature].applied === false){
            if(this.featureSettings[feature].active === false){//set settings back to defaults incase the feature was applied and had settings within the session
                this.featureSettings[feature] = JSON.parse(JSON.stringify(Locations.featureSettingDefaults[feature]));
            }
            this.featureSettings[feature].active = !this.featureSettings[feature].active;
            this.featureSettings[feature].applied = true;
        }
        else if(!insertingNewDevices){
            console.log('Feature already enabled or does not exist.', feature);
            return;
        }

        template = MyTemplates[feature];
            //figure out if were setting occ or dll features so we know how to handle role setting
        if(feature === 'day-occ' || feature === 'night-occ' || feature === 'ah-grace' || feature === 'nh-grace'){
            occ = true;
            let sw8Inputs = this.parentalSw8Inputs;
            if(Object.keys(sw8Inputs).length >= 1){
                for(let id in sw8Inputs){
                    let active = sw8Inputs[id];
                    if(parseInt(active.output) === 1){
                        let activeIsDualTech = true;
                        for(let id in active.parent.outputs){
                            let output = active.parent.outputs[id];
                            //if any output from this switch 8 that is not output 1 is tagged in a location, this sw8 cannot be dual tech.
                            if(parseInt(output.output) !== 1 && (output.location !== false && 'isRoot' in output.location && output.location.isRoot === false)){
                                activeIsDualTech = false;
                            }
                        }
                        if(activeIsDualTech === true){
                            dualTech = true;
                        }
                        sw8Inputs[id].parent.dualTech = activeIsDualTech;
                    }
                }
            }
        }
        else if(feature === 'dll'){
            dll = true;
        }

        let multiChannelOutputs = {...this.phdOutputs, ...this.MCDOutputs};
        let locationHasMCDOutputs = Object.keys(multiChannelOutputs).length > 0;
        let totalDevices = Object.keys(devices).length, devicesNotApplied = [], total, title ='', log = ''; //'Devices not applicable to '+feature+':';
        if(template.scenes.length > 0){
            if(occ || dll){
                this.configureLocationSensorRoles(occ, dll, insertingNewDevices);
            }
            //if there are multi channel devices well handle getting the necessary input for all of these and then go through and build scenes
            //parse scenes per device
            for(let id in devices){
                if(!Template.parseScenes(template.scenes, devices[id], false, this)){
                    devicesNotApplied.push(id);
                }
            }
            if(dualTech === true){
                let sw8InputsResponsive = (insertingNewDevices ? this.newSw8Inputs: this.parentalSw8Inputs);
                for(let id in sw8InputsResponsive){
                    let active = sw8InputsResponsive[id];
                    Template.parseScenes(template.scenes, active, false, this);
                }
            }
            if(feature === 'responsive-dimming'){
                let sw8InputsResponsive = (insertingNewDevices ? this.newSw8Inputs: this.parentalSw8Inputs);
                if(Object.keys(sw8InputsResponsive).length >= 2){
                    for(let id in sw8InputsResponsive){
                        let active = sw8InputsResponsive[id];
                        Template.parseScenes(template.scenes, active, false, this);
                    }
                }
            }

            //handle multi channel devices
            if(locationHasMCDOutputs !== false && (insertingNewDevices || this.onlyNewOutputs() === true || insertingNewPHD)){
                //get outputs by parent by loc id becase if were doing config to parent with many children
                //we create actions specific to the outputs within each
                let multiChannelDevices = Devices.orgMcdOutputsByParentByLoc(multiChannelOutputs);
                for(let id in multiChannelDevices){
                    totalDevices ++;//increase total device per parent multi channel device
                    if(!Template.parseScenes(template.scenes, App.Site.devices[id], multiChannelDevices[id], this)){
                        devicesNotApplied.push(id);
                    }
                }
            }
            this.tempMasterOcc = false;
            if(!insertingNewDevices){
                total = devicesNotApplied.length;
                if(total > 0){
                    for(let i = 0; i < total; i++){
                        log += App.Site.devices[devicesNotApplied[i]].name + '('+ App.Site.devices[devicesNotApplied[i]].ip_address.substr(-4)+')';
                        if(i+1 < total){
                            log += ',';
                        }
                    }
                    //if we didn't apply to any devices due to applicability...
                    if(total === totalDevices){
                        title += feature+' not applied, add applicable devices to successfuly apply this feature:';
                        this.featureSettings[feature].applied = false;
                        this.featureSettings[feature].active = false;
                        App.alert('warning', title, 'Devices not applicable: '+log);
                    }
                }
            }
        }
    }
    /* jshint ignore:end */
    //disable the feature and remove all scenes associated with it from the location
    disablefeature(feature, custom){
        let template, activeScene, scenes, preConf=false, linkIDsToRemove = new Set();
        if(this.featureSettings.hasOwnProperty(feature) && this.featureSettings[feature].applied === true && this.featureSettings[feature].active === false){
            template = MyTemplates[feature];
            this.featureSettings[feature].applied = false;
            this.featureSettings[feature].active = true;
            if(Object.keys(this.children).length>=0){
                scenes = this.parentalScenes;
            }else{
                scenes = this.scenes;
            }
        }else if(this.featureSettings.hasOwnProperty(feature) && this.featureSettings[feature].active === true){
            template = MyTemplates[feature];
            this.featureSettings[feature].applied = false;
            this.featureSettings[feature].active = false;
            scenes = this.buildingScenes;
            preConf = true;
        }else if(custom){
            //add handler for saving custom template, setting it true in the location and getting custom template info
        }else{
            console.log('Feature not enabled or does not exist.');
            return;
        }
        //remove all scenes associated with the
        if(template && template.hasOwnProperty('scenes')){
            let templateScenes = template.scenes;
            for (let id in scenes) {
                activeScene = scenes[id];
                if (activeScene.hasOwnProperty('description') && activeScene.description !== null) {
                    for (let tempSceneIndex = 0; tempSceneIndex < templateScenes.length; tempSceneIndex++) {
                        if (activeScene.description.toLowerCase() === templateScenes[tempSceneIndex].description){
                            if(preConf === false){//scenes are real so we delete them
                                this.scenesToDelete.add(id);
                                linkIDsToRemove.add(activeScene.link_id);
                            }else{//scenes are staged for creation so we just remove from their temp storage
                                delete this.buildingScenes[id];
                                linkIDsToRemove.add(id);
                            }
                        }
                    }
                }
            }
        }
        //remove actions to be saved to devices if we are in a pre configured state (meaning user toggled the feature on, then off before saving)
        if(preConf){
            let activeAct;
            for(let index in this.templateActions){
                activeAct = this.templateActions[index];
                if(linkIDsToRemove.has(activeAct.link_id.toString()) || linkIDsToRemove.has(activeAct.var_1.toString()) || linkIDsToRemove.has(Devices.switch.getLinkIDFromVar1(activeAct.var_1).toString())){
                    delete this.templateActions[index];
                }
            }
        }
    }
    //check if any features require feature 'feature' and are enabled, if they are disable them because we are disabling their dependency
    disableActiveDependentFeatures(feature){
        for(let id in this.featureSettings){
            let active = this.featureSettings[id];
            if('requires' in active && active.requires === feature && active.applied === true){
                this.disablefeature(id);
            }
        }
    }
    //apply all data points assocaited with the feature to each device set for active feature application
    applyFeatureDataPoints(feature, enable = 'enable'){//AA: Need to handle removing setpoints to write on feature disable in preconf state...not a huge deal though
        if(!(feature in MyTemplates)){
            return;
        }
        let featureDataPoints = MyTemplates[feature].datapoints[enable];
        for (let id in this.templateDevices ) {
            let device = this.templateDevices[id];
            //Remove the AM- if it exists
            let activeDevType = device.partType.replace('AM-', '');
            //If we are to change datapoints for this device
            if (featureDataPoints.hasOwnProperty(activeDevType)) {
                for (let dpIndex = 0; dpIndex < featureDataPoints[activeDevType].length; dpIndex++) {
                    let activeDatapoint = featureDataPoints[activeDevType][dpIndex];
                    if(device.classification === 'switch'){
                        continue;
                    }
                    if(device.type === 'DRIVER' && activeDatapoint.name === 'Min Out' && activeDatapoint.val < 16){
                        activeDatapoint.val = 16;
                    }
                    this.addSetpointsToWrite(device, activeDatapoint.val, activeDatapoint.name);
                }
            }
        }
        let phdOutputs = this.phdOutputs;
        //apply for phd channels
        for(let id in phdOutputs){
            let activeOutput = phdOutputs[id];
            let parentPHD = App.Site.devices[activeOutput.parent_id];
            let activeDevType = parentPHD.type.replace('AM-', '');
            if (featureDataPoints.hasOwnProperty(activeDevType)) {
                for (let dpIndex = 0; dpIndex < featureDataPoints[activeDevType].length; dpIndex++) {
                    let activeDatapoint = featureDataPoints[activeDevType][dpIndex];
                    this.addSetpointsToWrite(parentPHD, activeDatapoint.val, activeDatapoint.dname);
                }
            }
        }
        if(feature === 'day-occ' || feature === 'night-occ'){
            let parentSW8s = this.parentSW8s;
            let activeDevType = 'SW8';
            for(let id in parentSW8s){
                let device = parentSW8s[id];
                if(device.dualTech === false){
                    continue;
                }
                if (featureDataPoints.hasOwnProperty(activeDevType)) {
                    for (let dpIndex = 0; dpIndex < featureDataPoints[activeDevType].length; dpIndex++) {
                        let activeDatapoint = featureDataPoints[activeDevType][dpIndex];
                        this.addSetpointsToWrite(device, activeDatapoint.val, activeDatapoint.name, activeDatapoint.idx);
                    }
                }
            }

        }else if(feature === 'responsive-dimming'){
            //if not doing occ set set all sw8 inputs in this location to mode 2
            let sw8Inputs = this.parentalSw8Inputs;
            for(let id in sw8Inputs){
                let active = sw8Inputs[id];
                let parent = active.parent;
                if(parent.dualTech === true){
                    continue;
                }
                let dname = parent.indexToDname[active.output];
                //mode idx correlates directly to input number
                this.addSetpointsToWrite(parent, 2, dname, active.output);
            }
        }
    }
    get masterOcc(){
        let masterML = this.masterML;
        if(this.masterML === false || (typeof(this.masterML) === 'object' && 'role' in this.masterML && this.masterML.role === 'master_dll')){
            masterML = this.findMasterOcc();
        }
        return masterML;
    }
    //search all devices in the template for a master sensor
    configureLocationSensorRoles(occ=false, dll=false, insertingNewDevices=false){
        if(occ === false && dll === false){
            console.log('error: Occupancy or DLL must be true.');
            return;
        }
        if(!insertingNewDevices){
            this.masterML = (occ === true ? this.findMasterOcc() : this.findMasterDLL());
        }
        if(this.masterML !== false){
            //call to handle setting role and name things for master sensor
            if(!insertingNewDevices){
                this.setMasterSensor(this.masterML, occ, dll);
            }
            //handle all sensors that are not the master
            this.setNonMasterSensorRoles(occ, dll);
        }
    }
    //AA: do want to default occ to true here?
    setMasterSensor(masterSensor=this.masterML, occ=false, dll=false){
        if(occ === false && dll === false){
            console.log('error: Occupancy or DLL must be true.');
            return;
        }
        let roleToSet = (occ === true ? this.getMasterSensorRoleOcc(masterSensor) : this.getMasterSensorRoleDLL(masterSensor));
        let nameString = masterSensor.name;

        if(dll === true && masterSensor.name.toLowerCase().indexOf('dll') < 0){
            nameString += ' (DLL)';//set dll in name to reflect its new role
        }
        if(occ === true && masterSensor.name.toLowerCase().indexOf('master') < 0){
            nameString += ' (Master)';//set master in name to reflect its new role
        }
        masterSensor.setProps({'name':nameString, 'role':roleToSet});
    }
    //get the correct role for master sensor to be set in context of occupancy
    getMasterSensorRoleOcc(masterSensor){
        let activeRole = masterSensor.role, roleToSet;
         //If the master is a dll master already, we will want to set his role to  master_motion_dll now to show that he is doing both roles
        if (activeRole === 'master_dll' ||
            activeRole === 'master_motion_dll') {
            roleToSet = 'master_motion_dll';
        } else {
            roleToSet = 'master_motion';
        }
        return roleToSet;
    }
    //get the correct role for master sensor to be set in context of daylighting
    getMasterSensorRoleDLL(masterSensor){
        let activeRole = masterSensor.role, roleToSet;
        if (activeRole === 'master_motion' || activeRole === 'master_motion_dll' || activeRole === 'master_dll') {
            roleToSet = 'master_motion_dll';
        } else if (activeRole === 'child_motion' || activeRole === 'master_dll_child_motion') {
            roleToSet = 'master_dll_child_motion';
        }
        return roleToSet;
    }

    setNonMasterSensorRoles(occ=false, dll=false){
        //handle setting the roles for all sensors in location that are not the master sensor
        let activeRole, activeDevice;
        let roleToSet = false;
        let devices = this.devices;
        let parentalSw8s = this.parentSW8s;
        if(Object.keys(parentalSw8s).length > 0){
            for(let id in parentalSw8s){
                if(parentalSw8s[id].dualTech === true){
                    devices[id] = parentalSw8s[id];
                }
            }
        }
        for(let id in devices){
            if(id !== this.masterML.id && (devices[id].is_sensor === true || devices[id].dualTech === true)){
                activeDevice = App.Site.devices[id];
                activeRole = activeDevice.role;
                if(occ && !dll){
                    //If this guys is a dll master already, we will want to set his role to master_dll_child_motion now to show that he is doing both roles
                    if (activeRole === 'master_dll' || activeRole === 'master_motion_dll')
                     {
                        roleToSet = 'master_dll_child_motion';
                    } else {
                        roleToSet = 'child_motion';
                    }
                }else if(dll && !occ){
                    //If this guys is a dll master already, we will want to set his role to master_dll_child_motion now to show that he is doing both roles
                    if (activeRole === 'master_dll') {
                        roleToSet = '-';
                    } else if (activeRole === 'master_dll_child_motion') {
                        roleToSet = 'child_motion';
                    } else if (activeRole === 'master_motion_dll') {
                        roleToSet =  'master_motion';
                    }
                }
                if(roleToSet !== false){
                    activeDevice.setProps({'role':roleToSet});
                }
            }
        }
    }
    //get all devices currently in motion group not in this location (motion link)
    getMotionLinks(){
        let links = {};
        let scenes = this.parentalScenes;
        for(let id in scenes){
            if(scenes[id].description === 'motion_group'){
                for(let trigger in scenes[id].triggers){
                    let device = App.Site.devices[App.Site.deviceLookup[scenes[id].triggers[trigger].ip_address]];
                    if(device.location !== false && device.location.id !== this.id && !device.location.path.has(this.id)){
                        links[device.id] = device.location;
                    }
                }
            }
        }
        return links;
    }
    //create link between motion groups, adds passed in masterOcc (sensor) from another location to this locations motion group as a trigger
    setMotionLink(masterOcc){
        let scenes = this.scenes;
        for(let id in scenes){
            if(scenes[id].description === 'motion_group'){
                let linkID = scenes[id].link_id;
                Scenes.functionGenerator(masterOcc, 'Motion Update', 'trigger', linkID);
            }
        }
        for(let index in this.templateActions){//prepare actions to be saved
            this.insertActionToSave(this.templateActions[index]);
        }
        this.post({'data':"["+this.externalActionsToSave+"]"}, false,  'site_table_edit', 'v2').then((actions) => {
            this.templateActions = [];
            this.externalActionsToSave = '';
            Devices.parseActions(actions);
            App.alert('success', 'Motion Link', 'Motion Link Added Successfully!');
            if(App.hasOwnProperty('locationSettingsMenu')){
                App.LocationSettingsMenu.updateLocationSettings();
            }
        });

    }
    //remove specified master occ from motion group
    removeMotionLink(masterOcc){
        let scenes = this.scenes;
        let deleted = false;
        for(let id in scenes){
            if(scenes[id].description === 'motion_group'){
               for(let trigger in scenes[id].triggers){
                   let active = scenes[id].triggers[trigger];
                   if(active.ip_address === masterOcc.ip_address){
                       deleted = true;
                       active.name = 'deleted';
                       active.table_row = '65535';
                       this.insertActionToSave(active);
                       delete scenes[id].triggers[trigger];
                   }
               }
            }
        }
        if(deleted){
            this.post({'data':"["+this.externalActionsToSave+"]"}, false,  'site_table_edit', 'v2').then((actions) => {
                this.templateActions = [];
                this.externalActionsToSave = '';
                Devices.parseActions(actions);
                App.alert('success', 'Motion Link', 'Motion Link Removed Successfully!');
                if(App.hasOwnProperty('locationSettingsMenu')){
                    App.LocationSettingsMenu.updateLocationSettings();
                }
            });
        }else{
            App.alert('error', 'Motion Link', 'Motion Link Not Found!');
            if(App.hasOwnProperty('locationSettingsMenu')){
                App.LocationSettingsMenu.updateLocationSettings();
            }
        }

    }
    //find the master sensor in context of occupancy
    findMasterOcc(){
        let masterSensor = false;
        let devices = this.devices, active;
        let parentalSw8s = this.parentSW8s;
        if(Object.keys(parentalSw8s).length > 0){
            for(let id in parentalSw8s){
                if(parentalSw8s[id].dualTech === true){
                    devices[id] = parentalSw8s[id];
                }
            }
        }
        for(let id in devices){
            active = devices[id];
            if(active.is_sensor === true || active.dualTech === true){
                //use first sensor as master incase we don't have any expressly set to master
                if(masterSensor === false){
                    masterSensor = active;
                }
                if(active.isMasterOccByRole === true){//found master by role, this is our guy
                    masterSensor = active;
                    break;
                }else if(active.isMasterOccByName === true){//otherwise if master by name (name always second priority to role)
                    masterSensor = active;
                }
            }
        }
        this.masterML = masterSensor;
        return masterSensor;
    }
    //find the master sensor in context of daylight linking
    findMasterDLL(){
        let masterSensor = false;
        let devices, active;
        if(this.isParent === true && this.canUmbrellaDlh === true){
            devices = this.parentalDevices;
        }else{
            devices = this.devices;
        }
        for(let id in devices){
            active = devices[id];
            if(active.is_sensor === true){
                //use first sensor as master incase we don't have any expressly set to master
                if(masterSensor === false){
                    masterSensor = active;
                }
                if(active.isMasterDllByRole === true){//found master by role, this is our guy
                    masterSensor = active;
                    break;
                }else if(active.isMasterDllByName === true){//otherwise if master by name (name always second priority to role)
                    masterSensor = active;
                }
            }
        }
        this.masterML = masterSensor;
        return masterSensor;
    }

    activate(){
        if(App.ActiveLocation !== this){
            App.ActiveLocation = this;
            this.active = true;
        }
    }

    async destroy(fromCloud=false, count){
        //We only want to do a full destroy if we originated the destruction
        if(fromCloud === false){
            //Untag all devices in this location and its children
            this.untagTaggedDevices(this.devices);

            //Destroy all scenes in this location
            let scenes = this.scenes;
            for(let id in scenes){
                scenes[id].destroy(false, true);
            }
            //If this location has children, we will recursively delete all child locations
            if(this.isParent){
                let active;
                for(let id in App.Site.locations){
                    active = App.Site.locations[id];
                    if(active.path.has(this.id) && id !== this.id){
                        active.delete();
                        active.softDeleted = true;
                    }
                }
            }
            this.softDeleted = true;
            if(App.Site.root.externalActionsToSave.length > 0){
                App.Site.root.post({'data':'['+App.Site.root.externalActionsToSave+']'}, false,  'site_table_edit', 'v2').then((newActions) => {
                    Devices.parseActions(newActions);
                    App.Site.root.externalActionsToSave = "";
                });
            }
        }else{
            //This is done by the success handler in the api currently
            // So for backhaul deletes, we need to remove this item from the list
            Locations.removeFromLocationsList(this.ID);
        }

        //Otherwise we let the backhaul delete everything else
        Locations.removeFromParents(this.ID);
        this.updateUIComponents();

        //At the end, delete it from the cloud
        if(!fromCloud){
            this.delete();
        }
        if(count === false || count === 0){
            App.setActiveLocation(App.Site.root);
        }
    }
    untagTaggedDevices(devices){
        for(let index in devices){
            if(devices.hasOwnProperty(index)){
                let device = devices[index];
                device.setProps({location_id : null_location_id});
            }
        }
        let phdOutputs = this.phdOutputs;
        for(let id in phdOutputs){
            phdOutputs[id].setProps({location_id : null_location_id});
        }
        let sw8Inputs = this.sw8Inputs ;
        for(let id in sw8Inputs ){
            sw8Inputs[id].setProps({location_id : null_location_id});
        }
    }
    //look and see if there were outputs in the location before new outputs were added
    onlyNewOutputs(newOutputs=this.newOutputs){
        let phdOutputs = this.phdOutputs;
        let onlyNew = true;
        for(let id in phdOutputs){
            if(!(id in newOutputs)){
                onlyNew = false;
            }
        }
        return onlyNew;
    }

    get hasMute(){
        for (let deviceID in this.devices) {
            if (!this.devices[deviceID].talking) {
                this._hasMute = true;
            }
        }
        this._hasMute = false;
        return this._hasMute;
    }

    get path(){
        let location = this;
        while(App.Site.hasOwnProperty('locations')
            &&  App.Site.locations.hasOwnProperty(location.parent_id)
            && App.Site.locations[location.parent_id].parent_id !== 0
        ){
            this._path.add(location.parent_id);
            location = App.Site.locations[location.parent_id];
        }
        return this._path;
    }

    get pathStr (){
        let node = this;
        let pathStr = '';
        try {
            if (node.parent.hasOwnProperty('ID')){
                pathStr = node.parent.ID + ',' + node.ID;
            }

            while (typeof (node.parent.parent.ID) !== 'undefined') { //while parent isnt root
                pathStr = node.parent.parent.ID + ',' + pathStr;
                node = node.parent;
            }
        } catch (e) { //otherwise this location is an orphan
            // console.log(e);
            // orphanedChildLocs.push(index);
        }
        return pathStr;
    }
    get breadCrumbs(){
        let breadCrumbs = '';
        const pathReversed = new Set(Array.from(this.path).reverse());

        pathReversed.forEach((locid)=>{
            breadCrumbs += App.Site.locations[locid].name +'/';
        });

        return `${breadCrumbs}${this.name}`;
    }
    get parent_id(){
        return this._parent_id;
    }
    //find root during location creation
    set parent_id(parent_id){
        this._parent_id = parent_id;
    }

    get parentPHDs(){
        let outputs = this.phdOutputs;
        let parentPHDs = {};
        for(let id in outputs){
            if(id in outputs){
                let output = App.Site.phdOutputs[id];
                if(!(output.parent_id in parentPHDs)){
                    parentPHDs[output.parent_id] = output.parent_device;
                }
            }
        }
        return parentPHDs;
    }
    get parentMCDs(){
        let outputs = this.MCDOutputs;
        let parentMCDs = {};
        for(let id in outputs){
            if(id in outputs){
                let output = App.Site.MCDOutputs[id];
                if(!(output.parent_id in parentMCDs)){
                    parentMCDs[output.parent_id] = output.parent_device;
                }
            }
        }
        return parentMCDs;
    }
    get dcdmins() {
        const TYPES = ['AM-DCDIM', 'AM-DCDIM-DAC', 'AM-DCDIM-PWM', 'DCDIM-TINY-TEST'];
        const DCDMIN = {};
        for(let id in this.devices){
            if (TYPES.includes(this.devices[id].partType)) {
                DCDMIN[id] = this.devices[id];
            }
        }
        return DCDMIN;
    }

    get phdOutputs(){
        let phdOutputs = {};
        if(Object.keys(App.Site.phdOutputs).length >= 0){
            for(let id in App.Site.phdOutputs){
                let output = App.Site.phdOutputs[id];
                if(output.hasOwnProperty('location_id') && App.Site.locations.hasOwnProperty(output.location_id)){
                    if(output.location_id === this.id || App.Site.locations[output.location_id].path.has(this.id)){
                        phdOutputs[id] = output;
                    }
                }
            }
        }
        return phdOutputs;
    }

    /*
    NOTE: good idea but we need to try figureout how sincronize the data for sites.
    buildPhdOutputs(){
        const devices = Object.values(App.Site.phdOutputs);
        console.log('buildPhdOutputs', devices.length)
        if (!devices.length) {
            this.phdOutputs = {};
        }
        const devicesByLocation = devices.filter(phd => {
            console.log('PHD IN', phd)
            return phd.location_id === this.id
        });
        console.log('LLEGO buildPhdOutputs', devicesByLocation)
        if (!devicesByLocation.length) {
            this.phdOutputs = {};
        }
        let phdOutputs = {};
        devicesByLocation.forEach(datapoint => {
            phdOutputs[datapoint.ID] = datapoint;
        })
        console.log(' phdOutputs', phdOutputs)
        this.phdOutputs = phdOutputs;
    } */

    get MCDOutputs(){
        let MCDOutputs = {};
        if(Object.keys(App.Site.MCDOutputs).length >= 0){
            for(let id in App.Site.MCDOutputs){
                let output = App.Site.MCDOutputs[id];
                if(output.hasOwnProperty('location_id') && App.Site.locations.hasOwnProperty(output.location_id)){
                    if(output.location_id === this.id || App.Site.locations[output.location_id].path.has(this.id)){
                        MCDOutputs[id] = output;
                    }
                }
            }
        }
        return MCDOutputs;
    }
    get parentalSw8Inputs(){
        let sw8Inputs = {};
        if(Object.keys(App.Site.sw8Inputs).length >= 0){
            for(let id in App.Site.sw8Inputs){
                let input = App.Site.sw8Inputs[id];
                if(input.hasOwnProperty('location_id') && App.Site.locations.hasOwnProperty(input.location_id)){
                    if(input.location_id === this.id ){
                        sw8Inputs[id] = input;
                    }
                }
            }
        }
        return sw8Inputs;
    }
    get parentSW8s(){
        let sw8s = {};
        let sw8Inputs = this.sw8Inputs;
        for(let id in sw8Inputs){
            let input = App.Site.sw8Inputs[id];
            sw8s[input.parent.id] = input.parent;
        }
        return sw8s;
    }
    get sw8Inputs(){
        let sw8Inputs = {};
        if(Object.keys(App.Site.sw8Inputs).length >= 0){
            for(let id in App.Site.sw8Inputs){
                let input = App.Site.sw8Inputs[id];
                if(input.hasOwnProperty('location_id') && App.Site.locations.hasOwnProperty(input.location_id)){
                    if(input.location_id === this.id || input.location.path.has(this.id)){
                        sw8Inputs[id] = input;
                    }
                }
            }
        }
        return sw8Inputs;
    }
    //get devices specific to location, not that of what would also be in any child location
    get parentalDevices(){
        let locationDevices = {};
        for(const id in App.Site.devices){
            let device = App.Site.devices[id];
            //if the device is directly in this location or it is in a location that is a child of this location return the device
            if(device.hasOwnProperty('location_id') &&  App.Site.locations.hasOwnProperty(device.location_id)){
                if(device.location_id === this.id){
                    locationDevices[id] = device;
                }
            }
        }
        this.currentDevices = locationDevices;
        return locationDevices;
    }
    //get scenes specific to location, not that of what would also be in any child location
    get parentalScenes(){
        let scenes = {};
        for(let index in App.Site.scenes){
            let scene = App.Site.scenes[index];
            if('_location_id' in scene && (scene._location_id === this.id)){
                scenes[index] = scene;
            }
        }
        return scenes;
    }

    get devices(){
        let locationDevices = {};
        for(const id in App.Site.devices){
            let device = App.Site.devices[id];
            //if the device is directly in this location or it is in a location that is a child of this location return the device
            if(device.hasOwnProperty('location_id') &&  App.Site.locations.hasOwnProperty(device.location_id)){
                if(device.location_id === this.id || App.Site.locations[device.location_id].path.has(this.id)){
                    locationDevices[id] = device;
                }
            }
        }
        this.currentDevices = locationDevices;
        return locationDevices;
    }

    get devicesAllType() {
        const devices = Object.keys(App.Site.devices).reduce((acc, id) => {
            const current = App.Site.devices[id];
            if (current.hasOwnProperty('location_id')) {
                if (App.Site.locations.hasOwnProperty(current.location_id)) {
                    if (current.location_id === this.id || App.Site.locations[current.location_id].path.has(this.id)) {
                        acc[id] = current;
                    }
                }
            }
            return acc;
        }, {});
        const phdOutputs = this.phdOutputs;
        if (Object.keys(phdOutputs).length >= 1) {
            for (const id in phdOutputs) {
            devices[`output-${id}`] = phdOutputs[id];
            }
        }
        const MCDOutputs = this.MCDOutputs;
        if (Object.keys(MCDOutputs).length >= 1) {
            for (const id in MCDOutputs) {
              devices[`output-${id}`] = MCDOutputs[id];
            }
        }
        const sw8Inputs = this.sw8Inputs
        if (Object.keys(sw8Inputs).length >= 1) {
            for (const id in sw8Inputs) {
                devices[`input-${id}`] = sw8Inputs[id];
            }
        }

        return devices;
    }

    get hasAssociatedDevices(){
        if( Object.keys(this.devices).length !== 0
            || Object.keys(this.parentalSw8Inputs).length !== 0
            || Object.keys(this.phdOutputs).length !== 0
            || Object.keys(this.MCDOutputs).length !== 0
        ){
            return true;
        }

        return false;
    }
    // get XDDevices(){
    //     let locationDevices = {};
    //     for(const id in App.Site.devices){
    //         let device = App.Site.devices[id];
    //         //if the device is directly in this location or it is in a location that is a child of this location return the device
    //         if(device.hasOwnProperty('location_id') &&  App.Site.locations.hasOwnProperty(device.location_id)){
    //             if(device.location_id === this.id){
    //                 locationDevices[id] = device;
    //             }
    //         }
    //     }
    //     this.currentDevices = locationDevices;
    //     return locationDevices;
    // }

    //get all devices, phd channels and sw inputs
    get featureDevices(){
        let featureDevices = {};
        let allDevicesHere = {
            'devices':this.devices,
            'outputs':this.phdOutputs,
            'inputs':this.sw8Inputs,
        }
        for(let set in allDevicesHere){
            for(let id in allDevicesHere[set]){
                featureDevices[id] = allDevicesHere[set][id];
            }
        }
        return featureDevices;
    }
    get scenes(){
        let scenes = {};
        for(let index in App.Site.scenes){
            let scene = App.Site.scenes[index];
            if('_location_id' in scene &&
                (scene._location_id === this.id ||
                    (scene._location_id in App.Site.locations && App.Site.locations[scene._location_id].path.has(this.id)
                    )
                )
            ){
                scenes[index] = scene;
            }
        }
        return scenes;
    }
    removeAllScenes(){
        let scenes = this.scenes;
        for(let scene in scenes){
            scene[scenes].destroy();
        }
    }
    removeDeviceFromScenes(devceIP){
        let scenes = this.scenes;
        for(let scene in scenes){
            scenes[scene].prepareAssociatedDeviceActionsForDeletion(devceIP);
        }
    }
    get hasOcc(){
        let scenes = this.parentalScenes;
        for(let id in scenes){
            if(scenes[id].description === 'motion_group'){
                return true;
            }
        }
        return false;
    }
    calcState(){
        if(Locations.calculateLightLevel(this) === false){//if there are no devices well get false back and well need to delete all the scenes here
            this.hasOccupancyControl = false;//then handle removing device actions accordingly
            this.mode = false;
            //this.removeAllScenes(); uncomment to add functionality to remove all scenes when all devices are removed from a location
            this.updateUIComponents();
        }else{
            this.updateUIComponents();
        }
    }
    get errors(){
        let devices = (this.isRoot === true) ? App.Site.devices : this.devices;
        let errors= [];
        for(let index in devices){
            if(devices.hasOwnProperty(index)){
                let device = devices[index];
                let deviceError = device.errors;
                if(deviceError.error === true){
                    errors.push(deviceError);
                }
            }
        }
        return errors;
    }
    set lightLevel(level){
        this._lightLevel = level;
    }
    get lightLevel(){
        return this._lightLevel;
    }
    get roomTypeIcon(){
        if(Locations.roomType.hasOwnProperty(this.room_type)){
            return Locations.roomType[this.room_type].icon;
        }else{
            return Locations.roomType[0].icon;
        }
    }
    get roomTypeString(){
        if(Locations.roomType.hasOwnProperty(this.room_type)){
            return Locations.roomType[this.room_type].name;
        }else{
            return Locations.roomType[0].name;
        }
    }
    get shortName(){
        let maxLength = 22;
        let name = this.name;
        let shortName;
        if(name.length > maxLength) {
            let offSetLength = maxLength - 3;
            shortName = name.substring(0,offSetLength)+'...';
        }else{
            shortName = name;
        }
        return shortName;
    }
    get nameWithParents(){
        let parentArray = [];
        this.path.forEach((locationIndex)=>{
            //right now we just want the immediate parent
            // instad of doing get(0), I did this so we can expand if/when
            //we want to return more
            if(parentArray.length < 1){
                parentArray.push(App.Site.locations[locationIndex]);
            }
        });
        parentArray.push(this);
        return parentArray;
    }
    get isParent(){//location is not root and has children
        if(this.isRoot !== true && Object.keys(this.children).length > 0){
            return true;
        }else{
            return false;
        }
    }
    get isNoDevices(){
        if(Object.keys(this.devices).length === 0
            && Object.keys(this.phdOutputs).length === 0
            && Object.keys(this.sw8Inputs).length === 0
            && Object.keys(this.MCDOutputs).length === 0
        ){
            return true;
        }else{
            return false;
        }
    }
    get isChild(){
        if(this.isRoot === false && this.parent.id !== App.Site.root.id){
            return true;
        }else{
            return false;
        }
    }
    showFeature(locationFeature, locationFeatures=this.featureSettings){
        let activeFeature = locationFeatures[locationFeature];
        if ( activeFeature.display === false ){
            return false;
        }

        if (User.isPermitted(['admin', 'company', 'installer']) === false) {
            return false;
        }

        if ('requires' in activeFeature && locationFeatures[activeFeature.requires].applied === false) {
            return false;
        }

        // Case for PHD devices in location current
        if (Object.keys(this.phdOutputs).length > 0) {
            return true;
        }

        // Case for Generic Multichannel devices in location current
        if (Object.keys(this.MCDOutputs).length > 0) {
            return true;
        }

        // Case for DCDIM devices in location current
        if (Object.keys(this.dcdmins).length > 0) {
            return true;
        }

        if (this.masterML === false || this.masterML.version === null || this.masterML.version === undefined) {
            return false;
        }

        if ('version' in activeFeature && parseFloat(this.masterML.version.replace(/\./g, '')) < parseFloat(activeFeature.version.replace(/\./g, ''))) {
            return false;
        }

        return true;
    }
    //calculate if a feauture is applied based on the scenes in the location (looking at matching descriptions)
    calcFeaturesApplied(){
        let scenes;
        if(this.isParent){
            scenes = this.parentalScenes;
        }else{
            scenes = this.scenes;
        }
        let scenesByDesc = {};
        let featureApplied;
        for(let id in scenes){
            if(scenes[id].softDeleted === false){
                scenesByDesc[scenes[id].description] = scenes[id];
            }
        }
        let missing = {};
        let matched;
        for(let template in MyTemplates){
            if(this.featureSettings.hasOwnProperty(template)){
                featureApplied = true;
                matched = Object.keys(MyTemplates[template].scenes).length;
                for(let scene in MyTemplates[template].scenes){
                    let active = MyTemplates[template].scenes[scene];
                    //AA: currently requiring all scenes of the template must exist in the location, change this logic here if we want to handle it otherwise
                    if(!scenesByDesc.hasOwnProperty(active.description)){
                        matched--;
                        if(!missing.hasOwnProperty(template)){
                            missing[template] = [];
                        }
                        missing[template].push(active.name);
                    }
                }
                if(matched === 0){
                    featureApplied = false;
                    delete missing[template];
                }
                this.featureSettings[template].applied = featureApplied;
            }
        }
        return missing;
    }

    async patchResourceV3 (apiv3, params, payload) {
        return await apiv3.locations.updateLocation(params, payload);
    }
}

export default Location;
