import {
    LIST_LOCATIONS_REQUEST,
    LIST_LOCATIONS_SUCCESS,
    LIST_LOCATIONS_FAILURE,
    LIST_LOCATIONS_FULFILL,
    SET_LOCATION_MODAL,
    CLEAR_LOCATIONS,
    LOCATION_SET_OPENING,
    LOCATION_TOGGLE_OPENING,
    LOCATIONS_POSITION_SCROLL,
    SET_TEST_MODE,
    SET_TEST_MODE_ACTIVE,
    SET_LOCATION_SETTING_SAVE,
    HISTORY_BY_LOCATION_INFLUX_SUCCESS,
    HISTORY_BY_LOCATION_INFLUX_FAILURE,
    HISTORY_BY_LOCATION_INFLUX_REQUEST,
    HISTORY_BY_LOCATION_INFLUX_FULFILL
} from './actions';

const initialState = {
    error: null,
    items: [],
    loading: true,
    opening: [],
    positionScroll: null,
    modal: false,
    meta: {
        search: '',
    },
    testMode: false,
    saving_settings: false,
    influx: {
        items: [],
        error: null,
        loading: false,
    },
};

const handleRequest = state => Object.assign({}, state, { loading: true, error: null });
const handleSuccess = (state, { payload }) => Object.assign({}, state, { items: payload });
const handleFailure = (state, { payload }) => Object.assign({}, state, { error: payload });
const handleFulfill = state => Object.assign({}, state, { loading: false });
const handleClear = () => Object.assign({}, initialState);
const handleToggleOpening = ({ opening, ...otherStates }, { payload }) => {
    if(opening.includes(payload)) {
        return Object.assign({}, otherStates, {
            opening: opening.filter(open => open !== payload)
        });
    }
    return Object.assign({}, otherStates, { opening: [...opening, payload] });
}
const handleOpening = ({ opening, ...otherStates }, { payload }) => {
    return Object.assign({}, otherStates, { opening: [...opening, ...payload] });
}

const handlePositionScroll = (state, { payload }) => Object.assign({}, state, { positionScroll: payload })

const handleSetModal = (state, { payload }) =>
    Object.assign({}, state, { modal: payload });

const handleSetTestMode = (state, { payload }) =>
    Object.assign({}, state, { testMode: payload });

const handleSetTestModeActive = (state, { payload }) =>
    Object.assign({}, state, { isTestModeActive: payload });

const handleSetSavingSettings = (state, { payload }) =>
    Object.assign({}, state, { saving_settings: payload });

const handleSetInflux = (state, { payload }) => ({...state, influx: { ...state.influx, items: payload }});
const handleRequestInflux = state => ({...state, influx: { ...state.influx, items: [], loading: true }});
const handleFailureInflux = (state, { payload }) => ({...state, influx: { ...state.influx, error: payload }});
const handleFulfillInflux = state => ({...state, influx: { ...state.influx, loading: false }});

const handlers = {
    [LIST_LOCATIONS_REQUEST]: handleRequest,
    [LIST_LOCATIONS_SUCCESS]: handleSuccess,
    [LIST_LOCATIONS_FAILURE]: handleFailure,
    [LIST_LOCATIONS_FULFILL]: handleFulfill,
    [CLEAR_LOCATIONS]: handleClear,
    [LOCATION_TOGGLE_OPENING]: handleToggleOpening,
    [LOCATION_SET_OPENING]: handleOpening,
    [LOCATIONS_POSITION_SCROLL]: handlePositionScroll,
    [SET_LOCATION_MODAL]: handleSetModal,
    [SET_TEST_MODE]: handleSetTestMode,
    [SET_TEST_MODE_ACTIVE]: handleSetTestModeActive,
    [SET_LOCATION_SETTING_SAVE]: handleSetSavingSettings,
    [HISTORY_BY_LOCATION_INFLUX_REQUEST]: handleRequestInflux,
    [HISTORY_BY_LOCATION_INFLUX_SUCCESS]: handleSetInflux,
    [HISTORY_BY_LOCATION_INFLUX_FAILURE]: handleFailureInflux,
    [HISTORY_BY_LOCATION_INFLUX_FULFILL]: handleFulfillInflux
};

const reducer = (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
