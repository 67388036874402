import $ from 'jquery';
import { Locations } from  'api/amatis/site/locations/services/locations';
import { Scenes } from 'api/amatis/site/scenes/services/scenes';
import { Groups } from 'api/amatis/site/groups/groups';
import Communication from 'api/amatis/utilities/communications';
import Terminal from  'api/amatis/utilities/terminal';
import User from 'classes/users';
import { decToHex, hexToDec } from 'classes/methods';
import defines from '../models/device-defines';
import PhdOutput from './phdOutput.js';
import Sw8Input from './sw8Input.js';
import MCDOutput from './mcdOuput';
import Device from 'api/amatis/site/devices/Device';
import Scanner from 'classes/scanners';
import App from 'app/app';
import SDK from 'api/sdk';
import SDKDevices from 'api/sdkDevices';
import {null_location_id} from 'classes/methods.js';
import store from 'store';
import { addDeviceToItems } from 'redux/devices/actions';

// import store from 'store';
// import { deviceUpdate, addDevicesInternalAction, addDevicesExternalAction } from 'redux/devices';

let Devices = {
    //TODO: after actionSorter we need to go back and update the device list with links where there are scenes
    list: [],
    deletedList: [],
    devicesToDelete: 0,
    recentlyDeleted: {},
    actionsParsing: false,
    deletedDeviceList: [],
    activeConfig: false,
    activeDeviceIndex: false,
    blinkInterval: '',
    stopBlinkingTO: '',
    setpointsToWrite: [],
    livingNewDeviceList: [],
    maxChar: 23,
    unsavedMaxChar: 26,
    timer: 0,
    doSaveStaleDevices: false,
    deviceCount: 0,
    sensorCount: 0,
    switchCount: 0,
    dimmerCount: 0,
    otherCount: 0,
    groupSelectShown: false,
    didCheckForDangerousSetpoints: false,
    sortBy: 'name',
    selectedForGroupAdd: [],
    devicesNotLoadedCount: 0,
    didBuildDeviceList: false,
    classifications: {
        other: '',
        switch: '',
        sensor: '',
        dimmer: ''
    },
    getSwitchActionName(linkID){
        let string = 'INT.';
        switch (linkID) {
            case 65535:
                string += 'Button1_Release';
                break;

            case 65534:
                string += 'Button2_Release';
                break;

            case 65533:
                string +=  'Hold1';
                break;

            case 65532:
                string += 'Hold2';
                break;

            case 65531:
                string += 'Hold1_Release';
                break;

            case 65530:
                string += 'Hold2_Release';
                break;

            default:
                break;
        }
        return string;
    },
    defines: defines,

    getLiveData: async function(sinceago = ''){
        const SITEID = window.localStorage.getItem('amatis_site_id');
        const SDKOptions = { serverVariables: { baseVersion: 'v2' } };
        const queryParams = { SITEID, sinceago };
        if(App.isFirstLiveData) {
            App.isFirstLiveData = false;
            queryParams.critical = 1;
        }
        const api = await SDK.init();
        api.sites.getLiveData(queryParams, SDKOptions).then(response => {
            App.isLiveDataLoaded = true;
            this.parseLiveData(response.body.data);
        }).catch((err) => {
            // Commented message mqtt error, requested in history 179888345
            // App.alert('error', 'MQTT error: Unfortunately something didn\'t go quite right while trying to get live data', `${err}`);
        });
    },

    parseLiveData:function(data, remaining = false){
        const remainingData = [];
        // const bulkDevices = []
        let activeDevice;
        data.forEach(item => {
            if(App.Site.deviceLookup[item.ip]){
                const deviceLookUp = App.Site.deviceLookup[item.ip];
                const device = App.Site.devices[deviceLookUp];
                if(remaining || (device.location_id !== 0 && device.location_id !== App.Site.root.id)){
                    device.update([item], item.Time, true);
                    // const deviceIndex = bulkDevices.findIndex(bulk => bulk.deviceId === device.id);
                    activeDevice = device;
                    // if(deviceIndex < 0){
                    //     bulkDevices.push({
                    //         deviceId: device.id,
                    //         items: [item],
                    //         timestamp: item.Time,
                    //     });
                    // }else{
                    //     bulkDevices[deviceIndex].items.push(item);
                    // }
                } else {
                    remainingData.push(item);
                }
            }
        });
        // store.dispatch(deviceUpdate(bulkDevices));
        if(App.ActiveLocation !== false){
            App.ActiveLocation.updateUIComponents();
        }
        if(App.DeviceContainer !== false && activeDevice  && activeDevice.updateUIComponents){
            activeDevice.updateUIComponents();
        }
        if(!remaining){
            Locations.calcAllLightLevels();
            //do that at the end of parseLiveData instead of after every datapoint for performance
            if(remainingData.length > 0){
                this.parseLiveData(remainingData, true);
            }
        }
    },
    getPhdOutputs: async function(){
        let phdOutputs = await App.Site.get('phd_outputs', false, 'devices', 'v2');
        this.parsePhdOutputs(phdOutputs);
    },
    parsePhdOutputs:function(phdOutputs){
        let activeItem, activeOutput;
        for(let i = 0; i < phdOutputs.length; i++){
            activeItem = phdOutputs[i];
            if(activeItem.parent_ip_address in App.Site.deviceLookup){
                activeOutput = new PhdOutput(activeItem);
            }
            if(i +1 === phdOutputs.length){
                activeOutput.finishLoad();
            }
        }
    },
    getSw8Outputs: async function(){
        let sw8Inputs = await App.Site.get('sw8_inputs', false, 'devices', 'v2');
        this.parseSw8Outputs(sw8Inputs);
    },
    parseSw8Outputs:function(sw8Inputs){
        let activeItem, activeOutput;
        for(let i = 0; i < sw8Inputs.length; i++){
            activeItem = sw8Inputs[i];
            if(activeItem.parent_ip_address in App.Site.deviceLookup){
                activeOutput = new Sw8Input(activeItem);
            }
            if(i +1 === sw8Inputs.length){
                activeOutput.finishLoad();
            }
        }
    },
    //TODO: If the endpoint were extended to include part type, then we could pass that to the
    // PHDoutput constructor and have different types of those. Then we could convert all PHDOutput references
    // to be generic MCD (multi channel devices)
    getMCDOutputs: async function(){
        let mcdOutputs = await App.Site.get('multi_channel_device_outputs', false, 'devices', 'v2');
        this.parseMCDOutputs(mcdOutputs);
    },
    parseMCDOutputs:function(MCDOutputs){
        let activeItem, activeOutput;
        for(let i = 0; i < MCDOutputs.length; i++){
            activeItem = MCDOutputs[i];
            if(activeItem.parent_ip_address in App.Site.deviceLookup){
                activeOutput = new MCDOutput(activeItem);
            }
            if(i + 1 === MCDOutputs.length && activeOutput){
                activeOutput.finishLoad();
            }
        }
    },
    //loop over all actions in scene table from cloud and parse them into associated devices by ID
    //sorted into internal/external
    parseActions: function(sceneTable, initialLoad=false, isCompleteTable = false){
        Devices.actionsParsing = true;
        let deviceIP;
        let entry;
        let device;
        let linkID;
        let tableLength = sceneTable.length;
        Scenes.ghostScenesExist = false;
        Scenes.ghostScenes = [];

        // const deviceInternal = {};
        // const deviceExternal = {};

        for(let tableEntry = 0; tableEntry < tableLength; tableEntry ++){
            let deviceID = null;
            if (sceneTable[tableEntry].ip_address !== '') {
                deviceIP = sceneTable[tableEntry].ip_address;

                if (App.Site.deviceLookup.hasOwnProperty(deviceIP)){
                    deviceID = App.Site.deviceLookup[deviceIP];
                }

                if(App.Site.devices.hasOwnProperty(deviceID)){
                    entry = sceneTable[tableEntry];
                    device = App.Site.devices[deviceID];

                    //triggers will have name of coap_send_action(linkID) the reference linkID will be in var_1 so we will use that to parse the trigger into the right scene object
                    linkID = ((entry.name !== 'coap_send_action(linkID)') ? entry.link_id : entry.var_1);
                    if(entry.name.indexOf('MAC Action') >= 0 || (entry.link_id >= 65530 && entry.link_id <= 65535)){
                        linkID = Devices.switch.getLinkIDFromVar1(entry.var_1);
                    }
                    if (
                        (entry.name === 'deleted' ||
                        entry.table_row === '65535' ||
                        entry.table_row === 65535 ||
                        entry.saved !== 'FromNode') &&
                        device.isSyncable === true
                        )
                    {
                        device.unsaved = true;
                        this.handleActionDeletions(linkID, entry, device);
                    }

                    if (isCompleteTable) {
                        device.removeDeletedRows();
                    }

                    linkID = parseInt(linkID, 10);
                    //if the link_id is less than 256 the entry references an internal action
                    // TODO: change dispatch a bulk insert Internal a External endpoint
                    if (linkID < 256){
                        // if(deviceInternal[deviceID]) {
                        //     deviceInternal[deviceID].push(entry);
                        // } else {
                        //     deviceInternal[deviceID] = [entry];
                        // }
                        device.internalActions[entry.id] = entry;
                    }else{
                        //otherwise we have an external action, otherwise known as a scene
                        // if(deviceExternal[deviceID]) {
                        //     deviceExternal[deviceID].push(entry);
                        // } else {
                        //     deviceExternal[deviceID] = [entry];
                        // }
                        device.externalActions[entry.id] = entry;
                        //if the scene exists we tell it to parse the entry to see if this is a trigger or action
                        if(entry.name !== 'deleted'){
                            if( App.Site.sceneLookup.hasOwnProperty(linkID) &&
                                App.Site.scenes.hasOwnProperty(App.Site.sceneLookup[linkID])
                            ){
                                App.Site.scenes[App.Site.sceneLookup[linkID]].parseActionFunction(entry, deviceIP);
                            }
                            else if(linkID < 64000){//otherwise we have a ghost scene if its not a reserved linkID
                                let message = "Ghost Action Exists for device "+deviceIP+" : linkID - "+linkID+", uuid - "+entry.id;
                                console.log(message);
                                //     Scenes.ghostScenesExist = true;
                                //     entry.name = 'deleted';
                                //     entry.table_row = '65535';
                                //     device.unsaved = true;
                                //     App.Site.root.insertActionToSave(entry);
                                // //and also a garbage bin for ghost scenes
                                Scenes.ghostScenes.push(entry);
                            }
                        }
                        device.scenesFlag = true;
                    }
                }
            }
        }

        // store.dispatch(addDevicesInternalAction(deviceInternal));
        // store.dispatch(addDevicesExternalAction(deviceExternal));

        //we don't want to setSyncIcon while actions are parsing b/c we don't
        //want to update the view 100's of times for each device
        //so after we're done parsing we just want to loop and see if we found any
        //unsaved
        if (Devices.actionsParsing === true) {
            for (let id in App.Site.devices) {
                let deviceActive = App.Site.devices[id];
                if (deviceActive.unsaved) {
                    deviceActive.setSyncIcon(deviceActive.unsaved, deviceActive.isTalking());
                }
            }
        }

        Devices.actionsParsing = false;

        //Update all the UI things now that we have parsed all the actions
        if(App.ActiveLocation !== false){
            App.ActiveLocation.updateUIComponents();
        }

        if( device && device.hasOwnProperty('updateUIComponents')){
            device.updateUIComponents();
        }
        if(Scenes.ghostScenes.length > 0){
            let message = `:warning: ${Scenes.ghostScenes.length} ghosts exist here: ${window.location.origin}/site/${App.activeSiteID} :ghost:`;
            let data = {message}
            App.Site.post(data, 'slack-log','devices', 'v2').catch((err)=>{
                console.log('issue sending off to slack', err);
            });
        }else{
            console.log('no ghost scenes');
        }
       /* if(Scenes.ghostScenesExist){
            if(App.Site.root.externalActionsToSave.length > 0){
                // App.Site.root.post({'data':'['+App.Site.root.externalActionsToSave+']'}, false,  'site_table_edit', 'v2');
                App.Site.root.externalActionsToSave = '';
            }
            //Scenes.ghostScenesExist = false;
            //Scenes.ghostScenes = [];
        }*/
    },
    destroy: async function(device){
            //remove node from site
            try{
                let deletedDevice = await App.Site.delete(device.IP, 'devices', 'v1');
                if(deletedDevice === true){
                    console.log('devices about to get changed');
                    delete App.Site.devices[device.id];
                    Devices.list = Devices.list.filter(item => item.id != device.id);
                    if(App.hasOwnProperty('SyncContainer')){
                        App.SyncContainer.setDeviceList(Devices.list, true);
                    }
                    if(App.hasOwnProperty('PageContent')){
                        App.PageContent.setDeviceListLoadedFlag(true);
                    }
                    if(App.hasOwnProperty('ConfigTables')){
                        App.ConfigTables.setDeviceList(Devices.list);
                    }
                    if(App.ActiveLocation !== false && App.ActiveLocation.isRoot === false){
                        if(App.hasOwnProperty('SiteDashboardContainer')){
                            App.SiteDashboardContainer.setLocationStateDevices(App.ActiveLocation);
                        }
                    }else{
                        if(App.hasOwnProperty('DeviceContainer')){
                            App.DeviceContainer.setDeviceList(Devices.list);
                        }
                    }
                }
            }
            catch(e){
                console.log('error: ', e);
            }
    },
    //delete actions from device objects and scene objects if they exist
    handleActionDeletions: function(linkID, action, device){
        let actionId = action.id;
         //If actions are already in the device (like on update), remove them
         if(parseInt(linkID, 10) < 256){
            if (device.internalActions.hasOwnProperty(actionId)){
                delete device.internalActions[actionId];
            }
        } else {
            if (device.externalActions.hasOwnProperty(actionId)){
                delete device.externalActions[actionId];
            }
        }
        let scene = false;
        if(App.Site.sceneLookup.hasOwnProperty(linkID)){
            scene = App.Site.scenes[App.Site.sceneLookup[linkID]];
        }else if(App.Site.sceneLookup.hasOwnProperty(action.var_1)){
            scene = App.Site.scenes[App.Site.sceneLookup[action.var_1]];
        }

        //make sure everything exists and then delete proper
        if(scene !== false && scene !== undefined){
            if(actionId in scene.actions){
                delete scene.actions[actionId];
            }else if(actionId in scene.triggers){
                delete scene.triggers[actionId];
            }
        }
    },
    async checkInternalActions(devices){
        for(let id in devices){
            if(id in devices){
                if(devices[id].deviceShouldReportData === true && Object.keys(devices[id].internalActions).length < 2){
                    this.internalActionsCleanup(devices[id], true);
                }
            }
        }
        if(App.Site.root.externalActionsToSave.length > 0){
            let actions = await App.Site.root.post({'data':'['+App.Site.root.externalActionsToSave+']'}, false,  'site_table_edit', 'v2');
            App.Site.root.externalActionsToSave = '';
            Devices.parseActions(actions);
        }
        return devices;
    },
    //constructor for the object that associates groups and ID's of devices.
    DeviceGroup: function (deviceID, groupID) {
        this.deviceID = deviceID;
        this.groupID = groupID;
    },
    //Return the name, and optionally the last 4 of the IP as a string
    GetName: function (appendLastFourDigits) {
        if (appendLastFourDigits)
            return this.name + ' (' + this.ID.substr(-4) + ')';
        else
            return this.name;
    },
    //Handles V 2.0 of mac addresses, and we use interfaceID
    convertLinkLocalAddyToInterfaceID: function (str) {
        //Authors: Sebastien Gouin-Davis + Melvin Aguirre
        //Date: 5/14/2015
        //Can be used as a general bit flipper, but we are using it with respect
        //    to the problem laid in the following link
        //http://en.wikipedia.org/wiki/MAC_address
        //http://ben.akrin.com/?p=1347
        //EX: 001ec0a82c56 --> 0[0]1ec0a82c56 ([target])
        // In this instance want to flip 'the second bit of the first octet of the mac addy'

        let targetByteIndex = 1; //The index in the string that you want to play with
        let targetBitIndex = 2; //The bit of that byte you want to flip

        //Binary expression of the targetByteIndex from the left in the String
        let binaryCharToFlip = parseInt(str.charAt(targetByteIndex), 16).toString(2);

        //Pad with 0s
        while (binaryCharToFlip.length < 4) {
            binaryCharToFlip = '0' + binaryCharToFlip;
        }

        //Get the 2nd bit and flip it (third char from the left)
        let flippedBit = (binaryCharToFlip[targetBitIndex] === '0') ? 1 : 0;

        //Replace the 2nd bit with the flipped bit, parseInt from binary val, then cast to Hex
        let flippedBitChar = parseInt((binaryCharToFlip.substr(0, 2) + flippedBit + binaryCharToFlip.substr(-1).toString()), 2).toString(16);

        //Grab the whole String without the important char, and put the flipped bit in the middle, then put it back together
        return str.substr(0, targetByteIndex) + flippedBitChar + str.substr(targetByteIndex + 1);
    },
    //Converts the mac address of a part to the IP Address that is site Specific That can then be stored in the cloud
    convertMacAddressToIPAddress: function (macAddy) {
        let globalPrefix = '2001:0470:b995:'; //Same value for all amatis parts currently
        let paddedSiteID = (App.activeSiteID.length < 4) ? ('0' + App.activeSiteID) : App.activeSiteID;
        let interfaceID = Devices.convertLinkLocalAddyToInterfaceID(macAddy);
        //If interface ID is missing fffe, add it to the middle
        if (interfaceID.indexOf('fffe') < 0) {
            interfaceID = interfaceID.slice(0, 6) + 'fffe' + interfaceID.substr(6);
        }

        let interfaceIDWithColons = '';

        //Need to add colons to interfaceID
        for (let index = 0; index < interfaceID.length; index++) {
            if (index % 4 === 0) {
                interfaceIDWithColons += ':' + (interfaceID[index]);
            } else {
                interfaceIDWithColons += interfaceID[index];
            }
        }

        //Returns something like '2001:0470:b995:04e3:001e:c0ff:fea8:6dda'
        let ipAddress = globalPrefix + paddedSiteID + interfaceIDWithColons;
        return ipAddress.toLowerCase();
    },
    //Put info for spoofed devices into the cloud
    addSpoofedDeviceToSite: function (deviceInfoObj, addToLoc) {
        let jsonVersion = 'default';
        let tempDeviceInfoObj = {};
        this.mfgDate = '';
        this.partType = '';
        this.swVersion = '';
        this.swBuild = '';
        this.ipAddy = '';
        this.macAddy = '';
        this.mfgDate = '';

        //Add support for future versions, and loosely validate the data format
        if (deviceInfoObj.hasOwnProperty('v1')) {
            jsonVersion = 'v1';

        }
        //need scanned obj,

        switch (jsonVersion) {
        case 'v1':

            tempDeviceInfoObj = deviceInfoObj.v1;
            this.macAddy = tempDeviceInfoObj.mac;
            this.ipAddy = this.convertMacAddressToIPAddress(this.macAddy);
            this.swVersion = tempDeviceInfoObj.sw_ver;
            this.swBuild = tempDeviceInfoObj.sw_build;

            //this.partType = tempDeviceInfoObj.type;
            //RE ISS #465. Temporarily hardcoding this to Tny.
            this.partType = 'Tny';
            this.mfgDate = tempDeviceInfoObj.mfg_date;

            //Verify that the mac address is not too short
            if (this.macAddy.length < 12) {
                App.alert('error', 'Error adding Tiny Device: Device already exists on this site.');
                return;
            }

            for (let deviceIndex = 0; deviceIndex < Devices.list.length; deviceIndex++) {
                if (this.ipAddy === Devices.list[deviceIndex].IP) {
                    App.alert('error', 'Error adding Tiny Device: Device already exists on this site.');
                    return;
                }
            }
            break;

        default:
            App.alert('error', 'Unfortunately this encoded data format is currently not supported');
            return;
        }

        //info for adding tiny to be sent to server
        let requestObj = {
            ip_address: this.ipAddy,
            notes: 'mfg_date ' + this.mfgDate,
            name: this.partType,
            type: this.partType,
            pass_fail: '1', //Force it as a pass for now
            compile_time: this.swBuild,
            version: this.swVersion
        };

        //If we made it this far, save the new Tiny to the cloud
        // let myUrl = Communication.getBaseURL('v2') + '/sites/' + App.activeSiteID + '/devices';
        App.Site.post(requestObj, null, 'devices', 'v2')
        .then((response) => {
            //Add the new device to the device list
            var newDeviceObj = {
                ip_address: response.ip_address,
                id: response.id,
                name: response.name,
                type: response.type,
                version: response.version
            };
            const newDevice = new Device(newDeviceObj);
            Scanner.addDeviceToLocation(newDevice);
        }).catch((error)=>{
            App.alert('error', 'Unfortunately something didn\'t go quite right while creating a new device \n This could be because it already exists at this site');
        });
    },
    VerifyDeviceIsTalking: function (requestedAccepteableAge) {
        let deviceIsTalking = false;

        if (!App.isLiveDataLoaded) return false;

        //Set to 4hrs for now
        let acceptableAge = 14400; //How many seconds a device can not talk and still be considered alive 1800 = 30 mins
        if (this.age < acceptableAge) {
            deviceIsTalking =  true;
        }
        //If devices aren't talking, but we dont expect them to not talk (like battery devices), we return true
        //TODO: This should be a flag for 'fake device' or something instead of hardcoded here...
        if (this.partType === 'Tny' || this.partType === 'Tiny' || this.partType === 'SWITCH-B') {
            deviceIsTalking = true;
        }
        return deviceIsTalking;
    },
    //Figure out if a device is associated with a group referenced by name
    searchGroups(searchString, deviceToCheck){
        for (let group in App.Site.groups){
            if(App.Site.groups[group].name.toLowerCase().includes(searchString)){
                if(deviceToCheck.assocGroups.includes(App.Site.groups[group].id)){
                    return App.Site.groups[group];
                } else {
                    break;
                }
            }
        }
        return false;
    },
    // get all the device groups objects from the cloud and store them via parseAllGroups
    getGroups: function () {
        const SITEID = window.localStorage.getItem('amatis_site_id');
        SDK.init()
            .then(api => api.device_groups.getDeviceGroupsAndDevices({ SITEID }))
            .then(res => Devices.parseAllGroups(res.body.data))
            .catch(err => App.alert('error', 'Error getting groups from server', err.response.body.errors[0]));
    },
    //loop through the deviceGroupObj and when the device matches a group, add that group information to the corresponding device object
    parseAllGroups: function (deviceGroupObj) {
        //Temporary solution to race condition with async calls. should just change where this is called
        if (Object.keys(App.Site.devices).length < 1) {
            //Note at a site with no devices this will run every 500ms
            setTimeout(function () {
                Devices.parseAllGroups(deviceGroupObj);
            }, 500);
        }
        let devicesNotFound = [];
        for (let group of deviceGroupObj) {
            if(App.Site.deviceLookup.hasOwnProperty(group.device_id)){
                const deviceID = App.Site.deviceLookup[group.device_id];
                const activeDevice = App.Site.devices[deviceID];
                activeDevice.assocGroups.push(group.device_groups_id);
                activeDevice.assocDevicesGroups.push(group.devices_device_groups_id);
            }else {
                devicesNotFound.push(group.device_id);

                continue;
            }
        }
        if(devicesNotFound.length > 0){
            // console.log('Couldnt find devices associated with groups:', devicesNotFound);
        }

    },
    //DISPLAYS ALL GROUPS THAT THE ACTIVE DEVICE IS NOT CURRENTLY ASSOCIATED WITH
    //ONLY VISIBLE IN THE GROUP MODAL
    //TODO: Move this
    showGroupOptions: function (groupDOMEl) {
        let groupsLength = Groups.list.length;
        let active = Devices.list[Devices.activeDeviceIndex];
        let i;
        let groupListHtml = '';

        for (i = 0; i < groupsLength; i++) {

            if (active.assocGroups.indexOf(Groups.list[i].ID) < 0 && ($('#modal-group-display #group-select-' + Groups.list[i].ID).length === 0)) {
                groupListHtml += '<li id = \'group-select-' + Groups.list[i].ID + '\' class=\'group-select-option\' data-group-id=\'' + Groups.list[i].ID + '\'><span class=\'group-name-display\'>' + Groups.list[i].name + '</span><i class=\'group-option-delete-button icon-minus-sign\'></i></li>';
            }

        }
        $('#modal-group-display').append(groupListHtml);

    },

    findAssocScenes: function () {
        let i;
        let lastlinkID = '';
        let assocScenesCount = 0;
        //If there are any external actions configured, we look through them
        if (this.externalActions.length !== 0) {
            for (i = 0; i < this.externalActions.length; i++) {

                //Skip over deleted scenes
                if (this.externalActions[i].actionTableName === 'deleted') {
                    continue;
                } //These two mean that the actions are trigger actions
                else if (this.externalActions[i].actionTableName === 'coap_send_action(linkID)') {
                    //we sort of look for duplicates i guess?
                    if (this.externalActions[i].var1 !== lastlinkID) {
                        //Push the linkID to the list of assocScenes for this device
                        this.assocScenes[assocScenesCount] = this.externalActions[i].var1;
                        lastlinkID = this.externalActions[i].var1;
                        assocScenesCount++;
                    }
                } else if (this.externalActions[i].actionTableName === 'MAC Action(0xLinkID, 0xMAC)') {
                    // // 0x123 0204A38DF4D8
                    if (this.partType !== 'Tny' && this.partType !== 'Tiny') {
                        let hexVar1 = decToHex(this.externalActions[i].var1);
                        // let deviceMacAddy = hexVar1.substr(-12).toUpperCase(); //Last 12 Chars is the mac
                        let hexLinkID = hexVar1.slice(0, -12); //the rest is the linkID
                        let linkID = parseInt(hexLinkID, 10);

                        if (linkID !== lastlinkID) {
                            this.assocScenes[assocScenesCount] = linkID;
                            lastlinkID = linkID;
                            assocScenesCount++;
                        }

                    } else if (this.externalActions[i].var1 !== lastlinkID) {
                        this.assocScenes[assocScenesCount] = this.externalActions[i].var1;
                        lastlinkID = this.externalActions[i].var1;
                        assocScenesCount++;
                    }
                } else {
                    if (this.externalActions[i].linkID !== lastlinkID) {
                        this.assocScenes[assocScenesCount] = this.externalActions[i].linkID;
                        lastlinkID = this.externalActions[i].var1;
                        assocScenesCount++;
                    }
                }
            }
        } else {
            this.scenesFlag = false;
        }
        return this.assocScenes;
    },
    //set the .location attribute of device then refreshes the page to hide located devices
    tagWithLocation: function (deviceIndex, locationIndex, justLocal = false) {

        Devices.list[deviceIndex].location_id = Locations.list[locationIndex].ID;
        Devices.list[deviceIndex].location = Locations.list[locationIndex].path;
        Devices.list[deviceIndex].located = true;

        //This will refresh the assoc arrays for the location.
        Locations.calculateIfOccupied(Locations.list[locationIndex].ID);

        if (!justLocal) //save it to the cloud
            this.tagDeviceWithLocationInCloud(deviceIndex, Locations.list[locationIndex]);

    },
    convertScannerIPToValidID(IP){
        for (var deviceIndex = Devices.list.length - 1; deviceIndex >= 0; deviceIndex--) {
            let deviceListIP = Devices.list[deviceIndex].ip_address.substr(-7).replace(':','');
            let searchIP = IP.substr(-6).toLowerCase();
            if (deviceListIP === searchIP) {
                return Devices.list[deviceIndex].ID;
            }
        }
        return -1;
    },
    tagDeviceWithLocationInCloud: function (deviceIndex, loc, notes) {
        const myUrl = Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/devices/' + Devices.list[deviceIndex].IP;

        const request = {
            'location_id': loc.ID
        };

        $.ajax({
            type: 'PATCH',
            dataType: 'json',
            data: request,
            beforeSend: function (x) {
                if (x && x.overrideMimeType) {
                    x.overrideMimeType('application/json;charset=UTF-8');
                }
            },
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
            url: myUrl,

            success: function (response) {

            },
            error: function (response) {
                App.alert('error', 'There was a problem tagging this device with this location. ' + JSON.stringify(response));
            },
        });
    },

    getIndex: function (searchParam, paramType, shortID = false) {
        if (shortID) {
            for (let deviceIndex = Devices.list.length - 1; deviceIndex >= 0; deviceIndex--) {
                if (Devices.list[deviceIndex].ID.substr(-8) === searchParam.substr(-8)) {
                    return deviceIndex;
                }
            }
        }
        switch (paramType) {
        case 'name':
            for (let deviceIndex = Devices.list.length - 1; deviceIndex >= 0; deviceIndex--) {
                if (Devices.list[deviceIndex].name === searchParam)
                    return deviceIndex;
            }
            break;
        case 'ip':
            for (let deviceIndex = Devices.list.length - 1; deviceIndex >= 0; deviceIndex--) {
                if (Devices.list[deviceIndex].IP === searchParam)
                    return deviceIndex;
            }
            break;
        default:
            for (let deviceIndex = Devices.list.length - 1; deviceIndex >= 0; deviceIndex--) {
                if (Devices.list[deviceIndex].IP === searchParam)
                    return deviceIndex;
                if (Devices.list[deviceIndex].ID === searchParam)
                    return deviceIndex;
            }
            break;
        }
        //if not found
        return -1;
    },

    writeSingleSetpoint: function (deviceIndex, setpointToChange, newValue, paramObj, fromTerminal=false) {
        //this is so it doesn't fire twice on change/ with enter press
        let active = Devices.list[deviceIndex];
        if (!fromTerminal) {
            if (Devices.timer > 0) {
                return;
            }
            Devices.timer++;
        } else {
            active = deviceIndex;
        }

        let failedOnce = false; // eslint-disable-line
        let myUrl = Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/devices/' + active.ip_address+ '/setpoint';
        if(!fromTerminal){
            $('#setpoint-readout').html('Setting ' + active.name + ' IDX:' + setpointToChange + ' to VAL:' + newValue + '...').fadeIn();
        }

        $('#get-data-icon').addClass('icon-spin');
        write(myUrl);

        function write(myUrl, failedOnce) { //Asynchronous, so need checkIfAllSPsWritten to keep track of success
            let request = {
                'idx': setpointToChange,
                'value': newValue,
            };

            $.ajax({
                type: 'POST',
                data: request,
                datatype: 'json',
                url: myUrl,
                headers: {
                    Authorization: 'Bearer ' + User.apiToken,
                },
                success: function (response) {
                    let respObj;
                    if(typeof(response) === 'object'){
                        respObj = response;
                    }else{
                      respObj = JSON.parse(response);
                    }
                    //console.log('RESPONSE FROM ' + active.name + ' (' + active.IP.substr(-4) + '):' + JSON.stringify(response.data));
                    if (respObj.errors.length < 1) {
                        try {

                            if (fromTerminal) {
                                if (paramObj)
                                    Terminal.print('<span class="green">Successfully set: ' + active.getName(true) + ' id:' + setpointToChange + ' to val:' + newValue + '</span>', paramObj.placement, paramObj.multiple, paramObj.count);
                                else {

                                    Terminal.print('<span class="green">Successfully set: ' + active.getName(true) + ' id:' + setpointToChange + ' to val:' + newValue + '</span>', Terminal.commandCount, true, 1, false);

                                }
                            } else {
                                $('#setpoint-readout').html('Successfully set: ' + active.name + ' id:' + setpointToChange + ' to val:' + newValue);
                                $('#device-dp-input' + setpointToChange).val(newValue);
                                $('.getData' + deviceIndex + setpointToChange).css('background-color', '#05c829');
                                setTimeout(function () {
                                    $('.getData' + deviceIndex + setpointToChange).css('background-color', 'white');
                                }, 2000);
                            }
                            //update this datapoint with the response from the device

                            App.Site.update_deviceData(response.data, active.IP);

                        } catch (e) {
                            console.log(e);
                        } //debug}
                    } else {
                        console.log(active.ID + ' ' + active.name + setpointToChange + ' was NOT saved');
                        //if(!failedOnce) //try twice if failed.
                        //write(myUrl);
                        //failedOnce = true;
                        if (fromTerminal) {
                            if (paramObj)
                                Terminal.print('<span class="red">Unable to communicate with device. There was an error setting: ' + active.getName(true) + ' id:' + setpointToChange + ' to val:' + newValue + '</span>', paramObj.placement, paramObj.multiple, paramObj.count);
                            else
                                Terminal.print('<span class="red">Unable to communicate with device. There was an error setting: ' + active.getName(true) + ' id:' + setpointToChange + ' to val:' + newValue + '</span>', Terminal.commandCount, true, 1, false);

                        } else {
                            App.alert('error', 'Unable to communicate with device');
                        }
                    }
                },
                error: function (request, status, error) {
                    if (fromTerminal) {
                        if (paramObj)
                            Terminal.print('<span class="red">Unable to communicate with device. There was an error setting: ' + active.getName(true) + ' id:' + setpointToChange + ' to val:' + newValue + '</span>', paramObj.placement, paramObj.multiple, paramObj.count);
                        else {
                            Terminal.print('<span class="red">Unable to communicate with device. There was an error setting: ' + active.getName(true) + ' id:' + setpointToChange + ' to val:' + newValue + '</span>', Terminal.commandCount, true, 1, false);

                        }
                    }
                    //Error('writeSingleSetpoint',request.status,request.responseText);
                    //alert(request.responseText);
                },
                complete: function (response) {
                    if(!fromTerminal){
                        //resets the onchange function just in case you want to use that way
                        $('.getData' + deviceIndex + '' + setpointToChange).attr('onchange', 'Devices.writeSingleSetpoint(' + deviceIndex + ',' + setpointToChange + ', this.value)');
                        if ($('#get-data-icon').hasClass('icon-spin'))
                            $('#get-data-icon').removeClass('icon-spin');
                        $('#device-dp-input-' + setpointToChange).css('background-color', '#05c829');
                        setTimeout(function () {
                            $('#device-dp-input-' + setpointToChange).css('background-color', 'white');
                        }, 2000);
                    }
                }
            });
        }

        setTimeout(function () {
            Devices.timer = 0;
        }, 500);
    },

    writeManySetpoints: function (setpointQueue) {
        //if there atleast 2 setpoints to write fire the first two off concurrently, otherwise just fire the first
        const fire = (params) => {
            const OPTIONS = { requestBody: { ...params, site_id: App.activeSiteID } };
            SDKDevices.init().then(api => {
                    const PARAMS = { device_id: params.device_id };
                    api.devices.writeSetpoint(PARAMS, OPTIONS).then(({ data }) => {
                        try{
                            App.Site.devices[App.Site.deviceLookup[params.device_id]].update(data);
                        }catch(e){
                            console.log('error updating device data', e);
                        }
                        if(setpointQueue.length > 0){
                            fire(setpointQueue.pop());
                        }
                    }).catch(() => {
                        if(setpointQueue.length > 0){
                            fire(setpointQueue.pop());
                        }
                    });
            });
        }
        if(setpointQueue.length >= 2){
            fire(setpointQueue.pop());
            fire(setpointQueue.pop());
        }else{
            fire(setpointQueue.pop());
        }
    },
    //calls ajax function to getData from applicable device
    GetData: function (deviceIndex, force, paramObj, fromTerminal) {
        let activeDevice;
        if (parseInt(deviceIndex, 10) >= 0 && !fromTerminal)
            activeDevice = Devices.list[deviceIndex];
        else if (fromTerminal) {
            activeDevice = deviceIndex;
        } else {
            activeDevice = deviceIndex;
        }
        $('#setpoint-table-readout').show();
        let myUrl = Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/devices/' + activeDevice.IP + '/data';
        let request = {};

        if (force) {

            request['force'] = force;
        } else {
            request['force'] = 5;
        }

        if (paramObj !== undefined) {
            if (paramObj.hasOwnProperty('start')) {
                if (paramObj.start !== undefined) {
                    request['start_idx'] = paramObj.start.trim();
                }
            }
            if (paramObj.hasOwnProperty('stop')) {
                if (paramObj.stop !== undefined) {
                    request['stop_idx'] = paramObj.stop.trim();
                }
            }
        }
        if (!fromTerminal)
            $('#setpoint-readout').html('Getting live data from: ' + activeDevice.getName(true) + '...').fadeIn('fast');
        let startTime = new Date().getTime();

        $.ajax({ //get data request for applicable device
            type: 'GET',
            cache: false,
            data: request,
            dataType: 'json',
            //timeout: 15000, //removing timeout because even though it can take a minute to fail, i don't want this request hanging around
            url: myUrl,
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
            success: function (response) {

                let endTime = new Date().getTime();
                //if(response.data.toLowerCase().indexOf('node not available')<0){
                if (response.errors.length <= 0) {
                    if (fromTerminal) {
                        let dataText = '<span style=\'color:green;\'>OK</span>:' + activeDevice.name + '(' + activeDevice.ID + '){' + (endTime - startTime) / 1000 + 's}';

                        if (paramObj !== undefined) {
                            if (paramObj.hasOwnProperty('refresh')) {
                                if (paramObj.refresh === true) {
                                    Devices.parseGetData(activeDevice, response.data, force, true, true);
                                }

                            } else if (paramObj.showRaw === true) {
                                dataText += JSON.stringify(response.data);
                                dataText = dataText.replace(/},/g, '}<br>').replace(/}\[/g, '}[<br>');
                                Terminal.print(dataText + '<br>', paramObj.placement, paramObj.multiple, paramObj.count);
                            } else {

                                Terminal.print(dataText + Devices.parseGetData(activeDevice, response.data, force, true), paramObj.placement, paramObj.multiple, paramObj.count);
                            }
                        } else {
                            Terminal.print(dataText + Devices.parseGetData(activeDevice, response.data, force, true), Terminal.commandCount, true, 1, false);
                        }

                    } else {
                        $('#setpoint-readout').html('Succesfully refreshed the data for ' + activeDevice.getName(true));
                        Devices.parseGetData(activeDevice, response.data, force);
                    }
                } else {

                    if (fromTerminal) {
                        Terminal.print('<span class=\'red\'>Unable to communicate with ' + activeDevice.getName(true) + '</span>', paramObj.placement, paramObj.multiple, paramObj.count);
                    } else if (!JSON) {
                        $('#setpoint-readout').html('There was a problem communicating with device: ' + activeDevice.getName(true) + '. Please verify that it is powered up.');
                    } else {
                        console.log(response);
                    }
                }

                let errorStr = ''; // eslint-disable-line
                for (let i = 0; i < response.errors.length; i++) {

                    if (response.hasOwnProperty('meta')) {

                        if (response.meta.code === '408' && !fromTerminal) {

                            App.alert('error', 'There was an issue communicating with ' + activeDevice.getName(true), response.errorStr);

                        }
                        errorStr += '[' + response.meta.code + '] ' + response.errors[i] + '\n\n';

                    }

                }

            },
            error: function (request, status, error) {
                let errorString = '';
                if (fromTerminal && request.status === 503) {

                    if (paramObj !== undefined) {
                        Terminal.print('<span class=\'red\'>Unable to communicate with ' + activeDevice.getName(true) + '</span>', paramObj.placement, paramObj.multiple, paramObj.count);
                    } else {
                        Terminal.print('<span class=\'red\'>Unable to communicate with ' + activeDevice.getName(true) + '</span>', Terminal.commandCount, true, 1, false);
                    }

                } else if (request.status === 503) {
                    $('#setpoint-readout').html('<span class=\'red\'>Unable to communicate with ' + activeDevice.getName(true) + '</span>').fadeIn();
                }

                if (request.hasOwnProperty('responseJSON')) {
                    let responseObj = request.responseJSON;

                    if (responseObj.hasOwnProperty('errors') && responseObj.errors.length > 0) {
                        for (let i = 0; i < responseObj.errors.length; i++) {
                            errorString += responseObj.errors[i];
                        }
                    }

                    if (errorString !== '')

                        App.alert('error', errorString, error);
                }

                App.alert('error', 'Unable to communicate with ' + activeDevice.IP.substring(35) + ' ' + activeDevice.name, error);

            },
            complete: function () {

                $('#get-data-icon').removeClass('icon-spin');
                $('#get-data-icon-2').removeClass('icon-spin');

                $('#advanced-get-live-data .remove-after').remove();
                $('#advanced-get-logged-data .remove-after').remove();

            }
        });

    },

    //calls ajax function to getData from applicable device
    GetDatapoints: function (deviceIndex, start, stop, refreshRSSI) {
        let activeDevice;

        if (!deviceIndex){
            activeDevice = this;
        } else{
            activeDevice = Devices.list[deviceIndex];
        }

        let myUrl = Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/devices/' + activeDevice.IP + '/data';

        let request = {
            'force': 1, //this should become dynamic
            'start_idx': start
        };
        //Optional Param to get a couple of datapoints back
        if (stop)
            request['stop_idx'] = stop;

        $.ajax({ //get data request for applicable device
            type: 'GET',
            dataType: 'json',
            data: request,
            timeout: 15000,
            url: myUrl,
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
            success: function (response) {
                console.log(response);
                if (response.errors.length < 1) {
                    if (refreshRSSI && refreshRSSI === 'refreshRSSIList') {
                        let responseLen = response.data.length;

                        //Older code returns the whole dd so we check that we only got one point back
                        if (responseLen < 2) {
                            activeDevice.rssi = parseFloat(response.data[0].cval);
                        } else {
                            //Else we find the idx we were looking for
                            for (let dpIndex = 0; dpIndex < responseLen; dpIndex++) {
                                if (response.data[dpIndex].idx === start)
                                    activeDevice.rssi = parseFloat(response.data[dpIndex].cval);
                            }
                        }

                        let signalString = activeDevice.determineSignalStrength(true);
                        //get rid of any previous ones
                        $('#rssi-display-' + deviceIndex).remove();
                        //Display the actual RSSI value
                        $('#rssi-' + deviceIndex).append('<span id="rssi-display-' + deviceIndex + '" class="" style="display:none; position:absolute; right:6px; top:-6px;">' + activeDevice.rssi + '&nbsp;</span>');
                        $('#rssi-' + deviceIndex).removeClass('icon-spin icon-refresh').addClass(signalString).fadeIn();
                        $('#rssi-display-' + deviceIndex).fadeIn();
                        //Fade RSSI readout away after 5s
                        setTimeout(function () {
                            $('#rssi-display-' + deviceIndex).fadeOut('slow');
                        }, 5000);
                    } else if (refreshRSSI && refreshRSSI === 'refreshRSSIMain') {
                        activeDevice.rssi = parseFloat(response.data[0].cval);

                        let signalString = '<i class=" ' + activeDevice.determineSignalStrength(true) + '"></i><span id="main-rssi-display-' + deviceIndex + '" class="" style="display:none; padding:5px;">' + activeDevice.rssi + '&nbsp;</span>';
                        //get rid of any previous ones
                        $('#main-rssi-display-' + deviceIndex).remove();
                        //Display the actual RSSI value
                        //$('#device-' + deviceIndex).append('<span id="rssi-display-' + deviceIndex + '" class="" style="display:none; position:absolute; right:25px; top:7px;">' + activeDevice.rssi + '&nbsp;</span>');
                        $('#main-rssi-' + deviceIndex).hide().html(signalString).fadeIn();
                        $('#main-rssi-display-' + deviceIndex).fadeIn();
                        //Fade RSSI readout away after 5s
                        setTimeout(function () {
                            $('#main-rssi-display-' + deviceIndex).fadeOut('slow');
                        }, 5000);
                    }
                } else {
                    //Display warning icon so that user knows we failed
                    $('#rssi-' + deviceIndex).removeClass('icon-spin icon-refresh').addClass('icon-warning-sign').fadeIn();

                    if (!JSON) {
                        Terminal.print('There was a problem communicating with device: ' + activeDevice.ID + ' ' + activeDevice.name + '. Please verify that it is powered up.', Terminal.commandCount);

                    } else {
                        console.log(response);
                    }
                }
            },
            error: function (request, status, error) {
                App.alert('error', 'There was a problem communicating with device: ' + activeDevice.ID + ' ' + activeDevice.name + '. Please verify that it is powered up.', error);
                //Display warning icon so that user knows we failed
                $('#rssi-' + deviceIndex).removeClass('icon-spin icon-refresh').addClass('icon-warning-sign').fadeIn();
            },
            complete: function () {
                if ($('#get-data-icon').hasClass('icon-spin'))
                    $('#get-data-icon').removeClass('icon-spin');

                if ($('#get-device-rssi-icon').hasClass('icon-spin'))
                    $('#get-device-rssi-icon').removeClass('icon-refresh icon-spin').addClass(activeDevice.determineSignalStrength(true));

                $('#setpoint-readout').html('');
                $('#adv-functions').removeClass('icon-refresh icon-spin');
            }
        });
    },

    //Used to set daylight target in daylight sensors
    SetTargetLightLevel: function (deviceIndex) {
        let activeDevice;

        if (!deviceIndex){
            activeDevice = this;
        }
        else{
            activeDevice = Devices.list[deviceIndex];
        }
        let myUrl = Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/devices/' + activeDevice.IP + '/set_target_light_level';
        $.ajax({
            type: 'POST',
            url: myUrl,
            dataType: 'json',
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
            success: function (response) {
                if (App.hasOwnProperty('ActionOverlay')) {
                    App.ActionOverlay.hide();
                }
                console.log(response);
                if (response.errors.length <= 0) {
                    App.alert('success', 'Successfully set Daylight Harvesting target to current measured level');
                } else {
                    //TODO: HANDLE VPN CASE
                    App.alert('error', 'We were unable to communicate with this device. Please verify that it is powered up and within range.', response.errors);
                }
            },
            error: function (request, status, error) {
                console.log(request);
            },
            complete: function () {

            }
        });
    },

    Ota: function (deviceArry, custom, file, channel, override) {
        let devicesToOTA;

        if (!deviceArry) {
            devicesToOTA = [this];
        } else {
            devicesToOTA = deviceArry;
        }
        let myString = '';


        $('#setpoint-table-readout').show();

        OTA();

        function OTA() {
            if (devicesToOTA.length < 1)
                return;

            $(function () {
                let xhr = new XMLHttpRequest();

                xhr.onreadystatechange = function () { //Call a function when the state changes.
                    //when complete
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        let errorIndex = xhr.responseText.toLowerCase().indexOf('error');
                        if (errorIndex >= 0) {
                            if (xhr.responseText.toLowerCase().indexOf('current code') >= 0) {
                                $('#setpoint-table-readout').html('<span class="green">This device already has the latest code in it</span>');
                            } else if (xhr.responseText.toLowerCase().indexOf('error: part type miss match. will exit') >= 0) {
                                $('#setpoint-table-readout').html('<span class="red">Current part type and the type of the new files dont match<br>Click the overide for this check in the custom OTA form</span>');
                            } else if (xhr.responseText.toLowerCase().indexOf('error: node already on channel') >= 0) {
                                App.confirmModal('This device is already on Channel ' + channel + '. <br><br> Press OK to OTA anyway', function (result) {
                                    if (result) {
                                        //alert(xhr.responseText.substr(errorIndex));
                                        $('#OTA-' + Devices.list[Devices.activeDeviceIndex].ID).css('color', 'red');
                                        $('#otaStuff').remove();
                                        $('#log').remove();
                                        $('#update').remove();
                                        $('#advanced-update-software .remove-after').remove();
                                        Devices.list[Devices.activeDeviceIndex].ota(false, true, null, channel, true); //force the OTA
                                    }
                                });
                            } else {
                                $('#setpoint-table-readout').html('<span class="red">There was a problem communicating with this device.</span>');
                            }

                            //alert(xhr.responseText.substr(errorIndex));
                            $('#OTA-' + devicesToOTA[0].ID).css('color', 'red');
                            $('#otaStuff').remove();
                            $('#log').remove();
                            $('#update').remove();
                            $('#advanced-update-software .remove-after').remove();

                            //printError(xhr.responseText.substr(errorIndex));
                        } else {
                            $('#setpoint-table-readout').html('<span class="green">Successfully updated the software for this device to the latest version.  It will take at least 15 seconds for the device to reboot. Then the device will need to have its scenes saved back to it.</span>');
                            /*$('#OTA-' + devicesToOTA[0].ID).css('color','green');*/
                            $('#otaStuff').remove();
                            $('#log').remove();
                            $('#update').remove();

                            //For custom OTAs we want to make it obvious to the user that scenes need to be saved back in
                            if (custom) {
                                Devices.list[Devices.activeDeviceIndex].unsaved = true;
                                Devices.calcUnsavedForSyncDisplay();
                            }
                        }
                        //remove the device we just did from the list of devices to do
                        devicesToOTA.shift();
                        OTA();
                    }
                    //stream data
                    else if (xhr.readyState === 3) {
                        let active = xhr.responseText;
                        let changedText = active.substr(myString.length); //the new stuff
                        $('#otaStuff').html(changedText); //we put the script tag it returns in this div
                        myString = active;
                        $('#advanced-update-software .remove-after').remove();
                    }
                };

                //make a div to put script tag stuff in
                $('#setpoint-table-readout').html('<div id="otaStuff"></div>');
                $('#setpoint-table-readout').append('<div id="log"></div>');
                $('#setpoint-table-readout').append('<div id="update_' + devicesToOTA[0].IP.substr(-4) + '"></div>');
                $('#setpoint-table-readout').prepend('<span id=\'OTA-' + devicesToOTA[0] + '\'>OTAing: ' + devicesToOTA[0].IP + '</span><i id=\'cancel-ota\' class=\'icon-remove\'></i>');

                //TODO: Append a cancel button and make sure listener works
                $('#cancel-ota').on('click', function () {
                    xhr.abort();
                    $('#adv-functions').removeClass('icon-refresh icon-spin');
                    $('#setpoint-table-readout').html('<span class="red">OTA Cancelled</span>');
                    $('#OTA-' + devicesToOTA[0].ID).css('color', 'red');
                    $('#otaStuff').remove();
                    $('#log').remove();
                    $('#update').remove();
                    $('#advanced-update-software .remove-after').remove();

                });

                if (!custom || channel) { //go to the latest version
                    let url = 'https://dash.amatiscontrols.com/OTA/OTA-File.php';
                    let fd = new FormData();

                    fd.append('CloudBin', 1);
                    fd.append('ip', devicesToOTA[0].IP);
                    fd.append('clean', 1);

                    if (channel) {
                        fd.append('new_channel', channel);
                    }

                    if (override) {
                        fd.append('override', 1);
                    }

                    xhr.open('POST', url, true);
                    //Send the proper header information along with the request
                    //xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                    //xhr.setRequestHeader("Content-length", params.length);
                    //xhr.setRequestHeader("Connection", "close");
                    xhr.send(fd);
                } else {
                    if (file) {
                        let url = 'https://dash.amatiscontrols.com/OTA/OTA-File.php';
                        let fd = new FormData();

                        fd.append('ufile', file);
                        fd.append('ip', devicesToOTA[0].IP);
                        fd.append('clean', 1);

                        if (document.getElementById('do-part-check').checked) {
                            fd.append('FastNScary', 1);
                        }

                        xhr.open('POST', url, true);
                        xhr.send(fd);

                    }

                }

            });
        }
    },

    RebootMCBA: function (deviceIndex) {
        let activeDevice;

        if (!deviceIndex){
            activeDevice = this;
        }else{
            activeDevice = Devices.list[deviceIndex];
        }

        let valueToSend = '210'; //internal linkid for identify for all devices

        let myUrl = Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/devices/' + activeDevice.IP + '/simulate_scene';

        //Make the get-data thing spin
        $('#setpoint-readout').html('Will reboot the MCBA for PHD ' + activeDevice.name + '...').fadeIn('fast');

        let request = {
            'link_id': valueToSend
        };

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: myUrl,
            data: request,
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },

            success: function (response) {

                if (response.errors.length <= 0) {

                    //Stop the spinner
                    $('#setpoint-readout').html('<span class=\'green\'>Device is rebooting. Please allow 15s for device to reboot.</span>').fadeIn();
                } else {
                    App.alert('error', 'We were unable to communicate with this device. Please verify that it is powered up and within range.', response.errors);
                    $('#setpoint-readout').html('Unable to communicate with device').fadeIn('fast');

                    console.log(response);
                }
            },
            error: function (request, status, error) {
                $('#advanced-mcba-reboot').removeClass('icon-spin');
                App.alert('error', 'We were unable to communicate with this device. Please verify that it is powered up and within range.', error);
                console.log(request);
            },
            complete: function () {
                $('#advanced-mcba-reboot .remove-after').remove();
                $('#adv-functions').remove();
            }
        });
    },

    ResetMCBAEnergies: function (deviceIndex) {
        let activeDevice;

        if (!deviceIndex){
            activeDevice = this;
        } else{
            activeDevice = Devices.list[deviceIndex];
        }


        let valueToSend = '212'; //internal linkid for identify for all devices

        let myUrl = Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/devices/' + activeDevice.IP + '/simulate_scene';

        //Make the get-data thing spin
        $('#get-data-icon').addClass('icon-spin');
        $('#setpoint-readout').html('Will rest all energy totalizers for PHD ' + activeDevice.getName(true) + '...').fadeIn('fast');

        let request = {
            'link_id': valueToSend
        };

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: myUrl,
            data: request,
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },

            success: function (response) {

                if (response.errors.length <= 0) {

                    //Stop the spinner
                    $('#get-data-icon').removeClass('icon-spin');
                    $('#setpoint-readout').html('<span class=\'green\'>All energy totalizers for this device have been reset</span>').fadeIn();
                } else {
                    App.alert('error', 'We were unable to communicate with this device. Please verify that it is powered up and within range.', response.errors);
                    $('#setpoint-readout').html('Unable to communicate with device').fadeIn('fast');

                    console.log(response);
                }
            },
            error: function (request, status, error) {
                $('#advanced-mcbae-reset').removeClass('icon-spin');
                App.alert('error', 'We were unable to communicate with this device. Please verify that it is powered up and within range.', error);
                console.log(request);
                //Error('ident',request.status);
            },
            complete: function () {

                $('#advanced-mcba-e-reset .remove-after').remove();
                $('#adv-functions').remove();
            }
        });
    },

    GetKeys: function (doUploadToo, paramObj, fromTerminal) {
        let doSaveToo = false;

        if (paramObj === true){
            doSaveToo = true;
        }

        let activeDevice = this;

        let myUrl = Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/devices/' + activeDevice.IP + '/function_list';
        let request = {
            'update_db': 1
        };
        let startTime = new Date().getTime();

        $('#setpoint-readout').html('Getting skills table from ' + activeDevice.getName(true) + '...').fadeIn('fast');

        $.ajax({ //get data request for applicable device
            type: 'GET',
            cache: false,
            dataType: 'json',
            data: request,
            url: myUrl,
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
            success: function (response) {
                if (!doUploadToo)
                    $('#advanced-get-action-keys .remove-after').remove();

                if (response.errors.length <= 0) {
                    let elapsed = (new Date().getTime() - startTime) / 1000;
                    if (fromTerminal) {
                        if (fromTerminal === 'ota') {
                            $('.getKeys-device-' + activeDevice.ID).addClass('green');
                            return;
                        }
                        if (paramObj)
                            Terminal.print('<span class="green">Got skills table from ' + activeDevice.name + ' (' + activeDevice.IP.substr(-4) + ') to the cloud in ' + elapsed.toFixed(1) + ' seconds</span>', paramObj.placement, paramObj.multiple, paramObj.count);
                        else {
                            Terminal.print('<span class="green">Got skills table from ' + activeDevice.name + ' (' + activeDevice.IP.substr(-4) + ') to the cloud in ' + elapsed.toFixed(1) + ' seconds</span>', Terminal.commandCount, true, 1, false);

                        }
                    } else {
                        $('#setpoint-readout').html('<span class="green">Got skills table from ' + activeDevice.name + ' (' + activeDevice.IP.substr(-4) + ') to the cloud in ' + elapsed.toFixed(1) + ' seconds</span><br>').fadeIn();
                        // 					alert('Got keys from ' + activeDevice.name + " (" + Devices.list[deviceIndex].IP.substr(-4) + ') to the cloud in ' + elapsed + ' seconds')
                        if (doUploadToo) {
                            activeDevice.upload();
                        } else if (doSaveToo) {
                            activeDevice.save('fromKeys');
                        } else {
                            $('#advanced-get-action-keys .remove-after').remove();
                        }
                    }

                } else {
                    console.log('UNABLE to get skills from ' + activeDevice.name + ' (' + activeDevice.IP.substr(-4) + ') to the cloud. Because of the following:');
                    if (fromTerminal) {
                        $('.getKeys-device-' + activeDevice.ID).addClass('red');
                        if (paramObj)
                            Terminal.print('<span class=\'red\'>Unable to communicate with ' + activeDevice.getName(true) + '. Please ensure that it is powered up and the blue light is on.</span>', paramObj.placement, paramObj.multiple, paramObj.count);
                        else
                            Terminal.print('<span class=\'red\'>Unable to communicate with ' + activeDevice.getName(true) + '. Please ensure that it is powered up and the blue light is on.</span>', Terminal.commandCount, true, 1, false);

                    } else {
                        $('#setpoint-readout').html('<span class=\'red\'>Unable to communicate with ' + activeDevice.getName(true) + '. Please ensure that it is powered up and the blue light is on.</span>').fadeIn();
                    }
                    let errorStr = '';
                    for (let i = 0; i < response.errors.length; i++) {
                        if (response.hasOwnProperty('meta')) {

                            if (response.meta.code === '408') {

                                App.alert('error', 'There was an issue communicating with ' + activeDevice.getName(true));
                                $('#unsaved-display-' + Devices.getIndex(activeDevice.IP)).html('<i class="icon-warning-sign"></i>');

                            }
                            errorStr += '[' + response.meta.code + '] ' + response.errors[i] + '\n\n';

                        }

                    }

                    console.log(errorStr);
                }
            },
            error: function (request, status, error) {
                if (fromTerminal) {
                    $('.getKeys-device-' + activeDevice.ID).addClass('red');
                    if (paramObj)
                        Terminal.print('<span class=\'red\'>Unable to communicate with ' + activeDevice.getName(true) + '. Please ensure that it is powered up and the blue light is on.</span>', paramObj.placement, paramObj.multiple, paramObj.count);
                    else
                        Terminal.print('<span class=\'red\'>Unable to communicate with ' + activeDevice.getName(true) + '. Please ensure that it is powered up and the blue light is on.</span>', Terminal.commandCount, true, 1, false);

                } else if (request.status === 503) {

                    $('#setpoint-readout').html('<span class=\'red\'>Unable to communicate with ' + activeDevice.getName(true) + '</span>').fadeIn();
                }

                if (request.hasOwnProperty('responseJSON')) {
                    let responseObj = request.responseJSON;
                    let errorString = '';

                    if (responseObj.hasOwnProperty('errors') && responseObj.errors.length > 0) {
                        for (let i = 0; i < responseObj.errors.length; i++) {
                            errorString += responseObj.errors[i];
                        }
                    }

                    if (errorString !== '')

                        Terminal.print('<span class=\'red\'>' + errorString + '</span>', Terminal.commandCount);

                }

                Error('getKeys', request.status, 'No network connection. Please verify that you are connected to the internet.');
            },
            complete: function () {
                $('#advanced-get-action-keys .remove-after').remove();
                $('#advanced-sync-device-actions .remove-after').remove();

            }
        });
    },

    Upload: function (paramObj, fromTerminal) {

        let activeDevice = this;
        let myUrl = Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/devices/' + activeDevice.IP + '/table_list';
        let startTime = new Date().getTime();
        let request = {
            'update_db': 1
        };

        $('#adv-functions').addClass('icon-refresh icon-spin').removeClass('icon-info-sign');
        if (fromTerminal) {

        } else {
            $('#setpoint-readout').html('<span class=\'\'>Getting action table from ' + activeDevice.name + '</span>');

        }

        $.ajax({
            type: 'GET',
            cache: false,
            dataType: 'json',
            data: request,
            url: myUrl,
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
            success: function (response) {
                $('#advanced-sync-device-actions .remove-after').fadeOut('fast').remove();
                $('#advanced-upload-action-keys .remove-after').fadeOut('fast').remove();

                if (response.errors.length < 1) {
                    let actionTable = [];
                    let actionRowObj = {};
                    let elapsed = (new Date().getTime() - startTime) / 1000;

                    //Parse the table that comes back to make it match the old school insanity (for now)
                    let len = response.data.length;
                    for (let index = 0; index < len; index++) {
                        let active = response.data[index];

                        actionRowObj = {
                            nodeId: active.ip_address.toString(), //They all come in from the cloud as strings, so im matching that for now
                            tableRow: active.table_row.toString(),
                            linkID: active.link_id.toString(),
                            flags: active.flag.toString(),
                            flagMask: active.flag_mask.toString(),
                            var1: active.var_1.toString(),
                            actionFunction: active.address.toString(),
                            actionTableName: active.name.toString()
                        };

                        actionTable.push(actionRowObj);
                    }
                    console.log(activeDevice.internalActions); //debug
                    //Set the internal actions of this device to the new actions we just got back
                    activeDevice.internalActions = actionTable;

                    if (fromTerminal) {
                        let printStr = '<span class=\'green\'>' + activeDevice.name + '(' + activeDevice.IP.substr(-4) + ') is now in sync with the cloud.</span><br>';
                        printStr += 'Uploaded the action table from ' + activeDevice.name + ' (' + activeDevice.IP.substr(-4) + ') in ' + elapsed.toFixed(1) + ' seconds';
                        if (paramObj !== undefined) {

                            Terminal.print(printStr, paramObj.placement, paramObj.multiple, paramObj.count);
                        } else {
                            Terminal.print(printStr, paramObj.placement, Terminal.commandCount, true, 1, false);

                        }
                    } else {

                        $('#setpoint-readout').html('<span class=\'green\'>Uploaded the action table from ' + activeDevice.name + ' (' + activeDevice.IP.substr(-4) + ') in ' + elapsed.toFixed(1) + ' seconds</span>');

                    }

                } else {
                    if (fromTerminal) {
                        if (paramObj !== undefined) {
                            Terminal.print('<span class=\'red\'>Unable to communicate with ' + activeDevice.IP.substring(35) + activeDevice.getName(true), paramObj.placement, paramObj.multiple, paramObj.count);
                        } else {
                            Terminal.print('<span class=\'red\'>Unable to communicate with ' + activeDevice.IP.substring(35) + activeDevice.getName(true), Terminal.commandCount, true, 1, false);

                        }
                    } else {
                        $('#setpoint-readout').html('<span class=\'red\'>Unable to upload action table from ' + activeDevice.name + '</span>');
                    }

                    let errorStr = '';
                    for (let i = 0; i < response.errors.length; i++) {
                        if (response.hasOwnProperty('meta')) {

                            if (response.meta.code === '408') {

                                App.alert('error', 'There was an issue communicating with ' + activeDevice.getName(true));

                            }
                            errorStr += '[' + response.meta.code + '] ' + response.errors[i] + '\n\n';

                        }

                    }

                    console.log(errorStr);
                    console.log('UNABLE to get the action table from ' + activeDevice.name + ' (' + activeDevice.IP.substr(-4) + ') to the cloud. Because of the following:');
                    console.log(response);
                }
            },
            error: function (request, status, error) {

                $('#setpoint-readout').html('<span class=\'red\'>Unable to upload action table from ' + activeDevice.name + '</span>');
                console.log('UNABLE to get the action table from ' + activeDevice.name + ' (' + activeDevice.IP.substr(-4) + ') to the cloud. Because of the following:');
                console.log(request);

                if (request.status === 503) {
                    if (fromTerminal) {
                        if (paramObj !== undefined) {
                            Terminal.print('<span class=\'red\'>Unable to communicate with ' + activeDevice.IP.substring(35) + activeDevice.getName(true), paramObj.placement, paramObj.multiple, paramObj.count);
                        } else {
                            Terminal.print('<span class=\'red\'>Unable to communicate with ' + activeDevice.IP.substring(35) + activeDevice.getName(true), Terminal.commandCount, true, 1, false);

                        }
                    } else {

                        $('#setpoint-readout').html('<span class=\'red\'>Unable to communicate with ' + activeDevice.getName(true) + '. Please ensure that it is powered up and the blue light is on.</span>');

                    }
                }

                if (request.hasOwnProperty('responseJSON')) {
                    let responseObj = request.responseJSON;
                    let errorString = '';
                    let letsDoIt;

                    if (responseObj.hasOwnProperty('errors') && responseObj.errors.length > 0) {
                        for (let i = 0; i < responseObj.errors.length; i++) {

                            if (responseObj.errors[i] === 'No action keys found in the cloud. Needs /function_list') {
                                /* eslint-disable no-loop-func */
                                App.confirmModal('It looks like you need to upload the skills table for ' + activeDevice.name + ' to the cloud, would you like to do that now and try again?', function (result) {
                                    letsDoIt = result;
                                    if (letsDoIt === true) {
                                        activeDevice.getKeys(true);
                                    }

                                });
                                /* eslint-enable no-loop-func */
                                continue;
                            }
                            errorString += responseObj.errors[i];
                        }
                        if (request.status === 400) {
                            if (errorString !== '')
                                App.alert('error', errorString);
                        }
                    }
                }

                //Error('upload',request.status,'No network connection. Please verify that you are connected to the internet.');
            },
            complete: function () {
                $('#advanced-sync-device-actions .remove-after').remove();
                $('#advanced-upload-action-keys .remove-after').remove();

                $('.all-features-label i').removeClass('icon-spin');

            }
        });
    },
    switch:{
        getLinkIDFromVar1(var1){
            let linkID = parseInt(decToHex(var1).slice(0,-12), 16);
            return linkID;
        }
    },
    switch8:{
        sceneToButtonAction(input, name){
            let buttonAction = false;
            switch(name){//only odd for on
                case 'on':
                        if(parseInt(input.output)%2 === 1){
                            buttonAction = 'Button'+input.output+'_Release';
                        }
                        break;
                case 'off'://even for off
                        if(parseInt(input.output)%2 === 0){
                            buttonAction = 'Button'+input.output+'_Release';
                        }
                        break;
                case 'raise-lights':
                    if(parseInt(input.output)%2 === 1){
                        buttonAction = 'Long_Press'+input.output;
                    }
                break;
                case 'lower-lights':
                    if(parseInt(input.output)%2 === 0){
                        buttonAction = 'Long_Press'+input.output;
                    }
                break;
                case 'halt-fade':
                    buttonAction = 'Long_Release'+input.output;
                break;

                default:
                    break;
            }
            return buttonAction;
        },
    },

    //put new devices
    async create(deviceObject=false, fromCloud=false){
        const newDevice = new Device(deviceObject);
        newDevice.updateUIComponents();
        store.dispatch(addDeviceToItems(newDevice));
    },

    //put outputs into object by parent then by location id
    // return: [eachParentID] -> [eachLocID]] -> [outputs]
    orgMcdOutputsByParentByLoc(outputs){
        let mcdOutputs = outputs;
        let multiChannelDevices = {};
        //create object of outputs by parent id so we can create actions per scene
        for(let id in mcdOutputs){
            let output = mcdOutputs[id];
            let locID = output.location_id;
            if(!(output.parent_id in multiChannelDevices)){
                multiChannelDevices[output.parent_id] = {};
            }
            if(!multiChannelDevices[output.parent_id].hasOwnProperty(locID)){
                multiChannelDevices[output.parent_id][locID] = {assocOutputs:{}};
            }
            multiChannelDevices[output.parent_id][locID].assocOutputs[id] = mcdOutputs[id];
        }
        return multiChannelDevices;
    },
    insertMcdOutputsIntoLocScene(scene, location, parent=false){
        if(Object.keys(location.newOutputs).length === 0){
            return;
        }
        let actionForNewMCD = false;
        let existingParentalMCD = new Set();
        let parentsOfNewOutputs = new Set();
        let insertInto = (parent === false ? location : parent);
        for(let id in location.newOutputs){
            let activeParentIP = location.newOutputs[id].parent_device.ip_address;
            if(!(activeParentIP in parentsOfNewOutputs)){
                parentsOfNewOutputs.add(activeParentIP);
            }
        }
        //loop over all the actions and look for multichannel specific actions
        for(let actionId in scene.actions){
            let active = scene.actions[actionId];
            let activeDevice = App.Site.devices[App.Site.deviceLookup[active.ip_address]];
            //check for multichannel device action
            if(activeDevice.isMultiChannel){
                //get duty and fade from the action
                let dutyFade = (scene.description !== 'halt-fade' && scene.description !== 'direct_control');
                let var1Obj = Devices.phd.getDutyFadeFromVar1(active.var_1);
                //if were editing parent config in child we want to add the outputs specific to the child location
                //create new mask object with new outputs specific to phd
                let maskObj = Devices.phd.calcMasks(Devices.phd.getAssocOutputs(activeDevice.ip_address, location), var1Obj.duty, var1Obj.fade, dutyFade);
                //if the parental phd is already part of the config we add the new var1 to the action
                if(parentsOfNewOutputs.has(active.ip_address)//we already have outputs of this phd configured
                //and the active action outputs match the outputs in the location the new outputs were added to (var 1 matches)
                    && Devices.phd.compareVar1ToLocOutputs(active.var_1, activeDevice.ip_address, location, active.name, true)){

                        active.var_1 = maskObj.var1;
                        insertInto.insertActionToSave(active);
                        //we found an action specific to child, well create new a new action for all phds added to this child
                        //if outputs of a new parent multichannel device were added to child  (parentconfiginchild)
                        actionForNewMCD = JSON.parse(JSON.stringify(active));//save info for new mcd
                        actionForNewMCD.var1Obj = var1Obj;
                        if(!existingParentalMCD.has(active.ip_address)){
                            existingParentalMCD.add(activeDevice.ip_address);
                        }
                }
            }
        }
        if(actionForNewMCD !== false){
            for(let ip of parentsOfNewOutputs){
                //create actions per this scene for outputs of a new MCD with identical configuration of those existing in this location
                if(!(existingParentalMCD.has(ip))){
                    let device = App.Site.devices[App.site.deviceLookup[ip]];
                    let maskObj = Devices.phd.calcMasks(Devices.phd.getAssocOutputs(ip, location), actionForNewMCD.var1Obj.duty, actionForNewMCD.var1Obj.fade);
                    Scenes.functionGenerator(device, actionForNewMCD.link_id, actionForNewMCD.name, maskObj.var1, insertInto, false, maskObj.outputMask);
                }
            }
        }

    },
    psm: {
        buildConfigHTML: function (deviceIndex, sceneIndex) {
            let htmlStr = '<span class="psm-check-all-button"><label>Check All</label><input id="psm-check-all" type="checkbox"></span><span class="clearer"></span><ul id="psm-config-list">';

            for (let i = 1; i < 17; i++) {
                htmlStr += '<li >';
                htmlStr += '<span class=\'psmConfig\'><span class=\'psmConfigLabel\'>CH-' + i + '</span><span class=\'psmChoices\'><span class=\'psmON green\'>ON    <input id=\'CH' + i + 'ON\' class=\'psm-checkbox\' type=\'checkbox\'></span><span class=\'psmOFF red\'>OFF    <input id=\'CH' + i + 'OFF\' class=\'psm-checkbox\'  type=\'checkbox\'></span><span class=\'stretch\'></span></span>';
                htmlStr += '</span></span><span class=\'clearer\'></span></li>';
            }
            htmlStr += '</ul>';
            return htmlStr;
        },
        //Calculate the on and off bit mapped masks for CH17 control
        calcMasks: function () {
            let chan17_mask_on = 0;
            let chan17_mask_off = 0;
            //Iterate through all of the channel checkboxes on the page
            //Whilst performing bitwise magic to get the masks right
            //There are only 16 channels currently
            for (let index = 0; index < 16; index++) {
                //shift 1 left index times and OR it with chan17_mask_on to set that bit in chan17_mask_on
                if ($('#CH' + (index + 1) + 'ON').checked) {
                    chan17_mask_on |= 1 << index;
                }
                if ($('#CH' + (index + 1) + 'OFF').checked) {
                    chan17_mask_off |= 1 << index;
                }
            }

            let onAndOffMaskObj = {
                'on_mask': chan17_mask_on,
                'off_mask': chan17_mask_off
            };

            //Return the two masks in a single object
            return onAndOffMaskObj;
        },
        checkAll: function () {

            if (document.getElementById('psm-check-all').checked) {
                for (let i = 1; i < 17; i++) {
                    $('#CH' + i + 'ON').checked = true;
                    $('#CH' + i + 'OFF').checked = false;
                }
            } else {
                for (let i = 1; i < 17; i++) {
                    $('#CH' + i + 'OFF').checked = true;
                    $('#CH' + i + 'ON').checked = false;
                }
            }
        },
        // Unchecks the on, if off is checked for a channel, and vise versa
        // Gets called everytime someone checks a channel control box
        // Takes the id of the checkbox clicked as the only param
        optionClick: function (id) {
            let idNum = 0;
            let onId = '';
            let offId = '';

            //Pad single digits with 0s (probably should go away)
            //TODO:Refactor this, I think i didnt know what i was doing 2 years ago...
            if (id.charAt(3) !== 'O')
                idNum = id.charAt(2) + id.charAt(3);
            else
                idNum = id.charAt(2);

            onId = 'CH' + idNum + 'ON';
            offId = 'CH' + idNum + 'OFF';

            //If the ID we passed in of the item clicked is an off checkbox
            if (id.substr(-2) === 'FF') {
                if (document.getElementById(onId).checked && $(`#${offId}`).checked) {
                    document.getElementById(onId).checked = false;
                }
            } //Else it must be an on choice
            else {
                if (document.getElementById(offId).checked && document.getElementById(onId).checked) {
                    document.getElementById(offId).checked = false;
                }
            }
        },
    },
    phd: {
        //get actions in a location per parent PHD
        getAssocOutputs: function(parentIP, activeLocation, excludeNew=false){
            let phdOutputs = {};
            let parentPHD = App.Site.devices[App.Site.deviceLookup[parentIP]];

            for(let output in parentPHD.outputsById){
                if(parentPHD.outputsById[output] !== null){
                    const outputLocationID = parentPHD.outputsById[output].location_id;
                    if(App.Site.locations.hasOwnProperty(outputLocationID)){
                        const outputLocation = App.Site.locations[outputLocationID];
                        const outputLocationPath = outputLocation.path;

                        //if the active output is in the active location, or in a child location
                        if(outputLocationID === activeLocation.ID
                            || outputLocationPath.has(activeLocation.id)
                        ){
                            if(excludeNew && output in activeLocation.newOutputs){
                                continue;
                            }
                            phdOutputs[output] = parentPHD.outputsById[output];
                        }
                    }

                }
            }
            return phdOutputs;
        },
        //look through all locations that have outputs of this parentPhd and see if that location has the outputs associated with the current var1
        getAssocChannelLocationFromAction: function(parentPHD, actionFunction){//return the location if so
            for(let id in parentPHD.outputs){
                let activeOutputLoc =  parentPHD.outputs[id].location;
                if(Devices.phd.compareVar1ToLocOutputs(actionFunction.var_1, parentPHD.ip_address, activeOutputLoc, actionFunction.name) === true){
                    return activeOutputLoc;
                }
            }
            return false;
        },
        //parse duty and fade out of phd action var1
        getDutyFadeFromVar1:function(var1){
            let hexVar1 = decToHex(var1).replace('0x', '');
            let duty = hexVar1;
            let fade = parseInt(hexVar1.substr(-2), 16);
            duty = duty.substring(0, duty.length - 2);
            if (duty.length === 2){
                duty = duty.substr(-1);
            } else {
                duty = duty.substr(-2);
            }
            duty = parseInt(duty, 16);
            return {duty, fade};
        },
        //compare var1 of a PHD action to potential var1 created with same duty,fade and the outputs in given location associated with PhdIP
        //if they match we can tell that the action belongs to this location (for parent child things)
        compareVar1ToLocOutputs:function(var1, phdIP, location, actionName, excludeNew=false){
            let duty = false, fade = false, dutyFade=false;
            if(actionName.indexOf('(mask,%,fade)') >= 0 && var1 !== undefined){
                let var1Obj = Devices.phd.getDutyFadeFromVar1(var1);
                duty = var1Obj.duty;
                fade = var1Obj.fade;
                dutyFade=true
            }
            let assocOutputs = Devices.phd.getAssocOutputs(phdIP, location, excludeNew);
            let maskObj = Devices.phd.calcMasks(assocOutputs, duty, fade, dutyFade);
            if(maskObj === false){
                return false;
            }else{
                return (maskObj.var1.toString() === var1.toString());
            }
        },
        //calc masks for channels/zones and duty/fade for actions
        calcMasks: function (phdOutputs,inputVar1, actionFunctionName, dutyFade=false) {//override to pass in duty and fade as inputvar1 and actionfunction name
            let zones = new Set();
            let channels = new Set();
            let zoneMask = 0;
            let chanMask = 0;
            let duty = false;
            let fadeRate = false;
            let outputNumber;
            let var1 = 0;
            let maskOnly = (inputVar1 === '') ? true : false;
            //Zero pad the input just in case
            inputVar1 = ('0000' + inputVar1).substr(-4);
            //calc duty/fade based on actiontable name
            if(dutyFade !== false){
                duty = inputVar1;
                fadeRate = actionFunctionName;
            }else{
                switch(actionFunctionName){
                    case 'Set Auto Level (mask,%,fade)':
                    case 'Set Manual Level (mask,%,fade)':
                        duty = parseInt('0x'+inputVar1.toString().substr(0,2));
                        fadeRate = parseInt('0x'+inputVar1.toString().substr(-2));
                    break;
                    default:
                    break;
                }
            }
            // Initialize this flag false, it gets set to true based on the action and the input it needs
            for(let id in phdOutputs){
                let activeOutput = phdOutputs[id];
                if(activeOutput.type === 'Channel'){
                    channels.add(activeOutput.output);
                }else{
                    zones.add(activeOutput.output);
                }
            }
            ///THE DIMMING RELATED MASKS
            //Build the zone mask
            let zoneLookup = {1:'a', 2:'b', 3:'c', 4:'d', 5:'e', 6:'f', 7:'g', 8: 'h'};
            //TODO: figure out what do with the different actions with zones
            for (let index = 0; index < 17; index++) {
                outputNumber = index + 1;
                //shift 1 left index times and OR it with chan17_mask_on to set that bit in chan17_mask_on
                if (zones.has(zoneLookup[outputNumber])) {
                    //Set a flag if we need level and fade info
                    //dim for set manual, auto for set auto
                    zoneMask |= 1 << index;
                }
                if(channels.has(outputNumber)){
                    chanMask |= 1 << index;
                }
            }
            //For simple actions we skip this
            let outputMask = (('00' + zoneMask.toString(16)).substr(-2)) + (('0000' + chanMask.toString(16)).substr(-4));
            outputMask = hexToDec(outputMask);
            if (duty !== false && fadeRate !== false) {
                if (duty >= 0 && duty <= 100 && fadeRate >= 0 && fadeRate <= 20) {
                    var1 = (('00' + zoneMask.toString(16)).substr(-2)) + (('0000' + chanMask.toString(16)).substr(-4)) + (('00' + (parseInt(duty, 10)).toString(16)).substr(-2)) + (('00' + (parseInt(fadeRate, 10)).toString(16)).substr(-2));
                    var1 = hexToDec(var1);
                } else {
                    console.log('Invalid fade or target level');
                    return false;
                }
            } else if (maskOnly === true){
                var1 = outputMask;
            }else{
                var1 = outputMask + inputVar1;
            }
            //Return the masks in a single object
           /* let onAndOffMaskObj = {
                'on_mask': chan17_mask_on,
                'off_mask': chan17_mask_off,
                'var1': var1,
                //'var1_auto': var1_auto,
                //'dim_zone_mask': zoneMask,
                //'dim_chan_mask': chanMask,
                //'dim_zone_mask_auto': zoneMaskAuto,
                //'dim_chan_mask_auto': chanMaskAuto,
                'has_target_level_fade': hasTargetLevelFade
            };*/

            return {outputMask, var1};
        },
    },
    multiChannelDevice: {
        //get actions in a location per parent PHD
        getAssocOutputs: function(parentIP, activeLocation, excludeNew=false){
            let phdOutputs = {};
            let parentPHD = App.Site.devices[App.Site.deviceLookup[parentIP]];

            for(let output in parentPHD.outputsById){
                if(parentPHD.outputsById[output] !== null){
                    const outputLocationID = parentPHD.outputsById[output].location_id;
                    if(App.Site.locations.hasOwnProperty(outputLocationID)){
                        const outputLocation = App.Site.locations[outputLocationID];
                        const outputLocationPath = outputLocation.path;

                        //if the active output is in the active location, or in a child location
                        if(outputLocationID === activeLocation.ID
                            || outputLocationPath.has(activeLocation.id)
                        ){
                            if(excludeNew && output in activeLocation.newOutputs){
                                continue;
                            }
                            phdOutputs[output] = parentPHD.outputsById[output];
                        }
                    }

                }
            }
            return phdOutputs;
        },
        //look through all locations that have outputs of this parentPhd and see if that location has the outputs associated with the current var1
        getAssocChannelLocationFromAction: function(parentPHD, actionFunction){//return the location if so
            for(let id in parentPHD.outputs){
                let activeOutputLoc =  parentPHD.outputs[id].location;
                if(Devices.phd.compareVar1ToLocOutputs(actionFunction.var_1, parentPHD.ip_address, activeOutputLoc, actionFunction.name) === true){
                    return activeOutputLoc;
                }
            }
            return false;
        },
        //parse duty and fade out of phd action var1
        getDutyFadeFromVar1:function(var1){
            let hexVar1 = decToHex(var1).replace('0x', '');
            let duty = hexVar1;
            let fade = parseInt(hexVar1.substr(-2), 16);
            duty = duty.substring(0, duty.length - 2);
            if (duty.length === 2){
                duty = duty.substr(-1);
            } else {
                duty = duty.substr(-2);
            }
            duty = parseInt(duty, 16);
            return {duty, fade};
        },
        //compare var1 of a PHD action to potential var1 created with same duty,fade and the outputs in given location associated with PhdIP
        //if they match we can tell that the action belongs to this location (for parent child things)
        compareVar1ToLocOutputs:function(var1, phdIP, location, actionName, excludeNew=false){
            let duty = false, fade = false, dutyFade=false;
            if(actionName.indexOf('(mask,%,fade)') >= 0 && var1 !== undefined){
                let var1Obj = Devices.phd.getDutyFadeFromVar1(var1);
                duty = var1Obj.duty;
                fade = var1Obj.fade;
                dutyFade=true
            }
            let assocOutputs = Devices.phd.getAssocOutputs(phdIP, location, excludeNew);
            let maskObj = Devices.phd.calcMasks(assocOutputs, duty, fade, dutyFade);
            if(maskObj === false){
                return false;
            }else{
                return (maskObj.var1.toString() === var1.toString());
            }
        },
        //calc masks for channels/zones and duty/fade for actions
        calcMasks: function (phdOutputs,inputVar1, actionFunctionName, dutyFade=false) {//override to pass in duty and fade as inputvar1 and actionfunction name
            let zones = new Set();
            let channels = new Set();
            let zoneMask = 0;
            let chanMask = 0;
            let duty = false;
            let fadeRate = false;
            let outputNumber;
            let var1 = 0;
            let maskOnly = (inputVar1 === '') ? true : false;
            //Zero pad the input just in case
            inputVar1 = ('0000' + inputVar1).substr(-4);
            //calc duty/fade based on actiontable name
            if(dutyFade !== false){
                duty = inputVar1;
                fadeRate = actionFunctionName;
            }else{
                switch(actionFunctionName){
                    case 'Set Auto Level (mask,%,fade)':
                    case 'Set Manual Level (mask,%,fade)':
                        duty = parseInt('0x'+inputVar1.toString().substr(0,2));
                        fadeRate = parseInt('0x'+inputVar1.toString().substr(-2));
                    break;
                    default:
                    break;
                }
            }
            // Initialize this flag false, it gets set to true based on the action and the input it needs
            for(let id in phdOutputs){
                let activeOutput = phdOutputs[id];
                if(activeOutput.type === 'Channel'){
                    channels.add(activeOutput.output);
                }else{
                    zones.add(activeOutput.output);
                }
            }
            ///THE DIMMING RELATED MASKS
            //Build the zone mask
            let zoneLookup = {1:'a', 2:'b', 3:'c', 4:'d', 5:'e', 6:'f', 7:'g', 8: 'h'};
            //TODO: figure out what do with the different actions with zones
            for (let index = 0; index < 17; index++) {
                outputNumber = index + 1;
                //shift 1 left index times and OR it with chan17_mask_on to set that bit in chan17_mask_on
                if (zones.has(zoneLookup[outputNumber])) {
                    //Set a flag if we need level and fade info
                    //dim for set manual, auto for set auto
                    zoneMask |= 1 << index;
                }
                if(channels.has(outputNumber)){
                    chanMask |= 1 << index;
                }
            }
            //For simple actions we skip this
            let outputMask = (('00' + zoneMask.toString(16)).substr(-2)) + (('0000' + chanMask.toString(16)).substr(-4));
            outputMask = hexToDec(outputMask);
            if (duty !== false && fadeRate !== false) {
                if (duty >= 0 && duty <= 100 && fadeRate >= 0 && fadeRate <= 20) {
                    var1 = (('00' + zoneMask.toString(16)).substr(-2)) + (('0000' + chanMask.toString(16)).substr(-4)) + (('00' + (parseInt(duty, 10)).toString(16)).substr(-2)) + (('00' + (parseInt(fadeRate, 10)).toString(16)).substr(-2));
                    var1 = hexToDec(var1);
                } else {
                    console.log('Invalid fade or target level');
                    return false;
                }
            } else if (maskOnly === true){
                var1 = outputMask;
            }else{
                var1 = outputMask + inputVar1;
            }
            //Return the masks in a single object
           /* let onAndOffMaskObj = {
                'on_mask': chan17_mask_on,
                'off_mask': chan17_mask_off,
                'var1': var1,
                //'var1_auto': var1_auto,
                //'dim_zone_mask': zoneMask,
                //'dim_chan_mask': chanMask,
                //'dim_zone_mask_auto': zoneMaskAuto,
                //'dim_chan_mask_auto': chanMaskAuto,
                'has_target_level_fade': hasTargetLevelFade
            };*/

            return {outputMask, var1};
        },
    },

    //sorts through the HTML that comes back from the server and delivers the myData Array
    //myDdata has attributes for each column
    //calls deviceEdit(myParsedData)
    parseGetData: function (activeDevice, getDataResponse, force, fromTerminal, refresh) {
        let myData = [];
        let htmlString = '';
        let deviceIndex = -1;
        let deviceIP = activeDevice.ip_address;
        let jsonData;

        if (!refresh && fromTerminal) {
            htmlString = '<div class="getdata-table-' + activeDevice.ID + '">';
        }
        force = (force) ? force : 5;
        if (fromTerminal) {
            htmlString += '<span id="get-data-icon-terminal" class="needslcik icon-refresh right get-data-' + activeDevice.ID + '" data-force="' + force + '" data-id="' + activeDevice.ID + '""></span><table id = "data-table" class = "data-table-in-terminal data-table-' + activeDevice.ID + '">';
            htmlString += '<tr style="height: 45px; background: #2d2d2d; border: 1px solid #4174d0;"><th style="border-right: 1px solid #4174d0;">IDX</th><th style="border-right: 1px solid #4174d0;">NAME</th><th style="border-right: 1px solid #4174d0;">VALUE</th><th>UNIT</th></tr>';

        } else {
            $('#get-data-icon-2').remove();
            $('#setpoint-readout').prepend('<span id="get-data-icon-2" class="needsclick icon-refresh" data-force="' + force + '"></span>');
            htmlString = '<table id = "data-table">';
            htmlString += '<tr style="height: 45px; background: #2d2d2d; border: 1px solid #4174d0;"><th style="border-right: 1px solid #4174d0;">IDX</th><th style="border-right: 1px solid #4174d0;">NAME</th><th style="border-right: 1px solid #4174d0;">VALUE</th><th>UNIT</th></tr>';
        }
        try {
            jsonData = getDataResponse;
        } catch (e) {
            console.log(e);
            console.log(getDataResponse);
            return;
        }

        let activeReadOnly = activeDevice.readOnlyPoints;


        for (let index in jsonData) {
            try {
                let readOnly = false;
                for (let readOnlyIndex = 0; readOnlyIndex < activeReadOnly.length; readOnlyIndex++) {
                    if (activeReadOnly[readOnlyIndex] === jsonData[index]['idx'])
                        readOnly = true;
                }

                if (!readOnly)
                    $('#device-dp-input-' + jsonData[index]['idx']).val(parseFloat(jsonData[index]['cval']));
                else
                    $('#device-dp-input-' + jsonData[index]['idx']).text(parseFloat(jsonData[index]['cval']) + jsonData[index]['un']);

                //console.log('updated: ' + '#device-dp-input-'+jsonData[index]['idx'] +' with: '  + parseFloat(jsonData[index]['cval']) )
            } catch (e) {
                //console.log(e);
            }

            if (index % 2 === 0) {
                if (fromTerminal) {
                    htmlString += '<tr><td>' + jsonData[index]['idx'] + '</td><td style="text-align:left">' + jsonData[index]['dname'] + '</td><td><input data-idx="' + jsonData[index]['idx'] + '" data-dname="' + jsonData[index]['dname'] + '" data-index="' +
                    deviceIndex + '" class="terminal-data-input getData-input getData' + deviceIndex + '" data-deviceip="'+deviceIP+'" data-idx="'+jsonData[index]['idx'] + '"   ddname-idx="'+jsonData[index]['dname'] + '"  value="' + parseFloat(jsonData[index]['cval']) + '"></input></td><td>'
                    + jsonData[index]['un'] + '</td></tr>';
                } else {
                    htmlString += '<tr><td>' + jsonData[index]['idx'] + '</td><td style="text-align:left">' + jsonData[index]['dname'] + '</td><td><input data-idx="' + jsonData[index]['idx'] + '" data-dname="' + jsonData[index]['dname'] + '" data-index="' + deviceIndex + '" class=" getData-input getData' + deviceIndex + jsonData[index]['idx'] + '"value="' + parseFloat(jsonData[index]['cval']) + '"></input></td><td>' + jsonData[index]['un'] + '</td></tr>';

                }
            } else {
                if (fromTerminal) {
                    htmlString += '<tr style=""><td>' + jsonData[index]['idx'] + '</td><td style="text-align:left">' + jsonData[index]['dname'] + '</td><td><input data-idx="' + jsonData[index]['idx'] + '" data-dname="' + jsonData[index]['dname'] + '" data-deviceip="' + deviceIP + '" class="terminal-data-input getData-input getData' + deviceIndex + jsonData[index]['idx'] + '" value="' + parseFloat(jsonData[index]['cval']) + '"></input></td><td>' + jsonData[index]['un'] + '</td></tr>';
                } else {
                    htmlString += '<tr style=""><td>' + jsonData[index]['idx'] + '</td><td style="text-align:left">' + jsonData[index]['dname'] + '</td><td><input data-idx="' + jsonData[index]['idx'] + '" data-dname="' + jsonData[index]['dname'] + '" data-index="' + deviceIndex + '" class="getData-input getData' + deviceIndex + jsonData[index]['idx'] + '" value="' + parseFloat(jsonData[index]['cval']) + '"></input></td><td>' + jsonData[index]['un'] + '</td></tr>';
                }
            }
            myData.push({
                datapoint_id: jsonData[index]['idx'],
                name: jsonData[index]['dname'],
                unit: jsonData[index]['un'],
                value: Number(jsonData[index]['cval']),
            });
        }
        htmlString += '</table>';
        if (!refresh && fromTerminal) {
            htmlString += '</div>';
            setTimeout(() => {
                $('.terminal-data-input').off().on('change', async function(e) {
                    let idx = $(this).data('idx');
                    let dname = $(this).data('dname');
                    let nameOrId = dname || idx;
                    let value = $(this).val();
                    let deviceIP = $(this).data('deviceip');
                    const apiDevices = await SDKDevices.init();
                    const options = { requestBody: { name_or_id: nameOrId, value, site_id: App.activeSiteID } };
                    try {
                        const { body: { data } } = await apiDevices.devices.writeSetpoint({ device_id: deviceIP }, options);
                        if (data) {
                            $(this).addClass('write-success');
                            setTimeout(() => {
                                $(this).removeClass('write-success');
                            }, 3000);
                        }
                    } catch (error) {
                        $(this).addClass('write-error');
                        setTimeout(() => {
                            $(this).removeClass('write-error');
                        }, 3000);
                    }
                });
            } , 1000);
        }
        if (refresh && fromTerminal) {
            $('.getdata-table-' + activeDevice.ID).html(htmlString);
        } else if (fromTerminal) {
            return htmlString;
        } else {
            $('#setpoint-table-readout').show().html(htmlString);
        }
    },

    duplicateCleanup: function (doDelete) {
        let outputStr = '';
        let duplicateCount = 0;
        let previousDuplicateCount = window.localStorage.getItem('amatis_prev_dup_count');
        let result = {};
        if (previousDuplicateCount >= 0) {
            result.previous_duplicate_count = 'Previous Duplicate Count: ' + previousDuplicateCount;
        } else {
            previousDuplicateCount = 0;
            result.previous_duplicate_count = previousDuplicateCount;
            window.localStorage.setItem('amatis_prev_dup_count', previousDuplicateCount);
        }

        let deviceLen = Devices.list.length;

        for (let deviceIndex = 0; deviceIndex < deviceLen; deviceIndex++) {
            let activeDevice = Devices.list[deviceIndex];
            let activeAction, activeSortedAction;
            let foundDuplicate = false;
            //An object to keep track of actions we find
            let actionObjByLinkID = {};

            //Do internal actions first
            for (let actionIndex in activeDevice.internalActions) {
                activeAction = activeDevice.internalActions[actionIndex];

                if (actionObjByLinkID.hasOwnProperty(activeAction.link_id)) {

                    //actionObjByLinkID[activeAction.linkID].push(activeAction);
                    for (let sortedActionIndex = 0; sortedActionIndex < actionObjByLinkID[activeAction.link_id].length; sortedActionIndex++) {
                        activeSortedAction = actionObjByLinkID[activeAction.link_id][sortedActionIndex];

                        //Has to be an exact match
                        if (activeSortedAction.link_id === activeAction.link_id &&
                            activeSortedAction.var_1 === activeAction.var_1 &&
                            activeSortedAction.name === activeAction.name &&
                            activeSortedAction.name !== 'deleted'
                        ) {
                            foundDuplicate = true;
                            if (doDelete === true) {
                                activeAction.tableRow = '65535';
                                activeAction.actionTableName = 'deleted';
                                activeAction.saved = User.myUser.id;
                                activeDevice.unsaved = true;
                                App.Site.root.insertActionToSave(activeAction);
                            }

                            actionObjByLinkID[activeAction.link_id].push(activeAction);
                            break;
                        }
                    }
                } else {
                    actionObjByLinkID[activeAction.link_id] = [];
                    actionObjByLinkID[activeAction.link_id].push(activeAction);
                }
            }

            //Same exact drill again for externals
            // actionLen = activeDevice.externalActions.length;
            for (let actionIndex in activeDevice.externalActions) {
                activeAction = activeDevice.externalActions[actionIndex];
                if (actionObjByLinkID.hasOwnProperty(activeAction.link_id)) {

                    for (let sortedActionIndex = 0; sortedActionIndex < actionObjByLinkID[activeAction.link_id].length; sortedActionIndex++) {
                        activeSortedAction = actionObjByLinkID[activeAction.link_id][sortedActionIndex];

                        //Has to be an exact match
                        if (activeSortedAction.link_id === activeAction.link_id &&
                            activeSortedAction.var_1 === activeAction.var_1 &&
                            activeSortedAction.name === activeAction.name &&
                            activeSortedAction.name !== 'deleted'
                        ) {
                            foundDuplicate = true;
                            if (doDelete === true) {
                                activeAction.tableRow = '65535';
                                activeAction.name = 'deleted';
                                activeAction.saved = User.myUser.id;
                                activeDevice.unsaved = true;
                                App.Site.root.insertActionToSave(activeAction);
                            }

                            actionObjByLinkID[activeAction.link_id].push(activeAction);
                            break;
                        }
                    }
                } else {
                    actionObjByLinkID[activeAction.link_id] = [];
                    actionObjByLinkID[activeAction.link_id].push(activeAction);
                }
            }

            if (foundDuplicate) {

                outputStr += activeDevice.ip_address + ' has Duplicates:<br>';
                let warningString = '';

                for (let linkID in actionObjByLinkID) {
                    //Skip the first one, as thats not a duplicate
                    for (let actionIndex = 1; actionIndex < actionObjByLinkID[linkID].length; actionIndex++) {
                        warningString = '';
                        if (actionObjByLinkID[linkID][actionIndex].name === 'MAC Action(0xLinkID, 0xMAC)' ||
                            actionObjByLinkID[linkID][actionIndex].name === 'coap_send_action(linkID)' ||
                            actionObjByLinkID[linkID][actionIndex].name === 'coap_repeat_action(linkID)') {
                            warningString = '*Multi-MCAST* ';
                        }
                        outputStr += warningString + actionObjByLinkID[linkID][actionIndex].link_id + ', ' + actionObjByLinkID[linkID][actionIndex].name + ', ' + actionObjByLinkID[linkID][actionIndex].var_1 + '<br>';
                        duplicateCount++;
                    }
                }

                outputStr += '******************************************<br>';
            }
        }

        if (duplicateCount > previousDuplicateCount) {
            result.new_duplicates = 'NEW DUPLICATES!\n (was ' + previousDuplicateCount + ', now ' + duplicateCount + ')';
        }
        //Store the duplicate count for later
        window.localStorage.setItem('amatis_prev_dup_count', duplicateCount);

        result.duplicate_count = 'DUPLICATE COUNT: ' + duplicateCount;
        result.outputString = outputStr;
        return result;
    },
    //returns acitons grouped by linkID for better viewing
    findGhostActions: function(activeDevice){
        let ghosts = {};
        let externalActions = activeDevice.externalActions;
        if(Object.keys(externalActions).length === 0){
            return false;
        }

        for(let id in externalActions){
            let activeAction = externalActions[id];
            let linkID;
            if(activeAction.name === 'deleted'){
                continue;
            }else if(activeAction.name === "coap_send_action(linkID)"){
                linkID = activeAction.var_1;
            }else if(activeAction.name === "MAC Action(0xLinkID, 0xMAC)" || (activeAction.link_id >= 65530 && activeAction.link_id <= 65535)){
                linkID = Devices.switch.getLinkIDFromVar1(activeAction.var_1);
            }else{
                linkID = activeAction.link_id;
            }
            if(!App.Site.sceneLookup.hasOwnProperty(linkID)){
                if(!ghosts.hasOwnProperty(linkID)){
                    ghosts[linkID] = {};
                }
                ghosts[linkID][id] = activeAction;
            }
        }
        return ghosts;
    },

    internalActionsCleanup: function (activeDevice, doInsert=false) {

        let validActionsByDevice = {
            'core': [
                //The only values that really matter are linkID and actionTableName. We set tableRow by order later
                {
                    'ip_address': activeDevice.ip_address,
                    'table_row': '',
                    'link_id': '3',
                    'flag': '65535',
                    'site_id':App.Site.ID,
                    'flag_mask': '65535',
                    'var_1': '0',
                    'action_function': '65535',
                    'saved': User.myUser.id,
                    'name': 'Req Cloud Poll',
                    'ignored': '<--valid:ignored-->',
                },
                {
                    'ip_address': activeDevice.ip_address,
                    'table_row': '',
                    'link_id': '4',
                    'flag': '65535',
                    'site_id':App.Site.ID,
                    'flag_mask': '65535',
                    'var_1': '0',
                    'action_function': '65535',
                    'saved': User.myUser.id,
                    'name': 'Reboot Device',
                    'ignored': '<--valid:ignored-->',
                },
                {
                    'ip_address': activeDevice.ip_address,
                    'table_row': '',
                    'link_id': '5',
                    'flag': '65535',
                    'site_id':App.Site.ID,
                    'flag_mask': '65535',
                    'var_1': '0',
                    'action_function': '65535',
                    'saved': User.myUser.id,
                    'name': 'Identify Device',
                    'ignored': '<--valid:ignored-->',
                },
            ],
            'USGG-25P': [{
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '208',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '1',
                'action_function': '65535',
                'saved': User.myUser.id,
                'name': 'Cloud Dim',
                'part_type':'USGG-25P',
                'ignored': '<--valid:ignored-->',
            },
            {
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '209',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '1',
                'action_function': '65535',
                'saved': User.myUser.id,
                'name': 'Learn Light Level',
                'part_type': 'USGG-25P',
                'ignored': '<--valid:ignored-->',
            }
            ],
            'MLTH': [{
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '208',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '1',
                'action_function': '65535',
                'saved': User.myUser.id,
                'name': 'Cloud Dim',
                'part_type':'MLTH',
                'ignored': '<--valid:ignored-->',
            },
            {
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '209',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '1',
                'action_function': '65535',
                'saved': User.myUser.id,
                'name': 'Learn Light Level',
                'part_type': 'MLTH',
                'ignored': '<--valid:ignored-->',
            }
            ],
            'ALC': [{
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '208',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '1',
                'action_function': '65535',
                'saved': User.myUser.id,
                'name': 'Cloud Dim',
                'part_type':'ALC',
                'ignored': '<--valid:ignored-->',
            },
            {
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '209',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '1',
                'action_function': '65535',
                'saved': User.myUser.id,
                'name': 'Learn Light Level',
                'part_type': 'ALC',
                'ignored': '<--valid:ignored-->',
            }
            ],
            'AM-SW-ML': [{

                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '209',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '1',
                'action_function': '65535',
                'saved': User.myUser.id,
                'name': 'Learn Light Level',
                'part_type': 'USGG-25P',
                'ignored': '<--valid:ignored-->',
            }],
            'AM-DCDIM-DAC': [{

                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '208',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '1',
                'action_function': '65535',
                'saved': User.myUser.id,
                'name': 'Cloud Dim',
                'part_type': 'USGG-25P',
                'ignored': '<--valid:ignored-->',
            }],
            'AM-DCDIM-PWM': [{
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '208',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '1',
                'action_function': '65535',
                'saved': User.myUser.id,
                'name': 'Cloud Dim',
                'part_type': 'USGG-25P',
                'ignored': '<--valid:ignored-->',

            }],
            'AM-PHD': [{
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '212',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '1',
                'action_function': '65535',
                'saved': User.myUser.id,
                'name': 'Reset Energies',
                'part_type': 'AM-PHD',
                'ignored': '<--valid:ignored-->',
            },
            {
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '211',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '1',
                'action_function': '65535',
                'saved': User.myUser.id,
                'name': 'Set Defaults',
                'part_type': 'AM-PHD',
                'ignored': '<--valid:ignored-->',

            },
            {
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '210',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '1',
                'action_function': '65535',
                'saved': User.myUser.id,
                'name': 'Reboot PSM',
                'part_type': 'AM-PHD',
                'ignored': '<--valid:ignored-->',
            }
            ],
            'Tny': [{
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '34',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '65533',
                'action_function': parseInt(0x342D, 16),
                'saved': User.myUser.id,
                'name': 'coap_repeat_action(linkID)',
                'part_type': 'Tny',
                'ignored': '<--valid:ignored-->',
            },
            {
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '35',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '65532',
                'action_function': parseInt(0x342D, 16),
                'saved': User.myUser.id,
                'name': 'coap_repeat_action(linkID)',
                'part_type': 'Tny',
                'ignored': '<--valid:ignored-->',
            },
            {
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '36',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '65531',
                'action_function': parseInt(0x342D, 16),
                'saved': User.myUser.id,
                'name': 'coap_repeat_action(linkID)',
                'part_type': 'Tny',
                'ignored': '<--valid:ignored-->',
            },
            {
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '37',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '65531',
                'action_function': parseInt(0x342D, 16),
                'saved': User.myUser.id,
                'name': 'coap_repeat_action(linkID)',
                'part_type': 'Tny',
                'ignored': '<--valid:ignored-->',
            },
            {
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '32',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '65535',
                'action_function': parseInt(0x342D, 16),
                'saved': User.myUser.id,
                'name': 'coap_repeat_action(linkID)',
                'part_type': 'Tny',
                'ignored': '<--valid:ignored-->',
            },
            {
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '33',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '65534',
                'action_function': parseInt(0x342D, 16),
                'saved': User.myUser.id,
                'name': 'coap_repeat_action(linkID)',
                'part_type': 'Tny',
                'ignored': '<--valid:ignored-->',
            },
            {
                'ip_address': activeDevice.ip_address,
                'table_row': '',
                'link_id': '40',
                'flag': '65535',
                'site_id':App.Site.ID,
                'flag_mask': '65535',
                'var_1': '65529',
                'action_function': parseInt(0x342D, 16),
                'saved': User.myUser.id,
                'name': 'coap_repeat_action(linkID)',
                'part_type': 'Tny',
                'ignored': '<--valid:ignored-->',
            },
            ]
        };

        let newInternals = validActionsByDevice.core;
        //If there are devices specific actions, add them
        if (validActionsByDevice.hasOwnProperty(activeDevice.partType)) {
            newInternals = newInternals.concat(validActionsByDevice[activeDevice.partType]);
        }
        for(let id in activeDevice.internalActions){
            let active = activeDevice.internalActions[id];
            active.name = 'deleted';
            active.table_row = '65535';
            if(doInsert){
                App.Site.root.insertActionToSave(active);
            }
        }
        //Clean up tableRow to make sure they increment by 1
        for (let index = 0; index < newInternals.length; index++) {
            newInternals[index].table_row = index;
            if(doInsert){
                App.Site.root.insertActionToSave(newInternals[index]);
            }
        }
    },

    //Look for setpoints that could cause trouble, and alert the user to their state
    checkForDangerousSetpoints: function () {

        //We only want to do this once per site load. This is reset in App.siteLogout
        if (Devices.didCheckForDangerousSetpoints === false) {
            Devices.didCheckForDangerousSetpoints = true;
        } else {
            return;
        }

        let deviceLen = 0;
        let deviceDataLen = 0;
        let activeDevice = {};
        let activeDeviceData = {};
        let realDevIndex = -1;
        let outputString = '';

        //Add the message that goes on the top
        outputString += 'The following Setpoints are non standard.<br>' +
            'If these values were not intentionally set, please set defaults on the devices listed below:<br>';

        //A function to allow code reuse to format warning messages
        function addWarningMessage(deviceIP, deviceDataObj, messageString) {
            //TODO: Maybe use messageString later. leaving it here for now
            realDevIndex = Devices.getIndex(deviceIP);

            if (realDevIndex >= 0) {
                outputString += Devices.list[realDevIndex].getName(true) + ': ' +
                    deviceDataObj.dname + ' is ' + deviceDataObj.cval + '<br>';
            }
        }

        deviceLen = App.Site.deviceData.length;
        //Loop over the raw deviceData object which is an array of objects
        for (let deviceIndex = 0; deviceIndex < deviceLen; deviceIndex++) {
            activeDevice = App.Site.deviceData[deviceIndex];

            deviceDataLen = activeDevice.data.length;

            //Each device has its own set of data
            for (let deviceDataIndex = 0; deviceDataIndex < deviceDataLen; deviceDataIndex++) {
                activeDeviceData = activeDevice.data[deviceDataIndex];

                //TODO: Right now all warnings send the same thing back. Later we could add custom messages
                //We switch on the name of that datapoint, and based on name, compare value to what we expect
                switch (activeDeviceData.dname) {
                //////////////////
                ///// ML1 ////////
                //////////////////
                case 'PIR Mode':
                    //Make sure the motion mode is 5, cuz thats the magic number that works
                    if (activeDeviceData.cval !== 5) {
                        addWarningMessage(activeDevice.ip, activeDeviceData);
                    }

                    break;

                case 'PIR Enable':
                    //Make sure the motion is enabled, because in the real world that is standard
                    if (activeDeviceData.cval !== 1) {
                        addWarningMessage(activeDevice.ip, activeDeviceData);
                    }

                    break;

                    //////////////////
                    ///// SW8 ////////
                    //////////////////
                case 'SW1 Mode':
                case 'SW2 Mode':
                case 'SW3 Mode':
                case 'SW4 Mode':
                case 'SW5 Mode':
                case 'SW6 Mode':
                case 'SW7 Mode':
                case 'SW8 Mode':
                    //Make sure none of the inputs are disabled
                    if (activeDeviceData.cval === 0) {
                        addWarningMessage(activeDevice.ip, activeDeviceData);
                    }

                    break;

                    //////////////////////
                    // Disabled Outputs //
                    //////////////////////
                case 'Mode':
                    //Make sure none of the outputs are disabled
                    if (activeDeviceData.cval === 0) {
                        addWarningMessage(activeDevice.ip, activeDeviceData);
                    }
                    break;

                default:
                    break;

                }
            }

        }

        //Print this to the terminal. Maybe later this goes in a popup?
        Terminal.print(outputString, Terminal.commandCount, false, 0, false);

    },
    //currently age but we will want to do things like rssi, timestamp, name, loc etc
    //will assign a low, middle, high (printed green, yellow, red in terminal)
    sortDevicesBy: function (devices, sortBy) {
        if (sortBy === undefined) {
            sortBy = 'age';
        }
        //for determining what the comparison (limit) values are for each catagory
        let middle;
        let low;
        //object with 3 arrays which devices will be put into in sorted order
        let sorted = {
            low: [],
            middle: [],
            high: [],
        };

        if (sortBy === 'age') {
            low = 1800;
            middle = 86400;
        }

        //place devices by sortBy value
        //i hate this right now but itll be better when we objectify everything...
        for (let i = 0; i < devices.length; i++) {
            if (!Terminal.tinyCheck(App.Site.devices[devices[i]])) {
                sorted.low.push(devices[i]);
                continue;
            }
            if (App.Site.devices[devices[i]][sortBy] < low) {
                sorted.low.push(devices[i]);
            } else if (App.Site.devices[devices[i]][sortBy] < middle) {
                sorted.middle.push(devices[i]);
            } else {
                sorted.high.push(devices[i]);
            }
        }

        sorted.high.sort(function (a, b) {
            return a[sortBy] - b[sortBy];
        });

        sorted.middle.sort(function (a, b) {
            return a[sortBy] - b[sortBy];
        });

        sorted.low.sort(function (a, b) {
            return a[sortBy] - b[sortBy];
        });

        return sorted;

    },
    untagFromLocation(device,location){
        if (device && device.hasOwnProperty('id')) {
            if(device.id in location){
                    delete location.newDevices[this.id];
            }else if(device.id in location.newOutputs){
                    delete location.newOutputs[this.id];
            }else if(device.id in location.newSw8Inputs){
                delete location.newSw8Inputs[this.id];
            }
        }
        device.setProps({location_id: null_location_id});
    },
    getLocationOfSceneByLinkID: function(linkID){
        for (let scene in App.Site.scenes){
            let activeScene = App.Site.scenes[scene];
            if (activeScene.link_id == linkID){
                return activeScene.location_id;
            }
        }
        return false;
    },
    lookForXas: async function(){
        if(!App.Site.hasOwnProperty('locations')){
            return
        }
        const OPTIONS = { serverVariables: { baseVersion: 'v2' } };
        const PARAMS = { SITEID: App.activeSiteID };
        let errorsArray = null;
        try {
            const api = await SDK.init();
            let { body: { data, errors } } = await api.sites.getLookForXas(PARAMS, OPTIONS)
            errorsArray = errors
            let locationsWithXA = new Set([]);
            for (let linkID of data.link_list) {
                let location = this.getLocationOfSceneByLinkID(linkID);
                if (location) {
                    locationsWithXA.add(location)
                }
            }

            //We do two loops so that we update the state only for each location since there are many scenes per location.
            const locationsWithXAArray = Array.from(locationsWithXA);
            for (let location of locationsWithXAArray){
                App.Site.locations[location].hasXADevices = true;
            }
            return { link_list: data.link_list, locationsWithXAArray, errors };
        } catch (error) {
            console.log("ERROR ", error);
        }
        return {link_list: [], locationsWithXAArray: [], errors: errorsArray }
    }
};

export default Devices;
