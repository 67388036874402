//Device Object Class -> Makes actual device objects
import App from 'app/app.js';
import Amatis from 'api/amatis/amatis.js';
import { Scenes } from '../../scenes/services/scenes';
import Devices from '../services/devices';

class MCDOutput extends Amatis {
    constructor(info){
        super(info);
        this.amatisType = 'datapoints';
        this.id = info.datapoint_id;
        this.ID = info.datapoint_id;
        this.nameIsEditable = false;
        this.classification = 'dimmer';
        this.isMultiChannelOutput = true;
        this.partType = 'MCD Output';
        this.datapoint_id = this.id;
        this.name = info.name;
        this.parent_id = App.Site.deviceLookup[info.parent_ip_address];
        this.type = (info.output.indexOf('ch') >= 0 ? 'Channel' : 'Zone');
        this.output = (this.type === 'Channel' ? parseFloat(info.output.substr(-2)) : info.output.substr(-1));
        this.location_id = info.location_id;
        this.parent = App.Site.devices[this.parent_id];
        this.parent_device = App.Site.devices[this.parent_id];
        if(this.parent.hasOwnProperty('outputs')){
            this.parent_device.outputs[this.output] = this;
        }
        if(this.parent.hasOwnProperty('outputsById')){
            this.parent_device.outputsById[this.id] = this;
        }
        App.Site.MCDOutputs[this.id] = this;
        this.whiteList = new Set([
            'name',
            'location_id',
        ]);
        this.patchResourceV3 = this.patchResourceV3.bind(this);
    }
    finishLoad(){
        this.updateUIComponents();
    }

    get location(){
        if(this.location_id === null || this.location_id === 'null' || !(this.location_id in App.Site.locations)){
            return false
        }else{
            return App.Site.locations[this.location_id];
        }
    }
    updateUIComponents(){
        if(App.updateUIComponentsIsBusy('MCDOutputs', this.updateUIComponents)){
            return;
        }

        if(App.ActiveLocation !== false){
            if(App.hasOwnProperty('SiteDashboardContainer')){
                App.SiteDashboardContainer.setLocationStateDevices(App.ActiveLocation);
            }
        }
        if(App.hasOwnProperty('PageContent')){
            App.PageContent.setMCDDevicesListLoadedFlag(true);
        }
        if(App.hasOwnProperty('ConfigTables')){
            App.ConfigTables.setDeviceList(Devices.list);
        }
    }

    async patchResourceV3 (apiv3, params, payload) {
        return await apiv3.datapoints.updateDatapoint(params, payload);
    }

    async getDeviceLinkTables() {
        await Scenes.getSceneTable({ ip_address: [this.parent_device.ip_address] })
    }

    removeDeletedRows() {
        for (let index in this.internalActions) {
            if (this.internalActions[index].name === 'deleted') {
                delete this.internalActions[index];
            }
        }
        for (let index in this.externalActions) {
            if (this.externalActions[index].name === 'deleted') {
                delete this.externalActions[index];
            }
        }
    }
}

export default MCDOutput;