import App from 'app/app.js';
import {Scenes} from 'api/amatis/site/scenes/services/scenes.js';
import Devices from 'api/amatis/site/devices/services/devices.js';
import {hexToDec} from 'classes/methods.js';
import Scene from 'api/amatis/site/scenes/Scene.js';

import store from 'store';
import { deleteSceneSuccess, addSceneSuccess} from 'redux/scenes';

let trigConfig = {
    //BAF ISIS Fan controller info:
    //bafSerialButIsDown: [false,false,false],
    bafSerialfunctions: {
        '0': 'Button1',
        '1': 'Button2',
        '2': 'Button3',
        '3': 'Button1_Release',
        '4': 'Button2_Release',
        '5': 'Button3_Release',
        '6': 'Hold1',
        '7': 'Hold2',
        '8': 'Hold3',
        '9': 'Hold1_Release',
        '10': 'Hold2_Release',
        '11': 'Hold3_Release',
        '12': 'coap_send_action(linkID)',
        '13': 'coap_repeat_action',
        '14': 'Delay Scene 60 min(linkID)',
        '15': 'Delay Scene 1 min(linkID)',
        '16': 'Set LED Levels',
        '17': 'Delay Scene 1 sec(linkID)'
    },

    //SW3 info:
    swThreeFunctions: {
        '0': 'Button1',
        '1': 'Button2',
        '2': 'Button3',
        '3': 'Button1_Release',
        '4': 'Button2_Release',
        '5': 'Button3_Release',
        '6': 'Hold1',
        '7': 'Hold2',
        '8': 'Hold3',
        '9': 'Hold1_Release',
        '10': 'Hold2_Release',
        '11': 'Hold3_Release',
        '12': 'coap_send_action(linkID)',
        '13': 'coap_repeat_action',
        '14': 'Delay Scene 60 min(linkID)',
        '15': 'Delay Scene 1 min(linkID)',
        '16': 'Set LED Levels',
        '17': 'Delay Scene 1 sec(linkID)'
    },
    //SW8 info:
    swEightFunctions: {
        '0': 'Button1',
        '1': 'Button2',
        '2': 'Button3',
        '3': 'Button4',
        '4': 'Button5',
        '5': 'Button6',
        '6': 'Button7',
        '7': 'Button8',
        '8': 'Button1_Release',
        '9': 'Button2_Release',
        '10': 'Button3_Release',
        '11': 'Button4_Release',
        '12': 'Button5_Release',
        '13': 'Button6_Release',
        '14': 'Button7_Release',
        '15': 'Button8_Release'
    },
    //ML info:
    mlFunctions: {
        '0': 'Motion',
        '1': 'Motion TimeOut',
        '2': 'DayLightDim',
        '3': 'Disable Light Linking',
        '4': 'Disable Motion',
        '5': 'Enable Motion (0/1)',
        '6': 'Delay Scene 60 min(linkID)',
        '7': 'Enable Light Linking (0/1)',
        '8': 'Enable Light Linking',
        '9': 'Motion2',
        '10': 'Motion TimeOut2',
        '11': 'Set Motion Mode',

    },
    //Medley info
    medleyFunctions: {
        '0': 'Motion',
        '1': 'Motion TimeOut',
        '2': 'Button1',
        '3': 'Button2',
        '4': 'Button1_Release',
        '5': 'Button2_Release',
        '6': 'Hold1',
        '7': 'Hold2',
        '8': 'Hold1_Release',
        '9': 'Hold2_Release',
        '10': 'Set LED Levels',
        '11': 'Delay Scene 60 min(linkID)',
        '12': 'coap_send_action(linkID)',
        '13': 'coap_repeat_action',
        '14': 'Delay Scene 1 min(linkID)',
        '15': 'Delay Scene 1 sec(linkID)'
    },
    //dcdim info
    dcdimFunctions: {
        '0': 'Halt Fade',
        '1': 'Daylight Dim',
        '2': 'Ignore Daylight Dim',
        '3': 'Change Duty Cycle (Percent)',
        '4': 'Set Manual Duty(%,Fade)',
        '5': 'Set Auto Duty(%,Fade)',
        '6': 'Cloud Dim'
    },
    //phd info
    phdFunctions: {
        'cloud-dim': 'Cloud Dim (mask)',
        'auto-fade': 'Set Auto Level (mask,%,fade)',
        'man-fade': 'Set Manual Level (mask,%,fade)',
        'halt-fade': 'Halt Fade (mask)',
        'dlh': 'Harvest Daylight (mask)'
    },
    psmFunctions: {
        'enable-mapped': 'Enable Mapped Channels (mask) ',
        'disable-mapped': 'Disable Mapped Channels (mask)',
        'enable-chan': 'Enable PSM Channel (ch) ',
        'disable-chan': 'Disable PSM Channel (ch) ',
        'all-state': 'All Channels (state)',
    },
    //link info TODO: This should be deprecated at this point, but need to be replaced with mlFunctions
    linkFunctions: {
        '0': 'Motion Update', //slave action
        '1': 'Motion Reset', //master action
        '2': 'DayLightDim', //master ml
        '3': 'DayLight Dim' //Dll in dim
    },

    scenesFunctions: {
        '0': 'Motion', //slave action
    },

    devicesFunctions: {
        '0': 'Motion',
        '1': 'Motion',
        '2': 'Motion',
        '3': 'Motion',
    },

};

let Template = {
    //call make() first, which calls build(), then save() at the end
    templateDevices: [],
    scenesToSave: [],
    roomType: [],
    missingDevices: [],
    individualControlFlag: [],
    selectedDevicesWithCustomVar1: [],
    locationIndex: null,
    features: null,
    custom: false,
    tinyApplicableParts: [
        'AM-DCDIM-DAC',
        'AM-DCDIM-PWM', 'USGG-25P',
        'AM-ACDIM', 'DCDIM-TINY-TEST',
        'AM-ACDIM-MLTH',
        'AM-PSM',
        'AM-SW-ML',
        'AM-SW-MLTH',
        'AM-SW8',
        'ML-DALI',
        'ALC',
        'ALC-LVD',
        'DRIVER',
        'AM-PHD',
        'PHD',
        'MLTH',
        'SW-MLTH',
        'ACDIM-MLTH'
    ],

    devicesWithCustomVar1: {
        'PHD': {
            'assembleVar1': function (customString, templateVar1) {
                return hexToDec(customString + templateVar1);
            }
            /* jshint ignore:end */
        }
    },
    //final step inthe template/feature application process, save all new scenes and write device setpoints
    /* jshint ignore:start */
    async finalize(location=App.ActiveLocation){
        //create new scenes
        if(Object.keys(location.buildingScenes).length > 0){
            let scenes = location.buildingScenes;
            let sceneToSave;
            for(let linkID in scenes){
                if(!App.Site.sceneLookup.hasOwnProperty(linkID)){
                    let active = scenes[linkID];
                    let sceneDataObj = {
                        'name': active.name,
                        'link_id': linkID,
                        'location_id': location.ID,
                        'active': 0,
                        'hidden': active.hidden,
                        'description': active.description,
                    };
                    sceneToSave = sceneDataObj;
                    let newScene = await location.post(sceneToSave,false, 'scenes', 'v2');
                    new Scene(newScene);
                    // FIXME: remove dispatch move to redux
                    store.dispatch(addSceneSuccess(newScene));

                }
            }
        }
        if(location.hasOwnProperty('scenesToDelete')){
            for(let id of location.scenesToDelete){
                App.Site.scenes[id].destroy();
                location.scenesToDelete.delete(id);
                // FIXME: remove dispatch move to redux
                store.dispatch(deleteSceneSuccess({ id }));
            }
        }
        //save device set points
        let setpointQueue = [];
        for(let key in location.setpointsToWrite){
            const active = location.setpointsToWrite[key];
            setpointQueue.push({'name_or_id': active.idx ? active.idx : active.dname,'value': active.value, device_id: active.ip });
        }

        for(let index in location.templateActions){//prepare actions to be saved
            location.insertActionToSave(location.templateActions[index]);
        }
        //write externalActions to the cloud
        if(location.externalActionsToSave.length > 0){
            let newActions = await location.post({'data':"["+location.externalActionsToSave+"]"}, false,  'site_table_edit', 'v2');
            Devices.parseActions(newActions);
        }
        for(let feature in location.featureSettings){
            location.featureSettings[feature].active = false;
        }
        if(setpointQueue.length > 0){
            Devices.writeManySetpoints(setpointQueue);
        }
        if(Object.keys(App.Site.offlineSetpointsToWrite).length > 0){
            window.localStorage.setItem('offlineSetpointsToWrite', JSON.stringify(App.Site.offlineSetpointsToWrite));
        }
        //TODO: need to parse out device data
        //clear everything
        location.setpointsToWrite = {};
        location.templateActions = {};
        location.externalActionsToSave = "";
        location.buildingScenes = {};
        location.templateDevices = {};
        location.activeTemplateScenes = {};
        //TODO: trigger NEEDS SYNC
        if(App.hasOwnProperty('SceneContainer')){
            App.SceneContainer.setSceneList(App.Site.scenes);
        }
        if(App.hasOwnProperty('PageContent')){
            App.PageContent.setSceneList(App.Site.scenes);
        }
    },
    /* jshint ignore:end */
    //loops over all scenes in the template and builds actions/triggers
    parseScenes(templateScenes, device, mcdOutputs=false, location=App.ActiveLocation){
        let linkGroup, sceneName , activeLinkID, deviceTemplates, activeDeviceTemplate, sceneExists;
        let tried = templateScenes.length;

        let sceneNew = [];

        for(let id in device.externalActions){
            if(device.externalActions[id].name !== 'deleted'){
               sceneNew.push(device.externalActions[id]);
            }
        }

        for(let i = 0; i < templateScenes.length; i++){
            linkGroup =  templateScenes[i];
            sceneName = linkGroup.name;
            deviceTemplates = linkGroup.devices;
            let type =  device.partType.replace('AM-', '').replace('input', '').trim();
            if(!deviceTemplates.hasOwnProperty(type) || parseInt(device.version, 10) < 5){
                tried--;
                continue;
            }//handle devices not applicable to template here
            if (linkGroup.hasOwnProperty('linkID')) {
                activeLinkID = linkGroup.linkID;
            }else if(location.activeTemplateScenes.hasOwnProperty(linkGroup.description)){//lookup for scenes that exist
                activeLinkID = location.activeTemplateScenes[linkGroup.description];
            } else {
                activeLinkID = ++Scenes.highestLinkID;
            }
            sceneExists = (activeLinkID in location.buildingScenes ? true : false);
            if(!sceneExists){//case to build multiple actions/triggers for the same scene
                location.buildingScenes[activeLinkID] = {};
                location.buildingScenes[activeLinkID].name = sceneName;
                location.buildingScenes[activeLinkID].hidden = linkGroup.hidden;
                location.buildingScenes[activeLinkID].description = linkGroup.description;
                location.buildingScenes[activeLinkID].externalActions = [];
                location.activeTemplateScenes[linkGroup.description] = activeLinkID;
            }

            activeDeviceTemplate = deviceTemplates[type];
            //If this device just references another device, grab the info of the referenced device
            if (activeDeviceTemplate.hasOwnProperty('ref') && deviceTemplates.hasOwnProperty(activeDeviceTemplate.ref)) {
                activeDeviceTemplate = deviceTemplates[activeDeviceTemplate.ref];
            }

            let sceneID = null;
            if(mcdOutputs !== false){
                if(activeDeviceTemplate.hasOwnProperty('acts')){
                    for(let id in sceneNew) {
                        if(sceneNew[id].name === activeDeviceTemplate.acts[0].function && location.buildingScenes.hasOwnProperty(sceneNew[id].link_id)){
                            sceneID = sceneNew[id].id
                        }
                    }
                    for(let locID in mcdOutputs){
                        Template.configureActions(activeDeviceTemplate.acts, device, activeLinkID, location, mcdOutputs[locID].assocOutputs, sceneID);
                    }
                }

                if (activeDeviceTemplate.hasOwnProperty('parent')){
                    if(activeDeviceTemplate.parent.hasOwnProperty('acts')){
                        for(let id in sceneNew) {
                            if(sceneNew[id].name === activeDeviceTemplate.parent.acts[0].function && location.buildingScenes.hasOwnProperty(sceneNew[id].link_id)){
                                sceneID = sceneNew[id].id;
                            }
                        }
                        Template.configureActions(activeDeviceTemplate.parent.acts, device, activeLinkID, location, false, sceneID);
                    }
                }

            }else{
                if(activeDeviceTemplate.hasOwnProperty('trigs')){
                    Template.configureTriggers(activeDeviceTemplate.trigs, device, activeLinkID, location);
                }

                if(activeDeviceTemplate.hasOwnProperty('acts')){
                    for(let id in sceneNew) {
                        if(sceneNew[id].name === activeDeviceTemplate.acts[0].function && location.buildingScenes.hasOwnProperty(sceneNew[id].link_id)){
                            sceneID = sceneNew[id].id;
                        }
                    }
                    Template.configureActions(activeDeviceTemplate.acts, device, activeLinkID, location,false,sceneID);
                }

            }

        }
        if(tried === 0){
            return false;
        }else{
            return true;
        }
    },
    //build triggers for device per template scene
    configureTriggers(triggers, device, activeLinkID, location){
        for (let trigsIndex in triggers) {
            let activeTrig = triggers[trigsIndex];
            //TINY_STUFF
            if (activeTrig.hasOwnProperty('is_mac_action') && activeTrig.is_mac_action === true) {
                //Build the tiny scene and put it in the devices it needs to be in
                    Template.createMacActionFunction(activeTrig['function'], activeTrig.applicable_parts, activeLinkID, device, location); // eslint-disable-line
            } else {
                let deviceToLink = device;
                //If the action_function is role specific, make sure it matches the device
                if (activeTrig.hasOwnProperty('required_role') && activeTrig.required_role !== null) {
                    let roleOptions = activeTrig.required_role.split(',');
                    let deviceHasTheRightRole = false; //Since there could be multiple options of roles, we use a flag to make sure we fit the bill
                    let len = roleOptions.length;
                    if(device.partType === 'SW8 input' && device.parent.dualTech !== false){
                        deviceToLink = device.parent;
                    }
                    for (let index = 0; index < len; index++) {
                        if (roleOptions[index] === deviceToLink.role){
                            deviceHasTheRightRole = true;
                        }
                    }
                    if (deviceHasTheRightRole) {
                        let triggerlinkID = 'INT.' + activeTrig["function"];
                        //make the scene in the device
                        Scenes.functionGenerator(deviceToLink, triggerlinkID, 'trigger', activeLinkID, location);
                    }
                } else {
                    let triggerlinkID;
                    if(device.partType === 'SW8 input'){
                        let buttonAction = Devices.switch8.sceneToButtonAction(device, location.buildingScenes[activeLinkID].description);
                        if(buttonAction === false){
                            continue;
                        }
                        triggerlinkID = 'INT.'+buttonAction;
                        deviceToLink = device.parent;
                    }else{
                        triggerlinkID = 'INT.' + activeTrig["function"];
                    }
                    //make the scene in the device
                    Scenes.functionGenerator(deviceToLink, triggerlinkID, 'trigger', activeLinkID, location);
                }
            }
        }
    },
    //build actions for device per template scene
    configureActions(actions, device, activeLinkID, location, mcdOutputs=false, sceneID){
        let activeAct, actionFunctionVar1, multiChannelVar1, roleOptions, len, deviceHasTheRightRole, actionFunctionName;
        for (let actsIndex in actions) {
            activeAct = actions[actsIndex];

            actionFunctionVar1 = activeAct["var"];
            actionFunctionName = activeAct["function"];
            if(mcdOutputs !== false){
                //calc var1 with channels/zones
                let returnObj;
                if(device.partType === 'AM-PSM'){
                    returnObj = Devices.phd.calcMasks(mcdOutputs, actionFunctionVar1, false);
                }else{
                    returnObj = Devices.phd.calcMasks(mcdOutputs, actionFunctionVar1, actionFunctionName);
                }
                multiChannelVar1 = returnObj.var1;
                Scenes.functionGenerator(device, activeLinkID, actionFunctionName, multiChannelVar1, location , false, returnObj.outputMask, sceneID);

            }
            //If the action_function is role specific, make sure it matches the device
            else if (activeAct.hasOwnProperty('required_role') && activeAct.required_role !== null) {
                roleOptions = activeAct.required_role.split(',');
                deviceHasTheRightRole = false; //Since there could be multiple options of roles, we use a flag to make sure we fit the bill
                len = roleOptions.length;
                let deviceToLink = device;
                if(device.partType === 'SW8 input' && device.parent.dualTech !== false){
                    deviceToLink = device.parent;

                }
                let role = deviceToLink.role;
                for (let index = 0; index < len; index++) {
                    if (roleOptions[index] === role){
                        deviceHasTheRightRole = true;
                    }
                }
                if (deviceHasTheRightRole) {
                    //Set the variable for the action function, and the function name itself
                    //make the scene in the device
                    Scenes.functionGenerator(deviceToLink, activeLinkID, actionFunctionName, actionFunctionVar1, location,  false, false, sceneID);

                } else { //bail if the device doesn't have the right role
                    continue;
                }

            }else {
                //Set the variable for the action function, and the function name itself
                //make the scene in the device
                Scenes.functionGenerator(device, activeLinkID, actionFunctionName, actionFunctionVar1, location, false, false, sceneID);

            }
        }
    },

    //////// TINY STUFF ////////
    getMacForMacAction: function (deviceIndex) {
        //TODO: Verify that this will work moving forward. I think i may want to parse the mac out as its own property
        return Devices.list[deviceIndex].altID.toLowerCase();
    },
    getSw8LinkID(requestedFunction){
        let reservedLinkID = requestedFunction;
        let sw8TriggerLinkID = App.Site.triggerLinkIDLookup['AM-SW8'];
        for(let id in sw8TriggerLinkID){
            if(sw8TriggerLinkID[id] === requestedFunction){
                reservedLinkID = parseInt(id);
            }
        }
        return reservedLinkID;
    },
    getSwLinkID(requestedFunction){
        let reservedLinkID = 0;
        switch (requestedFunction) {
            case 'Button1_Release':
                reservedLinkID = 65535;
                break;

            case 'Button2_Release':
                reservedLinkID = 65534;
                break;

            case 'Button1_Hold':
            case 'Hold1':
                reservedLinkID = 65533;
                break;

            case 'Button2_Hold':
            case 'Hold2':
                reservedLinkID = 65532;
                break;

            case 'Hold1_Release':
                reservedLinkID = 65531;
                break;

            case 'Hold2_Release':
                reservedLinkID = 65530;
                break;

            default:
                break;
        }
        return reservedLinkID;
    },
    //Builds the macAction function
    //TINY_STUFF
    createMacActionFunction: function (requestedFunction, applicableDeviceTypes, activeLinkID, activeSwitch, location=App.ActiveLocation) {
        let reservedLinkID = this.getSwLinkID(requestedFunction);
        //Requested function will be one of these as a string
        //Make sure we found it
        if (reservedLinkID === 0)
            return App.alert("error", "This feature template appears to be corrupted, please contact support: " + reservedLinkID);

        let applicableDeviceTypesLen = applicableDeviceTypes.length;
        let active;
        let var1;
        let devices = Object.keys(location.templateDevices);
        //if we added a new switch to location with pre existing features configured, we need to make mac actions for all devices in the location for this switch
        if(location.newSwitches.hasOwnProperty(activeSwitch.id)){
            devices = Object.keys(location.devices);
        }
        let PHDs = Object.keys(location.parentPHDs);
        let MCDs = Object.keys(location.parentMCDs);
        let allDevices = devices.concat(PHDs).concat(MCDs);
        //Look through all of the devices that are being thrown into this template.
        for (let index in allDevices) {
            //TODO: ADJUST THIS FOR PHD/MULTICHANNEL DEVICES SINCE THEY ARE NOT AUTOMATICALLY INCLUDED IN App.ActiveLocation.TemplateDevices
            active = App.Site.devices[allDevices[index]];
            //Compare them to the devices we know need to respond to this action
            for (let applDevIndex = 0; applDevIndex < applicableDeviceTypesLen; applDevIndex++) {
                //If they match, we generate the function in its table, and it will get saved later
                if (active.type === applicableDeviceTypes[applDevIndex]) {
                    //TODO, make this action MAC Action(0xLinkID, 0xMAC) a tiny function so that we look it up that way
                    let activeSceneLinkID= activeLinkID.toString(16);
                    //Pass it to the cloud as a dec
                    var1 = activeSceneLinkID + activeSwitch.altID.toLowerCase();
                    var1 = hexToDec(var1);
                    Scenes.functionGenerator(active, reservedLinkID, 'MAC Action(0xLinkID, 0xMAC)', var1, false, activeLinkID, location);
                }
            }
        }
    }
};

export {
    trigConfig,
    Template
};
