import { createAction } from 'redux-actions';

export const LIST_SCHEDULES_REQUEST = 'LIST_SCHEDULES_REQUEST';
export const LIST_SCHEDULES_SUCCESS = 'LIST_SCHEDULES_SUCCESS';
export const LIST_SCHEDULES_FAILURE = 'LIST_SCHEDULES_FAILURE';
export const LIST_SCHEDULES_FULFILL = 'LIST_SCHEDULES_FULFILL';

export const listSchedulesRequest = createAction(LIST_SCHEDULES_REQUEST);
export const listSchedulesSuccess = createAction(LIST_SCHEDULES_SUCCESS);
export const listSchedulesFailure = createAction(LIST_SCHEDULES_FAILURE);
export const listSchedulesFulfill = createAction(LIST_SCHEDULES_FULFILL);
