import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import createDebounce from 'redux-debounced';
import storage from 'redux-persist/lib/storage';
import { middleware as bugsnagMiddleware } from 'classes/bugsnag';
import { middlewareLocationListener } from 'classes/middlewares';

import history from './history';
import reducer from './reducer';

const persistConfig = {
    debug: process.env.NODE_ENV !== 'production',
    key: 'root',
    storage,
    whitelist: [],
}

// If Redux DevTools Extension is installed use it, otherwise use Redux compose
const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;



const middlewares = [
    middlewareLocationListener,
    createDebounce(),
    thunk,
    routerMiddleware(history),
    bugsnagMiddleware,
];

const enhancers = [applyMiddleware(...middlewares)];

const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStore(persistedReducer, composeEnhancers(...enhancers));

export const persistor = persistStore(store)
export default store;
