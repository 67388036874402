import App from 'app/app.js';
import Amatis from 'api/amatis/amatis.js';

class Sw8Input extends Amatis{
    constructor(info){
        super(info);
        this.amatisType = 'datapoints';
        this.id = info.datapoint_id;
        this.ID = info.datapoint_id;
        this.nameIsEditable = false;
        this.classification = 'switch';
        this.partType = 'SW8 input';
        this.role = '';
        this.datapoint_id = this.id;
        this.name = info.name;
        this.parent_id = App.Site.deviceLookup[info.parent_ip_address];
        this.parent = App.Site.devices[this.parent_id];
        this.version = this.parent.version;
        this.type = info.type;
        this.output = info.output.charAt(2);
        this.location_id = info.location_id;
        this.parent.dualTech = false;
        if(this.parent.hasOwnProperty('outputs')){
            this.parent.outputs[this.output] = this;
        }
        App.Site.sw8Inputs[this.id] = this;
        this.whiteList = new Set([
            'name',
            'location_id',
        ]);
    }
    finishLoad(){
        this.updateUIComponents();
    }

    get location(){
        if(this.location_id === null || this.location_id === 'null' || !(this.location_id in App.Site.locations)){
            return false
        }else{
            return App.Site.locations[this.location_id];
        }
    }
    updateUIComponents(){
        if(App.updateUIComponentsIsBusy('sw8Inputs', this.updateUIComponents)){
            return;
        }

        if(App.ActiveLocation !== false){
            if(App.hasOwnProperty('SiteDashboardContainer')){
                App.SiteDashboardContainer.setLocationStateDevices(App.ActiveLocation);
            }
        }
    }
}

export default Sw8Input;