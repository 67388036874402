import queryString from 'query-string';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { setFiltersFromQueryParams, resetFiltersFromQueryParams } from 'redux/nav/actions';
import { SET_QUERY_PARAMS_FROM_FILTERS } from 'redux/nav/types';
import { setFiltersFromQueryParamsOnDatapoint } from  'redux/datapoints/actions';
import { setChart } from 'redux/charts';

export const middlewareLocationListener = store => next => action => {
  const result = next(action);

  if (action.type === LOCATION_CHANGE) {
    const { location } = store.getState().router;
    const params = queryString.parse(location.search);
    store.dispatch(setChart(params));
    store.dispatch(setFiltersFromQueryParams(location));
    for(var filter in location.query) {
      if(filter !== 'datapoint') {
        let data = location.query[filter].replaceAll('%20', ' ');
        if (data.includes('%3A')) data = data.replaceAll('%3A', ':')
        store.dispatch(setFiltersFromQueryParamsOnDatapoint({
          key: filter,
          value: data.includes('%2C') ? data.split('%2C') : data.split(',')
        }))
      }
    }
  }

  if (action.type === 'TABLE_DATAPOINT/FILTERS') {
    store.dispatch(setFiltersFromQueryParams(action.payload))
  }

  if (action.type === SET_QUERY_PARAMS_FROM_FILTERS || action.type === 'TABLE_DATAPOINT/FILTERS') {
    const { location } = store.getState().router;
    const { key, value } = action.payload;
    const search = location.search || '';
    const params = queryString.parse(search);
    if('datapoint' in params) {
      params.datapoint = params.datapoint.split(',');
    }
    Object.assign(params, { [key]: value });
    store.dispatch(push({
        pathname: location.pathname,
        search: queryString.stringify(params, {arrayFormat: 'comma', skipNull: true }),
    }));
  }

    if (action.type === 'TABLE_DATAPOINT/RESET_FILTERS') {
        store.dispatch(resetFiltersFromQueryParams());
        store.dispatch(push({
            pathname: location.pathname,
            search: '',
        }));
    }

  return result;
};
