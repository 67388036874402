import Swagger from 'swagger-client';

// Constant
export const delayBeforeAlert = 630

export const fetchInterface = window.fetch;

export const simpleLogout = () => {
    localStorage.removeItem('localLocation');
    localStorage.removeItem('localScene');
    localStorage.removeItem('App.localIP');
    localStorage.removeItem('amatis_user');
    localStorage.removeItem('amatis_token');
    if(window.Intercom){
      window.Intercom('update', {user_id: '', email: '', name: '', user_hash: ''});
    }
    window.location.replace("/");
}

export const swaggerClient = (OpenapiSpec, fetchingInterface) => {
    Swagger.prototype.execute = (...args) =>
        Swagger.execute.apply(this, args).catch(err => {
            if (!err || !err.response) {
                const message = ['Service not available. Please try again.'];
                Object.assign(err, { errorMessages: message });
            } else if (err.response.status === 408 || err.response.status === 'ECONNABORTED') {
                const message = ['The service timed out. Please try again.'];
                Object.assign(err, { errorMessages: message });
            } else if (err.response.body && !err.response.ok) {
                Object.assign(err, { errorMessages: err.response.body.errors || [] });
            } else {
                Object.assign(err, { errorMessages: [] })
            }
            // If we receive any 401 we logout
            const operationId = args && args.length && args[0].operationId ? args[0].operationId : null;
            if (err && err.response && err.response.status === 401 && operationId !== 'oauthToken') {
                simpleLogout();
            }
            throw err;
        });

    return new Swagger({
        spec: OpenapiSpec,
        usePromise: true,
        requestInterceptor: req => {
            const token = window.localStorage.getItem('amatis_token');
            if (token) {
                req.headers.Authorization = `Bearer ${token}`;
            } else {
                delete req.headers.Authorization;
            }
        },
        userFetch: fetchingInterface
    }).then(client => client.apis);
}
