// @ts-nocheck
import { reversePhdCalcMasks } from "components/app/menu/scenes/scene-config-modal/calculator";
import { normilzeIssue } from "../../utils/IssuesNormalizer";
import type { Location, Scene, KeyObjectType, Device, ValidatorKey, Issue, Category } from "./types";

const noActionParts = [
    "Tny",
    "AM-SW-ML",
    "AM-SW-MLTH",
    "MLTH",
    "AM-SW-M",
    "AM-SW8",
];

const getIsOpenClose = (scene: Scene) =>
scene.name.toLowerCase().indexOf("closed hours") >= 0 ||
scene.name.toLowerCase().indexOf("open hours") >= 0;

//validate that all devices in the location have an action in the scene and test for duplicate
export const validateActions = (location: Location, scene: Scene, validatorKey: ValidatorKey, categorySceneKey: Category) => {
    const actionIssues: Issue[] = [];
    const actionMark: KeyObjectType<Device> = {};
    const devices = location.devices;
    const parentPHDs = location.parentPHDs;
    const parentSW8s = location.parentSW8s;
    const isOpenClose = getIsOpenClose(scene);
  
    Object.assign(devices, parentPHDs, parentSW8s);
    for (let index in devices) {
        const device = devices[index];
        let hasAction = false;
        if (
            device.classification === "switch" ||
            (noActionParts.indexOf(device.partType) >= 0 && isOpenClose === false) ||
            (noActionParts.indexOf(device.partType) < 0 && isOpenClose === true) ||
            (!device.isMasterOccByRole && scene.description === "motion_group")
        ) {
            continue;
        }

        for (let index in scene.actions) {
            const action = scene.actions[index];
            if (action.ip_address === device.ip_address) {
                hasAction = true;
                if (actionMark.hasOwnProperty(device.id) && !device.isMultiChannelOutputDevice) {
                    actionIssues.push(
                        normilzeIssue({
                            name: device.nameWithLastFour,
                            identifierId: index,
                            errorMessage: `${device.nameWithLastFour} has duplicate actions`,
                            locationName: location.name,
                            validatorKey: validatorKey,
                            type: "duplicate",
                            category: categorySceneKey,
                            sceneData: scene
                        })
                    )
                } else {
                    actionMark[device.id] = device;
                }
                if (scene.description === "motion_group" && action.name !== "Motion Reset") {
                    actionIssues.push(
                        normilzeIssue({
                            name: device.nameWithLastFour,
                            identifierId: index,
                            errorMessage: "Scene is misconfigured, check the action function",
                            locationName: location.name,
                            validatorKey: validatorKey,
                            type: "misconfigured",
                            category: categorySceneKey,
                            sceneData: scene
                        })
                    )
                }
            }
        }
        if (hasAction === false) {
            actionIssues.push(
                normilzeIssue({
                    name: device.nameWithLastFour,
                    identifierId: index,
                    errorMessage: `${device.nameWithLastFour} is missing from actions`,
                    locationName: location.name,
                    validatorKey: validatorKey,
                    type: "missing",
                    category: categorySceneKey,
                    sceneData: scene
                })
            );
        }
    }

    return [...actionIssues];
};


export const validatePHDs = (location: Location, scene: Scene, validatorKey: ValidatorKey, categorySceneKey: Category) => {
    const actionIssues: Issue[] = [];
    const phdDevices = {
        ...location.parentPHDs,
        ...location.parentSW8s
    };

    if(scene.description === "occupied") {
        for (let index in phdDevices) {
            const device = phdDevices[index];
            const deviceOutputs = phdDevices[index].outputs;
            if (deviceOutputs) {
                const actionId = Object.keys(scene.actions).find(index => scene.actions[index].ip_address === device.ip_address);
                const action = scene.actions[actionId];
                if (action) {
                    const { channels } = reversePhdCalcMasks(action.var_1);
                    for (let i in channels) {
                        const deviceOutput = deviceOutputs[channels[i]];
                        if (deviceOutput && deviceOutput.location_id && deviceOutput.location_id !== location.id) {
                            actionIssues.push(
                                normilzeIssue({
                                    name: device.nameWithLastFour,
                                    identifierId: index,
                                    errorMessage: `${device.nameWithLastFour} channel ${channels[i]} is in the device list of a different location`,
                                    locationName: location.name,
                                    validatorKey: validatorKey,
                                    type: "duplicate",
                                    category: categorySceneKey,
                                    sceneData: scene
                                })
                            )
                        }
                    }
                }
            }
        }
    }
    return actionIssues
}
