import OpenApiDevices from './spec/openapi-devices-v1.json';
import { fetchInterface, swaggerClient } from './utils';

const { REACT_APP_API_URL_BASE_PATH } = process.env;

OpenApiDevices.servers[0].variables.base_url.default = REACT_APP_API_URL_BASE_PATH;

const SDKDevices = {
    apiDevices: null,
    _fetch: fetchInterface,
    init: () => {
        if (SDKDevices.apiDevices) return SDKDevices.apiDevices;
        SDKDevices.apiDevices = swaggerClient(OpenApiDevices, SDKDevices._fetch);
        return SDKDevices.apiDevices;
    }
}

export default SDKDevices;
