import React from 'react';
import LoadingA from './loading-a.js';
import App from 'app/app.js';

class actionOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
    };
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.decideDisplay = this.decideDisplay.bind(this);
    App.ActionOverlay = this;
  }

  static defaultProps = {
    show: false
  };

  hide() {
    if (this.state.show === true) {
      this.setState({ show: false });
    }
  }

  show() {
    if (this.state.show === false) {
      this.setState({ show: true });
    }
  }

  decideDisplay() {
    if (this.state.show === true) {
      return (
        <div className="action-overlay-container">
            <div className="image-container">
                <LoadingA />
            </div>
        </div>
      );
    }
    return null;
  }

  render() {
      return this.decideDisplay();
  }

}

export default actionOverlay;
