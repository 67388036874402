import { createActions, createAction } from 'redux-actions';
import store from 'store';
import { listMeSites, clearSites } from 'redux/sites';
import { updateUser as updateUserList } from 'redux/users';
import { logOut } from 'redux/auth/actions';

import SDK from 'api/sdk';
import SDKV3 from 'api/sdkV3';

import App from 'app/app';

export const RETRIEVE_USER = 'RETRIEVE_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const CLEAR_USER_SITES = 'CLEAR_USER_SITES';
export const ADD_SITES_TO_USER = 'ADD_SITES_TO_USER';
export const DELETE_SITE_TO_USER = 'DELETE_SITE_TO_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_USER_SITES = 'GET_USER_SITES';

const helperActions = {
    'REQUEST': undefined,
    'SUCCESS': undefined,
    'FAILURE': undefined,
    'FULFILL': undefined
};

export const { retrieveUser, addSitesToUser, deleteSiteToUser, updateUser, getUserSites } = createActions({
    [RETRIEVE_USER]: helperActions,
    [ADD_SITES_TO_USER]: helperActions,
    [DELETE_SITE_TO_USER]: helperActions,
    [UPDATE_USER]: helperActions,
    [GET_USER_SITES]: helperActions,
});

export const clearUser = createAction(CLEAR_USER);
export const clearUserSites = createAction(CLEAR_USER_SITES);

export const getUser = ({ id }) => dispatch => {
    const { sites } = store.getState();
    const params = { ID: id };
    dispatch(retrieveUser.request());
    return SDK.init().then(api => api.users.getUserById(params))
        .then(({ body }) => {
            dispatch(updateUserList.success({ data: body }));
            dispatch(retrieveUser.success(setUser(body, sites)));
        })
        .catch(error => {
            dispatch(retrieveUser.failure(error));
        })
        .finally(() => {
            dispatch(retrieveUser.fulfill());
        });
}

export const submitSitesToUser = ({ siteId, userId }) => dispatch => {
    const params = { ID: userId };
    const requestBody = { site_id: siteId };
    const OPTIONS = { serverVariables: { baseVersion: "v2" }, requestBody };
    dispatch(addSitesToUser.request());
    return SDK.init().then(api => api.users.addSiteToUser(params, OPTIONS))
        .then(() => {
            dispatch(addSitesToUser.success());
            dispatch(clearSites());
            dispatch(listMeSites({ id: userId }));
            dispatch(getUserSitesFetch({ id: userId }));
        })
        .catch(() => {
            dispatch(addSitesToUser.failure());
        })
        .finally(() => {
            dispatch(addSitesToUser.fulfill());
        });
}

export const removeSiteToUser = ({ siteId, userId }) => dispatch => {
    const params = { ID: userId, SITE_ID: siteId };
    const OPTIONS = { serverVariables: { baseVersion: "v2" } };
    dispatch(deleteSiteToUser.request());
    return SDK.init().then(api => api.users.deleteSiteToUser(params, OPTIONS))
        .then(() => {
            dispatch(deleteSiteToUser.success(siteId));
            dispatch(clearSites());
            dispatch(listMeSites({ id: userId }));
        })
        .catch(() => {
            dispatch(deleteSiteToUser.failure());
        })
        .finally(() => {
            dispatch(deleteSiteToUser.fulfill());
        });
}

export const submitUpdateUser = ({ id, ...values }) => dispatch => {
    const params = { USER_ID: id };
    const OPTIONS = { requestBody: values, serverVariables: { baseVersion: 'v3' }};
    dispatch(updateUser.request());
    return SDK.init()
            .then(api => api.users.patchtUserById(params, OPTIONS))
            .then(({ body }) => {
                dispatch(updateUser.success(body.data));
                App.alert('success',`User updated succesfully`);
            })
            .catch(err => {
                dispatch(updateUser.failure(err));
                if(err.errorMessages[0]){
                    App.alert('error',`Failed updating user`, `${err.errorMessages[0].message}`);
                }
            })
            .finally(() => {
                dispatch(updateUser.fulfill());
            });
}

export const getUserSitesFetch = ({ id }) => dispatch => {
    const params = { ID: id };
    const OPTIONS_SDK = { serverVariables: { baseVersion: "v2" } };
    dispatch(getUserSites.request());
    return SDK.init().then(api => api.users.getSitesByUserId(params, OPTIONS_SDK))
        .then(({ body: { data }}) => {
            dispatch(getUserSites.success(data));
        })
        .catch(error => {
            dispatch(getUserSites.failure(error));
        })
        .finally(() => {
            dispatch(getUserSites.fulfill());
        });
};

const setUser = (user, sites) => {
    let site = sites.items.find(item => item.siteid === user.siteid);
    let userToSet = {...user,
        site: {
            label: site ? site.dashname : '',
            value: site ? site.siteid : '',
        }, company: {
            label: user.company ? user.company : '',
            value: user.company ? user.company : ''
        }, timezone: {
            label: user.timezone,
            value: user.timezone,
        }
    }
    return userToSet;
}

export const updateSetUser = (user) => dispatch => {
    dispatch(retrieveUser.success(user));
}


export const removeUserSaga = (id) => dispatch => {
    const params = { id };

    return SDKV3.init().then(api => api.users.deleteUserById(params))
        .then(({ body: { meta }}) => {
            if (meta.message === "record deleted") {
                dispatch(logOut());
            }
        })
}
