import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { Redirect } from 'react-router-dom';
import { logOut } from 'redux/auth';
import store from 'store';
import User from 'classes/users';

export default WrappedComponent => {
  class PrivateAuthHOC extends Component {

    state = {
      isLoggedIn: User.isLocalStorage(),
    };

    componentDidMount() {
        const isLoggedIn = User.isLocalStorage();
        this.setState({ isLoggedIn });
    }

    render() {
      const { isLoggedIn } = this.state;
      const urlPath = ['/onboarding-mfa','/mfa']
      const allowRoles = ['admin', 'company'];
      const isTwoFactorEnable = User.myUser.client?.is_two_factor_enabled;
      const client = User.myUser.client;
      const path = this.props.history.location.pathname;
      const activePath = urlPath.includes(path);

      if (!isLoggedIn) {
        store.dispatch(logOut());
        return <Redirect to="/" />;
      } else if (allowRoles.includes(User.myUser.role) && client && !isTwoFactorEnable && !activePath) {
        return <Redirect to="/onboarding-mfa" />;
      }

      return <WrappedComponent {...this.state} {...this.props} />;
    }
  }

  hoistNonReactStatics(PrivateAuthHOC, WrappedComponent);

  return PrivateAuthHOC;
};
