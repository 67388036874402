import SDK from 'api/sdk';
import { push } from 'connected-react-router';
import { createAction } from 'redux-actions';
import history from 'store/history';

export const LIST_SITES_REQUEST = 'LIST_SITES_REQUEST';
export const LIST_SITES_SUCCESS = 'LIST_SITES_SUCCESS';
export const LIST_SITES_FAILURE = 'LIST_SITES_FAILURE';
export const LIST_SITES_FULFILL = 'LIST_SITES_FULFILL';
export const META_SEARCH_SITES = 'META_SEARCH_SITES';
export const CLEAR_SITES = 'CLEAR_SITES';
export const FILTER_SITES = 'FILTER_SITES';
export const SORTING_SITES = 'SORTING_SITES';
export const CURRENT_PAGE_SITES = 'CURRENT_PAGE_SITES';
export const PAGE_SIZE_SITES = 'PAGE_SIZE_SITES';

export const listSitesRequest = createAction(LIST_SITES_REQUEST);
export const listSitesSuccess = createAction(LIST_SITES_SUCCESS);
export const listSitesFailure = createAction(LIST_SITES_FAILURE);
export const listSitesFulfill = createAction(LIST_SITES_FULFILL);
export const metaSearchSites = createAction(META_SEARCH_SITES);
export const clearSites = createAction(CLEAR_SITES);
export const onFiltersChange = createAction(FILTER_SITES);
export const onSortingChange = createAction(SORTING_SITES);
export const onCurrentPageChange = createAction(CURRENT_PAGE_SITES);
export const onPageSizeChange = createAction(PAGE_SIZE_SITES);

export const listSites = () => dispatch => {
    dispatch(listSitesRequest());
    const OPTIONS_SDK = { serverVariables: { baseVersion: "v2" } };
    return SDK.init().then(api => api.users.getSitesList({}, OPTIONS_SDK))
        .then(({ body: { data }}) => {
            if (!data.length) {
                dispatch(push('/create-site'));
            } else if (data.length === 1 && history.location.pathname !== '/users') {
                dispatch(push(`/site/${data[0].siteid}`));
            } else {
                dispatch(listSitesSuccess(data));
            }
        })
        .catch(error => {
            dispatch(listSitesFailure(error));
        })
        .finally(() => {
            dispatch(listSitesFulfill());
        });
};

export const listMeSites = ({ id }) => dispatch => {
    dispatch(listSitesRequest());
    const params = { ID: id };
    const OPTIONS_SDK = { serverVariables: { baseVersion: "v2" } };
    return SDK.init().then(api => api.users.getSitesByUserId(params, OPTIONS_SDK))
        .then(({ body: { data }}) => {
            dispatch(listSitesSuccess(data));
        })
        .catch(error => {
            dispatch(listSitesFailure(error));
        })
        .finally(() => {
            dispatch(listSitesFulfill());
        });
};
