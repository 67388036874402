import { createAction } from 'redux-actions';
import SDKV3 from 'api/sdkV3';

export const LIST_NETWORKS_REQUEST = 'LIST_NETWORKS_REQUEST';
export const LIST_NETWORKS_SUCCESS = 'LIST_NETWORKS_SUCCESS';
export const LIST_NETWORKS_FAILURE = 'LIST_NETWORKS_FAILURE';
export const LIST_NETWORKS_FULFILL = 'LIST_NETWORKS_FULFILL';

export const UPDATE_NETWORK_REQUEST = 'UPDATE_NETWORK_REQUEST';
export const UPDATE_NETWORK_SUCCESS = 'UPDATE_NETWORK_SUCCESS';
export const UPDATE_NETWORK_FAILURE = 'UPDATE_NETWORK_FAILURE';
export const UPDATE_NETWORK_FULLFILL = 'UPDATE_NETWORK_FULLFILL';

export const DELETE_NETWORK_REQUEST = 'DELETE_NETWORK_REQUEST';
export const DELETE_NETWORK_SUCCESS = 'DELETE_NETWORK_SUCCESS';
export const DELETE_NETWORK_FAILURE = 'DELETE_NETWORK_FAILURE';
export const DELETE_NETWORK_FULLFILL = 'DELETE_NETWORK_FAILURE';

export const ADD_NETWORK_REQUEST = 'ADD_NETWORK_REQUEST';
export const ADD_NETWORK_SUCCESS = 'ADD_NETWORK_SUCCESS';
export const ADD_NETWORK_FAILURE = 'ADD_NETWORK_FAILURE';
export const ADD_NETWORK_FULLFILL = 'ADD_NETWORK_FULLFILL';

export const CLEAR_NETWORK_STATE = 'CLEAR_NETWORK_STATE';

export const clearNetworkItems = createAction(CLEAR_NETWORK_STATE);

export const listNetworksRequest = createAction(LIST_NETWORKS_REQUEST);
export const listNetworksSuccess = createAction(LIST_NETWORKS_SUCCESS);
export const listNetworksFailure = createAction(LIST_NETWORKS_FAILURE);
export const listNetworksFulfill = createAction(LIST_NETWORKS_FULFILL);

export const updateNetworkRequest = createAction(UPDATE_NETWORK_REQUEST);
export const updateNetworkSuccess = createAction(UPDATE_NETWORK_SUCCESS);
export const updateNetworkFailure = createAction(UPDATE_NETWORK_FAILURE);
export const updateNetworkFullfill = createAction(UPDATE_NETWORK_FULLFILL);

export const deleteNetworkRequest = createAction(DELETE_NETWORK_REQUEST);
export const deleteNetworkSuccess = createAction(DELETE_NETWORK_SUCCESS);
export const deleteNetworkFailure = createAction(DELETE_NETWORK_FAILURE);
export const deleteNetworkFullfill = createAction(DELETE_NETWORK_FULLFILL);

export const createNetworkRequest = createAction(ADD_NETWORK_REQUEST);
export const createNetworkSuccess = createAction(ADD_NETWORK_SUCCESS);
export const createNetworkFailure = createAction(ADD_NETWORK_FAILURE);
export const createNetworkFullfill = createAction(ADD_NETWORK_FULLFILL);


export const fetchNetworks = (siteId) => dispatch => {
    const params = { site_id: siteId, sort: 'name', limit: 150 };
    dispatch(listNetworksRequest());
    return SDKV3.init().then(api => {
        api.networks.getNetworks(params)
        .then(({ body: { data }}) => {
            dispatch(listNetworksSuccess(data));

        })
        .catch(error => {
            dispatch(listNetworksFailure(error));
        })
        .finally(() => {
            dispatch(listNetworksFulfill());
        });
    })
}

export const createNetwork = (siteId, requestBody) => dispatch => {
    dispatch(createNetworkRequest());
    const payload = { requestBody: { ...requestBody, site_id: siteId } };
    return SDKV3.init()
    .then(api => api.networks.createNetwork({}, payload))
    .then(({ body: { data } }) => {
        dispatch(createNetworkSuccess(data));
    })
    .catch(error => {
        dispatch(createNetworkFailure(error));
    })
    .finally(() => {
        dispatch(createNetworkFullfill());
    })
};


export const updateNetwork = (id, values) => dispatch => {
    dispatch(updateNetworkRequest());
    const payload = { requestBody: values };
    return SDKV3.init()
    .then(api => api.networks.updateNetwork({ id }, payload))
    .then(({ body: { data }}) => {
        dispatch(updateNetworkSuccess(data));
    }).catch(error => {
        dispatch(updateNetworkFailure(error));
    })
    .finally(() => {
        dispatch(updateNetworkFullfill());
    });
};

export const deleteNetwork = id => dispatch => {
    dispatch(deleteNetworkRequest());
    return SDKV3.init()
    .then(api => api.networks.deleteNetwork({ id }))
    .then(({ body: { data } }) => {
        dispatch(deleteNetworkSuccess(data));
    }).catch(error => {
        dispatch(deleteNetworkFailure(error));
    })
    .finally(() => {
        dispatch(deleteNetworkFullfill());
    });
};
