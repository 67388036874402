import Immutable from 'seamless-immutable';
import { setUsersCharts, saveUserCharts, setChart, setChartType, removeUserChart } from "./actions";


export const initialState = Immutable({
  charts: [],
  loading: false,
  error: null,
  chart: {},
  chartType: 'Line',
});


const handleRequest = (state, { payload }) => {
  return state.set('loading', true);
};

const handleSuccess = (state, { payload }) => {
  const charts = payload.data.map(chart => {
    return {
      ...chart,
      data: JSON.parse(chart.data),
    }
  })
  return state.set('charts', charts);
};

const handleSuccessSaveChart = (state, { payload }) => {
  const newChart = {
    ...payload.data,
    data: JSON.parse(payload.data.data),
  }
  return state.updateIn(['charts'], (charts, payload) => [...charts, payload], newChart);
};

const handleFailure = (state, { payload }) => {
  return state.set('error', payload);
};

const handleFulfill = (state, { payload }) => {
  return state.set('loading', false);
};

const handleSetChart = (state, { payload }) => {
  return state.set('chart', payload);
};

const handleSetChartType = (state, { payload }) => {
  return state.set('chartType', payload);
};

const handleSuccessRemoveChart = (state, { payload }) => {
  const updatedCharts = state.charts.filter( chart => chart.id !== payload.data.id);
  return state.set('charts', updatedCharts);
};

const handlers = {
  [setUsersCharts.request.toString()]: handleRequest,
  [setUsersCharts.success.toString()]: handleSuccess,
  [setUsersCharts.failure.toString()]: handleFailure,
  [setUsersCharts.fulfill.toString()]: handleFulfill,
  [saveUserCharts.request.toString()]: handleRequest,
  [saveUserCharts.success.toString()]: handleSuccessSaveChart,
  [saveUserCharts.failure.toString()]: handleFailure,
  [saveUserCharts.fulfill.toString()]: handleFulfill,
  [setChart.toString()]: handleSetChart,
  [setChartType.toString()]: handleSetChartType,
  [removeUserChart.request.toString()]: handleRequest,
  [removeUserChart.success.toString()]: handleSuccessRemoveChart,
  [removeUserChart.failure.toString()]: handleFailure,
  [removeUserChart.fulfill.toString()]: handleFulfill,
};

const reducer = (state = initialState, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
