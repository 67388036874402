import { createAction, createActions } from 'redux-actions';
import SDK from 'api/sdk';
import App from 'app/app';

export const LIST_USERS = 'LIST_USERS';
export const SORTING_USERS = 'SORTING_USERS';
export const FILTER_USERS = 'FILTER_USERS';
export const CURRENT_PAGE_USERS = 'CURRENT_PAGE_USERS';
export const PAGE_SIZE_USERS = 'PAGE_SIZE_USERS';
export const CLEAR_USERS = 'CLEAR_USERS';
export const DISABLE_MFA_USER = 'DISABLE_MFA_USER';
export const CREATE_USER = 'CREATE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const UPDATE_USER = 'UPDATE_USER';

const STAGE_STATES = {
    'REQUEST': undefined,
    'SUCCESS': undefined,
    'FAILURE': undefined,
    'FULFILL': undefined
};

export const { listUsers, disableMfaUser, createUser, removeUser, updateUser } = createActions({
    [LIST_USERS]: STAGE_STATES,
    [DISABLE_MFA_USER]: STAGE_STATES,
    [CREATE_USER]: STAGE_STATES,
    [REMOVE_USER]: STAGE_STATES,
    [UPDATE_USER]: STAGE_STATES,
});

export const clearUsers = createAction(CLEAR_USERS);
export const onFiltersChange = createAction(FILTER_USERS);
export const onSortingChange = createAction(SORTING_USERS);
export const onCurrentPageChange = createAction(CURRENT_PAGE_USERS);
export const onPageSizeChange = createAction(PAGE_SIZE_USERS);

export const fetchUsers = () => dispatch => {
    dispatch(listUsers.request());
    const params = { offset: 0, limit: 9999 };
    const OPTIONS_SDK = { serverVariables: { baseVersion: "v2" } };
    return SDK.init().then(api => api.users.getMeUsers(params, OPTIONS_SDK))
        .then(({ body: { data }}) => {
            dispatch(listUsers.success(data));
        })
        .catch(error => {
            dispatch(listUsers.failure(error));
        })
        .finally(() => {
            dispatch(listUsers.fulfill());
        });
}

export const disableMFAUser = (id) => dispatch => {
    dispatch(disableMfaUser.request());
    const OPTIONS_SDK = { serverVariables: { baseVersion: "v3" } };
    return SDK.init().then(api => api.users.disableMfa({ id }, OPTIONS_SDK))
        .then(({ body: { data }}) => {
            dispatch(disableMfaUser.success(data));
            dispatch(fetchUsers());
        })
        .catch(error => {
            dispatch(disableMfaUser.failure(error));
        })
        .finally(() => {
            dispatch(disableMfaUser.fulfill());
        });
}

export const submitCreateUser = ({ ...values }) => dispatch => {
    const OPTIONS = { serverVariables: { baseVersion: 'v3' }, requestBody: values };
    dispatch(createUser.request());
    App.ActionOverlay.show()
    return SDK.init()
            .then(api => api.users.addUser({}, OPTIONS))
            .then(({ body }) => {
                dispatch(createUser.success(body.data));
                App.alert('success',`User created succesfully`);
            })
            .catch((err) => {
                dispatch(createUser.failure(err));
                if(err.errorMessages[0]){
                    App.alert('error',`Failed to create user`, `${err.errorMessages[0].message}`);
                }
            })
            .finally(() => {
                dispatch(createUser.fulfill());
                App.ActionOverlay.hide()
            });
}

export const submitRemoveUser = (id) => dispatch => {
    const params = { ID: id };
    const OPTIONS = { serverVariables: { baseVersion: 'v3' }};
    dispatch(removeUser.request());
    App.ActionOverlay.show()
    return SDK.init()
            .then(api => api.users.removeUser(params, OPTIONS))
            .then(( { body } ) => {
                dispatch(removeUser.success(body.data));
                App.alert('success',`User removed succesfully`);
            })
            .catch(err => {
                dispatch(removeUser.failure(err));
            })
            .finally(() => {
                dispatch(removeUser.fulfill());
                App.ActionOverlay.hide()
            });
}


export const submitUpdateUser = (ID, { ...values }) => dispatch => {
    const params = { ID };
    const OPTIONS = { serverVariables: { baseVersion: 'v3' }, requestBody: values};
    dispatch(updateUser.request());
    App.ActionOverlay.show()
    return SDK.init()
            .then(api => api.users.updateUserById(params, OPTIONS))
            .then(( { body } ) => {
                dispatch(updateUser.success(body));
                App.alert('success',`User updated succesfully`);
            })
            .catch(err => {
                dispatch(updateUser.failure(err));
                if(err.errorMessages && err.errorMessages[0]){
                    App.alert('error',`Failed updating user`, `${err.errorMessages[0].message}`);
                } else {
                    App.alert('error',`Failed updating user`, `${err}`);
                }
            })
            .finally(() => {
                dispatch(updateUser.fulfill());
                App.ActionOverlay.hide()
            });
}
