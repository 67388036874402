/* eslint complexity: 0 */
//Disable complexity rule for this file because its insane
import $ from 'jquery';
import Communication from 'api/amatis/utilities/communications.js';
import User from 'classes/users.js';
import App from 'app/app.js';
import { Locations } from './amatis/site/locations/services/locations';
import SDKV3 from './sdkV3';
// import {Site} from 'api/amatis/site/sites';

const handleAuthError = () => {
    localStorage.removeItem('localLocation');
    localStorage.removeItem('localScene');
    localStorage.removeItem('App.localIP');
    localStorage.removeItem('amatis_site_id');
    localStorage.removeItem('amatis_user');
    localStorage.removeItem('amatis_token');
    window.location.reload();
}

export default class Api{
    constructor(){
        this.post = this.post.bind(this);
    }
    get (endpoint=false, ID = false, amatisType = this.amatisType, apiVersion = 'v1', sendData=false){
        if(amatisType === 'ambrs'){
            amatisType = `ambrs/${this.forward}/`;
        }
        return new Promise((resolve, reject) =>{
            let fullUrl;
            //override for implemented v2
            if(ID === false && (amatisType === "devices" || amatisType === "scenes")){
                apiVersion = 'v2';
            }
            if(ID !== false){
                fullUrl = `${Communication.getBaseURL(apiVersion)}/sites/${App.activeSiteID}/${amatisType}/${ID}/${endpoint}`;
            }else{
                fullUrl = endpoint ?
                `${Communication.getBaseURL(apiVersion)}/sites/${App.activeSiteID}/${amatisType}/${endpoint}`:
                `${Communication.getBaseURL(apiVersion)}/sites/${App.activeSiteID}/${amatisType}`;
            }
            sendData = (sendData === false ? null : sendData);
            $.ajax({
                type: 'GET',
                datatype: 'json',
                data:sendData,
                url: fullUrl,
                beforeSend: function (x) {
                    if (x && x.overrideMimeType) {
                        x.overrideMimeType('application/json;charset=UTF-8');
                    }
                },
                headers: {
                    Authorization: 'Bearer ' + User.apiToken,
                },
                success: function (response) {
                    if(response.meta.code === 200 || response.hasOwnProperty('data')){
                        if(response.hasOwnProperty('errors') &&
                            typeof(response.errors) === 'object' &&
                            response.errors.length > 0
                        ){
                            console.log('ERROR: response has an error array', response);
                            reject(response.errors);
                        }else{
                            //SUCCESS
                            resolve(response.data);
                        }
                    }else if(response.meta.code === 401){
                        User.logout();
                    }
                    else{
                        console.log('ERROR: response doesnt have a 200 code', response);
                        reject(response);
                    }
                },
                error: function (e) {
                    if(e.status === 401) handleAuthError();
                    reject(e);
                }
            });
        });
    }
    post (data, endpoint, amatisType = this.amatisType, apiVersion = 'v1'){
        if(amatisType === 'ambrs'){
            amatisType = `ambrs/${this.forward}/`;
        }
        return new Promise((resolve, reject) =>{
            let fullUrl = endpoint ?
                `${Communication.getBaseURL(apiVersion)}/sites/${App.activeSiteID}/${amatisType}/${endpoint}`:
                `${Communication.getBaseURL(apiVersion)}/sites/${App.activeSiteID}/${amatisType}`;
                $.ajax({
                type: 'POST',
                //timeout: 30000,
                datatype: 'json',
                url: fullUrl,
                data:data,
                beforeSend: function (x) {
                    if (x && x.overrideMimeType) {
                        x.overrideMimeType('application/json;charset=UTF-8');
                    }
                },
                headers: {
                    Authorization: 'Bearer ' + User.apiToken,
                },
                success: function (response) {
                    if(response.hasOwnProperty('data')){
                        resolve(response.data);
                    }else if(response.meta.code === 401){
                        User.logout();
                    }else{
                        console.log('ERROR: response has no data or response is not an object', response);
                    }
                },
                error: function (e) {
                    if('meta' in e && 'code' in e.meta && e.meta.code === 401){
                        User.logout();
                    }
                    console.log('Api.get ERROR: ', e);
                    reject(e);
                }
            });
        });
    }
    patch (data, endpoint = this.ID, amatisType = this.amatisType, apiVersion = 'v1'){
        if(amatisType === 'devices' && apiVersion === 'v1'){
            endpoint = this.ip_address;
            apiVersion = 'v2';
        }
        if(amatisType === "scenes"){
            apiVersion = 'v2';
        }

        return new Promise((resolve, reject) =>{
            let fullUrl = endpoint ?
                `${Communication.getBaseURL(apiVersion)}/sites/${App.activeSiteID}/${amatisType}/${endpoint}`:
                `${Communication.getBaseURL(apiVersion)}/sites/${App.activeSiteID}/${amatisType}`;
                $.ajax({
                type: 'PATCH',
                timeout: 10000,
                datatype: 'json',
                url: fullUrl,
                data:data,
                beforeSend: function (x) {
                    if (x && x.overrideMimeType) {
                        x.overrideMimeType('application/json;charset=UTF-8');
                    }
                },
                headers: {
                    Authorization: 'Bearer ' + User.apiToken,
                },
                success: function (response) {
                    if(response.hasOwnProperty('data')){
                        resolve(response.data);
                    }else if(response.meta.code === 401){
                        User.logout();
                    }else{
                        console.log('ERROR: response has no data or response is not an object', response);
                    }
                },
                error: function (e) {
                    if(e.status === 401) handleAuthError();
                    reject(e);
                }
            });
        });
    }
    delete (endpoint = this.ID, amatisType = this.amatisType, apiVersion = 'v1'){
        if(amatisType === 'ambrs'){
            amatisType = `ambrs/${this.forward}/`;
        }
        if(amatisType === "scenes"){
            apiVersion = 'v2';
        }
        return new Promise((resolve, reject) =>{
            let fullUrl = endpoint ?
                `${Communication.getBaseURL(apiVersion)}/sites/${App.activeSiteID}/${amatisType}/${endpoint}`:
                `${Communication.getBaseURL(apiVersion)}/sites/${App.activeSiteID}/${amatisType}`;
            $.ajax({
                type: 'DELETE',
                datatype: 'json',
                url: fullUrl,
                beforeSend: function (x) {
                    if (x && x.overrideMimeType) {
                        x.overrideMimeType('application/json;charset=UTF-8');
                    }
                },
                headers: {
                    Authorization: 'Bearer ' + User.apiToken,
                },
                success: function (response) {
                    if(response.meta.code === 401){
                        User.logout();
                    }
                    //remove the locations instances of the object
                    if(App.Site.hasOwnProperty(amatisType) && App.Site[amatisType].hasOwnProperty(endpoint)){
                        //let thing = App.Site[amatisType][endpoint];
                        if(amatisType === 'locations'){
                            Locations.removeFromLocationsList(endpoint);
                        }
                        delete App.Site[amatisType][endpoint]; //remove the object

                    }
                    resolve(true);
                },
                error: function (e) {
                    if(e.status === 401) handleAuthError();
                    resolve(false);
                }
            });
        });
    }
    //temp v2 get that resolves response for terminal purposes
    get2 (endpoint=false, ID = false, amatisType = this.amatisType, apiVersion = 'v1', sendData=false){
        if(amatisType === 'ambrs'){
            amatisType = `ambrs/${this.forward}/`;
        }
        return new Promise((resolve, reject) =>{
            let fullUrl;
            //override for implemented v2
            if(ID === false && (amatisType === "devices" || amatisType === "scenes")){
                apiVersion = 'v2';
            }
            if(ID !== false){
                fullUrl = `${Communication.getBaseURL(apiVersion)}/sites/${App.activeSiteID}/${amatisType}/${ID}/${endpoint}`;
            }else{
                fullUrl = endpoint ?
                `${Communication.getBaseURL(apiVersion)}/sites/${App.activeSiteID}/${amatisType}/${endpoint}`:
                `${Communication.getBaseURL(apiVersion)}/sites/${App.activeSiteID}/${amatisType}`;
            }
            sendData = (sendData === false ? null : sendData);
            $.ajax({
                type: 'GET',
                datatype: 'json',
                data:sendData,
                url: fullUrl,
                beforeSend: function (x) {
                    if (x && x.overrideMimeType) {
                        x.overrideMimeType('application/json;charset=UTF-8');
                    }
                },
                headers: {
                    Authorization: 'Bearer ' + User.apiToken,
                },
                success: function (response) {
                    if(response.meta.code === 200 || response.hasOwnProperty('data')){
                        if(response.hasOwnProperty('errors') &&
                            typeof(response.errors) === 'object' &&
                            response.errors.length > 0
                        ){
                            console.log('ERROR: response has an error array', response);
                            resolve(response);
                        }else{
                            //SUCCESS
                            resolve(response.data);
                        }
                    }else if(response.meta.code === 401){
                        User.logout();
                    }
                    else{
                        console.log('ERROR: response doesnt have a 200 code', response);
                        reject(response);
                    }
                },
                error: function (e) {
                    if(e.status === 401) handleAuthError();
                    reject(e);
                }
            });
        });
    }

    async patchV3 (payload) {
        try {
            const siteId = window.localStorage.getItem("amatis_site_id");
            const params = { id: this.ID, site_id: siteId };
            const body = { requestBody: payload };
            const apiv3 = await SDKV3.init();

            const {
                status,
                body: { errors, data },
            } = await this.patchResourceV3(apiv3, params, body);

            if (errors.length) throw new Error(errors.join());
            else if (!data) throw new Error("ERROR: response has no data");
            else if (status === 401) return User.logout();
            return data;
        } catch (error) {
            if (error.status === 401) handleAuthError();
            throw new Error(error);
        }
    }
}
