import {
    listAmbrsRequest,
    listAmbrsSuccess,
    listAmbrsFailure,
    listAmbrsFulfill,
    messageAmbrsReceive,
    updateAmbrLastPing,
} from "./actions";
import { delayBeforeAlert } from '../../api/utils';

const initialState = {
    error: null,
    items: [],
    loading: true,
    resentlyUpdated: false,
};

const diferenceInSeconds = (last_mqtt_update) => {
    const last_mqtt_update_date = new Date(last_mqtt_update);
    const now = new Date();
    const diferenceInMiliseconds =  now - last_mqtt_update_date;
    const diferenceInSeconds = Math.floor(diferenceInMiliseconds / 1000);
    return diferenceInSeconds;
}

const handleRequest = state =>
    Object.assign({}, state, { loading: true, error: null });

const handleSuccess = (state, { payload }) => {
    const items = payload.map(item => {
        const lastMqttPing = diferenceInSeconds(item.last_mqtt_update);
        let statusString = lastMqttPing > (delayBeforeAlert - 60) ? '⚠️' : '✅';
        return {
            ...item,
            status: statusString,
            last_ping: `${lastMqttPing}s`,
        }
    });



    return Object.assign({}, state, { items });
}

const handleFailure = (state, { payload }) =>
    Object.assign({}, state, { error: payload });

const handleFulfill = state => Object.assign({}, state, { loading: false });

const updateItem = (state, { payload }) => {
    return Object.assign({}, state, { state,
        items: [
            ...state.items.map(item => {
                const ambr = payload.find( el => item.MacAddy === el.MacAddy);
                if (ambr) return { ...item, ...ambr }
                return item;
            })
        ],
    })
};

const updateLastPingAmbr = (state, { payload }) => {
    const ambr = state.items.find(el => {
        return payload.find(item => {
            return item.topic.includes(el.MacAddy);
        });
    })
    const topicInfo = payload.find(item => item.topic.includes(ambr.MacAddy));
    return Object.assign({}, state, { state,
        items: [
            ...state.items.map(item => {
                if(item.MacAddy === ambr.MacAddy){
                    return {...item, last_ping: topicInfo.last_ping}
                }
                return item;
            })
        ],
        resentlyUpdated: true,
    })
};

const handlers = {
    [listAmbrsRequest.toString()]: handleRequest,
    [listAmbrsSuccess.toString()]: handleSuccess,
    [listAmbrsFailure.toString()]: handleFailure,
    [listAmbrsFulfill.toString()]: handleFulfill,
    [messageAmbrsReceive.toString()]: updateItem,
    [updateAmbrLastPing.toString()]: updateLastPingAmbr,
};

const reducer = (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
