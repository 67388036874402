import bugsnag from '@bugsnag/browser';
import bugsnagReact from '@bugsnag/plugin-react';
// import LogRocket from 'logrocket';
import React from 'react';

const { NODE_ENV, REACT_APP_BUGSNAG_KEY, REACT_APP_VERSION_NUMBER } = process.env;

const client = bugsnag({
  apiKey: REACT_APP_BUGSNAG_KEY,
  logger: null,
  notifyReleaseStages: ['production', 'staging'],
  releaseStage: NODE_ENV,
  appVersion: REACT_APP_VERSION_NUMBER,
  /* beforeSend: function (report) {
    report.updateMetaData('LogRocket', {
      sessionURL: LogRocket.sessionURL,
    });
  } */
});
client.use(bugsnagReact, React);

export default client;
