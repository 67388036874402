// @ts-nocheck
import App from "app/app.js";
import type { Location, Scene, KeyObjectType, ValidatorKey, Issue, Category } from "../../types";
import { normilzeIssue } from "../../utils/IssuesNormalizer";

const sceneDescToTrigger: KeyObjectType<string> = {
    occupied: "Motion",
    unoccupied: "Motion TimeOut",
    occupied_nighttime: "Motion2",
    unoccupied_nighttime: "Motion TimeOut2",
};

export const validateMotion = (location: Location, scene: Scene, validatorKey: ValidatorKey, categorySceneKey: Category) => {
    const motionIssues: Issue[] = [];

    const allDevices = App.Site.devices;
    const devLookup = App.Site.deviceLookup;
    const triggerLookup = App.Site.triggerLinkIDLookup;
    const requiredTrigger = sceneDescToTrigger[scene.description];
    if (scene.description in sceneDescToTrigger) {
        let foundMotionTrigger = false;
        for (let id in scene.triggers) {
            let activeTrigger = scene.triggers[id];
            let device = allDevices[devLookup[activeTrigger.ip_address]];
            if(triggerLookup[device.partType] && triggerLookup[device.partType][activeTrigger.link_id]) {
                let activeTriggerName = triggerLookup[device.partType][activeTrigger.link_id];
                if (activeTriggerName === requiredTrigger) {
                    foundMotionTrigger = true;
                }
            }
        }
        if (!foundMotionTrigger) {
            motionIssues.push(
                normilzeIssue({
                    name: scene.name,
                    identifierId: scene.description,
                    errorMessage: 'Missing Motion Triggers!',
                    locationName: location.name,
                    validatorKey: validatorKey,
                    type: "missing",
                    category: categorySceneKey,
                    sceneData: scene,
                })
            )
        }
    }

    return motionIssues;
};
