import { matchPath } from 'react-router';
import Api from 'api/api.js';
import {Locations} from  'api/amatis/site/locations/services/locations.js';
import {Scenes} from 'api/amatis/site/scenes/services/scenes.js';
import Devices from 'api/amatis/site/devices/services/devices.js';
import store from 'store';
import history from 'store/history';
import { processEvent } from 'redux/events';
import Immutable from 'seamless-immutable';
import { messageAmbrsReceive } from "redux/ambrs/actions";
import { delayBeforeAlert } from '../utils';

class Amatis extends Api {
    constructor(infoObject){
        super();
        //Add all properties from the cloud to this object
        for(let prop in infoObject){
            //Im not positive this matters. I think the getters/setters override on this (some quick tests show thats true)
            // if(prop !== 'name'){
            this[prop] = infoObject[prop];
            // }
        }
        this.ID = this.id; //Circle Back to this
        this.client = {};
        this.Site = {};
    }

    get dataTypes() {
        return ['locations','devices', 'scenes', 'device_groups'];
    }
    get typesToClass(){
        return  {locations:'Location',devices:'Device', scenes:'Scene', device_groups:'Group'};
    }
    async setProps (props, fromCloud = false, overrideUiUpdate=false) {
        let propsToSaveToCloud = {};
        //Set any states that are valid
        for(let prop of Object.keys(props)){
                //If its a new val, and the cloud isnt the one who told us and if the cloud
                //will accept the prop
                if(this[prop] !== props[prop] && !fromCloud && this.whiteList.has(prop)){
                    propsToSaveToCloud[prop] = props[prop];
                }
                //Set the local property
                this[prop] = props[prop];
        }
        if(Object.keys(propsToSaveToCloud).length !== 0 && !fromCloud){
            if (this.patchResourceV3) {
                this.patchV3(propsToSaveToCloud);
            } else {
                this.patch(propsToSaveToCloud);
            }
        }
        if(overrideUiUpdate === false){
            this.updateUIComponents();
        }
    }
    upsert(type, data){
        switch(type){
            case 'ambr':
                const bulkMessage = data.map(ambr => {
                    const last_ping = parseInt((Math.round(new Date() / 1000) - (ambr.last_ping / 1000)), 10);
                    let statusString = '✅';
                    if(last_ping > (delayBeforeAlert - 60)){
                        statusString = '⚠️';
                    }
                    return {
                        globalIP: ambr.global_ip,
                        localIP: ambr.local_ip,
                        SiteID: ambr.siteID,
                        MacAddy: ambr.mac,
                        SerialNumber: ambr.serial_no,
                        Version: ambr.ambr_version,
                        neighbors: ambr.neighbors,
                        mqtt_worker: ambr.mqtt_worker,
                        pan_id: ambr.pan_id,
                        tick: ambr.tick,
                        routes_with_fails: ambr.routes_with_fails,
                        uptime: ambr.uptime,
                        routes: ambr.routes,
                        channel: ambr.channel,
                        disk_utilization: ambr.disk_utilization,
                        throttle_write: ambr.throttle_write,
                        sd_card_mounted: ambr.sd_card_mounted,
                        local_mqtt_connected: ambr.local_mqtt_connected,
                        timezone: ambr.timezone,
                        uptime_seconds: ambr.uptime_seconds,
                        master_ambr: ambr.master_ambr,
                        last_ping: `${last_ping}s`,
                        status: statusString,
                    }
                });
                store.dispatch(messageAmbrsReceive(bulkMessage));
                break;
            case 'devices':
                //TODO: [CON-807]
                if(!this.updateIfObjectExists(type, data)){
                    if(Array.isArray(data)){
                        data = data[0];
                    }
                    Devices.create(data, true);
                }
                break;
            case 'locations':
                if(!this.updateIfObjectExists(type, data)){
                    if(Array.isArray(data)){
                        data = [data];
                    }
                    Locations.create(data, true);
                }
                break;
            case 'scenes':
                if(!this.updateIfObjectExists(type, data)){
                    if(Array.isArray(data)){
                        data = data[0];
                    }
                    Scenes.create(data, true);
                }
                break;
            case 'site_table_edit':
            case 'linktables':
                Devices.parseActions(data);
                break;
            case 'save_table_list':
                Devices.parseActions(data, false, true);
                break;
            case 'events':
                const match = matchPath(history.location.pathname, {
                    path: '/site/:id/events',
                    exact: true,
                    strict: false
                });
                if (match !== null) {
                    this.dispatchEvents(data);
                }
                break;
            default:
                console.log(`UPSERT: Unknown type: ${type}`, data);
        }
    }
    dispatchEvents(data) {
        data = Array.isArray(data) ? data : [data];
        const initialPage = ['before', null];
        const { events } = store.getState();
        const filters = Immutable.asMutable(events.getIn(['table','filters']), { deep: true });
        const filterCreatedAt = filters.find(f => f.columnName === 'created_at');
        const isInitialPage = initialPage.every(p => filterCreatedAt.value.navigation.includes(p));
        const lastItem = (events.items !== null && events.items.length > 0) ? events.items[events.items.length - 1] : null;

        if (lastItem && isInitialPage) {
            const lastItemTimestamp = (new Date(lastItem.created_at)).getTime();
            data.filter(event => (new Date(event.created_at)).getTime() > lastItemTimestamp)
                .map(event => store.dispatch(processEvent(event)));
        }
    }
    updateIfObjectExists(type, data){
        if(Array.isArray(data)){
            data = data[0];
        }
        //If App.Site already has a reference to this device, we set props
        if ( this.hasOwnProperty(type) && this[type].hasOwnProperty(data.id)) {
            this[type][data.id].setProps(data, true);
            return true;
        } else {
            return false;
        }
    }
    destroy(type, data){
        switch(type){
            case 'scenes':
            case 'locations':
                if( this.hasOwnProperty(type)
                    && this[type].hasOwnProperty(data.id)
                ){
                    this[type][data.id].destroy(true);
                }
                break;
            default:
                console.log('Unknown object type, skipping destroy', type, data);
                break;
        }
    }
    get all(){ // eslint-disable-line
        //SGD: Consider adding return in front?
        this.get();
    }

    set Name(newName){
        /*newName = newName.trim();
        //Remove currently illegal
        if (newName.indexOf('\'') >= 0) {
            newName = newName.replace(/'/g, '');
        }
        //make async call to set data in the cloud, then set local value to match
        (async () => {
            let val = await this.patch({name:newName});
            this._name = val.name;//set name
        })();*/
        this.setProps({name:newName});
    }

    get Name() {
        let name = this.name
        if(name && name.length > 23) {
            return name.substring(0,20)+'...';
        }
        return name;
    }
}

export default Amatis;
