import { ADD_TASK_SUCCESS, ADD_TASK_FAILURE, ADD_TASK_REQUEST, ADD_TASK_FULLFILL } from "./actions";

const initialState = {
    error: null,
    items: null,
    loading: false,
    mode: {
        edit: false,
    },
    meta: {
        search: "",
        editable: [],
        checked: [],
        stageNames: [],
    }
};

const handleAdd = (state, { payload }) => {
    if (!state.items) {
        return Object.assign({}, state, { items: payload, loading: true });
    }
    return Object.assign({}, state, { items: payload, loading: true });
}

const handleFailure = (state, { payload }) =>
    Object.assign({}, state, { error: payload, loading: false });

const handleRequest = (state) =>
    Object.assign({}, state, { error: null, loading: true });

const handleFulfill = state => Object.assign({}, state, { loading: false });

const handlers = {
    [ADD_TASK_SUCCESS]: handleAdd,
    [ADD_TASK_FAILURE]: handleFailure,
    [ADD_TASK_REQUEST]: handleRequest,
    [ADD_TASK_FULLFILL]: handleFulfill,
};

const reducer = (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
