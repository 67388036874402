import {
    LIST_SCHEDULES_REQUEST,
    LIST_SCHEDULES_FAILURE,
    LIST_SCHEDULES_FULFILL,
} from './actions';

const initialState = {
    error: null,
    loading: true,
};

const handleRequest = state => Object.assign({}, state, { loading: true, error: null });
const handleFailure = (state, { payload }) => Object.assign({}, state, { error: payload });
const handleFulfill = state => Object.assign({}, state, { loading: false });

const handlers = {
    [LIST_SCHEDULES_REQUEST]: handleRequest,
    [LIST_SCHEDULES_FAILURE]: handleFailure,
    [LIST_SCHEDULES_FULFILL]: handleFulfill,
};

const reducer = (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
