// @ts-nocheck
import App from "app/app.js";
import Devices from "../../../devices/services/devices";
import type { Location, Scene, KeyObjectType, Device, TriggerScene, Issue, Category, ValidatorKey } from "../../types";
import { normilzeIssue } from "../../utils/IssuesNormalizer";

const triggersScenesBlackList = ["direct_control", "load-shed-start", "load-shed-end", "start-open-hours", "start-closed-hours"];
const motionDeviceTypeBlackList = ["AM-DCDIM-PWM"];
const occDescriptionScenes = ["occupied", "unoccupied", "occupied_nighttime", "unoccupied_nighttime", "nh_grace", "ah_grace"]

const getLocationSwtchDevices = (location: Location) => {
    const locationSwitchDevices: KeyObjectType<Device> = Object.keys(location.devices)
        .filter((key) => location.devices[key].is_switch)
        .reduce((obj: KeyObjectType<Device>, key) => {
            obj[key] = location.devices[key];
            return obj;
        }, {});

    return locationSwitchDevices
}

export const validateSwitchTriggers = (location: Location, scene: Scene, validatorKey: ValidatorKey, categorySceneKey: Category) => {
    const missingSwitchDevices: Issue[] = [];
    const locationSw8Inputs = location.sw8Inputs;
    const locationSwitchDevices = getLocationSwtchDevices(location);

    if (Object.keys(scene.triggers).length > 0 &&
        (Object.keys(locationSw8Inputs).length > 0 || Object.keys(locationSwitchDevices).length > 0)) {
        for (let index in locationSwitchDevices) {
            const switchDevice = locationSwitchDevices[index];
            // Only comparing last bytes to avoid confusion with interface ID
            const deviceInTriggerList = Object.keys(scene.triggers).find(
                (key) => ("tiny_ip" in scene.triggers[key] && scene.triggers[key].tiny_ip.substr(-14) === switchDevice.ip_address.substr(-14) && Devices.switch.getLinkIDFromVar1(scene.triggers[key].var_1) === scene.link_id));
            if (!deviceInTriggerList) {
                missingSwitchDevices.push(
                    normilzeIssue({
                        name: switchDevice.nameWithLastFour,
                        identifierId: index,
                        errorMessage: `${switchDevice.nameWithLastFour} switch is missing from trigger`,
                        locationName: location.name,
                        validatorKey: validatorKey,
                        type: "switchTrigger",
                        category: categorySceneKey,
                        sceneData: scene
                    })
                )
            }
        }
        for (let index in locationSw8Inputs) {
            const sw8Device = locationSw8Inputs[index];
            const sw8InTriggerList = Object.keys(scene.triggers).find(
                (key) => (scene.triggers[key].ip_address === sw8Device.parent_ip_address));
            if (!sw8InTriggerList) {
                missingSwitchDevices.push(
                    normilzeIssue({
                        name: sw8Device.parent.nameWithLastFour,
                        identifierId: index,
                        errorMessage: `${sw8Device.parent.nameWithLastFour} switch is missing from trigger`,
                        locationName: location.name,
                        validatorKey: validatorKey,
                        type: "switchTrigger",
                        category: categorySceneKey,
                        sceneData: scene
                    })
                )
            }
        }
    }
    return missingSwitchDevices;
};

//test for duplicate and missing triggers in each location scene
export const validateTriggers = (location: Location, scene: Scene, validatorKey: ValidatorKey, categorySceneKey: Category) => {
    // scenes blacklist that shouldn"t have trigger validation
    const triggerIssues: Issue[] = [];
    const triggersMarked: KeyObjectType<TriggerScene> = {};
    const allDevices = App.Site.devices;
    const devLookup = App.Site.deviceLookup;

    if (!triggersScenesBlackList.includes(scene.description)) {
        if (scene.isSwitchControl) {
            triggerIssues.push(...validateSwitchTriggers(location, scene, validatorKey, categorySceneKey));
        } else {
            for (let index in location.devices) {
                const device = location.devices[index];
                if (scene.description === "motion_group" && (device.isMasterOccByRole || device.is_switch || motionDeviceTypeBlackList.includes(device.partType))) continue;
                if (occDescriptionScenes.includes(scene.description) && !device.isMasterOccByRole) continue;

                let hasTrigger = false;
                for (let index in scene.triggers) {
                    const trigger = scene.triggers[index];
                    if (trigger.ip_address === device.ip_address) {
                        hasTrigger = true;
                    }
                }
                if (hasTrigger === false) {
                    triggerIssues.push(
                        normilzeIssue({
                            name: device.nameWithLastFour,
                            identifierId: index,
                            errorMessage: `${device.nameWithLastFour} is missing from triggers`,
                            locationName: location.name,
                            validatorKey: validatorKey,
                            type: "missing",
                            category: categorySceneKey,
                            sceneData: scene
                        })
                    )
                }
            }
        }
    }

    // validate for each scene if triggers devices are duplicated
    for (let trigger in scene.triggers) {
        const sceneTrigger = scene.triggers[trigger];
        const device = allDevices[devLookup[sceneTrigger.ip_address]];
        if (triggersMarked.hasOwnProperty(device.id) &&
            sceneTrigger.var_1 === triggersMarked[device.id].var_1 &&
            scene.description !== "halt-fade" && !device.isMultiChannelOutputDevice
        ) {
            triggerIssues.push(
                normilzeIssue({
                    name: device.nameWithLastFour,
                    identifierId: trigger,
                    errorMessage: `${device.nameWithLastFour} has duplicate triggers`,
                    locationName: location.name,
                    validatorKey: validatorKey,
                    type: "duplicate",
                    category: categorySceneKey,
                    sceneData: scene
                })
            )
        } else {
            triggersMarked[device.id] = sceneTrigger;
        }
    }

    return triggerIssues

};
