import {
    listUsers,
    clearUsers,
    createUser,
    removeUser,
    onFiltersChange,
    onSortingChange,
    onCurrentPageChange,
    onPageSizeChange,
    updateUser,
} from "./actions";

const initialState = {
    error: null,
    items: [],
    loading: true,
    user: null,
    table: {
        sorting: [{ columnName: 'id', direction: 'asc' }],
        filters: [],
        currentPage: 0,
        pageSize: 10,
        pageSizes: [5, 10, 15, 0],
    }
};

const handleRequest = state =>
    Object.assign({}, state, { loading: true, error: null });

const handleSuccess = (state, { payload }) =>
    Object.assign({}, state, { items: payload });

const handleFailure = (state, { payload }) =>
    Object.assign({}, state, { error: payload });

const handleFulfill = state => Object.assign({}, state, { loading: false });

const handleSorting = (state, { payload }) =>
    Object.assign({}, state, { state, table: {
            ...state.table,
            sorting: payload
        }
    });

const handleFilters = (state, { payload }) =>
    Object.assign({}, state, { state, table: {
            ...state.table,
            filters: payload
        }
    });

const handleCurrentPage = (state, { payload }) =>
    Object.assign({}, state, { state, table: {
            ...state.table,
            currentPage: payload
        }
    });

const handlePageSize = (state, { payload }) =>
    Object.assign({}, state, { state, table: {
            ...state.table,
            pageSize: payload
        }
    });

    
const handleSuccessCreate = (state, { payload }) => 
    Object.assign({}, state, { state, items: [
            payload, ...state.items
        ]
    });

const handleSuccessRemove = (state, { payload }) =>
    Object.assign({}, state, { state, items: [
            ...state.items.filter(item => item.id !== payload.id)
        ]
    });

const handleSuccessUpdated = (state, { payload }) => {
    return Object.assign({}, state, { state, items: [
        ...state.items.map(item => {
            if(item.id === payload.data.id){
                return payload.data
            }
            return item;
    })]})
};

const handleClearUser = () => Object.assign({}, initialState);

const handlers = {
    [listUsers.request.toString()]: handleRequest,
    [listUsers.success.toString()]: handleSuccess,
    [listUsers.failure.toString()]: handleFailure,
    [listUsers.fulfill.toString()]: handleFulfill,
    [onSortingChange.toString()]: handleSorting,
    [onFiltersChange.toString()]: handleFilters,
    [onCurrentPageChange.toString()]: handleCurrentPage,
    [onPageSizeChange.toString()]: handlePageSize,
    [createUser.request.toString()]: handleRequest,
    [createUser.success.toString()]: handleSuccessCreate,
    [createUser.failure.toString()]: handleFailure,
    [createUser.fulfill.toString()]: handleFulfill,
    [removeUser.request.toString()]: handleRequest,
    [removeUser.success.toString()]: handleSuccessRemove,
    [removeUser.failure.toString()]: handleFailure,
    [removeUser.fulfill.toString()]: handleFulfill,
    [updateUser.request.toString()]: handleRequest,
    [updateUser.success.toString()]: handleSuccessUpdated,
    [updateUser.failure.toString()]: handleFailure,
    [updateUser.fulfill.toString()]: handleFulfill,
    [clearUsers.toString()]: handleClearUser,
};

const reducer = (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
