import $ from 'jquery';
import App from 'app/app.js';
import User from 'classes/users.js';

const { REACT_APP_API_URL_BASE_PATH } = process.env;

let Communication = {
    baseURL: '',
    //APIURL: 'https://api-staging.amatiscontrols.com/api/', //BETA URL
    //APIURL: 'https://api.amatiscontrols.com/api/',
    APIURL: `https://${REACT_APP_API_URL_BASE_PATH}.amatiscontrols.com/api/`,//BETA URL
    apiVersion: 'v1', //Default API version, individual calls can choose to use different versions
    HAL_BASE_URL: 'dash.amatiscontrols.com',
    v2URL:'https://api-beta.amatiscontrols.com/api/v2',

    //Sets root url for all API calls
    setBaseURL: function (newUrl) {
        //You can set a baseURL, otherwise we default to node01
        if (newUrl) {
            this.baseURL = newUrl;
        } else {
            this.baseURL = this.APIURL;
        }

        /*debug*/
        console.log('Set the base URL to: ' + this.baseURL);
        this.determineHalBaseUrl();

    },
    //Allows the caller to choose an api version, else default
    getBaseURL: function (version) {
        //If its cleared, we will go to default
        //Note: We clear this on sitelogout
        if (this.baseURL === '') {
            this.setBaseURL();
        }

        let fullBaseURL = (!version) ? (Communication.baseURL + Communication.apiVersion) : (Communication.baseURL + version);
        return fullBaseURL;
    },

    determineAMBRIP: function (code) {
        switch (code) {
        case 1:
            App.setLocalIP('http://am-br.local');
            //window.localStorage.setItem('App.localIP','http://am-br.local');
            break;

        case 2:
            App.setLocalIP(window.localStorage.getItem('App.localIP'));

            break;

        case 3:
            /*
            			if($.cookie('token')===null && $.cookie('refresh_token')!=null)
            				App.refreshToken($.cookie('refresh_token'));
            */

            if (window.localStorage.amatis_token != null) {
                //if(window.localStorage.getItem('siteID')!= null)
                //Communication.getSiteIPInfo(true);
            } else {
                User.logout();
                App.alert('alert', 'Your session has expired. You have been logged out.');
            }
            break;

        default:
            break;
        }
    },
    determineHalBaseUrl: function () {
        if (window.location.host === 'config.nextekpower.com') {
            Communication.HAL_BASE_URL = 'config.nextekpower.com';
            $('#amatis-favicon').remove();
            let link = document.createElement('link');
            link.id = 'nextek-favicon';
            link.rel = 'shortcut icon';
            link.href = 'Nextek-favicon.png';
            console.log(link);
            document.head.appendChild(link);

        }
    }
};

export default Communication;
