import { createActions, createAction } from 'redux-actions';
import SDK from 'api/sdk';
import SDKV3 from 'api/sdkV3';
import App from 'app/app';
import { delayBeforeAlert } from '../../api/utils';

export const LIST_AMBRS_REQUEST = 'LIST_AMBRS_REQUEST';
export const LIST_AMBRS_SUCCESS = 'LIST_AMBRS_SUCCESS';
export const LIST_AMBRS_FAILURE = 'LIST_AMBRS_FAILURE';
export const LIST_AMBRS_FULFILL = 'LIST_AMBRS_FULFILL';

export const MESSAGE_AMBRS_RECEIVE = 'MESSAGE_AMBRS_RECEIVE';
export const UPDATE_AMBR_LAST_PING = 'UPDATE_AMBR_LAST_PING';

export const updateAmbrLastPing = createAction(UPDATE_AMBR_LAST_PING);
export const messageAmbrsReceive = createAction(MESSAGE_AMBRS_RECEIVE);

export const {
    listAmbrsRequest,
    listAmbrsSuccess,
    listAmbrsFailure,
    listAmbrsFulfill
} = createActions(
    LIST_AMBRS_REQUEST,
    LIST_AMBRS_SUCCESS,
    LIST_AMBRS_FAILURE,
    LIST_AMBRS_FULFILL,
);

export const fetchAmbrs = (siteId) => dispatch => {
    const params = { SITEID: siteId };
    dispatch(listAmbrsRequest());
    return SDK.init().then(api => {
        api.ambr.getAmbrsBySiteId(params)
        .then(({ body: { data }}) => {
            dispatch(listAmbrsSuccess(data));
        })
        .catch(error => {
            dispatch(listAmbrsFailure(error));
        })
        .finally(() => {
            dispatch(listAmbrsFulfill());
        });
    })
}

export const fetchAllAmbrs = (siteId) => dispatch => {
    const params = { SiteID: siteId };
    dispatch(listAmbrsRequest());
    try {
        return SDKV3.init().then(api => {
            api.ambr.getAmbrs(params)
            .then(({ body: { data }}) => {
                dispatch(listAmbrsSuccess(data));
            })
            .catch(error => {
                dispatch(listAmbrsFailure(error));
            })
            .finally(() => {
                dispatch(listAmbrsFulfill());
            });
        })
    } catch (error) {
        console.log("error", error)
    }
}

export const updateAmbrFromMqtt = (message) => (dispatch, getState) => {
    const ambrs = message[App.activeSiteID];
    let bulkMessage = [];
    for (let ambr in ambrs) {
        const infoAmbr = ambrs[ambr]
        if (infoAmbr.topic_info !== "hey" && infoAmbr.general_info.hasOwnProperty("data")){
            let statusString = '✅';
            if(infoAmbr.topic_info.last_ping > (delayBeforeAlert - 60)){
                statusString = '⚠️';
            }
            const data = infoAmbr.general_info.data;
            const last_ping = infoAmbr.topic_info.last_ping || '';
            const newMessage = {
                globalIP: data.global_ip,
                localIP: data.local_ip,
                VPNState: data.vpn_state,
                SiteID: data.siteID,
                MacAddy: data.mac,
                SerialNumber: data.serial_no,
                Version: data.ambr_version,
                neighbors: data.neighbors,
                mqtt_worker: data.mqtt_worker,
                pan_id: data.pan_id,
                tick: data.tick,
                routes_with_fails: data.routes_with_fails,
                uptime: data.uptime,
                routes: data.routes,
                channel: data.channel,
                disk_utilization: data.disk_utilization,
                throttle_write: data.throttle_write,
                sd_card_mounted: data.sd_card_mounted,
                local_mqtt_connected: data.local_mqtt_connected,
                timezone: data.timezone,
                uptime_seconds: data.uptime_seconds,
                master_ambr: data.master_ambr,
                last_ping: `${last_ping}s`,
                contiki_ticks: data.contiki_ticks,
                status: statusString,
            }
            bulkMessage.push(newMessage);
        }
    }
    dispatch(messageAmbrsReceive(bulkMessage));
}
