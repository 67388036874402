import { retrieveUser, clearUser, addSitesToUser, updateUser, getUserSites, clearUserSites, deleteSiteToUser } from "./actions";

const initialState = {
    error: null,
    item: {
        id: null,
        email: '',
    },
    sites: null,
    loading: false,
    isSubmitting: false
};

const handleRequest = state =>
    Object.assign({}, state, { loading: true, error: null });

const handleSuccess = (state, { payload }) => {
    const userObj = payload.hasOwnProperty('data') ? { ...payload.data } : { ...payload };
    return Object.assign({}, state, { item: userObj });
}

const handleFailure = (state, { payload }) =>
    Object.assign({}, state, { error: payload });

const handleFulfill = state => Object.assign({}, state, { loading: false });

const handleClear = () => Object.assign({}, initialState);

const handleClearSites = (state) => Object.assign({}, state, { sites: null });

const handleSubmittingTrue = (state) =>
    Object.assign({}, state, { isSubmitting: true });

const handleSubmittingFalse = (state) =>
    Object.assign({}, state, { isSubmitting: false });

const handleRequestUserSites = (state) =>
    Object.assign({}, state, { loading: true, error: null });

const handleSuccessUserSites = (state, { payload }) =>
    Object.assign({}, state, { sites: payload });

const handleFailureUserSites = (state, { payload }) =>
    Object.assign({}, state, { loading: false, error: payload });

const handleFulfillUserSites = (state) =>
    Object.assign({}, state, { loading: false });

const handleRemoveSite = (state, { payload }) =>
    Object.assign({}, state, { sites: state.sites.filter(item => item.siteid != payload) });


const handlers = {
    [retrieveUser.request.toString()]: handleRequest,
    [retrieveUser.success.toString()]: handleSuccess,
    [retrieveUser.failure.toString()]: handleFailure,
    [retrieveUser.fulfill.toString()]: handleFulfill,
    [updateUser.request.toString()]: handleSubmittingTrue,
    [updateUser.success.toString()]: handleSuccess,
    [updateUser.failure.toString()]: handleFailure,
    [updateUser.fulfill.toString()]: handleSubmittingFalse,
    [addSitesToUser.request.toString()]: handleSubmittingTrue,
    [addSitesToUser.fulfill.toString()]: handleSubmittingFalse,
    [clearUser.toString()]: handleClear,
    [getUserSites.request.toString()]: handleRequestUserSites,
    [getUserSites.success.toString()]: handleSuccessUserSites,
    [getUserSites.failure.toString()]: handleFailureUserSites,
    [getUserSites.fulfill.toString()]: handleFulfillUserSites,
    [clearUserSites.toString()]: handleClearSites,
    [deleteSiteToUser.success.toString()]: handleRemoveSite,
};

const reducer = (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
