import App from 'app/app.js';
import Amatis from 'api/amatis/amatis.js';

class Ambr extends Amatis {
    constructor(info){
        super(info);
        this.amatisType = 'ambrs';
        this.siteID = info.siteID;
        this.devices = info.devices;
        this.profiles = info.profiles;
        this.multiData = info.multiData;
        this.profileAssignments = info.profileAssignments;
        this.forward = 'me';
        if(info.hasOwnProperty('deviceStates')){
            this.deviceStates = info.deviceStates;
            App.Site.ambrHasStates = true;
        }
        this.currentProfile = false;
        this.oldProfile = false;
        this.version = info.version;
        App.Site.ambrs[info.id] = this;
        if(info.id.indexOf(App.Site.ID) >= 0){
            App.Site.thisAmbr = this;
        }
    }
    async getReq(endpoint, dest='me'){
        endpoint = `ambrs/${dest}/${endpoint}`;
        let response = await App.Site.post({'method':'GET','url':endpoint},'api', 'ambr');
        return response;
    }

    async postReq(data, endpoint, dest='me'){
        endpoint = `ambrs/${dest}/${endpoint}`;
        let request = {'method':'POST','url':endpoint};
        Object.assign(request, data);
        let response = await App.Site.post(request,'api?timeout=28000', 'ambr');
        return response;
    }
    async getState(){
        let info = await this.getReq('state');
        if(info.hasOwnProperty('data')){
            info = info.data;
            this.deviceStates = info.deviceStates;
            this.profiles = info.profiles;
            this.profileAssignments = info.profileAssignments;
            this.currentProfile = this.profile;
        }
    }
    //tell ambr to make a new secure profile, multicast to tell devices to move to that profile and move to the profile itself
    async secure(channel=false){
        let data = {};
        this.oldProfile = this.profile;
        if(channel !== false){
            data['channel'] = channel;
        }
        data['sourceProfile'] = this.oldProfile;
        let response = await this.postReq(data, 'secure');
        if(response.hasOwnProperty('data')){
            this.currentProfile = response.data.profileID;
            this.profiles[this.currentProfile] = response.data.profile;
            this.deviceStates = response.data.deviceStates;
        }else{//if we didnt get the data due to timeout or something well force get the state from ambr to fill in necessary details
            await this.getState();
        }
        setTimeout(()=>{//wait a sec so routes have time to start coming in then call confirm
            this.confirm();
        }, 6000);
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    //flip back to old profile and send current profile move and reboot (could be used to quickly grab devices from defaults)
    async recover(oldProfile=this.oldProfile, currentProfile=this.currentProfile){
        if(oldProfile === false || currentProfile === false){
            console.log('profiles required');
            return;
        }
        let data ={
            "currentProfile":currentProfile,
            "recoverFrom":oldProfile,
        };
        await this.postReq(data, 'recover');
    }
    //confirms devices are at new secure profile by getting live devices from ambr and checking against deviceStates and devices that were talking before the move
    async confirm(){
        let confirmed = 0;
        let numDevices = Object.keys(this.devices).length;
        let tries = 0;//num times we try to recover devices that havent made it
        let count = 0;//count of times we've gotten devices from ambr and compared to state
        let maxTries = 3;
        let devices;
        while(confirmed < numDevices){
            devices = await this.liveDevices();
            if(devices === false){//means file is still cleared from softreboot, wait a second and try again
                this.sleep(1000);
                continue;
            }
            for(let id in devices){
                if(id in this.devices){
                    if(id in this.deviceStates && this.deviceStates[id].confirmed === false && this.deviceStates[id].dest === this.currentProfile){
                        confirmed++;
                        this.deviceStates[id].confirmed = true;
                    }
                }
            }
            await this.sleep(2000);
            if(++count === 5){
                tries++;
                count = 0;
                await this.recover();
            }
            if(tries === maxTries -1 && confirmed !== numDevices){
                if(window.confirm("Not all devices have been confirmed at the new secure profile, continue trying to retrieve them?")){
                    tries = 0;

                }else{
                    break;
                }
            }
        }
        if(confirmed === numDevices){
            alert('Network secured!');
        }
        this.postReq({"deviceStates":JSON.stringify(this.deviceStates)}, "device_states");
    }

    checkStates(){
        let changed = false;
        if(this.deviceStates !== false){
            for(let id in this.deviceStates){
                if(id in this.devices && this.deviceStates[id].confirmed === false && this.deviceStates[id].dest === this.profile){
                    this.deviceStates[id].confirmed = true;
                    changed = true;
                }
            }
            if(changed === true){
                this.postReq({"deviceStates":JSON.stringify(this.deviceStates)}, "device_states");
            }
        }
    }

    async liveDevices(){
        let response = await this.getReq('live_devices');
        if(response.hasOwnProperty('data')){
            return response.data.devices;
        }else{
            return false;
        }

    }

    get profile(){
        for(let id in this.profileAssignments){
            if(this.profileAssignments[id] === this.ID && id in this.profiles){
                return id;
            }
        }
        return false;
    }

    async softReboot(){
        await this.getReq('softreboot');
    }

    setIPInfo(staticIP = false, data = false){
        let endpoint;
        if(staticIP !== false && data !== false){
            endpoint = 'static_ip';
        }else{
            endpoint = 'dynamic_ip';
        }
        this.post(data, endpoint);
    }

    scan(){
        this.get('scan');
    }

    setToChild(){
        this.post('set_to_child');//add dynamic reference to master
    }

    setToMaster(){
        this.post('set_to_master');
    }
    //send current profiles to ambr for storage
    async setProfiles(profiles = false){
        profiles = (profiles === false ? this.profiles : profiles);
        profiles = await this.post(profiles, 'profiles');
        this.profiles = profiles;
    }

    //will return the corresponding length 4 ID from a length 12 string.
    //handles cases where a string of length 3,4 is passed in, so that scan, etc. of old routers can work.
    get shortID(){
        let IDString = this.id;
        let IDLength = this.id.length;
        if (IDLength !== 12){
            if (IDLength === 4) return IDString;
            if (IDLength === 3) return '0' + IDString;
            return false;
        }
        //if we get here, we assume a valid length 12 ID ( === to MAC) was passed in. decide if amatis MAC or 508 MAC and return accordingly
        let amatisMACprefix = '88a3cc';
        return (IDString.indexOf(amatisMACprefix) === 0) ? '0' + IDString.substr(6, 3) : IDString.substr(-4);
    }
}

export default Ambr;
