import {
    LIST_DEVICES_REQUEST,
    LIST_DEVICES_SUCCESS,
    LIST_DEVICES_FAILURE,
    LIST_DEVICES_FULFILL,
    DEVICES_SORTING,
    DEVICES_GROUPING,
    DEVICES_SELECTION,
    DEVICES_FILTERS,
    DEVICES_EXPANDED_GROUPS,
    DEVICES_EXPANDED_ROW_IDS,
    CLEAR_DEVICES,
    DEVICES_ADD_READOUT,
    DEVICE_MODE_EDIT,
    DEVICE_MODE_ADD,
    DEVICE_DELETE,
    DEVICE_OPEN_MODAL,
    DEVICE_ADD_DEVICE,
    SET_CHECKED_DEVICES,
    SET_ADDING_DEVICES,
    SEARCH_DEVICES_REQUEST,
    SEARCH_DEVICES_SUCCESS,
    SEARCH_DEVICES_FAILURE,
    SEARCH_DEVICES_FULFILL,
} from './actions';

const initialState = {
    error: null,
    items: [],
    itemsSearch: [],
    loading: true,
    mode: {
        edit: [],
        add: [],
    },
    openModal: false,
    checked: null,
    adding: false,
    meta: {
        sorting: [{ columnName: 'nameWithLastFour', direction: 'desc' }],
        grouping: [],
        selection: [],
        filters: [],
        expandedGroups: [],
        expandedRowIds: [],
        readout: [],
        pagination: {
            limit: 25,
            offset: 0,
            count: 0,
            before: null,
            after: null,
        }
    }
};

const handleRequest = state => Object.assign({}, state, { loading: true, error: null });
const handleSuccess = (state, { payload }) => Object.assign({}, state, { items: payload });
const handleSearchSuccess = (state, { payload }) =>
    Object.assign({}, state, {
        itemsSearch: [...state.itemsSearch, ...payload.data],
        meta: {...state.meta, pagination: payload.meta.pagination }
    });
const handleFailure = (state, { payload }) => Object.assign({}, state, { error: payload });
const handleFulfill = state => Object.assign({}, state, { loading: false });
const handleSorting = (state, { payload }) => {
    return Object.assign(
        {}, state, { meta: { ...state.meta, sorting: payload } }
    )
};
const handleGrouping = (state, { payload }) => {
    return Object.assign(
        {}, state, { meta: { ...state.meta, grouping: payload } }
    )
};
const handleSelection = (state, { payload }) => {
    return Object.assign(
        {}, state, { meta: { ...state.meta, selection: payload } }
    )
};
const handleFilters = (state, { payload }) => {
    return Object.assign(
        {}, state, { meta: { ...state.meta, filters: payload } }
    )
};
const handleExpandedGroups = (state, { payload }) => {
    return Object.assign(
        {}, state, { meta: { ...state.meta, expandedGroups: payload } }
    )
};
const handleExpandedRowIds = (state, { payload }) => {
    return Object.assign(
        {}, state, { meta: { ...state.meta, expandedRowIds: payload } }
    )
};

const handleReadout = (state, { payload }) => {
    return Object.assign(
        {}, state, { meta: { ...state.meta, readout: [payload, ...state.meta.readout] } }
    )
};

const handleClear = () => Object.assign({}, initialState);

const handleModeEdit = (state,{ payload }) => {
    let modeEdit = state.mode.edit;
    if (modeEdit.includes(payload)) {
        modeEdit = modeEdit.filter(id => id !== payload);
    } else {
        modeEdit.push(payload);
    }
    return Object.assign({}, state, { mode: { ...state.mode, edit: modeEdit } });
}

const handleModeAdd = (state, { payload }) => {
    let modeEdit = state.mode.add;
    if (modeEdit.includes(payload)) {
        modeEdit = modeEdit.filter(id => id !== payload);
    } else {
        modeEdit.push(payload);
    }
    return Object.assign({}, state, { mode: { ...state.mode, add: modeEdit } });
}

const handleDelete = (state, { payload }) => {
    const items = state.items.filter(item => item.ip_address !== payload);
    return Object.assign(state, { items });
}

const handleOpenModal = (state, {payload}) =>
    Object.assign({}, state, { openModal: payload });


const handleAddDevice = (state, { payload }) =>  Object.assign({}, state, { items: [payload, ...state.items] });

const handleCheckedDevices = (state, {payload}) => Object.assign({}, state, { checked: payload });

const handleAddingDevices = (state, {payload}) => Object.assign({}, state, { adding: payload });

const handlers = {
    [SEARCH_DEVICES_REQUEST]: handleRequest,
    [SEARCH_DEVICES_SUCCESS]: handleSearchSuccess,
    [SEARCH_DEVICES_FAILURE]: handleFailure,
    [SEARCH_DEVICES_FULFILL]: handleFulfill,
    [LIST_DEVICES_REQUEST]: handleRequest,
    [LIST_DEVICES_SUCCESS]: handleSuccess,
    [LIST_DEVICES_FAILURE]: handleFailure,
    [LIST_DEVICES_FULFILL]: handleFulfill,
    [DEVICES_SORTING]: handleSorting,
    [DEVICES_GROUPING]: handleGrouping,
    [DEVICES_SELECTION]: handleSelection,
    [DEVICES_FILTERS]: handleFilters,
    [DEVICES_EXPANDED_GROUPS]: handleExpandedGroups,
    [DEVICES_EXPANDED_ROW_IDS]: handleExpandedRowIds,
    [CLEAR_DEVICES]: handleClear,
    [DEVICES_ADD_READOUT]: handleReadout,
    [DEVICE_MODE_EDIT]: handleModeEdit,
    [DEVICE_MODE_ADD]: handleModeAdd,
    [DEVICE_DELETE]: handleDelete,
    [DEVICE_OPEN_MODAL]: handleOpenModal,
    [DEVICE_ADD_DEVICE]: handleAddDevice,
    [SET_CHECKED_DEVICES]: handleCheckedDevices,
    [SET_ADDING_DEVICES]: handleAddingDevices,
};

const reducer = (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
