import App from 'app/app.js';
import Schedule from 'api/amatis/site/schedules/schedules.js';
import jQuery from 'jquery';

const null_location_id = 0;

///////////////////////////////
///Key down listeners/////////
/////////////////////////////
var keyDownTextField = function (e) {
    var keyCode = e.keyCode;
    if (keyCode === 13) {
        App.alert('info', 'saving');
        Schedule.cron_save();
    }
};

/////////////////////////////
////////// EXTRA SHIT ///////
////////////////////////////
jQuery.fn.selectText = function () {
    var doc = document;
    var element = this[0];
    var range;

    try {
        if (doc.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();
        } else if (window.getSelection) {
            var selection = window.getSelection();
            range = document.createRange();
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    } catch (e) {
        console.log(e);
    }
};

function set_union(setA, setB) {
    var _union = new Set(setA);
    for (var elem of setB) {
        _union.add(elem);
    }
    return _union;
}

//Useful for removing duplicates of anything from an array
function remove_duplicates(objectsArray) {
    var usedObjects = {};
    for (var i = objectsArray.length - 1; i >= 0; i--) {
        var so = JSON.stringify(objectsArray[i]);

        if (usedObjects[so]) {
            objectsArray.splice(i, 1);

        } else {
            usedObjects[so] = true;
        }
    }
    return objectsArray;
}

/**
 * A function for converting hex <-> dec w/o loss of precision.
 *
 * The problem is that parseInt("0x12345...") isn't precise enough to convert
 * 64-bit integers correctly.
 *
 * Internally, this uses arrays to encode decimal digits starting with the least
 * significant:
 * 8 = [8]
 * 16 = [6, 1]
 * 1024 = [4, 2, 0, 1]
 */

// Adds two arrays for the given base (10 or 16), returning the result.
// This turns out to be the only "primitive" operation we need.
function add(x, y, base) {
    var z = [];
    var n = Math.max(x.length, y.length);
    var carry = 0;
    var i = 0;
    while (i < n || carry) {
        var xi = i < x.length ? x[i] : 0;
        var yi = i < y.length ? y[i] : 0;
        var zi = carry + xi + yi;
        z.push(zi % base);
        carry = Math.floor(zi / base);
        i++;
    }
    return z;
}

// Returns a*x, where x is an array of decimal digits and a is an ordinary
// JavaScript number. base is the number base of the array x.
function multiplyByNumber(num, x, base) {
    if (num < 0) return null;
    if (num === 0) return [];

    var result = [];
    var power = x;
    while (num !== 0) {
        if (num & 1) {
            result = add(result, power, base);
        }
        num = num >> 1;
        // if (num === 0) break;
        power = add(power, power, base);
    }

    return result;
}

function parseToDigitsArray(str, base) {
    var digits = str.split('');
    var ary = [];
    for (var i = digits.length - 1; i >= 0; i--) {
        var n = parseInt(digits[i], base);
        if (isNaN(n)) return null;
        ary.push(n);
    }
    return ary;
}

function convertBase(str, fromBase, toBase) {
    var digits = parseToDigitsArray(str, fromBase);
    if (digits === null) return null;

    var outArray = [];
    var power = [1];
    for (var i = 0; i < digits.length; i++) {
        // invariant: at this point, fromBase^i = power
        if (digits[i]) {
            outArray = add(outArray, multiplyByNumber(digits[i], power, toBase), toBase);
        }
        power = multiplyByNumber(fromBase, power, toBase);
    }

    var out = '';
    for (var index = outArray.length - 1; index >= 0; index--) {
        out += outArray[index].toString(toBase);
    }
    return out;
}

function decToHex(decStr) {
    if(typeof(decStr) != 'string'){
        decStr = decStr.toString();
    }
    if (decStr === '0') {
        return '0x0';
    }
    var hex = convertBase(decStr, 10, 16);
    return hex ? '0x' + hex : '';
}

function hexToDec(hexStr) {
    if(typeof(hexStr) != 'string'){
        hexStr = hexStr.toString();
    }
    if (hexStr.substring(0, 2) === '0x') hexStr = hexStr.substring(2);
    hexStr = hexStr.toLowerCase();
    return convertBase(hexStr, 16, 10);
}

function isnull_location_id(uuid){
    return (uuid === null_location_id) ? true : false;
}

export const exportToCsv = (filename, items) => {
    let csv = "";
    for(let row = 0; row < items.length; row++){
        let keysAmount = Object.keys(items[row]).length
        let keysCounter = 0
        if(row === 0) {
            for(let key in items[row]){
                csv += key + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
                keysCounter++
            }
            keysCounter = 0
        }
        for(let key in items[row]) {
            csv += items[row][key] + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
            keysCounter++
        }
        keysCounter = 0
    }
    var link = document.createElement("a");
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${filename}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export {
    keyDownTextField,
    set_union,
    remove_duplicates,
    decToHex,
    hexToDec,
    isnull_location_id,
    null_location_id,
};
