import { validateAppliedFeatures, validateGracePeriodFeatures } from "./validatorResources/Features";
import { validateLocationScenes } from "./validatorResources/scenes";
import { generateSuccessMessage } from "./utils/GenerateIssues";
import { validatorActionTables } from "./validatorResources/ActionTables"
import type { Issue, ValidatorType, Location } from "./types";

class Validator {
    location: Location;
    issues: Issue[];

    constructor(location: Location) {
        this.location = location;
        this.issues = []
        this.validateLocation = this.validateLocation.bind(this);
    }

    validateLocation () {
        const issues: Issue[] = [];

        if (this.location.isRoot || (this.location.isParent && Object.keys(this.location.parentalScenes).length < 1)) return;

        const validators: ValidatorType = [
            { key: "missingFeatures", validator: validateAppliedFeatures },
            { key: "gracePeriod", validator: validateGracePeriodFeatures },
            { key: "actionTables", validator: validatorActionTables},
            { key: "scenes", validator: validateLocationScenes }
        ]

        validators.forEach(({ key, validator }) => {
            issues.push(...validator(this.location, key));
        });

        this.issues = issues
    }

    generateValidatorSuccessMessage = () => generateSuccessMessage();
}

export default Validator;
