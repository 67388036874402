import Immutable from 'seamless-immutable';


import { setFiltersFromQueryParams, resetFiltersFromQueryParams } from "./actions";


export const initialState = Immutable({
  filters: {
    datapoint: null,
    urlPath: "",
    timestart: ['-6h']
  },
});

const buildFilterFromQueryParams = (payload, state) => {
  const { query, pathname, search, key, value } = payload;
  let filters = {...state.filters};
  if (query) {
    for ( var filter in query ) {
      if (filter === 'datapoint'){
        let datapoints = query.datapoint.split(',').map(datapoint => parseInt(datapoint))
        Object.assign(filters, { datapoint: datapoints });
      } else {
        Object.assign(filters, { [filter]: query[filter].replaceAll('%20', ' ').split('%2C') });
      }
    }
  } else if (key && value) {
    Object.assign(filters, { [key]: value });
  }
  if (pathname && search) {
    Object.assign(filters, { urlPath: `${window.location.host}${pathname}${search}` });
  }

  return filters;
}

const handleSetFilterFromQueryParams = (state, { payload }) => {
  const filters = buildFilterFromQueryParams(payload, state);

  return state.set('filters', filters );
};

const handleCleanFiltersFromQueryParams = state => state.setIn(['filters'], initialState.getIn(['filters']));

const handlers = {
  [setFiltersFromQueryParams.toString()]: handleSetFilterFromQueryParams,
  [resetFiltersFromQueryParams.toString()]: handleCleanFiltersFromQueryParams,
};

const reducer = (state = initialState, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
