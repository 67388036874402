import { createSelector } from 'reselect';

export const getSites = state => state.sites;
export const isLoadingSites = createSelector(getSites, sites => sites.loading);
export const getSitesMeta = createSelector(getSites, sites => sites.meta);
export const getSitesMetaSearch = createSelector(getSitesMeta, meta => meta.search);
export const getSitesError = createSelector(getSites, sites => sites.error);

export const getSitesItems = createSelector(getSites, getSitesMetaSearch, (sites, search) => {
    let items = sites.items;

    if (items !== null) {
        items = items.filter(item => item.siteid !== null && item.dashboard !== '');
    }

    if (items !== null && search !== '') {
        return items.filter(({ dashname, siteid }) =>
            `${dashname} (${siteid})`.toLowerCase().includes(search.toLowerCase())
        );
    }
    return items
});

export const getSitesTable = createSelector(getSites, sites => sites.table);

export const getSitesTableSorting = createSelector(getSitesTable, table => table.sorting);
export const getSitesTableFilters = createSelector(getSitesTable, table => table.filters);
export const getSitesTableCurrentPage = createSelector(getSitesTable, table => table.currentPage);
export const getSitesTablePageSize = createSelector(getSitesTable, table => table.pageSize);
export const getSitesTablePageSizes = createSelector(getSitesTable, table => table.pageSizes);
