import $ from 'jquery';
import App from 'app/app.js';
import Devices from 'api/amatis/site/devices/services/devices.js';
import Amatis from 'api/amatis/amatis.js';

class Group extends Amatis {
    //Goroups constructor
    constructor (groupInfo) {
        super(groupInfo);
        this.amatisType = 'groups';
        this.ID = groupInfo.device_groups_id; //group ID
        this.id = this.ID;
        this.name = groupInfo.name;
        this.parent = groupInfo.parent_id;
        this.description = groupInfo.description;
        this.siteID = groupInfo.site_id;

        //Push this thing to the list(s) that holds it
        App.Site.groups[this.ID] = this;
    }
}

let Groups = {
    list: [],
    //get group index in Groups.list[] by ID
    getIndexByID: function (groupID) {

        let len = Groups.list.length;
        let i;

        for (i = 0; i < len; i++) {
            if (Groups.list[i].ID === groupID) {
                return i;
            }

        }

        return -1;

    },

    buildGroupHTML: function () {
        let groupLength = Groups.list.length;
        let groupListHtml = '<ul id=\'group-select-list\'>';
        let i;

        if ($('#group-device-selector').children().length >= groupLength) {

            for (i = 0; i < groupLength; i++) {
                if (i === (groupLength - 2)) {
                    groupListHtml += '<li class="group-select-list-item border-bottom" data-group-id="' + Groups.list[i].ID + '">' + Groups.list[i].name + '<span class="show-group-add">tag with group<i class="icon-tag add-devices-to-group-tag"></i></span></li>';
                } else {
                    groupListHtml += '<li class="group-select-list-item border-bottom" data-group-id="' + Groups.list[i].ID + '">' + Groups.list[i].name + '<span class="show-group-add">tag with group<i class="icon-tag add-devices-to-group-tag"></i></span></li>';

                }

            }

        } else if ($('#group-device-selector').children().length < groupLength) { //for if there have been more groups added
            for (i = $('#group-device-selector').children().length; i < groupLength; i++) {
                groupListHtml += '<li class="group-select-list-item" data-group-id="' + Groups.list[i].ID + '">' + Groups.list[i].name + '<span class="show-group-add">tag with group<i class="icon-tag add-devices-to-group-tag"></i></span></li>';
            }
        }

        groupListHtml += '</ul>';

        $('#tag-groups-container').html(groupListHtml);

    },
    //find all devices associated with a specific group
    findAssocDevices: function (groupID) {
        let len = Devices.list.length;
        let i;
        let assocDevices = [];
        for (i = 0; i < len; i++) { //creates a list of associated devices per group and returns it
            if (Devices.list[i].assocGroups.indexOf(groupID.toString()) >= 0) {
                assocDevices.push(Devices.list[i].IP);
            }
        }

        return assocDevices;
    }
    //function to save a new group, calls post to API
    /*aveNew (name, description) { //option to make new group or tag device with existing group

        let myURL = Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/device_groups';

        $.ajax({
            type: 'POST',
            data: {
                'site_id': App.activeSiteID,
                'name': name
            },
            dataType: 'json',
            url: myURL,
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
            success: function (response) {
                if (response.errors.length < 1) {
                    let newGroupObj = {
                        device_groups_id: response.data.device_groups_id,
                        name: name,
                        parent_id: null,
                        description: description,
                        site_id: App.activeSiteID

                    };
                    newGroup = new Groups.Group(newGroupObj);
                    Groups.list.push(newGroup);
                    //Groups.buildGroupHTML();

                    let listHtml = '<li id = \'group-select-' + newGroup.ID + '\' class=\'group-select-list-item\' data-group-id=\'' + newGroup.ID + '\'>' + newGroup.name + '</li>';
                    $('#group-select-new').replaceWith(listHtml);
                } else {
                    App.alert('error', 'Error adding that new group. Please try again');
                    return -1;
                }
            },
            error: function () {
                console.log('An Error Occured Getting Groups from Server');
            }

        });
    }
    //function to remove a group from both the cloud and locally
    deleteGroup (groupID, that) {
        //first remove the group from all devices
        console.log(groupID);

        let allAssocDev = Groups.findAssocDevices(groupID);
        let devLen = allAssocDev.length;
        let groupLen = Groups.list.length;
        let groupIndex = Groups.getIndexByID(groupID);
        Groups.buildGroupHTML();
        let i, j, k;
        let groupsID = Groups.list[groupIndex].ID;
        let myURL = Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/device_groups/' + groupsID;
        let len = $('#device-groups-list li').length;

        let children = $('#device-groups-list').children();
        $.ajax({
            type: 'DELETE',
            dataType: 'json',
            url: myURL,
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
            success: function (response) {
                if (response.errors.length < 1) {
                    if (groupIndex >= 0) { //remove the group from the groups list
                        Groups.list.splice(groupIndex, 1);
                        that.remove();
                    }

                    for (i = 0; i < len; i++) { //remove this group from all devices who are a part of the corresponding group
                        if ($(children[i]).data('group-id') == groupID) {
                            $(children[i]).remove();
                        }
                    }

                    for (j = 0; j < devLen; j++) {
                        Devices.removeFromGroup(allAssocDev[j], groupID);
                    }
                } else {
                    App.alert('error', 'An error occured while deleting that device group. Your change may not have saved', response.errors);
                    console.log(response); //debug
                }

            },
            error: function (response) {
                console.log('Error Deleting group');
                console.log(response);
            }

        });

    }*/
};

export {Group, Groups};
