import bugsnag from './client';

const bugsnagMiddleware = () => next => action => {
  const { type, payload } = action;

  if (/^.*\/FAILURE$/i.test(type)) {
    bugsnag.notify(payload);
  }

  next(action);
};

export default bugsnagMiddleware;
