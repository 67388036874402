import { createActions } from 'redux-actions';
import {
    LIST_EVENTS,
    RETRIEVE_EVENT,
    UPDATE_EVENT,
    PROCESS_EVENT,
    DELETE_EVENT,
    ADD_EVENT,
    PAGINATION_EVENT,
    TABLE_EVENT,
    CLEAR_NEW_EVENTS,
} from './types';

const helperTypes = {
    REQUEST: undefined,
    SUCCESS: undefined,
    FAILURE: undefined,
    FULFILL: undefined,
};

const actions = createActions({
    [LIST_EVENTS]: helperTypes,
    [RETRIEVE_EVENT]: helperTypes,
    [UPDATE_EVENT]: helperTypes,
    [DELETE_EVENT]: helperTypes,
    [PROCESS_EVENT]: undefined,
    [CLEAR_NEW_EVENTS]: undefined,
    [ADD_EVENT]: undefined,
    [PAGINATION_EVENT]: {
        CHANGE_NAVIGATION: undefined,
        PAGE_SIZE: undefined,
    },
    [TABLE_EVENT] : {
        SORTING: undefined,
        GROUPING: undefined,
        SELECTION: undefined,
        FILTERS: undefined,
        EXPANDED_GROUPS: undefined,
        EXPANDED_ROW_IDS: undefined,
        SEARCH: undefined,
        RESET_FILTERS: undefined,
    }
});

export const {
    listEvents,
    updateEvent,
    deleteEvent,
    retrieveEvent,
    processEvent,
    addEvent,
    tableEvent,
    paginationEvent,
    clearNewEvents,
} = actions;
