/************ NOTES ***************
 * DO NOT SET 998/9 on USGG parts. Have to set individual points
 *
 *
 */

//Something is broken with this import... But it works in locations.js
 // import {trigConfig} from 'app/templates.js';
 let trigConfig = {
    //BAF ISIS Fan controller info:
    //bafSerialButIsDown: [false,false,false],
    bafSerialfunctions: {
        '0': 'Button1',
        '1': 'Button2',
        '2': 'Button3',
        '3': 'Button1_Release',
        '4': 'Button2_Release',
        '5': 'Button3_Release',
        '6': 'Hold1',
        '7': 'Hold2',
        '8': 'Hold3',
        '9': 'Hold1_Release',
        '10': 'Hold2_Release',
        '11': 'Hold3_Release',
        '12': 'coap_send_action(linkID)',
        '13': 'coap_repeat_action',
        '14': 'Delay Scene 60 min(linkID)',
        '15': 'Delay Scene 1 min(linkID)',
        '16': 'Set LED Levels',
        '17': 'Delay Scene 1 sec(linkID)'
    },

    //SW3 info:
    swThreeFunctions: {
        '0': 'Button1',
        '1': 'Button2',
        '2': 'Button3',
        '3': 'Button1_Release',
        '4': 'Button2_Release',
        '5': 'Button3_Release',
        '6': 'Hold1',
        '7': 'Hold2',
        '8': 'Hold3',
        '9': 'Hold1_Release',
        '10': 'Hold2_Release',
        '11': 'Hold3_Release',
        '12': 'coap_send_action(linkID)',
        '13': 'coap_repeat_action',
        '14': 'Delay Scene 60 min(linkID)',
        '15': 'Delay Scene 1 min(linkID)',
        '16': 'Set LED Levels',
        '17': 'Delay Scene 1 sec(linkID)'
    },
    //SW8 info:
    swEightFunctions: {
        '0': 'Button1',
        '1': 'Button2',
        '2': 'Button3',
        '3': 'Button4',
        '4': 'Button5',
        '5': 'Button6',
        '6': 'Button7',
        '7': 'Button8',
        '8': 'Button1_Release',
        '9': 'Button2_Release',
        '10': 'Button3_Release',
        '11': 'Button4_Release',
        '12': 'Button5_Release',
        '13': 'Button6_Release',
        '14': 'Button7_Release',
        '15': 'Button8_Release'
    },
    //ML info:
    mlFunctions: {
        '0': 'Motion',
        '1': 'Motion TimeOut',
        '2': 'DayLightDim',
        '3': 'Disable Light Linking',
        '4': 'Disable Motion',
        '5': 'Enable Motion (0/1)',
        '6': 'Delay Scene 60 min(linkID)',
        '7': 'Enable Light Linking (0/1)',
        '8': 'Enable Light Linking',
        '9': 'Motion2',
        '10': 'Motion TimeOut2',
        '11': 'Set Motion Mode',
        '12': 'NH_Motion_Grace',
        '13': 'AH_Motion_Grace',

    },
    //Medley info
    medleyFunctions: {
        '0': 'Motion',
        '1': 'Motion TimeOut',
        '2': 'Button1',
        '3': 'Button2',
        '4': 'Button1_Release',
        '5': 'Button2_Release',
        '6': 'Hold1',
        '7': 'Hold2',
        '8': 'Hold1_Release',
        '9': 'Hold2_Release',
        '10': 'Set LED Levels',
        '11': 'Delay Scene 60 min(linkID)',
        '12': 'coap_send_action(linkID)',
        '13': 'coap_repeat_action',
        '14': 'Delay Scene 1 min(linkID)',
        '15': 'Delay Scene 1 sec(linkID)'
    },
    //dcdim info
    dcdimFunctions: {
        '0': 'Halt Fade',
        '1': 'Daylight Dim',
        '2': 'Ignore Daylight Dim',
        '3': 'Change Duty Cycle (Percent)',
        '4': 'Set Manual Duty(%,Fade)',
        '5': 'Set Auto Duty(%,Fade)',
        '6': 'Cloud Dim',
        '7': 'Set Load Shed (0-1)',
    },
    //phd info
    phdFunctions: {
        'cloud-dim': 'Cloud Dim (mask)',
        'auto-fade': 'Set Auto Level (mask,%,fade)',
        'man-fade': 'Set Manual Level (mask,%,fade)',
        'halt-fade': 'Halt Fade (mask)',
        'set-load': 'Set Load Shed (mask,0-1)',
        'dlh': 'Harvest Daylight (mask)'
    },
    psmFunctions: {
        'enable-mapped-chans': 'Enable Mapped Channels (mask) ',
        'disable-mapped-chans': 'Disable Mapped Channels (mask) ',
        'enable-chan': 'Enable PSM Channel (ch) ',
        'disable-chan': 'Disable PSM Channel (ch) ',
        'all-state': 'All Channels (state)',
    },
    //link info TODO: This should be deprecated at this point, but need to be replaced with mlFunctions
    linkFunctions: {
        '0': 'Motion Update', //slave action
        '1': 'Motion Reset', //master action
        '2': 'DayLightDim', //master ml
        '3': 'DayLight Dim' //Dll in dim
    },

    scenesFunctions: {
        '0': 'Motion', //slave action
    },

    devicesFunctions: {
        '0': 'Motion',
        '1': 'Motion',
        '2': 'Motion',
        '3': 'Motion',
    },

};

//SW3 info:
let DEVICE_ACTION_LOOKUP = {
    Tny: {
        //Button1: {
        //    display_name: "Top Press",
        //},
        //Button2: {
        //    display_name: "Bottom Press",
        //},
        Button1_Release: 'Top Release',
        Button2_Release: 'Bottom Release',
        Hold1: 'Top Long Press',
        Hold2: 'Bottom Long Press',
        Hold1_Release: 'Top Long Release',
        Hold2_Release:'Bottom Long Release',
    }
};

let StandardTemplates = {
    'office': {
        'features': ['slider-control', 'responsive-dimming', 'day-occ',],
        'required_device_classifications': ['dimmer', 'sensor'],
        'feature_settings':{
            'day-occ':{
                'occ-light-level':100,
                'vac-light-level':0,
                'timeout':15,
            }
        }
    },
    'supermarket-aisle': {
        'features': ['responsive-dimming', 'day-occ', 'night-occ'],
        'required_device_classifications': ['dimmer', 'sensor']
    },
    'demand-response': {
        'features': ['toggle-demand-response'],
        'required_device_classifications': []
    },
    /*'good-morning-night': { we dont currently have a UX representation for these. we should talk about how to implement or if we want these at all
        'features': ['toggle-occupancy-mode'],
        'required_device_classifications': ['dimmer']
    },*/
    'on-off-only': {
        'features': ['on-off'],
        'required_device_classifications': []
    },
    'normal-hours-after-hours': {
        'features': ['toggle-occupancy-mode'],
        'required_device_classifications': ['dimmer']
    }
};

//A default array of parts that can be configured to work with Tiny. a custom array can also be defined for each template
let tinyApplicableParts = [
    'AM-DCDIM-DAC',
    'AM-DCDIM-PWM',
    'USGG-25P',
    'AM-ACDIM',
    'DCDIM-TINY-TEST',
    'AM-ACDIM-MLTH',
    'AM-PSM',
    'AM-SW-ML',
    'AM-SW-MLTH',
    'AM-SW8',
    'ML-DALI',
    'ALC',
    'ALC-LVD',
    'DRIVER',
    'AM-PHD',
    'PHD',
    'MLTH',
    'SW-MLTH',
    'ACDIM-MLTH',
    'XPOE'
];

//Add it to the template object for use in other places
// Template.tinyApplicableParts = tinyApplicableParts;

let MyTemplates = {
    'nextScene': 256,
    //The horseshoe slider to set levels directly
    'dimming': {
        'name': 'dimming',
        'description': 'This just sets the setpoints so that the zone will dim',

        'datapoints': {
            'enable': {
                'DCDIM-DAC': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '2',
                    'description': 'Put the device in 0-10V dimming mode'
                }],
                'DCDIM-PWM': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '4',
                    'description': 'Put the device in PWM dimming mode'
                }],
                'ACDIM': [{
                    'name': 'Mode',
                    'idx': '18',
                    'val': '2',
                    'description': 'Put the device in 0-10V dimming mode'
                }],
                'USGG-25P': [{
                    'name': 'Mode',
                    'idx': '21',
                    'val': '3',
                    'description': 'Put the device in 1-10V dimming mode'
                }]
            },
            'disable': {
                'DCDIM-DAC': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '1',
                    'description': 'Put the device in relay mode'
                }],
                'DCDIM-PWM': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '1',
                    'description': 'Put the device in relay mode'
                }],
                'ACDIM': [{
                    'name': 'Mode',
                    'idx': '18',
                    'val': '1',
                    'description': 'Put the device in relay mode'
                }],
                'USGG-25P': [{
                    'name': 'Mode',
                    'idx': '21',
                    'val': '1',
                    'description': 'Put the device in relay mode'
                }]
            }
        },

        'scenes': []
    },
    //The horseshoe slider to set levels directly
    'slider-control': {
        'name': 'slider-control',
        'description': 'Provide a slider to directly set light levels',

        'datapoints': {
            'enable': {
                'DCDIM-DAC': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '2',
                    'description': 'Put the device in 0-10V dimming mode'
                }],
                'DCDIM-PWM': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '4',
                    'description': 'Put the device in PWM dimming mode'
                }],
                'USGG-25P': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '3',
                    'description': 'Put the device in 1-10V dimming mode'
                }],
                'ACDIM': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '2',
                    'description': 'Put the device in 0-10V dimming mode'
                }]
            },
            'disable': {}
        },

        'scenes': [
            //Cloud Dim
            {
                'name': 'Direct Control',
                'description': 'direct_control',
                'hidden': 0,
                'devices': {
                    'DCDIM-DAC': {
                        'min-ver': '05.02.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[6], //Cloud dim
                            'var': '1'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['cloud-dim'], //set Auto 100% 1 second fade(70)
                            'var': ''
                        }]
                    },
                    'XPOE': {
                        ref: 'PHD'
                    },
                    'DCDIM-PWM': {
                        ref: 'DCDIM-DAC'
                    },
                    'ACDIM': {
                        ref: 'DCDIM-DAC'
                    },
                    'USGG-25P': {
                        ref: 'DCDIM-DAC'
                    },
                    'ML-DALI': {
                        ref: 'DCDIM-DAC'
                    },
                    'BAF-DRIVE': {
                        ref: 'DCDIM-DAC'
                    },
                    'ALC': {
                        ref: 'DCDIM-DAC'
                    },
                    'ALC-LVD': {
                        ref: 'DCDIM-DAC'
                    },
                    'DRIVER': {
                        ref: 'DCDIM-DAC'
                    },
                    'MLTH': {
                        ref: 'DCDIM-DAC'
                    }
                }
            }
        ]
    },
    //A variation on the horseshoe slider for individual control of each device in a location
    'individual-slider-control': {
        'name': 'slider-control',
        'description': 'Provide a slider to directly set light levels',
        'type': 'individual',

        'datapoints': {
            'enable': {
                'DCDIM-DAC': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '2',
                    'description': 'Put the device in 0-10V dimming mode'
                }],
                'DCDIM-PWM': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '4',
                    'description': 'Put the device in PWM dimming mode'
                }]
            },
            'disable': {}
        },

        'scenes': [
            //Cloud Dim
            {
                'name': 'Direct Control',
                'description': 'individual_direct_control',
                'hidden': 0,
                'devices': {
                    'DCDIM-DAC': {
                        'min-ver': '05.02.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[6], //Cloud dim
                            'var': '1'
                        }]
                    },
                    'DCDIM-PWM': {
                        'min-ver': '05.02.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[6], //Cloud dim
                            'var': '1'
                        }]
                    },
                    'ACDIM': {
                        'min-ver': '05.02.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[6], //Cloud dim
                            'var': '1'
                        }]
                    }

                }
            }
        ]
    },
    //Daylight Linking group
    'dll': {
        'name': 'dll',
        'description': 'Link Light sensors to Dimmers to harvest sunlight',
        'setSensor': true,
        'datapoints': {
            'enable': {
                'DCDIM-DAC': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '2',
                    'description': 'Put the device in 0-10V dimming mode'
                }],
                'DCDIM-PWM': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '4',
                    'description': 'Put the device in PWM dimming mode'
                }],
                'SW-ML': [{
                    'name': 'Lt Enable',
                    'idx': '2',
                    'val': '1',
                    'description': 'Enable Daylight Harvest'
                }],
                'SW-MLTH': [{
                    'name': 'Lt Enable',
                    'idx': '2',
                    'val': '1',
                    'description': 'Enable Daylight Harvest'
                }],
                'USGG-25P': [{
                    'name': 'Lt Enable',
                    'idx': '29',
                    'val': '1',
                    'description': 'Enable Daylight Harvest'
                }],
                'DRIVER': [{
                    'name': 'DLH Enable',
                    'idx': '71',
                    'val': '1',
                    'description': 'Enable daylight sensing'
                }],
                'ALC': [{
                    'name': 'DLH Enable',
                    'idx': '71',
                    'val': '1',
                    'description': 'Enable daylight sensing'
                }],
                'MLTH': [{
                    'name': 'DLH Enable',
                    'idx': '71',
                    'val': '1',
                    'description': 'Enable daylight sensing'
                }],
            },
            'disable': {}
        },

        'scenes': [

            {
                'name': 'Daylight Link Group',
                'description': 'dll_group',
                'hidden': 1,
                'devices': {
                    'SW-ML': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'master_dll,master_motion_dll,master_dll_child_motion', //comma separated, no spaces
                            'function': trigConfig.mlFunctions[2] //Send Daylight information
                        }]
                    },
                    'SW-MLTH': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'master_dll,master_motion_dll,master_dll_child_motion',
                            'function': trigConfig.mlFunctions[2] //Send Daylight information
                        }]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[1], //Listen for daylight Information
                            'var': '1'
                        }]
                    },
                    'DCDIM-PWM': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[1], //Listen for daylight Information
                            'var': '1'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['dlh'], //set Auto 100% 2 second fade(70)
                            'var': ''
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'master_dll,master_motion_dll,master_dll_child_motion', //comma separated, no spaces
                            'function': trigConfig.mlFunctions[2] //Send Daylight information
                        }],
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[1], //Listen for daylight Information
                            'var': '1'
                        }]
                    },
                    'MLTH': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM': {
                        ref: 'USGG-25P'
                    },
                    'BAF20': {
                        ref: 'USGG-25P'
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    }

                }
            }
        ]
    },
    //Normal occupancy
    'day-occ': {
        'name': 'day-occ',
        'description': 'Normal (mode 0) occupancy logic',
        'setSensor': true,

        'datapoints': {
            'enable': {
                'DCDIM-DAC': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '2',
                    'description': 'Put the device in 0-10V dimming mode'
                }],
                'DCDIM-PWM': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '4',
                    'description': 'Put the device in PWM dimming mode'
                }],
                'SW-ML': [{
                    'name': 'Reed Mode',
                    'idx': '0',
                    'val': '999',
                    'description': 'Set the defaults'
                }
                    /*
                     {
                     "name":'PIR Mode',
                     "idx":'1',
                     "val": '5',
                     "description": "PIR must be in mode 5 for it to work"
                     },
                     {
                     "name":'PIR Enable',
                     "idx":'5',
                     "val": '1',
                     "description": "PIR must be 1 to be enabled"
                     },
                     {
                     "name":'Timeout Mode',
                     "idx":'8',
                     "val": '0',
                     "description": "Day Timeout mode"
                     },
                     {
                     "name":'PIR Timeout',
                     "idx":'7',
                     "val": '15',
                     "description": "Default to a 15 minute timeout period"
                     },
                     */
                ],
                'SW-MLTH': [{
                    'name': 'Reed Mode',
                    'idx': '0',
                    'val': '999',
                    'description': 'Set the defaults'
                }
                    /*
                     {
                     "name":'PIR Mode',
                     "idx":'1',
                     "val": '5',
                     "description": "PIR must be in mode 5 for it to work"
                     },
                     {
                     "name":'PIR Enable',
                     "idx":'5',
                     "val": '1',
                     "description": "PIR must be 1 to be enabled"
                     },
                     {
                     "name":'Timeout Mode',
                     "idx":'8',
                     "val": '0',
                     "description": "Day Timeout mode"
                     },
                     {
                     "name":'PIR Timeout',
                     "idx":'7',
                     "val": '15',
                     "description": "Default to a 15 minute timeout period"
                     },
                     */
                ],
                'USGG-25P': [{
                    'name': 'Mode',
                    'idx': '12',
                    'val': '999',
                    'description': 'Set the defaults'
                }],
                'SW8': [{
                    'name': 'SW1 Mode',
                    'idx': '1',
                    'val': '5',
                    'description': 'Motion Sense'
                    },
                    {'name': 'SW2 Mode',
                    'idx': '2',
                    'val': '0',
                    'description': 'Input Off'} ,
                    {'name': 'SW3 Mode',
                    'idx': '3',
                    'val': '0',
                    'description': 'Input Off'},
                    {'name': 'SW4 Mode',
                    'idx': '4',
                    'val': '0',
                    'description': 'Input Off'},
                    {'name': 'SW5 Mode',
                    'idx': '5',
                    'val': '0',
                    'description': 'Input Off'},
                    {'name': 'SW6 Mode',
                    'idx': '6',
                    'val': '0',
                    'description': 'Input Off'},
                    {'name': 'SW7 Mode',
                    'idx': '7',
                    'val': '0',
                    'description': 'Input Off'},
                    {'name': 'SW8 Mode',
                    'idx': '8',
                    'val': '0',
                    'description': 'Input Off'}
                ],
                'DRIVER': [{
                    'name': 'Occ Enable',
                    'idx': '62',
                    'val': '1',
                    'description': 'Enable motion sensing'
                }],
                'ALC': [{
                    'name': 'Occ Enable',
                    'idx': '62',
                    'val': '1',
                    'description': 'Enable motion sensing'
                }],
                'MLTH': [{
                    'name': 'Occ Enable',
                    'idx': '62',
                    'val': '1',
                    'description': 'Enable motion sensing'
                }],
            },
            'disable': {}
        },

        'scenes': [
            //Occupied
            {
                'name': 'Occupied',
                'description': 'occupied',
                'hidden': 1,
                'devices': {
                    'SW3': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.swThreeFunctions[16], //set led levels 60 10 10
                            'var': '060010010'
                        }]
                    },
                    'SW-ML': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.mlFunctions[0] //Motion Sense
                        }]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[5], //set Auto 100% 2 second fade(70)
                            'var': '100002'
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.mlFunctions[0] //Motion Sense
                        }],

                        'acts': [{
                            'function': trigConfig.dcdimFunctions[5], //set Auto 100% 2 second fade(70)
                            'var': '100002'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['auto-fade'], //set Auto 100% 1 second fade(70)
                            'var': '6401'
                        }]
                    },
                    'PSM': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.psmFunctions['enable-mapped-chans'], //set Auto 100% 1 second fade(70)
                            'var': ''
                        }]
                    },
                    'XPOE': {
                        ref: 'PHD'
                    },
                    'SW8': {
                        ref: 'SW-ML'
                    },
                    'MLTH': {
                        ref: 'USGG-25P'
                    },
                    'SW-MLTH': {
                        ref: 'USGG-25P'
                    },
                    'DCDIM-PWM': {
                        ref: 'DCDIM-DAC'
                    },
                    'ML-DALI': {
                        ref: 'USGG-25P'
                    },
                    'BAF-DRIVE': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM': {
                        ref: 'USGG-25P'
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    }
                }
            },
            //Unoccupied
            {
                'name': 'Unoccupied',
                'hidden': 1,
                'description': 'unoccupied',
                'devices': {
                    'SW3': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.swThreeFunctions[16], //set led levels 60 10 10
                            'var': '010010060'
                        }]
                    },
                    'SW-ML': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.mlFunctions[1] //Motion Timeout
                        }]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[4], //set manual off at 2 second fade(70)
                            'var': '10'
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.mlFunctions[1] //Motion Timeout
                        }],
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[4], //set manual off at 2 second fade(70)
                            'var': '10'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['man-fade'], //set manual off at 10 second fade
                            'var': '0001'
                        }]
                    },
                    'PSM': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.psmFunctions['disable-mapped-chans'], //set Auto 100% 1 second fade(70)
                            'var': ''
                        }]
                    },
                    'XPOE': {
                        ref: 'PHD'
                    },
                    'SW8': {
                        ref: 'SW-ML'
                    },
                    'MLTH': {
                        ref: 'USGG-25P'
                    },
                    'SW-MLTH': {
                        ref: 'USGG-25P'
                    },
                    'DCDIM-PWM': {
                        ref: 'DCDIM-DAC'
                    },
                    'ML-DALI': {
                        ref: 'USGG-25P'
                    },
                    'BAF-DRIVE': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM': {
                        ref: 'USGG-25P'
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    }
                }
            },
            //Motion Group
            {
                'name': 'Motion Group',
                'hidden': 1,
                'description': 'motion_group',
                'devices': {
                    'SW-ML': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'child_motion',
                            'function': trigConfig.linkFunctions[0] //Motion Update
                        }],
                        'acts': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.linkFunctions[1], //Motion RESET
                            'var': '1'
                        }]
                    },
                    'SW-MLTH': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'child_motion',
                            'function': trigConfig.linkFunctions[0] //Motion Update
                        }],
                        'acts': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.linkFunctions[1], //Motion RESET
                            'var': '1'
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'child_motion',
                            'function': trigConfig.linkFunctions[0] //Motion Update
                        }],
                        'acts': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.linkFunctions[1], //Motion RESET
                            'var': '1'
                        }]
                    },
                    'SW8': {
                        ref: 'SW-ML'
                    },
                    'MLTH': {
                        ref: 'SW-ML'
                    },
                    'ML-DALI': {
                        ref: 'USGG-25P'
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    }
                }
            }
        ]
    },
    //normal hours grace period
    'nh-grace': {
        'name': 'nh-grace',
        'description': 'Normal (mode 0) occupancy logic',
        'setSensor': true,
        'datapoints': {
            'enable': {
                'SW-ML': [{
                    'name':'NH Grace Time',
                    'idx': '98',
                    'val': '1',
                }],
                'SW-MLTH': [{
                    'name':'NH Grace Time',
                    'idx': '98',
                    'val': '1',
                }],
                'DRIVER': [{
                    'name':'NH Grace Time',
                    'idx': '98',
                    'val': '1',
                }],
                'SW8': [{
                    'name':'NH Grace Time',
                    'idx': '41',
                    'val': '1',
                }],
                'ALC': [{
                    'name':'NH Grace Time',
                    'idx': '98',
                    'val': '1',
                }],
                'MLTH': [{
                    'name':'NH Grace Time',
                    'idx': '98',
                    'val': '1',
                }],
            },
            'disable': {
                'SW-ML': [{
                    'name':'NH Grace Time',
                    'idx': '98',
                    'val': '0',
                }],
                'SW-MLTH': [{
                    'name':'NH Grace Time',
                    'idx': '98',
                    'val': '0',
                }],
                'SW8': [{
                    'name':'NH Grace Time',
                    'idx': '41',
                    'val': '0',
                }],
                'DRIVER': [{
                    'name':'NH Grace Time',
                    'idx': '98',
                    'val': '0',
                }],
                'ALC': [{
                    'name':'NH Grace Time',
                    'idx': '98',
                    'val': '0',
                }],
                'MLTH': [{
                    'name':'NH Grace Time',
                    'idx': '98',
                    'val': '0',
                }],
            }
        },
        'scenes': [
            //nh grace period
            {
                'name': 'Normal Hours Grace',
                'description': 'nh_grace',
                'hidden': 1,
                'devices': {
                    'SW-ML': {
                        'min-ver': '07.46.04',
                        'trigs': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.mlFunctions[12],//NH_Motion_Grace
                        }]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[4], //set manual off at 2 second fade(70)
                            'var': '10001'
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '07.46.04',
                        'trigs': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.mlFunctions[12],//NH_Motion_Grace
                        }],
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[4], //set manual off at 2 second fade(70)
                            'var': '10001'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['man-fade'], //set manual off at 10 second fade
                            'var': '0A01'
                        }]
                    },
                    'PSM': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.psmFunctions['disable-mapped-chans'], //set Auto 100% 1 second fade(70)
                            'var': ''
                        }]
                    },
                    'SW8': {
                        ref: 'SW-ML'
                    },
                    'MLTH': {
                        ref: 'USGG-25P'
                    },
                    'SW-MLTH': {
                        ref: 'USGG-25P'
                    },
                    'DCDIM-PWM': {
                        ref: 'DCDIM-DAC'
                    },
                    'ML-DALI': {
                        ref: 'USGG-25P'
                    },
                    'BAF-DRIVE': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM': {
                        ref: 'USGG-25P'
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    }
                }
            },

        ]
    },
    //After Hours Grace Period
    'ah-grace': {
        'name': 'ah-grace',
        'description': 'Normal (mode 0) occupancy logic',
        'setSensor': true,
        'datapoints': {
            'enable': {
                'SW-ML': [{
                    'name':'AH Grace Time',
                    'idx': '99',
                    'val': '1',
                }],
                'SW-MLTH': [{
                    'name':'AH Grace Time',
                    'idx': '99',
                    'val': '1',
                }],
                'SW8': [{
                    'name':'AH Grace Time',
                    'idx': '42',
                    'val': '1',
                }],
                'DRIVER': [{
                    'name':'AH Grace Time',
                    'idx': '99',
                    'val': '1',
                }],
                'ALC': [{
                    'name':'AH Grace Time',
                    'idx': '99',
                    'val': '1',
                }],
                'MLTH': [{
                    'name':'AH Grace Time',
                    'idx': '99',
                    'val': '1',
                }],
            },
            'disable': {
                'SW-ML': [{
                    'name':'AH Grace Time',
                    'idx': '99',
                    'val': '0',
                }],
                'SW-MLTH': [{
                    'name':'AH Grace Time',
                    'idx': '99',
                    'val': '0',
                }],
                'SW8': [{
                    'name':'AH Grace Time',
                    'idx': '42',
                    'val': '0',
                }],
                'DRIVER': [{
                    'name':'AH Grace Time',
                    'idx': '99',
                    'val': '0',
                }],
                'ALC': [{
                    'name':'AH Grace Time',
                    'idx': '99',
                    'val': '0',
                }],
                'MLTH': [{
                    'name':'AH Grace Time',
                    'idx': '99',
                    'val': '0',
                }],
            }
        },
        'scenes': [
            //ah grace period
            {
                'name': 'After Hours Grace',
                'description': 'ah_grace',
                'hidden': 1,
                'devices': {
                    'SW-ML': {
                        'min-ver': '07.46.04',
                        'trigs': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.mlFunctions[13],//AH_Motion_Grace

                        }]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[4], //set manual off at 2 second fade(70)
                            'var': '10001'
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '07.46.04',
                        'trigs': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.mlFunctions[13],//AH_Motion_Grace
                        }],
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[4], //set manual off at 2 second fade(70)
                            'var': '10001'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['man-fade'], //set manual off at 10 second fade
                            'var': '0A01'
                        }]
                    },
                    'PSM': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.psmFunctions['disable-mapped-chans'], //set Auto 100% 1 second fade(70)
                            'var': ''
                        }]
                    },
                    'SW8': {
                        ref: 'SW-ML'
                    },
                    'MLTH': {
                        ref: 'USGG-25P'
                    },
                    'SW-MLTH': {
                        ref: 'USGG-25P'
                    },
                    'DCDIM-PWM': {
                        ref: 'DCDIM-DAC'
                    },
                    'ML-DALI': {
                        ref: 'USGG-25P'
                    },
                    'BAF-DRIVE': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM': {
                        ref: 'USGG-25P'
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    }
                }
            },

        ]
    },
    //Normal occupancy for supermarkets
    'day-occ-supermarket': {
        'name': 'day-occ-supermarket',
        'description': 'Normal (mode 0) occupancy logic',

        'datapoints': {
            'enable': {
                'DCDIM-DAC': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '2',
                    'description': 'Put the device in 0-10V dimming mode'
                }],
                'DCDIM-PWM': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '4',
                    'description': 'Put the device in PWM dimming mode'
                }],
                'SW-ML': [{
                    'name': 'Reed Mode',
                    'idx': '0',
                    'val': '999',
                    'description': 'Set the defaults'
                },
                /*
                     {
                     "name":'PIR Mode',
                     "idx":'1',
                     "val": '5',
                     "description": "PIR must be in mode 5 for it to work"
                     },
                     {
                     "name":'PIR Enable',
                     "idx":'5',
                     "val": '1',
                     "description": "PIR must be 1 to be enabled"
                     },
                     {
                     "name":'Timeout Mode',
                     "idx":'8',
                     "val": '0',
                     "description": "Day Timeout mode"
                     },
                     */
                {
                    'name': 'PIR Timeout',
                    'idx': '7',
                    'val': '0.5',
                    'description': 'Default to a 0.5 minute timeout period'
                }

                ]
            },
            'disable': {}
        },

        'scenes': [
            //Occupied
            {
                'name': 'Occupied',
                'description': 'occupied',
                'hidden': 1,
                'devices': {
                    'SW-ML': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            //"required_role" : 'master_motion', //Removed to make the super market aisle turn on from both ends
                            'function': trigConfig.mlFunctions[0] //Motion Sense
                        }]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[5], //set Auto 100% 2 second fade(70)
                            'var': '100002'
                        }]
                    },
                    'DCDIM-PWM': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[5], //set Auto 100% 2 second fade(70)
                            'var': '100002'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['auto-fade'], //set Auto 100% 2 second fade(70)
                            'var': '6401'
                        }]
                    },
                    'SW3': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.swThreeFunctions[16], //set led levels 60 10 10
                            'var': '060010010'
                        }]
                    }
                }
            },
            //Unoccupied
            {
                'name': 'Unoccupied',
                'hidden': 1,
                'description': 'unoccupied',
                'devices': {
                    'SW-ML': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'master_motion',
                            'function': trigConfig.mlFunctions[1] //Motion Timeout
                        }]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[4], //set manual off at 2 second fade(70)
                            'var': '050002'
                        }]
                    },
                    'DCDIM-PWM': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[4], //set manual off at 2 second fade(70)
                            'var': '050002'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['man-fade'], //set manual off at 2 second fade(70)
                            'var': '0001'
                        }]
                    },
                    'SW3': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.swThreeFunctions[16], //set led levels 60 10 10
                            'var': '010010060'
                        }]
                    }
                }
            },
            //Motion Group
            {
                'name': 'Motion Group',
                'hidden': 1,
                'description': 'motion_group',
                'devices': {
                    'SW-ML': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'child_motion',
                            'function': trigConfig.linkFunctions[0] //Motion Update
                        }],
                        'acts': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.linkFunctions[1], //Motion RESET
                            'var': '1'
                        }]
                    },
                    'SW-MLTH': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'child_motion',
                            'function': trigConfig.linkFunctions[0] //Motion Update
                        }],
                        'acts': [{
                            'required_role': 'master_motion',
                            'function': trigConfig.linkFunctions[1], //Motion RESET
                            'var': '1'
                        }]
                    }
                }
            }
        ]
    },
    //Mode 2 Occupancy
    'night-occ': {
        'name': 'night-occ',
        'description': 'Nighttime, or occupancy mode 2 logic. Timeout mode 3',

        'datapoints': {
            'enable': {
                'DCDIM-DAC': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '2',
                    'description': 'Put the device in 0-10V dimming mode'
                }],
                'DCDIM-PWM': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '4',
                    'description': 'Put the device in PWM dimming mode'
                }],
                'SW-ML': [{
                    'name': 'Reed Mode',
                    'idx': '0',
                    'val': '999',
                    'description': 'Set the defaults'
                }],
                'SW8': [{
                    'name': 'SW1 Mode',
                    'idx': '1',
                    'val': '5',
                    'description': 'Motion Sense'
                    },
                    {'name': 'SW2 Mode',
                    'idx': '2',
                    'val': '0',
                    'description': 'Input Off'} ,
                    {'name': 'SW3 Mode',
                    'idx': '3',
                    'val': '0',
                    'description': 'Input Off'},
                    {'name': 'SW4 Mode',
                    'idx': '4',
                    'val': '0',
                    'description': 'Input Off'},
                    {'name': 'SW5 Mode',
                    'idx': '5',
                    'val': '0',
                    'description': 'Input Off'},
                    {'name': 'SW6 Mode',
                    'idx': '6',
                    'val': '0',
                    'description': 'Input Off'},
                    {'name': 'SW7 Mode',
                    'idx': '7',
                    'val': '0',
                    'description': 'Input Off'},
                    {'name': 'SW8 Mode',
                    'idx': '8',
                    'val': '0',
                    'description': 'Input Off'}
                ]
            },
            'disable': {}
        },

        'scenes': [
            //Nighttime Occupancy
            {
                'name': 'Occupied Nighttime',
                'description': 'occupied_nighttime',
                'hidden': 1,
                'devices': {
                    'SW-ML': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.mlFunctions[9] //Nighttime motion sense
                        }]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[5], //set Auto 75% 2 second fade(70)
                            'var': '75002'
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.mlFunctions[9] //Motion Sense
                        }],
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[4], //set Auto 75% 2 second fade(70)
                            'var': '75002'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['man-fade'], //set Auto 75% 2 second fade(70)
                            'var': '4B01'
                        }]
                    },
                    'PSM': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.psmFunctions['enable-mapped-chans'], //set Auto 100% 1 second fade(70)
                            'var': ''
                        }]
                    },
                    'XPOE': {
                        ref: 'PHD'
                    },
                    'MLTH': {
                        ref: 'USGG-25P'
                    },
                    'SW8': {
                        ref: 'SW-ML'
                    },
                    'SW-MLTH': {
                        ref: 'SW-ML'
                    },
                    'DCDIM-PWM': {
                        ref: 'DCDIM-DAC'
                    },
                    'ML-DALI': {
                        ref: 'USGG-25P'
                    },
                    'BAF-DRIVE': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM': {
                        ref: 'USGG-25P'
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    }
                }
            },
            //Nighttime Vacancy
            {
                'name': 'Unoccupied NightTime',
                'description': 'unoccupied_nighttime',
                'hidden': 1,
                'devices': {
                    'SW-ML': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.mlFunctions[10] //Nighttime motion timeout
                        }]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[4], //set manual off at 2s fade
                            'var': '10'
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '05.00.00',
                        'trigs': [{
                            'required_role': 'master_motion,master_motion_dll',
                            'function': trigConfig.mlFunctions[10] //Nighttime motion timeout
                        }],
                        'acts': [{
                            'function': trigConfig.dcdimFunctions[4], //set manual off at 2s fade
                            'var': '10'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['man-fade'], //set Auto 75% 2 second fade(70)
                            'var': '0001'
                        }]
                    },
                    'PSM': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.psmFunctions['disable-mapped-chans'], //set Auto 100% 1 second fade(70)
                            'var': ''
                        }]
                    },
                    'XPOE': {
                        ref: 'PHD'
                    },
                    'MLTH': {
                        ref: 'USGG-25P'
                    },
                    'SW8': {
                        ref: 'SW-ML'
                    },
                    'SW-MLTH': {
                        ref: 'SW-ML'
                    },
                    'DCDIM-PWM': {
                        ref: 'DCDIM-DAC'
                    },
                    'ML-DALI': {
                        ref: 'USGG-25P'
                    },
                    'BAF-DRIVE': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM': {
                        ref: 'USGG-25P'
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    }
                }
            }
        ]
    },
    //Just on/off.
    'on-off': {
        'name': 'on-off',
        'description': 'ON(100%) OFF(0%) scenes.',

        'datapoints': {
            //We don't do much here because we are pretty boxed in
            'enable': {
                'SW-M': [{
                    'name': 'TOP Mode',
                    'idx': '0',
                    'val': '999',
                    'description': 'Set the defaults of the device to ensure the buttons are enabled and momentary'
                }],
                'SW3': [{
                    'name': 'TOP Mode',
                    'idx': '0',
                    'val': '999',
                    'description': 'Set the defaults of the device to ensure the buttons are enabled and momentary'
                }]
            },
            'disable': {}
        },

        'scenes': [
            //100% On Full
            {
                'name': 'On',
                'hidden': 0,
                'description': 'on',
                'devices': {
                    'Tny': { //TINY_STUFF
                        'min-ver': '05.00.00',
                        trigs: [{
                            'is_mac_action': true,
                            'applicable_parts': tinyApplicableParts,
                            'function': trigConfig.swThreeFunctions[3] //but1release
                        }]
                    },
                    'SW3': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'function': trigConfig.swThreeFunctions[3] //but1release
                        }],
                        acts: [{
                            'function': trigConfig.swThreeFunctions[16], //set led levels 60 10 10
                            'var': '060010010'
                        }]
                    },
                    'SW-M': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'function': trigConfig.medleyFunctions[4] //Button 1 release
                        }],
                        acts: [{
                            'function': trigConfig.medleyFunctions[10], //set led levels 60 10 0 the bottom is the sensor
                            'var': '060010000'
                        }
                        ]
                    },

                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[5], //set auto (100),
                            'var': '100000'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['auto-fade'], //set Auto 100% 0 second fade(70)
                            'var': '6400'
                        }]
                    },
                    'SW8': {
                        'min-ver': '05.00.00',
                        trigs: [{//standin so we have something to access in configuretriggers, we do this linkid/function calc with
                            //Devices.switch8.sceneToButtonAction and template.getSw8LinkID
                            'function': 'button-press'
                        }],
                    },
                    'ACDIM': {
                        ref: 'DCDIM-DAC'
                    },
                    'ALC': {
                        ref: 'DCDIM-DAC'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'DCDIM-DAC'
                    },
                    'MLTH': {
                        ref: 'DCDIM-DAC'
                    },
                    'DCDIM-PWM': {
                        ref: 'DCDIM-DAC'
                    },
                    'BAF-DRIVE': {
                        ref: 'DCDIM-DAC'
                    },
                    'USGG-25P': {
                        ref: 'DCDIM-DAC'
                    }

                }
            },
            //Off
            {
                'name': 'Off',
                'hidden': 0,
                'description': 'off',
                'devices': {
                    'Tny': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'is_mac_action': true,
                            'applicable_parts': tinyApplicableParts,
                            'function': trigConfig.swThreeFunctions[4] //but2release
                        }]
                    },
                    'ML-DALI': {
                        'min-ver': '05.00.00',
                        acts: [{ //TODO: replace with acdim actions
                            'function': trigConfig.dcdimFunctions[4], //set man (0),
                            'var': '0'
                        }]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set man (0),
                            'var': '0'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['man-fade'], //set Auto 0% 1 second fade(70)
                            'var': '0001'
                        }]
                    },
                    'SW8': {
                        'min-ver': '05.00.00',
                        trigs: [{//standin so we have something to access in configuretriggers, we do this linkid/function calc with
                            //Devices.switch8.sceneToButtonAction and template.getSw8LinkID
                            'function': 'button-press'
                        }],
                    },
                    'ACDIM': {
                        ref: 'DCDIM-DAC'
                    },
                    'ALC': {
                        ref: 'DCDIM-DAC'
                    },
                    'ALC-LVD': {
                        ref: 'DCDIM-DAC'
                    },
                    'DRIVER': {
                        ref: 'DCDIM-DAC'
                    },
                    'MLTH': {
                        ref: 'DCDIM-DAC'
                    },
                    'DCDIM-PWM': {
                        ref: 'DCDIM-DAC'
                    },
                    'BAF-DRIVE': {
                        ref: 'DCDIM-DAC'
                    },
                    'USGG-25P': {
                        ref: 'DCDIM-DAC'
                    }
                }
            }
        ]
    },
    //Just on off and 50% to keep it simple.
    'hundred-fifty-off': {
        'name': 'hundred-fifty-off',
        'description': '100% 50% and OFF(0%) scenes.',

        'datapoints': {
            'enable': {
                'DCDIM-DAC': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '2',
                    'description': 'Put the device in 0-10V dimming mode'
                }],
                'DCDIM-PWM': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '4',
                    'description': 'Put the device in PWM dimming mode'
                }]
            },
            'disable': {
                'DCDIM-DAC': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '1',
                    'description': 'Put the device in relay, ON OFF mode'
                }],
                'DCDIM-PWM': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '1',
                    'description': 'Put the device in relay, ON OFF mode'
                }]
            }
        },

        'scenes': [
            //100% On Full
            {
                'name': 'On',
                'hidden': 0,
                'description': 'on',
                'devices': {
                    'Tny': { //TINY_STUFF
                        'min-ver': '05.00.00',
                        trigs: [{
                            'is_mac_action': true,
                            'applicable_parts': tinyApplicableParts,
                            'function': trigConfig.swThreeFunctions[3] //but1release
                        }]
                    },
                    'SW3': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'function': trigConfig.swThreeFunctions[3] //but1release
                        }],
                        acts: [{
                            'function': trigConfig.swThreeFunctions[16], //set led levels 60 10 10
                            'var': '060010010'
                        }]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[5], //set auto (100),
                            'var': '100001'
                        }]
                    },
                    'DCDIM-PWM': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[5], //set auto (100),
                            'var': '100001'
                        }]
                    },
                    'ACDIM': {
                        'min-ver': '05.00.00',
                        acts: [{ //TODO: replace with acdim actions
                            'function': trigConfig.dcdimFunctions[5], //set auto (100),
                            'var': '100001'
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '05.00.00',
                        acts: [{ //TODO: replace with acdim actions
                            'function': trigConfig.dcdimFunctions[5], //set auto (100),
                            'var': '100001'
                        }]
                    },
                    'BAF-DRIVE': {
                        'min-ver': '05.00.00',
                        acts: [{ //TODO: replace with acdim actions
                            'function': trigConfig.dcdimFunctions[5], //set auto (100),
                            'var': '100001'
                        }]
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    }

                }
            },
            //50%
            {
                'name': '50%',
                'hidden': 0,
                'description': '50',
                'devices': {
                    'SW3': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'function': trigConfig.swThreeFunctions[4] //but2release
                        }],
                        acts: [{
                            'function': trigConfig.swThreeFunctions[16], //set led levels 10 60 10
                            'var': '010060010'
                        }]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set man (50),
                            'var': '050002'
                        }]
                    },
                    'DCDIM-PWM': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set man (50),
                            'var': '050002'
                        }]
                    },
                    'ACDIM': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set man (50),
                            'var': '050002'
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set man (50),
                            'var': '050002'
                        }]
                    },
                    'BAF-DRIVE': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set man (50),
                            'var': '050002'
                        }]
                    }
                },
                'ALC': {
                    ref: 'USGG-25P'
                },
                'ALC-LVD': {
                    ref: 'USGG-25P'
                },
                'DRIVER': {
                    ref: 'USGG-25P'
                }
            },
            //Off
            {
                'name': 'Off',
                'hidden': 0,
                'description': 'off',
                'devices': {
                    'Tny': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'is_mac_action': true,
                            'applicable_parts': tinyApplicableParts,
                            'function': trigConfig.swThreeFunctions[4] //but2release
                        }]
                    },
                    'SW3': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'function': trigConfig.swThreeFunctions[5] //but3release
                        }],
                        acts: [{
                            'function': trigConfig.swThreeFunctions[16], //set led levels 60 10 10
                            'var': '010010060'
                        }]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set auto (100),
                            'var': '0'
                        }]
                    },
                    'DCDIM-PWM': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set auto (100),
                            'var': '0'
                        }]
                    },
                    'ACDIM': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set auto (100),
                            'var': '0'
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set auto (100),
                            'var': '0'
                        }]
                    },
                    'BAF-DRIVE': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set auto (100),
                            'var': '0'
                        }]
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    }
                }
            }
        ]
    },
    //The responsive dimming needed to make a switch work well
    'responsive-dimming': {
        'name': 'responsive-dimming',
        'description': 'Fade up and down with button presses',

        'datapoints': {
            'enable': {
                'DCDIM-DAC': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '2',
                    'description': 'Put the device in 0-10V dimming mode'
                }],
                'DCDIM-PWM': [{
                    'name': 'Mode',
                    'idx': '3',
                    'val': '4',
                    'description': 'Put the device in PWM dimming mode'
                }],
                'SW3': [{
                    'name': 'TOP Mode',
                    'idx': '0',
                    'val': '999',
                    'description': 'Set the defaults of the device to ensure the buttons are enabled and momentary'
                }],
                'SW-M': [{
                    'name': 'TOP Mode',
                    'idx': '0',
                    'val': '999',
                    'description': 'Set the defaults of the device to ensure the buttons are enabled and momentary'
                }]

            },
            'disable': {
                //"DCDIM-DAC":[
                //	{
                //        "name":'Mode',
                //        "idx":'3',
                //        "val": '1',
                //        "description": "Put the device in 0-10V dimming mode"
                //    }
                //],
                //"DCDIM-PWM":[
                //    {
                //        "name":'Mode',
                //        "idx":'3',
                //        "val": '1',
                //        "description": "Put the device in PWM dimming mode"
                //    }
                //],
            }
        },

        'scenes': [
            //On Full
            {
                'name': 'On',
                'hidden': 1,
                'description': 'on',
                'devices': {
                    'Tny': { //TINY_STUFF
                        'min-ver': '05.00.00',
                        trigs: [{
                            'is_mac_action': true,
                            'applicable_parts': tinyApplicableParts,
                            'function': trigConfig.swThreeFunctions[3] //but1release
                        }]
                    },
                    'SW3': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'function': trigConfig.swThreeFunctions[3] //but1release
                        }],
                        acts: [{
                            'function': trigConfig.swThreeFunctions[16], //set led levels 60 10 10
                            'var': '060010010'
                        }]
                    },
                    'SW-M': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'function': trigConfig.medleyFunctions[4] //Button 1 release
                        }],
                        acts: [{
                            'function': trigConfig.medleyFunctions[10], //set led levels 60 10 0 the bottom is the sensor
                            'var': '060010000'
                        }

                        ]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[5], //set auto (100),
                            'var': '100001'
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '05.00.00',
                        acts: [{ //TODO: replace with acdim actions
                            'function': trigConfig.dcdimFunctions[5], //set auto (100),
                            'var': '100001'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['auto-fade'], //set Auto 100% 1 second fade(70)
                            'var': '6401'
                        }]
                    },
                    'PSM': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.psmFunctions['enable-mapped-chans'], //set Auto 100% 1 second fade(70)
                            'var': ''
                        }]
                    },
                    'SW8': {
                        'min-ver': '05.00.00',
                        trigs: [{//standin so we have something to access in configuretriggers, we do this linkid/function calc with
                            //Devices.switch8.sceneToButtonAction and template.getSw8LinkID
                            'function': 'button-press'
                        }],
                    },
                    'XPOE': {
                        ref: 'PHD'
                    },
                    'SW-MLTH': {
                        ref: 'SW-ML'
                    },
                    'DCDIM-PWM': {
                        ref: 'DCDIM-DAC'
                    },
                    'DCDIM-TINY-TEST': {
                        ref: 'DCDIM-DAC'
                    },
                    'ML-DALI': {
                        ref: 'USGG-25P'
                    },
                    'BAF-DRIVE': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM-MLTH': {
                        ref: 'DCDIM-DAC'
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    },
                    'MLTH': {
                        ref: 'DCDIM-DAC'
                    }
                }
            },
            //DIM UP
            {
                'name': 'Raise Lights',
                'hidden': 1,
                'description': 'raise-lights',
                'devices': {
                    'Tny': { //TINY_STUFF
                        'min-ver': '05.00.00',
                        trigs: [{
                            'is_mac_action': true,
                            'applicable_parts': tinyApplicableParts,
                            'function': trigConfig.swThreeFunctions[6] //Hold1
                        }]
                    },
                    'SW3': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'function': trigConfig.swThreeFunctions[6] //Hold1
                        }],
                        acts: [{
                            'function': trigConfig.swThreeFunctions[16], //set LEDs (50000000)
                            'var': '060010010'
                        }]
                    },
                    'SW-M': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'function': trigConfig.medleyFunctions[6] //Hold1
                        }],
                        acts: [{
                            'function': trigConfig.medleyFunctions[10], //set led levels 60 10 0 the bottom is the sensor
                            'var': '060010000'
                        }

                        ]
                    },
                    'SW8': {
                        'min-ver': '05.00.00',
                        trigs: [{//standin so we have something to access in configuretriggers, we do this linkid/function calc with
                            //Devices.switch8.sceneToButtonAction and template.getSw8LinkID
                            'function': 'button-press'
                        }],
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set Manual(10002)
                            'var': '100002'
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set Manual(10002)
                            'var': '100002'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['man-fade'], //set manual 100
                            'var': '6402'
                        }]
                    },
                    'XPOE': {
                        ref: 'PHD'
                    },
                    'SW-MLTH': {
                        ref: 'SW-ML'
                    },
                    'DCDIM-PWM': {
                        ref: 'DCDIM-DAC'
                    },
                    'DCDIM-TINY-TEST': {
                        ref: 'DCDIM-DAC'
                    },
                    'ML-DALI': {
                        ref: 'USGG-25P'
                    },
                    'BAF-DRIVE': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM-MLTH': {
                        ref: 'DCDIM-DAC'
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    },
                    'MLTH': {
                        ref: 'DCDIM-DAC'
                    },
                }
            },
            //DIM DOWN
            {
                'name': 'Lower Lights',
                'hidden': 1,
                'description': 'lower-lights',
                'devices': {
                    'Tny': { //TINY_STUFF
                        'min-ver': '05.00.00',
                        trigs: [{
                            'is_mac_action': true,
                            'applicable_parts': tinyApplicableParts,
                            'function': trigConfig.swThreeFunctions[7] //Hold button two (bottom)
                        }]
                    },
                    'SW3': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'function': trigConfig.swThreeFunctions[8] //Hold bottom button 3
                        }],
                        acts: [{
                            'function': trigConfig.swThreeFunctions[16], //set LEDs (50000000)
                            'var': '010010060'
                        }]
                    },
                    'SW-M': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'function': trigConfig.medleyFunctions[7] //Hold2
                        }],
                        acts: [{
                            'function': trigConfig.medleyFunctions[10], //set led levels 60 10 0 the bottom is the sensor
                            'var': '060010000'
                        }]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set Manual(10002)
                            'var': '9002'
                        }]
                    },
                    'SW8': {
                        'min-ver': '05.00.00',
                        trigs: [{//standin so we have something to access in configuretriggers, we do this linkid/function calc with
                            //Devices.switch8.sceneToButtonAction and template.getSw8LinkID
                            'function': 'button-press'
                        }],
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['man-fade'], //set man
                            'var': '0902'
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set Manual(10002)
                            'var': '3002'
                        }]
                    },
                    'XPOE': {
                        ref: 'PHD'
                    },
                    'SW-MLTH': {
                        ref: 'SW-ML'
                    },
                    'DCDIM-PWM': {
                        ref: 'DCDIM-DAC'
                    },
                    'DCDIM-TINY-TEST': {
                        ref: 'DCDIM-DAC'
                    },
                    'ML-DALI': {
                        ref: 'USGG-25P'
                    },
                    'BAF-DRIVE': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM-MLTH': {
                        ref: 'DCDIM-DAC'
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    },
                    'MLTH': {
                        ref: 'DCDIM-DAC'
                    }
                }
            },
            //Halt Fade
            {
                'name': 'Halt Fade (Hidden)',
                'hidden': 1,
                'description': 'halt-fade',
                'devices': {
                    'Tny': { //TINY_STUFF
                        'min-ver': '05.00.00',
                        trigs: [{
                            'is_mac_action': true,
                            'applicable_parts': tinyApplicableParts,
                            'function': trigConfig.swThreeFunctions[9] //but1release
                        },
                        {
                            'is_mac_action': true,
                            'applicable_parts': tinyApplicableParts,
                            'function': trigConfig.swThreeFunctions[10] //Hold3_release
                        }
                        ]
                    },
                    'SW8': {
                        'min-ver': '05.00.00',
                        trigs: [{//standin so we have something to access in configuretriggers, we do this linkid/function calc with
                            //Devices.switch8.sceneToButtonAction and template.getSw8LinkID
                            'function': 'button-press'
                        }],
                    },
                    'SW3': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'function': trigConfig.swThreeFunctions[9] //Hold1_release
                        },
                        {
                            'function': trigConfig.swThreeFunctions[11] //Hold3_release
                        }
                        ]
                    },
                    'SW-M': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'function': trigConfig.medleyFunctions[8] //Hold1_Release
                        },
                        {
                            'function': trigConfig.medleyFunctions[9] //Hold2_Release
                        }
                        ]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[0], //Halt Fade
                            'var': '1'
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[0], //Halt Fade
                            'var': '1'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.phdFunctions['halt-fade'], //Halt Fade
                            'var': ''
                        }]
                    },
                    'XPOE': {
                        ref: 'PHD'
                    },
                    'SW-MLTH': {
                        ref: 'SW-ML'
                    },
                    'DCDIM-PWM': {
                        ref: 'DCDIM-DAC'
                    },
                    'DCDIM-TINY-TEST': {
                        ref: 'DCDIM-DAC'
                    },
                    'ML-DALI': {
                        ref: 'USGG-25P'
                    },
                    'BAF-DRIVE': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM-MLTH': {
                        ref: 'DCDIM-DAC'
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    },
                    'MLTH': {
                        ref: 'DCDIM-DAC'
                    },
                }
            },
            //Off
            {
                'name': 'Off',
                'hidden': 1,
                'description': 'off',
                'devices': {
                    'Tny': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'is_mac_action': true,
                            'applicable_parts': tinyApplicableParts,
                            'function': trigConfig.swThreeFunctions[4] //but2release
                        }]
                    },
                    'SW3': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'function': trigConfig.swThreeFunctions[5] //but3release
                        }],
                        acts: [{
                            'function': trigConfig.swThreeFunctions[16], //set led levels 60 10 10
                            'var': '010010060'
                        }]
                    },
                    'SW8': {
                        'min-ver': '05.00.00',
                        trigs: [{//standin so we have something to access in configuretriggers, we do this linkid/function calc with
                            //Devices.switch8.sceneToButtonAction and template.getSw8LinkID
                            'function': 'button-press'
                        }],
                    },
                    'SW-M': {
                        'min-ver': '05.00.00',
                        trigs: [{
                            'function': trigConfig.medleyFunctions[5] //Button2-release
                        }],
                        acts: [{
                            'function': trigConfig.medleyFunctions[10], //set led levels 60 10 0 the bottom is the sensor
                            'var': '010060000'
                        }]
                    },
                    'DCDIM-DAC': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set auto (100),
                            'var': '0'
                        }]
                    },
                    'USGG-25P': {
                        'min-ver': '05.00.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set auto (100),
                            'var': '0'
                        }]
                    },
                    'PHD': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.phdFunctions['man-fade'], //set Auto 100% 1 second fade(70)
                            'var': '0001'
                        }]
                    },
                    'PSM': {
                        'min-ver': '05.00.00',
                        'acts': [{
                            'function': trigConfig.psmFunctions['disable-mapped-chans'], //set Auto 100% 1 second fade(70)
                            'var': ''
                        }]
                    },
                    'XPOE': {
                        ref: 'PHD'
                    },
                    'SW-MLTH': {
                        ref: 'SW-ML'
                    },
                    'DCDIM-PWM': {
                        ref: 'DCDIM-DAC'
                    },
                    'DCDIM-TINY-TEST': {
                        ref: 'DCDIM-DAC'
                    },
                    'ML-DALI': {
                        ref: 'USGG-25P'
                    },
                    'BAF-DRIVE': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM': {
                        ref: 'USGG-25P'
                    },
                    'ACDIM-MLTH': {
                        ref: 'DCDIM-DAC'
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    },
                    'MLTH': {
                        ref: 'DCDIM-DAC'
                    }
                }
            }
        ]
    },
    'toggle-occupancy-mode': {
        'name': 'toggle-occupancy-mode',
        'description': 'Toggle between nighttime and daytime modes',
        'datapoints': {
            'enable': {},
            'disable': {}
        },
        'scenes': [
            //Good morning
            {
                'name': 'Start Normal Hours',
                'hidden': 0,
                'description': 'start-open-hours',
                'devices': {
                    'SW-ML': {
                        acts: [{
                            'function': trigConfig.mlFunctions[11], //Normal motion
                            'var': 0
                        },
                        {
                            'function': trigConfig.mlFunctions[5], //Normal motion
                            'var': '1'
                        }
                        ]
                    },
                    'USGG-25P': {
                        acts: [{
                            'function': trigConfig.mlFunctions[11], //Normal motion
                            'var': 0
                        }]
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    },
                    'MLTH': {
                        ref: 'USGG-25P'
                    }
                }
            },
            //Good night
            {
                'name': 'Start After Hours',
                'hidden': 0,
                'description': 'start-closed-hours',
                'devices': {
                    'SW-ML': {
                        acts: [{
                            'function': trigConfig.mlFunctions[11], //Normal motion
                            'var': '3'
                        },
                        {
                            'function': trigConfig.mlFunctions[5], //Normal motion
                            'var': '1'
                        }
                        ]
                    },
                    'DCDIM-DAC': {
                        acts: [{
                            'function': trigConfig.dcdimFunctions[4], //set manual off at 2s fade
                            'var': '000002'
                        }]
                    },
                    'USGG-25P': {
                        acts: [{
                            'function': trigConfig.mlFunctions[11],
                            'var': '3' //Nighttime motion
                        }]
                    },
                    'ALC': {
                        ref: 'USGG-25P'
                    },
                    'ALC-LVD': {
                        ref: 'USGG-25P'
                    },
                    'DRIVER': {
                        ref: 'USGG-25P'
                    },
                    'MLTH': {
                        ref: 'USGG-25P'
                    }

                }
            }
        ]
    },
    'toggle-demand-response': {
        'name': 'toggle-demand-response',
        'description': 'Go into Load Shed mode',
        'datapoints': {
            'enable': {
                'DCDIM-DAC': [{
                    'name': 'Shed Level',
                    'idx': '104',
                    'val': '70',
                    'description': 'Enable default Load Shed Level'
                }],
                'DCDIM-PWM': [{
                    'name': 'Shed Level',
                    'idx': '104',
                    'val': '70',
                    'description': 'Enable default Load Shed Level'
                }],
                'SW3': [{
                    'name': 'Shed Level',
                    'idx': '104',
                    'val': '70',
                    'description': 'Enable default Load Shed Level'
                }],
                'SW-M': [{
                    'name': 'Shed Level',
                    'idx': '104',
                    'val': '70',
                    'description': 'Enable default Load Shed Level'
                }],
                'DRIVER': [{
                    'name': 'Shed Level',
                    'idx': '104',
                    'val': '70',
                    'description': 'Enable default Load Shed Level'
                }],
                'ALC': [{
                    'name': 'Shed Level',
                    'idx': '104',
                    'val': '70',
                    'description': 'Enable default Load Shed Level'
                }],
                'MLTH': [{
                    'name': 'Shed Level',
                    'idx': '104',
                    'val': '70',
                    'description': 'Enable default Load Shed Level'
                }],

            },
            'disable': {
                'DCDIM-DAC': [{
                    'name': 'Shed Level',
                    'idx': '104',
                    'val': '0',
                    'description': 'Enable default Load Shed Level'
                }],
                'DCDIM-PWM': [{
                    'name': 'Shed Level',
                    'idx': '104',
                    'val': '0',
                    'description': 'Enable default Load Shed Level'
                }],
                'SW3': [{
                    'name': 'Shed Level',
                    'idx': '104',
                    'val': '0',
                    'description': 'Enable default Load Shed Level'
                }],
                'SW-M': [{
                    'name': 'Shed Level',
                    'idx': '104',
                    'val': '0',
                    'description': 'Enable default Load Shed Level'
                }],
                'DRIVER': [{
                    'name': 'Shed Level',
                    'idx': '104',
                    'val': '0',
                    'description': 'Enable default Load Shed Level'
                }],
                'ALC': [{
                    'name': 'Shed Level',
                    'idx': '104',
                    'val': '0',
                    'description': 'Enable default Load Shed Level'
                }],
                'MLTH': [{
                    'name': 'Shed Level',
                    'idx': '104',
                    'val': '0',
                    'description': 'Enable default Load Shed Level'
                }],
            }
        },
        'scenes': [
            //Load Shed Start
            {
                'name': 'Load Shed Start',
                'hidden': 0,
                'description': 'load-shed-start',
                'devices': {
                    'MLTH': {
                        'min-ver': '7.47.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[7], //Load Shed
                            'var': 1001
                        }]
                    },
                    'DRIVER': {
                        ref: 'MLTH'
                    },
                    'ALC': {
                        ref: 'MLTH'
                    },
                    'ALC-LVD': {
                        ref: 'MLTH'
                    },
                    'PHD':{
                        'min-ver': '7.41.00',
                        parent:{
                            acts: [{
                                'function': trigConfig.phdFunctions['set-load'], //Load Shed
                                'var': 1097364129797
                            }]
                        }
                    }
                }
            },
            //Load Shed End
            {
                'name': 'Load Shed End',
                'hidden': 0,
                'description': 'load-shed-end',
                'devices': {
                    'MLTH': {
                        'min-ver': '7.47.00',
                        acts: [{
                            'function': trigConfig.dcdimFunctions[7], //Load Shed
                            'var': 5,
                        }]
                    },
                    'DRIVER': {
                        ref: 'MLTH'
                    },
                    'ALC': {
                        ref: 'MLTH'
                    },
                    'ALC-LVD': {
                        ref: 'MLTH'
                    },
                    'PHD':{
                        'min-ver': '7.41.00',
                        parent:{
                            acts: [{
                                'function': trigConfig.phdFunctions['set-load'], //Load Shed
                                'var': 1097364078597
                            }]
                        }
                    }
                }
            }
        ]
    },
};

export {
    MyTemplates,
    DEVICE_ACTION_LOOKUP,
    StandardTemplates,
    tinyApplicableParts,
};