import { createSelector } from 'reselect';

export const getUsers = state => state.users;
export const isLoadingUsers = createSelector(getUsers, users => users.loading);
export const getUsersError = createSelector(getUsers, users => users.error);
export const getUsersItems = createSelector(getUsers, users => users.items);
export const getUsersTable = createSelector(getUsers, users => users.table);

export const getUsersTableSorting = createSelector(getUsersTable, table => table.sorting);
export const getUsersTableFilters = createSelector(getUsersTable, table => table.filters);
export const getUsersTableCurrentPage = createSelector(getUsersTable, table => table.currentPage);
export const getUsersTablePageSize = createSelector(getUsersTable, table => table.pageSize);
export const getUsersTablePageSizes = createSelector(getUsersTable, table => table.pageSizes);
