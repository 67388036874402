import SDK from 'api/sdk';
import {
    listEvents,
    updateEvent,
    deleteEvent,
    retrieveEvent,
} from './actions';

const dateFilters = (filter, params, sorting) => {
    let created_at = null;
    let start = null;
    let end = null;
    let pageStart = null;
    let pageEnd = null

    const sortDirection = sorting.filter(sort => sort.columnName === 'created_at')[0].direction;

    const [rangeEnd, rangeStart] = filter.value.date_range;
    const [direction, timestamp] = filter.value.navigation;

    if (sortDirection === 'asc') {
        pageEnd = (direction === 'before') ? timestamp : null;
        pageStart = (direction === 'after') ? timestamp : null;
    } else {
        pageStart = (direction === 'before') ? timestamp : null;
        pageEnd = (direction === 'after') ? timestamp : null;
    }

    const arrayStart = [rangeStart, pageStart].filter(Number);
    const arrayEnd = [rangeEnd, pageEnd].filter(Number);

    if(arrayStart.length) start = Math.min.apply(null, arrayStart);
    if(arrayEnd.length) end = Math.max.apply(null,  arrayEnd);

    if (start && !end) {
        created_at = `lt:${start}`;
    } else if (!start && end) {
        created_at = `gt:${end}`;
    } else if (start && end){
        created_at = `between:${end},${start}`;
    }

    if (created_at !== null) {
        Object.assign(params, { created_at });
    }
    return direction === 'after';
}

export function listEventsTrigger(limit, search, filters, sorting, useDebounce = true) {
    const thunk = dispatch => {
        let reverseSorting = false;
        const id = window.localStorage.getItem("amatis_site_id");
        const params = { SITEID: id, limit, critical: 1 };
        if(search && search.trim() !== '') {
            Object.assign(params, { search: search.trim() });
        }

        if(filters && filters.length > 0) {
            filters.forEach(filter => {
                if(filter.columnName === 'created_at') {
                    reverseSorting = dateFilters(filter, params, sorting);
                } else {
                    Object.assign(params, { [filter.columnName]: `in:${filter.value}` });
                }
            });
        }

        if(sorting && sorting.length > 0) {
            const sortParam = [];
            sorting.forEach(sort => {
                let isDesc = sort.direction === "desc";
                if (reverseSorting && sort.columnName === 'created_at') isDesc = !isDesc;
                const field = isDesc ? `-${sort.columnName}`: sort.columnName;
                sortParam.push(field);
            });
            Object.assign(params, { sort: sortParam.join(",") });
        }

        const OPTIONS_SDK = { serverVariables: { baseVersion: "v2" } };
        dispatch(listEvents.request());
        return SDK.init()
            .then(api => api.events.getEventsBySite(params, OPTIONS_SDK))
            .then(response => {
                const { body: { meta, data } } = response;

                const newData = (reverseSorting) ? data.reverse() : data;

                dispatch(listEvents.success({ meta, data: newData }));
            })
            .catch(error => {
                dispatch(listEvents.failure(error));
            })
            .finally(() => {
                dispatch(listEvents.fulfill());
            });
    };
    if(useDebounce === true){
        thunk.meta = {
            debounce: {
                time: 300,
                key: 'LIST_EVENTS/TRIGGER'
            }
        };
    }
    return thunk;
}

export const retrieveEventTrigger = id => (dispatch, getState) => {
    const { events } = getState();
    const expandedRowIds = events.getIn(['table', 'expandedRowIds']);
    const idExist = expandedRowIds.filter(rowId => rowId === id);

    if (idExist.length === 1) {
        const SITEID = window.localStorage.getItem('amatis_site_id');
        const OPTIONS = { serverVariables: { baseVersion: "v2" } };
        const params = { SITEID, EVENTID: id };
        dispatch(retrieveEvent.request(id));
        return SDK.init()
            .then(api => api.events.getEventBySite(params, OPTIONS))
            .then(response => {
                const { body: { data } } = response;
                dispatch(retrieveEvent.success(data));
            }).catch(error => {
                dispatch(retrieveEvent.failure(error));
            })
            .finally(() => {
                dispatch(retrieveEvent.fulfill(id));
            });
    }
};

export const updateEventTrigger = (id, values) => dispatch => {
    const SITEID = window.localStorage.getItem('amatis_site_id');
    const OPTIONS = { requestBody: values, serverVariables: { baseVersion: "v2" } };
    const params = { SITEID, EVENTID: id };
    dispatch(updateEvent.request());
    return SDK.init()
        .then(api => api.events.pathEvent(params, OPTIONS))
        .then(response => {
            const { body: { data } } = response;
            dispatch(updateEvent.success(data));
        }).catch(error => {
            dispatch(updateEvent.failure(error));
        })
        .finally(() => {
            dispatch(updateEvent.fulfill());
        });
};

export const deleteEventTrigger = id => dispatch => {
    const SITEID = window.localStorage.getItem('amatis_site_id');
    const OPTIONS = { serverVariables: { baseVersion: "v2" } };
    const params = { SITEID, EVENTID: id };
    dispatch(deleteEvent.request());
    return SDK.init()
        .then(api => api.events.deleteEvent(params, OPTIONS))
        .then(response => {
            const { body: { data } } = response;
            dispatch(deleteEvent.success(data));
        }).catch(error => {
            dispatch(deleteEvent.failure(error));
        })
        .finally(() => {
            dispatch(deleteEvent.fulfill());
        });
};
