//Device Object Class -> Makes actual device objects
import {Locations} from  'api/amatis/site/locations/services/locations';
import App from 'app/app';
import Devices from './services/devices';
import {Scenes} from 'api/amatis/site/scenes/services/scenes';
import {isnull_location_id} from 'classes/methods';
import Amatis from 'api/amatis/amatis';
import User from 'classes/users';
import reverseCalculator from 'components/app/menu/scenes/scene-config-modal/calculators/ReverseCalculatorVar1';
import { calculatorVar1Phd } from 'components/app/menu/scenes/scene-config-modal/calculators/CalculatorFuntions';

class Device extends Amatis {
    constructor(info){
        super(info);
        this.internalActions = {};
        this.externalActions = {};
        this.externalActionsToSave = [];
        this.scenesFlag = false;
        this.ranCleanup = false;
        this.amatisType = 'devices';
        this.dualTech = false;
        //SGD: All of these need to be ported to be methods of this class that reference `this`
        this.determineSignalStrength = Devices.DetermineSignalStrength; //Returns a class for signal strength icon
        this.ota = Devices.Ota; //Put the latest software into the device
        this.swInfo = Devices.SwInfo; //GET software info from the device
        this.setTargetLightLevel = Devices.SetTargetLightLevel; //For DLL sensors, sets the current value as target
        this.reboot = Devices.Reboot; //Soft reboot
        this.rebootMCBA = Devices.RebootMCBA; //PHD Only for now
        this.resetMCBAEnergies = Devices.ResetMCBAEnergies; //PHD only for now
        this.getData = Devices.GetData; //Request full data dictionary
        this.getKeys = Devices.GetKeys; //Ask for the device 'action keys' returns the keys as JSON
        this.upload = Devices.Upload; //Request actions from the device and put them in the cloud (P to C)
        this.save = Devices.Save; //Save cloud actions to the device (C to P)
        this.isTalking = Devices.VerifyDeviceIsTalking; //Returns true or false based on the value of 'acceptableAge' in the function
        this.findAssocScenes = Devices.findAssocScenes; //Returns all of the scenes this device is associated with
        this.findDevicesToCopy = Devices.FindDevicesToCopy; //Returns devices of the same type and location
        this.updateUIComponents = this.updateUIComponents.bind(this);
        this.wroteActions = false;
        this.finishLoad = this.finishLoad.bind(this);
        this.data = [];
        this.dnameToIndex = {};
        this.indexToDname = {};
        this.talking = true;
        this.dataTable = {};
        this.location_id = info.location_id;
        this.listItem = false;
        this.unsaved = false;
        this.assocGroups = [];
        this.assocDevicesGroups = [];
        this.network_id = this.networks_id;
        this.outputs = {};

        //Push this thing to the list(s) that holds it
        App.Site.devices[this.ID] = this;
        Devices.list.push(this);

        App.Site.deviceLookup[this.ip_address] = this.ID;
        this.sync_status = null;
        this.nameIsEditable = false;
        this.whiteList = new Set([
            'type',
            'notes',
            'name',
            'pass_fail',
            'role',
            'compile_time',
            'version',
            'deleted',
            'site_id',
            'ip_address',
            'network_id',
            'location_id',
            'target_network_id',
            'device_groups',
            'targetVersion',
            'otaTagVersion'
        ]);
        this.ident_icon = 'lightbulb';
        this._lastPing = false;
        this.lastPingLoaded = false;
        this._rssi = false;
        this._level = false;
        this._occupied = false;
        this._occ_enable = false;
        this._sensorID = false;
        this.removeDeletedRows = this.removeDeletedRows.bind(this);
    }
    async finishLoad() {
        Devices.getLiveData();
        if(App.Site.readyForActions === 2){
            Scenes.getSceneTable();
            // Get deleted linktables for the entire site
            Scenes.getSceneTable({ deleted: true });
        }
        if(App.Site.hasMultiChannelDevices === true){
            // TODO: We should just always get these, no reason for these IFs
            if(App.Site.hasPHD === true){
                Devices.getPhdOutputs();
            }
            if(App.Site.hasSw8 === true){
                Devices.getSw8Outputs();
            }
        }

        await Devices.getMCDOutputs();

        if(!App.ActiveLocation.linksTablesLoaded && App.Site.readyForActions === 1) {
            App.ActiveLocation.linksTablesLoaded = true;
        }
        Devices.getGroups();
        this.updateUIComponents();
    }
    updateUIComponents(){
        if(App.updateUIComponentsIsBusy('Device', this.updateUIComponents)){
            return;
        }

        if(App.ActiveLocation !== false && App.ActiveLocation.isRoot === false){
            if(App.hasOwnProperty('SiteDashboardContainer')){
                App.SiteDashboardContainer.setLocationStateDevices(App.ActiveLocation);
            }
        }else{
            if(App.hasOwnProperty('DeviceContainer')){
                App.DeviceContainer.setDeviceList(Devices.list);
            }
        }
        if(App.hasOwnProperty('SyncContainer')){
            App.SyncContainer.setDeviceList(Devices.list, true);
        }
        if(App.hasOwnProperty('PageContent')){
            App.PageContent.setDeviceListLoadedFlag(true);
        }
        if(App.hasOwnProperty('ConfigTables')){
            App.ConfigTables.setDeviceList(Devices.list);
        }
    }
    //check for saved setpoints written while device was offline and write them to the device now that it is talking
    checkForAndWriteSavedSetpoints(){
        if(App.Site.offlineSetpointsToWrite.hasOwnProperty(this.id) && this.isTalking()){
            let setpointsToWrite = App.Site.offlineSetpointsToWrite[this.id];
            let setpointQueue = [];
            for(let key in setpointsToWrite){
                let active = setpointsToWrite[key];
                let endpoint;
                if('idx' in active){
                    endpoint = `${active.ip}/setpoint`;
                }else if(active.hasOwnProperty('dname')){
                    endpoint = active.ip+'/dd/'+active.dname.replace(/\s+/g, '_');
                }else{
                    console.log("Error: active setpoint has no dname or idx", active);
                    continue;
                }
                delete active.ip;
                delete active.dname;
                setpointQueue.push({'data':active,'endpoint':endpoint});
            }
            if(setpointQueue.length > 0){
                Devices.writeManySetpoints(setpointQueue);
                delete App.Site.offlineSetpointsToWrite[this.id];
                window.localStorage.setItem('offlineSetpointsToWrite', JSON.stringify(App.Site.offlineSetpointsToWrite));
            }
        }
    }
    set part_type(part_type) {
        this.type = part_type;
    }

    get network(){
        if(this.network_id !== null && App.Site.networks[this.network_id]){
            return App.Site.networks[this.network_id];
        }

        return null;
    }

    get networkName(){
        if(this.network !== null){
            return this.network.name;
        }

        //Return an empty string for name so that we can treat it like a string
        return '';
    }

    get targetNetwork(){
        if(this.target_network_id !== null && App.Site.networks[this.target_network_id]){
            return App.Site.networks[this.target_network_id];
        }

        return null;
    }

    // set targetNetwork(network_id){

    // }

    get targetNetworkName(){
        if(this.targetNetwork !== null){
            return this.targetNetwork.name;
        }

        //Return an empty string for name so that we can treat it like a string
        return '';
    }

    get rssi(){
        return this._rssi;
    }
    set rssi(rssi){
        this._rssi = rssi;
    }
    get occpied(){
        return this._occupied;
    }
    set occpied(value){
        this._occupied = value;
    }
    get occ_enable(){
        return this._occ_enable;
    }
    set occ_enable(value){
        this._occ_enable = value;
    }
    get sensorID(){
        return this._sensorID;
    }
    set sensorID(value){
        this._sensorID = value;
    }
    get level(){
        return (this._level !== false) ? `${this._level}%` : false;
    }
    set level(level){
        this._level = level;
    }
    get MAC(){
        let MAC;
        MAC = this.ip_address.substring(19).replace(/:/g, ''); //The mac addy of the device, matches barcodes ~last 12 no colons
        MAC = MAC.replace(/fffe/g, '').toUpperCase();
        return MAC;
    }

    get altID(){
        return Devices.convertLinkLocalAddyToInterfaceID(this.MAC).toUpperCase();
    }
    //winks the applicable device. called on deviceAssocScenesPage as of 12-20-13
    async ident(deviceIndex, isLittleList) { //TODO:I hate the way the little list is being handled but ill circle back
        var valueToSend = '0x5'; //internal linkid for identify for all devices
        var request = {
            'link_id': valueToSend,
            'var1': valueToSend
        };

        return new Promise((resolve, reject) =>{
            this.post(request, `${this.IP}/identify`).then((success)=>{
                resolve(success);
            }).catch((error)=>{
                reject(error);
            });
        });
    }
    get lastPing(){
        return this._lastPing;
    }
    set lastPing(lastPing){
        if(this.lastPing === false || this.lastPing < lastPing){
            this._lastPing = lastPing;
            this.lastPingLoaded = true;
            this.checkForAndWriteSavedSetpoints();
        }
    }

    get nameWithLastFour(){
        return `${this.name} (${this.ip_address.substr(-4)})`;
    }

    get shortName(){
        let name = this.name;
        let shortName;
        if(name.length > 10) {
            shortName = name.substring(0,7)+'...';
        }else{
            shortName = name;
        }
        return shortName;
    }

    get IP(){
        return this.ip_address;
    }

    get deviceID(){
        //The mac addy of the device, matches barcodes ~last 12 no colons
        return this.ip_address.substring(19).replace(/:/g, '').replace(/fffe/g, '').toUpperCase();
    }

    //Read only
    set IP(ip){
        this._IP = ip;
    }

    get swVersion(){
        return (this.version) ? this.version : '';
    }

    set swVersion(swVersion){
        this._swVersion = swVersion;
    }

    get Role(){
        return this.role;
    }

    set Role(role){
        this.role = role;//set name
        this.setProps({'role':role});
    }

    set TYPE(type){
        this.type = type;
    }

    set type(type){
        this._type = type;
        this.partType = type;//this will go away...need to replace a lot first
        if(this.isMultiChannel){
            App.Site.hasPHD = true;
            App.Site.hasMultiChannelDevices= true;
            if(this.partType === 'AM-SW8'){
                App.Site.hasSw8 = true;
            }
            App.Site.multiChannelDevices[this.id] = this;
            App.Site.multiChannelDevices[this.id].outputs = {};
            App.Site.multiChannelDevices[this.id].outputsById = {};
        }
        if(Devices.defines.hasOwnProperty(type)){
            Object.assign(this, Devices.defines[type]);
        }else{
            if(type === 'SWITCH-B' || type === 'SWITCH-W'){
                Object.assign(this, 'Tny');
                this._type='Tny';
                this.partType='Tny';
                this.classification = 'switch';
                this.is_switch = true;
            }else{
                Object.assign(this, Devices.defines.default);
            }
        }
    }

    get part_type(){
        return this._type;
    }

    get type(){
        return this._type;
    }

    get isMasterOccByRole(){
        if(this.role === 'master_motion' || this.role === 'master_motion_dll'){
            return true;
        }else{
            return false;
        }
    }

    get isMasterOccByName(){
        if(this.name.toLowerCase().indexOf('master') >= 0){
            return true;
        }else{
            return false;
        }
    }
    get isMasterDllByRole(){
        if(this.role === 'master_motion_dll' || this.role === 'master_dll'){
            return true;
        }else{
            return false;
        }
    }
    get isMasterDllByName(){
        if(this.name.indexOf('dll') >=0 ){
            return true;
        }else{
            return false;
        }
    }
    get location(){
        if(App.Site.hasOwnProperty('locations') && App.Site.locations.hasOwnProperty(this.location_id)){
            return App.Site.locations[this.location_id];
        }else{
            return false;
        }
    }

    set location(location){
        if(typeof(location) === 'object' && location.hasOwnProperty('ID')){
            this.setProps({'location_id':location.ID});
        }else{
            this.setProps({'location_id':location});
        }
    }

    get signalStrengthIcon () {
        if (this.partType === 'Tny' || this.partType === 'Tiny') {
            return null;
        }
        //if more than 15 minutes old
        if (!this.talking) {
            return 'exclamation-triangle';
        }else{
            return null;
        }
        // let rssi_val = Math.abs(parseInt(this.rssi), 10);
        // if (rssi_val === 0)
        //     return 'signal-2';
        // else if (rssi_val <= 30)
        //     return 'signal-5';
        // else if (rssi_val <= 50)
        //     return 'signal-4';
        // else if (rssi_val <= 60)
        //     return 'signal-3';
        // else if (rssi_val <= 70)
        //     return 'signal-2';
        // else if (rssi_val <= 85)
        //     return 'signal-1';
        // else
        //     return 'signal-1';
    }

    get isTagged(){
        if(this.location_id === null || isnull_location_id(this.location_id)){
            return false;
        } else {
            return true;
        }
    }
    //when we add a device to a new location we want to parse through the data and associate any necessary lightlevel/occupancy data with its location
    parseDataForNewLocationAssociation(){//and rebuild the locations ui components to reflect this
        if(Object.keys(this.dataTable).length > 0){
            this.update(this.dataTable);
        }
    }

    get errors(){
            let errorObj = {
                error: false,
                message: '',
                lastPing: '',
            };
            if (!this.isTalking() && this.lastPingLoaded === true) {
                errorObj.error = true;
                let deviceAge = this.age;
                let lastComm = '';

                if (deviceAge > 1209600) {
                    lastComm = ' has not communicated in more than 2 weeks.';
                } else if (deviceAge > 604800) {
                    lastComm = ' has not communicated in the last 7 days.';
                } else if (deviceAge > 86400) {
                    lastComm = ' has not communicated in the last 24 hours.';
                } else if (deviceAge > 10800) {
                    lastComm = ' has not communicated in the last 3 hours.';
                }
                let deviceName = this.nameWithLastFour;
                errorObj.message = deviceName + lastComm;
                errorObj.lastPing = 'Last Ping: ' + App.tm(this.lastPing - App.converter);
                this.talking = false;
            }
            return errorObj;
    }
    get deviceShouldReportData(){
        if (this.partType === 'Tny' || this.partType === 'Tiny' || this.partType === 'SWITCH-B') {
            return false;
        }
        return true;
    }
    get age(){
        let age = parseInt((Math.round(new Date() / 1000) - (this.lastPing - App.converter)), 10);
        return age;
    }
    get status(){
        if(this.isTalking() || this.deviceShouldReportData){
            if (this.age < 1800){
                return 'green';
            } else if (this.age < 86400){
                return 'yellow';
            } else {
                return 'red';
            }
        }
        return false;
    }
    setSyncIcon(sync_needed, is_talking){
        if(sync_needed === true && is_talking){
            if(App.hasOwnProperty('NavIcons')){
                App.NavIcons.setSyncNeeded(true);
            }
        } else if (sync_needed === true) {
            if(App.hasOwnProperty('NavIcons')){
                App.NavIcons.setStaleSyncNeeded(true);
            }
        }
    }
    set unsaved(unsaved){
        if(!Devices.actionsParsing){
            this.setSyncIcon(unsaved, this.isTalking());
        }
        this._unsaved = unsaved;
    }
    get unsaved(){
        return this._unsaved;
    }
    get isSwitch(){
        if(this.partType === 'Tny' || this.partType === 'SWITCH-W' || this.partType === 'SWITCH-B'){
            return true;
        }else{
            return false;
        }
    }
    async removeExternalActions(input=false, isMultiChannelOutputDevice=false){
        let deleted = false;
        let location = this.location;
        if(input !== false){
            location = input.location;
        }
        //go through possible switch control triggers and remove triggers associated with this switch
        if(this.classification === 'switch'){
            let scenes = location.scenes;
            for(let id in scenes){
                let desc = scenes[id].description;
                if(desc === 'on' || desc === 'off' || desc === 'raise-lights' || desc === 'lower-lights' || desc === 'halt-fade'){
                    for(let trig in scenes[id].triggers){
                        let active = scenes[id].triggers[trig];
                        if('tiny_ip' in active && active.tiny_ip.substr(-4) === this.ip_address.substr(-4)){
                            active.name = 'deleted';
                            active.saved = User.myUser.id;
                            active.table_row = '65535';
                            delete active.tiny_ip;
                            App.Site.root.insertActionToSave(active);
                            deleted = true;
                        }
                        if(input !== false && active.ip_address === this.ip_address && ((active.link_id - 15) === parseInt(input.output))){
                            active.name = 'deleted';
                            active.saved = User.myUser.id;
                            active.table_row = '65535';
                            App.Site.root.insertActionToSave(active);
                            deleted = true;
                        }
                    }
                }
            }
        }else if (isMultiChannelOutputDevice) { //if phd remove one channel leaving the scene with the rest or otherwise removing it
            const scenes = location.scenes;
            const activeOutput = input.output
            if (scenes && activeOutput) {
                for (let actionId in this.externalActions) {
                    if (this.externalActions[actionId].name !== "deleted") {
                        const isActionOnLocation = Object.keys(scenes).some(
                            key => scenes[key].link_id === this.externalActions[actionId].link_id);
                        if (isActionOnLocation) {
                            const { name, var_1: var1 } = this.externalActions[actionId];
                            let reversedCalculatorResult = reverseCalculator(name, var1);
                            if (typeof activeOutput === "number") {
                                reversedCalculatorResult.channels = reversedCalculatorResult.channels.filter(chan => chan !== activeOutput);
                            } else if (typeof activeOutput === "string") {
                                reversedCalculatorResult.zones = reversedCalculatorResult.zones.filter(zone => zone !== activeOutput);
                            }
                            const {channels, zones, fade, level} = reversedCalculatorResult;
                            const recalculatedVar1 = calculatorVar1Phd(zones, channels, level, fade);
                            if ((channels.length === 0 && zones.length === 0) || recalculatedVar1 === 0) {
                                this.externalActions[actionId].name = 'deleted';
                                this.externalActions[actionId].table_row ='65535';
                            } else {
                                this.externalActions[actionId].var_1 = recalculatedVar1;
                            }
                            this.externalActions[actionId].saved = User.myUser.id;
                            App.Site.root.insertActionToSave(this.externalActions[actionId]);
                            deleted = true;
                        }
                    }
                }
            }
        }
        else {//remove all actions
            for(let id in this.externalActions){
                this.externalActions[id].name = 'deleted';
                this.externalActions[id].table_row ='65535';
                this.externalActions[id].saved = User.myUser.id;
                App.Site.root.insertActionToSave(this.externalActions[id]);
                deleted = true;
            }
        }
        if(deleted === true){
            let actions = await App.Site.root.post({'data':'['+App.Site.root.externalActionsToSave+']'}, false,  'site_table_edit', 'v2');
            App.Site.root.externalActionsToSave = '';
            Devices.parseActions(actions);
            this.unsaved = true;
        }
    }
    get isMultiChannel(){
        //TODO: This should check the device classification, not the names.
        if(this.partType.indexOf('PHD') >=0 || this.partType.indexOf('PSM') >= 0 || this.partType === 'AM-SW8' || this.partType === 'XPOE'){
            return true
        }else{
            return false;
        }
    }
    get isMultiChannelOutputDevice(){
        if(this.partType === 'AM-PHD' || this.partType === 'XPOE'){
            return true
        }else{
            return false;
        }
    }
    normalizeDataTimeStamp(ts){
        if(ts > (new Date().getTime()/1000)){
            ts -= App.converter;
        }
        return ts;
    }
    update(data, timestamp=false, liveData=false){
        let i, activeData;
        let dataLength = data.length;
        let sceneName;
        let updateUIComponents = false;
        let valToSet;
        let activeLocation = false;
        const oldStatus = this.status;

        if(timestamp !== false){
            this.setProps({'lastPing': timestamp}, true, true);
        }

        if (this.status != oldStatus){
            this.updateUIComponents();
        }

        for(i = 0; i < dataLength; i++){
            activeData = data[i];

            //TODO: figure out what to do with data with no dnamae besides store it
            //build/update the data table for the device
            if(activeData.hasOwnProperty('idx')){
                const activeIdx = activeData.idx;
                if(timestamp !== false){
                    activeData.timestamp = this.normalizeDataTimeStamp(timestamp);
                }

                //Update the device data with the incoming data, (without erasing datapoint_id)
                if (this.dataTable.hasOwnProperty(activeIdx)){
                    for(let prop of Object.keys(activeData)){
                        //Update any properties contained in the new dataset
                        if(this.dataTable[activeIdx].hasOwnProperty(prop)){
                            this.dataTable[activeIdx][prop] = activeData[prop];
                        }
                    }
                }else{
                    //If we have no data yet, we will take what we can get
                    this.dataTable[activeIdx] = activeData;
                }

                if(!('dname' in activeData)){
                    if(activeIdx in this.indexToDname){
                        activeData['dname'] = this.indexToDname[activeIdx];
                    }
                }

                if('dname' in activeData){
                    this.dnameToIndex[activeData.dname] = activeIdx;
                    this.indexToDname[activeIdx] = activeData.dname;
                }

            }


            //TODO: Have a seperate handler for this instead of more distributed switch stuff
            if(this.isMultiChannel ){
                if(this.isMultiChannelOutputDevice){
                    this.parsePhdOutputData(data, liveData);
                    if(activeData.dname === 'Load Shed' && parseInt(activeData.cval) === 1){
                        this.location.loadShedOn = true;
                    }
                    continue;
                } else if (this.partType === 'AM-SW8'){
                    // The problem here is that the parent device has the occupied state
                    //   so the parent will be the one reporting occupied, so we need to determine
                    //   if the parent is in occupancy mode (sw1 mode == 5).
                    //   Then we overload the location using activeLocation to the location of
                    //   the output (sw1 mode), instead of the location of the parent
                    for (let datapoint in this.dataTable) {
                        const activeDatapoint = this.dataTable[datapoint];
                        if (activeDatapoint.dname === 'SW1 Mode') {
                            if(parseFloat(activeDatapoint.cval) === 5) {
                                const datapointID = activeDatapoint.Instance
                                const siteSW8Inputs = App.Site.sw8Inputs;

                                if (siteSW8Inputs[datapointID]) {
                                    const referenceInput = siteSW8Inputs[datapointID];
                                    const referenceLocationID = referenceInput.location_id;
                                    //Use the input/outputs location
                                    activeLocation = App.Site.locations[referenceLocationID];
                                }
                            }

                            // Only SW1 matters here, so we can break the loop if not 5
                            break;
                        }
                    }
                }
            }

            //handle data by dname accordingly
            if('dname' in activeData){
                if('Instance' in activeData){
                    App.Site.deviceDnameToInstanceID[activeData.dname] = activeData.Instance;
                }
                valToSet = false;
                switch(activeData.dname){
                    case 'PIR Value':
                    case 'PIR Timeout':
                    case 'PIR Timeout2':
                    case 'NH Timeout':
                    case 'AH Timeout':
                    case 'Timeout Mode':
                    case 'Occupied':
                    case 'Occ Enable':
                    case 'PIR Enable':
                    case 'DLL Enable':
                    case 'Lt Enable':
                    case 'Occ Mode':
                    case 'Occ Mode Enable':
                        if(activeData.dname === 'Occupied'){
                            this.occupied = parseInt(activeData.cval, 10, 10);
                        }

                        if(activeData.dname === 'Occ Enable'){
                            this.occ_enable = parseInt(activeData.cval, 10, 10);
                        }
                        
                        if(!this.isTalking() && this.id in App.Site.offlineSetpointsToWrite && (this.ip_address+activeData.dname.replace(/\s+/g, '_')) in App.Site.offlineSetpointsToWrite[this.id]){
                            activeData.cval = App.Site.offlineSetpointsToWrite[this.id][this.ip_address+activeData.dname.replace(/\s+/g, '_')].value;
                            activeData.timestamp = this.lastPing;//if device is offline well use the lastping as the timestamp
                        }

                        //Pass the device location if location hasnt been overridden
                        activeLocation = activeLocation || this.location;
                        if(Locations.calculateOccupancy(activeLocation, activeData, this.ip_address)){
                            updateUIComponents = true;
                        }
                    break;
                    case 'Min Out':
                        valToSet = parseFloat(activeData.cval);
                        if(this.location !== false && this.location.featureSettings['dimmer-trim'].settings['low-trim'].value !== valToSet){
                            this.location.featureSettings['dimmer-trim'].settings['low-trim'].value = valToSet;
                            updateUIComponents = true;
                        }
                    break;
                    case 'Max Out':
                        valToSet = parseFloat(activeData.cval);
                        if(this.location !== false &&  this.location.featureSettings['dimmer-trim'].settings['high-trim'].value !== valToSet){
                            this.location.featureSettings['dimmer-trim'].settings['high-trim'].value = valToSet;
                            updateUIComponents = true;
                        }
                    break;
                    case 'AH Grace Time':
                    case 'NH Grace Time':
                        let feature = (activeData.dname === 'AH Grace Time' ? 'ah-grace' : 'nh-grace');
                        valToSet = parseFloat(activeData.cval);
                        if(this.location !== false && this.location.featureSettings[feature].settings['grace-period'].value !== valToSet){
                            this.location.featureSettings[feature].settings['grace-period'].value = valToSet;
                            updateUIComponents = true;
                        }
                        break;
                    case 'PWM':
                    case 'Channel 1':
                    case 'DAC':
                        this.level = parseFloat(activeData.cval, 10);
                        if(liveData === false){
                            if(Locations.calculateLightLevel(this.location)){
                                updateUIComponents = true;
                            }
                        }
                    break;
                    case 'LinkID':
                        if(App.Site.scenes.hasOwnProperty(activeData.cval)){
                            sceneName = App.Site.scenes[activeData.cval].name;
                            sceneName = (sceneName === 'NULL') ? ('Scene ' + (activeData.cval - 255)) : sceneName;
                        }
                    break;
                    case 'RSSI':
                        this.talking = true;
                        this.rssi = parseInt(activeData.cval, 10, 10);
                        const deviceRssi = document.getElementById('rssi-'+this.ID);
                        const addDeviceRssi = document.getElementById('add-device-rssi-'+this.ID);

                        if(deviceRssi !== null){
                            deviceRssi.className = 'device-rssi '+this.signalStrengthIcon;
                        }

                        if(addDeviceRssi !== null){
                            addDeviceRssi.className = 'device-rssi '+this.signalStrengthIcon;
                        }
                    break;
                    case 'Load Shed':
                        if(parseInt(activeData.cval) === 1){
                            this.location.loadShedOn = true;
                        }
                    break;
                    case 'Sensor ID':
                        this.sensorID = parseFloat(activeData.cval, 10);
                    break;
                    default:
                        break;
                }
            }
        }
        //check these state values so that we don't re-render the ui a thousand times during live-data parse
        if(updateUIComponents && this.location !== false && liveData === false){
            this.location.updateUIComponents();
            //In case we set unsaved before we got data, we refresh it to get the badge
            if(this.unsaved && this.isTalking() && App.hasOwnProperty('NavIcons')){
                this.setSyncIcon(true, true);
            }
        }
    }
    get isSyncable(){
        if(this.type === 'Tny'){
            return false
        }else{
            return true;
        }
    }
    changeRole(newRole){
        let location = this.location;
        this.setProps({role: newRole}).then(() => {
            if(location !== false){
                location.masterML = this;
                if(newRole === 'master_motion' || newRole === 'master_motion_dll'){
                    location.setNonMasterSensorRoles(true, false);
                }
                if(newRole === 'master_motion_dll' || newRole === 'master_dll' || newRole === 'master_dll_child_motion'){
                    location.setNonMasterSensorRoles(false, true);
                }
            }
        });
    }
    parsePhdOutputData(data, liveData){
        let activeData;
        let output;
        let dname;
        //parse data per channel of phd and assign data accordingly to channels assoc location
        for(let id in data){
            activeData = data[id];
            if('dname' in activeData){
                dname = activeData.dname.toLowerCase();
                if(dname.indexOf('ch') >= 0 || dname.indexOf('zn') >=0 || dname.indexOf('state') >= 0){
                    output = (dname.indexOf('ch') >=0 ? parseFloat(dname.substr(-2).trim()).toString() : dname.substr(-1));
                    if(this.outputs.hasOwnProperty(output) && this.outputs[output].location_id !== null && this.outputs[output].location_id !== 0){
                        let cval = parseFloat(activeData.cval);
                        if(dname.indexOf('st') >= 0 && !(dname.indexOf('resistor') >= 0)){
                            if(App.Site.locations.hasOwnProperty(this.outputs[output].location_id)){
                                App.Site.locations[this.outputs[output].location_id].lightLevel = parseInt(cval);
                            }
                        }else if(dname.indexOf('Max Out') >= 0){
                            if(this.location !== false && this.location.featureSettings['dimmer-trim'].settings['high-trim'].value !== cval){
                                this.location.featureSettings['dimmer-trim'].settings['high-trim'].value = cval;
                            }
                        }

                    }
                }
            }
        }

    }

    get isModeTest() {
        const typeModes = ['Occ Mode', 'Occ Mode Enable'];
        return Object.keys(this.dataTable).some(idx => {
            const dataName = this.dataTable[idx];
            return typeModes.includes(dataName.dname) && dataName.cval === 1;
        });
    }

    async getDeviceLinkTables() {
        await Scenes.getSceneTable({ ip_address: [this.ip_address] })
    }

    async patchResourceV3 (apiv3, params, payload) {
        return await apiv3.devices.updateDevice(params, payload);
    }

    removeDeletedRows() {
        for (let index in this.internalActions) {
            if (this.internalActions[index].name === 'deleted') {
                delete this.internalActions[index];
            }
        }
        for (let index in this.externalActions) {
            if (this.externalActions[index].name === 'deleted') {
                delete this.externalActions[index];
            }
        }
    }
}

export default Device;
