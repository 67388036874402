import { reverseMappedChannels, reverseChansZonesFadeLevel, reverseSetLoadShed, reverseHarvestDaylight, reverseFadeLevel, reverseCalculateVar1DelayScene } from './CalculatorFuntions';

const reverseCalculator = (action, var1) => {
    switch (action) {
        case 'Disable Mapped Channels (mask) ':
        case 'Enable Mapped Channels (mask) ':
            return reverseMappedChannels(var1);
        case 'Set Auto Level (mask,%,fade)':
        case 'Set Manual Level (mask,%,fade)':
            return reverseChansZonesFadeLevel(var1);
        case 'Set Load Shed (mask,0-1)':
            return reverseSetLoadShed(var1);
        case 'Cloud Dim (mask)':
        case 'Halt Fade (mask)':
        case 'Harvest Daylight (mask)':
            return reverseHarvestDaylight(var1);
        case 'Set Auto Duty(%,Fade)':
        case 'Set Manual Duty(%,Fade)':
            return reverseFadeLevel(var1);
        case 'Set Auto Duty(%,Fad)':
            return reverseFadeLevel(var1);
        case 'Delay Scene ((mcast<<63)|(ms<< 16)|(linkID))':
            return reverseCalculateVar1DelayScene(var1);
        default:
            return {
                level: null,
                fade: null,
                zones: [],
                channels: []
            };
    }
}

export const reverseDefault = (var1) => {
    return var1;
}

export default reverseCalculator;