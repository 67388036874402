import React, { Component, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';


class ErrorHandler extends Component {
    state = { isError: false }

    // eslint-disable-next-line react/display-name
    AdapterLink = forwardRef((props, ref) => <Link innerRef={ref} {...props} />)

    componentDidCatch() {
      this.setState({ isError: true })
    }

    renderErrorPage = () => {
      return(
        <div className="app flex-row align-items-center not-found-container">
          <Grid container spacing={3}>
            <Grid className="icon-container" item xs={12} sm={5}>
              <span><FontAwesomeIcon icon={['fal', 'city']} /></span>
            </Grid>
            <Grid className="copy-container" item xs={12} sm={7}>
                <h1 className="float-left display-3 mr-4">Unhandled Error</h1>
                <h4 className="pt-3">Oops! You can&#39;t do that.</h4>
                <p className="text-muted float-left">
                    This error has been reported to Amatis.
                    Try again or contact your site administrator.
                </p>
                <div className="button-container">
                    <Button component={this.AdapterLink} to="/" disableRipple variant="contained" color="primary">
                        Go To Safety
                    </Button>
                </div>
            </Grid>
          </Grid>
        </div>
      )
    }

    render() {
        const { isError } = this.state;
        const { children } = this.props;
        return isError ? this.renderErrorPage() : children
    }
}

export default ErrorHandler;
