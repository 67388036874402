import {
    listSitesRequest,
    listSitesSuccess,
    listSitesFailure,
    listSitesFulfill,
    metaSearchSites,
    clearSites,
    onFiltersChange,
    onSortingChange,
    onCurrentPageChange,
    onPageSizeChange,
} from './actions';

const initialState = {
    error: null,
    items: null,
    loading: true,
    meta: {
        search: '',
    },
    table: {
        sorting: [],
        filters: [],
        currentPage: 0,
        pageSize: 10,
        pageSizes: [5, 10, 15, 0],
    }
};

const handleRequest = state => Object.assign({}, state, { loading: true, error: null });
const handleSuccess = (state, { payload }) => Object.assign({}, state, { items: payload });
const handleFailure = (state, { payload }) => Object.assign({}, state, { error: payload });
const handleFulfill = state => Object.assign({}, state, { loading: false });
const handleSearch = (state, { payload }) => Object.assign({}, state, { meta: { search: payload } });
const handleClear = () => Object.assign({}, initialState);

const handleSorting = (state, { payload }) =>
    Object.assign({}, state, { state, table: {
            ...state.table,
            sorting: payload
        }
    });

const handleFilters = (state, { payload }) =>
    Object.assign({}, state, { state, table: {
            ...state.table,
            filters: payload
        }
    });

const handleCurrentPage = (state, { payload }) =>
    Object.assign({}, state, { state, table: {
            ...state.table,
            currentPage: payload
        }
    });

const handlePageSize = (state, { payload }) =>
    Object.assign({}, state, { state, table: {
            ...state.table,
            pageSize: payload
        }
    });

const handlers = {
    [listSitesRequest.toString()]: handleRequest,
    [listSitesSuccess.toString()]: handleSuccess,
    [listSitesFailure.toString()]: handleFailure,
    [listSitesFulfill.toString()]: handleFulfill,
    [metaSearchSites.toString()]: handleSearch,
    [onSortingChange.toString()]: handleSorting,
    [onFiltersChange.toString()]: handleFilters,
    [onCurrentPageChange.toString()]: handleCurrentPage,
    [onPageSizeChange.toString()]: handlePageSize,
    [clearSites.toString()]: handleClear,
};

const reducer = (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
