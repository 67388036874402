import { createAction } from "redux-actions";
import Devices from 'api/amatis/site/devices/services/devices';
import SDK from "api/sdk";
import SDKDevices from 'api/sdkDevices';

export const SEARCH_DEVICES_REQUEST = "SEARCH_DEVICES_REQUEST";
export const SEARCH_DEVICES_SUCCESS = "SEARCH_DEVICES_SUCCESS";
export const SEARCH_DEVICES_FAILURE = "SEARCH_DEVICES_FAILURE";
export const SEARCH_DEVICES_FULFILL = "SEARCH_DEVICES_FULFILL";

export const LIST_DEVICES_REQUEST = "LIST_DEVICES_REQUEST";
export const LIST_DEVICES_SUCCESS = "LIST_DEVICES_SUCCESS";
export const LIST_DEVICES_FAILURE = "LIST_DEVICES_FAILURE";
export const LIST_DEVICES_FULFILL = "LIST_DEVICES_FULFILL";

export const DEVICES_SORTING = "DEVICES_SORTING";
export const DEVICES_GROUPING = "DEVICES_GROUPING";
export const DEVICES_SELECTION = "DEVICES_SELECTION";
export const DEVICES_FILTERS = "DEVICES_FILTERS";
export const DEVICES_EXPANDED_GROUPS = "DEVICES_EXPANDED_GROUPS";
export const DEVICES_EXPANDED_ROW_IDS = "DEVICES_EXPANDED_ROW_IDS";
export const DEVICES_ADD_READOUT = "DEVICES_ADD_READOUT";

export const DEVICES_ADD_INTERNAL_ACTION = "DEVICES_ADD_INTERNAL_ACTION";
export const DEVICES_ADD_EXTERNAL_ACTION = "DEVICES_ADD_EXTERNAL_ACTION";

export const DEVICE_SAVE_BY_POINT = "DEVICE_SAVE_BY_POINT";
export const DEVICE_UPDATE_BY_POINT = "DEVICE_UPDATE_BY_POINT";

export const DEVICE_UPDATE = "DEVICE_UPDATE";
export const DEVICE_IDENTIFY = "DEVICE_IDENTIFY";
export const DEVICE_SW_INFO = "DEVICE_SW_INFO";
export const DEVICE_DATA = "DEVICE_DATA";
export const DEVICE_REBOOT = "DEVICE_REBOOT";
export const DEVICE_TABLE_LIST = "DEVICE_TABLE_LIST";
export const DEVICE_SAVE_TABLE_LIST = "DEVICE_SAVE_TABLE_LIST";
export const DEVICE_FUNCTION_LIST = "DEVICE_FUNCTION_LIST";
export const DEVICE_OTA = "DEVICE_OTA";
export const CLEAR_DEVICES = "CLEAR_DEVICES";
export const DEVICE_DELETE = "DEVICE_DELETE";

export const DEVICE_MODE_EDIT = 'DEVICE_MODE_EDIT';
export const DEVICE_MODE_ADD = 'DEVICE_MODE_ADD';
export const DEVICE_OPEN_MODAL = 'DEVICE_OPEN_MODAL';
export const DEVICE_ADD_DEVICE = 'DEVICE_ADD_DEVICE';
export const SET_CHECKED_DEVICES = 'SET_CHECKED_DEVICES';
export const SET_ADDING_DEVICES = 'SET_ADDING_DEVICES';
export const addDeviceToItems = createAction(DEVICE_ADD_DEVICE);
export const listDevicesRequest = createAction(LIST_DEVICES_REQUEST);
export const listDevicesSuccess = createAction(LIST_DEVICES_SUCCESS);
export const listDevicesFailure = createAction(LIST_DEVICES_FAILURE);
export const listDevicesFulfill = createAction(LIST_DEVICES_FULFILL);

export const searchDevicesRequest = createAction(SEARCH_DEVICES_REQUEST);
export const searchDevicesSuccess = createAction(SEARCH_DEVICES_SUCCESS);
export const searchDevicesFailure = createAction(SEARCH_DEVICES_FAILURE);
export const searchDevicesFulfill = createAction(SEARCH_DEVICES_FULFILL);

export const onDeviceSortingChange = createAction(DEVICES_SORTING);
export const onDeviceGroupingChange = createAction(DEVICES_GROUPING);
export const onDeviceSelectionChange = createAction(DEVICES_SELECTION);
export const onDeviceFiltersChange = createAction(DEVICES_FILTERS);
export const onDeviceExpandedGroupsChange = createAction(
    DEVICES_EXPANDED_GROUPS
);
export const onDeviceExpandedRowIdsChange = createAction(
    DEVICES_EXPANDED_ROW_IDS
);
export const addDevicesReadout = createAction(DEVICES_ADD_READOUT);

export const addDevicesInternalAction = createAction(
    DEVICES_ADD_INTERNAL_ACTION
);
export const addDevicesExternalAction = createAction(
    DEVICES_ADD_EXTERNAL_ACTION
);

export const saveDeviceByPoint = createAction(DEVICE_SAVE_BY_POINT);
export const saveUpdateByPoint = createAction(DEVICE_UPDATE_BY_POINT);
export const setCheckedDevices = createAction(SET_CHECKED_DEVICES);
export const setAddingDevices = createAction(SET_ADDING_DEVICES);
export const deviceUpdate = createAction(DEVICE_UPDATE);
export const deviceIdentify = createAction(DEVICE_IDENTIFY);
export const deviceSwInfo = createAction(DEVICE_SW_INFO);
export const deviceData = createAction(DEVICE_DATA);
export const deviceReboot = createAction(DEVICE_REBOOT);
export const deviceTableList = createAction(DEVICE_TABLE_LIST);
export const deviceSaveTableList = createAction(DEVICE_SAVE_TABLE_LIST);
export const deviceFunctionList = createAction(DEVICE_FUNCTION_LIST);
export const deviceOTA = createAction(DEVICE_OTA);
export const clearDevices = createAction(CLEAR_DEVICES);
export const deviceDelete = createAction(DEVICE_DELETE);

export const deviceModeEdit = createAction(DEVICE_MODE_EDIT);
export const deviceModeAdd = createAction(DEVICE_MODE_ADD);
export const deviceModalOpen = createAction(DEVICE_OPEN_MODAL);

// table actions
export const onDeviceFilters = columns => dispatch => {
    columns.forEach(column => {
        if(column.columnName === 'statusIcon') {
            const value = column.value.toLowerCase();
            if (value === 'g') {
                column.value = '💚';
            }
            if (value === 'r') {
                column.value = '💔';
            }
            if (value === 'y') {
                column.value = '💛';
            }
            if (value === 'b') {
                column.value = '🖤';
            }
        }
    });
    dispatch(onDeviceFiltersChange(columns));
};

//error readout
export const addErrorReadOut = (id, parent, message) => dispatch => {
    return dispatch(
        addDevicesReadout({
            parent,
            message,
            idDevice: id,
            error: true
        })
    );
};

// fetchs
export const fetchIdentifyDevice = (device, parent = 1) => dispatch => {
    const id = window.localStorage.getItem("amatis_site_id");
    const params = { SITEID: id };
    dispatch(
        addDevicesReadout({
            parent,
            idDevice: device.id,
            message: `Will ask ${device.nameWithLastFour} to wink for 15s...`
        })
    );
    params.IP = device.ip_address;
    return SDK.init()
        .then(api => api.devices.identifyDevice(params))
        .then(result => {
            if (result && result.data && typeof result.data === 'string') {
                dispatch(
                    addDevicesReadout({
                        parent,
                        idDevice: device.id,
                        check: true,
                        icon: "✅",
                        message: `${
                            device.nameWithLastFour
                        } should be winking! Response:[${result.body.data[0]}]`
                    })
                );
            }
            if (result.body && result.body.errors && result.body.errors.length > 0) {
                dispatch(addErrorReadOut(device.id, parent, result.body.errors[0]));
            }
        })
        .catch(error => dispatch(addErrorReadOut(device.id, parent, error)));
};

export const fetchSwInfoDevice = (device, parent = 1) => dispatch => {
    const id = window.localStorage.getItem("amatis_site_id");
    const params = { SITEID: id };
    dispatch(
        addDevicesReadout({
            parent,
            idDevice: device.id,
            message: `Getting SWinfo from ${device.nameWithLastFour}...`
        })
    );
    params.IP = device.ip_address;
    return SDK.init()
        .then(api => api.devices.swInfoDevice(params))
        .then(result => {
            if (result && result.data && typeof result.data === 'string') {
                dispatch(
                    addDevicesReadout({
                        parent,
                        idDevice: device.id,
                        grid: true,
                        data: result.body.data,
                        title: `SWinfo for ${device.nameWithLastFour}`
                    })
                );
            }
            if (result.body && result.body.errors && result.body.errors.length > 0) {
                dispatch(addErrorReadOut(device.id, parent, result.body.errors[0]));
            }
        })
        .catch(error => dispatch(addErrorReadOut(device.id, parent, error)));
};

export const fetchDataDevice = (device, query, parent = 1) => dispatch => {
    const id = window.localStorage.getItem("amatis_site_id");
    const params = { SITEID: id };
    dispatch(
        addDevicesReadout({
            parent,
            idDevice: device.id,
            message: `Getting Data from ${device.nameWithLastFour}...`
        })
    );
    params.IP = device.ip_address;
    Object.assign({}, params, query);
    return SDK.init()
        .then(api => api.devices.dataDevice(params))
        .then(result => {
            if (result && result.data && typeof result.data === 'string') {
                dispatch(
                    addDevicesReadout({
                        parent,
                        idDevice: device.id,
                        grid: true,
                        data: result.body.data,
                        title: `Live Data Dictionary for ${device.nameWithLastFour}`
                    })
                );
            }
            if (result.body && result.body.errors && result.body.errors.length > 0) {
                dispatch(addErrorReadOut(device.id, parent, result.body.errors[0]));
            }
        })
        .catch(error => dispatch(addErrorReadOut(device.id, parent, error)));
};

export const fetchRebootDevice = (device, parent = 1) => dispatch => {
    const site_id = window.localStorage.getItem("amatis_site_id");
    const params = { device_id: device.ip_address, site_id };

    dispatch(
        addDevicesReadout({
            parent,
            idDevice: device.id,
            message: `Will ask ${device.nameWithLastFour} to do a soft reboot...`
        })
    );
    params.IP = device.ip_address;

    return SDKDevices.init()
        .then(api => api.devices.rebootDevice({}, params))
        .then(({body: data}) => {
            if (data && data.data && typeof data.data[0] === 'string' ) {
                dispatch(
                    addDevicesReadout({
                        parent,
                        idDevice: device.id,
                        check: true,
                        icon: "✅",
                        message: `${
                            device.nameWithLastFour
                        } rebooted! Response:[${data.data[0]}]`
                    })
                );
            }
            if (data && data.errors && data.errors.length > 0) {
                dispatch(addErrorReadOut(device.id, parent, data.errors[0]));
            }
        })
        .catch(error => dispatch(addErrorReadOut(device.id, parent, error)));
};

export const fetchTableListDevice = (device, query, parent = 1) => dispatch => {
    const id = window.localStorage.getItem("amatis_site_id");
    const params = { SITEID: id };
    dispatch(
        addDevicesReadout({
            // Getting the Action Function Table from {activeDevice.nameWithLastFour}, and putting it into the cloud...
            parent,
            idDevice: device.id,
            message: `Getting the Action Function Table from ${device.nameWithLastFour}...`
        })
    );
    params.IP = device.ip_address;
    Object.assign({}, params, query);
    return SDK.init()
        .then(api => api.devices.tableListDevice(params))
        .then(result => {
            if (result && result.data && typeof result.data === 'string') {
                dispatch(
                    addDevicesReadout({
                        parent,
                        idDevice: device.id,
                        grid: true,
                        data: result.body.data,
                        title: `AFT (/tablelist) for ${device.nameWithLastFour}`
                    })
                );
            }
            if (result.body && result.body.errors && result.body.errors.length > 0) {
                dispatch(addErrorReadOut(device.id, parent, result.body.errors[0]));
            }
        })
        .catch(error => dispatch(addErrorReadOut(device.id, parent, error)));
};

export const fetchSaveTableListDevice = (device, parent = 1) => dispatch => {
    const id = window.localStorage.getItem("amatis_site_id");
    const params = { SITEID: id };
    dispatch(
        addDevicesReadout({
            parent,
            idDevice: device.id,
            message: `Syncing the cloud Action Function Table to ${device.nameWithLastFour}...`
        })
    );
    params.IP = device.ip_address;
    return SDK.init()
        .then(api => api.devices.saveTableListDevice(params))
        .then(result => {
            if (result && result.data && typeof result.data === 'string') {
                dispatch(
                    addDevicesReadout({
                        parent,
                        idDevice: device.id,
                        grid: true,
                        data: result.body.data,
                        title: `Response from sync for ${device.nameWithLastFour}`
                    })
                );
            }
            if (result.body && result.body.errors && result.body.errors.length > 0) {
                dispatch(addErrorReadOut(device.id, parent, result.body.errors[0]));
            }
        })
        .catch(error => dispatch(addErrorReadOut(device.id, parent, error)));
};

export const fetchFunctionListDevice = (
    device,
    query,
    parent = 1
) => dispatch => {
    const id = window.localStorage.getItem("amatis_site_id");
    const params = { SITEID: id };
    dispatch(
        addDevicesReadout({
            parent,
            message: `Getting Skills Table from ${device.nameWithLastFour}...`
        })
    );
    params.IP = device.ip_address;
    Object.assign({}, params, query);
    return SDK.init()
        .then(api => api.devices.functionListDevice(params))
        .then(result => {
            if (result && result.data && typeof result.data === 'string') {
                dispatch(
                    addDevicesReadout({
                        parent,
                        idDevice: device.id,
                        grid: true,
                        data: result.body.data,
                        title: `Response from sync for ${device.nameWithLastFour}`
                    })
                );
            }
            if (result.body && result.body.errors && result.body.errors.length > 0) {
                dispatch(addErrorReadOut(device.id, parent, result.body.errors[0]));
            }
        })
        .catch(error => dispatch(addErrorReadOut(device.id, parent, error)));
};

// Actions
export const listDevices = () => dispatch => {
    const id = window.localStorage.getItem("amatis_site_id");
    const params = { SITEID: id };
    const optionSDK = { serverVariables: { baseVersion: "v2" } };
    dispatch(listDevicesRequest());
    return SDK.init()
        .then(api => api.devices.getDevicesBySite(params, optionSDK))
        .then(({ body: { data } }) => {
            dispatch(listDevicesSuccess(data));
        })
        .catch(error => {
            dispatch(listDevicesFailure(error));
        })
        .finally(() => {
            dispatch(listDevicesFulfill());
        });
};

export const saveDeviceNameByPoint = (value, device, row) => dispatch => {
    const id = window.localStorage.getItem("amatis_site_id");
    const params = { SITEID: id, IP: device.ip_address, SETPOINT_NAME: row.dname.replace(" ", "_") };
    const requestBody = { value };
    dispatch(saveDeviceByPoint());
    return SDK.init()
        .then(api => api.devices.saveDeviceNameByPoint(params, { requestBody }))
        .then(() => dispatch(saveUpdateByPoint()));
};

export const onDeviceIdentify = id => (dispatch, getState) => {
    dispatch(deviceIdentify());
    const orm = require("store/orm").default;
    const state = getState();
    const session = orm.session(state.orm);

    if (id) {
        const device = session.Device.withId(id);
        dispatch(fetchIdentifyDevice(device, 0));
    } else {
        const selections = state.devices.meta.selection;
        selections.forEach(select => {
            const device = session.Device.withId(select);
            dispatch(fetchIdentifyDevice(device));
        });
    }
};

export const onDeviceSwInfo = id => (dispatch, getState) => {
    dispatch(deviceSwInfo());
    const orm = require("store/orm").default;
    const state = getState();
    const session = orm.session(state.orm);

    if (id) {
        const device = session.Device.withId(id);
        dispatch(fetchSwInfoDevice(device, 0));
    } else {
        const selections = state.devices.meta.selection;
        selections.forEach(select => {
            const device = session.Device.withId(select);
            dispatch(fetchSwInfoDevice(device));
        });
    }
};

export const onDeviceData = (id, query = {}) => (dispatch, getState) => {
    dispatch(deviceData());
    const orm = require("store/orm").default;
    const state = getState();
    const session = orm.session(state.orm);

    if (id) {
        const device = session.Device.withId(id);
        dispatch(fetchDataDevice(device, query, 0));
    } else {
        const selections = state.devices.meta.selection;
        selections.forEach(select => {
            const device = session.Device.withId(select);
            dispatch(fetchDataDevice(device, query));
        });
    }
};

export const onDeviceReboot = id => (dispatch, getState) => {
    dispatch(deviceReboot());
    const orm = require("store/orm").default;
    const state = getState();
    const session = orm.session(state.orm);

    if (id) {
        const device = session.Device.withId(id);
        dispatch(fetchRebootDevice(device, 0));
    } else {
        const selections = state.devices.meta.selection;
        selections.forEach(select => {
            const device = session.Device.withId(select);
            dispatch(fetchRebootDevice(device));
        });
    }
};

export const onDeviceTableList = (id, query = {}) => (dispatch, getState) => {
    dispatch(deviceTableList());
    const orm = require("store/orm").default;
    const state = getState();
    const session = orm.session(state.orm);

    if (id) {
        const device = session.Device.withId(id);
        dispatch(fetchTableListDevice(device, query, 0));
    } else {
        const selections = state.devices.meta.selection;
        selections.forEach(select => {
            const device = session.Device.withId(select);
            dispatch(fetchTableListDevice(device, query));
        });
    }
};

export const onDeviceSaveTableList = id => (dispatch, getState) => {
    dispatch(deviceSaveTableList());
    const orm = require("store/orm").default;
    const state = getState();
    const session = orm.session(state.orm);

    if (id) {
        const device = session.Device.withId(id);
        dispatch(fetchSaveTableListDevice(device, 0));
    } else {
        const selections = state.devices.meta.selection;
        selections.forEach(select => {
            const device = session.Device.withId(select);
            dispatch(fetchSaveTableListDevice(device));
        });
    }
};

export const onDeviceFunctionList = (id, query = {}) => (
    dispatch,
    getState
) => {
    dispatch(deviceFunctionList());
    const orm = require("store/orm").default;
    const state = getState();
    const session = orm.session(state.orm);

    if (id) {
        const device = session.Device.withId(id);
        dispatch(fetchFunctionListDevice(device, query, 0));
    } else {
        const selections = state.devices.meta.selection;
        selections.forEach(select => {
            const device = session.Device.withId(select);
            dispatch(fetchFunctionListDevice(device, query));
        });
    }
};

export const onDeviceOTA = id => (dispatch, getState) => {
    dispatch(deviceOTA());
    const orm = require("store/orm").default;
    const state = getState();
    const session = orm.session(state.orm);

    if (id) {
        const device = session.Device.withId(id);
        dispatch(
            addDevicesReadout({
                parent: 0,
                idDevice: device.id,
                link: true,
                href: `https://dash.amatiscontrols.com/OTA/OTA.php?ip=${device.ip_address}`,
                message: `Click here to OTA ${device.nameWithLastFour}...`
            })
        );
    } else {
        const selections = state.devices.meta.selection;
        selections.forEach(select => {
            const device = session.Device.withId(select);
            dispatch(
                addDevicesReadout({
                    parent: 1,
                    idDevice: device.id,
                    link: true,
                    href: `https://dash.amatiscontrols.com/OTA/OTA.php?ip=${device.ip_address}`,
                    message: `Click here to OTA ${device.nameWithLastFour}...`
                })
            );
        });
    }
};

export const onDeviceDelete = devicesToDelete => async (dispatch) => {
    var devices = Devices.list.filter((device) => {
        return devicesToDelete.find(function(deviceToDelete) {
            return device.ip_address === deviceToDelete.ip
        })
    })
    try {
        for await ( let deviceItem of devices){
            Devices.destroy(deviceItem);
        }
    } catch (error) {
        console.log('ERROR ', error);
    } finally {
        devices[0].updateUIComponents();
    }
}
