import React from 'react';

export const generateSuccessMessage = () => {
    return (
        <>
            <li>All Manual scene edits have been updated</li>
            <li>All features are being applied based on the scenes in this location</li><br />
            <span>Occupancy scenes:</span><br />
            <li>PIR values for motion sensors are correct</li>
            <li>All actions include all devices in the device list</li>
            <li>Action functions are correct</li>
            <li>The trigger is the master device</li>
            <li>Grace period scenes match the occupancy scenes</li><br />
            <span>Switch scenes:</span><br />
            <li>All actions include all devices in the device list</li>
            <li>Action functions are correct</li>
            <li>The trigger is the switch, if found in the device list</li><br />
            <span>Load Shed scenes:</span><br />
            <li>Does not have a trigger</li><br />
            <span>Direct Control scene:</span><br />
            <li>Does not have a trigger</li>
        </>
    )
}
