import { createAction } from 'redux-actions';
import SDK from 'api/sdk';
/* import App from 'app/app.js'; */

export const LIST_LOCATIONS_REQUEST = 'LIST_LOCATIONS_REQUEST';
export const LIST_LOCATIONS_SUCCESS = 'LIST_LOCATIONS_SUCCESS';
export const LIST_LOCATIONS_FAILURE = 'LIST_LOCATIONS_FAILURE';
export const LIST_LOCATIONS_FULFILL = 'LIST_LOCATIONS_FULFILL';
export const LOCATION_TOGGLE_OPENING = 'LOCATION_TOGGLE_OPENING';
export const CLEAR_LOCATIONS = 'CLEAR_LOCATIONS';
export const LOCATIONS_POSITION_SCROLL = 'LOCATIONS_POSITION_SCROLL';
export const LOCATION_SET_OPENING = 'LOCATION_SET_OPENING';
export const SEARCH_LOCATIONS_REQUEST = 'SEARCH_LOCATIONS_REQUEST';
export const SEARCH_LOCATIONS_SUCCESS = 'SEARCH_LOCATIONS_SUCCESS';
export const SEARCH_LOCATIONS_FAILURE = 'SEARCH_LOCATIONS_FAILURE';
export const SEARCH_LOCATIONS_FULFILL = 'SEARCH_LOCATIONS_FULFILL';
export const SET_LOCATION_MODAL = 'SET_LOCATION_MODAL';
export const SET_TEST_MODE = 'SET_TEST_MODE';
export const SET_TEST_MODE_ACTIVE = 'SET_TEST_MODE_ACTIVE';
export const SET_LOCATION_SETTING_SAVE = 'SET_LOCATION_SETTING_SAVE';

export const HISTORY_BY_LOCATION_INFLUX_REQUEST = 'HISTORY_BY_LOCATION_INFLUX_REQUEST';
export const HISTORY_BY_LOCATION_INFLUX_SUCCESS = 'HISTORY_BY_LOCATION_INFLUX_SUCCESS';
export const HISTORY_BY_LOCATION_INFLUX_FAILURE = 'HISTORY_BY_LOCATION_INFLUX_FAILURE';
export const HISTORY_BY_LOCATION_INFLUX_FULFILL = 'HISTORY_BY_LOCATION_INFLUX_FULFILL';

export const listLocationsRequest = createAction(LIST_LOCATIONS_REQUEST);
export const listLocationsSuccess = createAction(LIST_LOCATIONS_SUCCESS);
export const listLocationsFailure = createAction(LIST_LOCATIONS_FAILURE);
export const listLocationsFulfill = createAction(LIST_LOCATIONS_FULFILL);
export const locationToggleOpening = createAction(LOCATION_TOGGLE_OPENING);
export const locationsPositionScroll = createAction(LOCATIONS_POSITION_SCROLL);
export const clearLocations = createAction(CLEAR_LOCATIONS);
export const locationSetOpening = createAction(LOCATION_SET_OPENING);

export const setLocationModal = createAction(SET_LOCATION_MODAL);
export const setTestMode = createAction(SET_TEST_MODE);
export const setIsTestModeActive = createAction(SET_TEST_MODE_ACTIVE);
export const setLocationSettingsSave = createAction(SET_LOCATION_SETTING_SAVE);

export const historyByLocationInfluxRequest = createAction(HISTORY_BY_LOCATION_INFLUX_REQUEST);
export const historyByLocationInfluxSuccess = createAction(HISTORY_BY_LOCATION_INFLUX_SUCCESS);
export const historyByLocationInfluxFailure = createAction(HISTORY_BY_LOCATION_INFLUX_FAILURE);
export const historyByLocationInfluxFulfill = createAction(HISTORY_BY_LOCATION_INFLUX_FULFILL);

export const listLocations = () => dispatch => {
    const id = window.localStorage.getItem('amatis_site_id');
    const params = { SITEID: id };
    dispatch(listLocationsRequest());
    return SDK.init().then(api => api.devices.getDevicesBySite(params))
        .then(({ body: { data }}) => {
            dispatch(listLocationsSuccess(data));
        })
        .catch(error => {
            dispatch(listLocationsFailure(error));
        })
        .finally(() => {
            dispatch(listLocationsFulfill());
        });
};

export const openingLocationById = locationId => (dispatch, getState) => {
    if (locationId) {
        const { locations: { items } } = getState();
        const ids = [];
        const locationIndex = items.findIndex(item => item.id === locationId);
        let location = locationIndex >= 0 ? items[locationIndex] : null;

        /* eslint-disable no-loop-func */
        while(location && location.parent_id !== 0){
            ids.push(location.parent_id);
            const parentIndex = items.findIndex(item => item.id === location.parent_id);
            location = locationIndex >= 0 ? items[parentIndex] : null;
        }
        const openings = ids.filter(id => id !== locationId);
        dispatch(locationSetOpening(openings));
    }
}

export const historyByLocationInflux = (params) => dispatch => {
    dispatch(historyByLocationInfluxRequest());
    return SDK.init().then(api => api.influx.getDataByLocationInflux(params, { serverVariables: { baseVersion: 'v3' } }))
        .then(({ body: { data }}) => {
            dispatch(historyByLocationInfluxSuccess(data));
        })
        .catch(error => {
            dispatch(historyByLocationInfluxFailure(error));
        })
        .finally(() => {
            dispatch(historyByLocationInfluxFulfill());
        });
};
