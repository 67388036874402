import $ from 'jquery';
import App from 'app/app';
import Communication from 'api/amatis/utilities/communications';
import { Scenes } from 'api/amatis/site/scenes/services/scenes';
import Devices from 'api/amatis/site/devices/services/devices';
import Location from 'api/amatis/site/locations/Location';
import User from 'classes/users';
import { Template, trigConfig } from 'app/templates';
import { set_union, decToHex, hexToDec } from 'classes/methods';

import { buildLocationTree } from 'redux/activeSite';
import store from 'store';

import uuidv4 from 'uuid/v4';

let Locations = {
    optionsOpen: false,
    list: [],
    maxID: 0,
    showAllDevices: true,
    maxChar: 18,
    deviceStr: '',
    buttonStr: '',
    dropdownStr: '',
    dropdownState: 'All Devices',
    activeTotalPower: 0,
    changedSettings: {},
    newLocations: [],
    tempIDList: [],
    firstTimeLoadingSettingsPage: true,
    updateKnobsTO: {},
    deleted:[],
    orphans: {},
    occModeLookup:{
        0:{
            'long-name':'Normal Hours',
            'short-name':'NH',
            'tool-tip':'Occupancy Control will follow Normal Hours settings'
        },
        1:{
            'long-name':'Test Mode',
            'short-name':'Test',
            'tool-tip':'Occupancy Test Mode. Will reset after 15 minutes...'
        },
        2:{
            'long-name':'12 Hour Override',
            'short-name':'12Hr-O',
            'tool-tip':'Occupancy Control is overridden for 12 hours.'
        },
        3:{
            'long-name':'After Hours',
            'short-name':'AH',
            'tool-tip':'Occupancy Control will follow After Hours settings'
        }
    },

    featureSettingDefaults: {
        'dimmer-trim':{
            name:'dimmer-trim',
            alias:'Dimmer Trim',
            secondary:'Set the maximum and minimum light level in this location',
            icon:'lightbulb',
            active: false,
            applied: true,
            display: true,
            settings:{
                'high-trim':{
                    name: 'High End Trim',
                    type:'dimmer',
                    dname:'Max Out',
                    value: 100,
                    unit: '%',
                    icon: 'arrow-to-top',
                    step: 1,
                    min: 0,
                    max: 100,
                    limit:[{
                        mustBe:'greaterThan',
                        feature:'dimmer-trim',
                        setting:'low-trim'
                    }],
                },
                'low-trim':{
                    name: 'Low End Trim',
                    type:'dimmer',
                    dname:'Min Out',
                    value: 0,
                    unit: '%',
                    icon: 'arrow-to-bottom',
                    step: 1,
                    min: 0,
                    max: 100,
                    limit:[{
                        mustBe:'lessThan',
                        feature:'dimmer-trim',
                        setting:'high-trim'
                    }],
                }
            }
        },
        'day-occ': {
            name: 'day-occ',
            alias: 'Normal Hours Occupancy',
            secondary: 'Set default mode for occupancy',
            icon: 'clouds-sun',
            active: false,
            applied:false,
            display: true,
            settings: {
                'timeout':{
                    name: 'Vacancy Timeout',
                    type:'sensor',
                    dname:'NH Timeout',
                    value: 15,
                    unit: ' minutes',
                    icon: 'clock',
                    step: 1,
                    min: 1,
                    max: 60,
                },
                'occ-light-level':{
                    name: 'Occupied Light-Level',
                    value: 100,
                    sceneName:'occupied',
                    unit: '%',
                    icon: 'walking',
                    step: 5,
                    min: 0,
                    max: 100,
                    limit:[
                        {mustBe:'greaterThan',
                        feature:'day-occ',
                        setting:'vac-light-level'},
                    ],
                    'fade-rate':{
                        name: 'fade',
                        value: 1,
                        unit: 's',
                        step: 1,
                        min: 0,
                        max: 30,
                    },

                },
                'vac-light-level':{
                    name: 'Vacant Light-Level',
                    value: 0,
                    sceneName:'unoccupied',
                    unit: '%',
                    icon: 'door-closed',
                    step: 5,
                    min: 0,
                    max: 100,
                    limit:[
                        {mustBe:'lessThan',
                        feature:'day-occ',
                        setting:'occ-light-level'},
                    ],
                    'fade-rate':{
                        name: 'fade',
                        value: 1,
                        unit: 's',
                        step: 1,
                        min: 0,
                        max: 30,
                    },
                }
            }
        },
        'nh-grace': {
            name: 'nh-grace',
            alias: 'Normal Hours Grace Period',
            secondary: 'Set an intermediate step between occupied and vacant light levels',
            icon: 'clouds-sun',
            active: false,
            applied:false,
            display: true,
            requires:'day-occ',
            version:'07.46.04',
            settings: {
                'grace-period':{
                    name: 'Grace Period',
                    type:'sensor',
                    dname:'NH Grace Time',
                    value: 1,
                    unit: ' minutes',
                    icon: 'clock',
                    step: 1,
                    min: 1,
                    max: 60,
                    limit:[{
                        mustBe:'lessThan',
                        feature:'day-occ',
                        setting:'timeout'
                    }],
                },
                'light-level':{
                    name: 'Light-Level',
                    value: 10,
                    sceneName:'nh_grace',
                    unit: '%',
                    icon: 'walking',
                    step: 5,
                    min: 0,
                    max: 100,
                    limit:[
                        {mustBe:'lessThan',
                        feature:'day-occ',
                        setting:'occ-light-level'},
                        {mustBe:'greaterThan',
                        feature:'day-occ',
                        setting:'vac-light-level'}
                    ],
                    'fade-rate':{
                        name: 'fade',
                        value: 1,
                        unit: 's',
                        step: 1,
                        min: 0,
                        max: 30,
                    },
                },
            }
        },
        'night-occ':{
            name: 'night-occ',
            alias: 'After Hours Occupancy',
            secondary: 'Set a second occupancy mode triggered by a schedule',
            icon: 'clouds-moon',
            active: false,
            applied:false,
            display: true,
            settings: {
                'timeout':{
                    name: 'Vacancy Timeout',
                    type:'sensor',
                    dname:'AH Timeout',
                    value: 15,
                    unit: ' minutes',
                    icon: 'clock',
                    step: 1,
                    min: 1,
                    max: 60,
                },
                'occ-light-level':{
                    name: 'Occupied Light-Level',
                    value: 75,
                    sceneName:'occupied_nighttime',
                    unit: '%',
                    icon: 'walking',
                    step: 5,
                    min: 0,
                    max: 100,
                    limit:[
                        {mustBe:'greaterThan',
                        feature:'night-occ',
                        setting:'vac-light-level'},
                    ],
                    'fade-rate':{
                        name: 'fade',
                        value: 1,
                        unit: 's',
                        step: 1,
                        min: 0,
                        max: 30,
                    },
                },
                'vac-light-level':{
                    name: 'Vacant Light-Level',
                    value: 0,
                    sceneName:'unoccupied_nighttime',
                    unit: '%',
                    icon: 'door-closed',
                    step: 5,
                    min: 0,
                    max: 100,
                    limit:[
                        {mustBe:'lessThan',
                        feature:'night-occ',
                        setting:'occ-light-level'},
                    ],
                    'fade-rate':{
                        name: 'fade',
                        value: 1,
                        unit: 's',
                        step: 1,
                        min: 0,
                        max: 30,
                    },
                }
            }
        },

        'ah-grace': {
            name: 'ah-grace',
            alias: 'After Hours Grace Period',
            secondary: 'Set an intermediate step between occupied and vacant light levels',
            icon: 'clouds-moon',
            active: false,
            applied:false,
            requires:'night-occ',
            version:'07.46.04',
            display: true,
            settings: {
                'grace-period':{
                    name: 'Grace Period',
                    type:'sensor',
                    dname:'AH Grace Time',
                    value: 1,
                    unit: ' minutes',
                    icon: 'clock',
                    limit:[{
                        mustBe:'lessThan',
                        feature:'night-occ',
                        setting:'timeout'
                    }],
                    step: 1,
                    min: 1,
                    max: 60,
                },
                'light-level':{
                    name: 'Light-Level',
                    value: 10,
                    sceneName:'ah_grace',
                    unit: '%',
                    icon: 'walking',
                    step: 5,
                    min: 0,
                    max: 100,
                    limit:[
                        {mustBe:'lessThan',
                        feature:'night-occ',
                        setting:'occ-light-level'},
                        {mustBe:'greaterThan',
                        feature:'day-occ',
                        setting:'vac-light-level'}
                    ],
                    'fade-rate':{
                        name: 'fade',
                        value: 1,
                        unit: 's',
                        step: 1,
                        min: 0,
                        max: 30,
                    },
                },
            }
        },
        'slider-control':{
            name: 'slider-control',
            alias: 'Slider Control',
            secondary: 'Adjust light levels from the slider in the app',
            icon: 'sliders-h',
            active: false,//active will be used to tell whether we are actively configuring the template/feature
            applied:false,//applied tells us it is currently configured or about to be saved
            settings: false,
            display: true,
        },
        'responsive-dimming':{
            name: 'responsive-dimming',
            alias: 'Switch Control',
            secondary: 'Adjust light level from a physical switch',
            icon: 'nintendo-switch',
            active: false,
            applied:false,
            display: true,
            settings: {
                'fade-on':{
                    name: 'Fade Rate On',
                    value: 1,
                    unit: 's',
                    icon:'hourglass-start',
                    step: 1,
                    min: 0,
                    max: 30,
                    sceneName:'on',
                },
                'fade-off':{
                    name: 'Fade Rate Off',
                    value: 1,
                    unit: 's',
                    icon:'hourglass-end',
                    step: 1,
                    min: 0,
                    max: 30,
                    sceneName:'off',
                },
            }
        },
        'dll':{
            name: 'dll',
            alias: 'DayLight Harvest',
            secondary: 'Set how lights can dim when natural light increases',
            icon: 'sun',
            dname:'DLH Targ',
            active: false,
            applied:false,
            settings: false,
            display: true,
        },
        'toggle-occupancy-mode':{
            name: 'toggle-occupancy-mode',
            alias: 'Daytime Nighttime',
            secondary: 'Change occupancy mode settings based on schedules',
            icon: 'clock',
            active: false,
            applied:false,
            settings: false,
            display: false,
        },
        'toggle-demand-response':{
                name: 'toggle-demand-response',
                alias: 'Demand Response',
                secondary: 'Create Scenes for Demand Response',
                icon: 'broadcast-tower',
                active: false,
                applied:false,
                settings: false,
                display: false,
        },
        /*{
            name: 'location_icon',
            alias: 'Location Icon',
            secondary: 'Change the icon of this location',
            icon: 'glasses',
            value: false,
            feature_settings: false
        },*/
    },
    //Keep these in alphabetical order (except default at top)
    roomType: {
        0 : {
            name: 'Default',
            icon: ['fal', 'building']
        },
        9 :  {
            name: 'Bathroom',
            icon: ['fal', 'building']
        },
        5 :  {
            name: 'Break Room',
            icon: ['fal', 'coffee']
        },
        14 :  {
            name: 'Conference room',
            icon: ['fal', 'chalkboard-teacher']
        },
        10 :  {
            name: 'Exterior',
            icon: ['fal', 'sun']
        },
        7 :  {
            name: 'Floor',
            icon: ['fal', 'building']
        },
        15 :  {
            name: 'Front Desk',
            icon: ['fal', 'phone-office']
        },
        2 :  {
            name: 'Hallway',
            icon: ['fal', 'door-open']
        },
        6 :  {
            name: 'Kitchen',
            icon: ['fal', 'utensils']
        },
        8 :  {
            name: 'Mechanical Room',
            icon: ['fal', 'tools']
        },
        1 :  {
            name: 'Office',
            icon: ['fal', 'chair-office']
        },
        4 :  {
            name: 'Open Area',
            icon: ['fal', 'users']
        },
        12 :  {
            name: 'Scheduling',
            icon: ['fal', 'calendar-alt']
        },
        11 :  {
            name: 'Supermarket aisle',
            icon: ['fal', 'shopping-cart']
        },
        3 :  {
            name: 'Stairway',
            icon: ['fal', 'shoe-prints']
        },
        13 :  {
            name: 'Troubleshooting',
            icon: ['fal', 'question-circle']
        },
        16 :  {
            name: 'Garage',
            icon: ['fal', 'garage']
        },
        17 :  {
            name: 'Storage',
            icon: ['fal', 'box-open']
        },
        18 :  {
            name: 'X-ray',
            icon: ['fal', 'x-ray']
        },
        19 :  {
            name: 'Procedure Room',
            icon: ['fal', 'procedures']
        },
        20 :  {
            name: 'Lab',
            icon: ['fas', 'flask']
        },
        21 :  {
            name: 'Exam Room',
            icon: ['fal', 'stethoscope']
        },
        22 :  {
            name: 'Pharmacy',
            icon: ['fal', 'pills']
        },
        23 :  {
            name: 'Waiting Room',
            icon: ['fal', 'couch']
        },
    },
    removeFromLocationsList(endpoint){
        for(let i = 0; i < Locations.list.length; i++){
            if(Locations.list[i].ID === endpoint){
                Locations.list = [...Locations.list];
                Locations.list.splice(i, 1);
                break;
            }
        }
    },
    /* jshint ignore:start */
    /* Ignoring this block because es7 */
    async create(locationObject=false, fromCloud=false){
        if(!Array.isArray(locationObject)){
            locationObject = [locationObject];
        }
        for(let location of locationObject){
            let newLocation = {};
            //This came through the backhaul, so we didnt create it just now
            if(fromCloud !== false){
                if(Array.isArray(location)){
                    location = location[0];
                }
                if(!App.Site.locations.hasOwnProperty(location.id)){
                    newLocation = new Location(location);
                    store.dispatch(buildLocationTree(App));
                    newLocation.updateUIComponents();
                }
            } else {
                let newLocationInfoToSend = {
                    id: uuidv4(),
                    name: 'New Location',
                    parent_id: 0,
                    room_type: 0,
                    brand_new_local: true,
                };
                //Update any params that were passed in
                for(let param in location){
                    if(newLocationInfoToSend.hasOwnProperty(param)){
                        newLocationInfoToSend[param] = location[param];
                    }
                }
                newLocation = new Location(newLocationInfoToSend);

                //We only want one to be editable, so reset all the others
                for(let location in App.Site.locations){
                    if(App.Site.locations[location].nameIsEditable){
                        App.Site.locations[location].nameIsEditable = false;
                    }
                }

                newLocation.nameIsEditable = true;

                App.Site.post(newLocationInfoToSend, false, 'locations').then(() => {
                    store.dispatch(buildLocationTree(App));
                    newLocation.updateUIComponents();
                });
            }
        }
    },
    getAllOccEnabled(location=App.ActiveLocation){
        let locations = {};
        for(let id in App.Site.locations){
            let active = App.Site.locations[id];
            if(active.hasOcc && active.id !== location.id && !(active.path.has(location.id))){
                locations[id] = active;
            }
        }
        return locations;
    },
    /* jshint ignore:end */
    emptyListHandler(){
        // On fresh sites, we want to make a default root location
        Locations.addRoot();
    },
    addRoot(){
        const rootLocName = User.siteNameFromStorage();
        Locations.create([{parent_id: 0, name: rootLocName}]);
    },
    saveNew (parent, name, isRoot=false, callback=null) {
        let newName = 'New Location';
        if (name) {
            newName = name;
        }
        let parentId;
        if(isRoot){
            parentId = parent;
        }else{
            parentId = parent.ID;
        }
        // User no longer gets a pop up. Partial Fix for Issue on chrome tablet
        let myUrl = Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/locations';
        if (newName === '' || newName === null || newName === 'null')
            return;

        //Save the location info to the cloud need to figure out how its getting saved
        const requestObj = {
            parent_id: parentId,
            name: newName,
            description: 'My new Location'
        };

        $.ajax({
            type: 'POST',
            dataType: 'JSON',
            data: requestObj,
            url: myUrl,
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
            success (response) {
                let id = response.data.id;
                let name = response.data.name;
                if(App.Site.locations.hasOwnProperty(id)){
                    App.Site.locations[id].name = name;
                }else{
                    let location = new Location('locations', response.data);
                    App.Site.locations[id] = location;
                    if(isRoot){
                        App.Site.root = location;
                        App.ActiveLocation = App.Site.root; //SGD: we gotta sort this activelocation out
                    }else{
                        App.Site.root.children[id] = location;
                        if(!parent.isRoot){
                            parent.children[id] = location;
                        }
                    }
                }

                if (callback !== null){
                    callback(response.data.id);
                }
            },
            error (response) {
                App.alert('error', 'Sorry but we had trouble saving that new location. This could be due to the internet connection. Please refresh the page and try again.');
                console.log('A problem has occured saveNewLocation.save: ' + JSON.stringify(response));
            },
        });

    },
    //Delete the location in the cloud, right now only supporting deletion of locations with no children to keep it simple.
    delete (locationsToDelete, orphanDelete = false) { //orphan delete will not ask for confirm
        // let locDeleteText = '';
        let locDeleteFailText = '';
        Locations.deleted = [];
        //Creates text for 'are you sure?'
        // for (let locIndex in locationsToDelete) {

        //     locDeleteText += Locations.list[locationsToDelete[locIndex]].name;
        //     if (locationsToDelete.length > (parseInt(locIndex, 10) + 1)) {
        //         locDeleteText += ', ';
        //     }
        // }

        let deleteLoc = function (locIndex, locationsToDelete) {
            let myURL = Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/locations/' + Locations.list[locIndex].ID;
            $.ajax({
                type: 'DELETE',
                dataType: 'json',
                beforeSend (x) {
                    if (x && x.overrideMimeType) {
                        x.overrideMimeType('application/json;charset=UTF-8');
                    }
                },
                url: myURL,
                headers: {
                    Authorization: 'Bearer ' + User.apiToken,
                },
                success (response) {
                    let activeLoc = Locations.list[locIndex];
                    let locScenes = activeLoc.assocScenes;
                    let locDevices = activeLoc.assocDevices;
                    let active = {};
                    let len = 0;
                    let index, i;
                    let indexToRemove;
                    let locToRemove;

                    try {
                        //Remove all of the associated scenes
                        len = locScenes.length;
                        for (index = 0; index < len; index++) {
                            Scenes.delete(locScenes[index].linkID);
                        }
                        //Remove all of the associated devices
                        len = locDevices.length;
                        for (index = 0; index < len; index++) {
                            if (!Devices.list.hasOwnProperty(locDevices[index]))
                                continue;

                            active = Devices.list[locDevices[index]];

                            if (active.hasOwnProperty('location') && active.location !== undefined && (active.location === activeLoc.ID || (typeof (active.location) === 'string' && active.location.indexOf(activeLoc.ID) >= 0))) {
                                //Clear the device Location
                                active.location = '';
                                active.located = false;
                                Devices.removeDeviceLocationInCloud(index, locIndex);
                            }
                        }
                        //if the location has children add all children to the list of locations to be deleted
                        if(activeLoc.children.length > 0){
                            for(let child in activeLoc.children){
                                locationsToDelete.push(activeLoc.children[child].index);
                            }
                        }
                        //remove location from parents record all the way up the chain
                        if (!orphanDelete) {
                            if(activeLoc.hasOwnProperty('parent') && activeLoc.parent.hasOwnProperty('children')){
                                for (i = 0; i < activeLoc.parent.children.length; i++) {
                                    if (activeLoc.parent.children[i].ID === Locations.list[locIndex].ID) {
                                        activeLoc.parent.children.splice(i, 1);
                                    }
                                }
                            }
                        }
                        Locations.deleted.push(activeLoc.ID);
                        $('.modal').modal('hide');
                        //Recursivley calls to delete all
                        if (locationsToDelete.length > 0) {
                            deleteLoc(locationsToDelete.pop(), locationsToDelete);
                        }else{
                            for(locToRemove in Locations.deleted){
                                indexToRemove = Locations.getIndex(Locations.deleted[locToRemove], 'ID');
                                // index (to make sure index fidelity post splice since we stored them pre splice)
                                Locations.list.splice(indexToRemove, 1);
                            }
                            Locations.deleted = [];
                            Locations.buildAllPaths();
                            let sceneLength = Scenes.list.length;
                            for(i = 0; i < sceneLength; i++){
                                if(Scenes.list[i].location === ''){
                                    Scenes.delete(Scenes.list[i].linkID);
                                }
                            }
                            if (App.activeLocationIndex === locIndex) {
                                App.activeLocationIndex = 0;
                            }
                        }
                        //$('#action-overlay').fadeOut('fast');
                    } catch (e) {
                        if (locDeleteFailText.length > 0) {
                            locDeleteFailText += ', ';
                        }
                        locDeleteFailText += Locations.list[locIndex].name;
                        //if there are still locations to delete...delete the next in line
                        if (locationsToDelete.length > 0) {
                            deleteLoc(locationsToDelete.pop(), locationsToDelete);
                            //if we have reached the end of the list of locations to delete, go through and remove them all from the device list
                        }else if(Locations.deleted.length > 0){
                            for(locToRemove in Locations.deleted){

                                indexToRemove = Locations.getIndex(Locations.deleted[locToRemove], 'ID');
                                Locations.list.splice(indexToRemove, 1);
                            }
                        }
                        // $('#loc-list-li' + Locations.list[locIndex].ID + '> .list-item-content > .item-select').removeClass('fa-spin fa-spinner').addClass('fa-warning');
                    }
                },
                error (response) {
                    if (locationsToDelete.length > 0) {
                        deleteLoc(locationsToDelete.pop(), locationsToDelete);
                    }else if(Locations.deleted.length > 0){
                        for(let locToRemove in Locations.deleted){
                            Locations.list.splice(Locations.getIndex(Locations.deleted[locToRemove], 'ID'), 1);
                        }
                    }
                    $('#loc-list-li' + Locations.list[locIndex].ID + '> .list-item-content > .item-select').removeClass('fa-spin fa-spinner').addClass('fa-warning');
                    App.alert('error', 'A communication error has occurred, please check your internet connection: ' + response.message, response.errors);
                }
            });
        };
        if (orphanDelete) {
            deleteLoc(locationsToDelete.pop(), locationsToDelete);
        } else {
            deleteLoc(locationsToDelete.pop(), locationsToDelete);
            //////BB TODO: Come back once we figure modals out
            // App.confirmModal('Are you sure you want to delete location(s): ' + locDeleteText + '?', function (result) {
            //     if (result) {
            //         //removes check box - adds spinner to show the location is being deleted
            //         $('.loc-list-li > .selected > .item-select').removeClass('fa-check-square-o').addClass('fa-spin fa-spinner');
            //         deleteLoc(locationsToDelete.pop(), locationsToDelete);
            //     }
            // });
        }

    },
    hasConfig(config){
        return (config.hasOcc === true || config.hasDLH === true || config.hasSwitch === true || config.hasSlider === true);
    },
    RemoveAssocDevice (deviceIndex) {
        let len = this.assocDevices.length;
        for (let index = 0; index < len; index++) {
            if (this.assocDevices[index] === deviceIndex)
                this.assocDevices.splice(index, 1);
        }

    },

    RemoveAssocScene (sceneIndex) {
        let len = this.assocScenes.length;
        let linkID = Scenes.list[sceneIndex].linkID;

        for (let index = 0; index < len; index++) {
            if (this.assocScenes[index].linkID === linkID)
                this.assocScenes.splice(index, 1);
        }

    },

    getIndex (searchParam, paramType) {
        let index;
        switch (paramType) {
        case 'ID':
            let active = Locations.list;
            let len = Locations.list.length;
            for (index = 0; index < len; index++) {
                if (active[index].ID === searchParam) {
                    return index;
                }
            }
            return -1;
        default:
            for (index = 0; index < Locations.list.length; index++) {
                if (Locations.list[index].ID === searchParam) {
                    return index;
                }
            }
            return -1;
        }
    },

    findChildren () {
        for (let index = 0; index < Locations.list.length; index++) {
            if (Locations.list[index].hasOwnProperty('parent') && Locations.list[index].parent !== null && Locations.list[index].parent.hasOwnProperty('children')) {
                Locations.list[index].parent.children.push(Locations.list[index]);
            }
        }
    },

    findAssocScenes (locationIndex, returnLinkIDs = false) {
        let targetLocation = Locations.list[locationIndex].ID;
        let locAssocScenes = [];

        for (let index = 0; index < Scenes.list.length; index++) {

            if (Scenes.list[index].located || locationIndex === '0' || locationIndex === 0) {
                if (locationIndex === '0' || locationIndex === 0 || Scenes.list[index].location.toString().indexOf(targetLocation) >= 0 || Scenes.list[index].locIndex === locationIndex) {
                    if (!returnLinkIDs)
                        locAssocScenes.push(Scenes.list[index]);
                    else
                        locAssocScenes.push(Scenes.list[index].linkID);
                }
            }
        }
        if (locAssocScenes.length !== Locations.list[locationIndex].scenesHere.length) {
            // Render.scenes.cached = false;
        }
        Locations.list[locationIndex].scenesHere = locAssocScenes;
        return locAssocScenes;
    },
    //constructs searcheable path through all ancestors of a node '0,1,10,6'
    //must pass parentNode in because the current Location has not yet been created
    buildPathStr (locIndex, parentNode) {

        let pathStr = parentNode.ID + ',';

        while (parentNode.parent.ID !== '1') { //while parent isnt root
            pathStr = parentNode.parent.ID + ',' + pathStr;
            parentNode = parentNode.parent;
        }

        //log (pathStr);
        return pathStr; //0,1,10,6
    },
    removeFromParents: function(deleteLocationID){
        for(let locationID in App.Site.locations){
            let location = App.Site.locations[locationID];
            if(location.isParent){
                delete location.children[deleteLocationID];
            }
        }
    },

    saveSettings () {
        let activeDevice;
        let locationsHit = [];
        let assocDevicesByLoc = {};
        for (let setting in this.changedSettings) {
            let obj = this.changedSettings[setting];
            let activeLoc = Locations.list[obj.locIndex];

            //Simple caching applied
            //Check if we already have stored the location and gotten its active devices
            if (locationsHit.indexOf(obj.locIndex) < 0) { //if not get them
                locationsHit.push(obj.locIndex);
                this.assocDevices = Locations.findAssocDevices(obj.locIndex);
                assocDevicesByLoc[obj.locIndex] = this.assocDevices;

                //Add devices for multiChannel from parent to active
                assocDevicesByLoc[obj.locIndex] =
                    assocDevicesByLoc[obj.locIndex].concat(Locations.findMultiChannelDevicesInParent(activeLoc));

                this.assocDevices = assocDevicesByLoc[obj.locIndex];

            } else { //if so use them
                //Will include multiChannel devs from parent
                this.assocDevices = assocDevicesByLoc[obj.locIndex];
            }

            let len = this.assocDevices.length;

            switch (obj.type) {
            case 'dp-single':
                Devices.writeSingleSetpoint(activeLoc.masterML, obj.index, obj.newVal);
                break;
            case 'dp-multiple':

                for (let index = 0; index < len; index++) {
                    activeDevice = Devices.list[this.assocDevices[index]];
                    if (activeDevice.isDimmer || activeDevice.isSensor) {
                        console.log('Adding ' + activeDevice.getName(true) + ' ' + obj.index + ' ' + obj.newVal);
                        //Overiding this check for now since the API is more healthy, and its causing confusion
                        // if(activeDevice.isTalking())
                        Devices.setpointsToWrite.push({
                            'deviceIndex': this.assocDevices[index],
                            'idx': obj.index,
                            'val': obj.newVal
                        });
                    }
                }
                break;

            case 'scene-dimmers':
                //let len = activeLoc.assocDevices.length;
                let active, activeExternal;
                let lightLevel, fadeRate; // eslint-disable-line
                let activeLinkID = obj.linkID;

                for (let dimmerIndex = 0; dimmerIndex < len; dimmerIndex++) {
                    active = Devices.list[this.assocDevices[dimmerIndex]];

                    for (let extActIndex = 0; extActIndex < active.externalActions.length; extActIndex++) {
                        activeExternal = active.externalActions[extActIndex];

                        if (activeExternal.linkID === activeLinkID) {
                            if (activeExternal.actionTableName === 'Set Auto Duty(%,Fade)' ||
                                activeExternal.actionTableName === 'Set Manual Duty(%,Fade)'
                            ) {
                                lightLevel = parseInt((activeExternal.var1 / 1000), 10);
                                fadeRate = parseInt(activeExternal.var1.substr(-3), 10);
                                //Add the right number of 0s so that it is handled corrrectly
                                //Add the right number of 0s so that it is handled corrrectly
                                if (fadeRate < 10) {
                                    fadeRate = '00' + fadeRate;
                                } else if (fadeRate < 100 && fadeRate >= 10) {
                                    fadeRate = '0' + fadeRate;
                                }

                                activeExternal.var1 = obj.newVal + fadeRate;
                                activeExternal.saved = User.myUser.id;
                                activeExternal.tableRow = '65535';
                                active.unsaved = true;
                            } //Handle PHD Actions
                            else if (activeExternal.actionTableName === trigConfig.phdFunctions['auto-fade'] ||
                                activeExternal.actionTableName === trigConfig.phdFunctions['man-fade']
                            ) {
                                let hexVar1 = decToHex(activeExternal.var1).replace('0x', '');
                                let str = hexVar1;
                                fadeRate = parseInt(hexVar1.substr(-2), 16);
                                let duty;

                                str = str.substring(0, str.length - 2);

                                if (str.length === 2) {
                                    str = str.substring(0, str.length - 1);
                                } else {
                                    str = str.substring(0, str.length - 2);
                                }

                                duty = (('00' + (parseInt(obj.newVal)).toString(16)).substr(-2), 10);
                                fadeRate = (('00' + (parseInt(fadeRate)).toString(16)).substr(-2), 10);

                                activeExternal.var1 = hexToDec(str + duty + fadeRate);
                                activeExternal.tableRow = '65535';
                                activeExternal.saved = User.myUser.id;
                                active.unsaved = true;
                            }
                        }
                    }
                }
                break;

            default:
                break;
            }
        }
        this.changedSettings = {};
        //SGD: Commented out for now 'Scenes.save is not a function'
        // Scenes.save();
    },

    //finds relevant devices in parent location and returns them as an array
    //SGD: What are we doing with this activeLocation global?
    findMultiChannelDevicesInParent (location = App.ActiveLocation) {
        let parentalPHDs = new Set();
        while(!location.isRoot && location.hasOwnProperty('parent') 
            && location.parent.hasOwnProperty('id') 
            && location.parent.id !== App.Site.root.id){
            if (location.parent.devicesByType.multi_channel.size > 0) {
               parentalPHDs = set_union(parentalPHDs, location.parent.devicesByType.multi_channel);
            }
            location = location.parent;
        }
        return parentalPHDs;
    },
    checkForAndHandleMultiChannelDevices(features, doToggle, devices, location=App.ActiveLocation){
        let higherLevelMultiChannelDevices = Locations.findMultiChannelDevicesInParent(location);
        let multiChannelDevices = set_union(location.devicesByType.multi_channel, higherLevelMultiChannelDevices);
        if (multiChannelDevices.size > 0) {
            let phdItem = '';
            let hasLocalPHD = false;
            for (let id in multiChannelDevices.values()) {
                let device = App.Site.devices[id];
                if (location.devices.hasOwnProperty(id)) { //phd is local
                    hasLocalPHD = true;
                    phdItem += '<li class=\'phd-device-li \'>' +
                        '<i class=\'icon-check-empty phd-device-check\' data-id=\'' + id + '\' aria-hidden=\'true\'></i>' +
                        device.name + '(' + device.IP.substr(-4) + ')' +
                        '</li>';
                } else {
                    phdItem += '<li class=\'phd-device-li parental\'>' +
                        '<i class=\'icon-check-empty phd-device-check\' data-id=\'' + id + '\' aria-hidden=\'true\'></i>' +
                        device.name + '(' + device.IP.substr(-4) + ')' +
                        '</li>';
                }
            }

            $('#assoc-phd-modal').modal('show').on('shown.bs.modal', function () {

                $('#action-overlay').fadeOut('fast');
                $('#phd-to-assoc').html(phdItem);
                if (hasLocalPHD) {
                    $('.show-parental-phd').removeClass('hidden');
                    $('.parental').hide();
                }
            });

            $('#associate-phd').one('click', function () {
                let selectedDevicesWithCustomVar1 = new Set();
                $('.phd-device-selected').each(function () {
                    selectedDevicesWithCustomVar1.add($(this).find('.phd-device-check').data('id'));
                });
                let allDevicesForFeature = new Set();
                allDevicesForFeature.add(selectedDevicesWithCustomVar1);
                doToggle(features, selectedDevicesWithCustomVar1);
            });

            return true;
        }else{
            return false;
        }
    },
    //Creates and saves a user defined template. Basically saving the way someone has configured a room
    saveTemplate (locIndex) {

        let activeLocIndex = (locIndex) ? locIndex : App.activeLocationIndex;
        let activeLoc = Locations.list[activeLocIndex];
        let newTemplateName = false;
        let newTemplateObj = {
            'name': '',
            'description': '',

            'datapoints': {
                'enable': {},
                'disable': {}
            },

            'scenes': []
        };
        let newSceneObj = {};
        let activeTrig, activeAct;
        let deviceList = Devices.list;

        //Get the latest list of scenes and devices in this location
        this.assocDevices = Locations.findAssocDevices(activeLocIndex);
        this.assocScenes = Locations.findAssocScenes(activeLocIndex);
        this.assocDimmers = [];
        this.assocSensors = [];
        this.assocSwitches = [];
        this.otherDevices = [];
        let activeDevice, activeRequiredRole, activeDeviceIndex;
        let functionIndex, functionLen, activeFunction;

        //// CONSTRUCTORS /////
        this.DeviceObj = function () {
            //"min-ver": "", //TODO: add this later?
            this.trigs = [];
            this.acts = [];
        };
        this.NewSceneObj = function (activeScene) {
            this.name = activeScene.name;
            this.description = activeScene.description;
            this.hidden = activeScene.hidden;
            this.devices = {};
        };
        this.NewDPObj = function (name, idx, val, description) {
            this.name = name;
            this.idx = idx;
            this.val = val;
            this.description = description;
        };

        //////// ININITIALIZE THE TEMPLATE OBJECT /////////
        //Let the user name the template
        newTemplateName = prompt('Name your new template', (activeLoc.name + ' copy'));

        //If user cancels or leaves the name field empty, bail
        if (!newTemplateName)
            return;

        newTemplateObj.name = newTemplateName;
        newTemplateObj.description = 'user_created'; //TODO: Will make the description unique and useable shortly

        //////// GRAB THE VALUES OF IMPORTANT DATAPOINTS /////////
        //Sort all of the devices into their respective types
        let len = this.assocDevices.length;
        for (let index = 0; index < len; index++) {
            activeDevice = deviceList[this.assocDevices[index]];
            //Note: We use seperate ifs because a device can be more than one thing here
            if (activeDevice.isSensor) {
                this.assocSensors.push(this.assocDevices[index]);
            }
            if (activeDevice.isDimmer) {
                this.assocDimmers.push(this.assocDevices[index]);
            }
            if (activeDevice.isSwitch) {
                this.assocSwitches.push(this.assocDevices[index]);
            }
            if (activeDevice.classification === 'other') {
                this.otherDevices.push(this.assocDevices[index]);
            }
        }

        //Sort out all of the live data by location so that activeData is an array of all datapoints in this location
        let activeData = [];
        let active;
        let deviceDataLength = App.Site.deviceData.length;
        for (let deviceDataIndex = 0; deviceDataIndex < deviceDataLength; deviceDataIndex++) {
            active = App.Site.deviceData[deviceDataIndex];
            try {
                for (let assocDevIndex = 0; assocDevIndex < this.assocDevices.length; assocDevIndex++) {
                    if (active.ip === Devices.list[this.assocDevices[assocDevIndex]].IP) {
                        let lastOldIndex = activeData.length;
                        activeData = activeData.concat(active.data);

                        //We want to tag all of the information with some extra useful info about its parent device (might switch to the deviceIndex later)...
                        len = activeData.length;
                        for (let index = lastOldIndex; index < len; index++) {
                            activeData[index].role = Devices.list[this.assocDevices[assocDevIndex]].role;
                            activeData[index].partType = Devices.list[this.assocDevices[assocDevIndex]].partType;
                        }
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }

        let activeDataLen = activeData.length;
        let tempDPObj = {};
        let saveThisDP = false;
        let activeDatapoint;

        //Occupancy settings
        //TODO: Make sure this is the master, or make sure we do it to all?
        for (let index = 0; index < activeDataLen; index++) {
            activeDatapoint = activeData[index];
            //look at the PIR Timeouts to figure out our timeout
            switch (activeData[index].dname) {

                case 'NH Timeout':
                case 'AH Timeout':
                case 'PIR Timeout':
                case 'PIR Timeout2':

                    //Verify that its the master sensor we are looking at
                    if (activeDatapoint.role === 'master_motion' || activeDatapoint.role === 'master_motion_dll') {
                        tempDPObj = new this.NewDPObj(activeDatapoint.dname, activeDatapoint.idx, activeDatapoint.cval, 'The master Timeout Period');
                        saveThisDP = true;
                    }
                    break;

                case 'Occ Enable':
                    //Verify that its the master sensor we are looking at
                    if (activeDatapoint.role === 'master_motion' || activeDatapoint.role === 'master_motion_dll') {
                        tempDPObj = new this.NewDPObj(activeDatapoint.dname, activeDatapoint.idx, 1, 'The master Timeout Period');
                        saveThisDP = true;
                    }
                    break;

                case 'DLH Targ':
                case 'DayDimTarg':
                    if (activeData[index].role === 'master_dll' || activeData[index].role === 'master_motion_dll' || activeData[index].role === 'master_dll_child_motion') {
                        tempDPObj = new this.NewDPObj(activeData[index].dname, activeData[index].idx, activeData[index].cval, 'The master Tiemout Period');
                        saveThisDP = true;
                    }
                    break;

                default:
                    break;

            }

            //if the active one isn't important go straight to the next
            if (!saveThisDP)
                continue;

            //Remove any am- for storage here.
            let activePartType = activeDatapoint.partType.replace('AM-', '');

            //Make a new device type in the list if the active one exists before we push the tempDP object
            if (!newTemplateObj.datapoints.enable.hasOwnProperty(activePartType)) { // i dont think we would ever have a disable here, unless we actually parse the existing templates... (which might be good)
                newTemplateObj.datapoints.enable[activePartType] = [];
            }
            newTemplateObj.datapoints.enable[activePartType].push(tempDPObj);
            //reset save this dp flag
            saveThisDP = false;
        }

        //////// ADD EACH SCENE IN THE LOCATION TO THE TEMPLATE /////////
        len = this.assocScenes.length;
        let activeScene = {};
        let functionWasAlreadyThere = false;
        let activePartType, newFunctionObj;
        let motionPartTypes = ['SW-ML', 'USGG-25P', 'ML-DALI', 'BAF-DRIVE', 'ACDIM', 'ALC', 'ALC-LVD', 'DRIVER'];
        let linkingPartTypes = ['SW-ML', 'USGG-25P', 'SW-MTLH', 'MLTH', 'ACDIM', 'ALC', 'ALC-LVD', 'DRIVER', 'BAF20'];
        //triggers and actions that will require a required role to be set
        let requireRole = ['Motion Update', 'Motion Reset', 'Motion', 'Motion2', 'Motion TimeOut', 'Motion TimeOut2'];
        for (let index = 0; index < len; index++) {
            activeScene = this.assocScenes[index];
            newSceneObj = new this.NewSceneObj(activeScene);

            ////////// Get all the triggers //////////
            let trigLen = activeScene.triggers.length;

            for (let trigIndex = 0; trigIndex < trigLen; trigIndex++) {
                activeTrig = activeScene.triggers[trigIndex];
                functionWasAlreadyThere = false;

                //TODO: add required role somehow //should get it from the devices. But then we have to assign them too
                newFunctionObj = {
                    //"required_role" : '',
                    //The trigger action function name (actionTableName) ex: 'INT.Hold1' (stored in linkID for triggers...),
                    //Remove INT. because it gets added later.
                    'function': activeTrig.linkID.replace('INT.', ''),
                };

                activeDeviceIndex = Devices.getIndex(activeTrig.nodeId, 'ip');
                activeDevice = Devices.list[activeDeviceIndex];

                if (requireRole.indexOf(newFunctionObj.function) >= 0) {
                    activeRequiredRole = activeDevice.role;
                } else {
                    activeRequiredRole = null;
                }

                activePartType = activeTrig.partType.replace('AM-', ''); //We remove the Am- for storage in a template

                //if we have a tiny we need to add the is_mac_action and applicable parts to complete the template
                //otherwise things break and its bad, very bad.
                if (activePartType === 'Tny') {
                    newFunctionObj.is_mac_action = true;
                    newFunctionObj.applicable_parts = Template.tinyApplicableParts;
                }

                //Make a new device type in the list if the active one exists
                if (!newSceneObj.devices.hasOwnProperty(activePartType))
                    newSceneObj.devices[activePartType] = new this.DeviceObj();

                //Need to make sure we dont already have the same action in there where we have multiple of the same device, or we will get dupes
                functionLen = newSceneObj.devices[activePartType].trigs.length;
                for (functionIndex = 0; functionIndex < functionLen; functionIndex++) {
                    activeFunction = newSceneObj.devices[activePartType].trigs[functionIndex];

                    if (activeFunction['function'] === newFunctionObj['function']) {
                        functionWasAlreadyThere = true;

                        //If there is already a required_role set, and we see the same action again, we concat the role of the current device
                        if (activeFunction.hasOwnProperty('required_role')) { //TODO: Review, for DLL case.... This isnt good enough yet
                            newFunctionObj.required_role = activeFunction.required_role + ',' + activeRequiredRole;
                        }
                    }

                }
                //set required role array as necessary based on action function/scene
                if (newFunctionObj.function.toLowerCase().indexOf('motion') >= 0 && motionPartTypes.indexOf(activePartType) >= 0) {
                    if (newFunctionObj.function.toLowerCase().indexOf('update') >= 0) {
                        newFunctionObj.required_role = 'child_motion';
                    } else {
                        newFunctionObj.required_role = 'master_motion,master_motion_dll';
                    }
                } else if (newFunctionObj.function.toLowerCase().indexOf('light') >= 0 && linkingPartTypes.indexOf(activePartType) >= 0) {
                    if (activePartType.indexOf('SW') >= 0 || activePartType === 'MLTH') {
                        newFunctionObj.required_role = 'master_dll,master_motion_dll,master_dll_child_motion';
                    } else {
                        newFunctionObj.required_role = 'master_dll,master_motion_dll';
                    }
                }

                //IF we havent already set required role, do so now.
                if (!newFunctionObj.hasOwnProperty('required_role'))
                    newFunctionObj.required_role = activeRequiredRole;

                //Add the function to the list of Action functions
                if (!functionWasAlreadyThere)
                    newSceneObj.devices[activePartType].trigs.push(newFunctionObj);

                /*
                //NOTE: triggers dont need a var1 because that will always be the linkID.
                //Its also confusing because the template implementer handles the fact that its a coap send action function, with the above linkID and var1 of the linkID_to_send
                //See Templates.build();
                */

            }

            ////////// Get all the actions //////////
            let actLen = activeScene.actions.length;

            for (let actIndex = 0; actIndex < actLen; actIndex++) {
                functionWasAlreadyThere = false;
                activeAct = activeScene.actions[actIndex];
                newFunctionObj = {
                    'function': activeAct.actionTableName, //The action function name  ex: 'Set Auto Duty(%,Fade)'
                    'var': activeAct.var1
                };

                activePartType = activeAct.partType.replace('AM-', ''); //We remove the Am- for storage in a template

                //Make a new device type in the list if the active one exists
                if (!newSceneObj.devices.hasOwnProperty(activePartType))
                    newSceneObj.devices[activePartType] = new this.DeviceObj();

                activeDeviceIndex = Devices.getIndex(activeAct.nodeId, 'IP');
                activeDevice = Devices.list[activeDeviceIndex];

                if (requireRole.indexOf(newFunctionObj.function) >= 0) {
                    activeRequiredRole = activeDevice.role;
                } else {
                    activeRequiredRole = null;
                }
                //Need to make sure we dont already have the same action in there where we have multiple of the same device, or we will get dupes
                functionLen = newSceneObj.devices[activePartType].acts.length;
                for (functionIndex = 0; functionIndex < functionLen; functionIndex++) {
                    activeFunction = newSceneObj.devices[activePartType].acts[functionIndex];
                    if (activeFunction['function'] === newFunctionObj['function']) {
                        functionWasAlreadyThere = true;
                        //If there is already a required_role set, and we see the same action again, we concat the role of the current device
                        if (activeFunction.hasOwnProperty('required_role')) //TODO: Review, for DLL case.... This isnt good enough yet
                            newFunctionObj.required_role = activeFunction.required_role + ',' + activeRequiredRole;
                    }
                }

                //set required roles based on action function
                if (newFunctionObj.function.toLowerCase().indexOf('motion') >= 0 && motionPartTypes.indexOf(activePartType) >= 0) {

                    newFunctionObj.required_role = 'master_motion,master_motion_dll';

                }

                //IF we havent already set required role, do so now.
                if (!newFunctionObj.hasOwnProperty('required_role'))
                    newFunctionObj.required_role = activeRequiredRole;

                //Add the function to the list of Action functions
                if (!functionWasAlreadyThere)
                    newSceneObj.devices[activePartType].acts.push(newFunctionObj);
            }
            //Add the whole scene to the template scene list
            newTemplateObj.scenes.push(newSceneObj);
        }

        //////// SAVE THE NEW TEMPLATE TO THE CLOUD ///////
        Locations.saveTemplateToCloud(newTemplateObj);

    },

    setName (locID, newName) {
        let locIndex = this.getIndex(locID, 'ID');

        if (newName === null || newName === '') {
            return;
        }

        if(locIndex >= 0 ){
            Locations.list[locIndex].setProps({name:newName});
        }
    },

    calculatePower (locID) {
        //let locationIndex = Locations.getIndex(locID, 'ID')
        let deviceDataLength = App.Site.deviceData.length;
        let totalPower = 0;
        for (let deviceDataIndex = 0; deviceDataIndex < deviceDataLength; deviceDataIndex++) {
            let active = App.Site.deviceData[deviceDataIndex];
            try {

                if (active.location && ((typeof (active.location) === 'string' && active.location.indexOf(locID) >= 0) || locID === active.location)) {
                    let len = active.data.length;
                    for (let index = 0; index < len; index++) {
                        if (active.data[index].un === 'W') {
                            totalPower = totalPower + parseInt(active.data[index].cval, 10);
                        }
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
        Locations.activeTotalPower = totalPower;
        try {
            return parseInt(totalPower, 10);
        } catch (e) {
            console.log(e);
            return 0;
        }
    },
    calcAllLightLevels(){
        for(let id in App.Site.locations){
            let location = App.Site.locations[id];
            if(!location.isRoot){
                this.calculateLightLevel(location);
            }
        }
        if(App.ActiveLocation !== false){
            App.ActiveLocation.updateUIComponents();
        }
    },
    calculateLightLevel (location) {
        if(location === false){
            return false;
        }
        let devices = location.devices, DAC = 0, PWM = 0, mode = false, activeDevice;
        let levelToSet = false;
        if(Object.keys(devices).length === 0){
            mode = 0;//set mode to 0 so we go right to the default case in the below switch and then update ui components
            return false;
        }
        let data, stamps={dac:false, pwm:false, ch1:false, mode:false};
        //get light level data from all devices in location, only update data if timestamp is newest or val has not been set
        for (let ip in devices) {
            activeDevice = devices[ip];
            if(activeDevice.dnameToIndex.hasOwnProperty('Channel 1')){
                data = activeDevice.dataTable[activeDevice.dnameToIndex['Channel 1']]
                if(stamps.ch1 === false || (data.hasOwnProperty('timestamp') && data.timestamp > stamps.ch1)){
                    stamps.ch1 = (data.hasOwnProperty('timestamp') ? data.timestamp : false);
                    DAC = parseFloat(data.cval, 10);
                    mode = 2;
                }
            }
            if(activeDevice.dnameToIndex.hasOwnProperty('PWM')){
                data = activeDevice.dataTable[activeDevice.dnameToIndex.PWM];
                if(stamps.pwm === false || (data.hasOwnProperty('timestamp') && data.timestamp > stamps.pwm)){
                    stamps.pwm = (data.hasOwnProperty('timestamp') ? data.timestamp : false);
                    PWM = parseFloat(data.cval, 10);
                }
            }
            if(activeDevice.dnameToIndex.hasOwnProperty('DAC')){
                data = activeDevice.dataTable[activeDevice.dnameToIndex.DAC];
                if(stamps.dac === false || (data.hasOwnProperty('timestamp') && data.timestamp > stamps.dac)){
                    stamps.dac = (data.hasOwnProperty('timestamp') ? data.timestamp : false);
                    DAC = parseFloat(data.cval, 10);
                }
            }
            if(activeDevice.dnameToIndex.hasOwnProperty('Mode')){
                data = activeDevice.dataTable[activeDevice.dnameToIndex.Mode];
                if(stamps.mode === false || (data.hasOwnProperty('timestamp') && data.timestamp > stamps.mode)){
                    stamps.mode= (data.hasOwnProperty('timestamp') ? data.timestamp : false);
                    if(data.cval > 0 && data.cval < 7){
                        mode = parseFloat(data.cval, 10);
                    }
                }
            }
        }
        switch (mode) {
            case 1:
                if (PWM > 0 || DAC > 0) {
                    levelToSet = 'On';
                } else {
                    levelToSet = 'Off';
                }
            break;
            case 2:
                if (DAC > 0)
                    location.lightsOn = true;
                //Handle the manual update case where 1000 is added to the target
                if (DAC >= 1000) {
                    DAC = DAC - 1000;
                }

                levelToSet = parseFloat(DAC, 10);
            break;
            case 3:
                if (DAC > 0)
                    location.lightsOn = true;
                //Handle the manual update case where 1000 is added to the target
                if (DAC >= 1000) {
                    DAC = DAC - 1000;
                }
                levelToSet = parseFloat(DAC, 10);
            break;
            case 4:
                if (PWM > 0)
                    this.lightsOn = true;
                //Handle the manual update case where 1000 is added to the target
                if (PWM >= 1000) {
                    PWM = PWM - 1000;
                }
                levelToSet = parseFloat(PWM, 10);
            break;
            case 5:
                if (DAC > 0)
                    location.lightsOn = true;
                //Handle the manual update case where 1000 is added to the target
                if (DAC >= 1000) {
                    DAC = DAC - 1000;
                }
                levelToSet = parseFloat(DAC, 10);
            break;
            case 6:
                if (DAC > 0)
                    location.lightsOn = true;
                //Handle the manual update case where 1000 is added to the target
                if (DAC >= 1000) {
                    DAC = DAC - 1000;
                }
                levelToSet = parseFloat(DAC, 10);
            break;

            default:
                break;
        }
        if(levelToSet !== false && (levelToSet > 0 || levelToSet === 'On')){
            location.lightsOn = true;
        }else{
            location.lightsOn = false;
        }
        return this.doUpdateLightLevel(levelToSet, location);

    },
    //figure out if the current change in level matches the trend
    levelChangeFitsTrend(trend, currentChange){
        if(trend === 0 || ((trend < 0 && currentChange < 0) ||
            (trend > 0 && currentChange > 0))){
                return true;
        }else{
            return false;
        }
    },
    //if the change and the new level fit in the trend well give the go ahead to set the new level
    //for example the trend is decreasing and the new level is actually lower than the old one
    newLevelFitsTrend(change, levelToSet, currentLightLevel){
        if((change > 0 && levelToSet > currentLightLevel) || (change <= 0 && levelToSet < currentLightLevel)){
            return true;
        }else{
            return false;
        }
    },
    resetLocationLevelTrend(location){
        setTimeout(() => {//timeout to clear the trend once we are no longer getting a constant stream of light levels
            let current = new Date().getTime();//3 seconds since the LAST reported light level
            if(current - location.lightLevelStamp >= 3000){//not directly arbitrary, did some testing and came to about this value.
                location.lightLevelChange = 0;//may want to test on bigger, potentially slower sites to make sure it works as expected
            }
        },3000);
    },
    //do the math to figure out whether we should update the light level for the user or not
    doUpdateLightLevel(levelToSet, location){

        //if the light level is new (different)
        let success = false;
        if(levelToSet !== false && levelToSet !== location.lightLevel){
            let timeOfThisSet = new Date().getTime();//set a time so we know when this one set
            //if its been 4 seconds since the last time the light level updated, just set it.
            if(timeOfThisSet - location.lightLevelStamp >= 4000){//not directly arbitrary, tested a few values. 3 seconds gave false positives
                location.lightLevel = levelToSet;//and this time works with the other time to make sure that the stragglers at the end hit the else
                location.lightLevelChange = 0;// This is our start over from the top to avoid not setting on load or carrying over trends from the last update
            }else{//otherwise, we are probably getting a stream of light levels in from different devices
                let change = levelToSet - location.lightLevel;
                let onTrend = false;
                if(this.levelChangeFitsTrend(location.lightLevelChange, change)){
                    onTrend= true;
                }
                if(onTrend && this.newLevelFitsTrend(change, levelToSet, location.lightLevel)){
                    location.lightLevel = levelToSet;//then we update
                }
                //update the trend
                location.lightLevelChange += change;
                this.resetLocationLevelTrend(location);//handle resetting trend for next time if level stops changing
            }
            location.lightLevelStamp = timeOfThisSet;
            if(App.hasOwnProperty('DirectControls')){
                App.DirectControls.handleUpdatedLevel();
            }
            success = true;
        }
        return success;
    },
    calculateOccPercentage: function (result, location){
        if(result.length > 0){
            //Calculate the average time occupied/vacant for this room
            //loop through datapoints for last hours and create a running total
            let sum = 0;

            for (let dp of result){
                sum += dp.value;
            }
            let count = result.length;
            let avg = sum/count;
            let firstDp = result[0];
            let now = (parseInt(new Date().getTime()/1000 + 62135596800));
            let timeSinceStart = +((now - firstDp.time_stamp_id)/60/60).toFixed(1);
            let timeOccupied = +(timeSinceStart * avg).toFixed(1);
            let timeVacant = (timeSinceStart - timeOccupied)
            let data = [parseInt(timeOccupied), parseInt(timeVacant)];
            location.setProps({'averageOccupancy' : data});
        }
    },
    //calculate whether the location is occupied based on device data that should have occ data attached based on dname
    calculateOccupancy (location, activeData, ip) {
        if(location === false) return;
        if(location.parent.isRoot === false){
            let config = location.findConfigInParent();
            if(config.hasOcc === true){
                location = config.parent;
            }
        }
        let master = location.masterOcc;
        let masterIP = false, didUpdate = false;
        let hasStamp = location.occTimestampsByDname.hasOwnProperty(activeData.dname);
        let timestamp = (activeData.hasOwnProperty('timestamp') ? activeData.timestamp : false);
        if(master !== false){
            masterIP = master.ip_address;
        }
        //make sure we have a valid master

        //if there is a timestamp and either the current data is newer than the old data or the data is from the master sensor, update timestamp
        if(timestamp !== false &&
            ((hasStamp &&
            location.occTimestampsByDname[activeData.dname] < timestamp) ||
            masterIP === ip)
        ){
            location.occTimestampsByDname[activeData.dname] = timestamp;
        }else{
            return;
        }
        switch (activeData.dname) {
            case 'DLL Enable':
            case 'Lt Enable': //Light sensing enable flag in light sensors
                if (activeData.cval === 1) { //If light sensing is enabled
                   // location.daylightingEnabled = true;
                }
            break;
            case 'Occ Enable':
            case 'PIR Enable': //Occupancy/PIR sensor enable flag for motion sensors
                if (parseFloat(activeData.cval) === 0 ) { //If it is NOT enabled
                    return false;
                }
                if(location.hasOccupancyControl === false){
                    location.hasOccupancyControl = true;
                    didUpdate = true;
                }
            break;
            case 'Occupied':
            case 'PIR Value': //The current occupancy state. 1 === "the zone is currently occupied"
                let occupancyValue = parseFloat(activeData.cval);
                if ((occupancyValue === 1 || occupancyValue === 2) && location.occupied === false) {
                    location.occupied = true;
                    didUpdate = true;
                } else if (occupancyValue === 0 && ip === masterIP && location.occupied === true) {
                    location.occupied = false;
                    didUpdate = true;
                } else { //Handles the case where we have an error value, like on a smart driver with no ML connected
                    //console.log(masterSensor.getName(true) + '(' + masterSensor.role + '): ' + masterSensor.name + ' has NAN PIR'); //debug
                    // console.log(activeData.cval); //debug
                }
                if(location.hasOccupancyControl === false){
                    location.hasOccupancyControl = true;
                    didUpdate = true;
                }
            break;
            case 'Occ Mode':
            case 'Timeout Mode': //Toggle for different occupancy control schemes
                if(location.mode !== activeData.cval){
                    location.mode = parseFloat(activeData.cval);
                    didUpdate = true;
                }
            break;
            case 'NH Timeout':
            case 'PIR Timeout': //The Period of time between the last motion event, and "motion timeout" call
                if(location.featureSettings['day-occ'].settings.timeout.value !== parseFloat(activeData.cval)){
                    location.featureSettings['day-occ'].settings.timeout.value = parseFloat(activeData.cval);
                    didUpdate = true;
                }
            break;

            case 'AH Timeout':
            case 'PIR Timeout2': //Same, but for timeout Mode 2
                if(location.featureSettings['night-occ'].settings.timeout.value !== parseFloat(activeData.cval)){
                    location.featureSettings['night-occ'].settings.timeout.value = parseFloat(activeData.cval);
                    didUpdate = true;
                }
            break;
            default:
                break;
        }
        return didUpdate;
    },
    updatePower (locID) {
        //let locationIndex = Locations.getIndex(locID, 'ID')
        let deviceDataLength = App.Site.deviceData.length;
        let totalPower = 0;
        for (let deviceDataIndex = 0; deviceDataIndex < deviceDataLength; deviceDataIndex++) {
            let active = App.Site.deviceData[deviceDataIndex];

            if (active.location && ((typeof (active.location) === 'string' && active.location.indexOf(locID) >= 0) || locID === active.location)) {
                let len = active.data.length;
                for (let index = 0; index < len; index++) {
                    if (active.data[index].un === 'W') {
                        totalPower = totalPower + parseInt(active.data[index].cval, 10);
                    }
                }
            }
        }
        Locations.activeTotalPower = totalPower;
        $('#total-power').html('Power: ' + parseInt(totalPower, 10) + 'W').fadeIn();
    },
    setLocationTargetLightLevel(){
        let deviceList = App.ActiveLocation.devices;
        let firstDevice = true;
        for(let key in deviceList){
            let device = deviceList[key];
            if(device.is_sensor === true){
                if(firstDevice === true){
                    if(App.hasOwnProperty('ActionOverlay')){
                        firstDevice = false;
                        App.ActionOverlay.show();
                    }
                }
                device.setTargetLightLevel();
            }
        }
    },
    calcForMuteDevices (locationIndex = App.activeLocationIndex) {
        let location = Locations.list[locationIndex];
        let length = location.assocDevices.length;
        let locDevices = location.assocDevices;
        if (length > 0) {
            for (let i = 0; i < length; i++) {
                if (!Devices.list[locDevices[i]].isTalking()) {
                    location.hasMuteDevice = true;
                    return;
                }
            }
        }
        location.hasMuteDevice = false;
    }
};

export {Locations};
