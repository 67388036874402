import { createActions } from 'redux-actions';
import SDK from 'api/sdk';

export const LIST_COMPANIES = 'LIST_COMPANIES';

const STAGE_STATES = {
    'REQUEST': undefined,
    'SUCCESS': undefined,
    'FAILURE': undefined,
    'FULFILL': undefined
};

export const { listCompanies } = createActions({
    [LIST_COMPANIES]: STAGE_STATES,
});

export const fetchCompanies = () => dispatch => {
    dispatch(listCompanies.request());
    const OPTIONS_SDK = { serverVariables: { baseVersion: "v3" } };
    return SDK.init().then(api => api.companies.getCompanies({}, OPTIONS_SDK))
        .then(({ body: { data }}) => {
            dispatch(listCompanies.success(data));
        })
        .catch(error => {
            dispatch(listCompanies.failure(error));
        })
        .finally(() => {
            dispatch(listCompanies.fulfill());
        });
}
