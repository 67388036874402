import $ from 'jquery';
import Paho from 'paho-mqtt';
import Devices from 'api/amatis/site/devices/services/devices';
import App from 'app/app';
import Communication from 'api/amatis/utilities/communications';
import User from 'classes/users';
import uuidv4 from 'uuid/v4';
import React from 'react';
import ReactJson from 'react-json-view';
import Amatis from 'api/amatis/amatis';
import store from 'store';
import { setMqttStatus } from 'redux/activeSite';
import { updateAmbrFromMqtt } from 'redux/ambrs';
import SDK from 'api/sdk';

class ActiveSite extends Amatis{
    constructor(ID, user){
        super();
        this.ID = ID;
        this.socket = null;
        this.notLocal = false;
        this.deviceData = [];
        this.mathdata ={};
        this.speed= 0;
        this.sw8Inputs = {};
        this.AMBRIP= '';
        this.ambrHasStates = false;
        this.hasPHD = false;
        this.hasSw8 = false;
        this.host= '';
        this.time= new Date();
        this.loadTime = Math.round(new Date().getTime() / 1000);
        this.customTemplates = (window.localStorage.getItem('custom_templates') !== null ? JSON.parse(window.localStorage.getItem('custom_templates')) : {});
        this.tryCount= 0;
        this.refreshTO='';
        this.dataRefreshPeriod= 30000; //Need to get socket going for this
        this.errorCount= 0;
        this.activateOffline = true;//flag to allow applying features even if devices are not talking
        this.offlineSetpointsToWrite = (window.localStorage.getItem('offlineSetpointsToWrite') !== null ? JSON.parse(window.localStorage.getItem('offlineSetpointsToWrite')) : {});
        this.dataIntervalSet= false;
        this.root = false;
        this.multiChannelDevices = {};
        this.phdOutputs = {};
        this.MCDOutputs = {};
        this.locations = {};
        this.devices = {};
        this.scenes = {};
        this.lastDataPacketTS = false;
        this.ambrs = {};
        this.readyForActions = 0;//scenes and devices have loaded (2) to avoid race condition
        this.deviceLookup = {};
        this.sceneLookup = {};
        this.device_groups = {};
        this.triggerLinkIDLookup = {};
        this.groups = {};
        this.data = [];
        this.info = {};
        this.networks = {};
        this.syncsCurrentlyRunning = 0;
        this.maxConcurrentSyncs = 3;
        this.mqtt = false;
        this.thisAmbr = false;
        this.mqttConnected = false;
        this.incomingDeviceData = {};
        this.mqttInitSuccess= false;
        this.deviceData = {};
        this.devicesToSave = [];
        this.senderObj = {};
        this.mqttTableUpdateInterval = {};
        this.MQTTStream = {};
        this.deviceDnameToInstanceID = {};
        this.u = user.email;
        this.p = user.mqtt_pass;
        this.h = 'mqtt.amatiscontrols.com';
        this.connectionRefreshTime = null; //this time is set in innit
        this.maxConnectRefreshTime = '300000'; //5 mins
        this.mqqtReconnectInterval = {}
        this.incomingDeviceDataObject = function(activeIP){
            ///// CONSTRUCTOR MODIFIED TO MATCH MY DEVICES /////
            let activeDevice = {};
            let activeDeviceIndex = Devices.getIndex(activeIP, 'ip');

            if (activeDeviceIndex >= 0) {
                activeDevice = Devices.list[activeDeviceIndex];
            } else {
                activeDevice.name = 'device_name';
                activeDevice.location = '';
            }

            this.ip = activeIP;
            this.name = activeDevice.name;
            this.description = 'device_description';
            this.location = activeDevice.location;
            this.data = [];
        };
        this.mqttAmbrTableSuscribe = false;
    }

    loadInfoFromStorage(){
        //Place holder for a function to load info from storage
        return false;
    }

    async sync(devicesToSave){
        return new Promise( async (resolve) =>{
            //Do we want to remove the red dot later? We need to handle this elsewhere if so
            if(App.hasOwnProperty('NavIcons')){
                App.NavIcons.setSyncNeeded(false);
            }

            //Reset all of the sync states no matter what when someone tries to sync
            for (let device of devicesToSave){
                device.sync_status = null;
            }

            let syncQueueManager = () => {
                let deviceToSync = false;
                let devicesThatDontNeedSync = 0;

                if (App.Site.syncsCurrentlyRunning < App.Site.maxConcurrentSyncs
                    && devicesToSave.length > 0
                ){
                    for(let activeDevice of devicesToSave){
                        //Look for a device in the list that isnt syncing, failed, or saved
                        if (activeDevice.sync_status === null
                            && activeDevice.unsaved === true
                        ){
                            deviceToSync = activeDevice;
                            break;
                        } else if (activeDevice.sync_status === 'exclamation-triangle'){
                            // In this case we know the sync has failed.
                            devicesThatDontNeedSync += 1;
                        }
                    }
                } else {
                    if (devicesToSave.length === 0){
                        resolve('success');
                    }
                    return;
                }

                //How does zero behave here?
                if(devicesThatDontNeedSync === devicesToSave.length){
                    // Scenes.getSceneTable();
                    resolve('success');
                }

                // If we are busy or done with all devices, we return here
                if(deviceToSync === false) {
                    if (devicesToSave.length === 0){
                        resolve('success');
                    }
                    return;
                }

                //Increment counter so that we dont do too many syncs at once
                App.Site.syncsCurrentlyRunning += 1;

                deviceToSync.setProps({sync_status : 'sync'});

                // Make the actual call to kick off the sync
                deviceToSync.get('save_table_list', deviceToSync.ip_address)
                    .then((result) => {
                        deviceToSync.setProps({
                            sync_status: null,
                            unsaved: false
                        });

                        App.alert('success',`Succesfully synced ${deviceToSync.name} (${deviceToSync.IP.substr(-4)})`);

                        //Remove this device from the list to sync
                        for (let index = 0; index < devicesToSave.length; index++){
                            if (devicesToSave[index].ID === deviceToSync.ID){
                                devicesToSave.splice(index, 1);
                            }
                        }
                    }).catch((err) => {
                        let secondaryText;
                        let syncStatus = 'exclamation-triangle';
                        //This needs to be refactored to use the SDK
                        if(Array.isArray(err) && err.length > 0 && err[0].includes('communicate')) {
                            secondaryText = 'There was a problem communicating with this device. Please try again';
                        } else if (err.hasOwnProperty('responseJSON') && err.responseJSON.errors.length){
                            secondaryText = err.responseJSON.errors.toString();
                            if (err.responseJSON.errors[0].includes('another')) {
                                syncStatus = 'sync-by-another-user';
                            }
                        } else {
                            secondaryText = 'Check that this device has the latest firmware';
                        }
                        //if index of = unable to commun w devices. show that
                        //else there was an issue with your evice)
                        App.alert('error',`Error Syncing ${deviceToSync.name} (${deviceToSync.IP.substr(-4)})`, secondaryText);
                        deviceToSync.setProps({
                            sync_status: syncStatus,
                            unsaved: true
                        });

                    }).finally(() => {
                        App.Site.syncsCurrentlyRunning -= 1;
                        syncQueueManager();
                    });

                // We call ourselves recursively here to fill the queue
                syncQueueManager();
            }

            // This is the first call to kick off the syncs
            syncQueueManager();
        });
    }
    secure(){
        if(App.hasOwnProperty('Site') && App.Site.hasOwnProperty('thisAmbr') && App.Site.thisAmbr !== false){
            App.Site.thisAmbr.secure();
        }else{
            window.alert("Ambr data still loading, try again in just a second...");
        }
    }
    //get sitewide data table per specified dataName over period of time (sinceAgo) in hours. Defaults to one day before app loaded, pass true for getcurrent to use current time instead of app load time
    getData(dataName,sinceAgo=false){
        let datapoint;
        if(this.deviceDnameToInstanceID.hasOwnProperty(dataName)){
            datapoint = this.deviceDnameToInstanceID[dataName];
        }else{
            console.log('ERROR: data instance ID not available for specified dataname or no dataname was specified');
            return;
        }
        sinceAgo = (sinceAgo === false ? 24 : sinceAgo);
        sinceAgo =  Math.round((new Date().getTime() / 1000) - (sinceAgo * 3600));
        $.ajax({
            type: 'GET',
            timeout: 10000,
            //datatype: 'json',
            url:  `${Communication.getBaseURL('v1')}/sites/${App.activeSiteID}/data?start=${sinceAgo}&datapoint_id=${datapoint}`,
            beforeSend: function (x) {
                if (x && x.overrideMimeType) {
                    x.overrideMimeType('application/json;charset=UTF-8');
                }
            },
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
            success: function (response) {
                if(response.hasOwnProperty('data')){
                    //data[dataName] = response.data;
                    console.log(response.data);
                }else{
                    console.log('ERROR: response has no data or response is not an object', response);
                }
            },
            error: function (e) {
                console.log('Api.get ERROR: ', e);
            }
        });
    }
    //if we havent received any data within the refresh period via mqtt, get live sinceAgo=refreshPeriod
    getLiveDataOnInterval () {
        let currentTime = new Date().getTime();
        if(App.Site.lastDataPacketTS === false
            || ((currentTime - App.Site.lastDataPacketTS) >= App.Site.dataRefreshPeriod)
        ){
            Devices.getLiveData(App.Site.dataRefreshPeriod / 1000);
        }
        App.Site.refreshTO = setTimeout(() => {
            App.Site.getLiveDataOnInterval();
        }, App.Site.dataRefreshPeriod);
    }

    init() {
        setTimeout(App.Site.getLiveDataOnInterval, 3000);//init interval;
        App.Site.notLocal = true; //TODO: Circle back on this later to make it work with local ambr
        this.connectionRefreshTime = 500;
        store.dispatch(setMqttStatus({ status: null, connectionRefreshTime: 500 }));
        App.Site.init_mqtt();
    }
    //MQTT INITIALIZATION
    init_mqtt() {
        // Amatis.client = new Paho.MQTT.Client(config.host.param, parseInt(config.port.param),'60');
        let clientID = `${new Date().getTime()}${User?.myUser?.id}`;
        Amatis.client = new Paho.Client(this.h, 443, clientID);
        // set callback handlers
        Amatis.client.onConnectionLost = this.mqtt_onConnectionLost;
        Amatis.client.onMessageArrived = this.mqtt_incomingDataHandler;

        var options = {
            useSSL: true,
            userName: this.u,
            password: this.p,
            cleanSession:true,
            onSuccess:this.mqtt_onConnect,
            onFailure:this.mqtt_onFail,
            reconnect: true,
        };
        // connect the client
        Amatis.client.connect(options);
        //MQTTREADOUT
        // App.Site.mqttStreamUpdateInterval = setInterval(()=>{App.Site.updateStreamState();}, 300);

    }
    //MQTT DATA HANDLING FUNCTIONS
    //called onmessage to condition the incoming data.
    mqtt_incomingDataHandler(message) {
        App.Site.lastDataPacketTS =  new Date().getTime();
        //MQTTREADOUT
        // App.Site.addMessageToStream(message);
        
        try{//this will be the first place any data coming in through the MQTT stream should hit
            if (message.topic.includes('info_obj')) {
                App.Site.generalInfoObjHandler(message.topic, message);
            }else if(message.topic.includes(`sites/${App.Site.ID}/api`)){
                App.Site.apiMessageHandler(message);
            } else if (message.topic.includes('ambr')){
                App.Site.ambrSpecificInfoObjHandler(message.topic, message);
            }else if(message.destinationName.indexOf(App.Site.ID) > 0){
                if( App.Site.mqttConnected === false
                    && message.payloadString === 'The App Logged in'
                ){
                    App.Site.mqttConnected = true;
                    return;
                }

                let obj = JSON.parse(message.payloadString);
                let timestamp = false;

                if(obj.hasOwnProperty('meta') && obj.meta.hasOwnProperty('ts')){
                    timestamp = obj.meta.ts;
                }

                if(obj.hasOwnProperty('data')){//otherwise we have data coming in, data handlers will often be handled by the class objects themselves so we will use the data router
                    App.Site.mqtt_data_router(obj.data, timestamp+62135596800);//to figure out what needs to be done based on what the data correlates to.
                }
            }else{
                return;
            }
        }catch(e){
            console.log(e);
        }

    }
    apiMessageHandler(message){
        //Strip out the header, so that we are left with the type of call
        let objectType = message.topic.replace(`sites/${App.Site.ID}/api/`, '');

        //Disect the payload
        let messageObj = App.Site.parseApiMessage(message.payloadString);

        if(messageObj === false) {
            return;
        }

        switch(messageObj.method){
            case 'POST':
            case 'PATCH':
                App.Site.upsert(objectType, messageObj.response.data);
                break;
            case 'DELETE':
                App.Site.destroy(objectType, messageObj.response.data);
                break;
            default:
                console.log(objectType, messageObj.method, message);
        }
    }
    parseApiMessage(payload){
        try{
            let obj = JSON.parse(payload);
            return obj;
        }catch(err){
            console.log('API MESSAGE PARSER:', err);
            return false;
        }
    }
    generalInfoObjHandler(topic, message){
        let obj = {};
        // console.log('general:', topic, message)
        // return;
        try{
            obj = JSON.parse(message.payloadString);
            // console.log(topic, obj)

            // Main.MQTTContainer.appendMessageToList(message);
        }catch(e){
            console.log(e);
        }
        if (obj !== {}){
            App.Site.updateTopicInfo(topic, obj);
            // $('#status-readout-table').html(obj.data.replace(/\n/g,'<br>').replace(/sites\//g, '&nbsp;&nbsp;&nbsp;&nbsp;sites/'));
            // console.log(obj.data);
        }
    }
    updateTopicInfo(topic, messageObj){
        // return;
        // console.log(messageObj);
        if (messageObj !== {}){
            // if(messageObj.hasOwnProperty('meta')){
                // if (messageObj.meta.hasOwnProperty('messages_per_minute')){
                    // Amatis.AppComponent.setMessagesPerMin(messageObj.meta.messages_per_minute);
                // }
            // }
            let siteID = '';
            let activeClient, sender;

            for(let client in messageObj.data){
                activeClient = messageObj.data[client];
                siteID = activeClient.site_id;
                sender = client;
                if(App.activeSiteID === siteID){
                    App.Site.updateSenderObj(topic, sender, siteID, activeClient, 'topic_info');
                }
            }
            // console.log(Amatis.senderObj);
            // window.localStorage.setItem('Amatis.senderObj', JSON.stringify(Amatis.senderObj));
            // $('#indiv-status-readout-table').html(`<pre>${JSON.stringify(Amatis.senderObj, undefined, 2)}</pre>`);
        }
    }
    ambrSpecificInfoObjHandler(topic, message){
        let messageObj = {};
        let lastPingHuman = '';
        let lastPingTS = '';
        try{
            messageObj = JSON.parse(message.payloadString);
            // Main.MQTTContainer.appendMessageToList(message);
        }catch(e){
            console.log(e);
        }

        let sender, siteID;
        const topicArr = topic.split('/');

        if(topicArr.hasOwnProperty(1)){
            siteID = topicArr[1];
        }else{
            siteID = '0000';
        }

        if (messageObj !== {}){
            if(messageObj.hasOwnProperty('meta')){
                if (messageObj.meta.hasOwnProperty('sender')){
                    sender =  messageObj.meta.sender;
                }

                //   if (messageObj.meta.hasOwnProperty('source')){
                //     let source = messageObj.meta.source;
                //   }

                //   if (messageObj.meta.hasOwnProperty('ts')){
                //     let lastPing = messageObj.meta.ts;
                //   }

                if (messageObj.meta.hasOwnProperty('ts_h')){
                    lastPingHuman = messageObj.meta.ts_h;
                }
                if (messageObj.meta.hasOwnProperty('ts')){
                    lastPingTS = messageObj.meta.ts;
                }
            }

            let tempObj = {
                tsh: lastPingHuman,
                ts: lastPingTS,
                data: messageObj.data.data
            };
            if (siteID === App.activeSiteID){
                App.Site.updateSenderObj(topic, sender, siteID, tempObj, 'general_info');
            }
        }
    }
    updateSenderObj(topic, sender, siteID, data, type){
        if(!App.Site.senderObj.hasOwnProperty(siteID)){
            App.Site.senderObj[siteID] = {};
        }

        if(!App.Site.senderObj[siteID].hasOwnProperty(sender)){
            App.Site.senderObj[siteID][sender] = {topic_info:'hey', general_info: {}};
        }

        App.Site.senderObj[siteID][sender][type] = data;
        // console.log(App.Site.senderObj);

        // Update data AMBR from mqtt message on redux
        if (topic.includes(`sites/${siteID}/ambr`) && this.mqttAmbrTableSuscribe === true){
            store.dispatch(updateAmbrFromMqtt(App.Site.senderObj));
        }
        window.localStorage.setItem('App.Site.senderObj', JSON.stringify(App.Site.senderObj));
    }
    verifyUserHasAccessToSite(siteID){
        if(!User.hasOwnProperty('mySites')){
            return false;
        }

        for(let site of User.mySites){
            if(site.siteid === siteID){
                // console.log('user has site')
                return site.dashname;
            }
        }
        return false;
    }

    updateTableState(){
        let rowsArray = [];
        let activeSender = '';
        let alertIconString = '';
        let statusString = '';
        let alertCount = 0;
        let clientCount = 0;

        //TODO: Need to use the real sites list. But where is it...
        // console.log(User.myUser)
        for(let site in App.Site.senderObj){
            let activeSite = App.Site.senderObj[site];
            let dashname = this.verifyUserHasAccessToSite(site);

            //User does not have access to this site
            if(dashname === false){
                continue;
            }

            for(let sender in activeSite){
                let topicArray = [];
                activeSender = activeSite[sender];
                alertIconString = '';
                statusString = '✅';
                clientCount++;
                // console.log(activeSender, site);
                //make a row object

                if(activeSender.topic_info.last_ping > (Amatis.delay_before_alert - 60)){
                    alertCount++;
                    alertIconString = '⚠️';
                    statusString = alertIconString;
                }

                if(!activeSender.topic_info.hasOwnProperty('topics')){
                    return;
                }


                let rowObj = {
                    name: dashname,
                    site_name: dashname,
                    site_id: site,
                    mac_address: sender,
                    active_topics: activeSender.topic_info.topics.length,
                    last_ping: `${alertIconString} (${activeSender.topic_info.last_ping}s)`,
                    routes: (activeSender.general_info.data && activeSender.general_info.data.routes) ? activeSender.general_info.data.routes : '-',
                    routes_w_fails: (activeSender.general_info.data && activeSender.general_info.data.routes_with_fails) ? activeSender.general_info.data.routes_with_fails : '-',
                    neighbors: (activeSender.general_info.data && activeSender.general_info.data.neighbors) ? activeSender.general_info.data.neighbors : '-',
                    version: (activeSender.general_info.data && activeSender.general_info.data.mqtt_worker) ? activeSender.general_info.data.mqtt_worker : '-',
                    tasks: [],
                    status: statusString,
                    activeSenderInfo: activeSender.general_info,
                };

                //Check for dangerous number of routes with fails
                if(rowObj.routes > 2 && (rowObj.routes_w_fails/rowObj.routes) > 0.4){
                    rowObj.routes_w_fails = rowObj.routes_w_fails + ' ⚠️';
                    alertCount++;
                }

                //Sort topics by last ping
                activeSender.topic_info.topics.sort((a,b) =>{
                    return a.last_ping - b.last_ping;
                });

                for (let topic of activeSender.topic_info.topics){
                    alertIconString = (topic.last_ping > (Amatis.delay_before_alert - 60)) ? '⚠️' : '' ;

                    let topicObj  = {
                        subject: topic.topic,
                        pt1: `(${topic.last_ping}s) ${alertIconString}`,
                        pt2: topic.last_ts
                    };
                    topicArray.push(topicObj);
                }
                rowObj.tasks = topicArray;

                rowsArray.push(rowObj);
            }
        }
        if(App.hasOwnProperty('MQTTTable')
            && App.MQTTTable.state.connectionStatus !== 'Paused'){
            App.MQTTTable.setState({rows:rowsArray, alertCount: alertCount, clientCount:clientCount});
        }

    }
    addMessageToStream(message){
        let topic = message.topic;
        let stream = App.Site.MQTTStream;
        let messageObj = {
            topics:message.topic,
            payload:message.payloadString,
            id:uuidv4(),
        };

        if(!stream.hasOwnProperty(topic)){
            stream[topic] = [messageObj];
        } else {
            stream[topic].push(messageObj);
        }
        // console.log(stream);
    }
    updateStreamState(){
        let rowsArray = [];
        let stream = App.Site.MQTTStream;
        let obj = false;

        for(let topic in stream){
            if(Object.hasOwnProperty.call(stream, topic)){
                let activeTopic = stream[topic];

                try{
                    obj = JSON.parse(activeTopic[activeTopic.length - 1].payload);
                } catch(e){} // eslint-disable-line

                let rowObj = {
                    site_name: 'Aspen HQ',
                    site_id: '867',
                    topic,
                    message: (obj !== false) ? <ReactJson collapsed={1} src={obj}/> : activeTopic[0].payload,
                };

                // //Sort topics by last ping
                // activeSender.topic_info.topics.sort((a,b) =>{
                //     return a.last_ping - b.last_ping;
                // });
                rowObj.messages = activeTopic;

                rowsArray.push(rowObj);
            }
        }

        if(App.hasOwnProperty('MQTTReadout')){
            // && App.MQTTTable.state.connectionStatus !== 'Paused'){
            App.MQTTReadout.updateStuff({rows:rowsArray});
        }

    }
    //function to handle data by specific type
    mqtt_data_router(data, timestamp){
        if(data.hasOwnProperty('device')){
            let device_ip = data.device.toString().replace('[', '').replace(']', '');
            if(App.Site.deviceLookup.hasOwnProperty(device_ip)){
                const device = App.Site.devices[App.Site.deviceLookup[device_ip]];
                device.update(data.data, timestamp);
                // const devices = [];
                // devices.push({
                //     deviceId: device.id,
                //     items: data.data,
                //     timestamp: timestamp,
                // });
                // store.dispatch(deviceUpdate(devices));
            }
        }
    }
    //MQTT UTILITY FUNCTIONS
    mqtt_onConnectionLost(responseObject) {
        if (responseObject.errorCode !== 0) {
            console.log('MQTT: CONNECTION LOST');
            console.log(responseObject);
            // store.dispatch(setMqttStatus({ status: 'disconnected' }));
            // App.Site.mqqtReconnectInterval = setTimeout(()=> {App.Site.reconnect();}, App.Site.connectionRefreshTime);
        }
        //App.MQTTContainer.setStates({connectionStatus:'disconnected'});
    }
    mqtt_onConnect(){
        // let siteID = this.ID;
        //let siteID = '000474166569';
        //App.MQTTContainer.setStates({connectionStatus:'connected'});
        // Once a connection has been made, make a subscription and send a message
        this.connectionRefreshTime = 500;
        store.dispatch(setMqttStatus({ status: 'connected', connectionRefreshTime: 500 }));
        console.log('Connected to sites/'+App.Site.ID);
        App.Site.mqttInitSuccess = true;
        Amatis.client.subscribe('sites/'+App.Site.ID+'/#');
        Amatis.client.publish('/sites/' + App.Site.ID + '/message/', 'The App Logged in');
    }
    mqtt_infoObjStart(){
        if(Amatis.hasOwnProperty('client') && Amatis.client.isConnected()){
            Amatis.client.subscribe('sites/0000/info_obj');
            // setTimeout(()=>{Amatis.Site.mqtt_infoObjStop(); console.log('in here')}, 2000)
            Amatis.client.subscribe('sites/+/ambr/+/info');
            App.Site.mqttTableUpdateInterval = setInterval(()=>{App.Site.updateTableState();}, 300);
        } else {
            setTimeout(() => {App.Site.mqtt_infoObjStart();}, 500);
        }
    }
    mqtt_infoAmbrTableSubscribe(){
        if (Amatis.client.isConnected()) {
            Amatis.client.unsubscribe('sites/'+App.Site.ID+'/#');
        }
        if(Amatis.hasOwnProperty('client') && Amatis.client.isConnected()){
            Amatis.client.subscribe('sites/0000/info_obj');
            Amatis.client.subscribe(`sites/${App.Site.ID}/ambr/+/info`);
            this.mqttAmbrTableSuscribe = true;
            App.Site.mqttTableUpdateInterval = setInterval(()=>{App.Site.updateTableState();}, 300);
        } else {
            if(App.Site.hasOwnProperty('mqtt_infoAmbrTableSubscribe')) {
                setTimeout(() => {App.Site.mqtt_infoAmbrTableSubscribe();}, 500);
            }
        }
    }
    mqtt_infoAmbrTableUnSubscribe(){
        if (Amatis.client.isConnected()) {
            Amatis.client.unsubscribe('sites/0000/info_obj');
            Amatis.client.unsubscribe(`sites/${App.Site.ID}/ambr/+/info`);
            this.mqttAmbrTableSuscribe = false;
        }
    }
    mqtt_unsubscribeFromTopic(){
        if (Amatis.client.isConnected()) {
            Amatis.client.unsubscribe('sites/'+App.Site.ID+'/data');
        }
    }
    mqtt_infoObjStop(){
        console.log('UNMOUNTING MQTT TABLE')
        if(Amatis.hasOwnProperty('client') && Amatis.client.isConnected()){
            Amatis.client.unsubscribe('sites/0000/info_obj');
            Amatis.client.unsubscribe('sites/+/ambr/+/info');
        }

        clearInterval(App.Site.mqttTableUpdateInterval);
    }
    mqttResetPass(){
        if (localStorage.getItem('amatis_user') !== null) {
            const storage = window.localStorage;
            let User = JSON.parse(localStorage.getItem('amatis_user'));
            const requestBody = {mqtt_pass: User.mqtt_pass};
            const OPTIONS_SDK = { serverVariables: { baseVersion: "v3" }, requestBody };
            SDK.init().then(api => api.users.getNewMqttPass({}, OPTIONS_SDK))
            .then(({ status, body }) => {
                if(status === 200){
                    this.p = body.data.mqtt_pass;
                    storage.setItem('amatis_user', JSON.stringify({...User, mqtt_pass: body.data.mqtt_pass}));
                    this.init_mqtt();
                }
            })
            .catch(({ response }) => {
                if (response.status === 401) console.log("Error response mqtt new pass 401")
                ;
                if (response.body) {
                    console.log("Error response mqtt new pass ", response.body)
                }
            })
        }
    }
    mqtt_onFail(e){
        console.log('MQTT FAILED TO CONNECT');
        console.log(e);
        store.dispatch(setMqttStatus({ status: 'disconnected' }));
        if (e.errorMessage.includes('not authorized')) {
            store.dispatch(
                setMqttStatus({
                    status: 'resetting_password',
                    connectionRefreshTime: this.connectionRefreshTime
                })
            );
            App.Site.mqttResetPass();
        }
        if(this.connectionRefreshTime >= this.maxConnectRefreshTime) {
            const message = `:electric_plug: MQTT error ${e.errorMessage}`;
            App.Site.post({ message }, 'slack-log','devices', 'v2').catch((err)=>{
                console.log('issue sending off to slack', err);
            });
            // Commented message mqtt error, requested in history 179888345
            // App.alert('error',`MQTT error: failed to connect`, e.errorMessage);
        }
        App.Site.mqqtReconnectInterval = setTimeout(()=> {App.Site.reconnect();}, App.Site.connectionRefreshTime);
    }
    mqtt_sendMessage(topicString, messageString){
        let dataObj = {
            url: messageString,
            method: 'GET',
        };

        let messageObj = {
            'meta': {
                'sender': '000000000',
                'data_type': 'JSON',
                'ts': Math.floor(new Date() / 1000),
                'id': uuidv4(),
                'schema': 'mqtt_v1'
            },
            'data': dataObj,
            'errors': []
        };

        console.log(topicString, messageObj);
        let message = new Paho.Message(JSON.stringify(messageObj));
        message.destinationName = topicString;
        Amatis.client.send(message);
        console.log(message);
    }
    //Close the socket
    quit() {
        if (this.socket !== null) {
            this.socket.close();
            this.socket = null;
        }
    }
    //Close then reconnect TODO:make sure this is still relevant
    reconnect() {
        //don't set highter than max time
        if(this.connectionRefreshTime <= this.maxConnectRefreshTime){
            store.dispatch(
                setMqttStatus({
                    status: 'reconnecting',
                    connectionRefreshTime: this.connectionRefreshTime * 2
                })
            );
            this.connectionRefreshTime = this.connectionRefreshTime * 2;
        } else {
            store.dispatch(setMqttStatus({ status: 'exceeded' }));
        }
        // this.quit();
        // this.init_mqtt();
    }
}

export {
    ActiveSite
};
