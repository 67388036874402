import { createActions, createAction } from 'redux-actions';
import {
    LIST_DATAPOINTS,
    RETRIEVE_DATAPOINT,
    UPDATE_DATAPOINT,
    PROCESS_DATAPOINT,
    CLEAN_INFLUX,
    DELETE_DATAPOINT,
    INFLUX_DATAPOINT,
    ADD_DATAPOINT,
    PAGINATION_DATAPOINT,
    TABLE_DATAPOINT,
    CLEAR_NEW_DATAPOINTS,
    SET_FILTERS_FROM_QUERY_PARAMS,
} from './types';

const helperTypes = {
    REQUEST: undefined,
    SUCCESS: undefined,
    FAILURE: undefined,
    FULFILL: undefined,
};

export const setFiltersFromQueryParamsOnDatapoint = createAction(SET_FILTERS_FROM_QUERY_PARAMS);

const actions = createActions({
    [LIST_DATAPOINTS]: helperTypes,
    [RETRIEVE_DATAPOINT]: helperTypes,
    [UPDATE_DATAPOINT]: helperTypes,
    [DELETE_DATAPOINT]: helperTypes,
    [INFLUX_DATAPOINT]: helperTypes,
    [PROCESS_DATAPOINT]: undefined,
    [CLEAN_INFLUX]: undefined,
    [CLEAR_NEW_DATAPOINTS]: undefined,
    [ADD_DATAPOINT]: undefined,
    [PAGINATION_DATAPOINT]: {
        CHANGE_NAVIGATION: undefined,
        PAGE_SIZE: undefined,
    },
    [TABLE_DATAPOINT] : {
        SORTING: undefined,
        GROUPING: undefined,
        SELECTION: undefined,
        FILTERS: undefined,
        EXPANDED_GROUPS: undefined,
        EXPANDED_ROW_IDS: undefined,
        SEARCH: undefined,
        RESET_FILTERS: undefined,
        RESET_SELECTION: undefined,
    }
});

export const {
    listDatapoints,
    updateDatapoint,
    deleteDatapoint,
    retrieveDatapoint,
    processDatapoint,
    cleanInflux,
    addDatapoint,
    tableDatapoint,
    paginationDatapoint,
    clearNewDatapoints,
    influxDatapoint,
} = actions;
