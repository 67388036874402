import {decToHex} from 'classes/methods.js'

const zoneLookup = { 1: 'a', 2: 'b', 3: 'c', 4: 'd', 5: 'e', 6: 'f', 7: 'g', 8: 'h' };

const phd_calculateLevelFade = (level, fade) => {
    return `00${level.toString(16)}`.slice(-2) + `00${fade.toString(16)}`.slice(-2);
}

const phd_combineZoneAndChannelMask = (zoneMask, chanMask) => {
    return (('00' + zoneMask.toString(16)).slice(-2)) + (('0000' + chanMask.toString(16)).slice(-4));
}

const phd_combineZoneChannelLevelFade = (zoneMask, chanMask, level, fade) => {
    if (!isNaN(level) && !isNaN(fade)) {
        return `0x${phd_combineZoneAndChannelMask(zoneMask, chanMask)}${phd_calculateLevelFade(level, fade)}`
    } else {
        return `0x${phd_combineZoneAndChannelMask(zoneMask, chanMask)}`
    }
}

export const reverseChansZonesFadeLevel = (var_1) => {
    let hexVar1 = decToHex(var_1).replace('0x', '');
    const allZones = [];
    const allChannels = [];
    let Arry = [];
    let numberOfChannels = 16;
    let zoneDisplayArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    let numberOfZones = 8;
    let str = hexVar1;
    let duty = '';

    let returnFade = parseInt(hexVar1.slice(-2), 16);
    str = str.substring(0, str.length - 2);
    if (str.length === 0){
        duty = '0';
    } else if (str.length === 2) {
        duty = str.slice(-1);
        str = str.substring(0, str.length - 1);
    } else {
        duty = str.slice(-2);
        str = str.substring(0, str.length - 2);
    }
    let returnLevel = parseInt(duty, 16);
    let chanMap = '0x' + str.slice(-4);

    str = str.substring(0, str.length - 4);

    let zoneMap = '0x' + str.slice(-2);

    for (let index = 0; index < numberOfChannels; index++) {
        if ((chanMap >> index) & 0x01)
            Arry.push('1');
        else
            Arry.push('ign');
    }
    //parse off arry
    for (let chan in Arry) {

        if (Arry[chan] === '1' && Arry[parseInt(chan)] !== Arry[(parseInt(chan) + 1)]) {
            allChannels.push(parseInt(chan) + 1);
        } else if (Arry[chan] === '1' && chan < 15 && Arry[parseInt(chan)] === Arry[(parseInt(chan) + 1)]) {
            allChannels.push(parseInt(chan) + 1);
        }
    }

    Arry = [];
    for (let index = 0; index < numberOfZones; index++) {
        if ((zoneMap >> index) & 0x01)
            Arry.push('1');
        else
            Arry.push('ign');
    }

    for (let chan in Arry) {
        if (Arry[chan] === '1' && Arry[parseInt(chan)] !== Arry[(parseInt(chan) + 1)]) {
            //displays A-H for zones
            allZones.push(zoneDisplayArr[chan].toLowerCase());

        } else if (Arry[chan] === '1' && chan < 7 && Arry[parseInt(chan)] === Arry[(parseInt(chan) + 1)]) {
            //displays A-H for zones
            allZones.push(zoneDisplayArr[chan].toLowerCase());
        }
    }

    return {
        zones: allZones,
        channels: allChannels,
        level: returnLevel,
        fade: returnFade,
    };
}

export const reverseFadeLevel = (var1) => {
    let var_converted = var1.toString();
    let level = ((`0000${var_converted}`).slice((-6))).slice(0,3);
    let fade = var_converted.slice(-3);
    return {
        level: parseInt((`000${level}`).slice(-3)),
        fade: parseInt((`000${fade}`).slice(-3)),
        zones: [],
        channels: []
    }
}

export const reverseMappedChannels = (var1) => {
    let allChannels = []
    let channelParse = parseInt(var1)
    for (let index = 0; index < 17; index++) {
        if( channelParse % 2 === 1) allChannels.push(index+1);
        channelParse = channelParse >> 1;
    }
    return {
        zones: [],
        channels: allChannels,
        level: null,
        fade: null,
    };
}

export const reverseHarvestDaylight = (var1) => {
    let var_1 = var1.toString();
    let hexVar1 = decToHex(var_1).replace('0x', '');

    let str = hexVar1;
    let chanMap = '0x' + str.slice(-4);
    str = str.substring(0, str.length - 4);
    let zoneMap = '0x' + str.slice(-2);
    let zoneParse = parseInt(zoneMap,16)
    let channelParse = parseInt(chanMap,16)

    let allChannels = []
    let allZones = []

    for (let index = 0; index < 17; index++) {
        if( zoneParse % 2 === 1) allZones.push(zoneLookup[index+1]);
        zoneParse = zoneParse >> 1;

        if( channelParse % 2 === 1) allChannels.push(index+1);
        channelParse = channelParse >> 1;
    }
    return {
        zones: allZones,
        channels: allChannels,
        level: null,
        fade: null,
    };
}

export const reverseSetLoadShed = (var1) => {
    let hexNumber = parseInt(var1).toString(16);

    let hexNumberToZonesAndChannles = '0000'+hexNumber;

    hexNumber = '0'+hexNumber;

    let hexNumberToZAndC = hexNumberToZonesAndChannles.slice(0,-4);
    let zonesAndChannels = hexNumberToZAndC.slice(-6);
    let zoneReturn = zonesAndChannels.slice(0,2);
    let channelReturn = zonesAndChannels.slice(-4);

    let zoneParse = parseInt(zoneReturn,16)
    let channelParse = parseInt(channelReturn,16)
    const allZones = []
    const allChannels = []

    for (let index = 0; index < 17; index++) {
        if( zoneParse % 2 === 1) allZones.push(zoneLookup[index+1]);
        zoneParse = zoneParse >> 1;

        if( channelParse % 2 === 1) allChannels.push(index+1);
        channelParse = channelParse >> 1;
    }
    return {
        zones: allZones,
        channels: allChannels,
        level: null,
        fade: null,
    };
}

export const setLoadShed = (zonesIn, channelsIn) => {
    let zones = new Set(zonesIn);
    let channels = new Set(channelsIn);
    let zoneMask = 0;
    let chanMask = 0;
    for (let index = 0; index < 16; index++) {
        const outputNumber = index + 1;
        zoneMask = (zones.has(zoneLookup[outputNumber])) ? zoneMask = zoneMask | (1 << index) : zoneMask;
        chanMask = (channels.has(outputNumber)) ? chanMask |= 1 << index : chanMask;
    }
    //constant 1100100000000010
    let var1 = `00000000${zoneMask.toString(2)}`.slice(-8) + `0000000000000000${chanMask.toString(2)}`.slice(-16) + '1100100000000010';
    return parseInt(var1, 2);
}

export const fadeLevelCalculate = (level, fade) => {
    return parseInt(`0000${level.toString()}`.slice(-3) + `0000${fade.toString()}`.slice(-3));
}

export const calculatorVar1Phd = (zonesIn = [], channelsIn = [], levelIn = null, fadeIn = null) => {
    let zones = new Set(zonesIn);
    let channels = new Set(channelsIn);
    let level = parseInt(levelIn);
    let fade = parseInt(fadeIn);
    let zoneMask = 0;
    let chanMask = 0;
    //shift 1 left index times and OR it with chan17_mask_on to set that bit in chan17_mask_on
    for (let index = 0; index < 17; index++) {
      const outputNumber = index + 1;
      zoneMask = (zones.has(zoneLookup[outputNumber])) ? zoneMask = zoneMask | (1 << index) : zoneMask;
      chanMask = (channels.has(outputNumber)) ? chanMask |= 1 << index : chanMask;
    }

    const hexVar1 = phd_combineZoneChannelLevelFade(zoneMask, chanMask, level, fade);
    const decimalVar1 = parseInt(hexVar1);

    return decimalVar1;
}

export const reverseCalculateVar1DelayScene = (var1) => {
    const linkID = var1 & 0xFFF;
    const timer = (var1 >> 16) & 0xFFFF;
    const mcast = (var1 >> 47) & 0x1;
    return { linkID, timer, mcast };
}