import type { Issue, Location, ValidatorKey } from "../types";
import { normilzeIssue } from "../utils/IssuesNormalizer";

export const validatorActionTables = (location: Location, validatorKey: ValidatorKey) => {
    const actionTablesIssues: Issue[] = [];
    const devices = location.devices;

    for (let index in devices) {
        const device = devices[index];
        const internalActions = devices[index].internalActions;
        if (!device.deviceShouldReportData) continue;
        if (!Object.keys(internalActions).length) {
            actionTablesIssues.push(
                normilzeIssue({
                    name: device.nameWithLastFour,
                    identifierId: index,
                    errorMessage: `${device.nameWithLastFour} do not have action tables`,
                    locationName: location.name,
                    validatorKey: validatorKey,
                    type: "missing",
                    category: "actionTables"
                })
            )
            continue;
        }
        let actionTablesLookUp = [3, 4, 5];
        for (let index in internalActions) {
            actionTablesLookUp = actionTablesLookUp.filter(item => item !== internalActions[index].link_id)
        }
        for (let i in actionTablesLookUp) {
            actionTablesIssues.push(
                normilzeIssue({
                    name: device.nameWithLastFour,
                    identifierId: index,
                    errorMessage: `${device.nameWithLastFour} do not have action tables with linkId ${actionTablesLookUp[i]}`,
                    locationName: location.name,
                    validatorKey: validatorKey,
                    type: "missing",
                    category: "actionTables"
                })
            )
        }
    }
    return actionTablesIssues;
}
