import { scenesSearch } from './actions';

export function searchScenesThunks(searchSceneValue, useDebounce = true) {

    const thunk = dispatch => {
        return dispatch(scenesSearch(searchSceneValue));
    };
    if(useDebounce === true){
        thunk.meta = {
            debounce: {
                time: 300,
                key: 'SCENES_SEARCH/TRIGGER'
            }
        };
    }
    return thunk;
}