import { createActions } from 'redux-actions';
import { SET_USERS_CHARTS, SAVE_USER_CHARTS, SET_CHART, SET_CHART_TYPE, REMOVE_USER_CHART } from './types';

const helperTypes = {
  REQUEST: null,
  SUCCESS: null,
  FAILURE: null,
  FULFILL: null,
};

export const actions = createActions({
  [SET_USERS_CHARTS]: helperTypes,
  [SAVE_USER_CHARTS]: helperTypes,
  [SET_CHART]: null,
  [SET_CHART_TYPE]: null,
  [REMOVE_USER_CHART]: helperTypes,
});


export const { setUsersCharts, saveUserCharts, setChart, setChartType, removeUserChart } = actions;