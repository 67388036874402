import {
    SITE_LOAD_REQUEST,
    SITE_LOAD_FAILURE,
    SITE_LOAD_FULFILL,
    SET_LOCATION_ID,
    SITE_LOAD_SUCCESS,
    PATCH_SITE_SUCCESS,
    TOGGLE_BOX_DEVICE_AND_SCENE,
    SET_MQTT_STATUS,
    SET_GLOBAL_TOGGLE_BOX_DEVICE_AND_SCENE,
    METRICS_REQUEST,
    METRICS_SUCCESS,
    METRICS_FAILURE,
    METRICS_FULFILL,
    METRICS_CLEAN,
} from './actions';

const initialState = {
    error: null,
    loading: true,
    locationId: null,
    mqtt: {
        status: null,
        connectionRefreshTime: null,
        maxConnectRefreshTime: 300000,
    },
    item: {},
    dashboard: {
        deviceAndScenes: false,
    },
    toggleGlobalDeviceAndScene: null,
    metrics: {
        items: [],
        error: null,
        loading: false,
    },
};

const handleRequest = state => Object.assign({}, state, { loading: true, error: null });
const handleFailure = (state, { payload }) => Object.assign({}, state, { error: payload });
const handleFulfill = state => Object.assign({}, state, { loading: false });
const handleLocationId = (state, { payload }) => Object.assign({}, state, { locationId: payload });
const handleSuccess =  (state, { payload }) => {
    const item = { ...state.item, ...payload };
    return Object.assign(
        {}, state, { item: item }
    )
};

const handleToggleBoxDeviceAndScene = (state, { payload }) => Object.assign({}, state, { dashboard: { deviceAndScenes: payload}});

const handleMqttStatus = (state, { payload }) => {
    return Object.assign({}, state, { mqtt: Object.assign({}, state.mqtt, payload)});
}

const handleGlobalToggleDeviceAndScene = (state, { payload }) => Object.assign({}, state, { toggleGlobalDeviceAndScene: payload});

const handleRequestMetrics = state => ({...state, metrics: { ...state.metrics, loading: true }});
const handleSetMetrics = (state, { payload }) => {
    const items = [...state.metrics.items];

    payload.forEach(payloadItem => {
        const index = items.findIndex(item => item.id === payloadItem.id);
        index >= 0 ? items[index] = payloadItem : items.push(payloadItem);
    });

    return {...state, metrics: { ...state.metrics, items: [...items] }};
};
const handleFailureMetrics = (state, { payload }) => ({...state, metrics: { ...state.metrics, error: payload }});
const handleFulfillMetrics = state => ({...state, metrics: { ...state.metrics, loading: false }});
const handleCleanMetrics = state => ({...state, metrics: { ...state.metrics, items:[], loading: true }});

const handlers = {
    [SITE_LOAD_REQUEST]: handleRequest,
    [SITE_LOAD_FAILURE]: handleFailure,
    [SITE_LOAD_FULFILL]: handleFulfill,
    [SET_LOCATION_ID]: handleLocationId,
    [SITE_LOAD_SUCCESS]: handleSuccess,
    [PATCH_SITE_SUCCESS]: handleSuccess,
    [TOGGLE_BOX_DEVICE_AND_SCENE]: handleToggleBoxDeviceAndScene,
    [SET_MQTT_STATUS]: handleMqttStatus,
    [SET_GLOBAL_TOGGLE_BOX_DEVICE_AND_SCENE]: handleGlobalToggleDeviceAndScene,
    [METRICS_REQUEST]: handleRequestMetrics,
    [METRICS_SUCCESS]: handleSetMetrics,
    [METRICS_FAILURE]: handleFailureMetrics,
    [METRICS_FULFILL]: handleFulfillMetrics,
    [METRICS_CLEAN]: handleCleanMetrics,
};

const reducer = (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
