/* eslint-disable no-unused-vars*/
import $ from 'jquery';
import Communication from 'api/amatis/utilities/communications';
import App from 'app/app';
import Devices from 'api/amatis/site/devices/services/devices';
import { Locations } from  'api/amatis/site/locations/services/locations';
import {decToHex} from 'classes/methods';
import User from 'classes/users';
import {Template} from 'app/templates';
import {validateOnInit, validate} from './validate';
import displayPretty from './display-pretty';
import uuidv4 from 'uuid/v4';
import Scene from 'api/amatis/site/scenes/Scene';
import store from 'store';
import { logOut } from 'redux/auth';
import SDKV3 from 'api/sdkV3';
import { addSceneSuccess } from 'redux/scenes/actions';

let Scenes = {
    list: [],
    oldSearchString: '',
    maxChar: 20,
    maxCharWithSettings: 17,
    activeCalls: 0,
    displayIndex: 0,
    indexArray: [],
    pathSceneList: [],
    validate: validate,
    validateOnInit: validateOnInit,
    displayPretty: displayPretty,
    maxConcurrentScenesCalls: 3,
    lastCallRequest: {
        linkIDToCall: null,
        locIndex: null
    },
    nav_html: '',
    lastCallQueue: [],
    activeIndex: 0,
    table: '',
    savingDeviceActions: false,
    didQueueASaveRequest: false,
    recentlySavedDevices: [],
    deletedTriggers: 0,
    deletedActions: 0,
    ghostScenes: [],
    highestLinkID:255,
    ghostScenesExist: false,
    lightLevelCallPending: false,
    lightLevelCaller: null,
    lastDelete:false,
    checkAssociatedActionData(scene, actionFunction){
        //if we have an action function that correlates to a scene setting, we will parse out the var1 so we can set the respective value in the location features settings
        if(actionFunction.name.indexOf('Duty(%,Fade)')>=0 || actionFunction.name.indexOf('(mask,%,fade') >= 0){
            let lightLevel, location = scene.location, var1 = actionFunction.var_1, fadeRate, duty, setInChild=false, device;
            if(location === false){
                return;
            }
            if(App.Site.deviceLookup.hasOwnProperty(actionFunction.ip_address)){

                device = App.Site.devices[App.Site.deviceLookup[actionFunction.ip_address]];
                if((device.partType === 'AM-PHD' || device.partType === 'AM-PSM') && location.isParent){
                    setInChild = Devices.phd.getAssocChannelLocationFromAction(device, actionFunction);
                }
                else if(location.isParent && device.location !== false && device.location.id !== location.id){
                    setInChild = device.location;
                }
            }
            if(actionFunction.name.indexOf('mask') >= 0){//phd so we need to use different math to get the duty/fade
                let hexVar1 = decToHex(var1).replace('0x', '');
                fadeRate = parseInt(hexVar1.substr(-2), 16);
                hexVar1 = hexVar1.substring(0, hexVar1.length - 2);
                if (hexVar1.length === 2) {
                    duty = hexVar1.substr(-1);
                } else {
                    duty = hexVar1.substr(-2);
                }
                lightLevel = parseInt(duty, 16);
            }else{
                lightLevel = parseInt(var1 / 1000);
                lightLevel = (lightLevel > 100) ? 100 : lightLevel;
                fadeRate = var1 && parseInt(var1.toString().substr(-3), 10);
            }
            location.assignAssocActionDataToFeatures(scene, lightLevel, fadeRate);
            if(setInChild !== false){
                setInChild.assignAssocActionDataToFeatures(scene,lightLevel, fadeRate, false);
            }
        }
        else if(actionFunction.name === 'coap_send_action(linkID)' && App.Site.deviceLookup.hasOwnProperty(actionFunction.ip_address)){
            let device = App.Site.devices[App.Site.deviceLookup[actionFunction.ip_address]];
            //if sw8 is trigger in motion group or any occupancy scene we know its a dual tech sw8
            if(device.partType === 'AM-SW8' && (scene.description === 'motion_group' || scene.description.indexOf('occupied') >= 0)){
                device.dualTech = true;
            }
        }
    },
    emptyListHandler(){
        // On sites with no scenes yet, we still want to get the device action tables
        Scenes.getSceneTable();
    },
    async create(infoObject=false, fromCloud=false){
        let scene = {};
        //TODO: [CON-800] Allow this to handle multiple for later. Also migrate all scene creation to this function
        if(fromCloud && infoObject){
            store.dispatch(addSceneSuccess(infoObject));
            const newObject = new Scene(infoObject);

            //We set the name of the new scene to editable by default
            newObject.nameIsEditable = true;
            newObject.updateUIComponents();
        } else {
            let newInfoToSend = {
                id: uuidv4(),
                name: 'New Scene'
            };

            //Update any params that were passed in
            for(let param in infoObject){
                if(newInfoToSend.hasOwnProperty(param)){
                    newInfoToSend[param] = infoObject[param];
                }
            }

            App.Site.post(scene,false, 'scenes');
        }

    },
    writeLinkID: function (var1){
        let hexVar1 = decToHex(var1);
        let deviceMacAddy = hexVar1.substr(-12).toUpperCase(); //Last 12 Chars is the mac
        let hexLinkID = hexVar1.slice(0, -12); //the rest is the linkID
        let linkID = parseInt(hexLinkID);
    },
    getSceneByDescription(scenes, lookFor){
        let scene, activeScene = false;
        for(let id in scenes){
            scene = scenes[id];
            if(scene.description === lookFor){
                activeScene = scene;
                break;
            }
        }
        return activeScene;
    },

    /* jshint ignore:start */
    functionGenerator: async function (device, linkID, functionName, var1, location=false, realLinkID = false, outputMask=false, sceneID) {
        let magic = '65535'; //cloud will put it in the next available row
        let lastScene, i;
        let actionTableName;
        //gets ready for making a new scene
        let deviceId = device.ip_address;
        location = (location === false ? App.ActiveLocation : location);
        /*let customName = device.name;
        let customSceneName = '';
        let scene;
        if (var1 === 'newScene' || linkID === 'newScene') {
            scene = false;
        } else {
            if (App.Site.scenes.hasOwnProperty(parseInt(var1))) {
                scene = App.Site.scenes[parseInt(var1)];
            }else if(App.Site.scenes.hasOwnProperty(parseInt(linkID))){
                scene = App.Site.scenes[parseInt(linkID)];
            }
        }
        if (sceneName)
            customSceneName = sceneName;
        */
        let partType = device.partType;
        if (functionName === 'trigger') { //true if a trigger
            actionTableName = 'coap_send_action(linkID)';
            linkID = linkID.replace('INT.','');//lookup the triggers linkID in the table by name
            //if were doing dual tech sw8 motion action we treat sw8 like ml until we have the triggerlinkid lookup table corrected for sw8
            if(partType === 'AM-SW8' && device.dualTech === true){
                partType = 'AM-SW8-DT';
            }
            if(partType === 'AM-SW8'){
                linkID = Template.getSw8LinkID(linkID);
            }else{
                for(let id in App.Site.triggerLinkIDLookup[partType]){
                    // eslint-disable-next-line
                    if(App.Site.triggerLinkIDLookup[partType][id] == linkID){
                        linkID = parseInt(id);
                    }
                }
            }
        } else {
            actionTableName = functionName;
            //this.link = parseInt(this.link)+255;
        }
        //handles newScene for triggers
        if (var1 === 'newScene') {
            linkID = ++Scenes.highestLinkID;
        }

        //Verify that the device action table isnt corrupted BEFORE we put the new action in there: AA: handled somehwere else but leaving this cause we may want to handle it
        //for single scenes in the future
        /* if (device.type !== 'Tny' || device.type !== 'Tiny') {
             if (Object.keys(device.internalActions).length < 1) {
                 //We still set the scenes/unsaved flags so that Scenes.save knows to alert the user about these...
                 device.unsaved = true;
                 device.scenesFlag = true;
                 return false; //TODO: handle this return somewhere
             }
         }*/
        let tempObject = {
            id: sceneID,
            ip_address: deviceId,
            table_row: magic,
            //linkID: linkID.toString(),
            link_id:linkID,
            flag: magic,
            flag_mask: magic,
            var_1: var1,
            //actionFunction: magic,
            saved: User.myUser.id,
            name: actionTableName,
            model: partType,
        };
        let identifier = deviceId.substr(-4)+'-';
        //device.externalActionsToSave.push(tempObject);
        if(functionName === 'trigger'){//case that this is a trigger, we need to add the external actions to the buildings scene by its var1 instead
            if(partType !== 'AM-SW8'){
                linkID = var1;
            }
            identifier += linkID.toString()+'t';
        }else if(functionName === 'MAC Action(0xLinkID, 0xMAC)'){
            identifier += var1.toString() +'-'+linkID.toString();
            linkID = realLinkID;
        }else if(outputMask !== false){
            identifier += linkID.toString()+'-'+outputMask;
        }else{
            identifier += linkID.toString();
        }
        device.unsaved = true;
        if(location.buildingScenes.hasOwnProperty(linkID)){
            location.buildingScenes[linkID].externalActions.push(tempObject);
        }
        //save actions for later in a way that we can iterate over them incase the user untoggles the feature after toggling it before saving
        location.templateActions[identifier] = tempObject;
        //add the device to location to be saved list
        if(!location.devicesToSave.has(device.id))
            location.devicesToSave.add(device.id);

    },
    /* jshint ignore:end */

    hasLocationLinkTablesLoaded: function () {
        if (App.visitedLocations.has(App.ActiveLocation.ID) || App.ActiveLocation.isRoot) {
            return true
        }
        window.alert('Link tables for this location have not loaded yet, please try again.')
        return false
    },

    requestingLinktablesByLocationIDForRoot: function (payload) {
        if (payload.location_id === App.ActiveLocation.ID && App.ActiveLocation.isRoot){
            if (payload.link_id){
                return true;
            } else {
                return false;
            }
        }
        return true;
    },

    shouldGetLinkTables: function (payload) {
        if (
            !payload.location_id ||
            !this.requestingLinktablesByLocationIDForRoot(payload) ||
            App.visitedLocations.has(payload.location_id)
        ) {
            return false;
        }
        return true;
    },

    getLinkTablesPayload: function (params) {
        const payload = {
            location_id: params.location ? params.location : App.ActiveLocation.ID,
        };
        if (params.linkId){
            payload.link_id = params.linkId;
        }
        if (params.ip_address) {
            payload.ip_address = `in:${params.ip_address.join()}`
            delete payload.location_id;
        } else if (params.deleted) {
            payload.deleted = '1'
            delete payload.location_id;
        } else if (!Scenes.shouldGetLinkTables(payload)) return {};
        return payload;
    },

    getSceneTable: async function (params = {}) {
        const payload = Scenes.getLinkTablesPayload(params);
        if (Object.keys(payload).length === 0) return;

        try {
            const api = await SDKV3.init();
            const { body: { errors, meta, data } } = await api.linkTables.getLinkTables({ site_id: App.activeSiteID, limit: 30000, linktables_meta: '1', user_info: '1', ...payload });
            if (errors.length) {
                App.alert('error', 'There was a problem getting the info for this location. Please try again');
                return;
            }
            Devices.parseActions(data);
            if(meta?.locations?.length) {
                meta.locations.forEach(element => {
                    App.visitedLocations.add(element)
                    App.Site.locations[element].linksTablesLoaded = true;
                    if(App.hasOwnProperty('PageContent')){
                        App.PageContent.setLocationList(Locations.list, true);
                    }
                })
            }
        } catch (error) {
            App.alert('error', 'There was a problem getting the info for this location. Please try again');
        } finally {
            // NOTE: Stop the linksTablesLoaded of the activelocation in case of the link tables is empty
            App.ActiveLocation.linksTablesLoaded = true;
        }
    },

    getIndex: function (searchParam, paramType) {
        switch (paramType) {
            case 'linkID':
                for (let sceneIndex = Scenes.list.length - 1; sceneIndex >= 0; sceneIndex--) {
                    if (Scenes.list[sceneIndex].linkID === searchParam) {
                        //console.log('sceneIndex: '+sceneIndex+' linkID: '+Scenes.list[sceneIndex].linkID);
                        return sceneIndex;
                    }
                }
                break;
            case 'link_id':
                for (let sceneIndex = Scenes.list.length - 1; sceneIndex >= 0; sceneIndex--) {
                    if (Scenes.list[sceneIndex].link_id === searchParam) {
                        return sceneIndex;
                    }
                }
                break;
            default:
                for (let sceneIndex = Scenes.list.length - 1; sceneIndex >= 0; sceneIndex--) {
                    if (Scenes.list[sceneIndex].linkID === searchParam)
                        return sceneIndex;
                }
                break;
        }
        return -1;
    },
    setLightLevelDebounce: async function(linkID, lightLevel){
        const fadeRate = 1;
        if(Scenes.lightLevelCallPending === true){
            clearTimeout(this.lightLevelCaller);
            this.lightLevelCaller = setTimeout(()=>this.setLightLevelDebounce(linkID, lightLevel), 200);
        }else{
            this.setLightLevel(linkID, lightLevel, fadeRate);
        }
    },
    setLightLevel: async function(linkID, lightLevel, fadeRate) {
        Scenes.lightLevelCallPending = true;
        var myURL = Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/scenes/level/';
        let message = `:flashlight: set light level call failed, check ambr: ${App.activeSiteID}`;
        message = {message};
        console.log('going to set light level', lightLevel);
        $.ajax({
            type: 'POST',
            url: myURL,
            data: {
                'link_id': linkID,
                'level': lightLevel,
                'fade': fadeRate
            },
            headers: {
                Authorization: 'Bearer ' + User.apiToken,
            },
            dataType: 'JSON',
            timeout: 30000,
            success: function(response) {
                if (typeof(response.data) !== 'undefined') {
                    //success indicated by response of "ok"
                    if (response.data.dataString.toLowerCase().indexOf('ok') >= 0 || response.data.dataString.toLowerCase().indexOf('error') < 0) {
                        console.log('success!');
                    } else {
                        //alert("A communication error has occured at call scene");
                        //App.displayError('Unable to communicate with devices. Please check your internet connection, and ensure all SW is up to date.',Errors.getCode('Scenes.call',200));
                        //$('#login-icon').trigger('click').addClass('active');
                        console.log('Unable to communicate with devices, check internet connection and ensure devices are up to date.');
                        App.alert('error','Call Failed: Unable to connect to site.', 'Please check your internet connection and that your AMBR is powered up and online, then try your request again.');
                        App.Site.post(message, 'slack-log','devices', 'v2').catch((err)=>{
                            console.log('issue sending off to slack', err);
                        });
                    }
                } else
                    Error('Scenes.call', 200);
            },
            error: function(request, status, error) {
                if (request.status !== 200) {
                    Error('Scenes.call', request.status, false, 'Scenes.call fails with ' + request.status + ':' + request.statusText);
                    //App.displayErrorOnScreen('Unable to communicate with devices. Please ensure all SW is up to date.');
                    //$('#login-icon').trigger('click').addClass('active');
                }
                App.alert('error','Call Failed: Unable to connect to site.', 'Please check your internet connection and that your AMBR is powered up and online, then try your request again.');
                App.Site.post(message, 'slack-log','devices', 'v2').catch((err)=>{
                    console.log('issue sending off to slack', err);
                });

            },
            complete: function() {
                Scenes.lightLevelCallPending = false;
            }
        });
    },
    //ask AMBR to multicast the scene
    call: function (linkIDToCall, location) {
        let myURL = Communication.getBaseURL() + '/sites/' + App.activeSiteID + '/scenes/call/' + linkIDToCall;
        let message = `:warning: Scene call failed to linkid: ${linkIDToCall}, check ambr: ${App.activeSiteID}`;
        let data = {message};
        Scenes.activeCalls++;
        //////////////////
        //FIRE AWAY!!!!
        ///////////////
        $.ajax({
            type: 'POST',
            url: myURL,
            //data: JSON.stringify(request),
            timeout: 10000,
            datatype: 'json',
            headers: {
                Authorization: 'Bearer ' + User.apiToken
            },
            success: function (response) {

                if (response.errors.length < 1) {
                    //success indicated by response of "ok"
                    //should be either ok: multicast sent or OK: MQTT request sent for: 724
                    if (response.data.dataString.toLowerCase().indexOf('ok') >= 0) {
                        //win
                    } else {

                        //alert("A communication error has occured at call scene");
                        App.alert('error','Call Failed: Unable to connect to site. Please check your internet connection and that your AMBR is powered up and online, then try your request again.');
                        //$('#login-icon').trigger('click').addClass('active');
                        App.Site.post(data, 'slack-log','devices', 'v2').catch((err)=>{
                            console.log('issue sending off to slack', err);
                        });
                    }
                }else{
                    App.alert('error','Call Failed: Unable to connect to site. Please check your internet connection and that your AMBR is powered up and online, then try your request again.');
                    App.Site.post(data, 'slack-log','devices', 'v2').catch((err)=>{
                        console.log('issue sending off to slack', err);
                    });
                }
                //else
                //Error('Scenes.call',200);
            },
            error: function (request, status, error) {
                console.log(request);
                //if(request.status !== 200){
                //Error('Scenes.call',request.status,false,"Scenes.call fails with "+request.status + ":"+request.statusText);
                App.alert('error','Call Failed: Unable to connect to site. Please check your internet connection and that your AMBR is powered up and online, then try your request again.');
                console.log('THE CALL SCENE ERROR OBJECT:');
                App.Site.post(data, 'slack-log','devices', 'v2').catch((err)=>{
                    console.log('issue sending off to slack', err);
                });
                console.log(request);
                //$('#login-icon').trigger('click').addClass('active');
                //}
            },
            complete: function () {
                // Scenes.activeCalls--;
                // if (Scenes.activeCalls < Scenes.maxConcurrentScenesCalls && Scenes.lastCallQueue.length > 0) {

                //     let popRequest = Scenes.lastCallQueue.pop();
                //     Scenes.call(popRequest.linkIDToCall, popRequest.locIndex);

                // }
            }

        });
    },

    //TODO: add this so that i can quickly swap out the action without deleting the other one?
    functionReplacer: function (sceneIndex, displayElement, newVar1) {
        let letsDoIt = true; //always true for now
        let activeScene = Scenes.list[sceneIndex];
        let justRemovedTheLastActionFunction = false; //Flag for indicating there are no actions in the HTML
        let deviceToEdit, linkIDToEdit, nodeIDToEdit;
        let isHostedAction = false; //Like tiny actions. mac action is an example
        let trig = displayElement.data('istrigger');
        let activeDevice;

        //Gather all the data from the DOM
        linkIDToEdit = displayElement.data('linkid');
        let actionFunctionToEdit = displayElement.data('actionfunction');
        deviceToEdit = displayElement.data('nodeid');

        if (letsDoIt) {
            let deviceIndexToEdit = Devices.getIndex(deviceToEdit, 'IP');

            //Scheduled triggers will fall through here
            if (deviceIndexToEdit >= 0) {
                activeDevice = Devices.list[deviceIndexToEdit];
            } else {
                App.alert('error', 'Unable to delete this trigger via this method at this time. \nIf its a scheduled task, please use the tasks tab to delete it.');
                return;
            }

            //find appropriate action in external actions for the device, then remove
            for (let externalActionIndex = 0; externalActionIndex < activeDevice.externalActions.length; externalActionIndex++) {
                let activeExternal = activeDevice.externalActions[externalActionIndex];
                if (
                    activeExternal.linkID === linkIDToEdit &&
                    activeExternal.actionTableName === actionFunctionToEdit) {
                    activeExternal.var1 = newVar1; //Put the new Variable in there
                    activeExternal.saved = User.myUser.id; //
                }
            }

            activeDevice.unsaved = true;

            return true;
        } else {
            return false;
        }
    }
};

export {Scenes};
