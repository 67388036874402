import OpenApiV3 from './spec/openapi-v3.json';
import { fetchInterface, swaggerClient } from './utils';

const { REACT_APP_API_URL_BASE_PATH } = process.env;

OpenApiV3.servers[0].variables.base_url.default = REACT_APP_API_URL_BASE_PATH;

const SDKV3 = {
    _apiV3: null,
    _fetch: fetchInterface,
    init: () => {
        if (SDKV3._apiV3) return SDKV3._apiV3;
        SDKV3._apiV3 = swaggerClient(OpenApiV3, SDKV3._fetch);

        return SDKV3._apiV3;
    }
}

export default SDKV3;
