import {
    LIST_DEVICE_GROUPS_REQUEST,
    LIST_DEVICE_GROUPS_FAILURE,
    LIST_DEVICE_GROUPS_FULFILL,
    LIST_DEVICE_GROUPS_SUCCESS,
    INSERT_DEVICE_GROUPS_SUCCESS,
} from './actions';

export const deviceGroupsColors = [
    '#84C1FF',
    '#4DB3F9',
    '#4476DB',
    '#5762F2',
    '#7D7DDB',
    '#9F4DF9',
    '#20307F',
    '#011F51',
    '#32D7FF',
    '#00E9F0',
    '#51F5C6',
    '#A9FB95',
    '#F9F871',
    '#7C9EE6',
    '#868BD8',
    '#9176C6',
    '#9A61B0',
    '#A04A96',
    '#A23179',
    '#6344DB',
    '#006DFA',
]


const getColor = (index) => {
    let i = String(index).slice(-2);
    if (i > deviceGroupsColors.length - 1) {
        i = String(index).slice(-1);
    }
    return deviceGroupsColors[Number(i)];
};

const initialState = {
    device_groups: [],
    device_groups_colors: [],
    error: null,
    loading: false,
};

const handleRequest = state => Object.assign({}, state, { loading: true, error: null });
const handleFailure = (state, { payload }) => Object.assign({}, state, { error: payload });
const handleFulfill = state => Object.assign({}, state, { loading: false });
const handleSuccess = (state, { payload }) =>
    Object.assign({}, state,
        {
            device_groups: payload,
            device_groups_colors: payload.map((item, index) =>
                ({
                    device_groups_id: item.device_groups_id,
                    color: getColor(index),
                })
            )
        }
    );

const handleSuccessCreate = (state, { payload }) => {
    const existDeviceGroup = state.device_groups.some(item => item.device_groups_id === payload.device_groups_id);
    if (!existDeviceGroup) {
        return Object.assign({}, state, {
            state,
            device_groups_colors: [
                ...state.device_groups_colors,
                {
                    device_groups_id: payload.device_groups_id,
                    color: getColor(state.device_groups.length + 1),
                }
            ],
            device_groups: [
                payload,
                ...state.device_groups,
            ]
        })
    }
};


const handlers = {
    [LIST_DEVICE_GROUPS_REQUEST]: handleRequest,
    [LIST_DEVICE_GROUPS_FAILURE]: handleFailure,
    [LIST_DEVICE_GROUPS_FULFILL]: handleFulfill,
    [LIST_DEVICE_GROUPS_SUCCESS]: handleSuccess,
    [INSERT_DEVICE_GROUPS_SUCCESS]: handleSuccessCreate,
};

const reducer = (state = initialState, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
